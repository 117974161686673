import "./index.scss";
import {memo, useContext, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import OrderHistory from './order-history';
import OrderInfo from './order-info';
import api from '../../../utils/api';
import Loading from '../../../components/ui/loading';
import UpdateOrder from '../../../components/update-order';
import {DEFAULT_CENTER} from '../index';
import Tab from '../../../components/ui/menu-tab';
import {RiCloseCircleFill} from 'react-icons/ri';
import Overlay from '../../../components/ui/overlay';
import {useTranslation} from "react-i18next";
import PreferencesContext from "../../../context/preferences/preferences-context";
import cn from "classnames";
import AuthContext from "../../../context/auth/auth-context";

function OrderDetails({
  mapIntegration,
  activeTab,
  selectedOrderActiveTab,
  setSelectedOrderActiveTab,
  setOrderUpdateIsOpen,
  setActiveTab,
  orderId,
  orderUpdateIsOpen,
  onCloseUpdateOrder,
  onDeselectOrder,
  branchMarkers = [],
  startDate,
  endDate,
  hasOverlay = false,
  isOpen
}) {
    const [selectedOrder, setSelectedOrder] = useState(null);
    const {t} = useTranslation();
    const {list} = useContext(PreferencesContext);
    const {user} = useContext(AuthContext);

    useEffect(() => {
      api.get(`/orders/${orderId}`).then(({ data }) => {
        setSelectedOrder(data);
      })
    }, [orderId]);

    return (
      <div className={cn("orders_details", {"orders_details--animate" : isOpen})}>
        {selectedOrder && (
          <div className="orders_details_header">
            {user.role.permissions.includes("update_order") && selectedOrder.status !== 'delivered' && selectedOrder.status !== 'canceled' && selectedOrder.status !== 'failed' && list.order_update_enabled.isActive && (
              <Tab
                title={t("pages.dashboard.order_details.edit")}
                onClick={() => setOrderUpdateIsOpen(true)}
              />
            )}
            <Tab title={`#${selectedOrder.externalId} ${t("pages.dashboard.order_details.order")}`} isActive={true}/>
            <div
              className="orders_details_header__close"
              onClick={onDeselectOrder}
            >
              <RiCloseCircleFill/>
            </div>
          </div>
        )}
        {selectedOrder && (
          <div className="orders_details_tabs">
            <Tab
              title={t("pages.dashboard.order_details.information")}
              isActive={ selectedOrderActiveTab === 'information'}
              onClick={() => setSelectedOrderActiveTab('information')}
            />
            <Tab
              title={t("pages.dashboard.order_details.history")}
              isActive={selectedOrderActiveTab === 'history'}
              onClick={() => setSelectedOrderActiveTab('history')}
            />
          </div>
        )}
        <div className="orders_details_in">
          {!selectedOrder && (
            <Loading />
          )}
          {selectedOrderActiveTab === 'information' && selectedOrder && (
            <OrderInfo order={selectedOrder} />
          )}
          {selectedOrderActiveTab === 'history' && selectedOrder && (
            <OrderHistory
              order={selectedOrder}
              mapIntegration={mapIntegration}
            />
          )}
        </div>
        {orderUpdateIsOpen && selectedOrder && (
          <UpdateOrder
            mapIntegration={mapIntegration}
            center={branchMarkers[0]?.position || DEFAULT_CENTER}
            onClose={onCloseUpdateOrder}
            order={selectedOrder}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            onDeselectOrder={onDeselectOrder}
            startDate={startDate}
            endDate={endDate}
          />
        )}
        {hasOverlay && (
          <Overlay
            isTransparent={true}
            onClick={onDeselectOrder}
          />
        )}
      </div>
    );
}

OrderDetails.propTypes = {
  mapIntegration: PropTypes.object.isRequired,
  selectedOrderActiveTab: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  orderUpdateIsOpen: PropTypes.bool.isRequired,
  onCloseUpdateOrder: PropTypes.func.isRequired,
  setSelectedOrderActiveTab: PropTypes.func.isRequired,
  setOrderUpdateIsOpen: PropTypes.func.isRequired,
  onDeselectOrder: PropTypes.func,
  branchMarkers: PropTypes.array,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  hasOverlay: PropTypes.bool
};

export default memo(OrderDetails)
