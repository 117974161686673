import "./index.scss";
import {memo} from "react";
import {useTranslation} from "react-i18next";
import VisaSvg from "../../../assets/visa.svg"
import MasterSvg from "../../../assets/mastercard.svg"
import AmexSvg from "../../../assets/amex.svg"
import DiscoverSvg from "../../../assets/discover.svg"
import JcbSvg from "../../../assets/jcb.svg"

function BillingCreditCard({ isDefault, name, last4, expFrom, expTo, onChange, onDelete }) {
  const {t} = useTranslation();

  return (
    <div className="billing_credit_card">
      {(name !== "visa" && name !== "mastercard" && name !== "amex" && name !== "discover" && name !== "jcb" ) && (
        <p className="billing_credit_card_name">{name}</p>
      )}
      {name === "visa" && (
        <img src={VisaSvg} alt="visa"/>
      )}
      {name === "mastercard" && (
        <img src={MasterSvg} alt="mastercard"/>
      )}
      {name === "amex" && (
        <img src={AmexSvg} alt="amex"/>
      )}
      {name === "discover" && (
        <img src={DiscoverSvg} alt="discover"/>
      )}
      {name === "jcb" && (
        <img src={JcbSvg} alt="jcb"/>
      )}
      <div className="billing_credit_card_info">
        <p className="billing_credit_card_info_num">**** {last4} | {expFrom}/{expTo}</p>
      </div>
      <div className="billing_credit_card_in">
        {isDefault && (
          <button
            className="billing_credit_card_in_btn billing_credit_card_in_btn--label"
          >{t("pages.billing.primary")}</button>
        )}
        {onChange && (
          <button
            className="billing_credit_card_in_btn"
            onClick={onChange}
          >{t("pages.billing.setDefaultCard")}</button>
        )}
        {onDelete && (
          <button
            className="billing_credit_card_in_btn"
            onClick={onDelete}
          >{t("pages.billing.deleteCard")}</button>
        )}
      </div>
    </div>
  );
}

export default memo(BillingCreditCard);
