import {memo} from "react";
import PhoneImg from "../../../../../assets/phone.png"
import {Tooltip} from "react-tooltip";
import {AiOutlineQuestionCircle} from "react-icons/ai";
import {useTranslation} from "react-i18next";

function SettingsIntegrationsSmsMobileWidget({
isSmsActivated,
selectedSmsChannel,
templates
}) {

  const {t} = useTranslation();

  return (
    <div className="settings_integrations_sms_in_right">
      <div className="settings_integrations_sms_image">
        <div className="settings_integrations_sms_image_in">
          <img src={PhoneImg} alt="sms"/>
          <div className="settings_integrations_sms_image_in_title">
            {isSmsActivated && selectedSmsChannel?.value === "paradox" && selectedSmsChannel.credentials.sourceNumber}
            {isSmsActivated && selectedSmsChannel?.value === "dexatel" && selectedSmsChannel.credentials.sender}
            {isSmsActivated && selectedSmsChannel?.value === "twilio" && "Delivery"}
            {isSmsActivated && selectedSmsChannel?.value === "default" && (
              <>
                Delivery
                <AiOutlineQuestionCircle
                  className="settings_integrations_sms_image_in_title_icon"
                  data-tooltip-id={`tooltip-notification-title`}
                />
              </>
            )}
          </div>
          {isSmsActivated && (
            <div className="settings_integrations_sms_image_in_wrapper">
              {templates.map((template, index) => (
                <div key={index}>
                  <div
                    data-tooltip-id={`tooltip-${template.id}`}
                    className="settings_integrations_sms_image_in_msg"
                  >
                    <p>{template.format}</p>
                  </div>
                  <Tooltip
                    id={`tooltip-${template.id}`}
                    place="left"
                    className="tooltipClass"
                  >{t(`pages.settings.smsStatusDescription`)} {t(`statuses.${template.name}`)}</Tooltip>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Tooltip
        id={`tooltip-notification-title`}
        place="top"
        className="tooltipClass"
      >{t("pages.settings.notificationTitle")}</Tooltip>
    </div>
  );
}

export default memo(SettingsIntegrationsSmsMobileWidget);
