import { useReducer, useCallback, memo } from "react";
import IntegrationsContext from "./integrations-context";

const defaultIntegrationsState = {
  mapIntegration: null,
  setMapIntegration: () => {}
};

const integrationsReducer = (state, action) => {
  if (action.type === "SET_INTEGRATIONS") {
    return {
      ...state,
      mapIntegration: action.payload.mapIntegration
    };
  }

  return defaultIntegrationsState;
};

const IntegrationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(integrationsReducer, defaultIntegrationsState);

  const setMapIntegration = useCallback(mapIntegration => {
    dispatch({
        type: "SET_INTEGRATIONS",
        payload: { mapIntegration },
      });
  }, [dispatch]);

  const integrationsContext = {
    mapIntegration: state.mapIntegration,
    setMapIntegration: setMapIntegration
  };

  return (
    <IntegrationsContext.Provider value={integrationsContext}>{children}</IntegrationsContext.Provider>
  );
};

export default memo(IntegrationsProvider);
