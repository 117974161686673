import { useReducer, useCallback, memo } from "react";
import AuthContext from "./auth-context";

const defaultAuthState = {
  isAuth: null,
  user: null,
};

const authReducer = (state, action) => {
  if (action.type === "AUTH") {
    return {
      ...state,
      isAuth: Boolean(action.payload.user),
      user: action.payload.user,
    };
  }
  if (action.type === "NOT_AUTH") {
    return {
      ...state,
      isAuth: false,
      user: null,
    };
  }
  if (action.type === "UPDATE") {
    return {
      ...state,
      isAuth: Boolean(action.payload.user),
      user: action.payload.user
    };
  }
  return defaultAuthState;
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, defaultAuthState);
  const authHandler = useCallback(
    (user) => {
      dispatch({
        type: "AUTH",
        payload: { user },
      });
    },
    [dispatch]
  );
  const logOutHandler = useCallback(
    (user) => {
      dispatch({
        type: "NOT_AUTH",
        payload: { user },
      });
    },
    [dispatch]
  );
  const updateHandler = useCallback(
    (user) => {
      dispatch({
        type: "UPDATE",
        payload: { user },
      });
    },
    [dispatch]
  );

  const authContext = {
    isAuth: state.isAuth,
    user: state.user,
    auth: authHandler,
    logOut: logOutHandler,
    update: updateHandler
  };
  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export default memo(AuthProvider);
