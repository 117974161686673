import './index.scss';
import {memo, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Form from "../../../../components/form";
import FormRow from "../../../../components/form/form-row";
import FormCol from "../../../../components/form/form-col";
import DropDown from "../../../../components/ui/drop-down";
import Loading from "../../../../components/ui/loading";
import api from "../../../../utils/api";
import {BsPersonCircle} from "react-icons/bs";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import moment from "moment";
import UserCompanyBranchesContext from "../../../../context/user-company-branches/user-company-branches-context";
import {errToString} from "../../../../utils";

function AssignUsersForm({
dateFields,
onCancel,
selectedBranch,
setIsAssignUsersFormOpen,
setBranchesData,
setSelectedBranch
}) {
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const {t} = useTranslation();
  const [companyUsers, setCompanyUsers] = useState([]);
  const [error, setError] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null);
  const [pending, setPending] = useState(false);
  const [loading, setLoading] = useState(true);
  const usersOptions = useMemo(() => {
    if (!companyUsers) {
      return;
    }
    return companyUsers
      .filter(user => !selectedBranch.users.find(({ id }) => id === user.id))
      .map(user => ({
        ...user,
        value: user?.id,
        label: `${user?.firstName} ${user?.lastName}`
      }));
  }, [companyUsers, selectedBranch])

  const onSubmit = useCallback(e => {
    e.preventDefault();

    const startDate = moment(dateFields.startDate).utc().format();
    const endDate = moment(dateFields.endDate).utc().format();

    setPending(true);

    api
      .post(`/company/address/${selectedBranch.id}/employees?from=${startDate}&to=${endDate}`, {
        ids: [assignedUser?.id]
      })
      .then(() => {
        setPending(false);
        setIsAssignUsersFormOpen(false);

        api
          .get('/company/addresses?includeUsers')
          .then(({data}) => {
            setBranchesData(data);
            setSelectedBranch(data.find(({id}) => id === selectedBranch.id));
          })
          .catch(err => console.log(errToString(err)));

        api
            .get('/user-company-addresses')
            .then(({data}) => userCompanyBranches.set(data))
            .catch(err => console.log(errToString(err)));
      })
      .catch(err => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      })
  // eslint-disable-next-line
  }, [dateFields, setSelectedBranch, assignedUser, selectedBranch, setIsAssignUsersFormOpen, setBranchesData, userCompanyBranches.set])

  useEffect(() => {
    Promise.all([
      api.get(`/employees/managers?companyAddressIds=all`),
      api.get(`/employees/agents?companyAddressIds=all`)
    ])
      .then((res) => {
        let employees = []
        res.forEach((obj) => {
          employees.push(...obj.data.results)
        })
        setCompanyUsers(employees)
      })
      .catch(err => console.log(errToString(err)))
      .finally(() => {
        setLoading(false);
      })
  }, []);

  return (
    <>
      <div className="assign_users_form">
        <Form
          title={`${t("pages.branches.branch_card.assign_users_form.assignUsersToBranch")} ${selectedBranch.name}`}
          error={error && t(`errors.${error?.toLowerCase()}`)}
          onSubmit={onSubmit}
        >
          <FormRow>
            <FormCol>
              <label htmlFor="">
                {t("pages.branches.branch_card.assign_users_form.chooseOption")}
              </label>
              <div className="create_order_delivery_address_col create_order_delivery_address_col--fit">
                <DropDown
                  hasPersonIcon={true}
                  placeholder={t("pages.branches.branch_card.assign_users_form.chooseOption")}
                  options={usersOptions.filter(option => option.type !== "owner").map((usersOption, index) => ({
                    option: usersOption,
                    el: (
                      <li
                        className="assign_users_form_in_col_list"
                        key={index}
                      >
                        <i className="assign_users_form_in_col_list__icon">
                          <BsPersonCircle />
                        </i>
                        <p className="assign_users_form_in_col_list__name">{usersOption.firstName} {usersOption.lastName}</p>
                        <p className="assign_users_form_in_col_list__name">{usersOption.type}</p>
                        <p className="assign_users_form_in_col_list__name">{usersOption.username}</p>
                      </li>
                    )
                  }))}
                  loading={loading}
                  value={assignedUser}
                  onChange={(e) => setAssignedUser(e)}
                  position="bottom"
                  showClose={false}
                />
              </div>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <a
                href="!#"
                className="update_agent__cancel_btn"
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
              >{t("pages.agents.update_agent.update_password_agent.cancel")}</a>
            </FormCol>
            <FormCol>
              {!pending && (
                <button type='submit' className="assign_users_form__submit_btn">{t("pages.branches.branch_card.assign_users_form.assignUser")}</button>
              )}
              {pending && (
                <button className="assign_users_form__submit_btn assign_users_form__submit_btn--pending">
                  <Loading />
                </button>
              )}
            </FormCol>
          </FormRow>
        </Form>
      </div>
      <div className="assign_users_form__overlay"/>
    </>
  );
}

AssignUsersForm.propTypes = {
  onCancel: PropTypes.func,
  selectedBranch: PropTypes.object,
  setIsAssignUsersFormOpen: PropTypes.func,
  setBranchesData: PropTypes.func,
  setSelectedBranch: PropTypes.func,
}

export default memo(AssignUsersForm);
