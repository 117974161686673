import { memo } from "react";
import "./index.scss";

function SideDecoration() {

  return (
    <div className='decoration'>
      <span className="decoration--start"></span>
      <span className="decoration--body"></span>
      <span className="decoration--end"></span>
    </div>
  );
}

export default memo(SideDecoration);
