import "./index.scss";
import {memo} from "react";


const Analytics = () => {

  return (
    <div>
      Analytics
    </div>
  );
}

export default memo(Analytics);