export const getZoomFromLatLngOpenStreet = (lat1, lng1, lat2, lng2) => {
  const latDiff = Math.abs(lat1 - lat2);
  const lngDiff = Math.abs(lng1 - lng2);
  const latZoom = Math.floor(Math.log(360 / latDiff) / Math.LN2);
  const lngZoom = Math.ceil(Math.log(360 / lngDiff) / Math.LN2);

  return Math.min(latZoom, lngZoom) - 0.5;
}

export const getCenterFromLatLngOpenStreet = (lat1, lng1, lat2, lng2) => {
  const lat = (lat1 + lat2) / 2;
  const lng = (lng1 + lng2) / 2;

  return {
    lat,
    lng
  };
}
