import MarkerCanceledSvg from '../assets/markers/marker_canceled.svg';
import MarkerFinishedSvg from '../assets/markers/marker_finished.svg';
import MarkerPendingSvg from '../assets/markers/marker_pending.svg';
import MarkerAcceptedSvg from '../assets/markers/marker_accepted.svg';
import MarkerAssignedSvg from '../assets/markers/marker_assigned.svg';
import MarkerStartedSvg from '../assets/markers/marker_started.svg';
import MarkerPickedUpSvg from '../assets/markers/marker_picked_up.svg';
import MarkerOnWaySvg from '../assets/markers/marker_on_way.svg';
import OnlineAgentSvg from '../assets/online_agent.svg';
import OfflineAgentSvg from '../assets/offline_agent.svg';

export const ORDER_MARKERS = {
  created: MarkerPendingSvg,
  unassigned: MarkerPendingSvg,
  assigned: MarkerAssignedSvg,
  accepted: MarkerAcceptedSvg,
  started: MarkerStartedSvg,
  picked_up: MarkerPickedUpSvg,
  on_way: MarkerOnWaySvg,
  delivered: MarkerFinishedSvg,
  failed: MarkerCanceledSvg,
  canceled: MarkerCanceledSvg
};

export const AGENT_MARKERS = {
  online: OnlineAgentSvg,
  offline: OfflineAgentSvg
};
