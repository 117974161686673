import "./index.scss";
import {memo, useCallback, useContext, useEffect, useMemo, useState} from "react";
import PageHeader from "../../../../components/layout/page-header";
import api from "../../../../utils/api";
import PreferencesContext from "../../../../context/preferences/preferences-context";
import DropDown from '../../../../components/ui/drop-down';
import {useTranslation} from "react-i18next";
import {TIME_RANGE_HOURS} from "../../../../constants/drop-down-options";
import {errToString} from "../../../../utils";

const DEFAULT_OPTIONS = [
  { label: "yes", value: true },
  { label: "no", value: false }
];
const DASHBOARD_VIEW_OPTIONS = [
  { label: "map", value: 'map' },
  { label: "list", value: 'list' }
];
const DATE_FORMAT_OPTIONS = [
  { label: "13/04/23", value: "DD/MM/YY"},
  { label: "Th/04/23", value: "dd/MM/YY"},
  { label: "13/Apr/23", value: "DD/MMM/YY"},
  { label: "13 Th/Apr/23", value: "DD dd/MMM/YY"},
  { label: "13/April/23", value: "DD/MMMM/YY"},
  { label: "13 Th/April/23", value: "DD dd/MMMM/YY"}
];
const TIME_FORMAT_OPTIONS = [
  { label: "24_hours", value: 'HH:mm' },
  { label: "12_hours", value: 'hh:mm' }
];
const DISTANCE_UNIT_OPTIONS = [
  { label: "Km", value: 'km' },
  { label: "Mile", value: 'mile' }
];

function SettingsCompanyPreferences() {
  const {list, update} = useContext(PreferencesContext);
  const [currencies, setCurrencies] = useState(null);
  const {t} = useTranslation();

  const [currencyOption, setCurrencyOption] = useState(null);
  const [delayTimeLow, setDelayTimeOptionLow] = useState((list.order_delay_time.value.low / 60).toFixed(0));
  const [delayTimeHigh, setDelayTimeOptionHigh] = useState((list.order_delay_time.value.high / 60).toFixed(0));
  const [workingHoursFrom, setWorkingHoursFrom] = useState({
    label: `${list.working_hours.value.from}`,
    value: `${list.working_hours.value.from}`,
  });
  const [workingHoursTo, setWorkingHoursTo] = useState({
    label: `${list.working_hours.value.to}`,
    value: `${list.working_hours.value.to}`,
  });

  const [dateFormatOption, setDateFormatOption] = useState(DATE_FORMAT_OPTIONS.find(({ value }) => value === list.date_format.value.format));
  const [timeFormatOption, setTimeFormatOption] = useState({label: "", value: ""});
  const [distanceUnitOption, setDistanceUnitOption] = useState(DISTANCE_UNIT_OPTIONS.find(({ value }) => value === list.distance_unit.value.unit));
  const [dashboardViewOption, setDashboardViewOption] = useState({label: "", value: ""});
  const [delayTimeOption, setDelayTimeOption] = useState({label: "", value: ""});
  const [orderUpdateOption, setOrderUpdateOption] = useState({label: "", value: ""});

  const default_options = useMemo(() => DEFAULT_OPTIONS.map(option => ({
    ...option,
    label: t(`pages.settings.${option.label}`)
  })), [t]);
  const dashboard_view_options = useMemo(() => DASHBOARD_VIEW_OPTIONS.map(option => ({
    ...option,
    label: t(`pages.settings.${option.label}`)
  })), [t]);
  const time_format_options = useMemo(() => TIME_FORMAT_OPTIONS.map(option => ({
    ...option,
    label: t(`pages.settings.${option.label}`)
  })), [t]);

  const onSubmit = useCallback(() => {
    const promises = [];

    //order_delay_time
    if (
      delayTimeOption.value !== list.order_delay_time.isActive ||
      Number(delayTimeLow) !== Number(list.order_delay_time.value.low / 60) ||
      Number(delayTimeHigh) !== Number(list.order_delay_time.value.high / 60)
    ) {
      const delayTimeBody = {
        isActive: delayTimeOption.value,
        value: {
          low: Number(delayTimeLow * 60),
          high: Number(delayTimeHigh * 60)
        }
      };
      promises.push(api.put(`/company/preferences/${list.order_delay_time.id}`, delayTimeBody));
    }

    //working_hours
    if (
      String(workingHoursFrom.label) !== String(list.working_hours.value.from) ||
      String(workingHoursTo.label) !== String(list.working_hours.value.to)
    ) {
      const workingHoursBody = {
        value: {
          from: String(workingHoursFrom.label),
          to: String(workingHoursTo.label)
        }
      };
      promises.push(api.put(`/company/preferences/${list.working_hours.id}`, workingHoursBody));
    }

    //order_update_enabled
    if (
      orderUpdateOption.value !== list.order_update_enabled.isActive
    ) {
      const orderUpdateBody = {
        isActive: orderUpdateOption.value
      };
      promises.push(api.put(`/company/preferences/${list.order_update_enabled.id}`, orderUpdateBody));
    }

    //dashboard_view
    if (dashboardViewOption.value !== list.dashboard_view.value.type) {
      const dashboardViewBody = {
        value: {
          type: dashboardViewOption.value,
        }
      };
      promises.push(api.put(`/company/preferences/${list.dashboard_view.id}`, dashboardViewBody));
    }

    //date_format
    if (dateFormatOption.value !== list.date_format.value.format) {
      const dateFormatBody = {
        value: {
          format: dateFormatOption.value,
        }
      };
      promises.push(api.put(`/company/preferences/${list.date_format.id}`, dateFormatBody));
    }

    //time_format
    if (timeFormatOption.value !== list.time_format.value.format) {
      const timeFormatBody = {
        value: {
          format: timeFormatOption.value,
        }
      };
      promises.push(api.put(`/company/preferences/${list.time_format.id}`, timeFormatBody));
    }

    //distance_unit
    if (distanceUnitOption.value !== list.distance_unit.value.unit) {
      const distanceUnitBody = {
        value: {
          unit: distanceUnitOption.value,
        }
      };
      promises.push(api.put(`/company/preferences/${list.distance_unit.id}`, distanceUnitBody));
    }

    //currency
    if (currencyOption.value !== list.currency.value.currency) {
      const currencyBody = {
        value: {
          currency: currencyOption.value,
        }
      };
      promises.push(api.put(`/company/preferences/${list.currency.id}`, currencyBody));
    }

    Promise
      .all(promises)
      .then((res) => {
        res.forEach(({ data }) => update(data));
        return alert(t("errors.success"));
      })
      .catch(err => {
        alert(errToString(err));
        console.log(errToString(err))
      });
  }, [
    t,
    list,
    update,
    currencyOption,
    dashboardViewOption,
    dateFormatOption,
    timeFormatOption,
    distanceUnitOption,
    delayTimeOption,
    delayTimeLow,
    delayTimeHigh,
    orderUpdateOption,
    workingHoursFrom,
    workingHoursTo
  ]);

  useEffect(() => {
    api
      .get('https://openexchangerates.org/api/currencies.json')
      .then(({data}) => {
        const currencies = [];

        for(const key in data) {
          currencies.push({
            value: key,
            label: `${key} - ${data[key]}`
          });
        }
        setCurrencies(currencies);
        setCurrencyOption(currencies.find(({ value }) => value.toLowerCase() === list.currency.value.currency.toLowerCase()));
      })
      .catch(err => console.log(errToString(err)))
  }, [list.currency.value.currency]);
  useEffect(() => {
    if (default_options) {
      setDelayTimeOption(default_options.find(({ value }) => value === list.order_delay_time.isActive));
      setOrderUpdateOption(default_options.find(({ value }) => value === list.order_update_enabled.isActive));
    }
    if (time_format_options) {
      setTimeFormatOption(time_format_options.find(({ value }) => value === list.time_format.value.format));
    }
    if (dashboard_view_options) {
      setDashboardViewOption(dashboard_view_options.find(({ value }) => value === list.dashboard_view.value.type));
    }
  }, [dashboard_view_options, default_options, time_format_options, list])

  return (
    <div className="settings_company_preferences">
      <PageHeader>
        <p>{t("pages.settings.companyPreferences")}</p>
      </PageHeader>
      <div className="settings_company_preferences_in">
        <h2 className="settings_company_preferences_in__title">
          {t("pages.settings.orderDelayTime")}
        </h2>
        <p className="settings_company_preferences_in__descrip">
          {t("pages.settings.orderDelayTimeDescription")}
        </p>
        <div className="settings_company_preferences_in_options">
          <div className="settings_company_preferences_in_options_in">
            <div className="settings_company_preferences_in_options_drop_down">
              <DropDown
                hasCounterIcon={true}
                placeholder={t("components.search.chooseOptions")}
                position="top"
                showClose={false}
                options={default_options}
                value={delayTimeOption}
                onChange={option => {
                  if (option.value === delayTimeOption.value) {
                    return;
                  }
                  setDelayTimeOption(option);
                  setDelayTimeOptionLow(list.order_delay_time.value.low / 60);
                  setDelayTimeOptionHigh(list.order_delay_time.value.high / 60);
                }}
              />
              {delayTimeOption.value && (
                <>
                  <div className="settings_company_preferences_in_options_wrapper">
                    <label htmlFor="">{t("pages.settings.lowDelay")}</label>
                    <input
                      type="text"
                      value={delayTimeLow}
                      onChange={e => setDelayTimeOptionLow(e.target.value)}
                    />
                  </div>
                  <div className="settings_company_preferences_in_options_wrapper">
                    <label htmlFor="">{t("pages.settings.highDelay")}</label>
                    <input
                      type="text"
                      value={delayTimeHigh}
                      onChange={e => setDelayTimeOptionHigh(e.target.value)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <h2 className="settings_company_preferences_in__title">
          {t("pages.settings.trackingLinkRedirect")}
        </h2>
        <p className="settings_company_preferences_in__descrip">
          {t("pages.settings.trackingLinkRedirectDescription")}
        </p>
        <h2 className="settings_company_preferences_in__title">
          {t("pages.settings.orderUpdateEnabled")}
        </h2>
        <p className="settings_company_preferences_in__descrip">
          {t("pages.settings.orderUpdateEnabledDescription")}
        </p>
        <div className="settings_company_preferences_in_options">
          <div className="settings_company_preferences_in_options_in">
            <div className="settings_company_preferences_in_options_drop_down">
              <DropDown
                hasUpdateIcon={true}
                placeholder={t("components.search.chooseOptions")}
                position="top"
                showClose={false}
                options={default_options}
                value={orderUpdateOption}
                onChange={option => {
                  if (option.value === orderUpdateOption.value) {
                    return;
                  }
                  setOrderUpdateOption(option);
                }}
              />
            </div>
          </div>
        </div>
        <h2 className="settings_company_preferences_in__title">
          {t("pages.settings.defaultDashboardView")}
        </h2>
        <p className="settings_company_preferences_in__descrip">
          {t("pages.settings.defaultDashboardViewDescription")}
        </p>
        <div className="settings_company_preferences_in_options">
          <div className="settings_company_preferences_in_options_in">
            <div className="settings_company_preferences_in_options_drop_down">
              <DropDown
                hasMapIcon={true}
                placeholder={t("components.search.chooseOptions")}
                position="top"
                showClose={false}
                options={dashboard_view_options}
                value={dashboardViewOption}
                onChange={option => {
                  if (option.value === dashboardViewOption.value) {
                    return;
                  }
                  setDashboardViewOption(option);
                }}
              />
            </div>
          </div>
        </div>
        <h2 className="settings_company_preferences_in__title">
          {t("pages.settings.workingHours")}
        </h2>
        <p className="settings_company_preferences_in__descrip">
          {t("pages.settings.workingHoursDescription")}
        </p>
        <div className="settings_company_preferences_in_options">
          <div className="settings_company_preferences_in_options_in">
            <div className="settings_company_preferences_in_options_drop_down">
              <div className="settings_company_preferences_in_options_wrapper">
                <label htmlFor="">{t("pages.settings.start")}</label>
                <DropDown
                  hasTimeIcon={true}
                  position="top"
                  placeholder="HH:MM"
                  options={TIME_RANGE_HOURS}
                  label="time"
                  maxHeight={100}
                  value={workingHoursFrom}
                  onChange={option => setWorkingHoursFrom(option)}
                  className="settings_company_preferences_in_options_drop_down_menu"
                />
              </div>
              <div className="settings_company_preferences_in_options_wrapper">
                <label htmlFor="">{t("pages.settings.end")}</label>
                <DropDown
                  hasTimeIcon={true}
                  position="top"
                  placeholder="HH:MM"
                  options={TIME_RANGE_HOURS}
                  label="time"
                  maxHeight={100}
                  value={workingHoursTo}
                  onChange={option => setWorkingHoursTo(option)}
                  className="settings_company_preferences_in_options_drop_down_menu"
                />
              </div>
            </div>
          </div>
        </div>
        <h2 className="settings_company_preferences_in__title">
          {t("pages.settings.dateFormat")}
        </h2>
        <p className="settings_company_preferences_in__descrip">
          {t("pages.settings.dateFormatDescription")}
        </p>
        <div className="settings_company_preferences_in_options">
          <div className="settings_company_preferences_in_options_in">
            <div className="settings_company_preferences_in_options_drop_down">
              <DropDown
                hasDateIcon={true}
                placeholder={t("components.search.chooseOptions")}
                position="top"
                showClose={false}
                options={DATE_FORMAT_OPTIONS}
                value={dateFormatOption}
                onChange={option => {
                  if (option.value === dateFormatOption.value) {
                    return;
                  }
                  setDateFormatOption(option);
                }}
              />
            </div>
          </div>
        </div>
        <h2 className="settings_company_preferences_in__title">
          {t("pages.settings.timeFormat")}
        </h2>
        <p className="settings_company_preferences_in__descrip">
          {t("pages.settings.timeFormatDescription")}
        </p>
        <div className="settings_company_preferences_in_options">
          <div className="settings_company_preferences_in_options_in">
            <div className="settings_company_preferences_in_options_drop_down">
              <DropDown
                hasTimeIcon={true}
                placeholder={t("components.search.chooseOptions")}
                position="top"
                showClose={false}
                options={time_format_options}
                value={timeFormatOption}
                onChange={option => {
                  if (option.value === timeFormatOption.value) {
                    return;
                  }
                  setTimeFormatOption(option);
                }}
              />
            </div>
          </div>
        </div>
        <h2 className="settings_company_preferences_in__title">
          {t("pages.settings.distanceUnit")}
        </h2>
        <p className="settings_company_preferences_in__descrip">
          {t("pages.settings.distanceUnitDescription")}
        </p>
        <div className="settings_company_preferences_in_options">
          <div className="settings_company_preferences_in_options_in">
            <div className="settings_company_preferences_in_options_drop_down">
              <DropDown
                hasKmIcon={true}
                placeholder={t("components.search.chooseOptions")}
                position="top"
                showClose={false}
                options={DISTANCE_UNIT_OPTIONS}
                value={distanceUnitOption}
                onChange={option => {
                  if (option.value === distanceUnitOption.value) {
                    return;
                  }
                  setDistanceUnitOption(option);
                }}
              />
            </div>
          </div>
        </div>
        <h2 className="settings_company_preferences_in__title">
          {t("pages.settings.currency")}
        </h2>
        <p className="settings_company_preferences_in__descrip">
          {t("pages.settings.currencyDescription")}
        </p>
        <div className="settings_company_preferences_in_options">
          <div className="settings_company_preferences_in_options_in">
            <div className="settings_company_preferences_in_options_drop_down">
              <DropDown
                hasCurrencyIcon={true}
                placeholder={t("components.search.chooseOptions")}
                position="top"
                showClose={false}
                options={currencies || []}
                value={currencyOption || {}}
                onChange={option => {
                  if (option.value === currencyOption.value) {
                    return;
                  }
                  setCurrencyOption(option);
                }}
              />
            </div>
          </div>
        </div>
        <div className="settings_company_preferences_in_actions">
          <button
            className="create_order_submit_btn"
            onClick={onSubmit}
          >
            {t("pages.branches.create_branch_form.save")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(SettingsCompanyPreferences);
