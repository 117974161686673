import { memo, useCallback, useContext, useState } from "react";
import {useNavigate} from "react-router-dom";
import api from "../../../utils/api";
import AuthContext from "../../../context/auth/auth-context";
import "./index.scss";
import Logo from "../../../assets/logo.png";
import {MdOutlineError, MdPersonOutline} from "react-icons/md";
import {RiLockPasswordLine} from "react-icons/ri";
import {errToString} from "../../../utils";
import {useTranslation} from "react-i18next";
import {BiHide, BiShow} from "react-icons/bi";

function SignIn({setAuthType}) {
    const authCtx = useContext(AuthContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [fields, setFields] = useState({
      username: '',
      password: '',
     });
    const onChangeField = useCallback((e, name) => {
    setError(null);

    if (
      e.target.type === 'password' ||
      e.target.type === 'text'
    ) {
      setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [fields]);

    const onSubmit = useCallback((e) => {
      e.preventDefault();
      setError(null);

      const data = {
          password: fields.password,
          username: fields.username,
        };
        for (const key in data) {
          if (data[key] === null) {
            delete data[key];
          }
        }
        for (const key in data) {
          if (data[key] === null) {
            delete data[key];
          }
        }
        api
          .post("/auth/sign-in", data)
          .then((res) => {
              localStorage.setItem('token', res.data.token);
              authCtx.auth(res.data.user);
              navigate("/", { replace: true });
          })
          .catch(err => {
            setError(errToString(err));
            console.log(errToString(err));
          });
    }, [fields, authCtx, navigate]);

    return (
      <div className="sign_in">
        <form
          className="new_form"
          onSubmit={onSubmit}
        >
          <img className="new_form_logo" src={Logo} alt="logo"/>
          <h2 className="new_form_title">Sign in</h2>
          <div className="new_form_columns">
            <div className="new_form_column">
              <label htmlFor="username">Email</label>
              <div className="create_order_wrapper">
                <div className="create_order_wrapper_icon">
                  <MdPersonOutline />
                </div>
                <input
                  name="username"
                  type="text"
                  placeholder="Email"
                  className="create_order_wrapper_input"
                  autoComplete="off"
                  value={fields.username}
                  onChange={e => onChangeField(e, 'username')}
                />
              </div>
            </div>
          </div>
          <div className="new_form_columns">
            <div className="new_form_column">
              <label htmlFor="password">Password</label>
              <div className="create_order_wrapper">
                <div className="create_order_wrapper_icon">
                  <RiLockPasswordLine />
                </div>
                <input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  autoComplete="off"
                  className="create_order_wrapper_input"
                  value={fields.password}
                  onChange={e => onChangeField(e, 'password')}
                />
                <div className="create_order_wrapper_icon create_order_wrapper_icon--loading">
                  <span
                    className="create_order_wrapper_icon create_order_wrapper_icon--show"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <BiHide /> : <BiShow />}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="new_form_actions">
            <h3 onClick={() => setAuthType("forgotPass")}>Forgot password?</h3>
            <button type={"submit"}>Sign in</button>
            <h2 className="new_form_actions_login" onClick={() => navigate("/auth/sign-up")}><span>Don't have an account?</span>Sign up</h2>
              {!error && (
                <span className="new_form_actions_error"/>
              )}
              {error && (
                <p className="new_form_actions_error">
                  <MdOutlineError />
                  {t(`errors.${error?.toLowerCase()}`)}
                </p>
              )}
          </div>
        </form>
      </div>
    );
}

export default memo(SignIn);
