import {memo, useState, useEffect, useContext, useCallback} from "react";
import "./index.scss";
import {createPortal} from "react-dom";
import {MdArrowBackIos, MdCircleNotifications} from "react-icons/md";
import AuthContext from "../../context/auth/auth-context";
import api from "../../utils/api"
import moment from "moment";
import cn from "classnames";
import {useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {errToString} from "../../utils";

function Notifications({
  notifications,
  setNotifications,
  notificationsHover,
  setNotificationsHover}) {

  const authCtx = useContext(AuthContext);
  const {pathname} = useLocation();
  const {t} = useTranslation();

  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onRead = useCallback((id, isRead) => {
    if (isRead) {
      return;
    }
    api
      .put(`/notifications/${id}`, { isRead: true })
      .then(res => {
        setNotifications([
          res.data,
          ...notifications.filter(notification => notification.id !== id)
        ]);
      })
      .catch(err => console.log(errToString(err)));
  }, [notifications, setNotifications]);
  const onReadAll = useCallback(() => {
    api
      .put('/notifications', { isRead: true })
      .then((res => {
        setNotifications(res.data);
      }))
      .catch(err => console.log(errToString(err)));
  }, [setNotifications]);

  useEffect(() => {
    setError(null);
    api
      .get("/notifications")
      .then((res => setNotifications(res.data)))
      .catch(err => console.log(errToString(err)));
  }, [setNotifications]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className={cn('notifications', { 'notifications--dashboard': pathname === '/' })}>
        <div
          className="notifications_in"
          onClick={() => setNotificationsHover(true)}
        >
          <div className="notifications_in_label">{
            notifications.filter(notification => !notification.isRead).length > 99 ?
                "99+" :
                notifications.filter(notification => !notification.isRead).length
          }</div>
          <MdCircleNotifications data-tooltip-id={`tooltip-notifications`}/>
        </div>
      </div>
      {authCtx.isAuth && createPortal(
        <>
          {authCtx.user && (
            <div className={cn(
              "notifications_container",
              {"notifications_container--animate" : notificationsHover},
            )}
            >
              <div className="notifications_container__actions">
                <div className="notifications_container__actions_in">
                  <div
                    className='notifications_container__icon'
                    onClick={() => setNotificationsHover(false)}
                  >
                    {windowWidth > 600 ? t("components.notifications.title") : <MdArrowBackIos />}
                  </div>
                  <div
                    className='notifications_container__icon'
                    onClick={onReadAll}
                  >
                    <span>{t("components.notifications.readAll")}</span>
                  </div>
                </div>
              </div>
              {!!notifications.length && (
                <ul>
                  {notifications?.map((notification, index) => (
                    <li
                      key={index}
                      className={cn("notifications_container_list", {"notifications_container_list--hovered" : !notification.isRead})}
                      onClick={() => onRead(notification.id, notification.isRead)}
                    >
                      <div className="notifications_container_list_heading">
                        <p className={cn("notifications_container_list_order", {"notifications_container_list_order--bold" : !notification.isRead})}>
                          {`${t("components.notifications.order")} #${notification.resourceId}`}
                        </p>
                        <p className="notifications_container_list_time">{moment(notification.updatedAt).fromNow()}</p>
                        {!notification.isRead && (
                          <span className="notifications_container_list_heading_newIcon"></span>
                        )}
                      </div>
                      <p className={cn("notifications_container_list_info", {"notifications_container_list_info--bold" : !notification.isRead})}>
                        {`${notification?.initiatorType === "owner" ? t("components.notifications.owner") : 
                          notification?.initiatorType === "manager" ? t("components.notifications.manager") : 
                            notification?.initiatorType === "agent" ? t("components.notifications.agent") : 
                              notification?.initiatorType === "customer" ? t("components.notifications.customer") : ""} ${notification.initiator?.firstName || notification.customer?.firstName || ''} ${notification.initiator?.lastName || notification.customer?.lastName || notification.customer?.phoneNumber} ${notification?.resourceAction === "update" ? t("components.notifications.update") : 
                          notification?.resourceAction === "create" ? t("components.notifications.create") : 
                            notification?.resourceAction === "rated" ? t("components.notifications.rated") : 
                              notification?.resourceAction === "delete" ? t("components.notifications.delete") : ""}
                        `}
                      </p>
                    </li>
                  ))}
                  {error && (
                    <p className="notifications_container_list_info">{error}</p>
                  )}
                </ul>
              )}
              {!notifications.length && (
                <p className="notifications_container_list_info">{t("components.notifications.noData")}</p>
              )}
            </div>
          )}
          {authCtx.user && notificationsHover && (
            <div
                className="notifications_container_overlay"
                onClick={() => setNotificationsHover(false)} />
          )}
        </>,
        document.getElementById('modal')
      )}
      <Tooltip
          id={`tooltip-notifications`}
          place="bottom"
          className="tooltipClass"
      >{t("components.layout.header.notifications")}</Tooltip>
    </>
  );
}

export default memo(Notifications);
