import {memo, useCallback, useEffect, useState} from "react";
import "./index.scss";
import Tab from "../../../components/ui/menu-tab";
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import OrderList from './orders-list';
import OrderDetails from '../order-details';
import { BiSearch } from "react-icons/bi";
import ActionBtn from "../../../components/ui/action-btn";
import {FiRefreshCcw} from "react-icons/fi";
import {fromLonLat} from 'ol/proj';
import {useTranslation} from "react-i18next";

function OrdersModal({
  mapIntegration,
  setIsNewOrderOpen,
  activeTab,
  setActiveTab,
  selectedOrderActiveTab,
  setSelectedOrderActiveTab,
  selectedOrder,
  setSelectedOrder,
  unassignedOrders,
  assignedOrders,
  completedOrders,
  setUnassignedOrders,
  setAssignedOrders,
  setCompletedOrders,
  map,
  startDate,
  endDate,
  searchValue,
  setSearchValue,
  branchMarkers,
  selectedBranchId,
  hasCreateOrderBtn
}) {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [orderUpdateIsOpen, setOrderUpdateIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onSelectOrder = useCallback(order => {
    setSelectedOrder(order);
    setIsOpen(true);
    setSelectedOrderActiveTab('information');

    if (mapIntegration.value === 'google') {
      map.setCenter(order.address.location);
      map.setZoom(15);
    }
    if (mapIntegration.value === 'open_street') {
      map.getView().setCenter(fromLonLat([order.address.location.lng, order.address.location.lat]));
      map.getView().setZoom(15);
    }
  }, [setSelectedOrder, setSelectedOrderActiveTab, map, mapIntegration]);
  const onDeselectOrder = useCallback(() => {
    setSelectedOrder(null);
    setActiveTab(activeTab)
    setIsOpen(false);

    if (mapIntegration.value === 'google') {
      map.setZoom(12);
    }
    if (mapIntegration.value === 'open_street') {
      map.getView().setZoom(12);
    }

    navigate(`/#order-info-${selectedOrder.id}`);
  }, [setSelectedOrder, setActiveTab, activeTab, map, selectedOrder, navigate, mapIntegration.value]);
  const onSearchOrder = useCallback(() => {
    setUnassignedOrders({
      ...unassignedOrders,
      loading: true,
      list: null
    });
    setAssignedOrders({
      ...assignedOrders,
      loading: true,
      list: null
    });
    setCompletedOrders({
      ...completedOrders,
      loading: true,
      list: null
    });
  }, [unassignedOrders, assignedOrders, completedOrders, setUnassignedOrders, setAssignedOrders, setCompletedOrders])
  const onRefreshOrders = useCallback(() => {
    setUnassignedOrders({
      ...unassignedOrders,
      loading: true,
      list: null
    });
    setAssignedOrders({
      ...assignedOrders,
      loading: true,
      list: null
    });
    setCompletedOrders({
      ...completedOrders,
      loading: true,
      list: null
    });
  }, [setUnassignedOrders, setAssignedOrders, setCompletedOrders, unassignedOrders, assignedOrders, completedOrders]);
  const onCloseUpdateOrder = useCallback(e => {
    e?.preventDefault();
    setOrderUpdateIsOpen(false);
  }, [setOrderUpdateIsOpen]);

  useEffect(() => {
    if (selectedOrder) {
      setSelectedOrder(selectedOrder);
    }
  }, [selectedOrder, setSelectedOrder]);

  return (
    <div className="orders_modal">
      {!selectedOrder && (
        <div className="orders_modal__search">
          <input
            type="text"
            placeholder={t("pages.dashboard.orders_modal.search")}
            value={searchValue}
            id="search"
            onKeyDown={e => {
              if (e.keyCode === 13) {
                return onSearchOrder();
              }
            }}
            onChange={(e) => {
              if(!e.target.value) {
                onSearchOrder();
              }
              setSearchValue(e.target.value)
              return onSearchOrder()
            }}
          />
          <label htmlFor="search"><BiSearch/></label>
          <ActionBtn
            icon={<FiRefreshCcw/>}
            onClick={onRefreshOrders}
            theme='refresh'
          />
        </div>
      )}
      {!selectedOrder && (
        <div className="orders_modal__tabs">
          <Tab
            title={t("pages.dashboard.orders_modal.unassigned")}
            isActive={activeTab === 'unassigned'}
            onClick={() => setActiveTab('unassigned')}
            count={unassignedOrders.count}
            deliveredCount={0}
            failedCount={0}
            canceledCount={0}
          />
          <Tab
            title={t("pages.dashboard.orders_modal.assigned")}
            isActive={activeTab === 'assigned'}
            onClick={() => setActiveTab('assigned')}
            count={assignedOrders.count}
            deliveredCount={0}
            failedCount={0}
            canceledCount={0}
          />
          <Tab
            title={t("pages.dashboard.orders_modal.completed")}
            isActive={activeTab === 'completed'}
            onClick={() => setActiveTab('completed')}
            count={completedOrders.count}
            deliveredCount={completedOrders.deliveredOrdersCount}
            failedCount={completedOrders.failedOrdersCount}
            canceledCount={completedOrders.canceledOrdersCount}
          />
        </div>
      )}
      {selectedOrder && (
        <OrderDetails
          mapIntegration={mapIntegration}
          setOrderUpdateIsOpen={setOrderUpdateIsOpen}
          selectedOrderActiveTab={selectedOrderActiveTab}
          setSelectedOrderActiveTab={setSelectedOrderActiveTab}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          orderId={selectedOrder.id}
          orderUpdateIsOpen={orderUpdateIsOpen}
          onCloseUpdateOrder={onCloseUpdateOrder}
          onDeselectOrder={onDeselectOrder}
          branchMarkers={branchMarkers}
          startDate={startDate}
          endDate={endDate}
          isOpen={isOpen}
        />
      )}
      {hasCreateOrderBtn && (
        <div className="orders_modal_fixed">
          <button
            className="orders_modal_fixed_btn"
            onClick={() => setIsNewOrderOpen(true)}
          >
            {t("pages.dashboard.createOrder")}
          </button>
        </div>
      )}
      {!selectedOrder && activeTab === 'unassigned' && (
        <OrderList
          activeTab="unassigned"
          orders={unassignedOrders}
          setOrders={setUnassignedOrders}
          onSelectOrder={onSelectOrder}
          startDate={startDate}
          endDate={endDate}
          selectedBranchId={selectedBranchId}
        />
      )}
      {!selectedOrder && activeTab === 'assigned' && (
        <OrderList
          activeTab="assigned"
          orders={assignedOrders}
          setOrders={setAssignedOrders}
          onSelectOrder={onSelectOrder}
          startDate={startDate}
          endDate={endDate}
          selectedBranchId={selectedBranchId}
        />
      )}
      {!selectedOrder && activeTab === 'completed' && (
        <OrderList
          activeTab="completed"
          orders={completedOrders}
          setOrders={setCompletedOrders}
          onSelectOrder={onSelectOrder}
          startDate={startDate}
          endDate={endDate}
          selectedBranchId={selectedBranchId}
        />
      )}
    </div>
  );
}

OrdersModal.propTypes = {
  mapIntegration: PropTypes.object.isRequired,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func.isRequired,
  unassignedOrders: PropTypes.object.isRequired,
  assignedOrders: PropTypes.object.isRequired,
  completedOrders: PropTypes.object.isRequired,
  setUnassignedOrders: PropTypes.func.isRequired,
  setAssignedOrders: PropTypes.func.isRequired,
  setCompletedOrders: PropTypes.func.isRequired,
  map: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  setSelectedOrderActiveTab: PropTypes.func.isRequired,
  selectedOrderActiveTab: PropTypes.string,
  selectedOrder: PropTypes.object,
  setSelectedOrder: PropTypes.func,
  branchMarkers: PropTypes.array,
  selectedBranchId: PropTypes.number.isRequired
};

export default memo(OrdersModal);
