import './index.scss';
import {memo, useState, useCallback, useEffect, useContext} from 'react';
import {BsThreeDotsVertical} from 'react-icons/bs';
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import ContentLoading from "../../../components/ui/loading/content-loading";
import Status from "../../../components/ui/status";
import {AiFillStar} from "react-icons/ai";
import MorePopup from "../../../components/ui/more-popup";
import api from "../../../utils/api";
import {createPortal} from "react-dom";
import PopUp from "../../../components/layout/popup";
import UpdateAgent from "../update-agent";
import PropTypes from 'prop-types';
import Loading from "../../../components/ui/loading";
import UpdateAgentPassword from "../update-agent/update-password-agent";
import sound from "../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import AuthContext from "../../../context/auth/auth-context";
import {errToString} from "../../../utils";

function AgentCard({ agent, loading, setAgents, agents}) {
  const {user} = useContext(AuthContext);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const [isMorePopupOpen, setIsMorePopupOpen] = useState(false);
  const [isFreezeAlertOn, setIsFreezeAlertOn] = useState(false);
  const [isDeleteAlertOn, setIsDeleteAlertOn] = useState(false);
  const [isUnFreezeAlertOn, setIsUnFreezeAlertOn] = useState(false);
  const [isEditProfileOn, setIsEditProfileOn] = useState(false);
  const [isEditPasswordOn, setIsEditPasswordOn] = useState(false);

  const onCancelUnFreezeUser = useCallback((e) => {
    e.stopPropagation();
    setIsMorePopupOpen(false);
    setIsUnFreezeAlertOn(false);
  }, []);
  const onCloseEditAgent = useCallback(e => {
    e?.preventDefault();
    setIsMorePopupOpen(false);
    setIsEditProfileOn(false);
    setIsEditPasswordOn(false);
  }, []);
  const onCancelFreezeUser = useCallback((e) => {
    e.stopPropagation();
    setIsMorePopupOpen(false);
    setIsFreezeAlertOn(false);
  }, []);
  const onCancelDeleteUser = useCallback((e) => {
    e.stopPropagation();
    setIsMorePopupOpen(false);
    setIsDeleteAlertOn(false);
  }, []);
  const onFreezeUser = useCallback((e) => {
    e.stopPropagation();

    api
      .delete(`/employees/agents/${agent.id}`)
      .then(() => {
        setAgents(agents.map((data) => {
          if (agent.id === data.id) {
            return {
              ...data,
              deletedAt: new Date()
            }
          }
          return data;
        }));
        setIsMorePopupOpen(false);
        setIsFreezeAlertOn(false);

        const audio = new Audio(sound);
        const info = `${t("pages.agents.agent")} ${t("pages.agents.hasBeenDeleted")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch((err) => console.log(errToString(err)))
  }, [t, agent, agents, setAgents]);
  const onDeleteUser = useCallback((e) => {
    e.stopPropagation();

    api
      .put(`/employees/agents/${agent.id}`, {
        isHidden: true
      })
      .then(() => {
        setAgents(agents.filter(data => data.id !== agent.id));
        setIsMorePopupOpen(false);
        setIsFreezeAlertOn(false);

        const audio = new Audio(sound);
        const info = `${t("pages.agents.agent_card.agent")} ${agent.username} ${t("pages.agents.agent_card.hasBeenFrozen")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch((err) => console.log(errToString(err)))
  }, [t, agent, agents, setAgents]);
  const onUnFreezeUser = useCallback((e) => {
    e.stopPropagation();

    api
      .put(`/employees/agents/${agent.id}`, {
        restore: true
      })
      .then(() => {
        setIsUnFreezeAlertOn(false);
        setIsMorePopupOpen(false);
        setAgents(agents.map((data) => {
          if (agent.id === data.id) {
            return {
              ...data,
              deletedAt: null
            }
          }
          return data;
        }));

        const audio = new Audio(sound);
        const info = `${t("pages.agents.agent_card.agent")} ${agent.username} ${t("pages.agents.agent_card.hasBeenUnfrozen")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch((err) => console.log(errToString(err)))
  }, [t, agent, agents, setAgents]);
  const onShowMore = useCallback(e => {
    e.stopPropagation();
    setIsMorePopupOpen(!isMorePopupOpen);
  }, [isMorePopupOpen]);

  useEffect(() => {
    if (isEditProfileOn || isEditPasswordOn) {
      api
        .get(`/employees/agents/${agent.id}?freezed`)
        .then(({ data }) => {
          setData(data);
        })
        .catch((err) => console.log(errToString(err)))
    }
  }, [agent.id, isEditProfileOn, isEditPasswordOn]);

  return (
    <>
      {!loading && (
        <div className="agent_card">
          {(user.role.permissions.includes("update_agent") || user.role.permissions.includes("delete_agent")) && (
            <div
              className="agent_card_more"
              onClick={onShowMore}
            >
              <BsThreeDotsVertical />
              {isMorePopupOpen && (
                <MorePopup
                  id={agent.id}
                  selectedUser={agent}
                  setIsMorePopupOpen={setIsMorePopupOpen}
                  setIsFreezeAlertOn={setIsFreezeAlertOn}
                  setIsEditProfileOn={setIsEditProfileOn}
                  setIsEditPasswordOn={setIsEditPasswordOn}
                  setIsUnFreezeAlertOn={setIsUnFreezeAlertOn}
                  setIsDeleteAlertOn={setIsDeleteAlertOn}
                />
              )}
            </div>
          )}
          <span
            className={cn("agent_card_status", {"agent_card_status--online" : agent?.isOnline, "agent_card_status--offline" : !agent?.isOnline})}
          />
          <div className="agent_card_img">
            <img src={agent.src} alt="agent"/>
          </div>
          <div className="agent_card_name">
            {agent.firstName} {agent.lastName}
          </div>
          <div className="agent_card_username">
            {agent.username}
          </div>
          {agent.deletedAt && (
            <div className="agent_card_state">
              <Status type='table' status={"freezed"}/>
            </div>
          )}
          {!agent.deletedAt && (
            <div className="agent_card_rating">
              <AiFillStar/>
              {agent.averageRating && Number(agent.averageRating).toFixed(2)}
            </div>
          )}
          <div className="agent_card_info">
            <p>{agent.phoneNumber}</p>
            {agent.vehicleType === "car" && (
              <p>{agent.carModel} {agent.carBrand} {agent.carPlate}</p>
            )}
            {agent.vehicleType === "bike" && (
              <p>{t(`pages.trackingLink.bike`)}</p>
            )}
            {agent.vehicleType === "truck" && (
              <p>{t(`pages.trackingLink.truck`)}</p>
            )}
            {agent.vehicleType === "bicycle" && (
              <p>{t(`pages.trackingLink.bicycle`)}</p>
            )}
            <p>{agent.device}</p>
          </div>
          {user.role.permissions.includes("see_agent_details") && (
            <button
              className="agent_card_btn"
              onClick={() => navigate(`/agents/details/${agent.id}`)}
            >{t("pages.agents.agent_card.seeOrders")}</button>
          )}
        </div>
      )}
      {loading && (
        <div className="agent_card_loading">
          <ContentLoading type="card" />
        </div>
      )}
      {isEditProfileOn && data && (
        <PopUp>
          <UpdateAgent
            agent={data}
            onClose={onCloseEditAgent}
            setAgentsData={setAgents}
            agentsData={agents}
            setAgent={setData}
          />
        </PopUp>
      )}
      {isEditPasswordOn && data && (
        <PopUp>
          <UpdateAgentPassword
            agent={data}
            onClose={onCloseEditAgent}
          />
        </PopUp>
      )}
      {isFreezeAlertOn && createPortal(
        <>
          <div
            className="agents_in_freeze_alert"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="agents_in_freeze_alert_title">{t("pages.agents.agent_card.warningFreeze")} {agent.firstName} {agent.lastName} ?</div>
            <div className="agents_in_freeze_alert_actions">
              <button
                className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_cancel"
                onClick={onCancelFreezeUser}
              >{t("pages.agents.agent_card.cancel")}</button>
              <button
                className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_submit agents_in_freeze_alert_btn_submit--freeze"
                onClick={onFreezeUser}
              >{t("pages.agents.agent_card.freeze")}</button>
            </div>
          </div>
          <div
            className="agents_in_freeze_alert_overlay"
            onClick={onCancelFreezeUser}
          ></div>
        </>, document.getElementById('modal')
      )}
      {isDeleteAlertOn && createPortal(
        <>
          <div
            className="agents_in_freeze_alert"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="agents_in_freeze_alert_title">{t("pages.agents.agent_card.warningDelete")} {agent.firstName} {agent.lastName} ?</div>
            <div className="agents_in_freeze_alert_actions">
              <button
                className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_cancel"
                onClick={onCancelDeleteUser}
              >{t("pages.agents.agent_card.cancel")}</button>
              <button
                className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_submit agents_in_freeze_alert_btn_submit--freeze"
                onClick={onDeleteUser}
              >{t("pages.agents.delete")}</button>
            </div>
          </div>
          <div
            className="agents_in_freeze_alert_overlay"
            onClick={onCancelDeleteUser}
          ></div>
        </>, document.getElementById('modal')
      )}
      {isUnFreezeAlertOn && createPortal(
        <>
          <div
            className="agents_in_freeze_alert"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="agents_in_freeze_alert_title">{t("pages.agents.agent_card.warningUnFreeze")} {agent.firstName} {agent.lastName} ?</div>
            <div className="agents_in_freeze_alert_actions">
              <button
                className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_cancel"
                onClick={onCancelUnFreezeUser}
              >{t("pages.agents.agent_card.cancel")}</button>
              <button
                className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_submit agents_in_freeze_alert_btn_submit--unfreeze"
                onClick={onUnFreezeUser}
              >{t("pages.agents.agent_card.unFreeze")}</button>
            </div>
          </div>
          <div
            className="agents_in_freeze_alert_overlay"
            onClick={onCancelUnFreezeUser}
          ></div>
        </>, document.getElementById('modal')
      )}
      {(isEditProfileOn || isEditPasswordOn) && !data && createPortal(
        <>
          <div className="agents_loading_wrapper">
            <Loading/>
          </div>
          <div className="agents_loading_wrapper_overlay"></div>
        </>, document.getElementById('modal')
      )}
    </>
  );
}

AgentCard.propTypes = {
  agent: PropTypes.object,
  loading:PropTypes.bool,
  setAgents: PropTypes.func,
  agents: PropTypes.array
};

export default memo(AgentCard);
