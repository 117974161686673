import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import Loading from '../../ui/loading';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import {OSM} from 'ol/source';
import {fromLonLat} from 'ol/proj';
import Marker, {resizeImage} from './marker';
import VectorLayer from "ol/layer/Vector";

VectorLayer.prototype.getFeature = async function() {
  const source = await this.getSource();
  const feature = await source.getFeatures();

  return feature[0];
};

Map.prototype.getMarkers = async function() {
// .map(async vectorLayer => await vectorLayer.getFeature()
  const layers = this.getLayers().getArray().filter(marker => marker instanceof VectorLayer);
  const markers = [];

  for (const layer of layers) {
    const feature = await layer.getFeature();
    markers.push(feature);
  }

  return markers;
};

function OpenStreetMap({ zoom, center, map, setMap, markers = [], isMarkerDraggable }) {
  const ref = useRef();
  const [loading, setLoading] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [mapMarkers, setMapMarkers] = useState([]);

  const onSingleClick = useCallback(e => {
    map.forEachFeatureAtPixel(e.pixel, feature => {
      const {onClick} = feature.getProperties();

      if (typeof onClick === 'function') {
        onClick();
      }
    });
  }, [map]);
  const onPointerMove = useCallback(e => {
    document.body.style.cursor = 'default';

    const overlays = map.getOverlays().getArray();
    overlays.forEach(overlay => {
      const properties = overlay.getProperties();

      if (!properties.open) {
        overlay.setPosition(null);
      }
    });

    map.forEachFeatureAtPixel(e.pixel, feature => {
      document.body.style.cursor = 'pointer';

      const {id, type} = feature.getProperties();
      const overlay = overlays.find(overlay => {
        const properties = overlay.getProperties();
        return properties.id === id && properties.type === type;
      });

      overlays.forEach(overlay => {
        const properties = overlay.getProperties();

        if (!properties.open) {
          overlay.setPosition(null);
        }
      });
      overlay?.setPosition(feature.getProperties().geometry.flatCoordinates);
    });
  }, [map]);

  useEffect(() => {
    const mapEl = ref.current;
    const _map = new Map({
      target: ref.current,
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      view: new View({
        center: fromLonLat([center.lng, center.lat]),
        zoom
      }),
      controls: []
    });
    window.map = _map; // TODO

    mapEl.onmousedown = () => setIsDragging(true);
    mapEl.onmouseup = () => setIsDragging(false);

    setMap(_map);
    setLoading(false);

    return () => {
      mapEl.onmousedown = null;
      mapEl.onmouseup = null;
    }

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!map) {
      return;
    }

    map.on('singleclick', onSingleClick);
    map.on('pointermove', onPointerMove);

    return () => {
      map.un('singleclick', onSingleClick);
      map.un('pointermove', onPointerMove);
    };
  }, [map, markers, onSingleClick, onPointerMove]);
  useEffect(() => {
    const promises = [];

    for (const marker of markers) {
      if (marker.type === 'agent') {
        promises.push(new Promise(resolve => resizeImage(marker.img, marker.imgSize[0], marker.imgSize[1], marker.status, img => resolve({
          ...marker,
          img
        }))));
      } else {
        promises.push(Promise.resolve(marker));
      }
    }

    Promise
        .all(promises)
        .then(mapMarkers => setMapMarkers(mapMarkers));
  }, [markers]);

  return (
    <>
      <div
        className="map"
        ref={ref}
      >
        {!loading && map && mapMarkers.map(marker => (
          <Marker
            key={`${marker.type}-${marker.id}`}
            map={map}
            options={marker}
            isMapDragging={isDragging}
            isDraggable={isMarkerDraggable}
          />
        ))}
      </div>
      {loading && (
        <div className="map__loading">
          <Loading />
        </div>
      )}
    </>
  );
}

OpenStreetMap.propTypes = {
  zoom: PropTypes.number.isRequired,
  center: PropTypes.object.isRequired,
  setMap: PropTypes.func.isRequired,
  map: PropTypes.object,
  markers: PropTypes.array,
  isMarkerDraggable: PropTypes.bool
};

export default memo(OpenStreetMap);
