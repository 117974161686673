import "./index.scss";
import {memo, useState, useMemo, useCallback, useRef, useEffect} from "react";
import PageHeader from "../../../../components/layout/page-header";
import Table from "../../../../components/table";
import {BsThreeDotsVertical} from "react-icons/bs";
import EditNotificationsForm from "./edit-notifications-form";
import Overlay from "../../../../components/ui/overlay";
import api from "../../../../utils/api";
import Loading from "../../../../components/ui/loading";
import Status from "../../../../components/ui/status";
import {useTranslation} from "react-i18next";
import {errToString} from "../../../../utils";

function SettingsCompanyNotifications() {
  const {t} = useTranslation();
  const editNotificationsRef = useRef({});
  const [agentTemplates, setAgentTemplates] = useState(null);
  const [partnerTemplates, setPartnerTemplates] = useState(null);
  const [error, setError] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditAgentNotificationsFormOpen, setIsEditAgentNotificationsFormOpen] = useState(false);
  const [isEditPartnerNotificationsFormOpen, setIsEditPartnerNotificationsFormOpen] = useState(false);
  const notificationsTitles = useMemo(() => [
    {
      name: t("pages.settings.status")
    },
    {
      name: t("pages.settings.sms")
    },
    {size: 50}
  ], [t]);
  const onChangeSmsActivation = useCallback((id, index, checked) => {

    setError(null);

    api
      .put(`/company/templates/${id}`, {
        isActive: checked
      })
      .then((res) => {

        setAgentTemplates(agentTemplates.map(obj => {
          if(obj.id === res.data.id) {
            return res.data
          }
          return obj;
        }))
        setPartnerTemplates(partnerTemplates.map(obj => {
          if(obj.id === res.data.id) {
            return res.data
          }
          return obj;
        }))
      })
      .catch(err => {
        setError(errToString(err));
        console.log(errToString(err));
      })
  }, [setPartnerTemplates, partnerTemplates, setAgentTemplates, agentTemplates]);
  const onEditAgentNotifications = useCallback((e, status) => {
    setIsEditAgentNotificationsFormOpen(true);
    setSelectedStatus(status);
  }, []);
  const onEditPartnerNotifications = useCallback((e, status) => {
    setIsEditPartnerNotificationsFormOpen(true);
    setSelectedStatus(status);
  }, []);
  const onCancelNotificationsForm = useCallback(() => {
    setIsEditPartnerNotificationsFormOpen(false);
    setIsEditAgentNotificationsFormOpen(false);
  }, []);

  useEffect(() => {
    api
      .get("/company/templates")
      .then((res) => {
        setAgentTemplates(res.data.filter(status => {
          return (
            status.name !== "create_employee_sms_template" &&
            status.name !== "order_created_sms_template" &&
            status.name !== "order_unassigned_sms_template" &&
            status.name !== "order_assigned_sms_template" &&
            status.name !== "recover_password_sms_template" &&
            status.name !== "order_delivered_by_partner_sms_template"
          )
        }));
        setPartnerTemplates(res.data.filter(status => {
          return status.name === "order_delivered_by_partner_sms_template"
        }));
        setLoading(false);
      })
      .catch((err) => console.log(errToString(err)))
  }, []);

  return (
    <div className="settings_company_notifications">
      <PageHeader>
        <p>{t("pages.settings.notifications")}</p>
      </PageHeader>
      <div className="settings_company_notifications_in">
        <h2 className="settings_company_notifications_in__title">
          {t("pages.settings.agentNotifications")}
        </h2>
        <p className="settings_company_notifications_in__descrip">
          {t("pages.settings.notificationsDescription")}
        </p>
        <div className="settings_company_notifications_in_options">
          {!loading && (
            <Table
              titles={notificationsTitles}
              loading={false}
              hiddenFields={['id']}
              rows={agentTemplates?.map((template, index) => ({
                id: template.id,
                status: (
                  <Status type="table" status={template.name}/>
                ),
                sms: (
                  <div className="settings_company_notifications_in_options_switcher">
                    <input
                      type="checkbox"
                      onChange={() => onChangeSmsActivation(template.id, index, !template.isActive)}
                      checked={template.isActive}
                    />
                  </div>
                ),
                more: (
                  <div
                    ref={el => editNotificationsRef.current[template.id] = el}
                    onClick={e => onEditAgentNotifications(e, template)}
                  >
                    <BsThreeDotsVertical/>
                  </div>
                )
              })) || []}
            />
          )}
          {error && (
            <p className="settings_company_notifications_in_options_error">{t(`errors.${error?.toLowerCase()}`)}</p>
          )}
          {loading && (
            <div className="settings_company_notifications_in_options settings_company_notifications_in_options_loading">
              <Loading/>
            </div>
          )}
        </div>
      </div>
      <div className="settings_company_notifications_in">
        <h2 className="settings_company_notifications_in__title">
          {t("pages.settings.partnerNotifications")}
        </h2>
        <p className="settings_company_notifications_in__descrip">
          {t("pages.settings.notificationsDescription")}
        </p>
        <div className="settings_company_notifications_in_options">
          {!loading && (
            <Table
              titles={notificationsTitles}
              loading={false}
              hiddenFields={['id']}
              rows={partnerTemplates?.map((template, index) => ({
                id: template.id,
                status: (
                  <Status type="table" status={template.name}/>
                ),
                sms: (
                  <div className="settings_company_notifications_in_options_switcher">
                    <input
                      type="checkbox"
                      onChange={() => onChangeSmsActivation(template.id, index, !template.isActive)}
                      checked={template.isActive}
                    />
                  </div>
                ),
                more: (
                  <div
                    ref={el => editNotificationsRef.current[template.id] = el}
                    onClick={e => onEditPartnerNotifications(e, template)}
                  >
                    <BsThreeDotsVertical/>
                  </div>
                )
              })) || []}
            />
          )}
        </div>
      </div>
      <div className="settings_company_notifications_footer"/>
      {isEditAgentNotificationsFormOpen && (
        <>
          <EditNotificationsForm
            id={selectedStatus.id}
            name={selectedStatus.name}
            onCancel={onCancelNotificationsForm}
            format={selectedStatus.format}
            templates={agentTemplates}
            setTemplates={setAgentTemplates}
            type={"agent"}
          />
          <Overlay onClick={onCancelNotificationsForm}/>
        </>
      )}
      {isEditPartnerNotificationsFormOpen && (
        <>
          <EditNotificationsForm
            type={"partner"}
            id={selectedStatus.id}
            name={selectedStatus.name}
            onCancel={onCancelNotificationsForm}
            format={selectedStatus.format}
            templates={partnerTemplates}
            setTemplates={setPartnerTemplates}
          />
          <Overlay onClick={onCancelNotificationsForm}/>
        </>
      )}
    </div>
  );
}

export default memo(SettingsCompanyNotifications);
