import "./index.scss";
import {memo, useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import Logo from "../../assets/logo.png"
import {useTranslation} from "react-i18next";
import {errToString} from "../../utils";
import api from "../../utils/api";
import Loading from "../ui/loading";
import { IoMdCloudDone } from "react-icons/io";
import { RiErrorWarningFill } from "react-icons/ri";
import cn from "classnames";

const Export = ({type}) => {
  const {pathname} = useLocation();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const route = useMemo(() => pathname.split('/'), [pathname]);
  useEffect(() => {
    if (!type) {
      return
    }
    if (!route) {
      return
    }

    setLoading(true);

    if (type === "orders") {
      if (route[2] === "orders") {
        api
          .get(`/orders/export?from=${route[4]}&to=${route[6]}`, {
            responseType: 'blob'
          })
          .then(res => {
            const filename = res.headers['content-disposition'].split('filename=')[1].replace('.xlsx', '').replaceAll('"', '');
            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
            setSuccess(true);
            console.log("Successfully exported!")
          })
          .catch(err => {
            setLoading(false);
            setSuccess(false);
            console.log(errToString(err));
          })
      }
    }
    if (type === "customers") {
      api
        .get(`/customers/export`, {
          responseType: 'blob'
        })
        .then(res => {
          const filename = res.headers['content-disposition'].split('filename=')[1].replace('.xlsx', '').replaceAll('"', '');
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
          setLoading(false);
          setSuccess(true);
          console.log("Successfully exported!")
        })
        .catch(err => {
          setLoading(false);
          setSuccess(false);
          console.log(errToString(err));
        })
    }

  }, [route, type]);

  return (
    <div className="export">
      <div className="export_in">
        <img src={Logo} alt="taskroad.pro"/>
        <h2>{t("general.exportInfo")}</h2>
        {loading && (
          <div className="export_in_loading">
            <p className="export_in_wrapper_txt">{t("general.dataRequest")}</p>
            <span><Loading /></span>
          </div>
        )}
        {!loading && (
          <div className={cn("export_in_wrapper", {"export_in_wrapper--success" : success}, {"export_in_wrapper--fail" : !success})}>
            <p className="export_in_wrapper_txt">{success ? t("general.completed") : t("general.failed")}</p>
            {success ? <IoMdCloudDone/> : <RiErrorWarningFill />}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(Export);