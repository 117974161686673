import "./index.scss";
import React, {memo, useCallback, useContext, useEffect, useMemo, useState} from "react";
import PageHeader from "../../../components/layout/page-header";
import {useTranslation} from "react-i18next";
import Table from "../../../components/table";
import moment from "moment/moment";
import cn from "classnames";
import Loading from "../../../components/ui/loading";
import AuthContext from "../../../context/auth/auth-context";
import api from "../../../utils/api";
import {errToString} from "../../../utils";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import BillingModal from "../billing-products-modal";
import VisaSvg from "../../../assets/visa.svg";
import MasterSvg from "../../../assets/mastercard.svg";
import AmexSvg from "../../../assets/amex.svg";
import DiscoverSvg from "../../../assets/discover.svg";
import JcbSvg from "../../../assets/jcb.svg";
import AnalyticsInfo from "../../../components/ui/analytics-info";
import {createPortal} from "react-dom";
import AddCardForm from "../stripe-form/add-card-form";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function AccountSubscriptions() {
  const {user} = useContext(AuthContext);
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [productsMonthly, setProductsMonthly] = useState(null);
  const [productsYearly, setProductsYearly] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [changePlanIsOpen, setChangePlanIsOpen] = useState(false);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [tasks, setTasks] = useState(null);
  const [pending, setPending] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState("yearly");
  const [isCancelAlertOn, setIsCancelAlertOn] = useState(false);

  const stripeCustomerId = useMemo(() => user.company.stripeCustomerId, [user.company]);
  const subscriptionPlanTitles = useMemo(() => [
    {
      name: t("pages.billing.planName"),
      size: 200
    },
    {
      name: t("pages.billing.planCreated"),
      size: 150
    },
    {
      name: t("pages.billing.planExpires"),
      size: 150
    },
    {
      name: t("pages.billing.paymentMethod"),
      size: 200
    },
    {
      size: 100
    },
    {
    }
  ], [t]);
  const freeTrialTitles = useMemo(() => [
    {
      name: t("pages.billing.planName"),
      size: 200
    },
    {
      name: t("pages.billing.planCreated"),
      size: 150
    },
    {
      name: t("pages.billing.planExpires"),
      size: 150
    },
    {
      size: 100
    },
    {

    }
  ], [t]);

  const freeTrialPlan = useMemo(() => {
    return {
      id: 'free-trial',
      name: 'Free Trial',
      createdAt: user.createdAt,
      endsAt: subscriptions?.freeTrialEndsAt || null
    };
  }, [user, subscriptions?.freeTrialEndsAt]);

  const selectProduct = useCallback(async productId => {
    api
        .post('/subscriptions', { productId: productId })
        .then(() => window.location.reload())
        .catch((err) => {
          console.log(errToString(err));
          alert(errToString(err));
          setPending(false);
        });
  }, []);
  const onCancel = useCallback(() => {
    api
      .delete('/subscriptions')
      .then(() => window.location.reload());
  }, []);
  const onSelectSubscription = useCallback(async productId => {
    if (pending) {
      return;
    }

    setPending(true);

    if (!stripeCustomerId || !defaultPaymentMethod) {
      setSelectedProductId(productId);
      setPending(false);
    } else {
      await selectProduct(productId);
    }
  }, [stripeCustomerId, defaultPaymentMethod, selectProduct, pending]);

  useEffect(() => {
    api
        .get('/subscriptions/methods')
        .then(({ data }) => setDefaultPaymentMethod(data.default))
        .catch(err => console.log(errToString(err)));

    api
        .get('/orders/usage')
        .then(({ data }) => {
          setTasksLoading(false);
          setTasks({
            created: data.created,
            limit: data.limit,
            remaining: data.remaining,
            extra: data.extra
          });
        })
        .catch(err => console.log(errToString(err)));
  }, []);
  useEffect(() => {
    setLoading(true);

    const promises = [];
    promises.push(api.get(`/products?type=monthly`));
    promises.push(api.get(`/products?type=yearly`));

    api
      .get('/subscriptions')
      .then(res => setSubscriptions(res.data))
      .catch(err => setSubscriptions(err));

    Promise.all(promises)
        .then(results => {
          setProductsYearly(results[1].data);
          return setProductsMonthly(results[0].data);
        })
        .catch(err => {
          console.log('catch')
          console.log(errToString(err));
        })
        .finally(() => setLoading(false));

    if (!stripeCustomerId) {
      return setSubscriptions([]);
    }
  }, [stripeCustomerId]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="subscriptions">
      <PageHeader>
        <p>{t("pages.billing.subscriptions")}</p>
        {windowWidth > 600 && (
          <button
            className="subscriptions_btn"
            onClick={() => setChangePlanIsOpen(true)}
          >{t("pages.billing.seePlan")}</button>
        )}
      </PageHeader>
      <div className="subscriptions_in">
        {(loading || subscriptions === null) && (
          <div className="subscriptions_in_center">
            <Loading />
          </div>
        )}
        {!loading && productsMonthly && productsYearly && (
          <>
            {subscriptions?.freeTrialEndsAt && !subscriptions?.subscription && (
              <Table
                titles={freeTrialTitles}
                loading={false}
                hiddenFields={['id']}
                rows={[freeTrialPlan].map(subscription => ({
                  id: subscription.id,
                  subscription: (
                    <button className="subscriptions_in_status subscriptions_in_status--plan">{subscription.name}</button>
                  ),
                  created: (
                    <p>{moment(subscription.createdAt).format('DD-MM-YYYY')}</p>
                  ),
                  trialExpires: (
                    <p>{moment(subscription.endsAt).format('DD-MM-YYYY')}</p>
                  ),
                  status: (
                    <div className="subscriptions_in_status subscriptions_in_status--active">{t("pages.billing.active")}</div>
                  )
                })) || []}
              />
            )}
            {subscriptions !== null && !subscriptions?.subscription && (
              <>
                <PageHeader>
                  <p>{t("pages.billing.noSubscription")}</p>
                </PageHeader>
                <button
                  className="subscriptions_btn subscriptions_btn--bg"
                  onClick={() => setChangePlanIsOpen(true)}
                >{t("pages.billing.choosePlan")}</button>
              </>
            )}
            {subscriptions?.subscription && (
              <Table
                titles={subscriptionPlanTitles}
                loading={false}
                hiddenFields={['id']}
                rows={[subscriptions.subscription].map(subscription => ({
                  id: subscription.id,
                  subscription: (
                    <button className="subscriptions_in_status subscriptions_in_status--plan">{subscription.product.name}</button>
                  ),
                  created: (
                    <p>{moment(subscription.startsAt).format('DD-MM-YYYY')}</p>
                  ),
                  trialExpires: (
                    <p>{moment(subscription.endsAt).format('DD-MM-YYYY')}</p>
                  ),
                  payment: (
                    <div className="subscriptions_in_payment">
                      {(subscription.card.brand !== "visa" && subscription.card.brand !== "mastercard" && subscription.card.brand !== "amex" && subscription.card.brand !== "discover" && subscription.card.brand !== "jcb" ) && (
                        <div className="subscriptions_in_payment_name">{subscription.card.brand}</div>
                      )}
                      {subscription.card.brand === "visa" && (
                        <div className="subscriptions_in_payment_img">
                          <img src={VisaSvg} alt="visa"/>
                        </div>
                      )}
                      {subscription.card.brand === "mastercard" && (
                        <div className="subscriptions_in_payment_img">
                          <img src={MasterSvg} alt="mastercard"/>
                        </div>
                      )}
                      {subscription.card.brand === "amex" && (
                        <div className="subscriptions_in_payment_img">
                          <img src={AmexSvg} alt="amex"/>
                        </div>
                      )}
                      {subscription.card.brand === "discover" && (
                        <div className="subscriptions_in_payment_img">
                          <img src={DiscoverSvg} alt="discover"/>
                        </div>
                      )}
                      {subscription.card.brand === "jcb" && (
                        <div className="subscriptions_in_payment_img">
                          <img src={JcbSvg} alt="jcb"/>
                        </div>
                      )}
                      <div className="subscriptions_in_payment_name">****{subscription.card.last4}  {subscription.card.expMonth.toString().length === 1 ? `0${subscription.card.expMonth}` : subscription.card.expMonth}/{subscription.card.expYear}</div>
                    </div>
                  ),
                  status: (
                    <div
                      className={cn('subscriptions_in_status', {
                        'subscriptions_in_status--active': subscription.status === 'active',
                        'subscriptions_in_status--past_due': subscription.status === 'past_due'
                      })}
                    >{t(`pages.billing.${subscription.status}`)}</div>
                  ),
                  cancel: subscription?.status === 'active' && !subscription?.cancelsAt && (
                    <button
                      className="subscriptions_in_status subscriptions_in_status--canceled"
                      onClick={() => setIsCancelAlertOn(true)}
                    >{t("pages.billing.cancelSubscription")}</button>
                  )
                })) || []}
              />
            )}
            {subscriptions?.subscription?.status === 'canceled' && subscriptions?.subscription?.cancelsAt && (
              <div className="subscriptions_cancelsAt">
                <span>{moment(subscriptions?.subscription.cancelsAt).format('DD-MM-YYYY')}</span>
                <p>{t("pages.billing.cancelsAtDescription")}</p>
              </div>
            )}
            {windowWidth < 600 && subscriptions?.subscription && (
              <button
                className="subscriptions_btn"
                onClick={() => setChangePlanIsOpen(true)}
              >{t("pages.billing.seePlan")}</button>
            )}
            {!tasksLoading && subscriptions !== null && (
              <>
                <PageHeader>
                  <p>{t("pages.billing.counter")}</p>
                </PageHeader>
                <div className="agent_details_dashboard">
                  <AnalyticsInfo
                    className="agent_details_dashboard__info"
                    count={tasks.created}
                    title={t("pages.billing.createdTasks")}
                    status={"ongoing"}
                    loading={tasksLoading}
                  />
                  <AnalyticsInfo
                    className="agent_details_dashboard__info"
                    count={tasks.limit}
                    title={t("pages.billing.limitTasks")}
                    status={"pending"}
                    loading={tasksLoading}
                  />
                  <AnalyticsInfo
                    className="agent_details_dashboard__info"
                    count={tasks.remaining}
                    title={t("pages.billing.remainingTasks")}
                    status={"canceled"}
                    loading={tasksLoading}
                  />
                  {subscriptions?.subscription && (
                    <AnalyticsInfo
                      className="agent_details_dashboard__info"
                      count={tasks.extra}
                      title={t("pages.billing.extraTasks")}
                      status={"finished"}
                      loading={tasksLoading}
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
      {isCancelAlertOn && createPortal(
        <>
          <div
            className="agents_in_freeze_alert"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="agents_in_freeze_alert_title">
              {t("pages.billing.cancelsAtDescription")}
            </div>
            <div className="agents_in_freeze_alert_actions">
              <button
                className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_submit agents_in_freeze_alert_btn_submit--freeze"
                onClick={onCancel}
              >{t("pages.billing.cancelRenewalSubscription")}</button>
            </div>
          </div>
          <div
            className="agents_in_freeze_alert_overlay"
            onClick={() => setIsCancelAlertOn(false)}
          ></div>
        </>, document.getElementById('modal')
      )}
      {!loading && selectedProductId && createPortal(
        <Elements stripe={stripePromise}>
          <AddCardForm
              onClose={() => setSelectedProductId(null)}
              onSuccess={() => selectProduct(selectedProductId)}
          />
        </Elements>,
        document.getElementById('modal')
      )}
      {!loading && productsMonthly && productsYearly && changePlanIsOpen && (
        <BillingModal
          subscriptionsMonthly={productsMonthly}
          subscriptionsYearly={productsYearly}
          setChangePlanIsOpen={setChangePlanIsOpen}
          onSelectSubscription={onSelectSubscription}
          pending={pending}
          showButton={!subscriptions?.subscription}
          setSubscriptionType={setSubscriptionType}
          subscriptionType={subscriptionType}
        />
      )}
    </div>
  );
}

export default memo(AccountSubscriptions);
