import './index.scss';
import React, {memo} from 'react';
import cn from "classnames";
import {useTranslation} from "react-i18next";

// const calcRoute = (
//   from,
//   to,
//   distanceUnit,
//   callback
// ) => {
//   api
//     .get(`/ors?start=${from.lng},${from.lat}&end=${to.lng},${to.lat}`)
//     .then(({ data: { features } }) => {
//       const distanceValue = distanceUnit === 'km' ? (features[0].properties.summary.distance / 1000).toFixed(1) : (features[0].properties.summary.distance / 1000 / 1.6).toFixed(1);
//       const distance = `${distanceValue} ${distanceUnit}`;
//       const duration = `${(features[0].properties.summary.duration / 60).toFixed()}`;
//
//       return callback({
//         distance,
//         duration
//       }, 'OK');
//     })
//     .catch(err => {
//       console.log(err.message)
//
//       return callback({
//         distance: 'n/a',
//         duration: 'n/a'
//       }, 'OK');
//     });
// }

function AssignAgentFormItem({
type,
index,
setAssignedPartner,
setAssignedAgent,
setShow,
agentsOption,
partnerOption,
pickupAddressLocation,
busy,
free,
assignedPartner,
assignedAgent
}) {
    const {t} = useTranslation();

    // const {list} = useContext(PreferencesContext);
    // const [route, setRoute] = useState(null);

  //   useEffect(() => {
  //     if (agentsOption) {
  //       if (agentsOption.lastLocation) {
  //         calcRoute(agentsOption.lastLocation, pickupAddressLocation, list.distance_unit.value.unit, ({ distance, duration }, status) => {
  //           return setRoute({
  //             distance,
  //             duration
  //           });
  //         })
  //       } else {
  //         return setRoute(null);
  //       }
  //     }
  // }, [pickupAddressLocation, agentsOption, list])

  return (
    <>
      {type === "agents" && (
        <li
          key={index}
          className={cn("assign_agent_form_item", {"assign_agent_form_item--inactive" : !agentsOption.isOnline}, {"assign_agent_form_item--hovered": agentsOption.id === assignedAgent?.id})}
          onClick={() => {
            setAssignedPartner(null);
            setAssignedAgent(agentsOption);
            setShow(false);
          }}
        >
          <div className="assign_agent_form_item_img">
            <img src={agentsOption.src} alt="driver"/>
          </div>
          <div className="assign_agent_form_item_wrapper">
            <span className={`assign_agent_form_item_status assign_agent_form_item_status--${agentsOption.isOnline ? 'online' : "offline"}`} />
            <span className='assign_agent_form_item_username'>
              {agentsOption.firstName ? `${agentsOption.firstName.charAt(0)}. ${agentsOption.lastName}` : agentsOption.username}
            </span>
            {busy && (
              <span className="assign_agent_form_item_username_status assign_agent_form_item_username_status--busy">
                {t("statuses.busy")}
              </span>
            )}
            {free && (
              <span className="assign_agent_form_item_username_status assign_agent_form_item_username_status--free">
                {t("statuses.free")}
              </span>
            )}
          </div>
          <span className='assign_agent_form_item_tasks assign_agent_form_item_tasks--ongoing'>
            {t("statuses.ongoing")}:
            <span>
                  {agentsOption.orders
                    .filter((order) => (
                      order.status !== "delivered" &&
                      order.status !== "failed" &&
                      order.status !== "canceled" &&
                      order.status !== "unassigned" &&
                      order.status !== "created"
                    )).length
                  }
            </span>
          </span>
          <span className='assign_agent_form_item_tasks assign_agent_form_item_tasks--completed'>
            {t("statuses.delivered")}:
            <span>
              {agentsOption.orders.filter((order) => order.status === "delivered").length}
            </span>
          </span>
          {/*{route && (*/}
          {/*  <span className='assign_agent_form_item_distance'>*/}
          {/*   {t("general.fromPickup")}:*/}
          {/*    <span>*/}
          {/*      {`${route.distance} ~ ${route.duration} ${t("general.min")}`}*/}
          {/*    </span>*/}
          {/*    {agentsOption.lastActivity !== null && (*/}
          {/*      <span*/}
          {/*        className="assign_agent_form_item_distance--time"*/}
          {/*      >*/}
          {/*        {moment(agentsOption.lastActivity).fromNow()}*/}
          {/*      </span>*/}
          {/*    )}*/}
          {/*</span>*/}
          {/*)}*/}
        </li>
      )}
      {type === "partners" && (
        <li
          key={index}
          className={cn("assign_agent_form_item", {"assign_agent_form_item--hovered": partnerOption.id === assignedPartner?.id})}
          onClick={() => {
            setAssignedPartner(partnerOption);
            setAssignedAgent(null);
            setShow(false);
          }}
        >
          <div className="assign_agent_form_item_img">
            <img src={partnerOption.src} alt="partner"/>
          </div>
          <span className='assign_agent_form_item_name'>{partnerOption.name}</span>
          <span className='assign_agent_form_item_tasks assign_agent_form_item_tasks--ongoing'>
            {t("statuses.ongoing")}:
            <span>
              {partnerOption.orders
                .filter((order) => (
                  order.status !== "delivered" &&
                  order.status !== "failed" &&
                  order.status !== "canceled" &&
                  order.status !== "unassigned" &&
                  order.status !== "created"
                )).length
              }
            </span>
          </span>
          <span className='assign_agent_form_item_tasks assign_agent_form_item_tasks--completed'>
             {t("statuses.delivered")}:
            <span>{partnerOption.orders.filter((order) => order.status === "delivered").length}</span>
          </span>
        </li>
      )}
    </>
  );
}

export default memo(AssignAgentFormItem);
