import "./index.scss";
import React, {memo, useState, useMemo, useEffect, useCallback, useContext} from "react";
import api from "../../../utils/api"
import moment from "moment";
import PageHeader from "../../../components/layout/page-header";
import Container from "../../../components/layout/container";
import Table from "../../../components/table";
import Search from "../../../components/search/index";
import {AiFillStar} from "react-icons/ai";
import Pagination from "../../../components/ui/pagination";
import {LIMITS} from "../../../constants/drop-down-options";
import DateTimePicker from "../../../components/ui/calendar/date-time-picker-double-calendar";
import Status from "../../../components/ui/status";
import {getCurrentDateWithoutHours} from '../../../utils/date';
import {createPortal} from 'react-dom';
import OrderDetails from '../order-details';
import PropTypes from 'prop-types';
import PreferencesContext from "../../../context/preferences/preferences-context";
import cn from "classnames";
import {useTranslation} from 'react-i18next';
import UserCompanyBranchesContext from "../../../context/user-company-branches/user-company-branches-context";
import {errToString} from "../../../utils";
import {BiExport} from "react-icons/bi";
import {Tooltip} from "react-tooltip";

const SEARCH_FIELDS_ORDERS = [
  {label: 'orderId', value: 'externalId'},
  {label: 'agent', value: 'agent'},
  {label: 'amount', value: 'amount'},
  {label: 'address', value: 'address'}
];

function Orders({
mapIntegration
}) {
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const {list} = useContext(PreferencesContext);
  const {t} = useTranslation();
  const [orderUpdateIsOpen, setOrderUpdateIsOpen] = useState(false);
  const [selectedOrderActiveTab, setSelectedOrderActiveTab] = useState("information");
  const [orders, setOrders] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [dateFields, setDateFields] = useState({
    startDate: getCurrentDateWithoutHours(),
    endDate: getCurrentDateWithoutHours(23, 59, 59),
  });
  const [loading, setLoading] = useState(true);

  const ordersTitles = useMemo(() => [
    {
      name: t("pages.dashboard.orders.orderId")
    },
    {
      name: t("pages.dashboard.orders.agent")
    },
    {
      name: t("pages.dashboard.orders.paymentMethod")
    },
    {
      name: t("pages.dashboard.orders.amount")
    },
    {
      name: t("pages.dashboard.orders.customer")
    },
    {
      name: t("pages.dashboard.orders.deliveryAddress"),
      size: 200
    },
    {
      name: t("pages.dashboard.orders.created")
    },
    {
      name: t("pages.dashboard.orders.updated")
    },
    {
      name:t("pages.dashboard.orders.rating")
    },
    {
      name: t("pages.dashboard.orders.review")
    },
    {
      name:t("pages.dashboard.orders.status")
    },
  ], [t]);
  const searchFieldsOrders = useMemo(() => SEARCH_FIELDS_ORDERS.map(option => ({
    ...option,
    label: t(`pages.dashboard.orders.${option.label}`)
  })), [t]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);

  const onExport = useCallback(() => {
    const startDate = moment(dateFields.startDate).utc().format();
    let endDate = moment(dateFields.endDate).utc().format();
    const branchIds = userCompanyBranches?.list.map(({ id }) => id);
    const companyAddressIds = branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : '';

    if (startDate === endDate) {
      endDate = moment(dateFields.endDate.setDate(dateFields.endDate.getDate() + 1)).utc().format();
    }

    return window.open(`/export/orders/from/${startDate}/to/${endDate}/${companyAddressIds}`, "_blank")
  }, [dateFields, userCompanyBranches?.list]);
  const onChangeLimit = useCallback(option => {
    if (option.value === limit.value) {
      return;
    }

    setLimit(option);
    setOffset(0);
    setLoading(true);
    setCurrentPage(0);


  }, [limit]);
  const onSearch = useCallback(({ search, searchField }) => {
    const branchIds = userCompanyBranches?.list.map(({ id }) => id);
    const companyAddressIds = branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : '';

    setLoading(true);

    api
      .get(`/orders?offset=${offset}&limit=${limit.value}${search && searchField ? `&search=${search}&searchField=${searchField}` : ''}${companyAddressIds}`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setOrders(res.data.results);
        setLoading(false);
      })
      .catch(err => {
        console.log(errToString(err));
        setLoading(false);
      })
  }, [offset, limit, userCompanyBranches?.list]);
  const onCloseUpdateOrder = useCallback(e => {
    e?.preventDefault();
    setOrderUpdateIsOpen(false);
  }, [setOrderUpdateIsOpen]);
  const onDeselectOrder = useCallback(() => {
    setSelectedOrderId(null);
    setOrderUpdateIsOpen(false);
  }, []);

  useEffect(() => {
    const startDate = moment(dateFields.startDate).utc().format();
    let endDate = moment(dateFields.endDate).utc().format();
    const branchIds = userCompanyBranches?.list.map(({ id }) => id);
    const companyAddressIds = branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : '';

    if (startDate === endDate) {
      endDate = moment(dateFields.endDate.setDate(dateFields.endDate.getDate() + 1)).utc().format();
    }

    api
      .get(`/orders?offset=${offset}&limit=${limit.value}&from=${startDate}&to=${endDate}${companyAddressIds}`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setOrders(res.data.results);
        setLoading(false);
      })
      .catch(err => console.log(errToString(err)))
  }, [offset, limit, dateFields, userCompanyBranches?.list]);

  return (
    <Container>
      <div className="orders">
        <PageHeader>
          <p>{t("pages.dashboard.orders.orders")}</p>
        </PageHeader>
        <div className="orders_in_actions">
          <Search
            placeholder={t("pages.dashboard.orders.search")}
            options={searchFieldsOrders}
            onSearch={onSearch}
          />
          <div className='orders_in_actions_calendar'>
            <DateTimePicker
              className="orders_date_time_picker"
              placeholder="DD/MM/YYYY HH:MM - HH:MM"
              enableTimePicker={true}
              position="bottom"
              theme='orders'
              enableRangePicker={true}
              value={[dateFields.startDate, dateFields.endDate]}
              onChange={e => {
                setDateFields(e);
                setOffset(0);
              }}
            />
          </div>
          <div
            className="billing_history_in_actions_export"
            data-tooltip-id={`tooltip-export-invoices`}
            onClick={onExport}
          >
            <BiExport />
            <Tooltip
              id={`tooltip-export-invoices`}
              place="top"
              className="tooltipClass"
            >{t("pages.dashboard.orders.exportOrders")}</Tooltip>
          </div>
        </div>
        <div className="orders_in">
          <Table
            titles={ordersTitles}
            hiddenFields={['id']}
            rows={orders && orders.map(order => ({
              id: order.id,
              orderId: <p>{`#${order.externalId}`}</p>,
              agent: (
                <p>{order.agent ? `${order.agent.firstName} ${order.agent.lastName} ${order.agent.phoneNumber}` : order.partner ? `${order.partner.name}` : 'n/a'}</p>
              ),
              paymentMethod: (
                <p>{order.paymentMethod.name.toLowerCase() === "cash" ? t("payment.cash") : order.paymentMethod.name}</p>
              ),
              amount: (
                <p>{`${order.amount}`}</p>
              ),
              customer: (order.customer.firstName && order.customer.lastName ? (
                  <p>{`${order.customer.firstName} ${order.customer.lastName} ${order.customer.phoneNumber}`}</p>
                ) : <p>{`${order.customer.phoneNumber}`}</p>
              ),
              deliveryAddress: (
                <p>{order.address.deliveryAddress}</p>
              ),
              created: (
                <p>{moment(order.createdAt).format(`${list.date_format.value.format}, ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}</p>
              ),
              updated: (
                <p>{moment(order.updatedAt).format(`${list.date_format.value.format}, ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}</p>
              ),
              rating: order.rating ? (
                <div className="table__rating">
                  <AiFillStar/>
                  <p>{order.rating}</p>
                </div>
              ) : <p>-</p>,
              review: (
                <p>{order.review ? order.review : '-'}</p>
              ),
              status: order.status && (
                <Status type='table' status={order.status}/>
              )
            }))}
            loading={loading}
            setRow={setSelectedOrderId}
          />
        </div>
        {!!pagesCount && (
            <Pagination
              pagesCount={pagesCount}
              setOffset={setOffset}
              limit={limit}
              limitPlaceholder={limit.label}
              limits={LIMITS}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onChange={onChangeLimit}
            />
        )}
        {selectedOrderId && createPortal(
          <div className={cn("orders_popup", {"orders_popup--animate": selectedOrderId})}>
            <OrderDetails
              mapIntegration={mapIntegration}
              setOrderUpdateIsOpen={setOrderUpdateIsOpen}
              selectedOrderActiveTab={selectedOrderActiveTab}
              setSelectedOrderActiveTab={setSelectedOrderActiveTab}
              orderId={selectedOrderId}
              orderUpdateIsOpen={orderUpdateIsOpen}
              onCloseUpdateOrder={onCloseUpdateOrder}
              onDeselectOrder={onDeselectOrder}
              startDate={dateFields.startDate}
              endDate={dateFields.endDate}
              hasOverlay={true}
            />
          </div>,
          document.getElementById('modal')
        )}
      </div>
    </Container>
  );
}

Orders.propTypes = {
  mapIntegration: PropTypes.object.isRequired
};

export default memo(Orders);
