import "./index.scss";
import {memo, useState, useCallback, useRef, useMemo, useEffect, cloneElement} from "react";
import {BiGitBranch} from "react-icons/bi";
import PropTypes from 'prop-types';
import cn from 'classnames';
import {createPortal} from 'react-dom';
import Loading from '../loading';
import {useTranslation} from "react-i18next";

function DropDownFilter({
  value,
  position = 'bottom',
  className = '',
  disabled,
  options,
  onSearch,
  onChange,
  onReset = () => null,
  onInit = ({ methods }) => null,
  placeholder,
  maxHeight,
  label,
  loading,
  hasHover = true,
  popupLeft,
  width
}) {
  const ref = useRef();
  const {t} = useTranslation();
  const popupRef = useRef();
  const inputRef = useRef();
  const [popupTop, setPopupTop] = useState('-9999px');
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState(value?.label || '');
  const [selected, setSelected] = useState(value);
  const [activeOptionIndex, setActiveOptionIndex] = useState(-1);
  const filteredOptions = useMemo(() => {
    if (search && !onSearch && !selected) {
      return options.filter(option => {
        if (option?.option) {
          return option?.option?.label.toLowerCase().includes(search.toLowerCase());
        }

        return option?.label.toLowerCase().includes(search.toLowerCase());
      });
    }

    return options;
  }, [options, search, onSearch, selected]);
  const onChangeSearch = useCallback(e => {
    setActiveOptionIndex(-1);
    setSearch(e.target.value);
    setSelected(null);
    onSearch && onSearch(e.target.value);
  }, [onSearch]);
  const onSelect = useCallback(option => {
    setSelected(option);
    setSearch(option.label);
    setIsOpen(false);
    onChange(option);
    setActiveOptionIndex(filteredOptions.findIndex(_option => _option.value === option.value));
    inputRef.current.blur();
  }, [onChange, filteredOptions, inputRef]);
  const onDeselect = useCallback(() => {
    setSelected(null);
    setSearch('');
    setIsOpen(false);
    onReset();
    setActiveOptionIndex(-1);
  }, [onReset]);
  const onKeyDown = useCallback(e => {
    if (selected && e.keyCode === 8) {
      onReset();
      setActiveOptionIndex(-1);
      return setSelected(null);
    }

    if (e.keyCode === 9) {
      setIsOpen(false);
    }
    if (e.keyCode === 38) {
      const index = activeOptionIndex > 0 ? activeOptionIndex - 1 : activeOptionIndex;
      setActiveOptionIndex(index);
      setIsOpen(true);
    }
    if (e.keyCode === 40) {
      const index = activeOptionIndex < filteredOptions.length - 1 ? activeOptionIndex + 1 : activeOptionIndex;
      setActiveOptionIndex(index);
      setIsOpen(true);
    }
    if (e.keyCode === 13) {
      if (filteredOptions[activeOptionIndex]?.option) {
        e.preventDefault();
        return onSelect(filteredOptions[activeOptionIndex].option);
      }
      if (filteredOptions[activeOptionIndex]) {
        e.preventDefault();
        return onSelect(filteredOptions[activeOptionIndex]);
      }

      onSelect(filteredOptions[activeOptionIndex]);
    }
    if (e.keyCode === 27) {
      setSearch('');
      setSelected(null);
      setIsOpen(false);
      onReset();
      setActiveOptionIndex(-1);
      document.activeElement.blur();
    }
  }, [activeOptionIndex, filteredOptions, onSelect, onReset, selected]);
  const onCloseMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (ref.current && popupRef.current) {
      if (position === 'top') {
        setPopupTop(ref.current?.getBoundingClientRect().top - popupRef.current?.getBoundingClientRect().height - 5);
      }
      if (position === 'bottom') {
        setPopupTop(ref.current?.getBoundingClientRect().top + ref.current?.getBoundingClientRect().height + 5);
      }
    }
  }, [ref, popupRef, isOpen, position, filteredOptions]);
  useEffect(() => {
    setActiveOptionIndex(filteredOptions.findIndex(option => {
      if (option?.option) {
        return option?.option?.label === selected?.label;
      }

      return option?.label === selected?.label;
    }));
  }, [filteredOptions, selected]);
  useEffect(() => setSelected(value), [value]);
  useEffect(() => {
    const resetSearch = () => setSearch('');
    onInit({ resetSearch });
  // eslint-disable-next-line
  }, []);

  return (
    <div
      ref={ref}
      className={cn('drop_down_filter', { 'drop_down_filter--disabled': disabled })}
    >
      <div className={cn('drop_down_filter_in', { 'drop_down_filter_in--disable_hover': !hasHover })}>
        <div className="drop_down_filter_search">
          <label
            className="drop_down_filter__label"
            htmlFor="dropDown"
            onClick={onDeselect}
          >
            <BiGitBranch />
          </label>
          <input
            ref={inputRef}
            className={cn('drop_down_filter__input', {'drop_down_filter__input--active': isOpen })}
            autoComplete="do-not-autofill"
            id={placeholder}
            type="text"
            placeholder={placeholder}
            onChange={onChangeSearch}
            value={selected?.label || search}
            onKeyDown={onKeyDown}
            onFocus={() => setIsOpen(true)}
            onClick={() => setIsOpen(true)}
            disabled={disabled}
          />
        </div>
      </div>
      {isOpen && createPortal(
        <div
          ref={popupRef}
          className={cn('drop_down_filter_menu', className)}
          style={{top: popupTop, left: popupLeft, width: width, maxHeight}}
        >
          <ul>
            <>
              {loading && (
                <Loading/>
              )}
              {!loading && !filteredOptions?.length && (
                <p>{t("components.ui.drop_down.empty")}</p>
              )}
              {!loading && filteredOptions.map((option, index) => {
                if (option.el) {
                  return cloneElement(option.el, {
                    onClick: () => {
                      onSelect(option.option);
                    },
                    onMouseEnter: () => setActiveOptionIndex(index),
                    className: cn('drop_down_filter_menu__item', option.el.props?.className, { 'drop_down_filter_menu__item--active': index === activeOptionIndex })
                  });
                }
                return (
                  <li
                    key={index}
                    className={cn('drop_down_filter_menu__item', { 'drop_down_filter_menu__item--active': index === activeOptionIndex })}
                    onClick={() => onSelect(option)}
                    onMouseEnter={() => setActiveOptionIndex(index)}
                  >
                    {option.label}
                  </li>
                );
              })}
            </>
          </ul>
        </div>,
        document.getElementById('modal')
      )}
      {isOpen && createPortal(
        <div
          className="drop_down_filter__overlay"
          onClick={onCloseMenu}
        />, document.getElementById('modal')
      )}
    </div>
  );
}

DropDownFilter.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array.isRequired,
  onSearch: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onInit: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  position: PropTypes.string,
  maxHeight: PropTypes.number,
  label: PropTypes.string,
  hasHover: PropTypes.bool,
  loading: PropTypes.bool,
  showClose: PropTypes.bool
};

export default memo(DropDownFilter);
