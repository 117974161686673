import './index.scss';
import {memo, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Map from '../map';
import {ORDER_MARKERS} from '../../constants/markers';
import {useTranslation} from "react-i18next";

function SingleMarker({ mapIntegration, agentLocation, agentSrc, deliveryAddressLocation, pickUpLocation }) {
  const {t} = useTranslation();
  const [map, setMap] = useState(null);
  const markers = useMemo(() => {
    const markers = [
      {
        type: 'agent',
        position: agentLocation,
        // icon: AGENT_MARKERS.online,
        img: agentSrc,
        imgSize: [40, 40],
        status: 'online',
        onClick: () => null,
        open: true,
        content: `
          <div class="marker">
            ${t("components.single_marker.agentLocation")}
          </div>
        `,
      },
      {
        type: 'deliveryAddress',
        position: deliveryAddressLocation,
        icon: ORDER_MARKERS.delivered,
        onClick: () => null,
        open: true,
        content: `
          <div class="marker">
              ${t("components.single_marker.deliveryAddressLocation")}
          </div>
        `,
      }
    ];

    if (pickUpLocation) {
      markers.push({
        type: 'pickUpLocation',
        position: pickUpLocation,
        icon: ORDER_MARKERS.picked_up,
        onClick: () => null,
        open: true,
        content: `
          <div class="marker">
              ${t("components.single_marker.pickedUpLocation")}
          </div>
        `,
      });
    }

    return markers;
  }, [t, agentLocation, deliveryAddressLocation, pickUpLocation, agentSrc]);

  return (
    <div className="single_marker">
      <Map
        type={mapIntegration.value}
        zoom={13}
        center={agentLocation}
        setMap={setMap}
        map={map}
        markers={markers}
      />
    </div>
  );
}

SingleMarker.propTypes = {
  mapIntegration: PropTypes.object.isRequired,
  agentLocation: PropTypes.object.isRequired,
  agentSrc: PropTypes.string.isRequired,
  deliveryAddressLocation: PropTypes.object.isRequired,
  pickUpLocation: PropTypes.object
};
export default memo(SingleMarker);
