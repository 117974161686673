import './index.scss';
import {memo, useCallback, useState, useEffect, useContext} from 'react';
import Form from "../../../../../components/form";
import FormRow from "../../../../../components/form/form-row";
import FormCol from "../../../../../components/form/form-col";
import PropTypes from "prop-types";
import DropDown from "../../../../../components/ui/drop-down";
import {BiGitBranch} from "react-icons/bi";
import api from "../../../../../utils/api";
import {useTranslation} from "react-i18next";
import Loading from "../../../../../components/ui/loading";
import moment from "moment";
import UserCompanyBranchesContext from "../../../../../context/user-company-branches/user-company-branches-context";
import {errToString} from "../../../../../utils";

function TransferUserForm({
dateFields,
setSelectedBranch,
setBranchesData,
setIsTransferUsersFormOpen,
selectedUser,
branchesData,
selectedBranch,
onCancel
}) {
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const {t} = useTranslation();
  const [transferredBranch, setTransferredBranch] = useState(null);
  const [branchOptions, setBranchOptions] = useState(branchesData);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const onSingleTransfer = useCallback(e => {
    e.preventDefault();
    setPending(true);

    const startDate = moment(dateFields.startDate).utc().format();
    const endDate = moment(dateFields.endDate).utc().format();

    api
      .post(`/company/address/employees/move?from=${startDate}&to=${endDate}`, {
        ids: [selectedUser.id],
        from: selectedBranch.id,
        to: transferredBranch?.id
      })
      .then(() => {
        api
          .get('/company/addresses?includeUsers')
          .then(({data}) => {
            setPending(false);
            setSelectedBranch(data.find(({id}) => id === selectedBranch.id));
            setBranchesData(data);
            setIsTransferUsersFormOpen(false);
          })
          .catch(err => console.log(errToString(err)));

        api
            .get('/user-company-addresses')
            .then((res) => userCompanyBranches.set(res.data))
            .catch(err => {
              setError(errToString(err));
              console.log(errToString(err));
            });
      })
      .catch(err => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      })
  // eslint-disable-next-line
  }, [dateFields, setSelectedBranch, setIsTransferUsersFormOpen, setBranchesData, selectedUser, selectedBranch, transferredBranch, userCompanyBranches.set]);

  useEffect(() => {
    if (!branchesData) {
      return;
    }

    setBranchOptions(branchesData.map((branch) => (
      {
        ...branch,
        label: branch.name,
        value: branch.deliveryAddress
      }
    )));
    setLoading(false);
  }, [branchesData]);

  return (
    <>
      <div className="transfer_user_form">
        <Form
          title={`${t("pages.branches.branch_card.branch_settings_popup.transfer_user_form.transfer")} ${selectedUser.type} ${selectedUser.firstName} ${selectedUser.lastName}`}
          error={error && t(`errors.${error?.toLowerCase()}`)}
          onSubmit={onSingleTransfer}
        >
          <FormRow>
            <FormCol>
              <label htmlFor="">
                {t("pages.branches.branch_card.branch_settings_popup.transfer_user_form.chooseBranch")}
              </label>
              <div className="create_order_delivery_address_col create_order_delivery_address_col--fit">
                <DropDown
                  hasBranchIcon={true}
                  placeholder={t("pages.branches.branch_card.branch_settings_popup.transfer_user_form.chooseBranch")}
                  options={branchOptions.filter(branch => (branch.id !== selectedBranch.id) && !branch.deletedAt).map((branchOption, index) => ({
                    option: branchOption,
                    el: (
                      <li
                        className='create_order_custom_dropdown_menu'
                        key={index}
                      >
                        <BiGitBranch /> {branchOption.name} - address: {branchOption.deliveryAddress}
                      </li>
                    )
                  }))}
                  loading={loading}
                  value={transferredBranch}
                  onChange={(e) => setTransferredBranch(e)}
                  position="bottom"
                  showClose={false}
                />
              </div>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <a
                href="!#"
                className="update_agent__cancel_btn"
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
              >{t("pages.agents.update_agent.update_password_agent.cancel")}</a>
            </FormCol>
            <FormCol>
              {!pending && (
                <button type='submit' className="transfer_user_form_submit_btn">{t("pages.branches.branch_card.branch_settings_popup.transfer_user_form.transferUser")}</button>
              )}
              {pending && (
                <button className="create_order_submit_btn create_order_submit_btn--pending">
                  <Loading />
                </button>
              )}
            </FormCol>
          </FormRow>
        </Form>
      </div>
      <div className="transfer_user_form__overlay"/>
    </>
  );
}

TransferUserForm.propTypes = {
  onCancel: PropTypes.func,
  selectedBranch: PropTypes.object,
  branchesData: PropTypes.array.isRequired,
  type: PropTypes.string,
  selectedUser: PropTypes.object,
  setIsTransferUsersFormOpen: PropTypes.func,
  setBranchesData: PropTypes.func,
  setSelectedBranch: PropTypes.func
}

export default memo(TransferUserForm);
