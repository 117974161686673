import React, {memo, useEffect, useContext, useState, useCallback} from "react";
import Footer from "./components/layout/footer";
import Navigation from "./components/navigation";
import Router from "./Router";
import api from "./utils/api";
import AuthContext from "./context/auth/auth-context";
import { NOT_ALLOWED_NAVIGATION_ROUTES } from "./constants/not-allowed-navigation-routes";
import {useLocation, useNavigate} from "react-router-dom";
import isRouteAllowed from './utils/is-route-allowed';
import Header from "./components/layout/header";
import NotificationsPopup from "./components/ui/notifications-popup";
import {Loader} from '@googlemaps/js-api-loader';
import io from 'socket.io-client';
import SocketContext from './context/socket/socket-context';
import {animateTo} from './utils/marker';
import PreferencesContext from "./context/preferences/preferences-context";
import {useTranslation} from "react-i18next";
import Loading from "./components/ui/loading";
import PaymentMethodsContext from "./context/payment-methods/payment-methods-context";
import UserCompanyBranchesContext from "./context/user-company-branches/user-company-branches-context";
import {errToString} from "./utils";
import {setMomentJsLocale} from "./utils/moment";
import IntegrationsContext from "./context/integrations/integrations-context";

export const LIMIT = 10;

function App() {
  const {isAuth, auth, user} = useContext(AuthContext);
  const integrations = useContext(IntegrationsContext);
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const preferences = useContext(PreferencesContext);
  const paymentMethods = useContext(PaymentMethodsContext);
  const {setSocket} = useContext(SocketContext);
  const {i18n} = useTranslation();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [mapIntegration, setMapIntegration] = useState({
    name: 'map',
    value: null,
    apiKey: null
  }); // TODO: need to get rid of this state and continue using IntegrationsContext
  const [isNewOrderOpen, setIsNewOrderOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [agentsData, setAgentsData] = useState([]);
  const [analyticsLoading, setAnalyticsLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);

  const onToggleMenu = useCallback(() => {
    setIsBurgerOpen(() => !isBurgerOpen);
  }, [isBurgerOpen]);

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    api
      .get('/company/integrations')
      .then(({ data }) => {
        const activeMapIntegration = data.find(({ name, isActive }) => name === 'map' && isActive);
        const loader = new Loader({
          apiKey: activeMapIntegration.credentials?.apiKey || process.env.REACT_APP_GOOGLE_MAPS_KEY,
          version: "weekly"
        });

        loader
          .load()
          .then(() => {
            window.google.maps.Marker.prototype.animateTo = animateTo;
            setMapIntegration(activeMapIntegration);
            integrations.setMapIntegration(activeMapIntegration);
          })
          .catch(err => console.log(errToString(err)))
      })
      .catch(err => console.log(errToString(err)))
  // eslint-disable-next-line
  }, [isAuth, integrations.setMapIntegration]);
  useEffect(() => {
    if (window.location.pathname.includes('/t/')) {
      return auth(null);
    }

    if (isAuth === null) {
      api
        .get("/auth/token")
        .then((res) => {
          auth(res.data);
        })
        .catch(err => {
          console.log(errToString(err));
          return auth(null);
        })
    }
    if (isAuth) {
      api
        .get("/subscriptions")
        .then(({ data }) => {
          if (data.subscription?.status === 'past_due') {
            setHasSubscription(false);
            if (!pathname.includes("/account")) {
              return navigate('/account');
            }
          }

          setHasSubscription(true);
        })
        .catch(err => {
          console.log(errToString(err));
          setHasSubscription(false);
          if (!pathname.includes("/account")) {
            return navigate('/account');
          }
        });

      const token = localStorage.getItem('token');
      const socket = io(process.env.REACT_APP_BASE_URL, {
        transports: ['websocket'],
        auth: {
          token
        }
      });

      socket.on('connect', () => {
        setSocket(socket);
        console.log('connected');
      });

      socket.on('disconnect', reason => {
        setSocket(null);
        console.log('disconnect', reason);
      });

      return () => {
        socket.off('connect');
        socket.off('disconnect');
        setSocket(null);
      };
    }
  // eslint-disable-next-line
  }, [isAuth, auth]);
  useEffect(() => {
    if (!isAuth) {
      return;
    }

    api
        .get('/user-company-addresses')
        .then((res) => {
          userCompanyBranches.set(res.data);
        })
        .catch(err => console.log(errToString(err)));

    api
        .get('/company/payment_methods?freezed')
        .then(({data}) => {
          paymentMethods.set(data);
        })
      .catch(err => console.log(errToString(err)));

    api
      .get("company/preferences")
      .then(({data}) => {
        const preferencesObj = {};

        for(const preference of data) {
          preferencesObj[preference.name] = preference;
        }
        preferences.set(preferencesObj);
      })
      .catch(err => console.log(errToString(err)));

    // eslint-disable-next-line
  }, [isAuth, preferences.set, paymentMethods.set, userCompanyBranches.set]);
  useEffect(() => {
    if (localStorage.getItem("lng")) {
      i18n.changeLanguage(localStorage.getItem("lng"));
      return setMomentJsLocale(localStorage.getItem("lng"));
    }

    if (user) {
      if (user?.company?.countryCode === "am") {
        i18n.changeLanguage("am");
        return setMomentJsLocale("am");
      }

      if (user?.company?.countryCode !== "am") {
        i18n.changeLanguage("en");
        return setMomentJsLocale("en");
      }
    }
  }, [i18n, user]);

  if (isAuth === null || mapIntegration === null) {
    return null;
  }

  if (isAuth && isRouteAllowed(NOT_ALLOWED_NAVIGATION_ROUTES, pathname) && (!preferences.list || !paymentMethods.list || !userCompanyBranches.list)) {
    return (
      <Loading />
    )
  }

  return (
    <>
      {isAuth && isRouteAllowed(NOT_ALLOWED_NAVIGATION_ROUTES, pathname) && preferences.list && (
        <Header
          setIsNewOrderOpen={setIsNewOrderOpen}
          onToggleMenu={onToggleMenu}
          isBurgerOpen={isBurgerOpen}
          notifications={notifications}
          setNotifications={setNotifications}
          setIsBurgerOpen={setIsBurgerOpen}
          analyticsLoading={analyticsLoading}
          hasSubscription={hasSubscription}
        />
      )}
      {isAuth && isRouteAllowed(NOT_ALLOWED_NAVIGATION_ROUTES, pathname) && preferences.list && (
        <Navigation
          notifications={notifications}
          setNotifications={setNotifications}
          onToggleMenu={onToggleMenu}
          isBurgerOpen={isBurgerOpen}
        />
      )}
      <Router
        setAgentsData={setAgentsData}
        agentsData={agentsData}
        isNewOrderOpen={isNewOrderOpen}
        setIsNewOrderOpen={setIsNewOrderOpen}
        notifications={notifications}
        setNotifications={setNotifications}
        mapIntegration={mapIntegration}
        setAnalyticsLoading={setAnalyticsLoading}
      />
      {isAuth && isRouteAllowed(NOT_ALLOWED_NAVIGATION_ROUTES, pathname) && <NotificationsPopup />}
      {isAuth && isRouteAllowed(NOT_ALLOWED_NAVIGATION_ROUTES, pathname) && <Footer />}
    </>
  );
}

export default memo(App);
