import { memo } from "react";
import "./index.scss";

function ContentLoading({type}) {
  return (
    <div className="content_loading">
      {type === "default" && (
        <>
          <div className="content_loading_img" />
          <div className='content_loading_content'>
            <div className='content_loading_content_line content_loading_content_line1' />
            <div className='content_loading_content_line content_loading_content_line2' />
          </div>
        </>
      )}
      {type === "line" && (
        <div className='content_loading_content_line content_loading_content_line2' />
      )}
      {type === "image" && (
        <div className="content_loading_img content_loading_img--single" />
      )}
      {type === "card" && (
        <div className="content_loading_card content_loading_card_loading">
          <div className="content_loading_card__image" />
          <div className="content_loading_card__content">
            <p></p>
            <div className="content_loading_card__description" />
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(ContentLoading);
