import "./index.scss";
import React, {memo, useCallback, useContext, useEffect, useMemo, useState} from "react";
import PageHeader from "../../../components/layout/page-header";
import {useTranslation} from "react-i18next";
import AnalyticsInfo from "../../../components/ui/analytics-info";
import StripeFormSms from "./stripe-form-sms";
import AuthContext from "../../../context/auth/auth-context";
import {errToString} from "../../../utils";
import api from "../../../utils/api";
import Loading from "../../../components/ui/loading";
import {createPortal} from "react-dom";
import {Elements} from "@stripe/react-stripe-js";
import AddCardForm from "../stripe-form/add-card-form";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function AccountSmsCredits() {
  const {t} = useTranslation();
  const {user} = useContext(AuthContext);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingCredits, setLoadingCredits] = useState(true);
  const [pending, setPending] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);

  const [smsFreeCredits, setSmsFreeCredits] = useState(null);
  const [smsCredits, setSmsCredits] = useState(null);
  // const [autoRecharge, setAutoRecharge] = useState(false);
  const stripeCustomerId = useMemo(() => user.company.stripeCustomerId, [user.company]);

  const selectProduct = useCallback(async productId => {
    api
      .post('/subscriptions/sms', { productId: productId })
      .then(() => window.location.reload())
      .catch((err) => {
        console.log(errToString(err));
        alert(errToString(err));
        setPending(false);
      });
  }, []);
  const onSelect = useCallback(async productId => {
    if (pending) {
      return;
    }

    setPending(true);

    if (!stripeCustomerId || !defaultPaymentMethod) {
      setSelectedProductId(productId);
      setPending(false);
    } else {
      await selectProduct(productId);
    }
  }, [stripeCustomerId, defaultPaymentMethod, selectProduct, pending]);

  // const onChangeAutoRecharge = useDebouncedCallback(() => {
  //   return setAutoRecharge(!autoRecharge)
  // }, 300);

  useEffect(() => {
    setLoading(true);

    api
      .get("/products?type=onetime")
      .then(({data}) => {
        setProducts(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(errToString(err));
      })
  }, []);
  useEffect(() => {
    setLoadingCredits(true);

    api
      .get("/company")
      .then(({data}) => {
        setSmsFreeCredits(data.smsFreeCredits);
        setSmsCredits(data.smsCredits)
        setLoadingCredits(false);
      })
      .catch((err) => {
        setLoadingCredits(false);
        console.log(errToString(err));
      })
  }, []);
  useEffect(() => {
    api
      .get('/subscriptions/methods')
      .then(({ data }) => setDefaultPaymentMethod(data.default))
      .catch(err => console.log(errToString(err)));
  }, []);

  console.log(smsFreeCredits, smsCredits)
  return (
    <div className="account_sms_credits">
      <PageHeader>
        <p>{t("pages.billing.smsCredits")}</p>
      </PageHeader>
      <div className="account_sms_credits_in">
        <p className="account_sms_credits_in_txt">{t("pages.billing.creditsDescription")}</p>
        {!loading && !loadingCredits && (
          <>
            <div className="agent_details_dashboard">
              {smsFreeCredits > 0 && (
                <AnalyticsInfo
                  className="agent_details_dashboard__info"
                  count={smsFreeCredits}
                  title={t("pages.billing.freeCredits")}
                  status={smsFreeCredits > 0 ? "finished" : "canceled"}
                  loading={false}
                />
              )}
              <AnalyticsInfo
                className="agent_details_dashboard__info"
                count={smsCredits}
                title={t("pages.billing.paidCredits")}
                status={smsCredits > 0 ? "finished" : "canceled"}
                loading={false}
              />
            </div>
            <div
              className="account_sms_credits_in_btn"
              onClick={() => setIsFormOpen(true)}
            >
              {t("pages.billing.buyCredits")}
            </div>
            {/*<PageHeader>*/}
            {/*  <p>{t("pages.billing.recharge")}</p>*/}
            {/*</PageHeader>*/}
            {/*<div className="account_sms_credits_in_switcher">*/}
            {/*  <input*/}
            {/*    type="checkbox"*/}
            {/*    onChange={onChangeAutoRecharge}*/}
            {/*    checked={autoRecharge}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<p className="settings_company_agents_in__descrip">*/}
            {/*  {t("pages.billing.rechargeDescrip")}*/}
            {/*</p>*/}
          </>
        )}
        {(loading || loadingCredits) && (
          <div className="account_payment_methods_in_center">
            <Loading />
          </div>
        )}
      </div>
      {!loading && !loadingCredits && selectedProductId && createPortal(
        <Elements stripe={stripePromise}>
          <AddCardForm
            onClose={() => setSelectedProductId(null)}
            onSuccess={() => selectProduct(selectedProductId)}
          />
        </Elements>,
        document.getElementById('modal')
      )}
      {isFormOpen && createPortal(
        <StripeFormSms
          setIsFormOpen={setIsFormOpen}
          products={products}
          onSelect={onSelect}
          pending={pending}
          setPending={setPending}
        />,
        document.getElementById('modal')
      )}
    </div>
  );
}

export default memo(AccountSmsCredits);
