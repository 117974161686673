import {memo, useContext, useEffect} from "react";
import "./index.scss";
import Container from "../../components/layout/container";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import cn from "classnames";
import PageHeader from "../../components/layout/page-header";
import {useTranslation} from "react-i18next";
import {ACCOUNT_ROUTES} from "../../constants/navigation-routes";
import AuthContext from "../../context/auth/auth-context";

function Account() {
    const {user} = useContext(AuthContext)
    const {t} = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      if (pathname === '/account') {
        navigate('/account/profile');
      }
    }, [pathname, navigate]);

    return (
      <Container>
          <div className="account">
            <PageHeader>
              <p>{t("pages.billing.account")}</p>
            </PageHeader>
            <div className="account_in">
              <div className="account_menu">
                <ul>
                  {user.type === "owner" && ACCOUNT_ROUTES.map(({id, icon, label, link}) => (
                    <li
                      key={id}
                      className={cn("account_menu_list", {"account_menu_list--active": pathname === link})}
                    >
                      <Link to={link}>
                        {icon}
                        <span>{t(`pages.billing.${label}`)}</span>
                      </Link>
                    </li>
                  ))}
                  {user.type !== "owner" && ACCOUNT_ROUTES
                    .filter(el => el.id === "account-profile")
                    .map(({id, icon, label, link}) => (
                      <li
                        key={id}
                        className={cn("account_menu_list", {"account_menu_list--active": pathname === link})}
                      >
                        <Link to={link}>
                          {icon}
                          <span>{t(`pages.billing.${label}`)}</span>
                        </Link>
                      </li>
                  ))}
                </ul>
              </div>
              <div className="account_main">
                <Outlet />
              </div>
            </div>
          </div>
      </Container>
    );
}

export default memo(Account);
