import { memo } from "react";
import "./index.scss";
import {useTranslation} from "react-i18next";

function NoData() {
  const {t} = useTranslation();

  return (
    <div className="no_data">
        <p className="no_data__text">{t('components.ui.no_data.noData')}</p>
    </div>
  );
}

export default memo(NoData);
