import {memo, useCallback, useEffect, useState} from 'react';
import api from "../../../../utils/api";
import Form from '../../../../components/form'
import FormRow from "../../../../components/form/form-row";
import FormCol from "../../../../components/form/form-col";
import PropTypes from "prop-types";
import sound from "../../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {RiLockPasswordLine} from "react-icons/ri";
import Loading from "../../../../components/ui/loading";
import {errToString} from "../../../../utils";

function UpdateAgentPassword({onClose, agent}) {
  const {t} = useTranslation();
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [fields, setFields] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onChangeField = useCallback((e, name) => {
    setError(null);

    if (e.target.type === 'password') {
      setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [fields]);
  const onSubmit = useCallback(e => {
    e.preventDefault();

    const data = {
      newPassword: fields.newPassword,
      confirmPassword: fields.confirmPassword,
    };

    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    if (data.newPassword !== data.confirmPassword) {
      return setError(t("passwords_not_match"));
    }

    setPending(true);
    api
      .put(`/employees/agents/${agent.id}`, {
        password: fields.confirmPassword
      })
      .then(() => {
        setPending(false);
        onClose();
        const audio = new Audio(sound);
        const info = `${t("pages.agents.update_agent.update_password_agent.agent")} ${agent.username} ${t("pages.agents.update_agent.update_password_agent.passwordUpdateMsg")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch((err) => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      });
  }, [t, onClose, fields, agent.id, agent.username]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="update_agent">
      <div className="update_agent_in">
        {agent && (
          <Form
            title={`${t("pages.agents.update_agent.update_password_agent.updatePassword")} ${agent.username}`}
            error={error && t(`errors.${error?.toLowerCase()}`)}
            onSubmit={onSubmit}
          >
            {windowWidth > 600 && (
                <FormRow>
                  <FormCol>
                    <label htmlFor="newPassword">
                      <span>*</span>
                      {t("pages.agents.update_agent.update_password_agent.newPassword")}
                    </label>
                    <div className="create_order_wrapper">
                      <div className="create_order_wrapper_icon">
                        <RiLockPasswordLine />
                      </div>
                      <input
                          id="newPassword"
                          name="newPassword"
                          className="create_order_wrapper_input"
                          type="password"
                          placeholder={t("pages.agents.update_agent.update_password_agent.newPassword")}
                          autoComplete="off"
                          value={fields.newPassword}
                          onChange={e => onChangeField(e, 'newPassword')}
                      />
                    </div>
                  </FormCol>
                  <FormCol>
                    <label htmlFor="confirmPassword">
                      <span>*</span>
                      {t("pages.agents.update_agent.update_password_agent.confirmPassword")}
                    </label>
                    <div className="create_order_wrapper">
                      <div className="create_order_wrapper_icon">
                        <RiLockPasswordLine />
                      </div>
                      <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          placeholder={t("pages.agents.update_agent.update_password_agent.confirmPassword")}
                          autoComplete="off"
                          className="create_order_wrapper_input"
                          value={fields.confirmPassword}
                          onChange={e => onChangeField(e, 'confirmPassword')}
                      />
                    </div>
                  </FormCol>
                </FormRow>
            )}
            {windowWidth <= 600 && (
                <>
                  <FormRow>
                    <FormCol>
                      <label htmlFor="newPassword">
                        <span>*</span>
                        {t("pages.agents.update_agent.update_password_agent.newPassword")}
                      </label>
                      <div className="create_order_wrapper">
                        <div className="create_order_wrapper_icon">
                          <RiLockPasswordLine />
                        </div>
                        <input
                            id="newPassword"
                            name="newPassword"
                            className="create_order_wrapper_input"
                            type="password"
                            placeholder={t("pages.agents.update_agent.update_password_agent.newPassword")}
                            autoComplete="off"
                            value={fields.newPassword}
                            onChange={e => onChangeField(e, 'newPassword')}
                        />
                      </div>
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <FormCol>
                      <label htmlFor="confirmPassword">
                        <span>*</span>
                        {t("pages.agents.update_agent.update_password_agent.confirmPassword")}
                      </label>
                      <div className="create_order_wrapper">
                        <div className="create_order_wrapper_icon">
                          <RiLockPasswordLine />
                        </div>
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          placeholder={t("pages.agents.update_agent.update_password_agent.confirmPassword")}
                          autoComplete="off"
                          className="create_order_wrapper_input"
                          value={fields.confirmPassword}
                          onChange={e => onChangeField(e, 'confirmPassword')}
                        />
                      </div>
                    </FormCol>
                  </FormRow>
                </>
            )}
            <FormRow>
              <FormCol>
                <a
                  href="!#"
                  className="update_agent__cancel_btn"
                  onClick={onClose}
                >{t("pages.agents.update_agent.update_password_agent.cancel")}</a>
              </FormCol>
              <FormCol>
                {!pending && (
                  <button type="submit" className="update_agent__submit_btn">
                    {t("pages.agents.update_agent.update_password_agent.updatePasswordBtn")}
                  </button>
                )}
                {pending && (
                  <button className="update_agent__submit_btn update_agent__submit_btn--pending">
                    <Loading />
                  </button>
                )}
              </FormCol>
            </FormRow>
          </Form>
        )}
      </div>
    </div>
  );
}

UpdateAgentPassword.propTypes = {
  onClose: PropTypes.func,
  agent: PropTypes.object
};

export default memo(UpdateAgentPassword);
