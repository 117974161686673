import {TbBell, TbLayoutDashboard, TbLockAccess} from "react-icons/tb";
import { BsFillPersonFill } from "react-icons/bs";
import { BsPeopleFill } from "react-icons/bs";
import { IoIosSettings } from "react-icons/io";
import { RiCustomerService2Fill, RiBillLine} from "react-icons/ri";
import {BiGitBranch, BiTask} from "react-icons/bi"
import {MdPayment} from "react-icons/md"
import {ImMap2} from "react-icons/im"
import {MdOutlineTextsms} from "react-icons/md"
import {HiOutlineUser, HiUserGroup} from "react-icons/hi"
import {AiOutlineStar} from "react-icons/ai";
// import {AiFillStar} from "react-icons/ai";
// import {SiGoogleanalytics} from "react-icons/si";
import {VscSettings} from "react-icons/vsc";

export const NAVIGATION_ROUTES = [
  { id: "dashboard", links: ["/", '/dashboard/orders'], icon: <TbLayoutDashboard stroke={'#5C0089'} fill={"#5C0089"}/> },
  // { id: "analytics", links: ["/analytics"], icon: <SiGoogleanalytics fill={"#0000FFFF"}/> },
  { id: "orders", links: ["/orders"], icon: <BiTask fill={"green"}/> },
  { id: "agents", links: ["/agents", "/agents/details"], icon: <BsFillPersonFill fill={"#5C0089"}/> },
  { id: "managers", links: ["/managers", "/managers/details"], icon: <RiCustomerService2Fill fill={"darkorange"}/> },
  { id: "customers", links: ["/customers", "/customers/details"], icon: <BsPeopleFill fill={"#0000FFFF"}/> },
  { id: "partners", links: ["/partners", "/partners/details"], icon: <HiUserGroup fill={"#f0b101"}/> },
  { id: "branches", links: ["/branches"], icon: <BiGitBranch fill={"#5C0089"}/> },
  { id: "settings", links: ["/settings/payment-methods","/settings/reviews", "/settings/roles", "/settings/partners", "/settings/notifications", "/settings/preferences", "/settings/maps", "/settings/sms"], icon: <IoIosSettings fill={"#00008BFF"} /> },
  { id: "account", links: ["/account", "/account/profile", "/account/payment-methods", "/account/billing-history", "/account/subscriptions"], icon: <RiBillLine fill={"green"}/> },
];
export const SETTINGS_COMPANY_ROUTES = [
  { id: "payment-methods", link: "/settings/payment-methods", icon: <MdPayment fill={"#00008BFF"}/>, label: "paymentMethods"},
  { id: "notifications", link: "/settings/notifications", icon: <TbBell fill={"#f1f3f7"} stroke={"#0000FFFF"}/>, label: "notifications"},
  { id: "roles", link: "/settings/roles", icon: <TbLockAccess stroke={'green'}/>, label: "roles"},
  { id: "agents", link: "/settings/agents", icon: <HiOutlineUser stroke={"#5C0089"}/>, label: "agents"},
  { id: "reviews", link: "/settings/reviews", icon: <AiOutlineStar fill={"#f0b101"}/>, label: "reviews"},
  { id: "preferences", link: "/settings/preferences", icon: <VscSettings fill={"#0000FFFF"}/>, label: "preferences"},
];
export const ACCOUNT_ROUTES = [
  { id: "account-profile", link: "/account/profile", icon: <BsFillPersonFill fill={"#5C0089"}/>, label: "account"},
  { id: "payment-methods", link: "/account/payment-methods", icon: <MdPayment fill={"#00008BFF"}/>, label: "paymentMethods"},
  { id: "billing-history", link: "/account/billing-history", icon: <BiTask fill={"#f0b101"} />, label: "billingHistory"},
  { id: "sms-credits", link: "/account/sms-credits", icon: <MdOutlineTextsms fill={'#5C0089'}/>, label: "smsCredits"},
  { id: "subscriptions", link: "/account/subscriptions", icon: <RiBillLine fill={'green'}/>, label: "subscriptions"},
];
export const SETTINGS_INTEGRATIONS_ROUTES = [
  { id: "maps", link: "/settings/maps", icon: <ImMap2 fill={"#5C0089"}/>, label: "maps"},
  { id: "sms", link: "/settings/sms", icon: <MdOutlineTextsms fill={"green"}/>, label: "sms"}
];
