import {memo} from "react";
import {createPortal} from "react-dom";
import "./index.scss";
import Overlay from "../../ui/overlay";
import PropTypes from "prop-types";

function PopUp({
 children,
 onOverlayClick,
 isTransparent
}) {

  return createPortal(
    <div className="popup">
      {children}
      <Overlay onClick={onOverlayClick} isTransparent={isTransparent}/>
    </div>,
    document.getElementById('modal')
  );
}

PopUp.propTypes = {
  children: PropTypes.any,
  onOverlayClick: PropTypes.func,
  isTransparent: PropTypes.bool
};

export default memo(PopUp)
