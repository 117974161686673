import './index.scss';
import React, {memo, useCallback, useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import api from "../../../../../../utils/api";
import Loading from '../../../../../../components/ui/loading';
import moment from "moment/moment";
import sound from "../../../../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {errToString} from "../../../../../../utils";
import AssignAgentForm from "../../../../../../components/assign-agent-form";
import {BsPlus} from "react-icons/bs";

function AssignOrder({ onClose, order, startDate, endDate }) {

  const {t} = useTranslation();

  const [pending, setPending] = useState(false);
  const [assignedAgent, setAssignedAgent] = useState(null);
  const [agentsData, setAgentsData] = useState(null);
  const agentsOptions = useMemo(() => {
    if (!agentsData) {
      return [];
    }

    return agentsData
      ?.map(agent => ({
        value: agent?.id,
        label: `${agent?.firstName} ${agent?.lastName}`,
        ...agent
      }))
      .sort((a, b) => b['isOnline'] - a['isOnline'])
  }, [agentsData]);
  const [assignedPartner, setAssignedPartner] = useState(null);
  const [partnersData, setPartnersData] = useState(null);
  const partnersOptions = useMemo(() => {
    if (!partnersData) {
      return [];
    }

    return partnersData
      ?.map(partner => ({
        value: partner?.id,
        label: `${partner?.name}`,
        ...partner
      }))
  }, [partnersData]);
  const [assignTypeAgents, setAssignTypeAgents] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onSubmit = useCallback(e => {
    e.preventDefault();

    if (pending) {
      return;
    }

    const data = {};

    if (assignedAgent) {
      data.agentId = assignedAgent.value;
      setAssignedPartner(null);
    }
    if (assignedPartner) {
      data.partnerId = assignedPartner.value;
      setAssignedAgent(null);
    }

    setPending(true);

    api
      .put(`/orders/${order.id}/agent`, data)
      .then(() => {
        onClose();
        setPending(false);

        const audio = new Audio(sound);
        const info = `#${order.externalId} ${t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.order")} ${t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.hasBeenAssigned")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch((err) => {
        setPending(false);
        alert(t(`errors.${errToString(err)}`));
        console.log(errToString(err));
      })
  }, [t, pending, order, onClose, assignedAgent, assignedPartner]);

  useEffect(() => {
    const from = moment(startDate).utc().format();
    const to = moment(endDate).utc().format();
    const companyAddressIds = order.pickupAddress.id ? `&companyAddressIds=${order.pickupAddress.id}` : '';

    api
      .get(`/employees/agents?from=${from}&to=${to}${companyAddressIds}`)
      .then((res) => {
        setAgentsData(res.data.results)
      })
      .catch((err) => console.log(errToString(err)));
  }, [startDate, endDate, order.pickupAddress.id]);
  useEffect(() => {
    const from = moment(startDate).utc().format();
    const to = moment(endDate).utc().format();
    const companyAddressIds = order.pickupAddress.id ? `&companyAddressIds=${order.pickupAddress.id}` : '';

    api
      .get(`/partners?from=${from}&to=${to}${companyAddressIds}`)
      .then(({ data }) => {
        setPartnersData(data.results);
      })
      .catch(err => console.log(errToString(err)));
  }, [startDate, endDate, order.pickupAddress.id]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
      <>
          {agentsData && partnersData && (
              <div className="assign_order">
                <div className="assign_order_in">
                    <AssignAgentForm
                      show={true}
                      setShow={() => true}
                      assignTypeAgents={assignTypeAgents}
                      setAssignTypeAgents={setAssignTypeAgents}
                      agentsOptions={agentsOptions}
                      partnersOptions={partnersOptions}
                      pickupAddress={order.pickupAddress.name}
                      setAssignedPartner={setAssignedPartner}
                      setAssignedAgent={setAssignedAgent}
                      pickupAddressLocation={order.pickupAddress.location}
                      assignedAgent={assignedAgent}
                      assignedPartner={assignedPartner}
                    />
                  </div>
                <div className="assign_order_in_fixed">
                  <a
                    href="!#"
                    className="assign_order_in_fixed_actions_cancel"
                    onClick={onClose}
                  >{t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.cancel")}</a>
                  {assignedAgent && (
                    <div className="assign_order_in_fixed_assignBtn assign_order_in_fixed_assignBtn--assigned">
                      <p>{assignedAgent.firstName} {assignedAgent.lastName}</p>
                      <span
                        className="assign_order_in_fixed_assignBtn--close"
                        onClick={() => setAssignedAgent(null)}
                      >
                        {t(`general.change`)}
                      </span>
                    </div>
                  )}
                  {assignedPartner && (
                    <div className="assign_order_in_fixed_assignBtn assign_order_in_fixed_assignBtn--assigned">
                      {assignedPartner.name}
                    </div>
                  )}
                  <div className="assign_order_in_fixed_actions">
                    {windowWidth > 800 && (
                      <>
                        {!pending && (
                          <button
                            className="assign_order_in_fixed_actions_submit"
                            onClick={onSubmit}
                          >
                            {t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.assign")}
                          </button>
                        )}
                        {pending && (
                          <button className="assign_order_in_fixed_actions_submit assign_order_in_fixed_actions_submit--pending">
                            <Loading />
                          </button>
                        )}
                      </>
                    )}
                    {windowWidth <= 800 && (
                      <div
                        className="update_order_submit_btn--round"
                        onClick={onSubmit}
                      >
                        <BsPlus />
                      </div>
                    )}
                  </div>
                </div>
              </div>
          )}
          {(!agentsData || !partnersData) && (
            <div className="assign_order_loading">
              <Loading />
            </div>
          )}
      </>
  );
}

AssignOrder.propTypes = {
  onClose: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

export default memo(AssignOrder);
