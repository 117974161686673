import {memo} from "react";
import "./index.scss";
import {useTranslation} from "react-i18next";

function AgentInfoLabel({status, data}) {
    const {t} = useTranslation();

    return (
      <>
        {data?.orders?.filter(order => order.status === status)?.length > 0 && (
          <div className="agent_status">
            <span className={`agent_status--${status}`}>
              {t(`statuses.${status}`)}
              <span>
                {data?.orders?.filter(order => order.status === status)?.length}
              </span>
            </span>
          </div>
        )}
      </>
    );
}

export default memo(AgentInfoLabel)
