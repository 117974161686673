import "./index.scss";
import {memo, useCallback, useState, useContext} from "react";
import {createPortal} from 'react-dom';
import Form from "../../../components/form";
import FormRow from "../../../components/form/form-row";
import FormCol from "../../../components/form/form-col";
import PropTypes from "prop-types";
import api from "../../../utils/api";
import DropDown from "../../../components/ui/drop-down";
import {FaMapMarkerAlt} from "react-icons/fa";
import {getLatLngFromAddress, searchAddress} from "../../../utils/geocoding";
import {useDebouncedCallback} from "use-debounce";
import AuthContext from "../../../context/auth/auth-context";
import {useTranslation} from "react-i18next";
import {HiOutlineLocationMarker} from "react-icons/hi";
import Loading from "../../../components/ui/loading";
import {errToString} from "../../../utils";
import IntegrationsContext from "../../../context/integrations/integrations-context";

function CreateBranchForm({
onCancel,
branchesData,
setBranchesData
}) {
  const {user} = useContext(AuthContext);
  const integrations = useContext(IntegrationsContext);
  const {t} = useTranslation();
  const [pending, setPending] = useState(false);
  const [fields, setFields] = useState({
    lat: '',
    lng: '',
    deliveryAddress: null,
    name: ''
  });
  const [geocodingAddresses, setGeocodingAddresses] = useState([]);
  const [deliveryAddressLoading, setDeliveryAddressLoading] = useState(false);
  const [error, setError] = useState(null);

  const onChangeDeliveryAddress = useDebouncedCallback(value => {
    if (!value) {
      return
    }

    setDeliveryAddressLoading(true)
    searchAddress(user.address.countryCode, value, (err, addresses) => {
      if (err) {
        setGeocodingAddresses([]);
        return setDeliveryAddressLoading(false);
      }

      setGeocodingAddresses(addresses);
      setDeliveryAddressLoading(false);
    }, {
        type: integrations.mapIntegration.value,
        apiKey: integrations.mapIntegration?.credentials?.apiKey
    });
  }, 1000);
  const onResetDeliveryAddress = useCallback(() => {
    setFields({
      ...fields,
      deliveryAddress: null,
      apartment: '',
      entrance: '',
      floor: '',
      lat: '',
      lng: ''
    });
    setGeocodingAddresses([]);
  }, [fields]);
  const onChangeField = useCallback((e, name) => {
    setError(null);

    if (name === 'deliveryAddress') {
      return getLatLngFromAddress(e.label, location => {
        const deliveryAddress = {
          ...e,
          location
        };

        setGeocodingAddresses(geocodingAddresses.filter(geocodingAddress => geocodingAddress.value === deliveryAddress.value));

        return setFields({
          ...fields,
          [name]: deliveryAddress,
          lat: location.lat,
          lng: location.lng,
          apartment: e?.apartment || '',
          entrance: e?.entrance || '',
          floor: e?.floor || ''
        });
      }, {
        type: integrations.mapIntegration.value,
        apiKey: integrations.mapIntegration?.credentials?.apiKey
      });
    }

    if (
      e.target.type === 'text' ||
      e.target.type === 'number'
    ) {
      return setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [fields, geocodingAddresses, integrations.mapIntegration.value, integrations.mapIntegration?.credentials?.apiKey]);
  const onSubmit = useCallback((e) => {
    e.preventDefault();

    const data = {
      deliveryAddress: fields.deliveryAddress?.label,
      countryCode: user.address.countryCode,
      location: fields.deliveryAddress?.location,
      name: fields.name
    }

    setPending(true);

    api
      .post('/company/address', data)
      .then((res) => {
        setBranchesData([
          res.data,
          ...branchesData
        ]);
        onCancel();
        setPending(false);
      })
      .catch((err) => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      })
  }, [fields, onCancel, setBranchesData, branchesData, user.address.countryCode]);

  return createPortal(
    <div className="create_branch_form">
      <Form
        title={t("pages.branches.create_branch_form.createBranch")}
        error={error && t(`errors.${error?.toLowerCase()}`)}
        onSubmit={onSubmit}
      >
        <FormRow>
          <FormCol>
            <label>
              <span>*</span>
              {t("pages.branches.create_branch_form.name")}
            </label>
            <div className="create_order_wrapper">
              <div className="create_order_wrapper_icon">
                <HiOutlineLocationMarker />
              </div>
              <input
                type="text"
                className="create_order_wrapper_input"
                placeholder={t("pages.branches.create_branch_form.name")}
                autoComplete="off"
                value={fields.name}
                onChange={e => onChangeField(e, 'name')}
              />
            </div>
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <label>
              <span>*</span>
              {t("pages.branches.create_branch_form.address")}
            </label>
            <DropDown
              hasBranchIcon={true}
              placeholder={t("pages.branches.create_branch_form.address")}
              loading={deliveryAddressLoading}
              options={geocodingAddresses.map((deliveryAddresses, index) => ({
                option: deliveryAddresses,
                el: (
                  <li
                    className='create_order_custom_dropdown_menu'
                    key={`${index} ${deliveryAddresses}`}
                  >
                    <FaMapMarkerAlt /> {deliveryAddresses.label}
                  </li>
                )
              }))}
              value={fields.deliveryAddress}
              onSearch={onChangeDeliveryAddress}
              onChange={e => onChangeField(e, 'deliveryAddress')}
              onReset={onResetDeliveryAddress}
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <button
              className="create_branch_form_cancel_btn"
              onClick={onCancel}
            >{t("pages.branches.create_branch_form.cancel")}</button>
          </FormCol>
          <FormCol>
            {!pending && (
              <button className="create_branch_form_submit_btn" type="submit">{t("pages.branches.create_branch_form.save")}</button>
            )}
            {pending && (
              <button className="create_branch_form_submit_btn create_branch_form_submit_btn--pending">
                <Loading />
              </button>
            )}
          </FormCol>
        </FormRow>
      </Form>
    </div>,
      document.getElementById('modal')
  );
}

CreateBranchForm.propTypes = {
  onCancel: PropTypes.func,
  setBranchesData: PropTypes.func,
  branchesData: PropTypes.array
}

export default memo(CreateBranchForm);
