import React, {memo, useContext, useEffect, useState} from "react";
import "./index.scss";
import {useLocation, useNavigate} from "react-router-dom";
import Burger from "./burger";
import Notifications from "../../notifcations";
import {GoListUnordered} from "react-icons/go";
import {ImMap2} from "react-icons/im";
import cn from "classnames";
import AuthContext from "../../../context/auth/auth-context";
import PropTypes from 'prop-types';
import {createPortal} from "react-dom";
import UserProfile from "../user-profile";
import PreferencesContext from "../../../context/preferences/preferences-context";
import {Tooltip} from "react-tooltip";
import {useTranslation} from "react-i18next";
import Logo from "../../../assets/logo.png"
import {AiFillClockCircle, AiFillStar, AiOutlinePlusCircle} from "react-icons/ai";
import moment from "moment";
import {toPriceWithCurrency} from "../../../utils";
import {FaMountain} from "react-icons/fa";
import Loading from "../../ui/loading";
import AnalyticsContext from "../../../context/analytics/analytics-context";

function Header({
  setIsBurgerOpen,
  setIsNewOrderOpen,
  onToggleMenu,
  isBurgerOpen,
  setNotifications,
  notifications,
  hasSubscription,
  analyticsLoading
}) {

  const authCtx = useContext(AuthContext);
  const {list} = useContext(PreferencesContext);
  const analyticsCtx = useContext(AnalyticsContext);
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {t} = useTranslation();
  const [dateToday, setDateToday] = useState(moment(new Date()).format(`${list.date_format.value.format}, ${list.time_format.value.format}:ss ${list.time_format.value.format === "hh:mm" ? "A" : ""}`));
  const [notificationsHover, setNotificationsHover] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateToday(moment(new Date()).format(`${list.date_format.value.format}, ${list.time_format.value.format}:ss ${list.time_format.value.format === "hh:mm" ? "A" : ""}`));
    }, 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [list.date_format.value.format, list.time_format.value.format]);

  return (
    <div className={cn(
      "header",
      {"header--short": pathname !== (list.dashboard_view.value.type === 'list' ? '/dashboard/orders' : '/')}
    )}
    >
      <div className="header_in">
        {hasSubscription && (
          <Burger
            onClick={onToggleMenu}
            isBurgerOpen={isBurgerOpen}
          />
        )}
        <div
          className="header_in_logo"
          onClick={() => {
            if (hasSubscription) {
              setIsBurgerOpen(false)
              navigate('/')
            }
          }}
        >
          <img src={Logo} alt="taskroad logo"/>
        </div>
        <div className="header_in_clock">
          <p>{dateToday}</p>
        </div>
        {(list.dashboard_view.value.type === 'list' ? pathname === '/dashboard/orders' : pathname === '/') && (
            <div className="header_in_analytics">
              <div
                  className="header_in_analytics_item header_in_analytics_item--yellow"
                  data-tooltip-id={"tooltip-averageRating"}
              >
                <AiFillStar />
                {!analyticsLoading && (
                    <p>{analyticsCtx.analytics.analytics.averageRating.toFixed(2)}</p>
                )}
                {analyticsLoading && (
                    <Loading />
                )}
              </div>
              <div
                  className="header_in_analytics_item header_in_analytics_item--purple"
                  data-tooltip-id={"tooltip-averageDuration"}
              >
                <AiFillClockCircle />
                {!analyticsLoading && (
                    <p>{(analyticsCtx.analytics.analytics.averageDeliveryTimeMs / 1000 / 60).toFixed(2)} {t("components.layout.header.min")}</p>
                )}
                {analyticsLoading && (
                    <Loading />
                )}
              </div>
              <div
                  className="header_in_analytics_item header_in_analytics_item--green"
                  data-tooltip-id={"tooltip-averageAmount"}
              >
                <FaMountain />
                {!analyticsLoading && (
                    <p>{toPriceWithCurrency(analyticsCtx.analytics.analytics.averageAmount.toFixed(0), list.currency.value.currency.toUpperCase())}</p>
                )}
                {analyticsLoading && (
                    <Loading />
                )}
              </div>
            </div>
        )}
        <div className="header_in_actions">
          {(list.dashboard_view.value.type === 'list' ? pathname === '/dashboard/orders' : pathname === '/') && authCtx.user.role.permissions.includes("create_order") && (
            <button
                className="header_in_actions_btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsNewOrderOpen(true);
                }}
            >
              <AiOutlinePlusCircle />
              {t("components.layout.header.createOrder")}
            </button>
          )}
          {(pathname === '/' || pathname === '/dashboard/orders') && (
            <>
              <div className="header_nav">
                <div
                  className={cn("header_nav_tab", {'header_nav_tab--active' : pathname === '/'})}
                  onClick={() => navigate('/')}
                  data-tooltip-id={(list.dashboard_view.value.type === 'map') ? `tooltip-map` : "tooltip-orders"}
                >
                  {(list.dashboard_view.value.type === 'map') && (
                    <ImMap2 />
                  )}
                  {(list.dashboard_view.value.type === 'list') && (
                    <GoListUnordered />
                  )}
                </div>
            </div>
              <div className="header_nav">
                <div
                  className={cn("header_nav_tab", {'header_nav_tab--active' : pathname === '/dashboard/orders'})}
                  onClick={() => navigate('/dashboard/orders')}
                  data-tooltip-id={(list.dashboard_view.value.type === 'list') ? `tooltip-map` : "tooltip-orders"}
                >
                  {(list.dashboard_view.value.type === 'list') && (
                    <ImMap2 />
                  )}
                  {(list.dashboard_view.value.type === 'map') && (
                    <GoListUnordered />
                  )}
                </div>
              </div>
            </>
          )}
          {hasSubscription && (
            <>
              <Notifications
                setNotifications={setNotifications}
                notifications={notifications}
                notificationsHover={notificationsHover}
                setNotificationsHover={setNotificationsHover}
              />
              <div className="header_profile__separator" />
            </>
          )}
          <div className="header_profile">
            <div onClick={() => setShowProfile(true)}>
              <img data-tooltip-id={`tooltip-profile`} className="header_profile__img" src={authCtx.user.src} alt="user profile" />
            </div>
          </div>
        </div>
      </div>
      {authCtx && createPortal(
        <UserProfile
          id={authCtx.user.id}
          type={authCtx.user.type}
          user={authCtx.user}
          isProfileModalOpen={showProfile}
          setIsProfileModalOpen={setShowProfile}
        />,
        document.getElementById('modal')
      )}
      <Tooltip
          id={`tooltip-map`}
          place="bottom"
          className="tooltipClass"
      >{t("components.layout.header.map")}</Tooltip>
      <Tooltip
          id={`tooltip-orders`}
          place="bottom"
          className="tooltipClass"
      >{t("components.layout.header.orders")}</Tooltip>
      <Tooltip
          id={`tooltip-profile`}
          place="bottom"
          className="tooltipClass"
      >{t("components.layout.header.profile")}</Tooltip>
      <Tooltip
          id={`tooltip-averageRating`}
          place="bottom"
          className="tooltipClass"
      >{t("components.layout.header.averageRating")}</Tooltip>
      <Tooltip
          id={`tooltip-averageAmount`}
          place="bottom"
          className="tooltipClass"
      >{t("components.layout.header.averageAmount")}</Tooltip>
      <Tooltip
          id={`tooltip-averageDuration`}
          place="bottom"
          className="tooltipClass"
      >{t("components.layout.header.averageDuration")}</Tooltip>
    </div>
  );
}

Header.propTypes = {
  setIsBurgerOpen: PropTypes.func,
  setIsNewOrderOpen: PropTypes.func,
  onToggleMenu: PropTypes.func,
  isBurgerOpen: PropTypes.bool,
  setNotifications: PropTypes.func,
  notifications: PropTypes.array
};

export default memo(Header);
