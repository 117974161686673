import "./index.scss";
import React, {memo, useState} from "react";
import cn from "classnames";
import Loading from "../../../../components/ui/loading";
import {createPortal} from "react-dom";
import {useTranslation} from "react-i18next";

function BillingProductsModalCard({
name,
price,
currency,
descriptions,
onSelect,
recommended,
pending,
showButton
}) {
  const [alert, setAlert] = useState(false);
  const {t} = useTranslation();

  return (
    <div className={cn("billing_products_modal_card", {"billing_products_modal_card--recommended" : recommended})}>
      <div className="billing_products_modal_card_header">
        <p>{name}</p>
      </div>
      <div className="billing_products_modal_card_in">
        <h3><span>{currency}</span>{price}</h3>
        <ul>
          {descriptions.map((description, index) => (
            <li key={index}>{description}</li>
          ))}
        </ul>
      </div>
      {showButton && (
        <div className="billing_products_modal_card_actions">
          {!pending && (
            <button
              onClick={() => setAlert(true)}
            >Choose plan</button>
          )}
          {pending && (
            <button>
              <Loading />
            </button>
          )}
        </div>
      )}
      {alert && createPortal(
        <>
          <div
            className="billing_products_modal_card_alert"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="billing_products_modal_card_alert_title">
              {t("pages.billing.smsCreditsPurchaseWarningTitle")} {name}
            </div>
            <div className="billing_products_modal_card_alert_actions">
              <button
                className="billing_products_modal_card_alert_actions_btn billing_products_modal_card_alert_actions_btn--submit"
                onClick={() => {
                  setAlert(false);
                  onSelect();
                }}
              >{t("pages.billing.purchase")}</button>
            </div>
          </div>
          <div
            className="billing_products_modal_card_alert_overlay"
            onClick={() => setAlert(false)}
          ></div>
        </>, document.getElementById('modal'))}
    </div>
  );
}

export default memo(BillingProductsModalCard);
