import {memo, useState, useEffect, useMemo, useCallback, useContext} from "react";
import api from "../../utils/api";
import "./index.scss";
import PageHeader from "../../components/layout/page-header";
import Table from '../../components/table';
import Container from "../../components/layout/container";
import Status from "../../components/ui/status";
import Search from "../../components/search";
import AuthContext from "../../context/auth/auth-context";
import {useTranslation} from 'react-i18next';
import CreatePartnerForm from "./create-partner-form";
import Overlay from "../../components/ui/overlay";
import {TbTrashX} from "react-icons/tb";
import {MdSettingsBackupRestore} from "react-icons/md";
import SwitcherBtn from "../../components/ui/switcher-btn";
import cn from "classnames";
import sound from "../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {errToString} from "../../utils";
import {BsPlus} from "react-icons/bs";
import {createPortal} from "react-dom";

const SEARCH_FIELDS_PARTNERS = [
  {label: `name`, value: 'name'}
];

function Partners() {
  const {user} = useContext(AuthContext);
  const {t} = useTranslation();

  const [partners, setPartners] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [isCreatePartnerFormOpen, setIsCreatePartnerFormOpen] = useState(false);
  const [isDeletePartnerFormOpen, setIsDeletePartnerFormOpen] = useState(false);
  const [showFrozenPartners, setShowFrozenPartners] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const searchFieldsPartners = useMemo(() => SEARCH_FIELDS_PARTNERS.map(option => ({
    ...option,
    label: t(`pages.partners.${option.label}`)
  })), [t]);
  const partnerTableTitles = useMemo(() => [
    {
      name: t("pages.partners.partner")
    },
    {
      size: 100
    },
    {
      size: 50
    }
  ], [t]);
  const onSearch = useCallback(({ search, searchField }) => {
    setLoading(true);
    setPartners(null);
    api
      .get(`/partners?${search && searchField ? `&search=${search}&searchField=${searchField}` : ''}&freezed`)
      .then((res) => {
        setPartners(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(errToString(err));
        setLoading(false);
      });
  }, []);
  const onRestorePartner = useCallback((partner) => {

    if (
      partner.name === "Yandex" ||
      partner.name === "Glovo"
    ) {
      return alert("Partner could not be deleted!")
    }

    api
      .put(`/partners/${partner.id}`, {
        restore: true
      })
      .then(() => {
        setPartners(partners.map((data) => {
          if (partner.id === data.id) {
            return {
              ...data,
              deletedAt: null
            }
          }
          return data;
        }));
        const audio = new Audio(sound);
        const info = `${t("pages.partners.partner")} ${partner.name} ${t("pages.branches.branch_card.hasBeenUnfrozen")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch((err) => console.log(errToString(err)))
  }, [t, partners, setPartners]);
  const onDeletePartner = useCallback((e) => {
    e.preventDefault();

    if (
        selectedPartner.name === "Yandex" ||
        selectedPartner.name === "Glovo"
    ) {
      setIsDeletePartnerFormOpen(false);
      return alert("Partner could not be deleted!")
    }

    api
        .delete(`/partners/${selectedPartner.id}`)
        .then(() => {
          setPartners(partners.map((_partner) => {
            if (_partner.id === selectedPartner.id) {
              return {
                ..._partner,
                deletedAt: new Date()
              }
            }
            return _partner;
          }));
          setIsDeletePartnerFormOpen(false);
          const audio = new Audio(sound);
          const info = `${t("pages.partners.partner")} ${selectedPartner.name} ${t("pages.partners.hasBeenFrozen")}`;
          toast.info(info, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          audio.play();
        })
        .catch((err) => console.log(errToString(err)))
  }, [t, selectedPartner, partners, setPartners]);

  useEffect(() => {
    api
      .get(`/partners?${showFrozenPartners ? "&freezed" : ""}`)
      .then((res) => {
        setPartners(res.data.results);
        setLoading(false);
      })
      .catch(err => console.log(errToString(err)))
  }, [showFrozenPartners]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container>
      <div className="partners">
        <PageHeader>
          <p>{t("pages.partners.partners")}</p>
        </PageHeader>
        <div className="partners_in_actions">
          <Search
            placeholder={t("pages.partners.searchPartner")}
            options={searchFieldsPartners}
            onSearch={onSearch}
          />
          <div className="partners_in_actions__switcher">
            <SwitcherBtn
              checked={showFrozenPartners}
              setChecked={setShowFrozenPartners}
              type={"freeze-unfreeze-managers"}
            />
          </div>
          {user.role.permissions.includes('update_partners') && (
            <button
              onClick={() => setIsCreatePartnerFormOpen(true)}
            >
              {windowWidth > 600 ? t("components.form.createPartner") : <BsPlus />}
            </button>
          )}
        </div>
        <div className="partners_in">
          <Table
            route={user.role.permissions.includes("see_partner_details") ? "partners" : ""}
            titles={partnerTableTitles}
            loading={loading}
            hiddenFields={['id', 'deletedAt']}
            rows={partners?.map(partner => ({
              id: partner.id,
              deletedAt: partner.deletedAt,
              partner: (
                <div className="table__avatar">
                  <img src={partner.src} alt="partner"/>
                  <p>{partner.name}</p>
                </div>
              ),
              isActive: (
                <Status
                  type="table"
                  status={partner.deletedAt ? "freezed" : "active"}
                />
              ),
              delete_unfreeze: partner.name?.toLowerCase() !== "glovo" && partner.name?.toLowerCase() !== "yandex" && user.role.permissions.includes('update_partners') && (
                <div
                  className={cn("partners_in_icon", {"partners_in_icon--delete" : !partner.deletedAt}, {"partners_in_icon--restore" : partner.deletedAt})}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (!partner.deletedAt) {
                      setIsDeletePartnerFormOpen(true);
                      return setSelectedPartner(partner);
                    }
                    if (partner.deletedAt) {
                      return onRestorePartner(partner)
                    }
                  }}
                >
                  {!partner.deletedAt ? <TbTrashX/> : <MdSettingsBackupRestore/>}
                </div>
              )
            })) || []}
          />
        </div>
        <div className="partners_footer" />
      </div>
      {isCreatePartnerFormOpen && (
        <CreatePartnerForm
          onCancel={() => setIsCreatePartnerFormOpen(false)}
          partners={partners}
          setPartners={setPartners}
        />
      )}
      {isDeletePartnerFormOpen && createPortal(
          <>
            <div
                className="agents_in_freeze_alert"
                onClick={(e) => e.stopPropagation()}
            >
              <div className="agents_in_freeze_alert_title">{t("pages.partners.warningFreeze")} {selectedPartner.name}</div>
              <div className="agents_in_freeze_alert_actions">
                <button
                    className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_cancel"
                    onClick={() => setIsDeletePartnerFormOpen(false)}
                >{t("pages.agents.cancel")}</button>
                <button
                    className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_submit agents_in_freeze_alert_btn_submit--freeze"
                    onClick={onDeletePartner}
                >{t("pages.agents.freeze")}</button>
              </div>
            </div>
          </>, document.getElementById('modal')
      )}
      {(isCreatePartnerFormOpen || isDeletePartnerFormOpen) && (
        <Overlay
          onClick={() => {
            setIsDeletePartnerFormOpen(false);
            setIsCreatePartnerFormOpen(false);
          }}
        />
      )}
    </Container>
  );
}

export default memo(Partners);
