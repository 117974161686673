import React, {memo, useCallback, useState} from "react";
import Form from "../../../../../components/form";
import FormRow from "../../../../../components/form/form-row";
import FormCol from "../../../../../components/form/form-col";
import Loading from "../../../../../components/ui/loading";
import {useTranslation} from "react-i18next";
import api from "../../../../../utils/api";
import {errToString} from "../../../../../utils";
import cn from "classnames";
import Taskroad from "../../../../../assets/logo.png"
import Dexatel from "../../../../../assets/dexatel.svg"
import Twilio from "../../../../../assets/twilio.png"
import Paradox from "../../../../../assets/paradox.svg"
import {RiExternalLinkLine} from "react-icons/ri";

function SettingsIntegrationsSmsAllBranches({
selectedSmsChannel,
setSelectedSmsChannel,
smsChannels
}) {

  const {t} = useTranslation();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [pending, setPending] = useState(false);

  // const onChangeSmsType = useCallback((e) => {
  //   setSelectedSmsChannel(e);
  //   setSuccess(null);
  //   setError(null);
  // }, [setSelectedSmsChannel]);

  //TODO Need to send sms Activation true;
  //TODO Check submit request

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setPending(true);

    const data = {
      name: 'sms',
      isActive: true
    };

    if (selectedSmsChannel.value === "paradox") {
      data.credentials = {
        login: selectedSmsChannel.credentials.login,
        password: selectedSmsChannel.credentials.password,
        sourceNumber: selectedSmsChannel.credentials.sourceNumber
      }
    }
    if (selectedSmsChannel.value === "dexatel") {
      data.credentials = {
        apiKey: selectedSmsChannel.credentials.apiKey,
        sender: selectedSmsChannel.credentials.sender
      }
    }
    if (selectedSmsChannel.value === "twilio") {
      data.credentials = {
        sid: selectedSmsChannel.credentials.sid,
        authToken: selectedSmsChannel.credentials.authToken,
        from: selectedSmsChannel.credentials.from
      }
    }

    api
      .put(`/company/integrations/${selectedSmsChannel.id}`, data)
      .then(() => {
        setSuccess(t("pages.settings.smsSuccess"));
        setError(null);
        setPending(false);
      })
      .catch((err) => {
        console.log(errToString(err))
        setSuccess(null);
        setPending(false);
      })

  }, [t, selectedSmsChannel]);

  return (
    <div className="settings_integrations_sms_in_left_wrapper">
      <p className="settings_integrations_sms_in_left_descrip">
        {selectedSmsChannel?.value === "default" && (
          t("pages.settings.smsDefault")
        )}
        {selectedSmsChannel?.value !== "default" && (
          t("pages.settings.smsNotDefault")
        )}
      </p>
      {/*<div className="settings_integrations_sms_in_drop_down">*/}
      {/*  <DropDown*/}
      {/*    hasSmsIcon={true}*/}
      {/*    placeholder={t('components.search.chooseOptions')}*/}
      {/*    options={smsChannels}*/}
      {/*    value={selectedSmsChannel}*/}
      {/*    onChange={onChangeSmsType}*/}
      {/*    position="bottom"*/}
      {/*    showClose={false}*/}
      {/*  />*/}
      {/*</div>*/}
      <ul className="settings_integrations_sms_in_list">
        {smsChannels.filter(sms => sms.label === "default").map((sms, index) => (
          <li
            key={`${sms.id} ${index}`}
            className={cn("settings_integrations_sms_in_list_item", {"settings_integrations_sms_in_list_item--active" : sms.id === selectedSmsChannel.id})}
            onClick={() => {
              setSelectedSmsChannel(sms);
              setSuccess(null);
              setError(null);
            }}
          >
            <img src={Taskroad} alt={sms.label}/>
            <h3>{sms.label}</h3>
            <a href="https://taskroad.pro" target={"_blank"} rel="noreferrer">
              {t("general.learnMore")}
              <RiExternalLinkLine style={{marginLeft: "5px"}}/>
            </a>
          </li>
        ))}
        {smsChannels.filter(sms => sms.label !== "default").map((sms, index) => (
          <li
            key={`${sms.id} ${index}`}
            className={cn("settings_integrations_sms_in_list_item", {"settings_integrations_sms_in_list_item--active" : sms.id === selectedSmsChannel.id})}
            onClick={() => {
              setSelectedSmsChannel(sms);
              setSuccess(null);
              setError(null);
            }}
          >
            {sms.label === "default" && (
              <>
                <img src={Taskroad} alt={sms.label}/>
                <h3>{sms.label}</h3>
                <a href="https://taskroad.pro" target={"_blank"} rel="noreferrer">
                  {t("general.learnMore")}
                  <RiExternalLinkLine style={{marginLeft: "5px"}}/>
                </a>
              </>
            )}
            {sms.label === "twilio" && (
              <>
                <img src={Twilio} alt={sms.label}/>
                <h3>{sms.label}</h3>
                <a href="https://twilio.com/en-us" target={"_blank"} rel="noreferrer">
                  {t("general.learnMore")}
                  <RiExternalLinkLine style={{marginLeft: "5px"}}/>
                </a>
              </>
            )}
            {sms.label === "dexatel" && (
              <>
                <img src={Dexatel} alt={sms.label}/>
                <h3>{sms.label}</h3>
                <a href="https://dexatel.com/" target={"_blank"} rel="noreferrer">
                  {t("general.learnMore")}
                  <RiExternalLinkLine style={{marginLeft: "5px"}}/>
                </a>
              </>
            )}
            {sms.label === "paradox" && (
              <>
                <img src={Paradox} alt={sms.label}/>
                <h3>{sms.label}</h3>
                <a href="https://www.paradox.am/" target={"_blank"} rel="noreferrer">
                  {t("general.learnMore")}
                  <RiExternalLinkLine style={{marginLeft: "5px"}}/>
                </a>
              </>
            )}
          </li>
        ))}
      </ul>
      {selectedSmsChannel && (
        <div className="settings_integrations_sms_in_options">
          {selectedSmsChannel?.value === 'default' && (
            <div className="settings_integrations_sms_in_form">
              <Form onSubmit={onSubmit}>
                <FormRow>
                  <FormCol>
                    {!pending && (
                      <button className="create_order_submit_btn">
                        {t("pages.branches.create_branch_form.save")}
                      </button>
                    )}
                    {pending && (
                      <button className="create_order_submit_btn create_order_submit_btn--pending">
                        <Loading />
                      </button>
                    )}
                    {success && (
                      <p className="settings_integrations_sms_in_form_success">{success}</p>
                    )}
                    {error && (
                      <p className="settings_integrations_sms_in_form_error">{error}</p>
                    )}
                  </FormCol>
                </FormRow>
              </Form>
            </div>
          )}
          {selectedSmsChannel?.value === 'paradox' && (
            <div className="settings_integrations_sms_in_form">
              <Form onSubmit={onSubmit}>
                <FormRow>
                  <FormCol>
                    <label htmlFor="login">
                      <span>*</span>
                      Login
                    </label>
                    <input
                      name="login"
                      type="text"
                      placeholder="Login"
                      autoComplete="off"
                      value={selectedSmsChannel.credentials.login}
                      onChange={(e) => {
                        return setSelectedSmsChannel({
                          ...selectedSmsChannel,
                          credentials: {
                            ...selectedSmsChannel.credentials,
                            login: e.target.value
                          }
                        })
                      }}
                    />
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label htmlFor="password">
                      <span>*</span>
                      Password
                    </label>
                    <input
                      name="password"
                      type="text"
                      placeholder="Password"
                      autoComplete="off"
                      value={selectedSmsChannel.credentials.password}
                      onChange={(e) => setSelectedSmsChannel({
                        ...selectedSmsChannel,
                        credentials: {
                          ...selectedSmsChannel.credentials,
                          password: e.target.value
                        }
                      })}
                    />
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label htmlFor="sourceNumber">
                      <span>*</span>
                      Source number
                    </label>
                    <input
                      name="sourceNumber"
                      type="text"
                      placeholder="Source number"
                      autoComplete="off"
                      value={selectedSmsChannel.credentials.sourceNumber}
                      onChange={(e) => setSelectedSmsChannel({
                        ...selectedSmsChannel,
                        credentials: {
                          ...selectedSmsChannel.credentials,
                          sourceNumber: e.target.value
                        }
                      })}
                    />
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    {!pending && (
                      <button className="create_order_submit_btn">
                        {t("pages.branches.create_branch_form.save")}
                      </button>
                    )}
                    {pending && (
                      <button className="create_order_submit_btn create_order_submit_btn--pending">
                        <Loading />
                      </button>
                    )}
                    {success && (
                      <p className="settings_integrations_sms_in_form_success">{success}</p>
                    )}
                    {error && (
                      <p className="settings_integrations_sms_in_form_error">{error}</p>
                    )}
                  </FormCol>
                </FormRow>
              </Form>
            </div>
          )}
          {selectedSmsChannel?.value === 'dexatel' && (
            <div className="settings_integrations_sms_in_form">
              <Form onSubmit={onSubmit}>
                <FormRow>
                  <FormCol>
                    <label htmlFor="apiKey">
                      <span>*</span>
                      apiKey
                    </label>
                    <input
                      name="apiKey"
                      type="text"
                      placeholder="apiKey"
                      autoComplete="off"
                      value={selectedSmsChannel.credentials.apiKey}
                      onChange={(e) => {
                        return setSelectedSmsChannel({
                          ...selectedSmsChannel,
                          credentials: {
                            ...selectedSmsChannel.credentials,
                            apiKey: e.target.value
                          }
                        })
                      }}
                    />
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label htmlFor="sender">
                      <span>*</span>
                      Sender
                    </label>
                    <input
                      name="password"
                      type="text"
                      placeholder="Sender"
                      autoComplete="off"
                      value={selectedSmsChannel.credentials.sender}
                      onChange={(e) => setSelectedSmsChannel({
                        ...selectedSmsChannel,
                        credentials: {
                          ...selectedSmsChannel.credentials,
                          sender: e.target.value
                        }
                      })}
                    />
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    {!pending && (
                      <button className="create_order_submit_btn">
                        {t("pages.branches.create_branch_form.save")}
                      </button>
                    )}
                    {pending && (
                      <button className="create_order_submit_btn create_order_submit_btn--pending">
                        <Loading />
                      </button>
                    )}
                    {success && (
                      <p className="settings_integrations_sms_in_form_success">{success}</p>
                    )}
                    {error && (
                      <p className="settings_integrations_sms_in_form_error">{error}</p>
                    )}
                  </FormCol>
                </FormRow>
              </Form>
            </div>
          )}
          {selectedSmsChannel?.value === 'twilio' && (
            <div className="settings_integrations_sms_in_form">
              <Form onSubmit={onSubmit}>
                <FormRow>
                  <FormCol>
                    <label htmlFor="sid">
                      <span>*</span>
                      SID
                    </label>
                    <input
                      name="sid"
                      type="text"
                      placeholder="SID"
                      autoComplete="off"
                      value={selectedSmsChannel.credentials.sid}
                      onChange={(e) => {
                        return setSelectedSmsChannel({
                          ...selectedSmsChannel,
                          credentials: {
                            ...selectedSmsChannel.credentials,
                            sid: e.target.value
                          }
                        })
                      }}
                    />
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label htmlFor="authToken">
                      <span>*</span>
                      Auth Token
                    </label>
                    <input
                      name="authToken"
                      type="text"
                      placeholder="Auth Token"
                      autoComplete="off"
                      value={selectedSmsChannel.credentials.authToken}
                      onChange={(e) => setSelectedSmsChannel({
                        ...selectedSmsChannel,
                        credentials: {
                          ...selectedSmsChannel.credentials,
                          authToken: e.target.value
                        }
                      })}
                    />
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label htmlFor="from">
                      <span>*</span>
                      From
                    </label>
                    <input
                      name="from"
                      type="text"
                      placeholder="From"
                      autoComplete="off"
                      value={selectedSmsChannel.credentials.from}
                      onChange={(e) => setSelectedSmsChannel({
                        ...selectedSmsChannel,
                        credentials: {
                          ...selectedSmsChannel.credentials,
                          from: e.target.value
                        }
                      })}
                    />
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    {!pending && (
                      <button className="create_order_submit_btn">
                        {t("pages.branches.create_branch_form.save")}
                      </button>
                    )}
                    {pending && (
                      <button className="create_order_submit_btn create_order_submit_btn--pending">
                        <Loading />
                      </button>
                    )}
                    {success && (
                      <p className="settings_integrations_sms_in_form_success">{success}</p>
                    )}
                    {error && (
                      <p className="settings_integrations_sms_in_form_error">{error}</p>
                    )}
                  </FormCol>
                </FormRow>
              </Form>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default memo(SettingsIntegrationsSmsAllBranches);
