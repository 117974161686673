import './index.scss';
import {memo,useCallback,useState,useMemo,useEffect,useContext} from 'react';
import api from "../../../utils/api";
import DropDown from '../../../components/ui/drop-down';
import Form from '../../../components/form'
import FormRow from "../../../components/form/form-row";
import FormCol from "../../../components/form/form-col";
import PropTypes from "prop-types";
import {FaMapMarkerAlt} from "react-icons/fa";
import {MdPersonOutline} from "react-icons/md";
import {useDebouncedCallback} from "use-debounce";
import {getAddressFromLatLng, getLatLngFromAddress, searchAddress} from "../../../utils/geocoding";
import {useTranslation} from "react-i18next";
import {BsDoorClosed} from "react-icons/bs";
import {BiHomeSmile} from "react-icons/bi";
import {TbStairs} from "react-icons/tb";
import Loading from "../../../components/ui/loading";
import {errToString} from "../../../utils";
import sound from "../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import PhoneNumberInput from "../../../components/ui/phone-number-input";
import IntegrationsContext from "../../../context/integrations/integrations-context";
import AuthContext from "../../../context/auth/auth-context";

function CreateCustomer({
onClose,
setCustomers,
customers
}) {
  const integrations = useContext(IntegrationsContext);
  const {user} = useContext(AuthContext);
  const {t} = useTranslation();
  const [pending, setPending] = useState(false);
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    address: null,
    apartment: '',
    entrance: '',
    floor: '',
    lat: '',
    lng: ''
  });
  const [error, setError] = useState(null);
  const [geocodingAddresses, setGeocodingAddresses] = useState([]);
  const [addressLoading, setAddressLoading] = useState(false);
  const [addressFromMap, setAddressFromMap] = useState(null);
  const [phoneNumberLoading, setPhoneNumberLoading] = useState(false);
  const [phoneNumberPassed, setPhoneNumberPassed] = useState(false);
  const [phoneNumber, setPhoneNumber]= useState('');
  const [parsedPhoneNumber, setParsedPhoneNumber] = useState(null);
  const [showPhoneNumberError, setShowPhoneNumberError] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const addresses = useMemo(() => {
    const addresses = [...geocodingAddresses];

    if (addressFromMap) {
      addresses.unshift(addressFromMap);
    }

    return addresses;
  }, [geocodingAddresses, addressFromMap]);
  const onChangeAddress = useDebouncedCallback(value => {
    if (!value) {
      return
    }

    setAddressLoading(true);

    const data = {};
    if (integrations.mapIntegration.value === "open_street") {
      data.type = "default"
    }
    if (integrations.mapIntegration.value === "google") {
      data.type = integrations.mapIntegration.value;
      data.apiKey = integrations.mapIntegration?.credentials?.apiKey;
    }

    searchAddress(user.address.countryCode, value, (err, addresses) => {
      setGeocodingAddresses(addresses);
      setAddressLoading(false)
    }, data);
  }, 1000);
  const onResetAddress = useCallback(() => {
    setFields({
      ...fields,
      address: null,
      apartment: '',
      entrance: '',
      floor: '',
      lat: '',
      lng: '',
    });
    setGeocodingAddresses([]);
    setAddressFromMap(null);
  }, [fields]);
  const onChangeField = useCallback((e, name) => {
    setError(null);

    if (
      name === 'lat' ||
      name === 'lng'
    ) {
      const lat = name === 'lat' ? e.target.value : fields.lat;
      const lng = name === 'lng' ? e.target.value : fields.lng;

      const data = {};
      if (integrations.mapIntegration.value === "open_street") {
        data.type = "default"
      }
      if (integrations.mapIntegration.value === "google") {
        data.type = integrations.mapIntegration.value;
        data.apiKey = integrations.mapIntegration?.credentials?.apiKey;
      }

      return getAddressFromLatLng(lat, lng, address => {
        setAddressFromMap(address);
        setFields({
          ...fields,
          address,
          lat,
          lng
        })
      }, data);
    }

    if (name === 'address') {
      const data = {};
      if (integrations.mapIntegration.value === "open_street") {
        data.type = "default"
      }
      if (integrations.mapIntegration.value === "google") {
        data.type = integrations.mapIntegration.value;
        data.apiKey = integrations.mapIntegration?.credentials?.apiKey;
      }

      return getLatLngFromAddress(e.label, location => {
        return setFields({
          ...fields,
          [name]: e,
          address: {
            ...e,
            location
          },
          apartment: e?.apartment || '',
          entrance: e?.entrance || '',
          floor: e?.entrance || '',
          lat: location.lat,
          lng: location.lng
        });
      }, data);
    }

    if (
      e.target.type === 'text' ||
      e.target.type === 'number'
    ) {
      return setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [fields, integrations.mapIntegration?.credentials?.apiKey, integrations.mapIntegration.value]);
  const onCheckValidation = useDebouncedCallback((field, link) => {
    setError(null);

    if (!phoneNumber) {
      setShowPhoneNumberError(false)
    }
    if (!field) {
      setPhoneNumberLoading(false);
      return;
    }


    if (link === "phone-number") {
      if (!parsedPhoneNumber) {
        return
      }

      setPhoneNumberLoading(true);

      if (parsedPhoneNumber) {
        if (!parsedPhoneNumber.isPossible || !parsedPhoneNumber.isValid) {
          setPhoneNumberPassed(false);
          setPhoneNumberLoading(false);
          setShowPhoneNumberError(true);
          return setError(errToString("phoneNumber.invalid"));
        }
      }

      if (parsedPhoneNumber) {
        if (!parsedPhoneNumber.data) {
          setPhoneNumberPassed(false);
          setPhoneNumberLoading(false);
          setShowPhoneNumberError(false);
          return setError(null);
        }
      }

      if (parsedPhoneNumber) {
        if (parsedPhoneNumber.isPossible && parsedPhoneNumber.isValid && parsedPhoneNumber.data) {
          return (
            api
              .get(`/validations/phone-number?phoneNumber=${field}&countryCode=${parsedPhoneNumber.data.country?.toLowerCase()}`)
              .then(() => {
                setPhoneNumberLoading(false);
                setPhoneNumberPassed(true);
                setShowPhoneNumberError(false);
                setError(null);
              })
              .catch((err) => {
                console.log(errToString(err));
                setPhoneNumberPassed(false);
                setPhoneNumberLoading(false);
                setShowPhoneNumberError(true);
                setError(errToString(err));
              })
          )
        }
      }
    }

  }, 500);
  const onSubmit = useCallback(e => {
    e.preventDefault();

    const data = {
      phoneNumber: phoneNumber,
      firstName: fields.firstName || null,
      lastName: fields.lastName || null,
      address: {
        countryCode: user.address.countryCode,
        deliveryAddress: fields.address?.label,
        apartment: fields.apartment || null,
        entrance: fields.entrance || null,
        floor: fields.floor || null,
        location: fields.address?.location
      }
    };

    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    for (const key in data.address) {
      if (data.address[key] === null) {
        delete data.address[key];
      }
    }

    setPending(true);
    api
      .post("/customers", data)
      .then(res => {
        setCustomers([
          res.data,
          ...customers
        ])
        setPending(false);
        const audio = new Audio(sound);
        const info = t("pages.customers.create_customer.createCustomerNotification");
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
        onClose();
        onClose();
      })
      .catch(err => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      });
  }, [t, phoneNumber, fields, customers, onClose, setCustomers, user]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (!phoneNumber) {
      return
    }

    setPhoneNumberPassed(false);
    setShowPhoneNumberError(false);
    setError(null);
    onCheckValidation(phoneNumber, "phone-number");
  }, [phoneNumber, onCheckValidation]);

  return (
    <div className="create_customer">
      <div className="create_customer_in">
        <Form
          title={t("pages.customers.create_customer.createCustomer")}
          error={error && t(`errors.${error?.toLowerCase()}`)}
          onSubmit={onSubmit}
        >
          {windowWidth > 700 && (
            <>
              <FormRow>
                <FormCol>
                  <label htmlFor="phoneNumber">
                    <span>*</span>
                    {t("pages.customers.create_customer.phoneNumber")}
                  </label>
                  <PhoneNumberInput
                    country={"AM"}
                    placeholder={t("pages.signUp.phoneNumber")}
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    loading={phoneNumberLoading}
                    passed={phoneNumberPassed}
                    showError={showPhoneNumberError}
                    setParsedPhoneNumber={setParsedPhoneNumber}
                  />
                </FormCol>
                <FormCol>
                  <label htmlFor="firstName">
                    {t("pages.customers.create_customer.firstName")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <MdPersonOutline />
                    </div>
                    <input
                      name="firstName"
                      className="create_order_wrapper_input"
                      type="text"
                      placeholder={t("pages.customers.create_customer.firstName")}
                      autoComplete="off"
                      value={fields.firstName}
                      onChange={e => onChangeField(e, 'firstName')}
                    />
                  </div>
                </FormCol>
                <FormCol>
                  <label htmlFor="lastName">
                    {t("pages.customers.create_customer.lastName")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <MdPersonOutline />
                    </div>
                    <input
                      name="lastName"
                      type="text"
                      className="create_order_wrapper_input"
                      placeholder={t("pages.customers.create_customer.lastName")}
                      autoComplete="off"
                      value={fields.lastName}
                      onChange={e => onChangeField(e, 'lastName')}
                    />
                  </div>
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <label>
                    <span>*</span>
                    {t("components.create_order.deliveryAddress")}
                  </label>
                  <DropDown
                    hasMarkerIcon={true}
                    placeholder={t("pages.customers.create_customer.address")}
                    loading={addressLoading}
                    options={addresses.map((addresses, index) => ({
                      option: addresses,
                      el: (
                        <li
                          className='create_order_custom_dropdown_menu'
                          key={index}
                        >
                          <FaMapMarkerAlt /> {addresses.label}
                        </li>
                      )
                    }))}
                    value={fields.address}
                    onSearch={onChangeAddress}
                    onChange={option => onChangeField(option, 'address')}
                    onReset={onResetAddress}
                  />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <label>
                    {t("components.create_order.apartment")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <BiHomeSmile />
                    </div>
                    <input
                      type="text"
                      className="create_order_wrapper_input"
                      placeholder={t("components.create_order.apartment")}
                      autoComplete="off"
                      value={fields.apartment}
                      onChange={e => onChangeField(e, 'apartment')}
                    />
                  </div>
                </FormCol>
                <FormCol>
                  <label>
                    {t("components.create_order.entrance")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <BsDoorClosed />
                    </div>
                    <input
                      type="text"
                      className="create_order_wrapper_input"
                      autoComplete="off"
                      placeholder={t("components.create_order.entrance")}
                      value={fields.entrance}
                      onChange={e => onChangeField(e, 'entrance')}
                    />
                  </div>
                </FormCol>
                <FormCol>
                  <label>
                    {t("components.create_order.floor")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <TbStairs />
                    </div>
                    <input
                      type="text"
                      className="create_order_wrapper_input"
                      placeholder={t("components.create_order.floor")}
                      autoComplete="off"
                      value={fields.floor}
                      onChange={e => onChangeField(e, 'floor')}
                    />
                  </div>
                </FormCol>
              </FormRow>
            </>
          )}
          {windowWidth > 500 && windowWidth <= 700 && (
            <>
              <FormRow>
                <FormCol>
                  <label htmlFor="firstName">
                    {t("pages.customers.create_customer.firstName")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <MdPersonOutline />
                    </div>
                    <input
                      name="firstName"
                      className="create_order_wrapper_input"
                      type="text"
                      placeholder={t("pages.customers.create_customer.firstName")}
                      autoComplete="off"
                      value={fields.firstName}
                      onChange={e => onChangeField(e, 'firstName')}
                    />
                  </div>
                </FormCol>
                <FormCol>
                  <label htmlFor="lastName">
                    {t("pages.customers.create_customer.lastName")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <MdPersonOutline />
                    </div>
                    <input
                      name="lastName"
                      type="text"
                      className="create_order_wrapper_input"
                      placeholder={t("pages.customers.create_customer.lastName")}
                      autoComplete="off"
                      value={fields.lastName}
                      onChange={e => onChangeField(e, 'lastName')}
                    />
                  </div>
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <label htmlFor="phoneNumber">
                    <span>*</span>
                    {t("pages.customers.create_customer.phoneNumber")}
                  </label>
                  <PhoneNumberInput
                    country={"AM"}
                    placeholder={t("pages.signUp.phoneNumber")}
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    loading={phoneNumberLoading}
                    passed={phoneNumberPassed}
                    showError={showPhoneNumberError}
                    setParsedPhoneNumber={setParsedPhoneNumber}
                  />
                </FormCol>
                <FormCol>
                  <label>
                    <span>*</span>
                    {t("components.create_order.deliveryAddress")}
                  </label>
                  <div style={{width: '100%'}}>
                    <DropDown
                      hasMarkerIcon={true}
                      placeholder={t("pages.customers.create_customer.address")}
                      loading={addressLoading}
                      options={addresses.map((addresses, index) => ({
                        option: addresses,
                        el: (
                          <li
                            className='create_order_custom_dropdown_menu'
                            key={index}
                          >
                            <FaMapMarkerAlt /> {addresses.label}
                          </li>
                        )
                      }))}
                      value={fields.address}
                      onSearch={onChangeAddress}
                      onChange={option => onChangeField(option, 'address')}
                      onReset={onResetAddress}
                    />
                  </div>
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <label>
                    {t("components.create_order.apartment")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <BiHomeSmile />
                    </div>
                    <input
                      type="text"
                      className="create_order_wrapper_input"
                      placeholder={t("components.create_order.apartment")}
                      autoComplete="off"
                      value={fields.apartment}
                      onChange={e => onChangeField(e, 'apartment')}
                    />
                  </div>
                </FormCol>
                <FormCol>
                  <label>
                    {t("components.create_order.entrance")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <BsDoorClosed />
                    </div>
                    <input
                      type="text"
                      className="create_order_wrapper_input"
                      autoComplete="off"
                      placeholder={t("components.create_order.entrance")}
                      value={fields.entrance}
                      onChange={e => onChangeField(e, 'entrance')}
                    />
                  </div>
                </FormCol>
                <FormCol>
                  <label>
                    {t("components.create_order.floor")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <TbStairs />
                    </div>
                    <input
                      type="text"
                      className="create_order_wrapper_input"
                      placeholder={t("components.create_order.floor")}
                      autoComplete="off"
                      value={fields.floor}
                      onChange={e => onChangeField(e, 'floor')}
                    />
                  </div>
                </FormCol>
              </FormRow>
            </>
          )}
          {windowWidth <= 500 && (
            <>
              <FormRow>
                <FormCol>
                  <label htmlFor="firstName">
                    {t("pages.customers.create_customer.firstName")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <MdPersonOutline />
                    </div>
                    <input
                      name="firstName"
                      className="create_order_wrapper_input"
                      type="text"
                      placeholder={t("pages.customers.create_customer.firstName")}
                      autoComplete="off"
                      value={fields.firstName}
                      onChange={e => onChangeField(e, 'firstName')}
                    />
                  </div>
                </FormCol>
                <FormCol>
                  <label htmlFor="lastName">
                    {t("pages.customers.create_customer.lastName")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <MdPersonOutline />
                    </div>
                    <input
                      name="lastName"
                      type="text"
                      className="create_order_wrapper_input"
                      placeholder={t("pages.customers.create_customer.lastName")}
                      autoComplete="off"
                      value={fields.lastName}
                      onChange={e => onChangeField(e, 'lastName')}
                    />
                  </div>
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <label htmlFor="phoneNumber">
                    <span>*</span>
                    {t("pages.customers.create_customer.phoneNumber")}
                  </label>
                  <PhoneNumberInput
                    country={"AM"}
                    placeholder={t("pages.signUp.phoneNumber")}
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    loading={phoneNumberLoading}
                    passed={phoneNumberPassed}
                    showError={showPhoneNumberError}
                    setParsedPhoneNumber={setParsedPhoneNumber}
                  />
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <label>
                    <span>*</span>
                    {t("components.create_order.deliveryAddress")}
                  </label>
                  <div style={{width: '100%'}}>
                    <DropDown
                      hasMarkerIcon={true}
                      placeholder={t("pages.customers.create_customer.address")}
                      loading={addressLoading}
                      options={addresses.map((addresses, index) => ({
                        option: addresses,
                        el: (
                          <li
                            className='create_order_custom_dropdown_menu'
                            key={index}
                          >
                            <FaMapMarkerAlt /> {addresses.label}
                          </li>
                        )
                      }))}
                      value={fields.address}
                      onSearch={onChangeAddress}
                      onChange={option => onChangeField(option, 'address')}
                      onReset={onResetAddress}
                    />
                  </div>
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol>
                  <label>
                    {t("components.create_order.apartment")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <BiHomeSmile />
                    </div>
                    <input
                      type="text"
                      className="create_order_wrapper_input"
                      placeholder={t("components.create_order.apartment")}
                      autoComplete="off"
                      value={fields.apartment}
                      onChange={e => onChangeField(e, 'apartment')}
                    />
                  </div>
                </FormCol>
                <FormCol>
                  <label>
                    {t("components.create_order.entrance")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <BsDoorClosed />
                    </div>
                    <input
                      type="text"
                      className="create_order_wrapper_input"
                      autoComplete="off"
                      placeholder={t("components.create_order.entrance")}
                      value={fields.entrance}
                      onChange={e => onChangeField(e, 'entrance')}
                    />
                  </div>
                </FormCol>
                <FormCol>
                  <label>
                    {t("components.create_order.floor")}
                  </label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <TbStairs />
                    </div>
                    <input
                      type="text"
                      className="create_order_wrapper_input"
                      placeholder={t("components.create_order.floor")}
                      autoComplete="off"
                      value={fields.floor}
                      onChange={e => onChangeField(e, 'floor')}
                    />
                  </div>
                </FormCol>
              </FormRow>
            </>
          )}
          <FormRow>
            <FormCol>
              <a
                href="!#"
                className="create_customer__cancel_btn"
                onClick={onClose}
              >{t("pages.customers.create_customer.cancel")}</a>
            </FormCol>
            <FormCol>
              {!pending && (
                <button className="create_customer__submit_btn">{t("pages.customers.create_customer.createCustomerBtn")}</button>
              )}
              {pending && (
                <button className="create_customer__submit_btn create_customer__submit_btn--pending">
                  <Loading />
                </button>
              )}
            </FormCol>
          </FormRow>
        </Form>
      </div>
    </div>
  );
}

CreateCustomer.propTypes = {
  onClose: PropTypes.func,
  customers: PropTypes.array.isRequired
};

export default memo(CreateCustomer);
