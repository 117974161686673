import {memo, useContext, useEffect, useState} from "react";
import {Routes, Route, useNavigate, useLocation} from "react-router-dom";
import {toast} from 'react-toastify';
import sound from './assets/audio/notification.mp3';
import PropTypes from 'prop-types';
import Dashboard from "./pages/dashboard";
import Settings from "./pages/settings";
import Auth from "./pages/auth";
import NotFound from "./pages/not-found";
import AuthContext from "./context/auth/auth-context";
import Orders from "./pages/dashboard/orders";
import Agents from "./pages/agents";
import AgentDetails from "./pages/agents/agent-details";
import Customers from "./pages/customers";
import CustomerDetails from "./pages/customers/customer-details";
import Managers from "./pages/managers";
import ManagerDetails from "./pages/managers/manager-details";
import TrackingLink from "./pages/tracking-link";
import ForgotPassword from "./pages/auth/forgot-password";
import SocketContext from './context/socket/socket-context';
import SettingsCompanyPaymentMethods from "./pages/settings/settings-company/settings-company-payment-methods";
import SettingsCompanyRoles from "./pages/settings/settings-company/settings-company-roles";
import SettingsCompanyNotifications from "./pages/settings/settings-company/settings-company-notifications";
import SettingsCompanyPreferences from "./pages/settings/settings-company/settings-company-preferences";
import SettingsIntegrationsMaps from "./pages/settings/settings-integrations/settings-integrations-maps";
import SettingsIntegrationsSms from "./pages/settings/settings-integrations/settings-integrations-sms";
import SettingsCompanyAgents from "./pages/settings/settings-company/settings-company-agents";
import Branches from "./pages/branches";
import PreferencesContext from "./context/preferences/preferences-context";
import Partners from "./pages/partners";
import PartnerDetails from "./pages/partners/partner-details";
import CreateEmployee from "./pages/auth/create-employee";
import SignUp from "./pages/auth/sign-up";
import SettingsCompanyReviews from "./pages/settings/settings-company/settings-company-review";
import Admin from "./pages/admin";
import Analytics from "./pages/analytics";
import Account from "./pages/account";
import AccountPaymentMethods from "./pages/account/account-payment-methods";
import AccountBillingHistory from "./pages/account/account-billing-history";
import AccountSubscriptions from "./pages/account/account-subscriptions";
import AccountProfile from "./pages/account/account-profile";
import {useTranslation} from "react-i18next";
import AccountSmsCredits from "./pages/account/account-sms-credits";
import Export from "./components/export";

function Router({
mapIntegration,
isNewOrderOpen,
setIsNewOrderOpen,
notifications,
setNotifications,
setAgentsData,
agentsData,
setAnalyticsLoading
}) {
  const {isAuth, user} = useContext(AuthContext);
  const {list} = useContext(PreferencesContext);
  const {socket} = useContext(SocketContext);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const { pathname } = useLocation();
  const [authType, setAuthType] = useState("sign-in");
  const [showSignUpWarning, setShowSignUpWarning] = useState(false);

  useEffect(() => {
    if (
      isAuth === false &&
      pathname !== "/forgot-password/verify" &&
      pathname !== "/employees/verify" &&
      pathname !== "/auth/sign-up" &&
      pathname !== "/auth/sign-up/am" &&
      pathname !== "/admin" &&
      !pathname.includes('/t/')
    ) {
      return navigate("/auth", { replace: true });
    }

    if (
      isAuth &&
      (
        pathname === "/auth" ||
        pathname === "/auth/sign-up" ||
        pathname === "/auth/sign-up/am"
      )
    ) {
      return navigate("/", { replace: true });
    }

    if (isAuth && user.type === 'agent') {
      return navigate("/settings/profile", { replace: true });
    }
  }, [navigate, isAuth, pathname, user?.type]);
  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on('notification:get', notification => {
      console.log('notification:get', notification);

      const audio = new Audio(sound);
      const info =
        `${notification?.initiatorType === "owner" ? t("components.notifications.owner") : 
          notification?.initiatorType === "manager" ? t("components.notifications.manager") : 
            notification?.initiatorType === "agent" ? t("components.notifications.agent") : 
              notification?.initiatorType === "customer" ? t("components.notifications.customer") : ""} ${notification.initiator?.firstName || notification.customer?.firstName || ''} ${notification.initiator?.lastName || notification.customer?.lastName || notification.customer?.phoneNumber} 
              ${notification?.resourceAction === "update" ? t("components.notifications.update") : 
                notification?.resourceAction === "create" ? t("components.notifications.create") : 
                notification?.resourceAction === "rated" ? t("components.notifications.rated") : 
                notification?.resourceAction === "delete" ? t("components.notifications.delete") : ""}`

      toast.info(info, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      audio.play();

      setNotifications([
        notification,
        ...notifications
      ]);
    });

    return () => {
      socket.off('notification:get');
    };
  }, [t, socket, notifications, setNotifications]);

  if (
    !list &&
    !pathname.includes('/t/') &&
    !pathname.includes('/auth') &&
    !pathname.includes('/admin')
  ) {
    return null;
  }

  return (
    <Routes>
      <Route path="/t/:id" element={<TrackingLink/>}/>
      <Route path="/admin" element={<Admin />} />
      {isAuth && (
        <>
          <Route
            path={list?.dashboard_view.value.type === 'map' ? "/" : "/dashboard/orders"}
            element={
              <Dashboard
                mapIntegration={mapIntegration}
                setIsNewOrderOpen={setIsNewOrderOpen}
                isNewOrderOpen={isNewOrderOpen}
                notifications={notifications}
                setNotifications={setNotifications}
                setAgentsData={setAgentsData}
                agentsData={agentsData}
                setAnalyticsLoading={setAnalyticsLoading}
                showSignUpWarning={showSignUpWarning}
                setShowSignUpWarning={setShowSignUpWarning}
              />
            }
          />
          <Route
            path={list?.dashboard_view.value.type === 'list' ? "/" : "/dashboard/orders"}
            element={
              <Orders
                mapIntegration={mapIntegration}
              />
            }
          />
          <Route
            path={"/orders"}
            element={
              <Orders
                mapIntegration={mapIntegration}
              />
            }
          />
          <Route
            path={"/export/:type/from/:from/to/:to/:ids"}
            element={
              <Export
                type={"orders"}
              />
            }
          />
          <Route
            path={"/export/customers"}
            element={
              <Export
                type={"customers"}
              />
            }
          />
          <Route
            path={"/analytics"}
            element={
              <Analytics />
            }
          />
          {user.role.permissions.includes("read_customer") && (
            <Route path="/customers" element={<Customers />} />
          )}
          {user.role.permissions.includes("see_customer_details") && (
            <Route path="/customers/details/:id" element={<CustomerDetails />} />
          )}
          {user.role.permissions.includes("read_agent") && (
            <Route path="/agents" element={<Agents
              setAgentsData={setAgentsData}
              agentsData={agentsData}
            />} />
          )}
          {user.role.permissions.includes("see_agent_details") && (
            <Route path="/agents/details/:id" element={<AgentDetails />} />
          )}
          {user.role.permissions.includes("read_manager") && (
            <Route path="/managers" element={<Managers />} />
          )}
          {user.role.permissions.includes("see_manager_details") && (
            <Route path="/managers/details/:id" element={<ManagerDetails />} />
          )}
          {user.role.permissions.includes("read_partner") && (
            <Route path="/partners" element={<Partners />} />
          )}
          {user.role.permissions.includes("see_partner_details") && (
            <Route path="/partners/details/:id" element={<PartnerDetails />} />
          )}
          {user.role.permissions.includes("read_branch") && (
            <Route path="/branches" element={<Branches />} />
          )}
          <Route path="/settings" element={<Settings/>}>
            {user.role.permissions.includes('update_payment_methods') && (
              <Route path="/settings/payment-methods" element={<SettingsCompanyPaymentMethods />} />
            )}
            {user.role.permissions.includes('update_roles') && (
              <Route path="/settings/roles" element={<SettingsCompanyRoles />} />
            )}
            {user.role.permissions.includes('update_agent_preferences') && (
              <Route path="/settings/agents" element={<SettingsCompanyAgents />} />
            )}
            {user.role.permissions.includes('update_notifications') && (
              <Route path="/settings/notifications" element={<SettingsCompanyNotifications />} />
            )}
            {user.role.permissions.includes('update_company_preferences') && (
              <Route path="/settings/preferences" element={<SettingsCompanyPreferences />} />
            )}
            {user.role.permissions.includes('update_company_preferences') && (
                <Route path="/settings/reviews" element={<SettingsCompanyReviews />} />
            )}
            {user.role.permissions.includes('update_map') && (
              <Route path="/settings/maps" element={<SettingsIntegrationsMaps />} />
            )}
            {user.role.permissions.includes('update_sms') && (
              <Route path="/settings/sms" element={<SettingsIntegrationsSms />} />
            )}
          </Route>
          <Route path="/account" element={<Account/>}>
            <Route path="/account/profile" element={<AccountProfile />} />
            {user.type === "owner" && (
              <>
                <Route path="/account/payment-methods" element={<AccountPaymentMethods />} />
                <Route path="/account/billing-history" element={<AccountBillingHistory />} />
                <Route path="/account/sms-credits" element={<AccountSmsCredits />} />
                <Route path="/account/subscriptions" element={<AccountSubscriptions />} />
              </>
            )}
          </Route>
        </>
      )}
      {!isAuth && (
        <>
          <Route path="/auth" element={
            <Auth
              authType={authType}
              setAuthType={setAuthType}
              setShowSignUpWarning={setShowSignUpWarning}
            />
          } />
          <Route path="/auth/sign-up" element={
            <SignUp
              lng={"en"}
              setShowSignUpWarning={setShowSignUpWarning}
            />
          } />
          <Route path="/auth/sign-up/am" element={
            <SignUp
              lng={"am"}
              setShowSignUpWarning={setShowSignUpWarning}
            />
          } />
          <Route path="/employees/verify" element={<CreateEmployee />} />
          <Route path="/forgot-password/verify" element={<ForgotPassword />} />
        </>
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

Router.propTypes = {
  mapIntegration: PropTypes.object.isRequired,
  setNotifications: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  setIsNewOrderOpen: PropTypes.func.isRequired,
  isNewOrderOpen: PropTypes.bool.isRequired
};

export default memo(Router);
