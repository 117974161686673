import "./index.scss";
import 'react-phone-number-input/style.css'
import {memo, useEffect} from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber
} from 'react-phone-number-input'
import { formatPhoneNumber } from 'react-phone-number-input'
import Loading from "../loading";
import {BsFillCheckCircleFill} from "react-icons/bs";
import {MdOutlineError} from "react-icons/md";

function PhoneNumberInput({
country,
value,
setValue,
placeholder,
loading,
passed,
showError,
setParsedPhoneNumber,
onFocus = () => {},
readOnly
}) {

    useEffect(() => {
      if (!value) {
        return
      }

      setParsedPhoneNumber({
        isValid: isValidPhoneNumber(value) ? 'true' : 'false',
        isPossible: isPossiblePhoneNumber(value) ? 'true' : 'false',
        data: parsePhoneNumber(value),
        local: formatPhoneNumber(value),
        int: formatPhoneNumberIntl(value)
      })
    }, [value, setParsedPhoneNumber]);

    return (
        <div className="phone_number_input">
          <PhoneInput
            placeholder={placeholder}
            value={value}
            type="tel"
            name="phoneNumber"
            onChange={setValue}
            defaultCountry={country}
            international={true}
            withCountryCallingCode={true}
            countryCallingCodeEditable={false}
            smartCaret={false}
            countrySelectProps={{ unicodeFlags: true }}
            onFocus={onFocus}
            readOnly={readOnly}
          />
          <div className="phone_number_input_in">
            {loading && (
              <div className="phone_number_input_in_icon">
                <Loading />
              </div>
            )}
            {passed && !loading && (
              <span className="phone_number_input_in_icon phone_number_input_in_icon--green">
                <BsFillCheckCircleFill/>
              </span>
            )}
            {showError && !loading && (
              <span className="phone_number_input_in_icon phone_number_input_in_icon--error">
                <MdOutlineError />
              </span>
            )}
          </div>
        </div>
    );
}

export default memo(PhoneNumberInput);
