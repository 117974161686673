import "./index.scss";
import { memo } from "react";
import PropTypes from 'prop-types';
import cn from 'classnames';

function FormCol({
  shouldFit = true,
  children
}) {
  return (
    <div className={cn('form_col', { 'form_col--fit': shouldFit })}>
      {children}
    </div>
  );
}

FormCol.propTypes = {
  shouldFit: PropTypes.bool,
  children: PropTypes.any
};

export default memo(FormCol);
