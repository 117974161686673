import "./index.scss";
import { memo } from "react";
import PropTypes from 'prop-types';
import {RiCloseCircleFill} from "react-icons/ri";
import {MdOutlineError} from "react-icons/md";
import cn from "classnames";

function Form({
  title,
  description,
  children,
  onSubmit,
  onClose,
  error,
  className
}) {
  return (
    <form
      className={cn("form", className)}
      onSubmit={onSubmit}
    >
      <div className="form_header">
        {!error && title && (
          <h2 className="form_header__title">{title}</h2>
        )}
        {error && (
          <div className="form_header__title form_header__title--error">
            <MdOutlineError />
            <p>{error}</p>
          </div>
        )}
        {onClose && (
          <div className="form_header__close_icon">
            <RiCloseCircleFill onClick={onClose}/>
          </div>
        )}
      </div>
      {description && (
        <h2 className="form__description">{description}</h2>
      )}
      {children}
    </form>
  );
}

Form.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

export default memo(Form);
