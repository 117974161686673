import "./index.scss";
import {memo, useCallback, useContext, useMemo, useState} from "react";
import moment from 'moment';
import AgentMoney from "../agent-money";
import cn from "classnames";
import AgentInfoLabel from "../../../../components/ui/agnet-info-labels";
import PropTypes from "prop-types";
import {AiFillBell} from "react-icons/ai";
import sound from "../../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import api from '../../../../utils/api';
import {useTranslation} from "react-i18next";
import PreferencesContext from "../../../../context/preferences/preferences-context";
import PaymentMethodsContext from "../../../../context/payment-methods/payment-methods-context";
import {errToString} from "../../../../utils";

function AgentInfo({
agent,
onClick,
isOpen
}) {
    const paymentMethods = useContext(PaymentMethodsContext);
    const {list} = useContext(PreferencesContext);
    const {t} = useTranslation();

    const cashOptions = useMemo(() => {
      return  paymentMethods.list
          .filter(option => option.name.toLowerCase() === "cash")
          .map((option) => ({
            paymentMethod: option.name,
            amount: agent.orders
              .filter(order => order.status === 'delivered')
              .filter(order => (order.paymentMethod.name)?.toLowerCase() === (option.name).toLowerCase())
              .reduce((acc, order) => acc + order.amount, 0),
            count: agent.orders
              .filter(order => order.status === 'delivered')
              .filter(order => order.paymentMethod.name?.toLowerCase() === option.name.toLowerCase())
              .length
      }));
    }, [agent.orders, paymentMethods.list]);
    const activePaymentMethodOption = useMemo(() => {
      const activePaymentMethodName = list.agent_pane_money.value.paymentMethods?.filter(item => !item.includes("Cash"));

      if (activePaymentMethodName.length === 0) {
        return;
      }
      return {
        paymentMethod: activePaymentMethodName[0],
        amount: agent.orders
          .filter(order => order.status === 'delivered')
          .filter(order => (order.paymentMethod.name)?.toLowerCase() === activePaymentMethodName[0].toLowerCase())
          .reduce((acc, order) => acc + order.amount, 0),
        count: agent.orders
          .filter(order => order.status === 'delivered')
          .filter(order => order.paymentMethod.name?.toLowerCase() === activePaymentMethodName[0].toLowerCase())
          .length
      }
    }, [agent.orders, list.agent_pane_money.value.paymentMethods]);
    const otherPaymentMethodOption = useMemo(() => {

      return {
        paymentMethod: "Other",
        amount: agent.orders
          .filter(order => order.status === 'delivered')
          .filter(order => order.paymentMethod.name?.toLowerCase() !== "cash" && order.paymentMethod.name.toLowerCase() !== activePaymentMethodOption?.paymentMethod.toLowerCase())
          .reduce((acc, order) => acc + order.amount, 0),
        count: agent.orders
          .filter(order => order.status === 'delivered')
          .filter(order => order.paymentMethod.name?.toLowerCase() !== "cash" && order.paymentMethod.name.toLowerCase() !== activePaymentMethodOption?.paymentMethod.toLowerCase())
          .length
      }
    }, [activePaymentMethodOption, agent.orders]);
    const otherPaymentMethodOptions = useMemo(() => {

      return agent.orders
        .filter(order => order.status === 'delivered')
        .filter(order => order.paymentMethod.name?.toLowerCase() !== "cash" && order.paymentMethod.name?.toLowerCase() !== activePaymentMethodOption?.paymentMethod.toLowerCase())
    }, [activePaymentMethodOption, agent.orders]);

    const [isHovered, setIsHovered] = useState(false);
    const onAgentCall = useCallback(() => {
      api
        .post(`/employees/agents/${agent.id}/notify`)
        .then(() => {
          const info = `${t("pages.dashboard.agents_modal.agent_info.agent")} ${agent.firstName} ${agent.lastName} ${t("pages.dashboard.agents_modal.agent_info.hasBeenCalled")}`;
          toast.info(info, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          const audio = new Audio(sound);
          audio.play()
        })
        .catch(err => console.log(errToString(err)));
    }, [t, agent.id, agent.firstName, agent.lastName]);

    return (
      <li
        className={cn("agents_container__list", {"agents_container__list--animate" : isOpen})}
        onClick={onClick}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="agent_info">
          <div className="agent_info_details">
                  <div className="agent_info_details_data">
                    {((!isHovered && list.agent_call_enabled.isActive) || !list.agent_call_enabled.isActive) && (
                      <div className="agent_info_details_data__img">
                        <img src={agent.src} alt="driver"/>
                      </div>
                    )}
                    {isHovered && list.agent_call_enabled.isActive && (
                      <div
                        className="agent_info_details_data__bell"
                        onClick={e => {
                          e.stopPropagation();
                          onAgentCall();
                        }}
                      >
                        <AiFillBell />
                      </div>
                    )}
                    <div className="agent_info_details_data_rows">
                      <div className="agent_info_details_data_row">
                        <span className="agent_info_details_data_row_title">{t("pages.dashboard.agents_modal.agent_info.info")}:</span>
                        {list.agent_info.value.field === 'username' && (
                          <p>{agent.username}</p>
                        )}
                        {list.agent_info.value.field === 'name' && (
                          <p>{agent.firstName} {agent.lastName}</p>
                        )}
                        <span className={cn("agent_info_details_data_row_status", {
                          "agent_info_details_data_row_status--online" : agent?.isOnline,
                          "agent_info_details_data_row_status--offline" : !agent?.isOnline})
                        } />
                      </div>
                      <div className="agent_info_details_data_row">
                        <span className="agent_info_details_data_row_title">{t("pages.dashboard.agents_modal.agent_info.phone")}:</span>
                        <p>{agent.phoneNumber}</p>
                      </div>
                      <div className="agent_info_details_data_row">
                        <span className="agent_info_details_data_row_title">{t("pages.dashboard.agents_modal.agent_info.lastActivity")}:</span>
                        <p>{agent.lastActivity === null ? "n/a" : moment(agent.lastActivity).fromNow()}</p>
                      </div>
                    </div>
                  </div>
              </div>
          <div className="agent_info_tasks">
            <AgentInfoLabel data={agent} status="on_way"/>
            <AgentInfoLabel data={agent} status="picked_up"/>
            <AgentInfoLabel data={agent} status="started"/>
            <AgentInfoLabel data={agent} status="assigned"/>
            <AgentInfoLabel data={agent} status="accepted"/>
            <AgentInfoLabel data={agent} status="delivered"/>
            <AgentInfoLabel data={agent} status="canceled"/>
            <AgentInfoLabel data={agent} status="failed"/>
          </div>
          <div className="agent_info_money">
            <div className="agent_info_money__col">
              <AgentMoney
                paymentMethod={t("components.create_order.cash")}
                amount={cashOptions[0].amount}
                count={cashOptions[0].count}
                agentId={agent.id}
              />
              {activePaymentMethodOption && (
                <AgentMoney
                  paymentMethod={activePaymentMethodOption.paymentMethod}
                  amount={activePaymentMethodOption.amount}
                  count={activePaymentMethodOption.count}
                  agentId={agent.id}
                />
              )}
              <AgentMoney
                paymentMethod={t("pages.dashboard.agents_modal.agent_info.other")}
                amount={otherPaymentMethodOption.amount}
                count={otherPaymentMethodOption.count}
                data={otherPaymentMethodOptions}
                agentId={agent.id}
              />
            </div>
          </div>
        </div>
      </li>
    );
}

AgentInfo.propTypes = {
    agent: PropTypes.object.isRequired,
    onClick: PropTypes.func
};

export default memo(AgentInfo)
