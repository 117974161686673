const isRouteAllowed = (routes, pathname) => {
  for (const route of routes) {
    if (pathname.includes(route)) {
      return false;
    }
  }

  return true;
};

export default isRouteAllowed;
