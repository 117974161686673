import "./index.scss";
import {memo, useCallback, useState} from "react";
import DropDown from "../ui/drop-down";
import PropTypes from "prop-types";
import {useDebouncedCallback} from 'use-debounce';
import {useTranslation} from "react-i18next";
import {BiSearch} from "react-icons/bi";

function Search({placeholder, options, onSearch}) {
  const {t} = useTranslation();
  const [searchInput, setSearchInput] = useState('')
  const [searchOption, setSearchOption] = useState(options[0])

  const onChangeSearch = useDebouncedCallback(() => {
    onSearch({
      search: searchInput,
      searchField: !!options.length ? searchOption.value : "id"
    });
  }, 300);
  const onChangeSearchField = useCallback(option => {
    setSearchOption(option);
    onSearch({
      search: searchInput,
      searchField: option.value
    });
  }, [searchInput, onSearch]);

  return (
    <div className="search">
        <div className="search_input">
          <label htmlFor="search"><BiSearch /></label>
          <input
            id="search"
            type="text"
            placeholder={placeholder}
            value={searchInput}
            onChange={e => {
              setSearchInput(e.target.value);
              onChangeSearch();
            }}
          />
        </div>
      {!!options.length && (
        <div className="search_drop_down">
          <DropDown
            hasFilterIcon={true}
            placeholder={t("components.search.chooseOptions")}
            options={options}
            value={searchOption}
            onChange={onChangeSearchField}
            position="bottom"
            theme="search"
            showClose={false}
          />
        </div>
      )}
    </div>
  );
}

Search.propTypes = {
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default memo(Search);
