import "./index.scss";
import {memo, useEffect, useState, useMemo, useCallback, useContext} from "react";
import {useLocation} from "react-router-dom";
import {createPortal} from "react-dom";
import api from "../../../utils/api";
import AnalyticsInfo from "../../../components/ui/analytics-info";
import Container from "../../../components/layout/container";
import Table from "../../../components/table";
import PageHeader from "../../../components/layout/page-header";
import moment from "moment";
import Status from "../../../components/ui/status";
import {LIMITS} from "../../../constants/drop-down-options";
import Pagination from "../../../components/ui/pagination";
import Search from "../../../components/search";
import DateTimePicker from "../../../components/ui/calendar/date-time-picker-double-calendar";
import UserProfile from "../../../components/layout/user-profile";
import {getCurrentDateWithoutHours} from '../../../utils/date';
import PreferencesContext from "../../../context/preferences/preferences-context";
import {useTranslation} from 'react-i18next';
import {errToString} from "../../../utils";


const SEARCH_FIELDS_ORDERS = [
  {label: 'orderId', value: 'externalId'},
  {label: 'agent', value: 'agent'},
  {label: 'amount', value: 'amount'},
  {label: 'address', value: 'address'}
];

function ManagerDetails() {
  const {pathname} = useLocation();
  const managerId = pathname.split("/")[3];
  const [showProfile, setShowProfile] = useState(false)
  const {list} = useContext(PreferencesContext);
  const {t} = useTranslation();

  const detailsOrdersTitles = useMemo(() => [
    {
      name: t("pages.managers.manager_details.id"),
    },
    {
      name: t("pages.managers.manager_details.agent")
    },
    {
      name: t("pages.managers.manager_details.paymentMethod"),
    },
    {
      name: t("pages.managers.manager_details.amount"),
    },
    {
      name: t("pages.managers.manager_details.customer")
    },
    {
      name: t("pages.managers.manager_details.address"),
      size: 200
    },
    {
      name: t("pages.managers.manager_details.created"),
    },
    {
      name: t("pages.managers.manager_details.status"),
    }
  ], [t]);

  const searchFieldsOrders = useMemo(() => SEARCH_FIELDS_ORDERS.map(option => ({
    ...option,
    label: t(`pages.dashboard.orders.${option.label}`)
  })), [t]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);

  const [managerData, setManagerData] = useState({})
  const [analyticsData, setAnalyticsData] = useState({})
  const [profileData, setProfileData] = useState(null)
  const [profileLoading, setProfileLoading] = useState(true)
  const [managerDataLoading, setManagerDataLoading] = useState(true)
  const [managerAnalyticsLoading, setManagerAnalyticsLoading] = useState(true)

  const onChangeLimit = useCallback(option => {
    if (option.value === limit.value) {
      return;
    }

    setLimit(option);
    setOffset(0);
    setCurrentPage(0);
  }, [limit]);
  const [dateFields, setDateFields] = useState({
    startDate: getCurrentDateWithoutHours(),
    endDate: getCurrentDateWithoutHours(23, 59, 59),
  });
  const onSearch = useCallback(({ search, searchField }) => {
    const startDate = moment(dateFields.startDate).utc().format();
    const endDate = moment(dateFields.endDate).utc().format();

    setManagerDataLoading(true);

    api
      .get(`/employees/managers/${managerId}/orders?offset=${offset}&limit=${limit.value}&from=${startDate}&to=${endDate}${search && searchField ? `&search=${search}&searchField=${searchField}` : ''}`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setManagerData(res.data);
        setManagerDataLoading(false);
      })
      .catch((err) => console.log(errToString(err)));
  }, [offset, limit, managerId, dateFields])

  useEffect(() => {
    const startDate = moment(dateFields.startDate).utc().format();
    const endDate = moment(dateFields.endDate).utc().format();

    setManagerDataLoading(true);
    setManagerAnalyticsLoading(true);
    api
      .get(`/employees/managers/${managerId}/orders?offset=${offset}&limit=${limit.value}&from=${startDate}&to=${endDate}`)
      .then((res) => {
        setManagerData(res.data);
        setPagesCount(res.data.pagesCount);
        setManagerDataLoading(false);
        setManagerAnalyticsLoading(false);
      })
      .catch((err) => console.log(errToString(err)));
  }, [managerId, dateFields, offset, limit]);
  useEffect(() => {
    const startDate = moment(dateFields.startDate).utc().format();
    let endDate = moment(dateFields.endDate).utc().format();

    setManagerDataLoading(true);
    setManagerAnalyticsLoading(true);
    api
      .get(`/employees/managers/${managerId}/analytics?from=${startDate}&to=${endDate}`)
      .then((res) => {
        setAnalyticsData(res.data);
        setManagerDataLoading(false);
        setManagerAnalyticsLoading(false);
      })
      .catch((err) => console.log(errToString(err)));
  }, [managerId, dateFields]);
  useEffect(() => {

    setProfileLoading(true);
    api
      .get(`/employees/managers/${managerId}?freezed`)
      .then((res) => {
        setProfileData(res.data);
        setProfileLoading(false)
      })
      .catch((err) => console.log(errToString(err)));
  }, [managerId]);

  return (
    <Container>
      <div
        className="manager_details"
      >
        <PageHeader
          type="navigation"
          link="/managers"
          linkTitle={t("pages.managers.manager_details.managers")}
          pageTitle={t("pages.managers.manager_details.managerDetails")}
          isCustomer={false}
          data={profileData}
          loading={profileLoading}
          onClick={() => setShowProfile(true)}
        />
        <div className="manager_details_in_actions">
          <Search
            placeholder={t("pages.managers.manager_details.search")}
            options={searchFieldsOrders}
            onSearch={onSearch}
          />
          <div className='orders_in_actions_calendar'>
            <DateTimePicker
              className="orders_date_time_picker"
              placeholder="DD/MM/YYYY HH:MM - HH:MM"
              enableTimePicker={true}
              position="bottom"
              theme='orders'
              enableRangePicker={true}
              value={[dateFields.startDate, dateFields.endDate]}
              onChange={e => {
                setDateFields(e);
                setOffset(0);
              }}
            />
          </div>
        </div>
        <div className="manager_details_dashboard">
          <AnalyticsInfo
            className="manager_details_dashboard__info"
            title={t("pages.managers.manager_details.pending")}
            count={analyticsData.pendingOrdersCount}
            status={"pending"}
            loading={managerAnalyticsLoading}
          />
          <AnalyticsInfo
            className="manager_details_dashboard__info"
            title={t("pages.managers.manager_details.ongoing")}
            count={analyticsData?.ongoingOrdersCount}
            status={"ongoing"}
            loading={managerAnalyticsLoading}
          />
          <AnalyticsInfo
            className="manager_details_dashboard__info"
            title={t("pages.managers.manager_details.finished")}
            count={analyticsData?.finishedOrdersCount}
            status={"finished"}
            loading={managerAnalyticsLoading}
          />
          <AnalyticsInfo
            className="manager_details_dashboard__info"
            title={t("pages.managers.manager_details.canceled")}
            count={analyticsData?.canceledOrdersCount}
            status={"canceled"}
            loading={managerAnalyticsLoading}
          />
        </div>
        <div className="manager_details_in">
          <Table
            titles={detailsOrdersTitles}
            hiddenFields={['id']}
            rows={managerData?.results?.map(order => ({
              id: order.id,
              orderId: (
                <p>{`Order #${order.externalId}`}</p>
              ),
              agent: order.agent ? (
                <p>{`${order.agent.firstName} ${order.agent.lastName} ${order.agent.phoneNumber}`}</p>
              ) : <p>n/a</p>,
              paymentMethod: (
                <p>{order.paymentMethod.name.toLowerCase() === "cash" ? t("payment.cash") : order.paymentMethod.name}</p>
              ),
              amount: (
                <p>{`${order.amount}`}</p>
              ),
              customer: (order.customer.firstName && order.customer.lastName ? (
                  <p>{`${order.customer.firstName} ${order.customer.lastName} ${order.customer.phoneNumber}`}</p>
                ) : <p>{`${order.customer.phoneNumber}`}</p>
              ),
              deliveryAddress: (
                <p>{order.address.deliveryAddress}</p>
              ),
              orderCreated: (
                <p>{moment(order.createdAt).format(`${list.date_format.value.format}, ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}</p>
              ),
              status: order.status && (
                <Status status={order.status} type="table"/>
              )
            })) || []}
            loading={managerDataLoading}
          />
        </div>
        {profileData && createPortal(
          <UserProfile
            id={managerId}
            type="managers"
            user={profileData}
            isProfileModalOpen={showProfile}
            setIsProfileModalOpen={setShowProfile}
          />,document.getElementById('modal'))}
      </div>
      {!!pagesCount && (
        <Pagination
            pagesCount={pagesCount}
            setOffset={setOffset}
            limit={limit}
            limitPlaceholder={limit.label}
            limits={LIMITS}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onChange={onChangeLimit}
        />
      )}
    </Container>
  );
}

export default memo(ManagerDetails);
