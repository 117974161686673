import "./index.scss";
import {memo, useEffect, useState, useMemo, useCallback, useContext} from "react";
import {useLocation} from "react-router-dom";
import api from "../../../utils/api";
import AnalyticsInfo from "../../../components/ui/analytics-info";
import Container from "../../../components/layout/container";
import Table from "../../../components/table";
import moment from "moment";
import Status from "../../../components/ui/status";
import {LIMITS} from "../../../constants/drop-down-options";
import Pagination from "../../../components/ui/pagination";
import Search from "../../../components/search";
import DateTimePicker from "../../../components/ui/calendar/date-time-picker-double-calendar";
import {getCurrentDateWithoutHours} from '../../../utils/date';
import PreferencesContext from "../../../context/preferences/preferences-context";
import PageHeader from "../../../components/layout/page-header";
import {useTranslation} from 'react-i18next';
import {errToString} from "../../../utils";


const SEARCH_FIELDS_ORDERS = [
  {label: `id`, value: 'externalId'},
  {label: `phoneNumber`, value: 'phoneNumber'},
  {label: `email`, value: 'email'},
  {label: `amount`, value: 'amount'},
  {label: `address`, value: 'address'},
];

function PartnerDetails() {
  const {pathname} = useLocation();
  const {list} = useContext(PreferencesContext);
  const partnerId = pathname.split("/")[3];
  const {t} = useTranslation();

  const detailsOrdersTitles = useMemo(() => [
    {
      name: t("pages.partners.partner_details.id"),
    },
    {
      name: t("pages.partners.partner")
    },
    {
      name: t("pages.partners.partner_details.paymentMethod"),
    },
    {
      name: t("pages.partners.partner_details.amount"),
    },
    {
      name: t("pages.partners.partner_details.customer")
    },
    {
      name: t("pages.partners.partner_details.address"),
      size: 200
    },
    {
      name: t("pages.partners.partner_details.created"),
    },
    {
      name: t("pages.partners.partner_details.status"),
    }
  ], [t]);

  const searchFieldsOrders = useMemo(() => SEARCH_FIELDS_ORDERS.map(option => ({
    ...option,
    label: t(`pages.partners.partner_details.${option.label}`)
  })), [t]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);

  const [partnerData, setPartnerData] = useState({})
  const [analyticsData, setAnalyticsData] = useState({})
  const [partnerDataLoading, setPartnerDataLoading] = useState(true)
  const [partnerAnalyticsLoading, setPartnerAnalyticsLoading] = useState(true)
  const [profileLoading, setProfileLoading] = useState(true)
  const [selectedPartner, setSelectedPartner] = useState(null);

  const onChangeLimit = useCallback(option => {
    if (option.value === limit.value) {
      return;
    }

    setLimit(option);
    setOffset(0);
    setCurrentPage(0);
  }, [limit]);
  const [dateFields, setDateFields] = useState({
    startDate: getCurrentDateWithoutHours(),
    endDate: getCurrentDateWithoutHours(23, 59, 59),
  });
  const onSearch = useCallback(({ search, searchField }) => {
    const startDate = moment(dateFields.startDate).utc().format();
    const endDate = moment(dateFields.endDate).utc().format();

    setPartnerDataLoading(true);

    api
      .get(`/partners/${partnerId}/orders?offset=${offset}&limit=${limit.value}&from=${startDate}&to=${endDate}${search && searchField ? `&search=${search}&searchField=${searchField}` : ''}`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setPartnerData(res.data);
        setPartnerDataLoading(false);
      })
      .catch((err) => console.log(errToString(err)));
  }, [offset, limit, partnerId, dateFields])

  useEffect(() => {
    const startDate = moment(dateFields.startDate).utc().format();
    const endDate = moment(dateFields.endDate).utc().format();

    setPartnerDataLoading(true);
    setPartnerAnalyticsLoading(true);
    api
      .get(`/partners/${partnerId}/orders?offset=${offset}&limit=${limit.value}&from=${startDate}&to=${endDate}`)
      .then((res) => {
        setPartnerData(res.data);
        setPagesCount(res.data.pagesCount);
        setPartnerDataLoading(false);
        setPartnerAnalyticsLoading(false);
      })
      .catch((err) => console.log(errToString(err)));
  }, [partnerId, dateFields, offset, limit]);
  useEffect(() => {
    const startDate = moment(dateFields.startDate).utc().format();
    let endDate = moment(dateFields.endDate).utc().format();

    setPartnerDataLoading(true);
    setPartnerAnalyticsLoading(true);
    api
      .get(`/partners/${partnerId}/analytics?from=${startDate}&to=${endDate}`)
      .then((res) => {
        setAnalyticsData(res.data);
        setPartnerDataLoading(false);
        setPartnerAnalyticsLoading(false);
      })
      .catch((err) => console.log(errToString(err)));
  }, [partnerId, dateFields]);
  useEffect(() => {

    setProfileLoading(true);
    api
      .get(`/partners/${partnerId}?freezed`)
      .then((res) => {
        setSelectedPartner(res.data);
        setProfileLoading(false)
      })
      .catch((err) => console.log(errToString(err)));
  }, [partnerId]);

  return (
    <Container>
      <div
        className="partner_details"
      >
        <PageHeader
          type="navigation"
          link="/partners"
          linkTitle={t("pages.partners.partner_details.partners")}
          pageTitle={t("pages.partners.partner_details.partnerDetails")}
          isCustomer={false}
          isPartner={true}
          data={selectedPartner}
          loading={profileLoading}
          onClick={() => {}}
        />
        <div className="partner_details_in_actions">
          <Search
            placeholder={t("pages.partners.partner_details.search")}
            options={searchFieldsOrders}
            onSearch={onSearch}
          />
          <div className='orders_in_actions_calendar'>
            <DateTimePicker
              className="orders_date_time_picker"
              placeholder="DD/MM/YYYY HH:MM - HH:MM"
              enableTimePicker={true}
              position="bottom"
              theme='orders'
              enableRangePicker={true}
              value={[dateFields.startDate, dateFields.endDate]}
              onChange={e => {
                setDateFields(e);
                setOffset(0);
              }}
            />
          </div>
        </div>
        <div className="partner_details_dashboard">
          <AnalyticsInfo
            className="partner_details_dashboard__info"
            title={t("pages.partners.partner_details.pending")}
            count={analyticsData.pendingOrdersCount}
            status={"pending"}
            loading={partnerAnalyticsLoading}
          />
          <AnalyticsInfo
            className="partner_details_dashboard__info"
            title={t("pages.partners.partner_details.ongoing")}
            count={analyticsData?.ongoingOrdersCount}
            status={"ongoing"}
            loading={partnerAnalyticsLoading}
          />
          <AnalyticsInfo
            className="partner_details_dashboard__info"
            title={t("pages.partners.partner_details.finished")}
            count={analyticsData?.finishedOrdersCount}
            status={"finished"}
            loading={partnerAnalyticsLoading}
          />
          <AnalyticsInfo
            className="partner_details_dashboard__info"
            title={t("pages.partners.partner_details.canceled")}
            count={analyticsData?.canceledOrdersCount}
            status={"canceled"}
            loading={partnerAnalyticsLoading}
          />
        </div>
        <div className="partner_details_in">
          <Table
            titles={detailsOrdersTitles}
            hiddenFields={['id']}
            rows={partnerData?.results?.map(order => ({
              id: order.id,
              orderId: (
                <p>{`Order #${order.externalId}`}</p>
              ),
              partner: order.partner ? (
                <p>{`${order.partner.name}`}</p>
              ) : <p>n/a</p>,
              paymentMethod: (
                <p>{order.paymentMethod.name.toLowerCase() === "cash" ? t("payment.cash") : order.paymentMethod.name}</p>
              ),
              amount: (
                <p>{`${order.amount}`}</p>
              ),
              customer: (order.customer.firstName && order.customer.lastName ? (
                  <p>{`${order.customer.firstName} ${order.customer.lastName} ${order.customer.phoneNumber}`}</p>
                ) : <p>{`${order.customer.phoneNumber}`}</p>
              ),
              deliveryAddress: (
                <p>{order.address.deliveryAddress}</p>
              ),
              orderCreated: (
                <p>{moment(order.createdAt).format(`${list.date_format.value.format}, ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}</p>
              ),
              status: order.status && (
                <Status status={order.status} type="table"/>
              )
            })) || []}
            loading={partnerDataLoading}
          />
        </div>
      </div>
      {!!pagesCount && (
          <Pagination
              pagesCount={pagesCount}
              setOffset={setOffset}
              limit={limit}
              limitPlaceholder={limit.label}
              limits={LIMITS}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onChange={onChangeLimit}
          />
      )}
    </Container>
  );
}

export default memo(PartnerDetails);
