import "./index.scss";
import {memo, useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import cn from 'classnames';
import ContentLoading from "../ui/loading/content-loading";
import NoData from "../ui/no-data";
import TableRow from "./table-row";
import TableColumn from "./table-column";
import {createArrFromLength} from "../../utils";

function Table({
loading,
route,
setRow,
title,
titles,
rows,
hiddenFields = []
}) {
  const ref = useRef();
  const navigate = useNavigate();
  const [colMinWidth, setColMinWidth] = useState(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const colMinWidth = (ref.current?.offsetWidth - titles.reduce((prev, current) => prev + (current?.size || 0), 0)) / titles.filter(title => !title.size).length;
    setColMinWidth(colMinWidth);

    window.onresize = () => {
      const colMinWidth = (ref.current?.offsetWidth - titles.reduce((prev, current) => prev + (current?.size || 0), 0)) / titles.filter(title => !title.size).length;
      setColMinWidth(colMinWidth);
    };

    return () => {
      window.onresize = null;
    };
  // eslint-disable-next-line
  }, [ref.current, titles]);

  return (
      <>
        {title && (
          <p className="table_title">{title}</p>
        )}
        <table
          ref={ref}
          className="table"
        >
          {ref.current && !loading && !!rows?.length && (
            <>
              <thead className="table_header">
              <tr>
                {colMinWidth && titles.map(({ name, isSortable, size, onSortByAsc, onSortByDesc }, index) => (
                  <TableColumn
                    key={index}
                    name={name}
                    isSortable={isSortable}
                    size={size}
                    onSortByAsc={onSortByAsc}
                    onSortByDesc={onSortByDesc}
                    colMinWidth={colMinWidth}
                  />
                ))}
              </tr>
              </thead>
              <tbody className="table_body">
              {rows.map((row, index) => (
                <TableRow
                  key={index}
                  row={row}
                  route={route}
                  hiddenFields={hiddenFields}
                  className={cn({ 'table--has_route': (route || setRow) })}
                  onClick={() => {
                    if (setRow) {
                      return setRow(row.id);
                    }
                    if (route) {
                      if (row.deletedAt) {
                        return alert("Please unfreeze to see details!")
                      }
                      return navigate(`/${route}/details/${row.id}`)
                    }
                  }}
                />
              ))}
              </tbody>
            </>
          )}
        </table>
        {(!loading && !rows?.length) && (
          <div className="table_body_no_data">
            <NoData />
          </div>
        )}
        {loading && (
          <div className="table_loading">
            {createArrFromLength(5).map((val, index) => (
              <ContentLoading type="default" key={index} />
            ))}
          </div>
      )}
    </>
  );
}

Table.propTypes = {
  route: PropTypes.string,
  titles: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  hiddenFields: PropTypes.array,
  setRow: PropTypes.func
};

export default memo(Table);
