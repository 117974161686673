import "./index.scss";
import {memo, useState, useMemo, useEffect, useRef, useCallback, useContext} from "react";
import api from "../../utils/api";
import PageHeader from "../../components/layout/page-header";
import Table from '../../components/table';
import Container from "../../components/layout/container";
import PopUp from "../../components/layout/popup";
import moment from "moment";
import {AiFillStar} from "react-icons/ai";
import CreateAgent from "./create-agent";
import Pagination from "../../components/ui/pagination";
import {LIMITS} from "../../constants/drop-down-options";
import Status from "../../components/ui/status";
import Search from "../../components/search";
import AgentCard from "./agent-card";
import {createArrFromLength, errToString} from "../../utils";
import MorePopup from '../../components/ui/more-popup';
import {createPortal} from 'react-dom';
import UpdateAgent from './update-agent';
import Loading from '../../components/ui/loading';
import {BsPlus, BsThreeDotsVertical} from 'react-icons/bs';
import UpdateAgentPassword from "./update-agent/update-password-agent";
import sound from "../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import NoData from "../../components/ui/no-data";
import PreferencesContext from "../../context/preferences/preferences-context";
import AuthContext from "../../context/auth/auth-context";
import {useTranslation} from 'react-i18next';
import SwitcherBtn from "../../components/ui/switcher-btn";
import UserCompanyBranchesContext from "../../context/user-company-branches/user-company-branches-context";
import {MdQrCode2} from "react-icons/md";
import {Tooltip} from "react-tooltip";
import DriverAppQR from "../../components/driver-app-qr";

const SEARCH_FIELDS_AGENTS = [
  {label: `name`, value: 'name'},
  {label: `phoneNumber`, value: 'phoneNumber'},
  {label: `email`, value: 'email'}
];
const MORE_BTN_SIZE = 50;

function Agents({
setAgentsData,
agentsData
}) {
  const {user} = useContext(AuthContext);
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const {list} = useContext(PreferencesContext);
  const {t} = useTranslation();

  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [loading, setLoading] = useState(true);
  const morePopupRef = useRef({});
  const [morePopupUser, setMorePopupUser] = useState(null);
  const [isMorePopupOpen, setIsMorePopupOpen] = useState(false);
  const [isUnFreezeAlertOn, setIsUnFreezeAlertOn] = useState(false);
  const [isFreezeAlertOn, setIsFreezeAlertOn] = useState(false);
  const [isDeleteAlertOn, setIsDeleteAlertOn] = useState(false);
  const [isEditProfileOn, setIsEditProfileOn] = useState(false);
  const [isEditPasswordOn, setIsEditPasswordOn] = useState(false);
  const [tableModeIsOn, setTableModeIsOn] = useState(false);
  const [createAgent, setCreateAgent] = useState(false);
  const [showFrozenAgents, setShowFrozenAgents] = useState(false);
  const [showQr, setShowQr] = useState(false);

  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [currentPage, setCurrentPage] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const agentTableTitles = useMemo(() => [
    {
      name: t("pages.agents.agent")
    },
    {
      name: t("pages.agents.username")
    },
    {
      name: t("pages.agents.avgRating"),
      size: 100
    },
    {
      name: t("pages.agents.phoneNumber"),
      size: 200
    },
    {
      name: t("pages.agents.device"),
      size: 100
    },
    {
      name: t("pages.agents.car"),
      size: 100
    },
    {
      name: t("pages.agents.lastActivity"),
      size: 100
    },
    {
      name: t("pages.agents.status"),
      size: 100
    },
    {
      name: t("pages.agents.state"),
      size: 100
    },
    {
      size: MORE_BTN_SIZE
    }
  ], [t]);
  const searchFieldsAgents = useMemo(() => SEARCH_FIELDS_AGENTS.map(option => ({
    ...option,
    label: t(`pages.agents.${option.label}`)
  })), [t]);

  const onCloseCreateAgent = useCallback(e => {
    e?.preventDefault();

    setCreateAgent(false);
    setSelectedAgentId(null);
    setSelectedAgent(null);
  }, []);
  const onCancelFreezeUser = useCallback((e) => {
    e.stopPropagation();
    setIsMorePopupOpen(false);
    setIsFreezeAlertOn(false);
    setSelectedAgentId(null);
    setSelectedAgent(null);
  }, []);
  const onCancelDeleteUser = useCallback((e) => {
    e.stopPropagation();
    setIsMorePopupOpen(false);
    setIsDeleteAlertOn(false);
    setSelectedAgentId(null);
    setSelectedAgent(null);
  }, []);
  const onCancelUnFreezeUser = useCallback((e) => {
    e.stopPropagation();
    setIsMorePopupOpen(false);
    setIsUnFreezeAlertOn(false);
    setSelectedAgentId(null);
    setSelectedAgent(null);
  }, []);
  const onCloseEditAgent = useCallback(e => {
    e?.preventDefault();
    setIsMorePopupOpen(false);
    setIsEditProfileOn(false);
    setIsEditPasswordOn(false);
    setSelectedAgentId(null);
    setSelectedAgent(null);
  }, []);
  const onChangeLimit = useCallback(option => {
    if (option.value === limit.value) {
      return;
    }

    setLimit(option);
    setOffset(0);
    setLoading(true);
    setCurrentPage(0);
  }, [limit]);
  const onFreezeUser = useCallback((e) => {
    e.stopPropagation();

    api
      .delete(`/employees/agents/${selectedAgentId}`)
      .then(() => {
        setAgentsData(agentsData.map((data) => {
          if (selectedAgentId === data.id) {
            return {
              ...data,
              deletedAt: new Date()
            }
          }
          return data;
        }));
        setIsFreezeAlertOn(false);
        setIsMorePopupOpen(false);

        const audio = new Audio(sound);
        const info = `${t("pages.agents.agent")} ${t("pages.agents.hasBeenFrozen")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch(err => console.log(errToString(err)));
  }, [t, selectedAgentId, agentsData, setAgentsData]);
  const onDeleteUser = useCallback((e) => {
    e.stopPropagation();

    api
      .put(`/employees/agents/${selectedAgentId}`, {
        isHidden: true
      })
      .then(() => {
        setAgentsData(agentsData.filter(data => selectedAgentId !== data.id));
        setIsDeleteAlertOn(false);
        setIsMorePopupOpen(false);

        const audio = new Audio(sound);
        const info = `${t("pages.agents.agent")} ${t("pages.agents.hasBeenDeleted")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch(err => console.log(errToString(err)));
  }, [t, selectedAgentId, agentsData, setAgentsData]);
  const onUnFreezeUser = useCallback((e) => {
    e.stopPropagation();

    api
      .put(`/employees/agents/${selectedAgentId}`, {
        restore: true
      })
      .then(() => {
        setIsUnFreezeAlertOn(false);
        setIsMorePopupOpen(false);
        setAgentsData(agentsData.map((data) => {
          if (selectedAgentId === data.id) {
            return {
              ...data,
              deletedAt: null
            }
          }
          return data;
        }));

        const audio = new Audio(sound);
        const info = `${t("pages.agents.agent")} ${t("pages.agents.hasBeenUnFrozen")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch(err => console.log(errToString(err)));
  }, [t, selectedAgentId, agentsData, setAgentsData]);
  const onShowMore = useCallback((e, id, user) => {
    e.stopPropagation();
    setIsMorePopupOpen(true);
    setSelectedAgentId(id);
    setMorePopupUser(user);
  }, []);
  const onSearch = useCallback(({ search, searchField }) => {
    const branchIds = userCompanyBranches?.list.map(({ id }) => id);
    const companyAddressIds = branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : '';

    setLoading(true);

    api
      .get(`/employees/agents?offset=${offset}&limit=${limit.value}${search && searchField ? `&search=${search}&searchField=${searchField}` : ''}&freezed${companyAddressIds}`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setAgentsData(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(errToString(err))
        setLoading(false);
      });
  }, [setAgentsData, userCompanyBranches?.list, offset, limit]);

  useEffect(() => {
    api
      .get(`/employees/agents?offset=${offset}&limit=${limit.value}${showFrozenAgents ? "&freezed" : ""}&companyAddressIds=all`)
      .then((res) => {
        setAgentsData(res.data.results);
        setLoading(false);
        setPagesCount(res.data.pagesCount);
      })
      .catch((err) => console.log(errToString(err)));
  }, [setAgentsData, offset, limit, showFrozenAgents]);
  useEffect(() => {
    if (!selectedAgentId) {
      return;
    }

    if (
      selectedAgentId &&
      (isEditProfileOn || isEditPasswordOn)
    ) {
      api
        .get(`/employees/agents/${selectedAgentId}?freezed`)
        .then(({ data }) => {
          setSelectedAgent(data);
        })
        .catch(err => console.log(errToString(err)));
    }
  }, [selectedAgentId, isEditProfileOn, isEditPasswordOn]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container>
      <div className="agents">
        <PageHeader>
          <p>{t("pages.agents.agents")}</p>
        </PageHeader>
        <div className="agents_in_actions">
          <Search
            placeholder={t("pages.agents.searchAgent")}
            options={searchFieldsAgents}
            onSearch={onSearch}
          />
          <div className="agents_in_actions__switcher">
            <SwitcherBtn
                checked={tableModeIsOn}
                setChecked={setTableModeIsOn}
                type={"table-card"}
            />
          </div>
          <div className="agents_in_actions__switcher">
            <SwitcherBtn
              checked={showFrozenAgents}
              setChecked={setShowFrozenAgents}
              type={"freeze-unfreeze-agents"}
            />
          </div>
          {user.role.permissions.includes("create_agent") && (
            <button
              onClick={() => setCreateAgent(true)}
            >
              {windowWidth > 600 ? t("components.form.createDriver") : <BsPlus />}
            </button>
          )}
          <div
              className="agents_in_actions__download"
              data-tooltip-id={`tooltip-qr`}
              onClick={() => setShowQr(true)}
          >
            <MdQrCode2 />
            <Tooltip
                id={`tooltip-qr`}
                place="top"
                className="tooltipClass"
            >{t("pages.agents.qr")}</Tooltip>
          </div>
          {showQr && (
            <PopUp onOverlayClick={() => setShowQr(false)}>
               <DriverAppQR
                 onClose={() => setShowQr(false)}
               />
            </PopUp>
          )}
          {createAgent && (
            <PopUp>
              <CreateAgent
                onClose={onCloseCreateAgent}
                setAgents={setAgentsData}
                agents={agentsData}
              />
            </PopUp>
          )}
        </div>
        {tableModeIsOn && agentsData && (
          <div className="agents_in">
            <Table
              route={user.role.permissions.includes("see_agent_details") ? "agents" : ""}
              titles={agentTableTitles}
              loading={loading}
              hiddenFields={['id']}
              rows={agentsData?.map(agent => ({
                id: agent.id,
                agent: (
                  <div className="table__avatar">
                    <img
                      src={agent.src}
                      alt="agent"
                    />
                    <p>{agent.firstName} {agent.lastName}</p>
                  </div>
                ),
                userName: (
                  <p>{agent.username}</p>
                ),
                rating: (
                  <div className="table__rating">
                    <AiFillStar/>
                    <p>{agent.averageRating ? Number(agent.averageRating).toFixed(2) : "-"}</p>
                  </div>
                ),
                phoneNumber: (
                  <p>{agent.phoneNumber}</p>
                ),
                device: (
                  <p>{agent.device}</p>
                ),
                carBrand: agent.vehicleType === "car" ?
                  <p>{`${agent.carBrand} ${agent.carModel} ${agent.carPlate}`}</p> :
                  agent.vehicleType === "bike" ?
                    <p>{t(`pages.trackingLink.bike`)}</p> :
                    agent.vehicleType === "bicycle" ?
                      <p>{t(`pages.trackingLink.bicycle`)}</p> :
                      agent.vehicleType === "truck" ?
                        <p>{t(`pages.trackingLink.truck`)}</p> :
                        `-`,
                lastActivity: (
                  <p>{agent.lastActivity ? moment(agent.lastActivity).format(`${list.date_format.value.format}, ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`) : "n/a"}</p>
                ),
                status: (
                  <Status type='table' status={agent.isOnline ? "online" : "offline"}/>
                ),
                state: (
                  <Status type='table' status={agent.deletedAt ? "freezed" : "active"}/>
                ),
                more: (user.role.permissions.includes("update_agent") || user.role.permissions.includes("delete_agent")) && (
                  <div
                    ref={el => morePopupRef.current[agent.id] = el}
                    className="table_body_menu"
                    onClick={e => onShowMore(e, agent.id, agent)}
                  >
                    <BsThreeDotsVertical/>
                  </div>
                )
              })) || []}
            />
          </div>
        )}
        {!tableModeIsOn && (
          <>
            {!loading && !!agentsData.length &&(
              <div className="agents_in_cards">
                {agentsData.map(agent => (
                  <AgentCard
                    key={agent.id}
                    agent={agent}
                    setAgents={setAgentsData}
                    agents={agentsData}
                    loading={false}
                  />
                ))}
              </div>
            )}
            {!loading && !agentsData.length && (
              <div className="agents_in_cards_no_data">
                <NoData/>
              </div>
            )}
            {loading && (
              <div className="agents_in_cards">
                {createArrFromLength(15).map((val, index) => (
                  <AgentCard
                    key={index}
                    agent={{}}
                    loading={true}
                  />
                ))}
              </div>
            )}
          </>
        )}
        {isEditProfileOn && selectedAgent && (
          <PopUp>
            <UpdateAgent
              agent={selectedAgent}
              onClose={onCloseEditAgent}
              setAgentsData={setAgentsData}
              agentsData={agentsData}
              setAgent={setSelectedAgent}
            />
          </PopUp>
        )}
        {isEditPasswordOn && selectedAgent && (
          <PopUp>
            <UpdateAgentPassword
              agent={selectedAgent}
              onClose={onCloseEditAgent}
            />
          </PopUp>
        )}
        {isFreezeAlertOn && createPortal(
          <>
            <div
              className="agents_in_freeze_alert"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="agents_in_freeze_alert_title">{t("pages.agents.warningFreeze")} {morePopupUser.firstName} {morePopupUser.lastName}</div>
              <div className="agents_in_freeze_alert_actions">
                <button
                  className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_cancel"
                  onClick={onCancelFreezeUser}
                >{t("pages.agents.cancel")}</button>
                <button
                  className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_submit agents_in_freeze_alert_btn_submit--freeze"
                  onClick={onFreezeUser}
                >{t("pages.agents.freeze")}</button>
              </div>
            </div>
            <div
              className="agents_in_freeze_alert_overlay"
              onClick={onCancelFreezeUser}
            ></div>
          </>, document.getElementById('modal')
        )}
        {isDeleteAlertOn && createPortal(
          <>
            <div
              className="agents_in_freeze_alert"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="agents_in_freeze_alert_title">{t("pages.agents.warningDelete")} {morePopupUser.firstName} {morePopupUser.lastName}</div>
              <div className="agents_in_freeze_alert_actions">
                <button
                  className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_cancel"
                  onClick={onCancelDeleteUser}
                >{t("pages.agents.cancel")}</button>
                <button
                  className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_submit agents_in_freeze_alert_btn_submit--freeze"
                  onClick={onDeleteUser}
                >{t("pages.agents.delete")}</button>
              </div>
            </div>
            <div
              className="agents_in_freeze_alert_overlay"
              onClick={onCancelDeleteUser}
            ></div>
          </>, document.getElementById('modal')
        )}
        {isUnFreezeAlertOn && createPortal(
          <>
            <div
              className="agents_in_freeze_alert"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="agents_in_freeze_alert_title">{t("pages.agents.warningUnFreeze")} {morePopupUser.firstName} {morePopupUser.lastName} ?</div>
              <div className="agents_in_freeze_alert_actions">
                <button
                  className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_cancel"
                  onClick={onCancelUnFreezeUser}
                >{t("pages.agents.cancel")}</button>
                <button
                  className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_submit agents_in_freeze_alert_btn_submit--unfreeze"
                  onClick={onUnFreezeUser}
                >{t("pages.agents.unFreeze")}</button>
              </div>
            </div>
            <div
              className="agents_in_freeze_alert_overlay"
              onClick={onCancelUnFreezeUser}
            ></div>
          </>, document.getElementById('modal')
        )}
        {isMorePopupOpen && selectedAgentId && (
          <MorePopup
            selectedUser={morePopupUser}
            id={selectedAgentId}
            top={morePopupRef.current[selectedAgentId].getBoundingClientRect().top}
            left={morePopupRef.current[selectedAgentId].getBoundingClientRect().left - 200}
            setIsMorePopupOpen={setIsMorePopupOpen}
            setIsFreezeAlertOn={setIsFreezeAlertOn}
            setIsEditProfileOn={setIsEditProfileOn}
            setIsEditPasswordOn={setIsEditPasswordOn}
            setIsUnFreezeAlertOn={setIsUnFreezeAlertOn}
            setIsDeleteAlertOn={setIsDeleteAlertOn}
          />
        )}
        {(isEditProfileOn || isEditPasswordOn) && !selectedAgent && createPortal(
          <>
            <div className="agents_loading_wrapper">
              <Loading/>
            </div>
            <div className="agents_loading_wrapper_overlay"></div>
          </>, document.getElementById('modal')
        )}
      </div>
      {!loading && !!pagesCount && !!agentsData.length && (
          <Pagination
              pagesCount={pagesCount}
              setOffset={setOffset}
              limit={limit}
              limitPlaceholder={limit.label}
              limits={LIMITS}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onChange={onChangeLimit}
          />
      )}
    </Container>
  );
}

export default memo(Agents);
