import "./index.scss";
import {memo, useCallback, useContext, useState} from "react";
import PageHeader from "../../../components/layout/page-header";
import {useTranslation} from "react-i18next";
import AuthContext from "../../../context/auth/auth-context";
import {AiOutlineEdit} from "react-icons/ai";
import {BsCheckLg} from "react-icons/bs";
import {IoMdClose} from "react-icons/io";
import api from "../../../utils/api";
import {errToString} from "../../../utils";
import PopUp from "../../../components/layout/popup";
import UpdateAccountPassword from "./account-profile-edit-password";
import {useNavigate} from "react-router-dom";
import {createPortal} from "react-dom";

function AccountProfile() {
  const {t} = useTranslation();
  const {user, update, logOut} = useContext(AuthContext);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [password, setPassword] = useState("xxxxxxxx");
  const [email, setEmail] = useState(user.email);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [src, setSrc] = useState(user.src);
  const [companyName, setCompanyName] = useState(user.company.name);
  const [closeAccountWarning, setCloseAccountWarning] = useState(false);
  const [editPasswordFormOpen, setEditPasswordFormOpen] = useState(false);

  const onChangeProfileData = useCallback((e) => {
    e.preventDefault();

    const data = {
      firstName,
      lastName
    };

    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    api
      .put("/profile", data)
      .then(({data}) => {
        setFirstName(data.firstName);
        setLastName((data.lastName));
        update(data);
        alert(t("pages.billing.dataSuccess"));
      })
      .catch(err => alert(t(`errors.${errToString(err)}`)))
  }, [t, firstName, lastName, update]);
  const onCloseAccount = useCallback((e) => {
    e.preventDefault();

    api
      .delete("/profile")
      .then(() => {
        localStorage.removeItem("token");
        logOut();
        navigate("/auth");
      })
      .catch(err => alert(t(`errors.${errToString(err)}`)))
  }, [t, logOut, navigate]);
  const onChangeImage = useCallback((e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('avatar', e.target.files[0]);

    api
      .put(`/profile`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' }})
      .then(({data}) => {
        setSrc(data.src);
        update(data);
      })
      .catch(err => alert(t(`errors.${errToString(err)}`)))
  }, [t, update]);

  return (
    <div className="account_profile">
      <PageHeader>
        <p>{t("pages.billing.accountDetails")}</p>
      </PageHeader>
      <div className="account_profile_in">
        <div className="account_profile_in_form">
          <div className="account_profile_in_form_left">
            <form onSubmit={onChangeProfileData}>
              <div className="account_profile_in_form_row">
                <div className="account_profile_in_form_col">
                  <label htmlFor="firstName">
                    {t("pages.billing.firstName")}
                  </label>
                  <input
                    name="firstName"
                    type="text"
                    placeholder={t("pages.billing.firstName")}
                    autoComplete="off"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="account_profile_in_form_row">
                <div className="account_profile_in_form_col">
                  <label htmlFor="lastName">
                    {t("pages.billing.lastName")}
                  </label>
                  <input
                    name="lastName"
                    type="text"
                    placeholder={t("pages.billing.lastName")}
                    autoComplete="off"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="account_profile_in_form_row">
                <div className="account_profile_in_form_col">
                  <label htmlFor="phoneNumber">
                    {t("pages.billing.phoneNumber")}
                  </label>
                  <input
                    name="phoneNumber"
                    type="text"
                    placeholder={t("pages.billing.phoneNumber")}
                    autoComplete="off"
                    value={phoneNumber}
                    disabled
                    onChange={e => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="account_profile_in_form_row">
                <div className="account_profile_in_form_col">
                  <label htmlFor="companyName">
                    {t("pages.billing.companyName")}
                  </label>
                  <input
                    name="companyName"
                    type="text"
                    placeholder={t("pages.billing.companyName")}
                    autoComplete="off"
                    value={companyName}
                    disabled
                    onChange={e => setCompanyName(e.target.value)}
                  />
                </div>
              </div>
              <button
                className="account_profile_in_form_btn account_profile_in_form_btn"
                type={"submit"}
              >{t("pages.billing.save")}</button>
            </form>
          </div>
          <div className="account_profile_in_form_right">
            <div className="account_profile_in_form_right_img">
              <p>{t("pages.billing.image")}</p>
              <img src={src} alt={user.firstName}/>
              <label className="account_profile_in_form_right_img_btn" htmlFor="profileImage">
                {t("pages.billing.changeImage")} <AiOutlineEdit />
              </label>
              <input
                id="profileImage"
                type="file"
                name="profileImage"
                onChange={(event) => onChangeImage(event)}
              />
            </div>
          </div>
        </div>
      </div>
      <PageHeader>
        <p>{t("pages.billing.loginDetails")}</p>
      </PageHeader>
      <div className="account_profile_in">
        <div className="account_profile_in_form">
          <div className="account_profile_in_form_left account_profile_in_form_left--full">
            <form>
              {user.email && user.email !== "" && (
                <div className="account_profile_in_form_row">
                  <div className="account_profile_in_form_col">
                    <label htmlFor="email">
                      {t("pages.billing.email")}
                    </label>
                    <input
                      name="email"
                      type="text"
                      placeholder={t("pages.billing.email")}
                      autoComplete="off"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      disabled
                    />
                  </div>
                  {user.isEmailVerified && (
                    <span className="account_profile_in_form_row_span account_profile_in_form_row_span--true"><BsCheckLg /> {t("pages.billing.confirmed")}</span>
                  )}
                  {!user.isEmailVerified && (
                    <span className="account_profile_in_form_row_span account_profile_in_form_row_span--false"><IoMdClose /> {t("pages.billing.notConfirmed")}</span>
                  )}
                </div>
              )}
              <div className="account_profile_in_form_row">
                <div className="account_profile_in_form_col">
                  <label htmlFor="password">
                    {t("pages.billing.password")}
                  </label>
                  <input
                    name="password"
                    type="text"
                    placeholder={t("pages.billing.password")}
                    autoComplete="off"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    disabled
                  />
                </div>
                <button
                  className="account_profile_in_form_btn account_profile_in_form_btn--edit"
                  onClick={(e) => {
                    e.preventDefault();
                    setEditPasswordFormOpen(true);
                  }}
                >{t("pages.billing.editPassword")}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <PageHeader>
        <p>{user.type === "owner" ? t("pages.billing.closeAccountOwner") : t("pages.billing.closeAccountManager")}</p>
      </PageHeader>
      <div className="account_profile_in">
        <div className="account_profile_in_form">
          <div className="account_profile_in_form_left">
            <p>{t("pages.billing.closeAccountDetails")}</p>
            <button
              className="account_profile_in_form_btn account_profile_in_form_btn--delete"
              onClick={() => setCloseAccountWarning(true)}
            >{user.type === "owner" ? t("pages.billing.closeAccountOwner") : t("pages.billing.closeAccountManager")}</button>
          </div>
        </div>
      </div>
      {closeAccountWarning && createPortal(
        <>
          <div
            className="agents_in_freeze_alert"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="agents_in_freeze_alert_title">{t("pages.billing.deleteAccountWarning")} ?</div>
            <div className="agents_in_freeze_alert_actions">
              <button
                className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_cancel"
                onClick={() => setCloseAccountWarning(false)}
              >{t("pages.agents.agent_card.cancel")}</button>
              <button
                className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_submit agents_in_freeze_alert_btn_submit--freeze"
                onClick={onCloseAccount}
              >{t("pages.agents.agent_card.freeze")}</button>
            </div>
          </div>
          <div
            className="agents_in_freeze_alert_overlay"
            onClick={() => setCloseAccountWarning(false)}
          ></div>
        </>, document.getElementById('modal')
      )}
      {editPasswordFormOpen && (
        <PopUp>
          <UpdateAccountPassword
            user={user}
            setEditPasswordFormOpen={setEditPasswordFormOpen}
          />
        </PopUp>
      )}
    </div>
  );
}

export default memo(AccountProfile);
