import './index.scss';
import React, {memo, useCallback, useMemo, useState, useContext, useEffect} from 'react';
import api from "../../../utils/api";
import {DEVICES} from "../../../constants/drop-down-options";
import DropDown from '../../../components/ui/drop-down';
import Form from '../../../components/form'
import FormRow from "../../../components/form/form-row";
import FormCol from "../../../components/form/form-col";
import PropTypes from "prop-types";
import sound from "../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Loading from "../../../components/ui/loading";
import {MdOutlineError, MdPersonOutline} from "react-icons/md";
import {AiFillCar, AiOutlineCar, AiOutlineFieldNumber, AiOutlineMail} from "react-icons/ai";
import {BsPersonCheck} from "react-icons/bs";
import UserCompanyBranchesContext from "../../../context/user-company-branches/user-company-branches-context";
import {RiEBike2Fill} from "react-icons/ri";
import {errToString} from "../../../utils";
import {useDebouncedCallback} from "use-debounce";
import {BsFillCheckCircleFill} from "react-icons/bs";
import PhoneNumberInput from "../../../components/ui/phone-number-input";
import { FaTruck } from "react-icons/fa";
import { IoMdBicycle } from "react-icons/io";
import cn from "classnames";

const GENDERS = [
  {
    value: "male",
    label: "male"
  },
  {
    value: "female",
    label: "female"
  }
]
function CreateAgent({
onClose,
agents,
setAgents,
setShowQr,
type = null,
setShowCreateAgentBtn,
refreshAgents,
}) {
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const {t} = useTranslation();
  const [branches, setBranches] = useState(null);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [fields, setFields] = useState({
    phoneNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    device: null,
    carBrand: "",
    carModel: "",
    carPlate: '',
  });
  const [loading, setLoading] = useState(true);
  const [phoneNumberLoading, setPhoneNumberLoading] = useState(false);
  const [phoneNumberPassed, setPhoneNumberPassed] = useState(false);
  const [usernameLoading, setUsernameLoading] = useState(false);
  const [usernamePassed, setUsernamePassed] = useState(false);
  const [parsedPhoneNumber, setParsedPhoneNumber] = useState(null);
  const [showPhoneNumberError, setShowPhoneNumberError] = useState(false);
  const [showUsernameError, setShowUsernameError] = useState(false);
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber]= useState('');
  const [assignedBranch, setAssignedBranch] = useState({label: "", value: ""});
  const [selectedGender, setSelectedGender] = useState({label: "", value: ""});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [carType, setCarType] = useState("car")

  const companyBranchesOptions = useMemo(() => {
    if (!branches) {
      return
    }

    return branches
      .filter(({deletedAt}) => !deletedAt)
      .map((address) => {
        return {
          ...address,
          value: address.id,
          label: address.name
        }
      })
  }, [branches]);
  const genders = useMemo(() => GENDERS.map(option => ({
    ...option,
    label: t(`pages.agents.create_agent.${option.label}`)
  })), [t]);

  const onCheckValidation = useDebouncedCallback((field, link) => {
    setError(null);

    if (!phoneNumber) {
      setShowPhoneNumberError(false)
    }
    if (!username) {
      setShowUsernameError(false);
    }
    if (!field) {
      setUsernameLoading(false);
      setPhoneNumberLoading(false);
      return;
    }


    if (link === "username") {
      setUsernameLoading(true);

      return (
        api
          .get(`/validations/username?username=${field}`)
          .then(() => {
            setUsernameLoading(false);
            setShowUsernameError(false);
            return setUsernamePassed(true);
          })
          .catch((err) => {
            setUsernamePassed(false);
            setUsernameLoading(false);
            setShowUsernameError(true);
            console.log(errToString(err));
            setError(errToString(err));
          })
      )
    }
    if (link === "phone-number") {
      if (!parsedPhoneNumber) {
        return
      }

      setPhoneNumberLoading(true);

      if (parsedPhoneNumber) {
        if (!parsedPhoneNumber.isPossible || !parsedPhoneNumber.isValid) {
          setPhoneNumberPassed(false);
          setPhoneNumberLoading(false);
          setShowPhoneNumberError(true);
          return setError(errToString("phoneNumber.invalid"));
        }
      }

      if (parsedPhoneNumber) {
        if (!parsedPhoneNumber.data) {
          setPhoneNumberPassed(false);
          setPhoneNumberLoading(false);
          setShowPhoneNumberError(false);
          return setError(null);
        }
      }

      if (parsedPhoneNumber) {
        if (parsedPhoneNumber.isPossible && parsedPhoneNumber.isValid && parsedPhoneNumber.data) {
          return (
            api
              .get(`/validations/phone-number?phoneNumber=${field}&countryCode=${parsedPhoneNumber.data.country?.toLowerCase()}`)
              .then(() => {
                setPhoneNumberLoading(false);
                setPhoneNumberPassed(true);
                setShowPhoneNumberError(false);
                setError(null);
              })
              .catch((err) => {
                console.log(errToString(err));
                setPhoneNumberPassed(false);
                setPhoneNumberLoading(false);
                setShowPhoneNumberError(true);
                setError(errToString(err));
              })
          )
        }
      }
    }

  }, 500);
  const onChangeField = useCallback((e, name) => {
    setError(null);

    if (
      name === 'device' ||
      name === 'gender'
    ) {
      return setFields({
        ...fields,
        [name]: e
      })
    }

    if (
      e.target.type === 'text' ||
      e.target.type === 'number'
    ) {
      setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [fields]);
  const onSubmit = useCallback(e => {
    e.preventDefault();

    if (!assignedBranch.id) {
      return setError("Branch is required")
    }

    const data = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      gender: selectedGender.value || null,
      username: username,
      phoneNumber: phoneNumber,
      email: fields.email || null,
      note: null,
      vehicleType: carType,
      device: fields.device?.value,
      carBrand: carType === "car" ? fields.carBrand : null,
      carModel: carType === "car" ? fields.carModel : null,
      carPlate: carType === "car" ? fields.carPlate : null,
      address: {
        countryCode: branches[0].countryCode,
        country: branches[0].countryCode,
        city: branches[0].countryCode,
        street: branches[0].deliveryAddress,
        apartment: null,
        entrance: null,
        floor: null,
      },
    };

    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    for (const key in data.address) {
      if (data.address[key] === null) {
        delete data.address[key];
      }
    }

    setPending(true);

    api
      .post("/employees/agents", data)
      .then((res) => {
        api
          .post(`/company/address/${assignedBranch.id}/employees`, {
            ids: [res.data.id]
          })
          .then(() => {
            api
              .get('/user-company-addresses')
              .then((res) => userCompanyBranches.set(res.data))
              .catch((err) => console.log(errToString(err)))
          })
          .catch((err) => console.log(errToString(err)))

        setPending(false);
        setAgents([
          res.data,
          ...agents
        ]);

        if(type === "congrats") {
          console.log(111)
          setShowCreateAgentBtn(false);
          setShowQr(true);
          refreshAgents();
        }

        const audio = new Audio(sound);
        const info = t("pages.agents.create_agent.createdNotification");
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
        onClose();
      })
      .catch((err) => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      })
  // eslint-disable-next-line
  }, [phoneNumber, username, carType, selectedGender, t, onClose, fields, agents, setAgents, assignedBranch, userCompanyBranches.set]);

  useEffect(() => {
    const promises = [];
    promises.push(api.get("/company/addresses"));

    Promise
      .all(promises)
      .then((res) => {
        setBranches(res[0].data)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(errToString(err))
      })
  }, []);
  useEffect(() => {
    if (!phoneNumber) {
      return
    }

    setPhoneNumberPassed(false);
    setShowPhoneNumberError(false);
    setError(null);
    onCheckValidation(phoneNumber, "phone-number");
  }, [phoneNumber, onCheckValidation]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {!loading && (
        <div className="create_agent">
          <div className="create_agent_in">
            <Form
              title={t("pages.agents.create_agent.createAgent")}
              error={error && t(`errors.${error?.toLowerCase()}`)}
              onSubmit={onSubmit}
            >
              <div className="create_agent_in_body">
                {windowWidth > 600 && (
                  <>
                    <FormRow>
                      <FormCol>
                        <label htmlFor="phoneNumber">
                          <span>*</span>
                          {t("pages.agents.create_agent.phoneNumber")}
                        </label>
                        <PhoneNumberInput
                          country={branches[0].countryCode?.toUpperCase()}
                          placeholder={t("pages.signUp.phoneNumber")}
                          value={phoneNumber}
                          setValue={setPhoneNumber}
                          loading={phoneNumberLoading}
                          passed={phoneNumberPassed}
                          showError={showPhoneNumberError}
                          setParsedPhoneNumber={setParsedPhoneNumber}
                        />
                      </FormCol>
                      <FormCol>
                        <label htmlFor="username">
                          <span>*</span>
                          {t("pages.agents.create_agent.username")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <BsPersonCheck />
                          </div>
                          <input
                            name="username"
                            type="text"
                            className="create_order_wrapper_input"
                            placeholder={t("pages.signUp.username")}
                            autoComplete="off"
                            value={username}
                            onChange={(e) => {
                              setError(null);
                              setUsernamePassed(false);
                              setUsername(e.target.value);
                              onCheckValidation(e.target.value, "username");
                            }}
                          />
                          <div className="create_order_wrapper_icon create_order_wrapper_icon--loading">
                            {usernameLoading && (
                              <Loading />
                            )}
                            {usernamePassed && (
                              <span className=" create_order_wrapper_icon create_order_wrapper_icon--green">
                            <BsFillCheckCircleFill/>
                          </span>
                            )}
                            {showUsernameError && !usernameLoading && (
                              <span className="create_order_wrapper_icon create_order_wrapper_icon--red">
                            <MdOutlineError />
                          </span>
                            )}
                          </div>
                        </div>
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <label htmlFor="firstName">
                          <span>*</span>
                          {t("pages.agents.create_agent.firstName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <MdPersonOutline />
                          </div>
                          <input
                            className="create_order_wrapper_input"
                            name="firstName"
                            type="text"
                            placeholder={t("pages.agents.create_agent.firstName")}
                            autoComplete="off"
                            value={fields.firstName}
                            onChange={e => onChangeField(e, 'firstName')}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label htmlFor="lastName">
                          <span>*</span>
                          {t("pages.agents.create_agent.lastName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <MdPersonOutline />
                          </div>
                          <input
                            name="lastName"
                            className="create_order_wrapper_input"
                            type="text"
                            placeholder= {t("pages.agents.create_agent.lastName")}
                            autoComplete="off"
                            value={fields.lastName}
                            onChange={e => onChangeField(e, 'lastName')}
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <label htmlFor="gender">
                          <span>*</span>
                          {t("pages.agents.create_agent.gender")}
                        </label>
                        <DropDown
                          hasPersonIcon={true}
                          name="gender"
                          placeholder={t("pages.agents.create_agent.gender")}
                          options={genders}
                          position={"top"}
                          value={selectedGender}
                          onChange={e => {
                            setError(null);
                            return setSelectedGender(e);
                          }}
                        />
                      </FormCol>
                      <FormCol>
                        <label htmlFor="email">
                          {t("pages.agents.create_agent.email")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <AiOutlineMail />
                          </div>
                          <input
                            name="email"
                            className="create_order_wrapper_input"
                            type="text"
                            placeholder= {t("pages.agents.create_agent.email")}
                            autoComplete="off"
                            value={fields.email}
                            onChange={e => onChangeField(e, 'email')}
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol title={t("pages.agents.create_agent.additionalInfo")}>
                        <label htmlFor="device">
                          <span>*</span>
                          {t("pages.agents.create_agent.device")}
                        </label>
                        <DropDown
                          hasDeviceIcon={true}
                          name="device"
                          placeholder={t("pages.agents.create_agent.device")}
                          options={DEVICES}
                          position={"top"}
                          value={fields.device}
                          onChange={e => onChangeField(e, 'device')}
                        />
                      </FormCol>
                      <FormCol>
                        <label>
                          <span>*</span>
                          {t("pages.agents.create_agent.branches")}
                        </label>
                        <DropDown
                          hasBranchIcon={true}
                          placeholder={t("pages.agents.create_agent.chooseBranch")}
                          options={companyBranchesOptions}
                          value={assignedBranch}
                          onChange={(e) => {
                            setError(null);
                            return setAssignedBranch(e);
                          }}
                          position="bottom"
                          showClose={false}
                        />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <div className="create_agent_in_switcher">
                        <div
                          className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "car"})}
                          onClick={() => {
                            setCarType("car");
                            setError(null)
                          }}
                        ><AiFillCar /></div>
                        <div
                          className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "bike"})}
                          onClick={() => {
                            setCarType("bike");
                            setError(null)
                          }}
                        ><RiEBike2Fill /></div>
                        <div
                          className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "bicycle"})}
                          onClick={() => {
                            setCarType("bicycle");
                            setError(null)
                          }}
                        ><IoMdBicycle /></div>
                        <div
                          className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "truck"})}
                          onClick={() => {
                            setCarType("truck");
                            setError(null)
                          }}
                        ><FaTruck /></div>
                      </div>
                    </FormRow>
                    {carType === "car" && (
                      <FormRow>
                        <FormCol>
                          <label htmlFor="carBrand">
                            <span>*</span>
                            {t("pages.agents.create_agent.carBrand")}
                          </label>
                          <div className="create_order_wrapper">
                            <div className="create_order_wrapper_icon">
                              <AiOutlineCar />
                            </div>
                            <input
                              type="text"
                              name="carBrand"
                              className="create_order_wrapper_input"
                              placeholder={t("pages.agents.create_agent.carBrand")}
                              autoComplete="off"
                              value={fields.carBrand}
                              onChange={e => onChangeField(e, 'carBrand')}
                            />
                          </div>
                        </FormCol>
                        <FormCol>
                          <label htmlFor="carModel">
                            <span>*</span>
                            {t("pages.agents.create_agent.carModel")}
                          </label>
                          <div className="create_order_wrapper">
                            <div className="create_order_wrapper_icon">
                              <AiOutlineCar />
                            </div>
                            <input
                              type="text"
                              name="carModel"
                              className="create_order_wrapper_input"
                              placeholder={t("pages.agents.create_agent.carModel")}
                              autoComplete="off"
                              value={fields.carModel}
                              onChange={e => onChangeField(e, 'carModel')}
                            />
                          </div>
                        </FormCol>
                        <FormCol>
                          <label htmlFor="carPlate">
                            <span>*</span>
                            {t("pages.agents.create_agent.carPlate")}
                          </label>
                          <div className="create_order_wrapper">
                            <div className="create_order_wrapper_icon">
                              <AiOutlineFieldNumber />
                            </div>
                            <input
                              name="carPlate"
                              className="create_order_wrapper_input"
                              type="text"
                              placeholder={t("pages.agents.create_agent.carPlate")}
                              autoComplete="off"
                              value={fields.carPlate}
                              onChange={e => onChangeField(e, 'carPlate')}
                            />
                          </div>
                        </FormCol>
                      </FormRow>
                    )}
                  </>
                )}
                {windowWidth <= 600 && (
                  <>
                    <FormRow>
                      <FormCol>
                        <label htmlFor="phoneNumber">
                          <span>*</span>
                          {t("pages.agents.create_agent.phoneNumber")}
                        </label>
                        <PhoneNumberInput
                          country={branches[0].countryCode?.toUpperCase()}
                          placeholder={t("pages.signUp.phoneNumber")}
                          value={phoneNumber}
                          setValue={setPhoneNumber}
                          loading={phoneNumberLoading}
                          passed={phoneNumberPassed}
                          showError={showPhoneNumberError}
                          setParsedPhoneNumber={setParsedPhoneNumber}
                        />
                      </FormCol>
                      <FormCol>
                        <label htmlFor="username">
                          <span>*</span>
                          {t("pages.agents.create_agent.username")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <BsPersonCheck />
                          </div>
                          <input
                            name="username"
                            type="text"
                            className="create_order_wrapper_input"
                            placeholder={t("pages.signUp.username")}
                            autoComplete="off"
                            value={username}
                            onChange={(e) => {
                              setError(null);
                              setUsernamePassed(false);
                              setUsername(e.target.value);
                              onCheckValidation(e.target.value, "username");
                            }}
                          />
                          <div className="create_order_wrapper_icon create_order_wrapper_icon--loading">
                            {usernameLoading && (
                              <Loading />
                            )}
                            {usernamePassed && (
                              <span className=" create_order_wrapper_icon create_order_wrapper_icon--green">
                            <BsFillCheckCircleFill/>
                          </span>
                            )}
                            {showUsernameError && !usernameLoading && (
                              <span className="create_order_wrapper_icon create_order_wrapper_icon--red">
                            <MdOutlineError />
                          </span>
                            )}
                          </div>
                        </div>
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <label htmlFor="firstName">
                          <span>*</span>
                          {t("pages.agents.create_agent.firstName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <MdPersonOutline />
                          </div>
                          <input
                            className="create_order_wrapper_input"
                            name="firstName"
                            type="text"
                            placeholder={t("pages.agents.create_agent.firstName")}
                            autoComplete="off"
                            value={fields.firstName}
                            onChange={e => onChangeField(e, 'firstName')}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label htmlFor="lastName">
                          <span>*</span>
                          {t("pages.agents.create_agent.lastName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <MdPersonOutline />
                          </div>
                          <input
                            name="lastName"
                            className="create_order_wrapper_input"
                            type="text"
                            placeholder= {t("pages.agents.create_agent.lastName")}
                            autoComplete="off"
                            value={fields.lastName}
                            onChange={e => onChangeField(e, 'lastName')}
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <label htmlFor="gender">
                          <span>*</span>
                          {t("pages.agents.create_agent.gender")}
                        </label>
                        <DropDown
                          hasPersonIcon={true}
                          name="gender"
                          placeholder={t("pages.agents.create_agent.gender")}
                          options={genders}
                          position={"top"}
                          value={selectedGender}
                          onChange={e => {
                            setError(null);
                            return setSelectedGender(e);
                          }}
                        />
                      </FormCol>
                      <FormCol>
                        <label htmlFor="email">
                          {t("pages.agents.create_agent.email")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <AiOutlineMail />
                          </div>
                          <input
                            name="email"
                            className="create_order_wrapper_input"
                            type="text"
                            placeholder= {t("pages.agents.create_agent.email")}
                            autoComplete="off"
                            value={fields.email}
                            onChange={e => onChangeField(e, 'email')}
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <label>
                          <span>*</span>
                          {t("pages.agents.create_agent.branches")}
                        </label>
                        <DropDown
                          hasBranchIcon={true}
                          placeholder={t("pages.agents.create_agent.chooseBranch")}
                          options={companyBranchesOptions}
                          value={assignedBranch}
                          onChange={(e) => {
                            setError(null);
                            return setAssignedBranch(e);
                          }}
                          position="bottom"
                          showClose={false}
                        />
                      </FormCol>
                      <FormRow>
                        <FormCol title={t("pages.agents.create_agent.additionalInfo")}>
                          <label htmlFor="device">
                            <span>*</span>
                            {t("pages.agents.create_agent.device")}
                          </label>
                          <DropDown
                            hasDeviceIcon={true}
                            name="device"
                            placeholder={t("pages.agents.create_agent.device")}
                            options={DEVICES}
                            position={"top"}
                            value={fields.device}
                            onChange={e => onChangeField(e, 'device')}
                          />
                        </FormCol>
                      </FormRow>
                    </FormRow>
                    <FormRow>
                      <div className="create_agent_in_switcher">
                        <div
                          className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "car"})}
                          onClick={() => {
                            setCarType("car");
                            setError(null)
                          }}
                        ><AiFillCar /></div>
                        <div
                          className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "bike"})}
                          onClick={() => {
                            setCarType("bike");
                            setError(null)
                          }}
                        ><RiEBike2Fill /></div>
                        <div
                          className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "bicycle"})}
                          onClick={() => {
                            setCarType("bicycle");
                            setError(null)
                          }}
                        ><IoMdBicycle /></div>
                        <div
                          className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "truck"})}
                          onClick={() => {
                            setCarType("truck");
                            setError(null)
                          }}
                        ><FaTruck /></div>
                      </div>
                    </FormRow>
                    {carType === "car" && (
                      <>
                        <FormRow>
                          <FormCol>
                            <label htmlFor="carBrand">
                              <span>*</span>
                              {t("pages.agents.create_agent.carBrand")}
                            </label>
                            <div className="create_order_wrapper">
                              <div className="create_order_wrapper_icon">
                                <AiOutlineCar />
                              </div>
                              <input
                                type="text"
                                name="carBrand"
                                className="create_order_wrapper_input"
                                placeholder={t("pages.agents.create_agent.carBrand")}
                                autoComplete="off"
                                value={fields.carBrand}
                                onChange={e => onChangeField(e, 'carBrand')}
                              />
                            </div>
                          </FormCol>
                          <FormCol>
                            <label htmlFor="carModel">
                              <span>*</span>
                              {t("pages.agents.create_agent.carModel")}
                            </label>
                            <div className="create_order_wrapper">
                              <div className="create_order_wrapper_icon">
                                <AiOutlineCar />
                              </div>
                              <input
                                type="text"
                                name="carModel"
                                className="create_order_wrapper_input"
                                placeholder={t("pages.agents.create_agent.carModel")}
                                autoComplete="off"
                                value={fields.carModel}
                                onChange={e => onChangeField(e, 'carModel')}
                              />
                            </div>
                          </FormCol>
                        </FormRow>
                        <FormRow>
                          <FormCol>
                            <label htmlFor="carPlate">
                              <span>*</span>
                              {t("pages.agents.create_agent.carPlate")}
                            </label>
                            <div className="create_order_wrapper">
                              <div className="create_order_wrapper_icon">
                                <AiOutlineFieldNumber />
                              </div>
                              <input
                                name="carPlate"
                                className="create_order_wrapper_input"
                                type="text"
                                placeholder={t("pages.agents.create_agent.carPlate")}
                                autoComplete="off"
                                value={fields.carPlate}
                                onChange={e => onChangeField(e, 'carPlate')}
                              />
                            </div>
                          </FormCol>
                          <FormCol />
                        </FormRow>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="create_agent_in_actions">
                <FormRow>
                  <FormCol>
                    <a
                      href="!#"
                      className="create_agent__cancel_btn"
                      onClick={onClose}
                    >{t("pages.agents.create_agent.cancel")}</a>
                  </FormCol>
                  <FormCol>
                    {!pending && (
                      <button className="create_agent__submit_btn">{t("pages.agents.create_agent.create")}</button>
                    )}
                    {pending && (
                      <button className="create_agent__submit_btn create_agent__submit_btn--pending">
                        <Loading />
                      </button>
                    )}
                  </FormCol>
                </FormRow>
              </div>
            </Form>
          </div>
        </div>
      )}
      {loading && (
        <div className="agents_loading_wrapper">
          <Loading/>
        </div>
      )}
    </>
  );
}

CreateAgent.propTypes = {
  onClose: PropTypes.func,
  agents: PropTypes.array.isRequired,
  setAgents: PropTypes.func.isRequired
};

export default memo(CreateAgent);
