import "./index.scss";
import {memo, useState, useEffect, useMemo} from "react";
import PageHeader from "../../../../components/layout/page-header";
import api from "../../../../utils/api";
import Loading from "../../../../components/ui/loading";
import {useTranslation} from 'react-i18next';
import {errToString} from "../../../../utils";
import SettingsIntegrationsSmsMobileWidget from "./settings-integrations-sms-mobile-widget";
import SettingsIntegrationsSmsActivation from "./settings-integrations-sms-activation/index";
import SettingsIntegrationsSmsAllBranches from "./settings-integrations-sms-external-all";

const ACTIVE_SMS_SELECTION = [
    {label: "sms_is_not_active", value: 'sms_is_not_active'},
    {label: "sms_is_active", value: 'sms_is_active'},
]
// const SMS_SELECTION_OPTIONS = [
//   {label: "all", value: 'all_branches'},
//   {label: "branch", value: 'per_branch'},
// ]

function SettingsIntegrationsSms() {
    // const {user} = useContext(AuthContext);
    const {t} = useTranslation();

    const [loading, setLoading] = useState(true);
    const [templateLoading, setTemplateLoading] = useState(true);
    const [templates, setTemplates] = useState(null);
    const [isSmsActivated, setIsSmsActivated] = useState(null);
    const [smsChannels, setSmsChannels] = useState(null);
    const [selectedSmsChannel, setSelectedSmsChannel] = useState(null);
    const activeSmsSelection = useMemo(() => ACTIVE_SMS_SELECTION.map(option => ({
        ...option,
        label: t(`pages.settings.sections.sms.${option.label}`)
    })), [t]);

    // const [selectedInternalSmsChannel, setSelectedInternalSmsChannel] = useState(null);
    // const smsSelectionOptions = useMemo(() => SMS_SELECTION_OPTIONS.map(option => ({
    //   ...option,
    //   label: t(`pages.settings.sections.sms.${option.label}`)
    // })), [t]);

    //TODO Need to get company integration type from user.company, after submitting changes backend must consider how to change integration type
    // const [smsSelectionOption, setSmsSelectionOption] = useState(smsSelectionOptions.find((el) => el.value === user.company.integrationType));
    // const [smsSelectionOption, setSmsSelectionOption] = useState(smsSelectionOptions.find((el) => el.value === "all_branches"));
    // console.log(user.company.integrationType);

    useEffect(() => {
        api
            .get("/company/integrations")
            .then(({data}) => {
                const smsChannels = data
                    .map(item => {
                        return {...item, 'label': item.value}
                    })
                    .filter(item => item.name === 'sms');
                setSmsChannels(smsChannels);

                setSelectedSmsChannel(smsChannels.find(({ isActive }) => isActive));
                // setSelectedSmsChannel(smsChannels.filter(({type}) => type === "external").find(({ isActive }) => isActive));
                // setSelectedInternalSmsChannel(smsChannels.filter(({type}) => type === "internal").find(({ isActive }) => isActive));

                if (smsChannels.find(({ isActive }) => isActive)) {
                    setIsSmsActivated(true);
                } else {
                    setIsSmsActivated(false);
                }

                setLoading(false);
            })
            .catch((err) => console.log(errToString(err)))
    }, []);
    useEffect(() => {
        api
            .get("/company/templates")
            .then((res) => {
                setTemplates(res.data
                    .filter(({isActive}) => isActive)
                    .filter((status) => {
                        return (
                            status.name !== "recover_password_sms_template" &&
                            status.name !== "create_employee_sms_template" &&
                            status.name !== "order_delivered_by_partner_sms_template"
                        )
                    }))
                setTemplateLoading(false)
            })
            .catch((err) => console.log(err))
    }, []);

    return (
        <>
            {!loading && !templateLoading && (
                <div className="settings_integrations_sms">
                    <PageHeader>
                        <p>SMS</p>
                    </PageHeader>
                    <div className="settings_integrations_sms_in">
                        <div className="settings_integrations_sms_in_left">
                            {/*<SettingsIntegrationsSmsInternal*/}
                            {/*  selectedInternalSmsChannel={selectedInternalSmsChannel}*/}
                            {/*  setSelectedInternalSmsChannel={setSelectedInternalSmsChannel}*/}
                            {/*  smsChannels={smsChannels.filter(({type}) => type === "internal")}*/}
                            {/*/>*/}
                            <SettingsIntegrationsSmsActivation
                                smsChannels={smsChannels}
                                activeSmsSelection={activeSmsSelection}
                                setIsSmsActivated={setIsSmsActivated}
                                isSmsActivated={isSmsActivated}
                            />
                            {isSmsActivated && (
                                <>
                                    <SettingsIntegrationsSmsAllBranches
                                        selectedSmsChannel={selectedSmsChannel}
                                        setSelectedSmsChannel={setSelectedSmsChannel}
                                        smsChannels={smsChannels}
                                    />
                                    {/*<div className="settings_integrations_sms_in_left_wrapper">*/}
                                    {/*  <p className="settings_integrations_sms_in_left_descrip">*/}
                                    {/*    {t("pages.settings.smsBranchConnection")}*/}
                                    {/*  </p>*/}
                                    {/*  <div className="settings_integrations_sms_in_drop_down">*/}
                                    {/*    <DropDown*/}
                                    {/*      hasSmsIcon={true}*/}
                                    {/*      placeholder="Choose options"*/}
                                    {/*      options={smsSelectionOptions}*/}
                                    {/*      value={smsSelectionOption}*/}
                                    {/*      onChange={(e) => setSmsSelectionOption(e)}*/}
                                    {/*      position="bottom"*/}
                                    {/*      showClose={false}*/}
                                    {/*    />*/}
                                    {/*  </div>*/}
                                    {/*</div>*/}
                                    {/*{smsSelectionOption.value === "all_branches" && (*/}
                                    {/*  <SettingsIntegrationsSmsAllBranches*/}
                                    {/*    selectedSmsChannel={selectedSmsChannel}*/}
                                    {/*    setSelectedSmsChannel={setSelectedSmsChannel}*/}
                                    {/*    smsChannels={smsChannels*/}
                                    {/*      .filter(({type}) => type === "external")*/}
                                    {/*      .filter(({companyAddressId}) => companyAddressId === null)}*/}
                                    {/*  />*/}
                                    {/*)}*/}
                                    {/*{smsSelectionOption.value === "per_branch" && (*/}
                                    {/*  <SettingsIntegrationsSmsPerBranch*/}
                                    {/*    smsChannels={smsChannels}*/}
                                    {/*  />*/}
                                    {/*)}*/}
                                </>
                            )}
                        </div>
                        <SettingsIntegrationsSmsMobileWidget
                            isSmsActivated={isSmsActivated}
                            selectedSmsChannel={selectedSmsChannel}
                            templates={templates}
                        />
                    </div>
                </div>
            )}
            {loading && (
                <div className="settings_integrations_sms_in_options_loading">
                    <Loading />
                </div>
            )}
        </>
    );
}

export default memo(SettingsIntegrationsSms);
