import './index.scss';
import React, {memo, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import Map from '../map';
import Form from '../form';
import api from '../../utils/api';
import FormRow from '../form/form-row';
import FormCol from '../form/form-col';
import DropDown from '../ui/drop-down';
import DateTimePicker from '../ui/calendar/date-time-picker';
import AuthContext from '../../context/auth/auth-context';
import {useDebouncedCallback} from 'use-debounce';
import {getAddressFromLatLng, getLatLngFromAddress, searchAddress} from '../../utils/geocoding';
import {MdPersonOutline} from "react-icons/md";
import {BsDoorClosed, BsPlus, BsPlusCircleFill} from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import {AiFillDollarCircle, AiFillPhone, AiOutlineMail} from "react-icons/ai";
import { AiFillCreditCard } from "react-icons/ai";
import MarkerOngoingSvg from '../../assets/markers/marker_started.svg';
import moment from 'moment/moment';
import {errToString, removeDuplicates} from '../../utils';
import DropDownPhoneNumber from '../ui/drop-down-phone-number';
import cn from 'classnames';
import Loading from '../ui/loading';
import {fromLonLat} from 'ol/proj';
import {useTranslation} from "react-i18next";
import {BiHomeSmile} from "react-icons/bi";
import {TbStairs} from "react-icons/tb";
import {GrMoney} from "react-icons/gr";
import {Tooltip} from "react-tooltip";
import PaymentMethodsContext from "../../context/payment-methods/payment-methods-context";
import UserCompanyBranchesContext from "../../context/user-company-branches/user-company-branches-context";
import {useNavigate} from "react-router-dom";
import AssignAgentForm from "../assign-agent-form";
import PreferencesContext from "../../context/preferences/preferences-context";
import { AiOutlineNumber } from "react-icons/ai";
import IntegrationsContext from "../../context/integrations/integrations-context";

const getStartDate = () => {
  const startDate = new Date();
  startDate.setHours(startDate.getHours());
  startDate.setMinutes(startDate.getMinutes() + 15);

  return startDate;
};
const getEndDate = (min) => {
  const endDate = new Date();
  endDate.setHours(endDate.getHours());
  endDate.setMinutes(endDate.getMinutes() + 15 + min);

  return endDate;
};

function CreateOrder({
  mapIntegration,
  center,
  onClose,
  setActiveTabOrders,
  selectedBranchId,
  startDate,
  endDate
}) {
  const integrations = useContext(IntegrationsContext);
  const paymentMethods = useContext(PaymentMethodsContext);
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const {user} = useContext(AuthContext);
  const {list} = useContext(PreferencesContext);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [pending, setPending] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [agentsData, setAgentsData] = useState(null);
  const [partnersData, setPartnersData] = useState(null);
  const [assignedAgent, setAssignedAgent] = useState(null);
  const [assignedPartner, setAssignedPartner] = useState(null);
  const [pickupAddressMethods, setPickupAddressMethods] = useState(null);
  const [paymentMethodsMethods, setPaymentMethodsMethods] = useState(null);
  const [deliveryAddressMethods, setDeliveryAddressMethods] = useState(null);
  const [phoneNumberMethods, setPhoneNumberMethods] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const pickupAddress = useMemo(() => {
    if (selectedBranchId !== -1) {
      return {
        value: userCompanyBranches.list.find(({id}) =>  id === selectedBranchId).id,
        label: userCompanyBranches.list.find(({id}) =>  id === selectedBranchId).name,
        location: userCompanyBranches.list.find(({id}) =>  id === selectedBranchId).location
      }
    }

    if (selectedBranchId === -1) {
      return {
        value: userCompanyBranches.list[0].id,
        label: userCompanyBranches.list[0].name,
        location: userCompanyBranches.list[0].location,
      }
    }

  }, [selectedBranchId, userCompanyBranches]);
  const [map, setMap] = useState(null);
  const [fields, setFields] = useState({
    email: '',
    firstName: '',
    lastName: '',
    pickupAddress: pickupAddress,
    lat: '',
    lng: '',
    deliveryAddress: null,
    apartment: '',
    extraId: '',
    entrance: '',
    floor: '',
    amount: '',
    paymentMethod: null,
    startDate: getStartDate(),
    endDate: getEndDate(list.order_delay_time.value.low / 60),
    note: '',
    deliveryType: 'now'
  });

  const [customers, setCustomers] = useState([]);
  const [customerPhoneNumberSearch, setCustomerPhoneNumberSearch] = useState(null);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState(null);
  const [error, setError] = useState(null);
  const [customerPhoneNumberLoading, setCustomerPhoneNumberLoading] = useState(false);
  const [deliveryAddressLoading, setDeliveryAddressLoading] = useState(false);
  const [showAssignForm, setShowAssignForm] = useState(false);
  const [assignTypeAgents, setAssignTypeAgents] = useState(true);

  const [geocodingAddresses, setGeocodingAddresses] = useState([]);
  const [deliveryAddressFromMap, setDeliveryAddressFromMap] = useState(null);

  const pickupAddresses = useMemo(() => {
    return userCompanyBranches.list.map(address => ({
      value: address.id,
      label: `${address.name}`,
      location: address.location
    }));
  }, [userCompanyBranches]);
  const existingDeliveryAddresses = useMemo(() => {
    if (selectedCustomer) {
      return selectedCustomer?.addresses.map(address => ({
        value: address.id,
        label: address.deliveryAddress,
        location: address.location,
        apartment: address.apartment,
        entrance: address.entrance,
        floor: address.floor
      }));
    }

    return [];
  }, [selectedCustomer]);
  const deliveryAddresses = useMemo(() => {
    const deliveryAddresses = removeDuplicates([...geocodingAddresses, ...existingDeliveryAddresses], 'value')

    if (deliveryAddressFromMap) {
      deliveryAddresses.unshift(deliveryAddressFromMap);
    }

    return deliveryAddresses;
  }, [existingDeliveryAddresses, geocodingAddresses, deliveryAddressFromMap]);
  const agentsOptions = useMemo(() => {
    if (!agentsData) {
      return [];
    }

    const agentsDataOptions = agentsData
      ?.map(agent => {
        return {
          ...agent,
          value: agent.id,
          label: `${agent.firstName} ${agent.lastName}`,
        }
      })
      .sort((a, b) => b.isOnline - a.isOnline);

    return [...agentsDataOptions]

  }, [agentsData]);
  const partnersOptions = useMemo(() => {
    if (!partnersData) {
      return [];
    }

    const partnersDataOptions = partnersData?.map(partner => {
      return {
        ...partner,
        value: partner.id,
        label: partner.name,
      }
    })

    return [...partnersDataOptions]

  }, [partnersData]);
  const paymentMethodsOptions = useMemo(() => paymentMethods.list.filter(({ deletedAt }) => !deletedAt).map(paymentMethod => ({
    value: paymentMethod.id,
    label: paymentMethod.name
  })), [paymentMethods.list]);
  const markers = useMemo(() => {
    const markers = [];

    const data = {};
    if (integrations.mapIntegration.value === "open_street") {
      data.type = "default"
    }
    if (integrations.mapIntegration.value === "google") {
      data.type = integrations.mapIntegration.value;
      data.apiKey = integrations.mapIntegration?.credentials?.apiKey;
    }

    if (fields.deliveryAddress) {
      markers.push({
        id: fields.deliveryAddress.value,
        type: 'address',
        position: fields.deliveryAddress.location,
        draggable: true,
        icon: MarkerOngoingSvg,
        onDragEnd: e => getAddressFromLatLng(e.latLng.lat(), e.latLng.lng(), address => {
          setGeocodingAddresses([]);
          setDeliveryAddressFromMap(address);
          setFields({
            ...fields,
            deliveryAddress: address,
            lat: address.location.lat,
            lng: address.location.lng
          });
        }, data)
      });
    }

    return markers;
  }, [integrations, fields]);

  const onSearchCustomerPhoneNumber = useDebouncedCallback(phoneNumber => {
    setError(null);

    if (!phoneNumber) {
      setCustomerPhoneNumberSearch(null);
      return onResetCustomerPhoneNumber();
    }

    setCustomerPhoneNumberSearch(phoneNumber);
    setCustomerPhoneNumberLoading(true);

    api
      .get(`/customers?phoneNumber=${encodeURIComponent(phoneNumber)}`)
      .then(({ data }) => {
        setCustomers(data);
        setCustomerPhoneNumberLoading(false);
      });
  }, 500);
  const onChangeCustomerPhoneNumber = useCallback(option => {
    setError(null);
    if (!option) {
      return;
    }

    const lastAddress = option.customer.addresses.length - 1;
    const deliveryAddress = option.customer.addresses[lastAddress] ? {
      value: option.customer.addresses[lastAddress].id,
      label: option.customer.addresses[lastAddress].deliveryAddress,
      location: option.customer.addresses[lastAddress].location,
      apartment: option.customer.addresses[lastAddress].apartment,
      floor: option.customer.addresses[lastAddress].floor,
      entrance: option.customer.addresses[lastAddress].entrance
    } : null;

    if (deliveryAddress) {
      if (mapIntegration.value === 'google') {
        map.setCenter(deliveryAddress.location);
        map.setZoom(16);
      }
      if (mapIntegration.value === 'open_street') {
        map.getView().setCenter(fromLonLat([deliveryAddress.location.lng, deliveryAddress.location.lat]));
        map.getView().setZoom(16);
      }
    }

    setCustomerPhoneNumber(option);
    setSelectedCustomer(option.customer);
    setFields({
      ...fields,
      firstName: option.customer.firstName || '',
      lastName: option.customer.lastName || '',
      email: option.customer.email || '',
      deliveryAddress,
      apartment: deliveryAddress?.apartment || '',
      entrance: deliveryAddress?.entrance || '',
      floor: deliveryAddress?.floor || '',
      lat: deliveryAddress?.location?.lat,
      lng: deliveryAddress?.location?.lng
    });
    deliveryAddressMethods?.resetSearch();
  }, [fields, deliveryAddressMethods, map, mapIntegration.value]);
  const onChangeDeliveryAddress = useDebouncedCallback(value => {
    setError(null);

    if (!value) {
      return
    }

    setDeliveryAddressLoading(true);

    const data = {};
    if (integrations.mapIntegration.value === "open_street") {
      data.type = "default"
    }
    if (integrations.mapIntegration.value === "google") {
      data.type = integrations.mapIntegration.value;
      data.apiKey = integrations.mapIntegration?.credentials?.apiKey;
    }

    searchAddress(user.address.countryCode, `${value}`, (err, addresses) => {
      if (err) {
        setGeocodingAddresses([]);
        return setDeliveryAddressLoading(false);
      }

      setGeocodingAddresses(addresses);
      setDeliveryAddressLoading(false);
    }, data);
  }, 1000);
  const onResetDeliveryAddress = useCallback(() => {
    setFields({
      ...fields,
      deliveryAddress: null,
      apartment: '',
      entrance: '',
      floor: '',
      lat: '',
      lng: ''
    });
    setGeocodingAddresses([]);
    setDeliveryAddressFromMap(null);

    if (mapIntegration.value === 'google') {
      map.setCenter(center);
      map.setZoom(13);
    }
    if (mapIntegration.value === 'open_street') {
      map.getView().setCenter(fromLonLat([center.lng, center.lat]));
      map.getView().setZoom(13);
    }
  }, [fields, map, center, mapIntegration.value]);
  const onResetCustomerPhoneNumber = useCallback(() => {
    setCustomerPhoneNumber(null);
    setCustomers([]);
    setSelectedCustomer(null);
    setFields({
      ...fields,
      firstName: '',
      lastName: '',
      email: '',
      deliveryAddress: null,
      apartment: '',
      entrance: '',
      floor: '',
      lat: '',
      lng: ''
    });
    setCustomerPhoneNumberSearch(null);
    setGeocodingAddresses([]);
    setDeliveryAddressFromMap(null);
    deliveryAddressMethods?.resetSearch();
  }, [fields, deliveryAddressMethods]);
  const closeAllDropDowns = useCallback(() => {
    phoneNumberMethods?.close && phoneNumberMethods.close();
    deliveryAddressMethods?.close && deliveryAddressMethods.close();
    pickupAddressMethods?.close && pickupAddressMethods.close();
    paymentMethodsMethods?.close && paymentMethodsMethods.close();
  }, [phoneNumberMethods, deliveryAddressMethods, pickupAddressMethods, paymentMethodsMethods]);

  const onChangeField = useCallback((e, name) => {
    setError(null);

    if (
      name === 'lat' ||
      name === 'lng'
    ) {
      const lat = name === 'lat' ? Number(e.target.value) : fields.lat;
      const lng = name === 'lng' ? Number(e.target.value) : fields.lng;

      const data = {};
      if (integrations.mapIntegration.value === "open_street") {
        data.type = "default"
      }
      if (integrations.mapIntegration.value === "google") {
        data.type = integrations.mapIntegration.value;
        data.apiKey = integrations.mapIntegration?.credentials?.apiKey;
      }

      return getAddressFromLatLng(lat, lng, address => {
        setDeliveryAddressFromMap(address);
        setFields({
          ...fields,
          deliveryAddress: {
            ...address,
            location: {
              lat,
              lng
            }
          },
          lat,
          lng
        });

        if (mapIntegration.value === 'google') {
          map.setCenter(address.location);
        }
        if (mapIntegration.value === 'open_street') {
          map.getView().setCenter(fromLonLat([address.location.lng, address.location.lat]));
        }
      }, data);
    }

    if (name === 'deliveryAddress') {
      const data = {};
      if (integrations.mapIntegration.value === "open_street") {
        data.type = "default"
      }
      if (integrations.mapIntegration.value === "google") {
        data.type = integrations.mapIntegration.value;
        data.apiKey = integrations.mapIntegration?.credentials?.apiKey;
      }

      return getLatLngFromAddress(e.label, location => {
        const deliveryAddress = {
          ...e,
          location
        };

        setGeocodingAddresses(geocodingAddresses.filter(geocodingAddress => geocodingAddress.value === deliveryAddress.value));
        if (deliveryAddress.value !== deliveryAddressFromMap?.value) {
          setDeliveryAddressFromMap(null);
        }

        if (mapIntegration.value === 'google') {
          map.setCenter(location);
          map.setZoom(16);
        }
        if (mapIntegration.value === 'open_street') {
          map.getView().setCenter(fromLonLat([location.lng, location.lat]));
          map.getView().setZoom(16);
        }

        return setFields({
          ...fields,
          [name]: deliveryAddress,
          lat: location.lat,
          lng: location.lng,
          apartment: e?.apartment || '',
          entrance: e?.entrance || '',
          floor: e?.floor || ''
        });
      }, data);
    }

    if (
      name === 'pickupAddress' ||
      name === 'paymentMethod'
    ) {
      return setFields({
        ...fields,
        [name]: e.label.toLowerCase() === "cash" ? {value: e.value, label: t("components.create_order.cash")} : e
      })
    }

    if (
      name === 'startDate' ||
      name === 'endDate'
    ) {
      return setFields({
        ...fields,
        [name]: e
      })
    }

    if (
      e.target.type === 'text' ||
      e.target.type === 'number' ||
      e.target.type === 'radio' ||
      e.target.type === 'textarea'
    ) {
      return setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [t, fields, map, geocodingAddresses, deliveryAddressFromMap, mapIntegration.value, integrations]);
  const onSubmit = useCallback(e => {
    e.preventDefault();

    if (pending) {
      return;
    }

    const data = {
      amount: Number(fields.amount),
      paymentMethodId: fields.paymentMethod?.value,
      deliveryType: fields.deliveryType,
      startDate: moment(fields.startDate).utc().format(),
      endDate: moment(fields.endDate).utc().format(),
      companyAddressId: fields.pickupAddress?.value
    };

    if (fields.note) {
      data.note = fields.note
    }
    if (fields.extraId) {
      data.extraId = fields.extraId
    }

    if (selectedCustomer) {
      data.customerId = selectedCustomer.id;

      const address = selectedCustomer.addresses.find(address => (
        address.deliveryAddress === fields.deliveryAddress?.label &&
        address.apartment === fields.apartment &&
        address.entrance === fields.entrance &&
        address.floor === fields.floor
      ));
      if (address) {
        data.customerAddressId = address.id
      } else {
        data.customerAddress = {
          countryCode: user.address.countryCode,
          deliveryAddress: fields.deliveryAddress?.label,
          apartment: fields.apartment || null,
          entrance: fields.entrance || null,
          floor: fields.floor || null,
          location: fields.deliveryAddress?.location
        };

        for (const key in data.customerAddress) {
          if (data.customerAddress[key] === null) {
            delete data.customerAddress[key];
          }
        }
      }
    }
    if (!selectedCustomer) {
      data.customer = {
        phoneNumber: customerPhoneNumberSearch,
        address: {
          countryCode: user.address.countryCode,
          deliveryAddress: fields.deliveryAddress?.label,
          apartment: fields.apartment || null,
          entrance: fields.entrance || null,
          floor: fields.floor || null,
          location: fields.deliveryAddress?.location
        }
      };

      if (fields.firstName) {
        data.customer.firstName = fields.firstName;
      }
      if (fields.lastName) {
        data.customer.lastName = fields.lastName;
      }
      if (fields.email) {
        data.customer.email = fields.email;
      }

      for (const key in data.customer.address) {
        if (data.customer.address[key] === null) {
          delete data.customer.address[key];
        }
      }
    }
    if (assignedAgent) {
      data.agentId = assignedAgent.value;
      setAssignedPartner(null);
    }
    if (assignedPartner) {
      data.partnerId = assignedPartner.value;
      setAssignedAgent(null);
    }
    if (fields.amount === "") {
      return setError(`${t("fillInAmount")}`)
    }

    setPending(true);

    api
      .post('/orders', data)
      .then(() => {
        if (!data.agentId) {
          setActiveTabOrders('unassigned');
        }
        if (data.agentId) {
          setActiveTabOrders('assigned');
        }
        setPending(false);
        onClose();
      })
      .catch(err => {
        setPending(false);
        console.log(errToString(err));
        setError(errToString(err));
      })
  }, [t, pending, fields, assignedPartner, assignedAgent, selectedCustomer, user.address.countryCode, onClose, setActiveTabOrders, customerPhoneNumberSearch]);

  useEffect(() => {
    const from = moment(startDate).utc().format();
    const to = moment(endDate).utc().format();
    const companyAddressIds = fields.pickupAddress?.value ? `&companyAddressIds=${fields.pickupAddress.value}` : '';

    api
      .get(`/employees/agents?from=${from}&to=${to}${companyAddressIds}`)
      .then(({ data }) => {
        setAgentsData(data.results);
      })
      .catch(err => console.log(errToString(err)));
  }, [startDate, endDate, fields.pickupAddress]);
  useEffect(() => {
    const from = moment(startDate).utc().format();
    const to = moment(endDate).utc().format();
    const companyAddressIds = fields.pickupAddress?.value ? `&companyAddressIds=${fields.pickupAddress.value}` : '';

    api
      .get(`/partners?from=${from}&to=${to}${companyAddressIds}`)
      .then(({ data }) => {
        setPartnersData(data.results);
      })
      .catch(err => console.log(errToString(err)));
  }, [startDate, endDate, fields.pickupAddress]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // useEffect(() => {
  //   if (localStorage.getItem("deliveryAddressSearchCity")) {
  //     return setSelectedCity(localStorage.getItem("deliveryAddressSearchCity"))
  //   } else {
  //     return setSelectedCity("")
  //   }
  // }, []);

  return user && (
    <>
      {createPortal(
        <>
          <div className="create_order">
            <div className="create_order_in">
              <Form
                title={t("components.create_order.createOrder")}
                error={error && t(`errors.${error?.toLowerCase()}`)}
                onClose={onClose}
                onSubmit={onSubmit}
              >
                <div className="create_order_in_body">
                  <div className="create_order_in_body_wrapper">
                    <FormRow>
                      <FormCol>
                        <label htmlFor="phoneNumber">
                          <span>*</span>
                          {t("components.create_order.customerPhoneNumber")}
                          {selectedCustomer && customers.length && (
                            <span className="create_order_customer_status create_order_customer_status--exist">
                              {t("components.create_order.exists")}
                            </span>
                          )}
                          {!selectedCustomer && !customers.length && customerPhoneNumberSearch && (
                            <span className="create_order_customer_status create_order_customer_status--new">
                              {t("components.create_order.new")}
                            </span>
                          )}
                        </label>
                        <DropDownPhoneNumber
                          placeholder={t("components.create_order.phoneNumber")}
                          options={customers.map((customer, index) => ({
                            option: { value: customer.phoneNumber, label: customer.phoneNumber, customer },
                            el: (() => {
                              let normPhoneNumber = customerPhoneNumberSearch.charAt(0) === '0' ? customerPhoneNumberSearch.slice(1, customerPhoneNumberSearch.length) : customerPhoneNumberSearch
                              const phoneNumberArr = [...customer.phoneNumber];

                              let highlightedStartIndex = customer.phoneNumber.indexOf(normPhoneNumber);
                              let highlightedEndIndex = -1;

                              if (highlightedStartIndex !== -1) {
                                highlightedEndIndex = highlightedStartIndex + normPhoneNumber.length - 1;
                              }

                              return (
                                <li
                                  className='create_order_custom_dropdown_menu'
                                  key={index}
                                >
                                  <AiFillPhone /> {phoneNumberArr.map((char, index) => {
                                  const isHighlighted = index >= highlightedStartIndex && index <= highlightedEndIndex;

                                  return (
                                    <span
                                      key={index}
                                      className={cn({ 'drop_down_phone_number__highlighted': isHighlighted })}
                                    >{char}</span>
                                  );
                                })}
                                </li>
                              );
                            })()
                          }))}
                          value={customerPhoneNumber}
                          loading={customerPhoneNumberLoading}
                          onReset={onResetCustomerPhoneNumber}
                          onSearch={onSearchCustomerPhoneNumber}
                          onChange={onChangeCustomerPhoneNumber}
                          onFocus={closeAllDropDowns}
                          onInit={methods => setPhoneNumberMethods(methods)}
                        />
                      </FormCol>
                      <FormCol>
                        <label htmlFor="extraId">
                          {t("components.create_order.orderId")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <AiOutlineNumber />
                          </div>
                          <input
                            name="extraId"
                            maxLength={30}
                            minLength={0}
                            type="text"
                            className="create_order_wrapper_input"
                            placeholder={t("components.create_order.orderId")}
                            autoComplete="off"
                            value={fields.extraId}
                            onChange={e => onChangeField(e, 'extraId')}
                            disabled={false}
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <label htmlFor="firstName">
                          {t("components.create_order.firstName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <MdPersonOutline />
                          </div>
                          <input
                            name="firstName"
                            className="create_order_wrapper_input"
                            type="text"
                            placeholder={t("components.create_order.firstName")}
                            autoComplete="off"
                            value={fields.firstName}
                            onChange={e => onChangeField(e, 'firstName')}
                            disabled={!!selectedCustomer}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label htmlFor="lastName">
                          {t("components.create_order.lastName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <MdPersonOutline />
                          </div>
                          <input
                            name="lastName"
                            className="create_order_wrapper_input"
                            type="text"
                            placeholder={t("components.create_order.lastName")}
                            autoComplete="off"
                            value={fields.lastName}
                            onChange={e => onChangeField(e, 'lastName')}
                            disabled={!!selectedCustomer}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label htmlFor="email">
                          {t("components.create_order.email")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <AiOutlineMail />
                          </div>
                          <input
                            name="email"
                            type="text"
                            className="create_order_wrapper_input"
                            placeholder={t("components.create_order.email")}
                            autoComplete="off"
                            value={fields.email}
                            onChange={e => onChangeField(e, 'email')}
                            disabled={!!selectedCustomer}
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                  </div>
                  <div className="create_order_in_body_wrapper">
                    <FormRow>
                      {/*{integrations.mapIntegration.value === "open_street" && (*/}
                      {/*  <FormCol>*/}
                      {/*    <label>*/}
                      {/*      {t("components.create_order.city")}*/}
                      {/*      {!selectedCity && (*/}
                      {/*        <div*/}
                      {/*          className="create_order_show_coords"*/}
                      {/*          onClick={() => {*/}
                      {/*            setSelectedCity(cityValue);*/}
                      {/*            localStorage.setItem("deliveryAddressSearchCity", cityValue);*/}
                      {/*          }}*/}
                      {/*        >*/}
                      {/*          <FaSave />*/}
                      {/*      </div>*/}
                      {/*      )}*/}
                      {/*      {selectedCity && (*/}
                      {/*        <div*/}
                      {/*          className="create_order_show_coords"*/}
                      {/*          onClick={() => {*/}
                      {/*            localStorage.removeItem("deliveryAddressSearchCity");*/}
                      {/*            setSelectedCity("");*/}
                      {/*            setCityValue("");*/}
                      {/*          }}*/}
                      {/*        >*/}
                      {/*          <MdEdit />*/}
                      {/*      </div>*/}
                      {/*      )}*/}
                      {/*    </label>*/}
                      {/*    {!selectedCity && (*/}
                      {/*      <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>*/}
                      {/*        <div className="create_order_city">*/}
                      {/*          <div className="create_order_wrapper_icon">*/}
                      {/*            <MdOutlineLocationCity />*/}
                      {/*          </div>*/}
                      {/*          <input*/}
                      {/*            type="text"*/}
                      {/*            className="create_order_wrapper_input"*/}
                      {/*            placeholder={t("components.create_order.city")}*/}
                      {/*            autoComplete="off"*/}
                      {/*            value={cityValue}*/}
                      {/*            onChange={e => setCityValue(e.target.value)}*/}
                      {/*          />*/}
                      {/*        </div>*/}
                      {/*      </div>*/}
                      {/*    )}*/}
                      {/*    {selectedCity && (*/}
                      {/*      <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>*/}
                      {/*        <div className="create_order_tag">*/}
                      {/*          <div className="create_order_wrapper_icon">*/}
                      {/*            <MdOutlineLocationCity />*/}
                      {/*          </div>*/}
                      {/*          <input*/}
                      {/*            type="text"*/}
                      {/*            className="create_order_wrapper_input"*/}
                      {/*            placeholder={t("components.create_order.city")}*/}
                      {/*            autoComplete="off"*/}
                      {/*            value={selectedCity}*/}
                      {/*            disabled={true}*/}
                      {/*          />*/}
                      {/*        </div>*/}
                      {/*      </div>*/}
                      {/*    )}*/}
                      {/*  </FormCol>*/}
                      {/*)}*/}
                      <FormCol>
                        <label>
                          <span>*</span>
                          {t("components.create_order.deliveryAddress")}
                        </label>
                        <div className="create_order_delivery_address_col">
                          <DropDown
                            hasMarkerIcon={true}
                            placeholder={t("components.create_order.deliveryAddress")}
                            loading={deliveryAddressLoading}
                            options={deliveryAddresses.map((deliveryAddresses, index) => ({
                              option: deliveryAddresses,
                              el: (
                                <li
                                  className='create_order_custom_dropdown_menu'
                                  key={`${index} ${deliveryAddresses}`}
                                >
                                  <FaMapMarkerAlt />
                                  <h3 className="create_order_custom_dropdown_menu_txt">{deliveryAddresses.label}</h3>
                                </li>
                              )
                            }))}
                            value={fields.deliveryAddress}
                            onSearch={onChangeDeliveryAddress}
                            onChange={e => onChangeField(e, 'deliveryAddress')}
                            onReset={onResetDeliveryAddress}
                            onFocus={closeAllDropDowns}
                            onInit={methods => setDeliveryAddressMethods(methods)}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label>
                          {t("components.create_order.apartment")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <BiHomeSmile />
                          </div>
                          <input
                            type="text"
                            className="create_order_wrapper_input"
                            placeholder={t("components.create_order.apartment")}
                            autoComplete="off"
                            value={fields.apartment}
                            onChange={e => onChangeField(e, 'apartment')}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label>
                          {t("components.create_order.entrance")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <BsDoorClosed />
                          </div>
                          <input
                            type="text"
                            className="create_order_wrapper_input"
                            autoComplete="off"
                            placeholder={t("components.create_order.entrance")}
                            value={fields.entrance}
                            onChange={e => onChangeField(e, 'entrance')}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label>
                          {t("components.create_order.floor")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <TbStairs />
                          </div>
                          <input
                            type="text"
                            className="create_order_wrapper_input"
                            placeholder={t("components.create_order.floor")}
                            autoComplete="off"
                            value={fields.floor}
                            onChange={e => onChangeField(e, 'floor')}
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                  </div>
                  {windowWidth > 600 && (
                    <>
                      <div className="create_order_in_body_wrapper">
                        <FormRow>
                          <FormCol>
                            <label htmlFor="amount">
                              <span>*</span>
                              {t("components.create_order.amount")}
                            </label>
                            <div className="create_order_wrapper">
                              <div className="create_order_wrapper_icon">
                                <GrMoney />
                              </div>
                              <input
                                className="create_order_wrapper_input"
                                name="amount"
                                type="text"
                                placeholder={t("components.create_order.amount")}
                                autoComplete="off"
                                value={fields.amount}
                                onChange={e => onChangeField(e, 'amount')}
                              />
                            </div>
                          </FormCol>
                          <FormCol>
                            <label htmlFor="paymentMethod">
                              <span>*</span>
                              {t("components.create_order.paymentMethod")}
                              <div
                                className="create_order_in_body_wrapper_icon"
                                data-tooltip-id={`tooltip-create-payment`}
                                onClick={() => {
                                  navigate("/settings/payment-methods");
                                  onClose();
                                }}
                              >
                                <BsPlusCircleFill />
                              </div>
                            </label>
                            <DropDown
                              hasPaymentMethodIcon={true}
                              placeholder={t("components.create_order.paymentMethod")}
                              options={paymentMethodsOptions.map((paymentMethod, index) => ({
                                option: paymentMethod,
                                el: (
                                  <li
                                    className='create_order_custom_dropdown_menu'
                                    key={index}
                                  >
                                    {paymentMethod.label.toLowerCase() === "cash" ? <AiFillDollarCircle /> : <AiFillCreditCard />}
                                    <h3 className="create_order_custom_dropdown_menu_txt">
                                      {paymentMethod.label.toLowerCase() === "cash" ? t("components.create_order.cash") : paymentMethod.label}
                                    </h3>
                                  </li>
                                )
                              }))}
                              value={fields.paymentMethod}
                              position="top"
                              onChange={e => onChangeField(e, 'paymentMethod')}
                              onFocus={closeAllDropDowns}
                              onInit={methods => setPaymentMethodsMethods(methods)}
                            />
                          </FormCol>
                          <FormCol>
                            <label htmlFor="pickupAddress">
                              <span>*</span>
                              {t("components.create_order.pickUpAddress")}
                              <div
                                className="create_order_in_body_wrapper_icon"
                                data-tooltip-id={`tooltip-create-branch`}
                                onClick={() => {
                                  navigate("/branches");
                                  onClose();
                                }}
                              >
                                <BsPlusCircleFill />
                              </div>
                            </label>
                            <DropDown
                              showClose={false}
                              hasMarkerIcon={true}
                              position={"top"}
                              placeholder={t("components.create_order.pickUpAddress")}
                              options={pickupAddresses.map((pickupAddresses, index) => ({
                                option: pickupAddresses,
                                el: (
                                  <li
                                    className='create_order_custom_dropdown_menu'
                                    key={index}
                                  >
                                    <FaMapMarkerAlt />
                                    <h3 className="create_order_custom_dropdown_menu_txt">
                                      {pickupAddresses.label}
                                    </h3>
                                  </li>
                                )
                              }))}
                              value={fields.pickupAddress}
                              disabled={userCompanyBranches.list.length === 1}
                              onChange={e => {
                                onChangeField(e, 'pickupAddress');
                                setAssignedAgent(null);
                                setAssignedPartner(null);
                              }}
                              onFocus={closeAllDropDowns}
                              onInit={(methods) => setPickupAddressMethods(methods)}
                            />
                          </FormCol>
                        </FormRow>
                      </div>
                      <div className="create_order_in_body_wrapper">
                        <FormRow>
                          <FormCol>
                            <label htmlFor="startDate">
                              <span>*</span>
                              {t("components.create_order.startDate")}
                            </label>
                            <DateTimePicker
                              className="create_order_date_time_picker"
                              placeholder="DD/MM/YYYY HH:MM"
                              enableTimePicker={true}
                              position="top"
                              minDate={new Date()}
                              value={fields.startDate}
                              onChange={e => onChangeField(e, 'startDate')}
                            />
                          </FormCol>
                          <FormCol>
                            <label htmlFor="endDate">
                              <span>*</span>
                              {t("components.create_order.endDate")}
                            </label>
                            <DateTimePicker
                              className="create_order_date_time_picker"
                              placeholder="DD/MM/YYYY HH:MM"
                              enableTimePicker={true}
                              position="top"
                              minDate={fields.startDate}
                              value={fields.endDate}
                              onChange={e => onChangeField(e, 'endDate')}
                            />
                          </FormCol>
                        </FormRow>
                        <FormRow>
                          <FormCol>
                            <label htmlFor="note">
                              {t("components.create_order.description")}
                            </label>
                            <input
                              name="note"
                              type={'text'}
                              placeholder={t("components.create_order.description")}
                              autoComplete="off"
                              value={fields.note}
                              onChange={e => onChangeField(e, 'note')}
                            />
                          </FormCol>
                        </FormRow>
                      </div>
                    </>
                  )}
                  {windowWidth <= 600 && (
                    <>
                      <div className="create_order_in_body_wrapper">
                        <FormRow>
                          <FormCol>
                            <label htmlFor="amount">
                              <span>*</span>
                              {t("components.create_order.amount")}
                            </label>
                            <div className="create_order_wrapper">
                              <div className="create_order_wrapper_icon">
                                <GrMoney />
                              </div>
                              <input
                                className="create_order_wrapper_input"
                                name="amount"
                                type="text"
                                placeholder={t("components.create_order.amount")}
                                autoComplete="off"
                                value={fields.amount}
                                onChange={e => onChangeField(e, 'amount')}
                              />
                            </div>
                          </FormCol>
                          <FormCol>
                            <label htmlFor="paymentMethod">
                              <span>*</span>
                              {t("components.create_order.paymentMethod")}
                              <div
                                className="create_order_in_body_wrapper_icon"
                                data-tooltip-id={`tooltip-create-payment`}
                                onClick={() => {
                                  navigate("/settings/payment-methods");
                                  onClose();
                                }}
                              >
                                <BsPlusCircleFill />
                              </div>
                            </label>
                            <DropDown
                              hasPaymentMethodIcon={true}
                              placeholder={t("components.create_order.paymentMethod")}
                              options={paymentMethodsOptions.map((paymentMethod, index) => ({
                                option: paymentMethod,
                                el: (
                                  <li
                                    className='create_order_custom_dropdown_menu'
                                    key={index}
                                  >
                                    {paymentMethod.label.toLowerCase() === "cash" ? <AiFillDollarCircle /> : <AiFillCreditCard />}
                                    <h3 className='create_order_custom_dropdown_menu_txt'>
                                      {paymentMethod.label.toLowerCase() === "cash" ? t("components.create_order.cash") : paymentMethod.label}
                                    </h3>
                                  </li>
                                )
                              }))}
                              value={fields.paymentMethod}
                              position="top"
                              onChange={e => onChangeField(e, 'paymentMethod')}
                              onFocus={closeAllDropDowns}
                              onInit={methods => setPaymentMethodsMethods(methods)}
                            />
                          </FormCol>
                        </FormRow>
                        <FormRow>
                          <FormCol>
                            <label htmlFor="pickupAddress">
                              <span>*</span>
                              {t("components.create_order.pickUpAddress")}
                              <div
                                className="create_order_in_body_wrapper_icon"
                                data-tooltip-id={`tooltip-create-branch`}
                                onClick={() => {
                                  navigate("/branches");
                                  onClose();
                                }}
                              >
                                <BsPlusCircleFill />
                              </div>
                            </label>
                            <DropDown
                              hasMarkerIcon={true}
                              showClose={false}
                              position="top"
                              placeholder={t("components.create_order.pickUpAddress")}
                              options={pickupAddresses.map((pickupAddresses, index) => ({
                                option: pickupAddresses,
                                el: (
                                  <li
                                    className='create_order_custom_dropdown_menu'
                                    key={index}
                                  >
                                    <FaMapMarkerAlt />
                                    <h3 className='create_order_custom_dropdown_menu_txt'>
                                      {pickupAddresses.label}
                                    </h3>
                                  </li>
                                )
                              }))}
                              value={fields.pickupAddress}
                              disabled={userCompanyBranches.list.length === 1}
                              onChange={e => {
                                onChangeField(e, 'pickupAddress');
                                setAssignedAgent(null);
                                setAssignedPartner(null);
                              }}
                              onFocus={closeAllDropDowns}
                              onInit={methods => setPickupAddressMethods(methods)}
                            />
                          </FormCol>
                        </FormRow>
                      </div>
                      <div className="create_order_in_body_wrapper">
                        <FormRow>
                          <FormCol>
                            <label htmlFor="startDate">
                              <span>*</span>
                              {t("components.create_order.startDate")}
                            </label>
                            <DateTimePicker
                              className="create_order_date_time_picker"
                              placeholder="DD/MM/YYYY HH:MM"
                              enableTimePicker={true}
                              position="top"
                              minDate={new Date()}
                              value={fields.startDate}
                              onChange={e => onChangeField(e, 'startDate')}
                            />
                          </FormCol>
                        </FormRow>
                        <FormRow>
                          <FormCol>
                            <label htmlFor="endDate">
                              <span>*</span>
                              {t("components.create_order.endDate")}
                            </label>
                            <DateTimePicker
                              className="create_order_date_time_picker"
                              placeholder="DD/MM/YYYY HH:MM"
                              enableTimePicker={true}
                              position="top"
                              minDate={fields.startDate}
                              value={fields.endDate}
                              onChange={e => onChangeField(e, 'endDate')}
                            />
                          </FormCol>
                        </FormRow>
                        <FormRow>
                          <FormCol>
                            <label htmlFor="note">
                              {t("components.create_order.description")}
                            </label>
                            <input
                              name="note"
                              type={'text'}
                              placeholder={t("components.create_order.description")}
                              autoComplete="off"
                              value={fields.note}
                              onChange={e => onChangeField(e, 'note')}
                            />
                          </FormCol>
                        </FormRow>
                      </div>
                      <FormRow>
                        <FormCol shouldFit={false}>
                          <div data-tooltip-id={`tooltip-now`}  className="create_order_radio">
                            <label htmlFor="deliveryType" className="create_order_radio_label">
                              {t("components.create_order.now")}
                            </label>
                            <input
                              name="deliveryType"
                              type="radio"
                              value="now"
                              checked={fields.deliveryType === 'now'}
                              onChange={e => onChangeField(e, 'deliveryType')}
                            />
                          </div>
                        </FormCol>
                        <FormCol shouldFit={false}>
                          <div data-tooltip-id={`tooltip-pre`} className="create_order_radio">
                            <label htmlFor="deliveryType" className="create_order_radio_label">
                              {t("components.create_order.pre")}
                            </label>
                            <input
                              name="deliveryType"
                              type="radio"
                              value="pre"
                              checked={fields.deliveryType === 'pre'}
                              onChange={e => onChangeField(e, 'deliveryType')}
                            />
                          </div>
                        </FormCol>
                      </FormRow>
                    </>
                  )}
                </div>
              </Form>
            </div>
            <div className="create_order_map">
              <Map
                type={mapIntegration.value}
                zoom={13}
                center={center}
                setMap={setMap}
                map={map}
                markers={markers}
                isMarkerDraggable={true}
              />
            </div>
            <Tooltip
              id={`tooltip-create-payment`}
              place="top"
              className="tooltipClass"
            >{t("components.create_order.createPayment")}</Tooltip>
            <Tooltip
              id={`tooltip-create-branch`}
              place="top"
              className="tooltipClass"
            >{t("components.create_order.createBranch")}</Tooltip>
          </div>
          <div className="create_order_in_fixed">
            <FormRow>
              {windowWidth > 600 && (
                <>
                  <FormCol shouldFit={false}>
                    <div data-tooltip-id={`tooltip-now`}  className="create_order_radio">
                      <label htmlFor="deliveryType" className="create_order_radio_label">
                        {t("components.create_order.now")}
                      </label>
                      <input
                        name="deliveryType"
                        type="radio"
                        value="now"
                        checked={fields.deliveryType === 'now'}
                        onChange={e => onChangeField(e, 'deliveryType')}
                      />
                    </div>
                  </FormCol>
                  <FormCol shouldFit={false}>
                    <div data-tooltip-id={`tooltip-pre`} className="create_order_radio">
                      <label htmlFor="deliveryType" className="create_order_radio_label">
                        {t("components.create_order.pre")}
                      </label>
                      <input
                        name="deliveryType"
                        type="radio"
                        value="pre"
                        checked={fields.deliveryType === 'pre'}
                        onChange={e => onChangeField(e, 'deliveryType')}
                      />
                    </div>
                  </FormCol>
                </>
              )}
              {(!assignedAgent && !assignedPartner) && (
                <div
                  className={cn("create_order_in_fixed_assignBtn", {"create_order_in_fixed_assignBtn--active" : showAssignForm})}
                  onClick={() => setShowAssignForm(!showAssignForm)}
                >
                  {t(`pages.dashboard.orders_modal.orders_list.order_item.assignAgent`)}
                </div>
              )}
              {assignedAgent && (
                <div className="create_order_in_fixed_assignBtn create_order_in_fixed_assignBtn--assigned">
                  <p>{assignedAgent.firstName} {assignedAgent.lastName}</p>
                  <span
                    className="create_order_in_fixed_assignBtn--close"
                    onClick={() => {
                      setShowAssignForm(true);
                      setAssignedAgent(null);
                    }}
                  >
                    {t(`general.change`)}
                  </span>
                </div>
              )}
              {assignedPartner && (
                <div className="create_order_in_fixed_assignBtn create_order_in_fixed_assignBtn--assigned">
                  {assignedPartner.name}
                  <span
                    className="create_order_in_fixed_assignBtn--close"
                    onClick={() => {
                      setShowAssignForm(true);
                      setAssignedPartner(null);
                    }}
                  >
                    {t(`general.change`)}
                  </span>
                </div>
              )}
              {windowWidth > 600 && (
                <FormCol>
                  {!pending && (
                    <button
                      className="create_order_submit_btn"
                      onClick={onSubmit}
                    >
                      {t("components.create_order.create")}
                    </button>
                  )}
                  {pending && (
                    <button className="create_order_submit_btn create_order_submit_btn--pending">
                      <Loading />
                    </button>
                  )}
                </FormCol>
              )}
              {windowWidth <= 600 && (
                <div
                  className="update_order_submit_btn--round"
                  onClick={onSubmit}
                >
                  <BsPlus />
                </div>
              )}
            </FormRow>
            <Tooltip
              id={`tooltip-now`}
              place="top"
              className="tooltipClass"
            >{t("components.create_order.nowDescrip")}</Tooltip>
            <Tooltip
              id={`tooltip-pre`}
              place="top"
              className="tooltipClass"
            >{t("components.create_order.preDescrip")}</Tooltip>
          </div>
          {agentsOptions && partnersOptions && (
            <>
              <AssignAgentForm
                show={showAssignForm}
                assignTypeAgents={assignTypeAgents}
                setAssignTypeAgents={setAssignTypeAgents}
                agentsOptions={agentsOptions}
                partnersOptions={partnersOptions}
                pickupAddress={fields.pickupAddress.label}
                setShow={setShowAssignForm}
                setAssignedPartner={setAssignedPartner}
                setAssignedAgent={setAssignedAgent}
                pickupAddressLocation={fields.pickupAddress.location}
              />
              <div
                className={cn("assign_agent_form_overlay", {"assign_agent_form_overlay--show": showAssignForm})}
                onClick={() => setShowAssignForm(false)}
              />
            </>
          )}
        </>,
        document.getElementById('modal')
      )}
    </>
  );
}

CreateOrder.propTypes = {
  mapIntegration: PropTypes.object.isRequired,
  center: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  setActiveTabOrders: PropTypes.func.isRequired,
  selectedBranchId: PropTypes.number,
};

export default memo(CreateOrder);
