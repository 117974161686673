import React, {memo, useState, useMemo, useEffect, useCallback, useContext} from "react";
import api from "../../utils/api";
import "./index.scss";
import PageHeader from "../../components/layout/page-header";
import Table from '../../components/table';
import Container from "../../components/layout/container";
import PopUp from "../../components/layout/popup";
import CreateCustomer from "./create-customer";
import Pagination from "../../components/ui/pagination";
import {LIMITS} from "../../constants/drop-down-options";
import Search from "../../components/search";
import AuthContext from "../../context/auth/auth-context";
import {useTranslation} from 'react-i18next';
import {errToString, removeDuplicates} from "../../utils";
import {BsPlus} from "react-icons/bs";
import {BiExport} from "react-icons/bi";
import {Tooltip} from "react-tooltip";

const SEARCH_FIELDS_CUSTOMERS = [
  {label: `phoneNumber`, value: 'phoneNumber'},
  {label: `email`, value: 'email'},
  {label: `address`, value: 'address'},
];

function Customers() {
  const {user} = useContext(AuthContext);
  const {t} = useTranslation();

  const [createCustomer, setCreateCustomer] = useState(false);
  const [customersData, setCustomersData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [currentPage, setCurrentPage] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const searchFieldsCustomers = useMemo(() => SEARCH_FIELDS_CUSTOMERS.map(option => ({
    ...option,
    label: t(`pages.customers.${option.label}`)
  })), [t]);
  const customerTableTitles = useMemo(() => [
    {
      name: t("pages.customers.customer")
    },
    {
      name: t("pages.customers.phoneNumber")
    },
    {
      name: t("pages.customers.email"),
    },
    {
      name: t("pages.customers.addresses"),
      size: 300
    },
    {
      name: t("pages.customers.totalOrders")
    },
  ], [t]);

  const onCloseCreateCustomer = useCallback(e => {
    e?.preventDefault();
    setCreateCustomer(false);
  }, []);
  const onChangeLimit = useCallback(option => {
    if (option.value === limit.value) {
      return;
    }

    setLimit(option);
    setOffset(0);
    setLoading(true);
    setCurrentPage(0);
  }, [limit]);
  const onSearch = useCallback(({ search, searchField }) => {
    setLoading(true);

    api
      .get(`/customers?offset=${offset}&limit=${limit.value}${search && searchField ? `&search=${search}&searchField=${searchField}` : ''}`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setCustomersData(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(errToString(err));
        setLoading(false);
      });
  }, [offset, limit]);
  const onExport = useCallback(() => {
    return window.open(`/export/customers`, "_blank")
  }, []);


  useEffect(() => {
    setLoading(true);

    api
      .get(`/customers?offset=${offset}&limit=${limit.value}`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setCustomersData(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(errToString(err));
        setLoading(false);
      });
  }, [offset, limit]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container>
      <div className="customers">
        <PageHeader>
          <p>{t("pages.customers.customers")}</p>
        </PageHeader>
        <div className="customers_in_actions">
          <Search
            placeholder={t("pages.customers.searchCustomer")}
            options={searchFieldsCustomers}
            onSearch={onSearch}
          />
          {user.role.permissions.includes("create_customer") && (
            <button
              onClick={() => setCreateCustomer(true)}
            >
              {windowWidth > 600 ? t("components.form.createCustomer") : <BsPlus />}
            </button>
          )}
          <div
            className="billing_history_in_actions_export"
            data-tooltip-id={`tooltip-export-invoices`}
            onClick={onExport}
          >
            <BiExport />
            <Tooltip
              id={`tooltip-export-invoices`}
              place="top"
              className="tooltipClass"
            >{t("pages.dashboard.orders.exportOrders")}</Tooltip>
          </div>
          {createCustomer && (
            <PopUp>
              <CreateCustomer
                onClose={onCloseCreateCustomer}
                customers={customersData}
                setCustomers={setCustomersData}
              />
            </PopUp>
          )}
        </div>
        <div className="customers_in">
          <Table
            route={user.role.permissions.includes("see_customer_details") ? "customers" : ""}
            titles={customerTableTitles}
            hiddenFields={['id']}
            loading={loading}
            rows={customersData.map(customer => ({
              id: customer.id,
              customer:
                (customer.firstName !== null && customer.lastName !== null) ?
                  <p>{`${customer.firstName} ${customer.lastName}`}</p> :
                  <p>-</p>,
              phoneNumber: (
                <p>{customer.phoneNumber}</p>
              ),
              email: customer.email ? (
                <p>{customer.email}</p>
              ) : <p>-</p>,
              address: removeDuplicates(customer.addresses, "deliveryAddress").map((address, index) => (
                <ul key={index} className="customers_in_list">
                  <li className="customers_in_list_item">{address.deliveryAddress}</li>
                </ul>
              )),
              totalOrders: customer.orders ? (
                <p>{ customer.orders.length}</p>
              ): <p>0</p>,
            })) || []}
          />
        </div>
      </div>
      {!loading && !!pagesCount && (
        <Pagination
          pagesCount={pagesCount}
          setOffset={setOffset}
          limit={limit}
          limitPlaceholder={limit.label}
          limits={LIMITS}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onChange={onChangeLimit}
        />
      )}
    </Container>
  );
}

export default memo(Customers);
