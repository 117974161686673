import "./index.scss";
import {memo, useCallback, useMemo, useState, useEffect, useContext} from "react";
import PropTypes from 'prop-types';
import OrderItem from './order-item';
import api from '../../../../utils/api';
import Loading from '../../../../components/ui/loading';
import NoData from '../../../../components/ui/no-data';
import moment from 'moment/moment';
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ContentLoading from "../../../../components/ui/loading/content-loading";
import {createArrFromLength, errToString} from "../../../../utils";
import cn from "classnames";
import UserCompanyBranchesContext from "../../../../context/user-company-branches/user-company-branches-context";

function OrderList({
 selectedBranchId,
 activeTab,
 orders,
 setOrders,
 onSelectOrder,
 startDate,
 endDate
}) {
    const {hash} = useLocation();
    const {t} = useTranslation();
    const userCompanyBranches = useContext(UserCompanyBranchesContext);
    const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
    const branchIds = userCompanyBranches?.list.map(({ id }) => id);
    const companyAddressIds = selectedBranchId === -1 && branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : selectedBranchId !== -1 ? `&companyAddressIds=${selectedBranchId}` : '';
    const shouldShowLoadMoreBtn = useMemo(() => orders?.list?.length < orders?.count, [orders]);
    const onLoadMore = useCallback(() => {
      setIsLoadMoreLoading(true);

      const from = moment(startDate).utc().format();
      const to = moment(endDate).utc().format();

      api
        .get(`/orders?offset=${orders.offset}&limit=${orders.limit}&state=${activeTab}&from=${from}&to=${to}${companyAddressIds}`)
        .then(({ data }) => {
          setOrders({
            loading: false,
            pagesCount: data.pagesCount,
            count: data.count,
            limit: orders.limit,
            offset: orders.offset + orders.limit,
            list: [
              ...orders.list,
              ...data.results
            ]
          });
          setIsLoadMoreLoading(false);
        })
        .catch(err => console.log(errToString(err)));
    }, [setOrders, activeTab, orders, startDate, endDate, companyAddressIds]);
    const onScrollBottom = useCallback(e => {
      const scrollTop =  e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      const offsetHeight = e.target.offsetHeight;
      const calculatedScrollTop = scrollHeight - offsetHeight - 20;

      if (shouldShowLoadMoreBtn && !orders.loading && calculatedScrollTop < scrollTop) {
        onLoadMore();
      }
    }, [onLoadMore, orders.loading, shouldShowLoadMoreBtn])

    useEffect(() => {
      if (hash) {
        document.querySelector(hash)?.scrollIntoView();
      }
    }, [hash]);

    return (
      <>
        <ul
          className={cn("order_list", {"order_list--animate" : !orders.loading})} onScroll={onScrollBottom}
        >
          {!!orders.list?.length && orders.list.map(order => (
            <OrderItem
              key={order.id}
              className="order_list__item"
              orders={orders.list}
              setOrders={setOrders}
              order={order}
              startDate={startDate}
              endDate={endDate}
              onSelectOrder={() => onSelectOrder(order)}
            />
          ))}
          {orders.list && !orders.list.length && (
            <NoData />
          )}
          {orders.loading && (
            <div className="order_list__loading">
              {createArrFromLength(3).map((val, index) => (
                <ContentLoading type="card" key={index} />
              ))}
            </div>
          )}
        </ul>
        {(shouldShowLoadMoreBtn && !isLoadMoreLoading) && (
          <button
            className="order_list__load_more_btn"
            onClick={onLoadMore}
          >{t("pages.dashboard.orders_modal.orders_list.loadMore")}</button>
        )}
        {isLoadMoreLoading && (
          <div className="order_list__load_more_loading">
            <Loading/>
          </div>
        )}
      </>
    );
}

OrderList.propTypes = {
  activeTab: PropTypes.string,
  orders: PropTypes.object.isRequired,
  setOrders: PropTypes.func.isRequired,
  onSelectOrder: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  selectedBranchId: PropTypes.number.isRequired
};

export default memo(OrderList)
