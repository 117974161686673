import "../index.scss";
import 'react-calendar/dist/Calendar.css';
import {memo, useState, useRef, useEffect, useMemo, useCallback, useContext} from "react";
import { BsCalendarCheck } from "react-icons/bs";
import Calendar from "react-calendar";
import moment from "moment";
import PropTypes from 'prop-types';
import cn from 'classnames';
import {createPortal} from 'react-dom';
import DropDown from '../../drop-down';
import Overlay from '../../overlay';
import {TIME_RANGE} from '../../../../constants/drop-down-options';
import {removeDuplicates} from '../../../../utils';
import PreferencesContext from "../../../../context/preferences/preferences-context";
import {useTranslation} from "react-i18next";

function DateTimePicker({
  className = '',
  placeholder = '',
  minDate,
  maxDate,
  position = 'bottom',
  disabled,
  enableTimePicker = false,
  enableRangePicker = false,
  value,
  onChange
}) {

  const {list} = useContext(PreferencesContext);
  const {t} = useTranslation();
  const ref = useRef();
  const popupRef = useRef();
  const [popupTop, setPopupTop] = useState('-9999px');
  const [popupLeft, setPopupLeft] = useState('-9999px');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const maxHeight = (() => {
    if (popupRef.current?.offsetHeight) {
      return popupRef.current?.offsetHeight - 52;
    }

    return null;
  })();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [date, setDate] = useState(value);
  const [timeOption, setTimeOption] = useState({
    label: `${value.getHours()}:${value.getMinutes() < 10 ? `0${value.getMinutes()}` : value.getMinutes()}`,
    value: { h: value.getHours(), m: value.getMinutes() }
  });
  const timeRange = useMemo(() => {
    if (moment(date).format('DD') === moment(new Date()).format('DD')) {
      let timeOptions = TIME_RANGE.filter(range => {

        const currentDate = new Date();
        const rangeDate = new Date();
        rangeDate.setHours(range.value.h);
        rangeDate.setMinutes(range.value.m);

        return currentDate < rangeDate;
      });
      timeOptions = [
        timeOption,
        ...timeOptions
      ];
      timeOptions = removeDuplicates(timeOptions, 'label');
      timeOptions.sort((a, b) => {
        if(a.label < b.label) return -1
        if(a.label > b.label) return 1
        return 0
      });
      return timeOptions
    }
    return TIME_RANGE
  }, [timeOption, date]);

  const onSave = useCallback(() => {
    const newDate = date;
    date.setHours(timeOption.value.h);
    date.setMinutes(timeOption.value.m);
    onChange(newDate);
    setIsCalendarOpen(false);
  }, [onChange, timeOption, date]);

  useEffect(() => {
    if (ref.current && popupRef.current) {
      setPopupLeft(ref.current?.getBoundingClientRect().left);

      if (position === 'top') {
        setPopupTop(ref.current?.getBoundingClientRect().top - popupRef.current?.getBoundingClientRect().height - 5);
      }
      if (position === 'bottom') {
        setPopupTop(ref.current?.getBoundingClientRect().top + ref.current?.getBoundingClientRect().height + 5);
      }
    }
  }, [ref, popupRef, isCalendarOpen, position]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={cn('date_time_picker', className, { 'date_time_picker--disabled': disabled })}
    >
      <div className="date_time_picker_in">
          <label htmlFor="dateTimePicker">
            <BsCalendarCheck/>
          </label>
          <input
            autoComplete="off"
            id="dateTimePicker"
            type="text"
            value={value ? moment(value).format(`${list.date_format.value.format}, ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`) : placeholder}
            readOnly={true}
            placeholder={placeholder}
            onClick={e => {
              e.stopPropagation();
              setIsCalendarOpen(!isCalendarOpen);
            }}
          />
      </div>
      {isCalendarOpen && createPortal(
        <div
          ref={popupRef}
          className="date_time_picker_wrapper"
          style={{
            top: popupTop,
            left: windowWidth > 700 ? popupLeft + 50 : popupLeft,
            maxWidth: windowWidth > 700 ? ref.current?.offsetWidth - 100 : ref.current?.offsetWidth
        }}
        >
          {enableTimePicker && (
            <>
              <div className="date_time_picker_wrapper_calendar">
                <Calendar
                  showNavigation={true}
                  selectRange={enableRangePicker}
                  view="month"
                  minDate={minDate}
                  maxDate={maxDate}
                  value={date}
                  onChange={date => setDate(date)}
                />
              </div>
              <div className="date_time_picker_wrapper_time">
                <div className="date_time_picker_wrapper_time_single">
                  <DropDown
                    position="top"
                    placeholder="HH:MM"
                    options={timeRange}
                    label="time"
                    maxHeight={maxHeight}
                    value={timeOption}
                    onChange={option => setTimeOption(option)}
                    className="dashboard_container_main_wrapper_calendar_menu"
                  />
                  <button
                    className="date_time_picker_wrapper_btn"
                    onClick={onSave}
                  >{t("components.ui.calendar.save")}</button>
                </div>
              </div>
            </>
          )}
        </div>,
        document.getElementById('modal')
      )}
      {isCalendarOpen && (
        <Overlay
          isTransparent={true}
          onClick={() => {
            setIsCalendarOpen(false);
            setTimeOption({
              label: `${value.getHours()}:${value.getMinutes() < 10 ? `0${value.getMinutes()}` : value.getMinutes()}`,
              value: { h: value.getHours(), m: value.getMinutes() }
            })
            return setDate(value)
          }}
        />
      )}
    </div>
  );
}

DateTimePicker.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  endDate: PropTypes.object,
  position: PropTypes.string,
  disabled: PropTypes.bool,
  enableTimePicker: PropTypes.bool,
  enableRangePicker: PropTypes.bool,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default memo(DateTimePicker);
