import {memo, useEffect} from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

function NotFound() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
      navigate('/not-found')
  }, [navigate]);

  return (
    <div className="not_found">
      <div className="not_found__content">
        <h2>{t(`pages.notFound.404`)}</h2>
        <p>{t(`pages.notFound.info`)}</p>
        <button onClick={() => navigate("/")}>{t(`components.navigation.dashboard`)}</button>
      </div>
    </div>
  );
}

export default memo(NotFound);
