import {memo, useState, useCallback, useMemo, useContext} from "react";
import "./index.scss";
import AgentInfo from "./agent-info";
import {useNavigate} from "react-router-dom";
import Tab from "../../../components/ui/menu-tab";
import { BiSearch } from "react-icons/bi";
import { RiCloseCircleFill } from "react-icons/ri";
import AgentHistory from "./agent-history";
import NoData from "../../../components/ui/no-data";
import PropTypes from 'prop-types';
import {FiRefreshCcw} from "react-icons/fi";
import ActionBtn from "../../../components/ui/action-btn";
import api from "../../../utils/api";
import moment from "moment/moment";
import AgentTimeline from './agent-timeline';
import {fromLonLat} from 'ol/proj';
import {useTranslation} from "react-i18next";
import PartnerInfo from "./partner-info";
import cn from "classnames";
import ContentLoading from "../../../components/ui/loading/content-loading";
import {createArrFromLength, errToString} from "../../../utils";
import UserCompanyBranchesContext from "../../../context/user-company-branches/user-company-branches-context";
import {AiOutlinePlusCircle} from "react-icons/ai";

function AgentsModal({
  mapIntegration,
  agentsLoading,
  freeAgents,
  busyAgents,
  inactiveAgents,
  activeTabAgents,
  setActiveTabAgents,
  selectedAgentActiveTab,
  setSelectedAgentActiveTab,
  setSelectedOrder,
  selectedAgent,
  setSelectedAgent,
  setAgentsLoading,
  setInactiveAgents,
  setFreeAgents,
  setBusyAgents,
  startDate,
  endDate,
  map,
  selectedBranchId,
  partners,
  setPartners,
  showCreateAgentBtn,
  setShowCreateAgentForm
}) {
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const searchedAgents = useMemo(() => {
    let data = []

    if (activeTabAgents === 'free') {
      data = [...freeAgents]
    }
    if (activeTabAgents === 'busy') {
      data = [...busyAgents]
    }
    if (activeTabAgents === 'inactive') {
      data = [...inactiveAgents]
    }

    if(!searchValue) {
      return data;
    }

    return data.filter(data => {
      return (
        String(data.firstName?.toLowerCase()).includes(searchValue) ||
        String(data.lastName?.toLowerCase()).includes(searchValue) ||
        String(data.phoneNumber).includes(searchValue) ||
        String(data.username).includes(searchValue) ||
        String(data.id).includes(searchValue)
      )
    });
  }, [activeTabAgents, freeAgents, busyAgents, inactiveAgents, searchValue]);
  const searchedPartners = useMemo(() => {
    let data = []

    if (activeTabAgents === 'partners') {
      data = [...partners]
    }
    if(!searchValue) {
      return data;
    }

    return data.filter(data => {
      return (
        String(data.name?.toLowerCase()).includes(searchValue) ||
        String(data.id).includes(searchValue)
      )
    });
  }, [activeTabAgents, partners, searchValue]);
  const onSelectAgent = useCallback(agent => {
    setSelectedAgent(agent);
    setSelectedAgentActiveTab('details');

    if (!agent.lastLocation) {
      return;
    }

    if (mapIntegration.value === 'google') {
      map.setCenter(agent.lastLocation);
      map.setZoom(15);
    }
    if (mapIntegration.value === 'open_street') {
      map.getView().setCenter(fromLonLat([agent.lastLocation?.lng, agent.lastLocation?.lat]));
      map.getView().setZoom(15);
    }
  }, [setSelectedAgent, setSelectedAgentActiveTab, map, mapIntegration.value]);
  const onDeselectAgent = useCallback(() => {
    setSelectedAgent(null);
    setActiveTabAgents(activeTabAgents);
    setIsOpen(false);

    if (mapIntegration.value === 'google') {
      map.setZoom(12);
    }
    if (mapIntegration.value === 'open_street') {
      map.getView().setZoom(12);
    }
  }, [setSelectedAgent, activeTabAgents, setActiveTabAgents, map, mapIntegration.value]);
  const onRefreshAgents = useCallback((type) => {
    const start = moment(startDate).utc().format();
    const end = moment(endDate).utc().format();
    const branchIds = userCompanyBranches?.list.map(({ id }) => id);
    const companyAddressIds = selectedBranchId === -1 && branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : selectedBranchId !== -1 ? `&companyAddressIds=${selectedBranchId}` : '';

    if (type !== "reorder") {
      setAgentsLoading(true);
    }

    api
      .get(`/employees/agents?from=${start}&to=${end}${companyAddressIds}&ordersSort=position`)
      .then(({ data }) => {
        setFreeAgents(data.results.filter(agent => {
          return (
            agent.isOnline &&
            (
              !agent.orders.filter(order => order.status === 'started' || order.status === 'picked_up' || order.status === 'on_way').length ||
              !agent.orders.length
            )
          );
        }))
        setBusyAgents(data.results.filter(agent => {
          return (
            agent.isOnline &&
            agent.orders.filter(order => order.status === 'started' || order.status === 'picked_up' || order.status === 'on_way').length
          );
        }))
        setInactiveAgents(data.results.filter(agent => !agent.isOnline));
        setAgentsLoading(false);
      })
      .catch((err) => console.log(errToString(err)));
    api
      .get(`/partners?from=${start}&to=${end}${companyAddressIds}`)
      .then(res => {
        setPartners(res.data.results);
      })
      .catch(err => console.log(errToString(err)))
  }, [setPartners, setBusyAgents, setFreeAgents, setInactiveAgents, setAgentsLoading, startDate, endDate, selectedBranchId, userCompanyBranches?.list]);

  return (
    <div className={cn("agents_container", {"agents_container--animate" : isOpen})}>
      <div className="agents_container__search">
        {!selectedAgent && (
          <>
            <input
              id='agentSearch'
              type="text"
              autoComplete="off"
              placeholder={t("pages.dashboard.agents_modal.search")}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
            />
            <label htmlFor="search"><BiSearch/></label>
            <ActionBtn
              icon={<FiRefreshCcw/>}
              onClick={onRefreshAgents}
              theme='refresh'
            />
          </>
        )}
        {selectedAgent && (
          <>
            <Tab
              title={`agent ${selectedAgent.firstName} ${selectedAgent.lastName}`}
              onClick={() => navigate(`/agents/details/${selectedAgent.id}`)}
            />
            <div
              className="agents_container__search_close"
              onClick={onDeselectAgent}
            >
              <RiCloseCircleFill/>
            </div>
          </>
        )}
      </div>
      <div className="agents_container__tab">
        {!selectedAgent && (
          <>
            <Tab
              title={t("pages.dashboard.agents_modal.free")}
              isActive={activeTabAgents === 'free'}
              onClick={() => setActiveTabAgents('free')}
              count={freeAgents.length}
            />
            <Tab
              title={t("pages.dashboard.agents_modal.busy")}
              isActive={activeTabAgents === 'busy'}
              onClick={() => setActiveTabAgents('busy')}
              count={busyAgents.length}
            />
            <Tab
              title={t("pages.dashboard.agents_modal.inactive")}
              isActive={activeTabAgents === 'inactive'}
              onClick={() => setActiveTabAgents('inactive')}
              count={inactiveAgents.length}
            />
            <Tab
              title={t("pages.dashboard.agents_modal.partners")}
              isActive={activeTabAgents === 'partners'}
              onClick={() => setActiveTabAgents('partners')}
              count={partners.length}
            />
          </>
        )}
        {selectedAgent && (
          <>
            <Tab
              title={t("pages.dashboard.agents_modal.details")}
              isActive={selectedAgentActiveTab === 'details'}
              onClick={() => setSelectedAgentActiveTab('details')}
            />
            <Tab
              title={t("pages.dashboard.agents_modal.timeline")}
              isActive={selectedAgentActiveTab === 'timeline'}
              onClick={() => setSelectedAgentActiveTab('timeline')}
            />
          </>
        )}
      </div>
      {!agentsLoading && partners && activeTabAgents === "partners" && (
        <ul>
          {searchedPartners.map((partner, index) => (
            <PartnerInfo
              key={index}
              partner={partner}
              isOpen={isOpen}
            />
          ))}
        </ul>
      )}
      {!agentsLoading && partners && !showCreateAgentBtn && activeTabAgents !== "partners" && (
        <ul>
          {!!searchedAgents.length && !selectedAgent && searchedAgents.map(agent => (
            <AgentInfo
              key={agent.id}
              agent={agent}
              isOpen={isOpen}
              onClick={() => {
                onSelectAgent(agent);
                setIsOpen(true);
              }}
            />
          ))}
          {!selectedAgent && !searchedAgents.length && !searchedPartners.length && (
            <NoData/>
          )}
          {selectedAgentActiveTab === 'details' && selectedAgent && (
            <li className="agents_container__list">
              <AgentHistory
                mapIntegration={mapIntegration}
                selectedAgent={selectedAgent}
                setSelectedAgent={setSelectedAgent}
                setSelectedOrder={setSelectedOrder}
                map={map}
                onRefreshAgents={onRefreshAgents}
              />
            </li>
          )}
          {selectedAgentActiveTab === 'timeline' && selectedAgent && (
            <li className="agents_container__list">
              <AgentTimeline
                mapIntegration={mapIntegration}
                agentId={selectedAgent.id}
                agentSrc={selectedAgent.src}
                orders={selectedAgent.orders}
              />
            </li>
          )}
        </ul>
      )}
      {!agentsLoading && partners && showCreateAgentBtn && activeTabAgents !== "partners" && (
        <ul>
          <button
              className="agents_container__list_btn"
              onClick={() => {
                setShowCreateAgentForm(true);
                setActiveTabAgents("inactive");
              }}>
            <AiOutlinePlusCircle />
            {t("pages.agents.create_agent.createAgent")}
          </button>
        </ul>
      )}
      {!showCreateAgentBtn && agentsLoading && (
        <ul>
          {createArrFromLength(3).map((val, index) => (
            <li className="agents_container__list" key={index}>
              <ContentLoading type="card" />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

AgentsModal.propTypes = {
  mapIntegration: PropTypes.object.isRequired,
  freeAgents: PropTypes.array.isRequired,
  busyAgents: PropTypes.array.isRequired,
  inactiveAgents: PropTypes.array.isRequired,
  selectedAgent: PropTypes.object,
  setSelectedAgent: PropTypes.func.isRequired,
  setAgentsLoading: PropTypes.func.isRequired,
  setFreeAgents: PropTypes.func.isRequired,
  setInactiveAgents: PropTypes.func.isRequired,
  setBusyAgents: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  map: PropTypes.object,
  selectedBranchId: PropTypes.number.isRequired,
  partners: PropTypes.array.isRequired,
  setPartners: PropTypes.func.isRequired
};

export default memo(AgentsModal);
