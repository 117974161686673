import {memo, useMemo} from "react";
import "./index.scss";
import {Tooltip} from "react-tooltip";
import {useTranslation} from "react-i18next";

function AgentMoney({paymentMethod, amount, count, agentId, data = []}) {
    const {t} = useTranslation();

    const otherOrders = useMemo(() => {
      const obj = {};

      for (const order of data) {
        obj[order.paymentMethod.name] = obj[order.paymentMethod.name] || {count: 0, amount: 0, paymentMethod: order.paymentMethod};
        obj[order.paymentMethod.name].count += 1;
        obj[order.paymentMethod.name].amount += order.amount;
      }

      return Object.values(obj);
    }, [data]);

    const tooltipContent = useMemo(() => {

      if (paymentMethod !== t("pages.dashboard.agents_modal.agent_info.other")) {
        return paymentMethod;
      }
      if (paymentMethod === t("pages.dashboard.agents_modal.agent_info.other") && !otherOrders.length) {
        return "n/a";
      }

      return (
        <ul className="agent_money_tooltip">
          {otherOrders.map((item, index) => (
            <li key={index}>
              <span>{item.paymentMethod.name}:</span> {Number(item.amount).toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 0})}
              <span className="agent_money_tooltip_count">{item.count}</span>
            </li>
          ))}
        </ul>
      );
    }, [t, paymentMethod, otherOrders]);

    return (
        <>
          <div
            className="agent_money"
            data-tooltip-id={`tooltip-${paymentMethod}-${agentId}`}
          >
            <span>{paymentMethod}:</span> {Number(amount).toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 0})}
            {!!amount > 0 && (
              <span className="agent_info_money__col_count">{count}</span>
            )}
          </div>
          <Tooltip
            id={`tooltip-${paymentMethod}-${agentId}`}
            place="top"
            className="tooltipClass"
          >{tooltipContent}</Tooltip>
        </>
    );
}

export default memo(AgentMoney)
