import "./index.scss";
import {memo, useCallback, useEffect, useMemo, useState} from "react";
import {createPortal} from 'react-dom';
import Form from "../../../../../components/form";
import FormRow from "../../../../../components/form/form-row";
import FormCol from "../../../../../components/form/form-col";
import PropTypes from "prop-types";
import api from "../../../../../utils/api";
import Loading from "../../../../../components/ui/loading";
import {useTranslation} from "react-i18next";
import cn from "classnames";
import {TbLockAccess} from "react-icons/tb";
import {errToString} from "../../../../../utils";

function CreateRoleForm({onCancel, setRoles, roles}) {

  const [permissions, setPermissions] = useState(null);
  const [pending, setPending] = useState(false);
  const [name, setName] = useState('');
  const agentPermissions = useMemo(() => {
    if (!permissions) {
      return;
    }
    return permissions.filter(({name}) => {
      return (
        name === "create_agent" ||
        name === "update_agent" ||
        name === "delete_agent" ||
        name === "agent_duty" ||
        name === "read_agent" ||
        name === "see_agent_details"
      )
    })
  }, [permissions]);
  const customerPermissions = useMemo(() => {
    if (!permissions) {
      return;
    }

    return permissions.filter(({name}) => {
      return (
        name === "create_customer" ||
        name === "read_customer" ||
        name === "see_customer_details"
      )
    })
  }, [permissions]);
  const managerPermissions = useMemo(() => {
    if (!permissions) {
      return;
    }

    return permissions.filter(({name}) => {
      return (
        name === "create_manager" ||
        name === "read_manager" ||
        name === "update_manager" ||
        name === "delete_manager" ||
        name === "see_manager_details"
      )
    })
  }, [permissions]);
  const orderPermissions = useMemo(() => {
    if (!permissions) {
      return;
    }

    return permissions.filter(({name}) => {
      return (
        name === "create_order" ||
        name === "update_order"
      )
    })
  }, [permissions]);
  const partnerPermissions = useMemo(() => {
    if (!permissions) {
      return;
    }

    return permissions.filter(({name}) => {
      return (
        name === "read_partner" ||
        name === "see_partner_details"
      )
    })
  }, [permissions]);
  const branchPermissions = useMemo(() => {
    if (!permissions) {
      return;
    }

    return permissions.filter(({name}) => {
      return (
        name === "create_branch" ||
        name === "read_branch" ||
        name === "update_branch" ||
        name === "delete_branch"
      )
    })
  }, [permissions]);
  const settingsPermissions = useMemo(() => {
    if (!permissions) {
      return;
    }

    return permissions.filter(({name}) => {
      return (
        name === "update_company_preferences" ||
        name === "update_agent_preferences" ||
        name === "update_notifications" ||
        name === "update_map" ||
        name === "update_roles" ||
        name === "update_partners" ||
        name === "update_salary" ||
        name === "update_integrations" ||
        name === "update_company_subscription" ||
        name === "update_payment_methods" ||
        name === "update_sms"
      )
    })
  }, [permissions]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {t} = useTranslation();
  const [open, setOpen] = useState("");
  const onChange = useCallback(permission => {
    setPermissions(permissions.map(_permission => {
      if (_permission.name === permission.name) {
        return {
          ...permission,
          isActive: !permission.isActive
        };
      }

      return _permission;
    }));
  }, [permissions]);
  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setError(null);

    const newPermissions = [
      ...agentPermissions,
      ...customerPermissions,
      ...managerPermissions,
      ...orderPermissions,
      ...partnerPermissions,
      ...branchPermissions,
      ...settingsPermissions
    ]
      .filter(({isActive}) => isActive)
      .map(({name}) => name)

    const data = {
      name,
      permissions: newPermissions
    };

    setPending(true);

    api
      .post("/roles", data)
      .then((res) => {
        setRoles([
          ...roles,
          res.data
          ])
        onCancel();
        setPending(false);
      })
      .catch(err => {
        setError(errToString(err));
        console.log(errToString(err));
        setPending(false);
      })
  }, [
    roles,
    setRoles,
    agentPermissions,
    customerPermissions,
    managerPermissions,
    orderPermissions,
    partnerPermissions,
    branchPermissions,
    settingsPermissions,
    onCancel,
    name
  ]);

  useEffect(() => {
    api
      .get("/roles/permissions")
      .then((res) => {
        setPermissions(res.data.map(permission => {
          return {
            name: permission,
            isActive: true
          }
        }));
        setLoading(false);
      })
      .catch(err => console.log(errToString(err)))
  }, []);

  return createPortal(
    <div className="create_role_form">
      {!loading && (
        <Form
          title ={`${t("pages.settings.createRole")}`}
          error={error && t(`errors.${error?.toLowerCase()}`)}
          onSubmit={onSubmit}
        >
          <FormRow>
            <FormCol>
              <div className="edit_role_form_input">
                <label >
                  <span>*</span>
                  {t("pages.settings.roleName")}
                </label>
                <input
                  maxLength={50}
                  id="roleName"
                  type="text"
                  placeholder={t("pages.settings.roleName")}
                  autoComplete="off"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>
            </FormCol>
          </FormRow>
          <div className="create_role_form_in">
            <FormRow>
              <FormCol>
                <p
                  onClick={() => setOpen(() => {
                    if (open === "agent_permissions") {
                      return ""
                    }
                    if (open !== "agent_permissions") {
                      return "agent_permissions"
                    }
                  })}
                  className={cn("edit_role_form_wrapper_title", {"edit_role_form_wrapper_title--active" : open === "agent_permissions"})}
                >
                  <TbLockAccess />
                  {t("pages.settings.agentPermissions")}
                </p>
                <ul className={cn("edit_role_form_wrapper edit_role_form_wrapper--close", {"edit_role_form_wrapper--open" : open === "agent_permissions"})}>
                  {agentPermissions.map((permission, index) => (
                    <li key={index}>
                      <p>{t(`pages.settings.${permission.name}`)}</p>
                      <input
                        type="checkbox"
                        onChange={(e) => onChange(permission)}
                        checked={permission.isActive}
                      />
                    </li>
                  ))}
                </ul>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <p
                  onClick={() => setOpen(() => {
                    if (open === "customer_permissions") {
                      return ""
                    }
                    if (open !== "customer_permissions") {
                      return "customer_permissions"
                    }
                  })}
                  className={cn("edit_role_form_wrapper_title", {"edit_role_form_wrapper_title--active" : open === "customer_permissions"})}
                >
                  <TbLockAccess />
                  {t("pages.settings.customerPermissions")}
                </p>
                <ul className={cn("edit_role_form_wrapper edit_role_form_wrapper--close", {"edit_role_form_wrapper--open" : open === "customer_permissions"})}>
                  {customerPermissions.map((permission, index) => (
                    <li key={index}>
                      <p>{t(`pages.settings.${permission.name}`)}</p>
                      <input
                        type="checkbox"
                        onChange={(e) => onChange(permission)}
                        checked={permission.isActive}
                      />
                    </li>
                  ))}
                </ul>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <p
                  onClick={() => setOpen(() => {
                    if (open === "manager_permissions") {
                      return ""
                    }
                    if (open !== "manager_permissions") {
                      return "manager_permissions"
                    }
                  })}
                  className={cn("edit_role_form_wrapper_title", {"edit_role_form_wrapper_title--active" : open === "manager_permissions"})}
                >
                  <TbLockAccess />
                  {t("pages.settings.managerPermissions")}
                </p>
                <ul className={cn("edit_role_form_wrapper edit_role_form_wrapper--close", {"edit_role_form_wrapper--open" : open === "manager_permissions"})}>
                  {managerPermissions.map((permission, index) => (
                    <li key={index}>
                      <p>{t(`pages.settings.${permission.name}`)}</p>
                      <input
                        type="checkbox"
                        onChange={(e) => onChange(permission)}
                        checked={permission.isActive}
                      />
                    </li>
                  ))}
                </ul>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <p
                  onClick={() => setOpen(() => {
                    if (open === "order_permissions") {
                      return ""
                    }
                    if (open !== "order_permissions") {
                      return "order_permissions"
                    }
                  })}
                  className={cn("edit_role_form_wrapper_title", {"edit_role_form_wrapper_title--active" : open === "order_permissions"})}
                >
                  <TbLockAccess />
                  {t("pages.settings.orderPermissions")}
                </p>
                <ul className={cn("edit_role_form_wrapper edit_role_form_wrapper--close", {"edit_role_form_wrapper--open" : open === "order_permissions"})}>
                  {orderPermissions.map((permission, index) => (
                    <li key={index}>
                      <p>{t(`pages.settings.${permission.name}`)}</p>
                      <input
                        type="checkbox"
                        onChange={(e) => onChange(permission)}
                        checked={permission.isActive}
                      />
                    </li>
                  ))}
                </ul>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <p
                  onClick={() => setOpen(() => {
                    if (open === "partner_permissions") {
                      return ""
                    }
                    if (open !== "partner_permissions") {
                      return "partner_permissions"
                    }
                  })}
                  className={cn("edit_role_form_wrapper_title", {"edit_role_form_wrapper_title--active" : open === "partner_permissions"})}
                >
                  <TbLockAccess />
                  {t("pages.settings.partnerPermissions")}
                </p>
                <ul className={cn("edit_role_form_wrapper edit_role_form_wrapper--close", {"edit_role_form_wrapper--open" : open === "partner_permissions"})}>
                  {partnerPermissions.map((permission, index) => (
                    <li key={index}>
                      <p>{t(`pages.settings.${permission.name}`)}</p>
                      <input
                        type="checkbox"
                        onChange={(e) => onChange(permission)}
                        checked={permission.isActive}
                      />
                    </li>
                  ))}
                </ul>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <p
                  onClick={() => setOpen(() => {
                    if (open === "branch_permissions") {
                      return ""
                    }
                    if (open !== "branch_permissions") {
                      return "branch_permissions"
                    }
                  })}
                  className={cn("edit_role_form_wrapper_title", {"edit_role_form_wrapper_title--active" : open === "branch_permissions"})}
                >
                  <TbLockAccess />
                  {t("pages.settings.branchPermissions")}
                </p>
                <ul className={cn("edit_role_form_wrapper edit_role_form_wrapper--close", {"edit_role_form_wrapper--open" : open === "branch_permissions"})}>
                  {branchPermissions.map((permission, index) => (
                    <li key={index}>
                      <p>{t(`pages.settings.${permission.name}`)}</p>
                      <input
                        type="checkbox"
                        onChange={(e) => onChange(permission)}
                        checked={permission.isActive}
                      />
                    </li>
                  ))}
                </ul>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <p
                  onClick={() => setOpen(() => {
                    if (open === "settings_permissions") {
                      return ""
                    }
                    if (open !== "settings_permissions") {
                      return "settings_permissions"
                    }
                  })}
                  className={cn("edit_role_form_wrapper_title", {"edit_role_form_wrapper_title--active" : open === "settings_permissions"})}
                >
                  <TbLockAccess />
                  {t("pages.settings.settingsPermissions")}
                </p>
                <ul className={cn("edit_role_form_wrapper edit_role_form_wrapper--close", {"edit_role_form_wrapper--open" : open === "settings_permissions"})}>
                  {settingsPermissions.map((permission, index) => (
                    <li key={index}>
                      <p>{t(`pages.settings.${permission.name}`)}</p>
                      <input
                        type="checkbox"
                        onChange={(e) => onChange(permission)}
                        checked={permission.isActive}
                      />
                    </li>
                  ))}
                </ul>
              </FormCol>
            </FormRow>
          </div>
          <FormRow>
            <div className="create_role_form_actions">
              <FormCol>
                <button
                  className="create_role_form__cancel_btn"
                  onClick={onCancel}
                >{t("pages.branches.create_branch_form.cancel")}</button>
              </FormCol>
              <FormCol>
                {!pending && (
                  <button className="create_role_form__submit_btn" type="submit">{t("pages.branches.create_branch_form.save")}</button>
                )}
                {pending && (
                  <button className="create_role_form__submit_btn create_role_form__submit_btn--pending">
                    <Loading />
                  </button>
                )}
              </FormCol>
            </div>
          </FormRow>
        </Form>
      )}
      {loading && (
        <Loading/>
      )}
    </div>,
    document.getElementById('modal')
  );
}

CreateRoleForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  roles: PropTypes.array,
  setRoles: PropTypes.func
}

export default memo(CreateRoleForm);
