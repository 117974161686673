import './index.scss';
import {memo, useMemo} from 'react';
import {createPortal} from "react-dom";
import {RiCloseCircleFill} from "react-icons/ri";
import Table from "../../../../components/table";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

function BranchAllUsersPopup({selectedBranch, onCancel, type}) {
  const {t} = useTranslation();
  const branchAllUsersTitles = useMemo(() => [
    {
      name: t("pages.branches.branch_card.branch_all_users_popup.user"),
      size: 300
    },
    {
      name: t("pages.branches.branch_card.branch_all_users_popup.type")
    },
    {
      name: t("pages.branches.branch_card.branch_all_users_popup.username")
    },
    {
      name: t("pages.branches.branch_card.branch_all_users_popup.role")
    }
  ], [t]);

  return createPortal(
    <>
      <div className="branch_all_users_popup">
        <h2 className="branch_all_users_popup__title">
          {`${selectedBranch.name}`} {type === "agent" ? t(`pages.branches.branch_card.branch_all_users_popup.agents`) : t(`pages.branches.branch_card.branch_all_users_popup.managers`)}
        </h2>
        <div
          className="branch_all_users_popup__close"
          onClick={onCancel}
        >
          <RiCloseCircleFill/>
        </div>
        <div className="branch_all_users_popup_in">
          <Table
            titles={branchAllUsersTitles}
            loading={false}
            hiddenFields={['id']}
            rows={selectedBranch.users ? (
              selectedBranch.users.filter(user => user.type === type).map((user, index) => ({
                id: index,
                name: (
                  <div className="table__avatar">
                    <img
                      src={user.src}
                      alt="agent"
                    />
                    <p>{user.firstName} {user.lastName}</p>
                  </div>
                ),
                type: (
                  <p>{user.type}</p>
                ),
                username: (
                  <p>{user.username}</p>
                ),
                role: (
                  <p>{user.role.name}</p>
                )
              })) || []
            ) : []}
          />
        </div>
      </div>
      <div
        className="branch_all_users_popup__overlay"
        onClick={onCancel}
      />
    </>, document.getElementById('modal')
  );
}

BranchAllUsersPopup.propTypes = {
  selectedBranch: PropTypes.object,
  onCancel: PropTypes.func,
  type: PropTypes.string
}

export default memo(BranchAllUsersPopup);
