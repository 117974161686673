import "./index.scss";
import React, {memo, useCallback, useEffect, useMemo, useState} from "react";
import Logo from "../../assets/logo.png";
import {MdOutlineError, MdPersonOutline} from "react-icons/md";
import {RiLockPasswordLine} from "react-icons/ri";
import {errToString} from "../../utils";
import api from "../../utils/api";
import Loading from "../../components/ui/loading";
import Table from "../../components/table";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {LIMITS} from "../../constants/drop-down-options";
import Pagination from "../../components/ui/pagination";
import {getCurrentDateWithoutHours} from "../../utils/date";
import DateTimePicker from "../../components/ui/calendar/date-time-picker-double-calendar";

function Admin() {
  const {t} = useTranslation();
  const [isAuth, setIsAuth] = useState(false);
  const [error, setError] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const dataTableTitles = useMemo(() => [
    {
      name: "Date",
    },
    {
      name: "Company"
    },
    {
      name: "Owner"
    },
    {
      name: "Phone"
    },
    {
      name: "Email"
    },
    {
      name: "Orders",
      size: 100
    },
    {
      name: "Agents",
      size: 100
    },
    {
      name: "Country code",
      size: 150
    }
  ], []);
  const [dateFields, setDateFields] = useState(localStorage.getItem("admin-date") ? JSON.parse(localStorage.getItem("admin-date")) : {
    startDate: getCurrentDateWithoutHours(),
    endDate: getCurrentDateWithoutHours(23, 59, 59),
  });

  const onChangeLimit = useCallback(option => {
    if (option.value === limit.value) {
      return;
    }

    setLimit(option);
    setOffset(0);
    setLoading(true);
    setCurrentPage(0);


  }, [limit]);
  const onLogout = useCallback(() => {
    setPassword("");
    setUsername("");
    setError(null);
    setIsAuth(false);
    localStorage.removeItem("admin-token");
    localStorage.removeItem("admin-date");
    setDateFields({
      startDate: getCurrentDateWithoutHours(),
      endDate: getCurrentDateWithoutHours(23, 59, 59),
    })
  }, []);
  const onSubmit = useCallback((e) => {
    e.preventDefault();

    setLoading(true);

    const authString = `${username}:${password}`;
    const encodedAuthString = btoa(authString);
    const startDate = moment(dateFields.startDate).utc().format();
    let endDate = moment(dateFields.endDate).utc().format();

    if (startDate === endDate) {
      endDate = moment(dateFields.endDate.setDate(dateFields.endDate.getDate() + 1)).utc().format();
    }

    api
      .get(`/admin?offset=${offset}&limit=${limit.value}&from=${startDate}&to=${endDate}`, {
        headers: {
          Authorization: `Basic ${encodedAuthString}`,
        },
      })
      .then(({data}) => {
        setData(data);
        setLoading(false);
        setIsAuth(true);
        setError(null);
        setPagesCount(data.pagesCount);
        localStorage.setItem('admin-token', encodedAuthString);
      })
      .catch(err => {
        setLoading(false);
        setError(errToString(err));
        setIsAuth(false);
      })
  }, [username, password, offset, limit, dateFields]);

  useEffect(() => {
    const token = localStorage.getItem("admin-token");

    if (token) {
      setLoading(true);
      const startDate = moment(dateFields.startDate).utc().format();
      let endDate = moment(dateFields.endDate).utc().format();

      if (startDate === endDate) {
        endDate = moment(dateFields.endDate.setDate(dateFields.endDate.getDate() + 1)).utc().format();
      }

      api
        .get(`/admin?offset=${offset}&limit=${limit.value}&from=${startDate}&to=${endDate}`, {
          headers: {
            Authorization: `Basic ${token}`,
          },
        })
        .then(({data}) => {
          setData(data);
          setLoading(false);
          setIsAuth(true);
          setError(null);
          setPagesCount(data.pagesCount);
        })
        .catch(err => {
          setLoading(false);
          setError(errToString(err));
          setIsAuth(false);
        })
    } else {
      setLoading(false);
      return setIsAuth(false);
    }
  }, [offset, dateFields, limit]);

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <div className="admin">
      {!isAuth && (
        <div className="sign_in">
          <form
            className="new_form"
            onSubmit={onSubmit}
          >
            <img className="new_form_logo" src={Logo} alt="logo"/>
            <h2 className="new_form_title">Sign in</h2>
            <div className="new_form_columns">
              <div className="new_form_column">
                <label htmlFor="username">Login</label>
                <div className="create_order_wrapper">
                  <div className="create_order_wrapper_icon">
                    <MdPersonOutline />
                  </div>
                  <input
                    name="username"
                    type="text"
                    placeholder="Email"
                    className="create_order_wrapper_input"
                    autoComplete="off"
                    value={username}
                    onChange={e => {
                      setUsername(e.target.value);
                      setError(null);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="new_form_columns">
              <div className="new_form_column">
                <label htmlFor="password">Password</label>
                <div className="create_order_wrapper">
                  <div className="create_order_wrapper_icon">
                    <RiLockPasswordLine />
                  </div>
                  <input
                    name="password"
                    type={"password"}
                    placeholder="Password"
                    autoComplete="off"
                    className="create_order_wrapper_input"
                    value={password}
                    onChange={e => {
                      setPassword(e.target.value);
                      setError(null);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="new_form_actions">
              <button style={{marginTop: "20px"}} type={"submit"}>Sign in</button>
              {!error && (
                <span className="new_form_actions_error"/>
              )}
              {error && (
                <p className="new_form_actions_error">
                  <MdOutlineError />
                  {t(`errors.${error?.toLowerCase()}`)}
                </p>
              )}
            </div>
          </form>
        </div>
      )}
      {isAuth && (
        <div className="admin_in">
          <div className="admin_in_header">
            <div className="admin_in_header_logo">
              <img src={Logo} alt="taskroad logo"/>
            </div>
            <div className='admin_in_header_calendar'>
              <DateTimePicker
                className="orders_date_time_picker"
                placeholder="DD/MM/YYYY HH:MM - HH:MM"
                enableTimePicker={true}
                position="bottom"
                theme='orders'
                enableRangePicker={true}
                type={"admin"}
                value={[dateFields.startDate, dateFields.endDate]}
                onChange={e => {
                  localStorage.setItem("admin-date", JSON.stringify(e))
                  setDateFields(e);
                  setOffset(0);
                }}
              />
            </div>
            <button
              className="admin_in_header_top_logout"
              onClick={onLogout}
            >
              Log out
            </button>
          </div>
          <div className="admin_in_content">
            <Table
              titles={dataTableTitles}
              loading={loading}
              hiddenFields={['id']}
              rows={data.results.map(data => ({
                id: data.id,
                createdAt: (
                  <p>{moment(data.createdAt).format("DD/MM/YY HH:mm")}</p>
                ),
                companyName: (
                  <p>{data.name}</p>
                ),
                name: (
                  <p>{data.owner.firstName} {data.owner.lastName}</p>
                ),
                phoneNumber: (
                  <p>{data.owner.phoneNumber}</p>
                ),
                email: (
                  <p>{data.owner.email}</p>
                ),
                orders: (
                  <p>{data.ordersCount}</p>
                ),
                agents: (
                  <p>{data.agentsCount}</p>
                ),
                country: (
                  <p>{data.countryCode}</p>
                )
              })) || []}
            />
          </div>
          {!!pagesCount && (
            <Pagination
              pagesCount={pagesCount}
              setOffset={setOffset}
              limit={limit}
              limitPlaceholder={limit.label}
              limits={LIMITS}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onChange={onChangeLimit}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default memo(Admin);
