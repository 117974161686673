import "./index.scss";
import {memo, useState, useMemo, useCallback, useEffect, useContext} from "react";
import PageHeader from "../../../../components/layout/page-header";
import Table from "../../../../components/table";
import api from "../../../../utils/api";
import Loading from "../../../../components/ui/loading";
import Status from "../../../../components/ui/status";
import DropDown from "../../../../components/ui/drop-down";
import PreferencesContext from "../../../../context/preferences/preferences-context";
import {useTranslation} from "react-i18next";
import SettingsCompanyAgentsAgentCard from "./settings-company-agents-agent-card";
import Logo from "../../../../assets/logo-short.png"
import PaymentMethodsContext from "../../../../context/payment-methods/payment-methods-context";
import {errToString} from "../../../../utils";

const AGENT_INFO_OPTIONS = [
  { label: "fullName", value: 'name' },
  { label: "username", value: 'username' }
];
const DEFAULT_OPTIONS = [
  { label: "yes", value: true },
  { label: "no", value: false }
];
const DISTANCE_FILTER_OPTIONS = [
  { label: "high", value: 5 },
  { label: "low", value: 40 }
];

function SettingsCompanyAgents() {
  const paymentMethods = useContext(PaymentMethodsContext);
  const {list, update} = useContext(PreferencesContext);
  const {t} = useTranslation();

  const [statuses, setStatuses] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const [statusLoading, setStatusLoading] = useState(true);

  const [agentCallTitle, setAgentCallTitle] = useState(list.agent_call_enabled.value.title || " ");
  const [agentCallBody, setAgentCallBody] = useState(list.agent_call_enabled.value.body || " ");


  const [agentInfoOption, setAgentInfoOption] = useState({label: "", value: ""})
  const [agentCallOption, setAgentCallOption] = useState({label: "", value: ""});
  const [distanceFilterOption, setDistanceFilterOption] = useState({label: "", value: ""});

  const statusTitles = useMemo(() => [
    {
      name: t("pages.settings.agentStatuses")
    },
    {
      name: t("pages.settings.isActive"),
      size: 100
    },
  ], [t]);

  const agent_info_options = useMemo(() => AGENT_INFO_OPTIONS.map(option => ({
    ...option,
    label: t(`pages.settings.${option.label}`)
  })), [t]);
  const default_options = useMemo(() => DEFAULT_OPTIONS.map(option => ({
    ...option,
    label: t(`pages.settings.${option.label}`)
  })), [t]);
  const distance_filter_options = useMemo(() => DISTANCE_FILTER_OPTIONS.map(option => ({
    ...option,
    label: t(`pages.settings.${option.label}`)
  })), [t]);

  const onChangeStatus = useCallback((status, index, checked) => {

    api
      .put(`company/order_statuses/${status.id}`, {
        isActive: checked
      })
      .then((res) => {
        setStatuses(statuses.map(obj => {
          if (obj.id === res.data.id) {
            return res.data
          }
          return obj
        }))
      })
      .catch(err => console.log(errToString(err)))
  }, [statuses]);
  const onSubmit = useCallback(() => {
    const promises = [];

    //agent_info
    if (agentInfoOption.value !== list.agent_info.value.field) {
      const agentInfoBody = {
        value: {
          field: agentInfoOption.value,
        }
      };
      promises.push(api.put(`/company/preferences/${list.agent_info.id}`, agentInfoBody));
    }

    //agent_call_enabled
    if (
      agentCallOption.value !== list.agent_call_enabled.isActive ||
      agentCallTitle !== list.agent_call_enabled.value.title ||
      agentCallBody !== list.agent_call_enabled.value.body
    ) {
      const agentCallEnabledBody = {
        isActive: agentCallOption.value,
        value: {
          title: agentCallTitle,
          body: agentCallBody
        }
      };
      promises.push(api.put(`/company/preferences/${list.agent_call_enabled.id}`, agentCallEnabledBody));
    }

    // distance_filter
    if (Number(distanceFilterOption.value) !== Number(list.distance_filter.value.distance)) {
      const distanceFilterBody = {
        value: {
          distance: Number(distanceFilterOption.value),
        }
      };
      promises.push(api.put(`/company/preferences/${list.distance_filter.id}`, distanceFilterBody));
    }

    // agent_pane_money
    if (selectedPaymentMethod) {
      const body = {
        value: {
          paymentMethods: [selectedPaymentMethod]
        }
      };
      promises.push(api.put(`/company/preferences/${list.agent_pane_money.id}`, body));
    }

    Promise
      .all(promises)
      .then((res) => {
        res.forEach(({ data }) => update(data));
        return alert(t("errors.success"));
      })
      .catch(err => {
        alert(errToString(err));
        console.log(errToString(err))
      });
  }, [
    t,
    selectedPaymentMethod,
    update,
    list,
    agentInfoOption,
    agentCallOption,
    agentCallTitle,
    agentCallBody,
    distanceFilterOption
  ]);

  useEffect(() => {
    api
      .get("/company/order_statuses")
      .then((res) => {
        setStatuses(res.data.filter(status => {
          return (
            status.status !== 'unassigned' &&
            status.status !== 'assigned' &&
            status.status !== 'created'
          )
        }));
        setStatusLoading(false);
      })
      .catch((err) => {
        setStatusLoading(false);
        console.log(errToString(err))
      })

    setSelectedPaymentMethod(list.agent_pane_money.value.paymentMethods.filter(paymentMethod => paymentMethod.toLowerCase() !== 'cash')[0]);
  }, [list])
  useEffect(() => {
    if (agent_info_options) {
      setAgentInfoOption(agent_info_options.find(({value}) => value === list.agent_info.value.field))
    }
    if (default_options) {
      setAgentCallOption(default_options.find(({ value }) => value === list.agent_call_enabled.isActive))
    }
    if (distance_filter_options) {
      setDistanceFilterOption(distance_filter_options.find(({ value }) => value === Number(list.distance_filter.value.distance)))
    }
  }, [agent_info_options, default_options, distance_filter_options, list])

  return (
    <div className="settings_company_agents">
      <PageHeader>
        <p>{t("pages.settings.agentPreferences")}</p>
      </PageHeader>
      <div className="settings_company_agents_in">
        {statusLoading && (
          <div className="settings_company_agents_in_loading">
            <Loading/>
          </div>
        )}
        {!statusLoading && (
          <>
            <h2 className="settings_company_agents_in__title">
              {t("pages.settings.agentStatuses")}
            </h2>
            <p className="settings_company_agents_in__descrip">
              {t("pages.settings.agentStatusesDescription")}
            </p>
            <div className="settings_company_agents_in_options">
              <Table
                titles={statusTitles}
                loading={false}
                hiddenFields={['id']}
                rows={statuses?.map((status, index) => ({
                  id: status.id,
                  status: (
                    <Status type="table" status={status.status}/>
                  ),
                  isActivate: (
                    <>
                      {(status.status === ("accepted") || status.status === ("started") || status.status === ("picked_up") || status.status === ("on_way")) && (
                        <div className="settings_company_agents_in_options_switcher">
                          <input
                            type="checkbox"
                            onChange={() => onChangeStatus(status, index, !status.isActive)}
                            checked={status.isActive}
                          />
                        </div>
                      )}
                      {(status.status !== ("accepted") && status.status !== ("started") && status.status !== ("picked_up") && status.status !== ("on_way")) && (
                        <p className="settings_company_agents_in_options_status_default">{t("pages.settings.on")}</p>
                      )}
                    </>
                  )
                })) || []}
              />
            </div>
            <h2 className="settings_company_agents_in__title">
              {t("pages.settings.agentPaneCustomization")}
            </h2>
            <p className="settings_company_agents_in__descrip">
              {t("pages.settings.agentPaneCustomizationDescription")}
            </p>
            <div className="settings_company_agents_in_options">
              <div className="settings_company_agents_in_options_in">
                <div className="settings_company_agents_in_options_drop_down">
                  <DropDown
                    hasPersonIcon={true}
                    placeholder={t("components.search.chooseOptions")}
                    position="top"
                    showClose={false}
                    options={agent_info_options}
                    value={agentInfoOption}
                    onChange={option => {
                      if (option.value === agentInfoOption.value) {
                        return;
                      }
                      setAgentInfoOption(option);
                    }}
                  />
                </div>
                <div className="settings_company_agents_in_options_image">
                  <SettingsCompanyAgentsAgentCard
                    nameOptions={agentInfoOption.value}
                    moneyOptions={selectedPaymentMethod}
                  />
                </div>
              </div>
            </div>
            <h2 className="settings_company_agents_in__title">
              {t("pages.settings.agentPaneMoney")}
            </h2>
            <p className="settings_company_agents_in__descrip">
              {t("pages.settings.agentPaneMoneyDescription")}
            </p>
            <div className="settings_company_agents_in_options">
              <div className="settings_company_agents_in_options_in">
                <div className="settings_company_agents_in_options_list">
                  <div className="settings_company_agents_in_options_checkbox">
                    <label>{t("components.create_order.cash")}</label>
                    <input
                      type="checkbox"
                      onChange={() => {}}
                      checked={true}
                    />
                  </div>
                  {paymentMethods.list
                    .filter((e) => e.name?.toLowerCase() !== "cash")
                    .filter(({deletedAt}) => !deletedAt)
                    .map(paymentMethod => (
                      <div
                        key={paymentMethod.id}
                        className="settings_company_agents_in_options_checkbox"
                      >
                        <label htmlFor={paymentMethod.name}>{paymentMethod.name}</label>
                        <input
                          type="checkbox"
                          onChange={() => setSelectedPaymentMethod(paymentMethod.name)}
                          checked={paymentMethod.name === selectedPaymentMethod}
                        />
                      </div>
                    ))}
                </div>
                <div className="settings_company_agents_in_options_image">
                  <SettingsCompanyAgentsAgentCard
                    nameOptions={agentInfoOption.value}
                    moneyOptions={selectedPaymentMethod}
                  />
                </div>
              </div>
            </div>
            <h2 className="settings_company_agents_in__title">
              {t("pages.settings.agentCallOptions")}
            </h2>
            <p className="settings_company_agents_in__descrip">
              {t("pages.settings.agentCallOptionsDescription")}
            </p>
            <div className="settings_company_agents_in_options">
              <div className="settings_company_agents_in_options_in">
                <div className="settings_company_agents_in_options_drop_down">
                  <DropDown
                    hasBellIcon={true}
                    placeholder={t("components.search.chooseOptions")}
                    position="top"
                    showClose={false}
                    options={default_options}
                    value={agentCallOption}
                    onChange={option => {
                      if (option.value === agentCallOption.value) {
                        return;
                      }
                      setAgentCallOption(option);
                      setAgentCallTitle(list.agent_call_enabled.value.title);
                      setAgentCallBody(list.agent_call_enabled.value.body);
                    }}
                  />
                  {agentCallOption.value && (
                    <>
                      <div className="settings_company_agents_in_options_wrapper">
                        <input
                          type="text"
                          value={agentCallTitle}
                          onChange={e => setAgentCallTitle(e.target.value)}
                        />
                      </div>
                      <div className="settings_company_agents_in_options_wrapper">
                    <textarea
                      value={agentCallBody}
                      onChange={e => setAgentCallBody(e.target.value)}
                    />
                      </div>
                    </>
                  )}
                </div>
                {agentCallOption.value && (
                  <div className="settings_company_agents_in_options_image">
                    <div className="settings_company_agents_in_options_image_msg">
                      <div className="settings_company_agents_in_options_image_msg_header">
                        <div className="settings_company_agents_in_options_image_msg_header_img">
                          <img src={Logo} alt="taskroad driver"/>
                        </div>
                        <p>Taskroad Driver</p>
                        <p>now</p>
                      </div>
                      <h2>{agentCallTitle}</h2>
                      <h3>{agentCallBody}</h3>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <h2 className="settings_company_agents_in__title">
              {t("pages.settings.distanceFilter")}
            </h2>
            <p className="settings_company_agents_in__descrip">
              {t("pages.settings.distanceFilterDescription")}
            </p>
            <div className="settings_company_agents_in_options">
              <div className="settings_company_agents_in_options_in">
                <div className="settings_company_agents_in_options_drop_down">
                  <DropDown
                    hasDistanceIcon={true}
                    placeholder={t("components.search.chooseOptions")}
                    position="top"
                    showClose={false}
                    options={distance_filter_options}
                    value={distanceFilterOption}
                    onChange={option => {
                      if (option.value === distanceFilterOption.value) {
                        return;
                      }
                      setDistanceFilterOption(option);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="settings_company_agents_in_actions">
              <button
                className="create_order_submit_btn"
                onClick={onSubmit}
              >
                {t("pages.branches.create_branch_form.save")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default memo(SettingsCompanyAgents);
