import './index.scss';
import React, {memo, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import Map from '../map';
import Form from '../form';
import api from '../../utils/api';
import FormRow from '../form/form-row';
import FormCol from '../form/form-col';
import DropDown from '../ui/drop-down';
import DateTimePicker from '../ui/calendar/date-time-picker';
import AuthContext from '../../context/auth/auth-context';
import {useDebouncedCallback} from 'use-debounce';
import {getAddressFromLatLng, getLatLngFromAddress, searchAddress} from '../../utils/geocoding';
import {MdPersonOutline} from "react-icons/md";
import {BsDoorClosed, BsPlus, BsTelephone} from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import {AiFillDollarCircle, AiOutlineMail, AiOutlineNumber} from "react-icons/ai";
import { AiFillCreditCard } from "react-icons/ai";
import sound from '../../assets/audio/notification.mp3'
import moment from 'moment/moment';
import {errToString, removeDuplicates} from '../../utils';
import MarkerOngoingSvg from '../../assets/markers/marker_started.svg';
import Loading from '../ui/loading';
import {fromLonLat} from 'ol/proj';
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {BiHomeSmile} from "react-icons/bi";
import {TbStairs} from "react-icons/tb";
import PaymentMethodsContext from "../../context/payment-methods/payment-methods-context";
import UserCompanyBranchesContext from "../../context/user-company-branches/user-company-branches-context";
import {GrMoney} from "react-icons/gr";
import cn from "classnames";
import AssignAgentForm from "../assign-agent-form";
import IntegrationsContext from "../../context/integrations/integrations-context";

function UpdateOrder({
  mapIntegration,
  onClose,
  order,
  setActiveTab,
  activeTab,
  onDeselectOrder,
  center,
  startDate,
  endDate
}) {
  const integrations = useContext(IntegrationsContext);
  const paymentMethods = useContext(PaymentMethodsContext);
  const {user} = useContext(AuthContext);
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const {t} = useTranslation();
  const [orderStatuses, setOrderStatuses] = useState(null);
  const [pending, setPending] = useState(false);
  const [agentsData, setAgentsData] = useState(null);
  const [partnersData, setPartnersData] = useState(null);
  const [assignedAgent, setAssignedAgent] = useState(order.agent ? {
    ...order.agent,
    value: order.agent.id,
    label: `${order.agent.firstName} ${order.agent.lastName}`
  } : null);
  const [assignedPartner, setAssignedPartner] = useState(order.partner ? {
    ...order.partner,
    value: order.partner.id,
    label: order.partner.name
  } : null);
  const [error, setError] = useState(null);
  const [map, setMap] = useState(null);
  const [pickupAddressMethods, setPickupAddressMethods] = useState(null);
  const [paymentMethodsMethods, setPaymentMethodsMethods] = useState(null);
  const [deliveryAddressMethods, setDeliveryAddressMethods] = useState(null);
  const [statusMethods, setStatusMethods] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [fields, setFields] = useState({
    email: order.customer.email || '',
    firstName: order.customer.firstName || '',
    lastName: order.customer.lastName || '',
    extraId: order.extraId || '',
    pickupAddress: {
      value: order.pickupAddress.id,
      label: order.pickupAddress.name,
      location: order.pickupAddress.location,
    },
    lat: order.address.location.lat,
    lng: order.address.location.lng,
    deliveryAddress: {
      value: order.address.id,
      label: order.address.deliveryAddress,
      location: order.address.location
    },
    apartment: order.address.apartment || '',
    entrance: order.address.entrance || '',
    floor: order.address.floor || '',
    amount: order.amount,
    paymentMethod: { value: order.paymentMethod.id, label: order.paymentMethod.name.toLowerCase() === "cash" ? t("components.update_order.cash") : order.paymentMethod.name},
    startDate: new Date(order.startDate),
    endDate: new Date(order.endDate),
    note: order.note || '',
    deliveryType: order.deliveryType
  });
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState(order.customer.phoneNumber);
  const [status, setStatus] = useState({label: t(`statuses.${order.status}`), value: order.status});
  const [orderOptions, setOrderOptions] = useState([]);

  const [deliveryAddressLoading, setDeliveryAddressLoading] = useState(false);

  const [geocodingAddresses, setGeocodingAddresses] = useState([]);
  const [deliveryAddressFromMap, setDeliveryAddressFromMap] = useState(null);

  const [showAssignForm, setShowAssignForm] = useState(false);
  const [assignTypeAgents, setAssignTypeAgents] = useState(true);

  const pickupAddresses = useMemo(() => {
    return userCompanyBranches.list.map(address => ({
      value: address.id,
      label: `${address.name}`,
      location: address.location
    }));
  }, [userCompanyBranches]);
  const existingDeliveryAddresses = useMemo(() => order.customer.addresses.map(address => ({
    value: address.id,
    label: address.deliveryAddress,
    location: address.location,
    apartment: address.apartment,
    entrance: address.entrance,
    floor: address.floor
  })), [order]);
  const deliveryAddresses = useMemo(() => {
    const deliveryAddresses = removeDuplicates([...geocodingAddresses, ...existingDeliveryAddresses], 'value');

    if (deliveryAddressFromMap) {
      deliveryAddresses.unshift(deliveryAddressFromMap);
    }

    return deliveryAddresses;
  }, [existingDeliveryAddresses, geocodingAddresses, deliveryAddressFromMap]);
  const agentsOptions = useMemo(() => {
    if (!agentsData) {
      return [];
    }

    return agentsData
      ?.map(agent => ({
        value: agent?.id,
        label: `${agent?.firstName} ${agent?.lastName}`,
        ...agent
      }))
      .sort((a, b) => b.isOnline - a.isOnline)
  }, [agentsData]);
  const partnersOptions = useMemo(() => {
    if (!partnersData) {
      return [];
    }

    return partnersData
      ?.map(partner => ({
        value: partner?.id,
        label: partner.name,
        ...partner
      }))
  }, [partnersData]);
  const paymentMethodsOptions = useMemo(() => paymentMethods.list.filter(({ deletedAt }) => !deletedAt).map(paymentMethod => ({
    value: paymentMethod.id,
    label: paymentMethod.name
  })), [paymentMethods.list]);
  const markers = useMemo(() => {
    const markers = [];

    const data = {};
    if (integrations.mapIntegration.value === "open_street") {
      data.type = "default"
    }
    if (integrations.mapIntegration.value === "google") {
      data.type = integrations.mapIntegration.value;
      data.apiKey = integrations.mapIntegration?.credentials?.apiKey;
    }

    if (fields.deliveryAddress) {
      markers.push({
        id: fields.deliveryAddress.value,
        type: 'address',
        position: fields.deliveryAddress.location,
        draggable: true,
        icon: MarkerOngoingSvg,
        onDragEnd: e => {
          return getAddressFromLatLng(e.latLng.lat(), e.latLng.lng(), address => {
            setGeocodingAddresses([]);
            setDeliveryAddressFromMap(address);
            setFields({
              ...fields,
              deliveryAddress: address,
              lat: address.location.lat,
              lng: address.location.lng
            });
          }, data);
        }
      });
    }

    return markers;
  }, [fields, integrations]);

  const onChangeDeliveryAddress = useDebouncedCallback(value => {
    setError(null);

    if (!value) {
      return
    }

    setDeliveryAddressLoading(true)

    const data = {};
    if (integrations.mapIntegration.value === "open_street") {
      data.type = "default"
    }
    if (integrations.mapIntegration.value === "google") {
      data.type = integrations.mapIntegration.value;
      data.apiKey = integrations.mapIntegration?.credentials?.apiKey;
    }

    searchAddress(user.address.countryCode, `${value}`, (err, addresses) => {
      if (err) {
        setGeocodingAddresses([]);
        return setDeliveryAddressLoading(false);
      }

      setGeocodingAddresses(addresses);
      setDeliveryAddressLoading(false);
    }, data);
  }, 1000);
  const onResetDeliveryAddress = useCallback(() => {
    setFields({
      ...fields,
      deliveryAddress: null,
      apartment: '',
      entrance: '',
      floor: '',
      lat: '',
      lng: ''
    });
    setGeocodingAddresses([]);
    setDeliveryAddressFromMap(null);

    if (mapIntegration.value === 'google') {
      map.setCenter(center);
      map.setZoom(13);
    }
    if (mapIntegration.value === 'open_street') {
      map.getView().setCenter(fromLonLat([center.lng, center.lat]));
      map.getView().setZoom(13);
    }
  }, [fields, map, center, mapIntegration.value]);
  const onChangeField = useCallback((e, name) => {
    setError(null);

    if (
      name === 'lat' ||
      name === 'lng'
    ) {
      const lat = name === 'lat' ? Number(e.target.value) : fields.lat;
      const lng = name === 'lng' ? Number(e.target.value) : fields.lng;

      const data = {};
      if (integrations.mapIntegration.value === "open_street") {
        data.type = "default"
      }
      if (integrations.mapIntegration.value === "google") {
        data.type = integrations.mapIntegration.value;
        data.apiKey = integrations.mapIntegration?.credentials?.apiKey;
      }

      return getAddressFromLatLng(lat, lng, address => {
        setDeliveryAddressFromMap(address);
        setFields({
          ...fields,
          deliveryAddress: {
            ...address,
            location: {
              lat,
              lng
            }
          },
          lat,
          lng
        });

        if (mapIntegration.value === 'google') {
          map.setCenter(address.location);
        }
        if (mapIntegration.value === 'open_street') {
          map.getView().setCenter(fromLonLat([address.location.lng, address.location.lat]));
        }
      }, data);
    }

    if (name === 'deliveryAddress') {
      const data = {};
      if (integrations.mapIntegration.value === "open_street") {
        data.type = "default"
      }
      if (integrations.mapIntegration.value === "google") {
        data.type = integrations.mapIntegration.value;
        data.apiKey = integrations.mapIntegration?.credentials?.apiKey;
      }

      return getLatLngFromAddress(e.label, location => {
        const deliveryAddress = {
          ...e,
          location
        };

        setGeocodingAddresses(geocodingAddresses.filter(geocodingAddress => geocodingAddress.value === deliveryAddress.value));
        if (deliveryAddress.value !== deliveryAddressFromMap?.value) {
          setDeliveryAddressFromMap(null);
        }

        if (mapIntegration.value === 'google') {
          map.setCenter(location);
          map.setZoom(16);
        }
        if (mapIntegration.value === 'open_street') {
          map.getView().setCenter(fromLonLat([location.lng, location.lat]));
          map.getView().setZoom(16);
        }

        return setFields({
          ...fields,
          [name]: deliveryAddress,
          lat: location.lat,
          lng: location.lng,
          apartment: e?.apartment || '',
          entrance: e?.entrance || '',
          floor: e?.floor || ''
        });
      }, data);
    }

    if (
      name === 'pickupAddress' ||
      name === 'paymentMethod'
    ) {
      return setFields({
        ...fields,
        [name]: e.label.toLowerCase() === "cash" ? {value: e.value, label: t("components.update_order.cash")} : e
      })
    }

    if (
      name === 'startDate' ||
      name === 'endDate'
    ) {
      return setFields({
        ...fields,
        [name]: e
      })
    }

    if (
      e.target.type === 'text' ||
      e.target.type === 'number' ||
      e.target.type === 'radio' ||
      e.target.type === 'textarea'
    ) {
      setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [t, fields, map, geocodingAddresses, deliveryAddressFromMap, mapIntegration.value, integrations]);

  const closeAllDropDowns = useCallback(() => {
    deliveryAddressMethods?.close && deliveryAddressMethods.close();
    pickupAddressMethods?.close && pickupAddressMethods.close();
    paymentMethodsMethods?.close && paymentMethodsMethods.close();
    statusMethods?.close && statusMethods.close();
  }, [deliveryAddressMethods, pickupAddressMethods, paymentMethodsMethods, statusMethods]);
  const onSubmit = useCallback(e => {
    e.preventDefault();

    if (pending) {
      return;
    }

    const data = {
      customerId: order.customer.id,
      companyAddressId: fields.pickupAddress?.value,
      amount: Number(fields.amount),
      paymentMethodId: fields.paymentMethod?.value,
      startDate: moment(fields.startDate).utc().format(),
      endDate: moment(fields.endDate).utc().format(),
      note: fields.note || null,
      deliveryType: fields.deliveryType,
      status: status?.value,
    };
    const audio = new Audio(sound);

    const address = order.customer.addresses.find(address => (
      address.deliveryAddress === fields.deliveryAddress?.label &&
      address.apartment === fields.apartment &&
      address.entrance === fields.entrance &&
      address.floor === fields.floor
    ));
    if (address) {
      data.customerAddressId = address.id
    } else {
      data.customerAddress = {
        countryCode: user.address.countryCode,
        deliveryAddress: fields.deliveryAddress?.label,
        apartment: fields.apartment || null,
        entrance: fields.entrance || null,
        floor: fields.floor || null,
        location: fields.deliveryAddress?.location
      };

      for (const key in data.customerAddress) {
        if (data.customerAddress[key] === null) {
          delete data.customerAddress[key];
        }
      }
    }

    if (assignedAgent) {
      data.agentId = assignedAgent.value;
    }
    if (!assignedAgent && !assignedPartner && order.agent?.id) {
      data.agentId = null;
    }
    if (assignedPartner) {
      data.partnerId = assignedPartner.value;
    }
    if (!assignedAgent && !assignedPartner && order.partner?.id) {
      data.partnerId = null;
    }
    if (order.agent?.id === assignedAgent?.value) {
      delete data.agentId;
    }
    if (order.partner?.id === assignedPartner?.value) {
      delete data.partnerId;
    }
    if (fields.extraId) {
      data.extraId = fields.extraId
    }

    if (setActiveTab && activeTab) {
      setActiveTab(activeTab);
    }

    if (fields.amount === "") {
      return setError(`${t("fillInAmount")}`)
    }

    setPending(true);

    api
      .put(`/orders/${order.id}`, data)
      .then(() => {
        audio.play();
        onClose();
        onDeselectOrder();
        setPending(false);
      })
      .catch(err => {
        setPending(false);
        console.log(errToString(err));
        setError(errToString(err));
      })
  }, [t, pending, activeTab, onDeselectOrder, setActiveTab, order, onClose, assignedPartner, assignedAgent, fields, status, user]);

  useEffect(() => {
    const from = moment(startDate).utc().format();
    const to = moment(endDate).utc().format();
    const companyAddressIds = fields.pickupAddress?.value ? `&companyAddressIds=${fields.pickupAddress.value}` : '';

    api
      .get(`/employees/agents?from=${from}&to=${to}${companyAddressIds}`)
      .then(({ data }) => {
        setAgentsData(data.results);
      })
      .catch(err => console.log(errToString(err)));
  }, [startDate, endDate, fields.pickupAddress]);
  useEffect(() => {
    const from = moment(startDate).utc().format();
    const to = moment(endDate).utc().format();
    const companyAddressIds = fields.pickupAddress?.value ? `&companyAddressIds=${fields.pickupAddress.value}` : '';

    api
      .get(`/partners?from=${from}&to=${to}${companyAddressIds}`)
      .then(({ data }) => {
        setPartnersData(data.results);
      })
      .catch(err => console.log(errToString(err)));
  }, [startDate, endDate, fields.pickupAddress]);
  useEffect(() => {
    api
      .get("/company/order_statuses")
      .then(({data}) => {
        setOrderStatuses(data
          .filter(({ status, isActive }) => status !== 'created' && isActive)
          .map(orderStatus => ({
            ...orderStatus,
            value: orderStatus.status,
            label: t(`statuses.${orderStatus.status}`)
          })));
        })
      .catch(err => console.log(errToString(err)));
  }, [t]);
  useEffect(() => {
    if (!orderStatuses) {
      return;
    }

    if (!assignedAgent && !assignedPartner) {
      setOrderOptions(orderStatuses.filter(status => status.value === 'unassigned'))
      setStatus(orderStatuses.find(({ status }) => status === 'unassigned'));
    }
    if (assignedAgent) {
      setOrderOptions(orderStatuses.filter(status => status.value !== 'unassigned'))
    }
    if (assignedAgent && status?.value === 'unassigned') {
      setStatus(orderStatuses.find(({ status }) => status === 'assigned'))
    }
    if (assignedPartner) {
      setOrderOptions(orderStatuses.filter(status => status.value !== 'unassigned'))
    }
    if (assignedPartner && status?.value === 'unassigned') {
      setStatus(orderStatuses.find(({ status }) => status === 'assigned'))
    }
  }, [orderStatuses, assignedPartner, assignedAgent, status?.value]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // useEffect(() => {
  //   if (localStorage.getItem("deliveryAddressSearchCity")) {
  //     return setSelectedCity(localStorage.getItem("deliveryAddressSearchCity"))
  //   } else {
  //     return setSelectedCity("")
  //   }
  // }, []);

  return (
    <>
      {createPortal(
        <>
          <div className="update_order">
            <div className="update_order_in">
              <Form
                title={`${t("components.update_order.updateOrder")} #${order.externalId}`}
                error={error && t(`errors.${error?.toLowerCase()}`)}
                onSubmit={onSubmit}
                onClose={onClose}
              >
                <div className="update_order_in_body">
                  <div className="create_order_in_body_wrapper">
                    <FormRow>
                      <FormCol>
                        <label htmlFor="phoneNumber">
                          <span>*</span>
                          {t("components.create_order.customerPhoneNumber")}
                        </label>
                        <div className="update_order_wrapper update_order_wrapper--disable">
                          <div className="update_order_wrapper_icon update_order_wrapper_icon--disable">
                            <BsTelephone />
                          </div>
                          <input
                            type="text"
                            className="update_order_wrapper_input"
                            autoComplete="off"
                            placeholder={t("components.update_order.phoneNumber")}
                            value={customerPhoneNumber}
                            onChange={e => setCustomerPhoneNumber(e.target.value)}
                            disabled
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label htmlFor="extraId">
                          {t("components.create_order.orderId")}
                        </label>
                        <div className="update_order_wrapper">
                          <div className="update_order_wrapper_icon">
                            <AiOutlineNumber />
                          </div>
                          <input
                            name="extraId"
                            type="text"
                            maxLength={30}
                            minLength={0}
                            className="create_order_wrapper_input"
                            placeholder={t("components.create_order.orderId")}
                            autoComplete="off"
                            value={fields.extraId}
                            onChange={e => onChangeField(e, 'extraId')}
                            disabled={false}
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <label htmlFor="firstName">
                          {t("components.update_order.firstName")}
                        </label>
                        <div className="update_order_wrapper update_order_wrapper--disable">
                          <div className="update_order_wrapper_icon update_order_wrapper_icon--disable">
                            <MdPersonOutline />
                          </div>
                          <input
                            name="firstName"
                            className="update_order_wrapper_input"
                            type="text"
                            placeholder={t("components.update_order.firstName")}
                            autoComplete="off"
                            value={fields.firstName}
                            onChange={e => onChangeField(e, 'firstName')}
                            disabled
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label htmlFor="lastName">
                          {t("components.update_order.lastName")}
                        </label>
                        <div className="update_order_wrapper update_order_wrapper--disable">
                          <div className="update_order_wrapper_icon update_order_wrapper_icon--disable">
                            <MdPersonOutline />
                          </div>
                          <input
                            name="lastName"
                            className="update_order_wrapper_input"
                            type="text"
                            placeholder={t("components.update_order.lastName")}
                            autoComplete="off"
                            value={fields.lastName}
                            onChange={e => onChangeField(e, 'lastName')}
                            disabled
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label htmlFor="email">
                          {t("components.update_order.email")}
                        </label>
                        <div className="update_order_wrapper update_order_wrapper--disable">
                          <div className="update_order_wrapper_icon update_order_wrapper_icon--disable">
                            <AiOutlineMail />
                          </div>
                          <input
                            name="email"
                            className="update_order_wrapper_input"
                            type="text"
                            placeholder={t("components.update_order.email")}
                            autoComplete="off"
                            value={fields.email}
                            onChange={e => onChangeField(e, 'email')}
                            disabled
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                  </div>
                  <div className="create_order_in_body_wrapper">
                    <FormRow >
                      {/*{integrations.mapIntegration.value === "open_street" && (*/}
                      {/*  <FormCol>*/}
                      {/*    <label>*/}
                      {/*      {t("components.create_order.city")}*/}
                      {/*      {!selectedCity && (*/}
                      {/*        <div*/}
                      {/*          className="create_order_show_coords"*/}
                      {/*          onClick={() => {*/}
                      {/*            setSelectedCity(cityValue);*/}
                      {/*            localStorage.setItem("deliveryAddressSearchCity", cityValue);*/}
                      {/*          }}*/}
                      {/*        >*/}
                      {/*          <FaSave />*/}
                      {/*      </div>*/}
                      {/*      )}*/}
                      {/*      {selectedCity && (*/}
                      {/*        <div*/}
                      {/*          className="create_order_show_coords"*/}
                      {/*          onClick={() => {*/}
                      {/*            localStorage.removeItem("deliveryAddressSearchCity");*/}
                      {/*            setSelectedCity("");*/}
                      {/*            setCityValue("");*/}
                      {/*          }}*/}
                      {/*        >*/}
                      {/*          <MdEdit />*/}
                      {/*      </div>*/}
                      {/*      )}*/}
                      {/*    </label>*/}
                      {/*    {!selectedCity && (*/}
                      {/*      <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>*/}
                      {/*        <div className="create_order_city">*/}
                      {/*          <div className="create_order_wrapper_icon">*/}
                      {/*            <MdOutlineLocationCity />*/}
                      {/*          </div>*/}
                      {/*          <input*/}
                      {/*            type="text"*/}
                      {/*            className="create_order_wrapper_input"*/}
                      {/*            placeholder={t("components.create_order.city")}*/}
                      {/*            autoComplete="off"*/}
                      {/*            value={cityValue}*/}
                      {/*            onChange={e => setCityValue(e.target.value)}*/}
                      {/*          />*/}
                      {/*        </div>*/}
                      {/*      </div>*/}
                      {/*    )}*/}
                      {/*    {selectedCity && (*/}
                      {/*      <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>*/}
                      {/*        <div className="create_order_tag">*/}
                      {/*          <div className="create_order_wrapper_icon">*/}
                      {/*            <MdOutlineLocationCity />*/}
                      {/*          </div>*/}
                      {/*          <input*/}
                      {/*            type="text"*/}
                      {/*            className="create_order_wrapper_input"*/}
                      {/*            placeholder={t("components.create_order.city")}*/}
                      {/*            autoComplete="off"*/}
                      {/*            value={selectedCity}*/}
                      {/*            disabled={true}*/}
                      {/*          />*/}
                      {/*        </div>*/}
                      {/*      </div>*/}
                      {/*    )}*/}
                      {/*  </FormCol>*/}
                      {/*)}*/}
                      <FormCol>
                        <label>
                          <span>*</span>
                          {t("components.update_order.deliveryAddress")}
                        </label>
                        <div className="update_order_delivery_address_col">
                          <DropDown
                            hasMarkerIcon={true}
                            placeholder={t("components.update_order.deliveryAddress")}
                            loading={deliveryAddressLoading}
                            options={deliveryAddresses.map((deliveryAddresses, index) => ({
                              option: deliveryAddresses,
                              el: (
                                <li
                                  className='update_order_custom_dropdown_menu'
                                  key={index}
                                >
                                  <FaMapMarkerAlt />
                                  <h3 className="create_order_custom_dropdown_menu_txt">
                                    {deliveryAddresses.label}
                                  </h3>
                                </li>
                              )
                            }))}
                            value={fields.deliveryAddress}
                            onSearch={onChangeDeliveryAddress}
                            onChange={e => onChangeField(e, 'deliveryAddress')}
                            onReset={onResetDeliveryAddress}
                            onFocus={closeAllDropDowns}
                            onInit={methods => setDeliveryAddressMethods(methods)}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label>
                          {t("components.create_order.apartment")}
                        </label>
                        <div className="update_order_wrapper">
                          <div className="update_order_wrapper_icon">
                            <BiHomeSmile />
                          </div>
                          <input
                            type="text"
                            className="update_order_wrapper_input"
                            placeholder={t("components.update_order.apartment")}
                            autoComplete="off"
                            value={fields.apartment}
                            onChange={e => onChangeField(e, 'apartment')}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label>
                          {t("components.create_order.entrance")}
                        </label>
                        <div className="update_order_wrapper">
                          <div className="update_order_wrapper_icon">
                            <BsDoorClosed />
                          </div>
                          <input
                            type="text"
                            className="update_order_wrapper_input"
                            autoComplete="off"
                            placeholder={t("components.update_order.entrance")}
                            value={fields.entrance}
                            onChange={e => onChangeField(e, 'entrance')}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label>
                          {t("components.create_order.floor")}
                        </label>
                        <div className="update_order_wrapper">
                          <div className="update_order_wrapper_icon">
                            <TbStairs />
                          </div>
                          <input
                            className="update_order_wrapper_input"
                            type="text"
                            placeholder={t("components.update_order.floor")}
                            autoComplete="off"
                            value={fields.floor}
                            onChange={e => onChangeField(e, 'floor')}
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                  </div>
                  <div className="create_order_in_body_wrapper">
                    <FormRow>
                      <FormCol>
                        <label htmlFor="amount">
                          <span>*</span>
                          {t("components.update_order.amount")}
                        </label>
                        <div className="update_order_wrapper">
                          <div className="update_order_wrapper_icon">
                            <GrMoney />
                          </div>
                          <input
                            name="amount"
                            type="text"
                            className="update_order_wrapper_input"
                            placeholder={t("components.update_order.amount")}
                            autoComplete="off"
                            value={fields.amount}
                            onChange={e => onChangeField(e, 'amount')}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label htmlFor="paymentMethod">
                          <span>*</span>
                          {t("components.update_order.paymentMethod")}
                        </label>
                        <DropDown
                          hasPaymentMethodIcon={true}
                          placeholder={t("components.update_order.paymentMethod")}
                          options={paymentMethodsOptions.map((paymentMethod, index) => ({
                            option: paymentMethod,
                            el: (
                              <li
                                className='create_order_custom_dropdown_menu'
                                key={index}
                              >
                                {paymentMethod.label.toLowerCase() === "cash" ? <AiFillDollarCircle /> : <AiFillCreditCard />}
                                <h3 className='create_order_custom_dropdown_menu_txt'>{paymentMethod.label.toLowerCase() === "cash" ? t("components.update_order.cash") : paymentMethod.label}</h3>
                              </li>
                            )
                          }))}
                          value={fields.paymentMethod}
                          position="top"
                          onChange={e => onChangeField(e, 'paymentMethod')}
                          onFocus={closeAllDropDowns}
                          onInit={methods => setPaymentMethodsMethods(methods)}
                        />
                      </FormCol>
                    </FormRow>
                  </div>
                  <div className="create_order_in_body_wrapper">
                    <FormRow>
                      <FormCol>
                        <label htmlFor="pickupAddress">
                          <span>*</span>
                          {t("components.update_order.pickUpAddress")}
                        </label>
                        <DropDown
                          hasMarkerIcon={true}
                          placeholder={t("components.update_order.pickUpAddress")}
                          options={pickupAddresses.map((pickupAddresses, index) => ({
                            option: pickupAddresses,
                            el: (
                              <li
                                className='create_order_custom_dropdown_menu'
                                key={index}
                              >
                                <FaMapMarkerAlt />
                                <h3 className="create_order_custom_dropdown_menu_txt">{pickupAddresses.label}</h3>
                              </li>
                            )
                          }))}
                          value={fields.pickupAddress}
                          disabled={userCompanyBranches.list.length === 1}
                          onChange={e => {
                            onChangeField(e, 'pickupAddress');
                            setAssignedAgent(null);
                            setAssignedPartner(null);
                          }}
                          position={"top"}
                          onFocus={closeAllDropDowns}
                          onInit={methods => setPickupAddressMethods(methods)}
                        />
                      </FormCol>
                      <FormCol>
                        <label htmlFor="status">
                          <span>*</span>
                          {t("components.update_order.status")}
                        </label>
                        <DropDown
                          hasStatusIcon={true}
                          placeholder="Status"
                          options={orderOptions}
                          value={status}
                          position="top"
                          onChange={option => setStatus(option)}
                          onFocus={closeAllDropDowns}
                          onInit={methods => setStatusMethods(methods)}
                        />
                      </FormCol>
                    </FormRow>
                  </div>
                  {windowWidth > 600 && (
                    <div className="create_order_in_body_wrapper">
                      <FormRow>
                        <FormCol>
                          <label htmlFor="startDate">
                            <span>*</span>
                            {t("components.update_order.startDate")}
                          </label>
                          <DateTimePicker
                            className="update_order_date_time_picker"
                            placeholder="DD/MM/YYYY HH:MM"
                            enableTimePicker={true}
                            position="top"
                            minDate={new Date()}
                            value={fields.startDate}
                            onChange={e => onChangeField(e, 'startDate')}
                          />
                        </FormCol>
                        <FormCol>
                          <label htmlFor="endDate">
                            <span>*</span>
                            {t("components.update_order.endDate")}
                          </label>
                          <DateTimePicker
                            className="update_order_date_time_picker"
                            placeholder="DD/MM/YYYY HH:MM"
                            enableTimePicker={true}
                            position="top"
                            minDate={fields.startDate}
                            value={fields.endDate}
                            onChange={e => onChangeField(e, 'endDate')}
                          />
                        </FormCol>
                      </FormRow>
                      <FormRow>
                        <FormCol>
                          <label htmlFor="note">
                            {t("components.update_order.description")}
                          </label>
                          <input
                            name="note"
                            type={"text"}
                            placeholder={t("components.update_order.description")}
                            autoComplete="off"
                            value={fields.note}
                            onChange={e => onChangeField(e, 'note')}
                          />
                        </FormCol>
                      </FormRow>
                    </div>
                  )}
                  {windowWidth <= 600 && (
                    <>
                      <div className="create_order_in_body_wrapper">
                        <FormRow>
                          <FormCol>
                            <label htmlFor="startDate">
                              <span>*</span>
                              {t("components.update_order.startDate")}
                            </label>
                            <DateTimePicker
                              className="update_order_date_time_picker"
                              placeholder="DD/MM/YYYY HH:MM"
                              enableTimePicker={true}
                              position="top"
                              minDate={new Date()}
                              value={fields.startDate}
                              onChange={e => onChangeField(e, 'startDate')}
                            />
                          </FormCol>
                        </FormRow>
                        <FormRow>
                          <FormCol>
                            <label htmlFor="endDate">
                              <span>*</span>
                              {t("components.update_order.endDate")}
                            </label>
                            <DateTimePicker
                              className="update_order_date_time_picker"
                              placeholder="DD/MM/YYYY HH:MM"
                              enableTimePicker={true}
                              position="top"
                              minDate={fields.startDate}
                              value={fields.endDate}
                              onChange={e => onChangeField(e, 'endDate')}
                            />
                          </FormCol>
                        </FormRow>
                        <FormRow>
                          <FormCol>
                            <label htmlFor="note">
                              {t("components.update_order.description")}
                            </label>
                            <input
                              name="note"
                              type={"text"}
                              placeholder={t("components.update_order.description")}
                              autoComplete="off"
                              value={fields.note}
                              onChange={e => onChangeField(e, 'note')}
                            />
                          </FormCol>
                        </FormRow>
                      </div>
                      <FormRow>
                        <FormCol shouldFit={false}>
                          <div data-tooltip-id={`tooltip-now`}  className="update_order_radio">
                            <label htmlFor="deliveryType" className="update_order_radio_label">
                              {t("components.update_order.now")}
                            </label>
                            <input
                              name="deliveryType"
                              type="radio"
                              value="now"
                              checked={fields.deliveryType === 'now'}
                              onChange={e => onChangeField(e, 'deliveryType')}
                            />
                          </div>
                        </FormCol>
                        <FormCol shouldFit={false}>
                          <div data-tooltip-id={`tooltip-pre`} className="update_order_radio">
                            <label htmlFor="deliveryType" className="update_order_radio_label">
                              {t("components.update_order.pre")}
                            </label>
                            <input
                              name="deliveryType"
                              type="radio"
                              value="pre"
                              checked={fields.deliveryType === 'pre'}
                              onChange={e => onChangeField(e, 'deliveryType')}
                            />
                          </div>
                        </FormCol>
                      </FormRow>
                    </>
                  )}
                </div>
              </Form>
            </div>
            <div className="update_order_map">
              <Map
                type={mapIntegration.value}
                zoom={16}
                center={fields?.deliveryAddress?.location || center}
                setMap={setMap}
                map={map}
                markers={markers}
                isMarkerDraggable={true}
              />
            </div>
          </div>
          <div className="update_order_in_fixed">
            <FormRow>
              {windowWidth > 600 && (
                <>
                  <FormCol shouldFit={false}>
                    <div data-tooltip-id={`tooltip-now`}  className="update_order_radio">
                      <label htmlFor="deliveryType" className="update_order_radio_label">
                        {t("components.update_order.now")}
                      </label>
                      <input
                        name="deliveryType"
                        type="radio"
                        value="now"
                        checked={fields.deliveryType === 'now'}
                        onChange={e => onChangeField(e, 'deliveryType')}
                      />
                    </div>
                  </FormCol>
                  <FormCol shouldFit={false}>
                    <div data-tooltip-id={`tooltip-pre`} className="update_order_radio">
                      <label htmlFor="deliveryType" className="update_order_radio_label">
                        {t("components.update_order.pre")}
                      </label>
                      <input
                        name="deliveryType"
                        type="radio"
                        value="pre"
                        checked={fields.deliveryType === 'pre'}
                        onChange={e => onChangeField(e, 'deliveryType')}
                      />
                    </div>
                  </FormCol>
                </>
              )}
              {(!assignedAgent && !assignedPartner) && (
                <div
                  className={cn("create_order_in_fixed_assignBtn", {"create_order_in_fixed_assignBtn--active" : showAssignForm})}
                  onClick={() => setShowAssignForm(!showAssignForm)}
                >
                  {t(`pages.dashboard.orders_modal.orders_list.order_item.assignAgent`)}
                </div>
              )}
              {assignedAgent && (
                <div className="create_order_in_fixed_assignBtn create_order_in_fixed_assignBtn--assigned">
                  <p>{assignedAgent.firstName} {assignedAgent.lastName}</p>
                  <span
                    className="create_order_in_fixed_assignBtn--close"
                    onClick={() => {
                      setShowAssignForm(true);
                      setAssignedAgent(null);
                    }}
                  >
                     {t(`general.change`)}
                  </span>
                </div>
              )}
              {assignedPartner && (
                <div className="create_order_in_fixed_assignBtn create_order_in_fixed_assignBtn--assigned">
                  {assignedPartner.name}
                  <span
                    className="create_order_in_fixed_assignBtn--close"
                    onClick={() => {
                      setShowAssignForm(true);
                      setAssignedPartner(null);
                    }}
                  >
                    {t(`general.change`)}
                  </span>
                </div>
              )}
              {windowWidth > 600 && (
                <FormCol>
                  {!pending && (
                    <button
                      className="update_order_submit_btn"
                      onClick={onSubmit}
                    >
                      {t("components.update_order.update")}
                    </button>
                  )}
                  {pending && (
                    <button className="update_order_submit_btn update_order_submit_btn--pending">
                      <Loading />
                    </button>
                  )}
                </FormCol>
              )}
              {windowWidth <= 600 && (
                <div
                  className="update_order_submit_btn--round"
                  onClick={onSubmit}
                >
                  <BsPlus />
                </div>
              )}
            </FormRow>
            <Tooltip
              id={`tooltip-now`}
              place="top"
              className="tooltipClass"
            >{t("components.create_order.nowDescrip")}</Tooltip>
            <Tooltip
              id={`tooltip-pre`}
              place="top"
              className="tooltipClass"
            >{t("components.create_order.preDescrip")}</Tooltip>
          </div>
          {agentsOptions && partnersOptions && (
            <>
              <AssignAgentForm
                show={showAssignForm}
                assignTypeAgents={assignTypeAgents}
                setAssignTypeAgents={setAssignTypeAgents}
                agentsOptions={agentsOptions}
                partnersOptions={partnersOptions}
                pickupAddress={fields.pickupAddress.label}
                setShow={setShowAssignForm}
                setAssignedPartner={setAssignedPartner}
                setAssignedAgent={setAssignedAgent}
                pickupAddressLocation={fields.pickupAddress.location}
              />
              <div
                className={cn("assign_agent_form_overlay", {"assign_agent_form_overlay--show": showAssignForm})}
                onClick={() => setShowAssignForm(false)}
              />
            </>
          )}
        </>,
        document.getElementById('modal')
      )}
    </>
  );
}

UpdateOrder.propTypes = {
  mapIntegration: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  order: PropTypes.object.isRequired,
  setActiveTab: PropTypes.func,
  activeTab: PropTypes.string,
  onDeselectOrder: PropTypes.func,
  center: PropTypes.object.isRequired
};

export default memo(UpdateOrder);
