import {memo, useCallback, useState} from 'react';
import api from "../../../../utils/api";
import Form from '../../../../components/form'
import FormRow from "../../../../components/form/form-row";
import FormCol from "../../../../components/form/form-col";
import sound from "../../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {RiLockPasswordLine} from "react-icons/ri";
import Loading from "../../../../components/ui/loading";
import {errToString} from "../../../../utils";

function UpdateAccountPassword({setEditPasswordFormOpen, user}) {
  const {t} = useTranslation();
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [fields, setFields] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const onChangeField = useCallback((e, name) => {
    setError(null);

    if (e.target.type === 'password') {
      setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [fields]);
  const onSubmit = useCallback(e => {
    e.preventDefault();

    const data = {
      currentPassword: fields.currentPassword,
      password: fields.newPassword,
      passwordConfirmation: fields.confirmPassword
    };

    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    if (data.newPassword !== data.confirmPassword) {
      return setError(t("passwords_not_match"));
    }

    setPending(true);

    api
      .put(`/auth/change-password`, data)
      .then(() => {
        setPending(false);
        setEditPasswordFormOpen(false);
        const audio = new Audio(sound);
        const info = `${user.username} ${t("pages.agents.update_agent.update_password_agent.passwordUpdateMsg")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch((err) => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      });
  }, [t, fields, user.username, setEditPasswordFormOpen]);

  return (
    <div className="update_agent">
      <div className="update_agent_in">
        {user && (
          <Form
            title={`${t("pages.agents.update_agent.update_password_agent.updatePassword")} ${user.username}`}
            error={error && t(`errors.${error?.toLowerCase()}`)}
            onSubmit={onSubmit}
          >
            <FormRow>
              <FormCol>
                <label htmlFor="currentPassword">
                  <span>*</span>
                  {t("pages.agents.update_agent.update_password_agent.currentPassword")}
                </label>
                <div className="create_order_wrapper">
                  <div className="create_order_wrapper_icon">
                    <RiLockPasswordLine />
                  </div>
                  <input
                    id="currentPassword"
                    name="currentPassword"
                    className="create_order_wrapper_input"
                    type="password"
                    placeholder={t("pages.agents.update_agent.update_password_agent.currentPassword")}
                    autoComplete="off"
                    value={fields.currentPassword}
                    onChange={e => onChangeField(e, 'currentPassword')}
                  />
                </div>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <label htmlFor="newPassword">
                  <span>*</span>
                  {t("pages.agents.update_agent.update_password_agent.newPassword")}
                </label>
                <div className="create_order_wrapper">
                  <div className="create_order_wrapper_icon">
                    <RiLockPasswordLine />
                  </div>
                  <input
                    id="newPassword"
                    name="newPassword"
                    className="create_order_wrapper_input"
                    type="password"
                    placeholder={t("pages.agents.update_agent.update_password_agent.newPassword")}
                    autoComplete="off"
                    value={fields.newPassword}
                    onChange={e => onChangeField(e, 'newPassword')}
                  />
                </div>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <label htmlFor="confirmPassword">
                  <span>*</span>
                  {t("pages.agents.update_agent.update_password_agent.confirmPassword")}
                </label>
                <div className="create_order_wrapper">
                  <div className="create_order_wrapper_icon">
                    <RiLockPasswordLine />
                  </div>
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    className="create_order_wrapper_input"
                    type="password"
                    placeholder={t("pages.agents.update_agent.update_password_agent.confirmPassword")}
                    autoComplete="off"
                    value={fields.confirmPassword}
                    onChange={e => onChangeField(e, 'confirmPassword')}
                  />
                </div>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <a
                  href="!#"
                  className="update_agent__cancel_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setEditPasswordFormOpen(false);
                  }}
                >{t("pages.agents.update_agent.update_password_agent.cancel")}</a>
              </FormCol>
              <FormCol>
                {!pending && (
                  <button type="submit" className="update_agent__submit_btn">
                    {t("pages.agents.update_agent.update_password_agent.updatePasswordBtn")}
                  </button>
                )}
                {pending && (
                  <button className="update_agent__submit_btn update_agent__submit_btn--pending">
                    <Loading />
                  </button>
                )}
              </FormCol>
            </FormRow>
          </Form>
        )}
      </div>
    </div>
  );
}

export default memo(UpdateAccountPassword);
