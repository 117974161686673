import { memo } from "react";
import "./index.scss";
import cn from "classnames";
import PropTypes from "prop-types";

function Burger({
  onClick,
  isBurgerOpen
}) {

  return (
    <div
      className="burger"
      onClick={onClick}
    >
      <div
        className={cn(
          "burger__close",
          { "burger__close--clicked": isBurgerOpen },
          { "burger__close--unclicked": !isBurgerOpen }
        )}
      />
      <div
        className={cn(
          "burger__close",
          { "burger__close--clicked": isBurgerOpen },
          { "burger__close--unclicked": !isBurgerOpen }
        )}
      />
    </div>
  );
}

Burger.propTypes = {
  onClick: PropTypes.func,
  isBurgerOpen: PropTypes.bool
};

export default memo(Burger);
