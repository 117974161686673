import {memo, useCallback, useContext, useEffect, useRef, useState} from "react";
import "./index.scss";
import moment from "moment";
import PopUp from "../../../../../components/layout/popup";
import AssignOrder from "./assign-order";
import PropTypes from 'prop-types';
import StopWatch from "../../../../../components/ui/stop-watch";
import {toPriceWithCurrency} from "../../../../../utils";
import PreferencesContext from "../../../../../context/preferences/preferences-context";
import {useTranslation} from "react-i18next";
import {createPortal} from "react-dom";
import {BsThreeDotsVertical} from "react-icons/bs";
import UpdateAgent from "./update-agent";
import UpdateStatus from "./update-status";
import Overlay from "../../../../../components/ui/overlay";
import cn from "classnames";
import UpdatePartner from "./update-partner";
import AuthContext from "../../../../../context/auth/auth-context";
import {BsPlus} from "react-icons/bs";
import {Tooltip} from "react-tooltip";

// const calcRoute = (
//   from,
//   to,
//   distanceUnit,
//   callback
// ) => {
//   api
//     .get(`/ors?start=${from.lng},${from.lat}&end=${to.lng},${to.lat}`)
//     .then(({ data: { features } }) => {
//       const distanceValue = distanceUnit === 'km' ? (features[0].properties.summary.distance / 1000).toFixed(1) : (features[0].properties.summary.distance / 1000 / 1.6).toFixed(1);
//       const distance = `${distanceValue} ${distanceUnit}`;
//       const duration = `${(features[0].properties.summary.duration / 60).toFixed()}`;
//
//       return callback({
//         distance,
//         duration
//       }, 'OK');
//     })
//     .catch(err => {
//       console.log(err.message)
//
//       return callback({
//         distance: 'n/a',
//         duration: 'n/a'
//       }, 'OK');
//     });
// }

function OrderItem({
 order,
 onSelectOrder,
 className = '',
 startDate,
 endDate
}) {

    const {list} = useContext(PreferencesContext);
    const ref = useRef();
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);
    const [assignAgent, setAssignAgent] = useState(false);
    const [updateMenuIsOpen, setUpdateMenuIsOpen] = useState(false);
    const [editAgentFormIsOpen, setEditAgentFormIsOpen] = useState(false);
    const [editPartnerFormIsOpen, setEditPartnerFormIsOpen] = useState(false);
    const [editStatusFormIsOpen, setEditStatusFormIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // const [route, setRoute] = useState(null);
    // const [eta, setEta] = useState(null);

    const onOpenUpdateMenu = useCallback((e) => {
      e.stopPropagation();
      setUpdateMenuIsOpen(!updateMenuIsOpen);
    }, [updateMenuIsOpen]);

    useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    // useEffect(() => {
    //   if (order.agent && order.pickupAddress) {
    //     if (order.agent.lastLocation && order.pickupAddress.location) {
    //       calcRoute(order.agent.lastLocation, order.pickupAddress.location, list.distance_unit.value.unit, ({ distance, duration }, status) => {
    //         return setRoute({
    //           distance,
    //           duration
    //         });
    //       })
    //     } else {
    //       return setRoute(null);
    //     }
    //   }
    // }, [order, list]);
    //   useEffect(() => {
    //     if (order.agent && order.address) {
    //       if (order.agent.lastLocation && order.address.location) {
    //         calcRoute(order.agent.lastLocation, order.address.location, list.distance_unit.value.unit, ({ duration }, status) => {
    //           return setEta( duration);
    //         })
    //       } else {
    //         return setEta(null);
    //       }
    //     }
    // }, [order, list]);

    return (
      <>
          <li
              id={`order-info-${order.id}`}
              key={order.id}
              className={className}
              onClick={onSelectOrder}
              onMouseOver={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              ref={ref}
          >
            <div className="order_item">
              <div className="order_item_agent">
                {order.agent && (
                  <div className="order_item_agent_in">
                    <div className="order_item_agent_in_img">
                      <img src={order.agent.src} alt="agent"/>
                    </div>
                  </div>
                )}
                {order.partner && (
                  <div className="order_item_agent_in">
                    <div className="order_item_agent_in_img">
                      <img src={order.partner.src} alt="partner"/>
                    </div>
                  </div>
                )}
                {!order.agent && !order.partner && (
                  <div className="order_item_agent_in">
                    <button
                      data-tooltip-id={"tooltip-assign"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setAssignAgent(true);
                      }}
                    >
                      <BsPlus />
                    </button>
                  </div>
                )}
              </div>
              {
                isHovered &&
                (order.agent || order.partner) &&
                (
                  order.status !== "delivered" &&
                  order.status !== "failed" &&
                  order.status !== "canceled"
                ) &&
                list.order_update_enabled.isActive &&
                user.role.permissions.includes("update_order") && (
                  <div
                    className="order_item_update"
                    onClick={onOpenUpdateMenu}
                  >
                    <BsThreeDotsVertical />
                  </div>
                )}
              <div className="order_item_heading">
                <div className="order_item_heading_id">
                  #{order.externalId}
                </div>
                {/*{*/}
                {/*  order.agent &&*/}
                {/*  order.status !== "delivered" &&*/}
                {/*  order.status !== "failed" &&*/}
                {/*  order.status !== "canceled" &&*/}
                {/*  order.status !== "unassigned" &&*/}
                {/*  order.status !== "created" && (*/}
                {/*    <div*/}
                {/*      className="order_item_times_time"*/}
                {/*      data-tooltip-id={"tooltip-eta"}*/}
                {/*    >*/}
                {/*      <span>eta</span>*/}
                {/*      <p>{eta ? eta : "..."} {t("general.min")}</p>*/}
                {/*    </div>*/}
                {/*  )}*/}
                {order.deliveryType === 'pre' && (
                  <div className="order_item_times_time order_item_times_time--pre">
                    <p>{t(`pages.dashboard.orders_modal.orders_list.order_item.${order.deliveryType}`)}</p>
                  </div>
                )}
              </div>
              <div className="order_item_statuses">
              {order.history
                .sort((a, b) => a["id"] - b["id"])
                .filter(_order => _order.id !== order.history[order.history.length - 1].id)
                .map(_order => (
                  <div
                    key={_order.id}
                    className={cn(`order_item_statuses_status order_item_statuses_status--small order_item_statuses_status--${_order.status}`)}
                    data-tooltip-id={`tooltip-${_order.id}-${_order.status}`}
                  >
                    <p>{t(`statuses.${_order.status}`)}</p>
                    {createPortal(
                      <Tooltip
                        id={`tooltip-${_order.id}-${_order.status}`}
                        place="top"
                        className="tooltipClass"
                      >
                        {t(`statuses.${_order.status}`)} {moment(_order.updatedAt).format(`${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}
                      </Tooltip>,
                      document.getElementById('modal')
                    )}
                  </div>
                ))}
              <div className={cn(`order_item_statuses_status order_item_statuses_status--active order_item_statuses_status--${order.history[order.history.length - 1].status}`)}>
                <p>
                  {t(`statuses.${order.history[order.history.length - 1].status}`)} {moment(order.history[order.history.length - 1].updatedAt).format(`${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}
                </p>
              </div>
            </div>
              <div className="order_item_info">
                {order.agent && (
                  <div className="order_item_info_wrapper">
                    <span>{t("pages.dashboard.orders_modal.orders_list.order_item.driver")}:</span>
                    <p>
                      {order.agent.firstName ? `${order.agent.firstName.charAt(0)}. ${order.agent?.lastName}` : order.agent.username}
                    </p>
                    {/*<p*/}
                    {/*  data-tooltip-id={*/}
                    {/*  (order.status !== "delivered" && order.status !== "failed" && order.status !== "canceled" && route) ?*/}
                    {/*    "tooltip-distance" :*/}
                    {/*    `${order.id}:${order.agent.id}`}*/}
                    {/*>*/}
                    {/*  {order.agent.firstName ? `${order.agent.firstName.charAt(0)}. ${order.agent?.lastName}` : order.agent.username}*/}
                    {/*  {*/}
                    {/*    order.status !== "delivered" &&*/}
                    {/*    order.status !== "failed" &&*/}
                    {/*    order.status !== "canceled" && (*/}
                    {/*      route ? `, ${route.distance} ~ ${route.duration} ${t("general.min")}` : ", ..."*/}
                    {/*    )}*/}
                    {/*</p>*/}
                  </div>
                )}
                {order.partner && (
                  <div className="order_item_info_wrapper">
                    <span>{t("pages.dashboard.orders_modal.orders_list.order_item.partner")}:</span>
                    <p>{order.partner.name}</p>
                  </div>
                )}
                <div className="order_item_info_wrapper">
                  <span>{t("pages.dashboard.orders_modal.orders_list.order_item.customer")}:</span>
                  <p>{order.customer.phoneNumber}</p>
                </div>
                <div className="order_item_info_wrapper">
                  <span>{t("components.create_order.pickUpAddress")}:</span>
                  <p>{order.pickupAddress.name}</p>
                </div>
                <div className="order_item_info_wrapper">
                    <span>
                      {t("pages.dashboard.orders_modal.orders_list.order_item.address")}:
                    </span>
                    <p>
                        {order.address.deliveryAddress}
                        {order.address.apartment &&
                        `, ${t("pages.dashboard.orders_modal.orders_list.order_item.apt")}. ${order.address.apartment}`}
                        {order.address.entrance &&
                            `, ${t("pages.dashboard.orders_modal.orders_list.order_item.ent")}. ${order.address.entrance}`}
                        {order.address.floor &&
                            `, ${t("pages.dashboard.orders_modal.orders_list.order_item.flr")}. ${order.address.floor}`}
                    </p>
                </div>
                {order.extraId && (
                  <div className="order_item_info_wrapper">
                    <span>
                      Extra Id:
                    </span>
                    <p>{order.extraId}</p>
                  </div>
                )}
                <div className="order_item_info_wrapper">
                    <span style={{fontWeight: 400}}>
                      {order.paymentMethod.name.toLowerCase() === "cash"
                        ? t("payment.cash") :
                        order.paymentMethod.name
                      }:
                    </span>
                    <p>{toPriceWithCurrency(order.amount, list.currency.value.currency.toUpperCase())}</p>
                </div>
              </div>
              <div className="order_item_times">
                  <div className="order_item_times_time" data-tooltip-id={`tooltip-updatedAt-${order.id}`}>
                      <span>upd</span>
                      <p>
                        {moment(order.history[order.history.length - 1].updatedAt).format(`${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "" : ""}`)}
                      </p>
                  </div>
                  <div className="order_item_times_time" data-tooltip-id={`tooltip-startDate-${order.id}`}>
                      <span>start</span>
                      <p>
                        {moment(order.startDate).format(`${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "" : ""}`)}
                      </p>
                  </div>
                  <div className="order_item_times_time" data-tooltip-id={`tooltip-endDate-${order.id}`}>
                      <span>end</span>
                      <p>
                        {moment(order.endDate).format(`${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "" : ""}`)}
                      </p>
                  </div>
                  {list.order_delay_time.isActive && (
                      <StopWatch order={order} />
                  )}
                  <Tooltip
                    id={`tooltip-updatedAt-${order.id}`}
                    place="top"
                    className="tooltipClass"
                  >{t(`pages.dashboard.orders_modal.orders_list.order_item.updatedAt`)} {moment(order.updatedAt).format(`${list.date_format.value.format} ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}</Tooltip>
                  <Tooltip
                    id={`tooltip-startDate-${order.id}`}
                    place="top"
                    className="tooltipClass"
                  >{t(`pages.dashboard.orders_modal.orders_list.order_item.startDate`)} {moment(order.startDate).format(`${list.date_format.value.format} ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}</Tooltip>
                  <Tooltip
                    id={`tooltip-endDate-${order.id}`}
                    place="top"
                    className="tooltipClass"
                  >{t(`pages.dashboard.orders_modal.orders_list.order_item.endDate`)} {moment(order.endDate).format(`${list.date_format.value.format} ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}</Tooltip>
              </div>
          </div>
          </li>
          {updateMenuIsOpen && createPortal(
            <div
                className="order_item_menu"
                style={{
                  width: ref.current?.offsetWidth / 1.5,
                  top: windowWidth > 1024 ? `${ref.current?.getBoundingClientRect().top + 10}px` : `${ref.current?.getBoundingClientRect().top + 0}px`,
                  left: windowWidth > 1024 ? `${ref.current?.getBoundingClientRect().left + ref.current?.offsetWidth + 20}px` : 10
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <ul>
                    <li
                        onClick={() => {
                          if (order.agent) {
                            setEditAgentFormIsOpen(true);
                          }
                          if (order.partner) {
                            setEditPartnerFormIsOpen(true);
                          }
                          setUpdateMenuIsOpen(false);
                        }}
                    >
                        {order.agent && t("pages.dashboard.orders_modal.orders_list.order_item.editAgent")}
                        {order.partner && t("pages.dashboard.orders_modal.orders_list.order_item.editPartner")}
                    </li>
                    <li
                        onClick={() => {
                          setEditStatusFormIsOpen(true);
                          setUpdateMenuIsOpen(false);
                        }}
                    >
                      {t("pages.dashboard.orders_modal.orders_list.order_item.editStatus")}
                    </li>
                </ul>
            </div>,
            document.getElementById('modal')
          )}
          {updateMenuIsOpen && (
            <Overlay isTransparent={true} onClick={() => setUpdateMenuIsOpen(false)}/>
          )}
          {assignAgent && (
            <PopUp
              onOverlayClick={(e) => {
                e?.preventDefault();
                setAssignAgent(false);
              }}
            >
              <AssignOrder
                order={order}
                startDate={startDate}
                endDate={endDate}
                onClose={(e) => {
                  e?.preventDefault();
                  setAssignAgent(false);
                }}
              />
            </PopUp>
          )}
          {editAgentFormIsOpen && (
            <PopUp
              onOverlayClick={(e) => {
                e?.preventDefault();
                setEditAgentFormIsOpen(false);
              }}
            >
              <UpdateAgent
                onClose={(e) => {
                  e?.preventDefault();
                  setEditAgentFormIsOpen(false);
                }}
                order={order}
                startDate={startDate}
                endDate={endDate}
              />
            </PopUp>
          )}
          {editPartnerFormIsOpen && (
            <PopUp
              onOverlayClick={(e) => {
                e?.preventDefault();
                setEditPartnerFormIsOpen(false);
              }}
            >
              <UpdatePartner
                onClose={(e) => {
                  e?.preventDefault();
                  setEditPartnerFormIsOpen(false);
                }}
                order={order}
                startDate={startDate}
                endDate={endDate}
              />
            </PopUp>
          )}
          {editStatusFormIsOpen && (
            <PopUp
              onOverlayClick={(e) => {
                e?.preventDefault();
                setEditStatusFormIsOpen(false);
              }}
            >
              <UpdateStatus
                onClose={(e) => {
                  e?.preventDefault();
                  setEditStatusFormIsOpen(false);
                }}
                order={order}
              />
            </PopUp>
          )}
          <Tooltip
            id={`tooltip-assign`}
            place="left"
            className="tooltipClass"
          >
            {t(`pages.dashboard.orders_modal.orders_list.order_item.assignAgent`)}
          </Tooltip>
          <Tooltip
            id={`tooltip-eta`}
            place="top"
            className="tooltipClass"
          >
            {t(`pages.dashboard.orders_modal.orders_list.order_item.eta`)}
          </Tooltip>
          {/*<Tooltip*/}
          {/*  id={`tooltip-distance`}*/}
          {/*  place="top"*/}
          {/*  className="tooltipClass"*/}
          {/*>*/}
          {/*  {t("general.fromPickup")} {route ? `${route.distance} ~ ${route.duration} ${t("general.min")}` : ""}{order.agent?.lastActivity ? `, ${moment(order.agent?.lastActivity).fromNow()}` : ""}*/}
          {/*</Tooltip>*/}
      </>
    );
}

OrderItem.propTypes = {
  order: PropTypes.object.isRequired,
  onSelectOrder: PropTypes.func.isRequired,
  className: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

export default memo(OrderItem)
