import "../index.scss";
import 'react-calendar/dist/Calendar.css';
import {memo, useState, useRef, useEffect, useCallback, useContext} from "react";
import { BsFillCalendar2CheckFill } from "react-icons/bs";
import Calendar from "react-calendar";
import moment from "moment";
import PropTypes from 'prop-types';
import cn from 'classnames';
import {createPortal} from 'react-dom';
import DropDown from '../../drop-down';
import Overlay from '../../overlay';
import {TIME_RANGE_HOURS} from '../../../../constants/drop-down-options';
import PreferencesContext from "../../../../context/preferences/preferences-context";
import {useTranslation} from "react-i18next";

function DateTimePicker({
  className = '',
  placeholder = '',
  minDate,
  maxDate,
  position = 'bottom',
  disabled,
  enableTimePicker = false,
  enableRangePicker = false,
  value,
  onChange
}) {
  const {list} = useContext(PreferencesContext);
  const ref = useRef();
  const popupRef = useRef();
  const {t} = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [popupTop, setPopupTop] = useState('-9999px');
  const [popupLeft, setPopupLeft] = useState('-9999px');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const maxHeight = (() => {
    if (popupRef.current?.offsetHeight) {
      return popupRef.current?.offsetHeight - 52;
    }

    return null;
  })();

  const [date, setDate] = useState(value.startDate);
  const [startTimeOption, setStartTimeOption] = useState({
    label: `${value.startDate.getHours()}:${value.startDate.getMinutes() < 10 ? `0${value.startDate.getMinutes()}` : value.startDate.getMinutes()}`,
    value: { h: value.startDate.getHours(), m: value.startDate.getMinutes() }
  });
  const [endTimeOption, setEndTimeOption] = useState({
    label: `${value.endDate.getHours()}:${value.endDate.getMinutes() < 10 ? `0${value.endDate.getMinutes()}` : value.endDate.getMinutes()}`,
    value: { h: value.endDate.getHours(), m: value.endDate.getMinutes(), s: 59}
  });

  const onSave = useCallback(() => {
    const newStartDate = new Date(date);
    newStartDate.setHours(startTimeOption.value.h);
    newStartDate.setMinutes(startTimeOption.value.m);

    const newEndDate = new Date(date);
    newEndDate.setHours(endTimeOption.value.h);
    newEndDate.setMinutes(endTimeOption.value.m);
    newEndDate.setSeconds(59);

    onChange({
      startDate: newStartDate,
      endDate: newEndDate
    });
    setIsCalendarOpen(false);
  }, [onChange, date, startTimeOption, endTimeOption]);

  useEffect(() => {
    if (ref.current && popupRef.current) {
      setPopupLeft(ref.current?.getBoundingClientRect().left);

      if (position === 'top') {
        setPopupTop(ref.current?.getBoundingClientRect().top - popupRef.current?.getBoundingClientRect().height - 5);
      }
      if (position === 'bottom') {
        setPopupTop(ref.current?.getBoundingClientRect().top + ref.current?.getBoundingClientRect().height);
      }
    }
  }, [ref, popupRef, isCalendarOpen, position]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={cn('date_time_picker', className, { 'date_time_picker--disabled': disabled })}
    >
      <div className="date_time_picker_in">
          <input
            autoComplete="off"
            id="dateTimePickerDoubleTime"
            type="text"
            value={`${moment(date).format(`${list.date_format.value.format}`)} ${startTimeOption.label} - ${endTimeOption.label}`}
            readOnly={true}
            placeholder={placeholder}
            onClick={e => {
              e.stopPropagation();
              setIsCalendarOpen(!isCalendarOpen);
            }}
          />
          <label htmlFor="dateTimePickerDoubleTime">
            <BsFillCalendar2CheckFill />
          </label>
      </div>
      {isCalendarOpen && createPortal(
        <div
          ref={popupRef}
          className="date_time_picker_wrapper"
          style={{
            top: popupTop + 5,
            left: windowWidth > 400 ? popupLeft : 50,
            maxWidth: windowWidth > 400 ? 270 : windowWidth - 100
        }}
        >
          <>
            <div className="date_time_picker_wrapper_calendar">
              <Calendar
                showNavigation={true}
                selectRange={enableRangePicker}
                view="month"
                minDate={minDate}
                maxDate={maxDate}
                value={date}
                onChange={date => setDate(date)}
              />
            </div>
            {enableTimePicker && (
              <div className="date_time_picker_wrapper_time">
                <div className="date_time_picker_wrapper_time_double">
                  <DropDown
                    position="top"
                    placeholder="HH:MM"
                    options={TIME_RANGE_HOURS}
                    label="time"
                    maxHeight={maxHeight}
                    value={startTimeOption}
                    onChange={option => setStartTimeOption(option)}
                    className="dashboard_container_main_wrapper_calendar_menu"
                  />
                  <DropDown
                    position="top"
                    placeholder="HH:MM"
                    options={TIME_RANGE_HOURS}
                    label="time"
                    maxHeight={maxHeight}
                    value={endTimeOption}
                    onChange={option => setEndTimeOption(option)}
                    className="dashboard_container_main_wrapper_calendar_menu"
                  />
                </div>
                <button
                  className="date_time_picker_wrapper_btn"
                  onClick={onSave}
                >{t("components.ui.calendar.save")}</button>
              </div>
            )}
          </>
        </div>,
        document.getElementById('modal')
      )}
      {isCalendarOpen && (
        <Overlay
          isTransparent={true}
          onClick={() => {
            setIsCalendarOpen(false);
            setDate(value.startDate);
            setStartTimeOption({
              label: `${value.startDate.getHours()}:${value.startDate.getMinutes() < 10 ? `0${value.startDate.getMinutes()}` : value.startDate.getMinutes()}`,
              value: { h: value.startDate.getHours(), m: value.startDate.getMinutes() }
            });
            setEndTimeOption({
              label: `${value.endDate.getHours()}:${value.endDate.getMinutes() < 10 ? `0${value.endDate.getMinutes()}` : value.endDate.getMinutes()}`,
              value: { h: value.endDate.getHours(), m: value.endDate.getMinutes()}
            })
          }}
        />
      )}
    </div>
  );
}

DateTimePicker.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  endDate: PropTypes.object,
  position: PropTypes.string,
  disabled: PropTypes.bool,
  enableTimePicker: PropTypes.bool,
  enableRangePicker: PropTypes.bool,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default memo(DateTimePicker);
