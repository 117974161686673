import {memo, useCallback, useContext, useEffect, useMemo, useState} from "react";
import "./index.scss";
import Container from "../../components/layout/container";
import {Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";
import AuthContext from "../../context/auth/auth-context";
import {
  SETTINGS_COMPANY_ROUTES,
  SETTINGS_INTEGRATIONS_ROUTES
} from "../../constants/navigation-routes";
import PageHeader from "../../components/layout/page-header";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {setMomentJsLocale} from "../../utils/moment";
import {HiLogin} from "react-icons/hi";

function Settings() {
    const authCtx = useContext(AuthContext);
    const {i18n, t} = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const settingsCompanyRoutes = useMemo(() => {
      return SETTINGS_COMPANY_ROUTES.filter(({id}) => {
        if (id === 'preferences') {
          return authCtx.user.role.permissions.includes('update_company_preferences');
        }
        if (id === 'agents') {
          return authCtx.user.role.permissions.includes('update_agent_preferences');
        }
        if (id === 'notifications') {
          return authCtx.user.role.permissions.includes('update_notifications');
        }
        if (id === 'payment-methods') {
          return authCtx.user.role.permissions.includes('update_payment_methods');
        }
        if (id === 'roles') {
          return authCtx.user.role.permissions.includes('update_roles');
        }
        if (id === 'subscription') {
          return authCtx.user.role.permissions.includes('update_company_subscription');
        }
        return true;
      });
    }, [authCtx.user.role.permissions]);
    const settingsIntegrationRoutes = useMemo(() => {
      return SETTINGS_INTEGRATIONS_ROUTES.filter(({id}) => {
        if (id === 'maps') {
          return authCtx.user.role.permissions.includes('update_map');
        }
        if (id === 'sms') {
          return authCtx.user.role.permissions.includes('update_sms');
        }
        if (id === 'apps') {
          return authCtx.user.role.permissions.includes('update_integrations');
        }
        return true;
      });
    }, [authCtx.user.role.permissions]);

    const logOutHandler = useCallback(() => {
        localStorage.removeItem("token");
        window.location.reload();
        authCtx.logOut();
    }, [authCtx]);
    const onChangeLng = useCallback((lng) => {
      i18n.changeLanguage(lng);
      localStorage.setItem("lng", lng);
      setMomentJsLocale(lng);
    }, [i18n]);

    useEffect(() => {
        if (pathname === '/settings') {
            navigate('/settings/payment-methods');
        }
    }, [pathname, navigate]);
    useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    return (
      <Container>
          <div className="settings">
            <PageHeader>
              <p>{t("pages.settings.settings")}</p>
            </PageHeader>
            <div className="settings_in">
              <div className="settings_menu">
                <h2>{t("pages.settings.company")}</h2>
                <ul>
                  {settingsCompanyRoutes.map(({id, icon, label, link}) => (
                    <li
                      key={id}
                      className={cn("settings_menu_list", {"settings_menu_list--active": pathname === link})}
                    >
                      <Link to={link}>
                        {icon}
                        <span>{t(`pages.settings.${label}`)}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
                <h2>{t("pages.settings.integrations")}</h2>
                <ul>
                  {settingsIntegrationRoutes.map(({id, icon, label, link}) => (
                    <li
                      key={id}
                      className={cn("settings_menu_list", {"settings_menu_list--active": pathname === link})}
                    >
                      <Link to={link}>
                        {icon}
                        <span>{t(`pages.settings.${label}`)}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className="settings_menu_lng">
                  <button
                    className={cn("settings_menu_lng_btn", {"settings_menu_lng_btn--active" : i18n.language === "am"})}
                    onClick={() => onChangeLng("am")}
                  >AM</button>
                  <button
                    className={cn("settings_menu_lng_btn", {"settings_menu_lng_btn--active" : i18n.language === "en"})}
                    onClick={() => onChangeLng("en")}
                  >EN</button>
                </div>
                <button
                  className="settings_menu_logout_btn"
                  onClick={logOutHandler}
                >
                  {windowWidth > 1024 ? t("pages.settings.logout") : <HiLogin />}
                </button>
              </div>
              <div className="settings_main">
                <Outlet />
              </div>
            </div>
          </div>
      </Container>
    );
}

Settings.propTypes = {
  setLng: PropTypes.func,
  lng: PropTypes.string
};

export default memo(Settings);
