import {memo, useCallback, useContext, useEffect, useState} from "react";
import "./index.scss";
import moment from "moment";
import { CgProfile } from "react-icons/cg";
import { AiFillPhone } from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";
import {BsBatteryFull} from "react-icons/bs";
import { FiActivity } from "react-icons/fi";
import { MdEmail } from "react-icons/md";
import { GoDeviceMobile } from "react-icons/go";
import { TbVersions } from "react-icons/tb";
import {getAddressFromLatLng} from "../../../../utils/geocoding";
import api from "../../../../utils/api";
import {useDebouncedCallback} from 'use-debounce';
import PropTypes from "prop-types";
import ContentLoading from "../../../../components/ui/loading/content-loading";
import {fromLonLat} from 'ol/proj';
import {useTranslation} from "react-i18next";
import PreferencesContext from "../../../../context/preferences/preferences-context";
import AuthContext from "../../../../context/auth/auth-context";
import cn from "classnames";
import {errToString, toPriceWithCurrency} from "../../../../utils";
import Tab from "../../../../components/ui/menu-tab";
import { MdDragIndicator } from "react-icons/md";
import IntegrationsContext from "../../../../context/integrations/integrations-context";

function AgentHistory({
mapIntegration,
selectedAgent,
setSelectedOrder,
map,
setSelectedAgent,
onRefreshAgents
}) {
    const integrations = useContext(IntegrationsContext);
    const {user} = useContext(AuthContext);
    const {list} = useContext(PreferencesContext);
    const {t} = useTranslation();
    const [lastLocationAddress, setLastLocationAddress] = useState(null);
    const [lastLocationLoading, setLastLocationLoading] = useState(true);
    const [showAddress, setShowAddress] = useState(false);
    const [isChecked, setIsChecked] = useState(selectedAgent.isOnline);
    const [activeTab, setActiveTab] = useState("assigned");
    const [dropTargetIndex, setDropTargetIndex] = useState(null);
    const [dragSourceIndex, setDragSourceIndex] = useState(null);

    const onAgentIsOnlineChange = useDebouncedCallback(e => {
        e.stopPropagation();

        api
          .put(`/employees/agents/${selectedAgent.id}`, {
              isOnline: !isChecked
          })
          .then(res => {
              setIsChecked(res.data.isOnline);
              setSelectedAgent({
                  ...selectedAgent,
                  isOnline: res.data.isOnline
              });
          })
          .catch(err => console.log(errToString(err)));
    }, 200);
    const handleDragStart = useCallback((e, index) => {
        e.dataTransfer.setData('text/plain', index);
        setDragSourceIndex(index);
    }, []);
    const handleDragOver = useCallback((e, index) => {
        e.preventDefault();
        setDropTargetIndex(index);
    }, []);
    const handleDragLeave = useCallback(() => {
        setDropTargetIndex(null);
    }, []);
    const handleDrop = useCallback((e, targetIndex) => {
        e.preventDefault();
        setDropTargetIndex(null);

        const sourceIndex = e.dataTransfer.getData('text/plain');
        const newObjects = [...selectedAgent.orders?.filter(order => order.status !== "canceled" && order.status !== "failed" && order.status !== "delivered")]
        const [draggedObject] = newObjects.splice(sourceIndex, 1);
        newObjects.splice(targetIndex, 0, draggedObject);

        let resultObject = {};
        const updatedObjects = [...newObjects.map(({id}) => id)];
        updatedObjects.forEach((number, index) => resultObject[number] = index + 1);

        api
          .put("/orders/sort", resultObject)
          .then(() => {
              setSelectedAgent({
                  ...selectedAgent,
                  orders:
                    [...newObjects, ...selectedAgent.orders?.filter(order => order.status === "canceled" || order.status === "failed" || order.status === "delivered")]
              })
              onRefreshAgents("reorder");
          })
          .catch((err) => console.log(errToString(err)));
    }, [setSelectedAgent, selectedAgent, onRefreshAgents]);
    const handleDragEnd = useCallback(() => {
        setDragSourceIndex(null);
    }, []);

    useEffect(() => {
        if (!showAddress) {
            return
        }

        if (!selectedAgent.lastLocation) {
            return setLastLocationLoading(false);
        }

        const lat = selectedAgent.lastLocation.lat;
        const lng = selectedAgent.lastLocation.lng;

        getAddressFromLatLng(lat, lng, address => {
            setLastLocationLoading(false);
            setLastLocationAddress(address.label);
        }, {
            type: integrations.mapIntegration.value,
            apiKey: integrations.mapIntegration?.credentials?.apiKey
        });
    // eslint-disable-next-line
    }, [showAddress]);

    return (
        <div className="agent_history">
          {selectedAgent.orders && (
            <>
              <div className='agent_history_header'>
                  <div className="agent_history_title agent_history_title--no_margin">
                      {t("pages.dashboard.agents_modal.agent_history.agentDetails")}
                  </div>
                  <p>{isChecked ? 'on' : 'off'}</p>
                  {user.role.permissions.includes("agent_duty") && (
                    <input
                      type="checkbox"
                      className="agent_history_isOnline"
                      onChange={onAgentIsOnlineChange}
                      checked={isChecked}
                    />
                  )}
              </div>
              <div className="agent_history_profile">
                <div className="agent_history_profile__info">
                  <div className="agent_history_profile__info_wrapper">
                    <CgProfile />
                    <p className="agent_history_profile__info--label">{t("pages.dashboard.agents_modal.agent_history.name")}</p>
                  </div>
                  <p className="agent_history_profile__info--descrip">{selectedAgent.firstName} {selectedAgent.lastName}</p>
                </div>
                <div className="agent_history_profile__info">
                  <div className="agent_history_profile__info_wrapper">
                    <AiFillPhone />
                    <p className="agent_history_profile__info--label">{t("pages.dashboard.agents_modal.agent_history.phoneNumber")}</p>
                  </div>
                  <p className="agent_history_profile__info--descrip">{selectedAgent.phoneNumber}</p>
                </div>
                  {selectedAgent.email && (
                    <div className="agent_history_profile__info">
                        <div className="agent_history_profile__info_wrapper">
                            <MdEmail />
                            <p className="agent_history_profile__info--label">{t("pages.dashboard.agents_modal.agent_history.email")}</p>
                        </div>
                        <p className="agent_history_profile__info--descrip">{selectedAgent.email}</p>
                    </div>
                  )}
                  <div className="agent_history_profile__info">
                      <div className="agent_history_profile__info_wrapper">
                          <GoDeviceMobile />
                          <p className="agent_history_profile__info--label">{t("pages.dashboard.agents_modal.agent_history.device")}</p>
                      </div>
                      <p className="agent_history_profile__info--descrip">{selectedAgent.device} {selectedAgent.deviceModel ? ` - ${selectedAgent.deviceModel}` : ""}</p>
                  </div>
                  {selectedAgent.batteryLevel && (
                    <div className="agent_history_profile__info">
                        <div className="agent_history_profile__info_wrapper">
                            <BsBatteryFull />
                            <p className="agent_history_profile__info--label">{t("pages.dashboard.agents_modal.agent_history.batteryLevel")}</p>
                        </div>
                        <p className="agent_history_profile__info--descrip">{`${(selectedAgent.batteryLevel * 100)?.toFixed(0)}%`}</p>
                    </div>
                  )}
                  {selectedAgent.appVersion && (
                    <div className="agent_history_profile__info">
                        <div className="agent_history_profile__info_wrapper">
                            <TbVersions />
                            <p className="agent_history_profile__info--label">{t("pages.dashboard.agents_modal.agent_history.appVersion")}</p>
                        </div>
                        <p className="agent_history_profile__info--descrip">{selectedAgent.appVersion}</p>
                    </div>
                  )}
                <div className="agent_history_profile__info">
                  <div className="agent_history_profile__info_wrapper">
                    <BiCurrentLocation />
                    <p className="agent_history_profile__info--label">{t("pages.dashboard.agents_modal.agent_history.lastLocation")}</p>
                  </div>
                    {!showAddress && (
                      <button
                        className="agent_history_profile__info--location_btn"
                        onClick={() => setShowAddress(true)}
                      >{t("pages.dashboard.agents_modal.agent_history.showAddress")}</button>
                    )}
                    {showAddress && (
                      <>
                          {!lastLocationLoading && (
                              <p className="agent_history_profile__info--location">{lastLocationAddress || t("pages.dashboard.order_details.order_history.noLocation")}</p>
                          )}
                          {lastLocationLoading && (
                            <div className="agent_history_profile__info--location_btn">
                                <ContentLoading type="line"/>
                            </div>
                          )}
                      </>
                    )}
                </div>
                  {selectedAgent.lastActivity && (
                    <div className="agent_history_profile__info">
                        <div className="agent_history_profile__info_wrapper">
                            <FiActivity />
                            <p className="agent_history_profile__info--label">{t("pages.dashboard.agents_modal.agent_history.lastActivity")}</p>
                        </div>
                        <p className="agent_history_profile__info--descrip">{moment(selectedAgent.lastActivity).fromNow()}</p>
                    </div>
                  )}
              </div>
            </>
          )}
          {!!selectedAgent.orders.length && (
            <div>
                <div className="agent_history_title">
                    {t("pages.dashboard.agents_modal.agent_history.agentOrders")}
                </div>
                <div className="orders_modal__tabs">
                    <Tab
                      title={t("pages.dashboard.orders_modal.assigned")}
                      isActive={activeTab === 'assigned'}
                      onClick={() => setActiveTab('assigned')}
                      count={selectedAgent.orders.filter(order => order.status !== "canceled" && order.status !== "failed" && order.status !== "delivered").length}
                    />
                    <Tab
                      title={t("pages.dashboard.orders_modal.completed")}
                      isActive={activeTab === 'completed'}
                      onClick={() => setActiveTab('completed')}
                      count={selectedAgent.orders.filter(order => order.status === "canceled" || order.status === "failed" || order.status === "delivered").length}
                    />
                </div>
                {activeTab === "assigned" &&
                  selectedAgent.orders
                    ?.filter(order => order.status !== "canceled" && order.status !== "failed" && order.status !== "delivered")
                    ?.map((order, index) => (
                          <div
                            key={order.id}
                            className={cn(
                              "agent_history_task_in",
                              {"agent_history_task_in--highlighted": index === dropTargetIndex},
                              {"agent_history_task_in--dragging": index === dragSourceIndex}
                            )}
                          >
                            <div
                              className="agent_history_task_in_item"
                              draggable={true}
                              onDragStart={(e) => handleDragStart(e, index)}
                              onDragOver={(e) => handleDragOver(e, index)}
                              onDragLeave={() => handleDragLeave()}
                              onDragEnd={() => handleDragEnd()}
                              onDrop={(e) => handleDrop(e, index)}
                            >
                              <div className="agent_history_task_in_item_draggable">
                                  <MdDragIndicator />
                              </div>
                              <div className="agent_history_task_in_item_heading">
                                  <div className="agent_history_task_in_item_heading_id">
                                      #{order.externalId}
                                  </div>
                                  {order.deliveryType === 'pre' && (
                                    <div className="order_item_times_time order_item_times_time--pre">
                                        <p>{t(`pages.dashboard.orders_modal.orders_list.order_item.${order.deliveryType}`)}</p>
                                    </div>
                                  )}
                                  <div
                                    className={cn(`agent_history_task_in_item_status agent_history_task_in_item_status--${order.status}`)}
                                  >
                                      <p>{t(`statuses.${order.status}`)}</p>
                                  </div>
                                  <button className="agent_history_task_in_item_btn" onClick={() => {
                                      setSelectedOrder(order);

                                      if (mapIntegration.value === 'google') {
                                          map.setCenter(order.address.location);
                                          map.setZoom(15);
                                      }
                                      if (mapIntegration.value === 'open_street') {
                                          map.getView().setCenter(fromLonLat([order.address.location.lng, order.address.location.lat]));
                                          map.getView().setZoom(15);
                                      }
                                  }}>{t("pages.dashboard.agents_modal.agent_history.viewDetails")}</button>
                              </div>
                              <div className="agent_history_task_in_item_info">
                                  <div className="agent_history_task_in_item_info_wrapper">
                                      <span>{t("pages.dashboard.orders_modal.orders_list.order_item.address")}:</span>
                                      <p>
                                          {order.address.deliveryAddress}
                                          {order.address.apartment &&
                                            `, ${t("pages.dashboard.orders_modal.orders_list.order_item.apt")}. ${order.address.apartment}`}
                                          {order.address.entrance &&
                                            `, ${t("pages.dashboard.orders_modal.orders_list.order_item.ent")}. ${order.address.entrance}`}
                                          {order.address.floor &&
                                            `, ${t("pages.dashboard.orders_modal.orders_list.order_item.flr")}. ${order.address.floor}`}
                                      </p>
                                  </div>
                                  <div className="agent_history_task_in_item_info_wrapper">
                                <span>{order.paymentMethod.name.toLowerCase() === "cash"
                                  ? t("payment.cash") :
                                  order.paymentMethod.name
                                }</span>
                                      <p>{toPriceWithCurrency(order.amount, list.currency.value.currency.toUpperCase())}</p>
                                  </div>
                                  <div className="agent_history_task_in_item_info_wrapper">
                                      <span>{t("pages.dashboard.orders_modal.orders_list.order_item.customer")}:</span>
                                      <p>{order.customer.phoneNumber}</p>
                                  </div>
                              </div>
                          </div>
                          </div>
                    ))
                }
                {activeTab === "assigned" &&
                  !selectedAgent.orders.filter(order => order.status !== "canceled" && order.status !== "failed" && order.status !== "delivered")
                    .length && (
                      <div
                        className='agent_history_no_data'
                        style={{ textAlign: 'center'}}
                      >{t("pages.dashboard.agents_modal.agent_history.noOrders")}
                      </div>
                  )
                }
                {activeTab === "completed" &&
                  selectedAgent.orders
                    ?.filter(order => order.status === "canceled" || order.status === "failed" || order.status === "delivered")
                    ?.map(order => (
                          <div key={order.id} className="agent_history_task_in">
                              <div className="agent_history_task_in_item">
                                  <div className="agent_history_task_in_item_heading">
                                      <div className="agent_history_task_in_item_heading_id">
                                          #{order.externalId}
                                      </div>
                                      {order.deliveryType === 'pre' && (
                                        <div className="order_item_times_time order_item_times_time--pre">
                                            <p>{t(`pages.dashboard.orders_modal.orders_list.order_item.${order.deliveryType}`)}</p>
                                        </div>
                                      )}
                                      <div
                                        className={cn(`agent_history_task_in_item_status agent_history_task_in_item_status--${order.status}`)}
                                      >
                                          <p>{t(`statuses.${order.status}`)}</p>
                                      </div>
                                      <button className="agent_history_task_in_item_btn" onClick={() => {
                                          setSelectedOrder(order);

                                          if (mapIntegration.value === 'google') {
                                              map.setCenter(order.address.location);
                                              map.setZoom(15);
                                          }
                                          if (mapIntegration.value === 'open_street') {
                                              map.getView().setCenter(fromLonLat([order.address.location.lng, order.address.location.lat]));
                                              map.getView().setZoom(15);
                                          }
                                      }}>{t("pages.dashboard.agents_modal.agent_history.viewDetails")}</button>
                                  </div>
                                  <div className="agent_history_task_in_item_info">
                                      <div className="agent_history_task_in_item_info_wrapper">
                                          <span>{t("pages.dashboard.orders_modal.orders_list.order_item.address")}:</span>
                                          <p>
                                              {order.address.deliveryAddress}
                                              {order.address.apartment &&
                                                `, ${t("pages.dashboard.orders_modal.orders_list.order_item.apt")}. ${order.address.apartment}`}
                                              {order.address.entrance &&
                                                `, ${t("pages.dashboard.orders_modal.orders_list.order_item.ent")}. ${order.address.entrance}`}
                                              {order.address.floor &&
                                                `, ${t("pages.dashboard.orders_modal.orders_list.order_item.flr")}. ${order.address.floor}`}
                                          </p>
                                      </div>
                                      <div className="agent_history_task_in_item_info_wrapper">
                                <span>{order.paymentMethod.name.toLowerCase() === "cash"
                                  ? t("payment.cash") :
                                  order.paymentMethod.name
                                }</span>
                                          <p>{toPriceWithCurrency(order.amount, list.currency.value.currency.toUpperCase())}</p>
                                      </div>
                                      <div className="agent_history_task_in_item_info_wrapper">
                                          <span>{t("pages.dashboard.orders_modal.orders_list.order_item.customer")}:</span>
                                          <p>{order.customer.phoneNumber}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                    ))
                }
                {activeTab === "completed" &&
                  !selectedAgent.orders.filter(order => order.status === "canceled" || order.status === "failed" || order.status === "delivered")
                    .length && (
                    <div
                      className='agent_history_no_data'
                      style={{ textAlign: 'center'}}
                    >{t("pages.dashboard.agents_modal.agent_history.noOrders")}
                    </div>
                  )
                }
            </div>
          )}
          {!selectedAgent.orders.length && (
            <div
                className='agent_history_no_data'
                style={{ textAlign: 'center'}}
            >{t("pages.dashboard.agents_modal.agent_history.noOrders")}</div>
          )}
        </div>
    );
}

AgentHistory.propTypes = {
    mapIntegration: PropTypes.object.isRequired,
    map: PropTypes.object,
    setSelectedAgent: PropTypes.func.isRequired
};

export default memo(AgentHistory)
