import "./index.scss";
import {memo, useCallback} from "react";
import {createPortal} from 'react-dom';
import PropTypes from "prop-types";
import api from "../../../../../utils/api";
import {useTranslation} from "react-i18next";
import {errToString} from "../../../../../utils";

function DeleteRoleForm({onCancel, selectedRole, setRoles, roles}) {
  const {t} = useTranslation();

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    if (selectedRole.name.toLowerCase() === 'default') {
      return alert("Default role cannot be deleted")
    }

    api
      .delete(`/roles/${selectedRole.id}`)
      .then(() => {
        setRoles(roles.filter(role => role.id !== selectedRole.id));
        onCancel();
      })
      .catch((err) => {
        alert(t(`errors.${errToString(err)}`))
        console.log(errToString(err))
      })

  }, [t, onCancel, selectedRole, roles, setRoles]);

  return createPortal(
    <div className="delete_role_form">
      <div
        className="agents_in_freeze_alert"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="agents_in_freeze_alert_title">
          {`${t("pages.settings.warningFreezeRole")} ${selectedRole.name}`}
        </div>
        <div className="agents_in_freeze_alert_actions">
          <button
            className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_cancel"
            onClick={onCancel}
          >{t("pages.agents.cancel")}</button>
          <button
            className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_submit agents_in_freeze_alert_btn_submit--freeze"
            onClick={onSubmit}
          >{t("pages.agents.freeze")}</button>
        </div>
      </div>
      <div
        className="agents_in_freeze_alert_overlay"
        onClick={onCancel}
      />
    </div>,
    document.getElementById('modal')
  );
}

DeleteRoleForm.propTypes = {
  onCancel: PropTypes.func,
  selectedRole: PropTypes.object.isRequired,
  roles: PropTypes.array,
  setRoles: PropTypes.func
}

export default memo(DeleteRoleForm);
