import { memo } from "react";
import Year from "../../ui/year";
import "./index.scss";

function Footer() {
  return (
    <div className="footer">
        <span>
          ©
        </span>
      <Year />
      <p>
        by Task Road. All Rights Reserved.
      </p>
    </div>
  );
}

export default memo(Footer);
