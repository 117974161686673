import {memo} from "react";
import "./index.scss";
import {useTranslation} from "react-i18next";
import AgentMoney from "../../../../dashboard/agents-modal/agent-money";
import DefaultAvatar from "../../../../../assets/avatar-default.png"
import {AiOutlineQuestionCircle} from "react-icons/ai";
import {Tooltip} from "react-tooltip";

function SettingsCompanyAgentsAgentCard({nameOptions, moneyOptions}) {
    const {t} = useTranslation();

    return (
      <div className="settings_company_agents_agent_card">
        <div className="settings_company_agents_agent_card_details">
          <div className="settings_company_agents_agent_card_details_data">
            <div className="settings_company_agents_agent_card_details_data__img">
                <img src={DefaultAvatar} alt="driver"/>
            </div>
            <div className="settings_company_agents_agent_card_details_data_rows">
                  <div className="settings_company_agents_agent_card_details_data_row">
                      <span className="settings_company_agents_agent_card_details_data_row_title">{t("pages.dashboard.agents_modal.agent_info.info")}:</span>
                      {nameOptions === "name" ? <p>John Doe</p> : <p>john_doe</p>}
                      <span className="settings_company_agents_agent_card_details_data_row_status settings_company_agents_agent_card_details_data_row_status--online" />
                  </div>
                  <div className="settings_company_agents_agent_card_details_data_row">
                      <span className="settings_company_agents_agent_card_details_data_row_title">{t("pages.dashboard.agents_modal.agent_info.phone")}:</span>
                      <p>+00 000 000 000</p>
                  </div>
                  <div className="settings_company_agents_agent_card_details_data_row">
                      <span className="settings_company_agents_agent_card_details_data_row_title">{t("pages.dashboard.agents_modal.agent_info.lastActivity")}:</span>
                      <p>3 min ago</p>
                  </div>
              </div>
          </div>
        </div>
        <div className="settings_company_agents_agent_card_money">
              <div className="settings_company_agents_agent_card_money__col">
                  <AgentMoney
                      paymentMethod={t("components.create_order.cash")}
                      amount={1000}
                      count={1}
                      agentId={1}
                  />
                  {!moneyOptions && (
                      <div  className="settings_company_agents_agent_card_money__col--icon">
                          <AiOutlineQuestionCircle
                              className="settings_integrations_sms_image_in_title_icon"
                              data-tooltip-id={`tooltip-notification-title`}
                          />
                          <Tooltip
                              id={`tooltip-notification-title`}
                              place="top"
                              className="tooltipClass"
                          >{t("pages.settings.addPaymentMethodOptionDescription")}</Tooltip>
                      </div>
                  )}
                  {moneyOptions && (
                      <AgentMoney
                          paymentMethod={moneyOptions}
                          amount={1000}
                          count={1}
                          agentId={1}
                      />
                  )}
                  <AgentMoney
                      paymentMethod={t("pages.dashboard.agents_modal.agent_info.other")}
                      amount={1000}
                      count={1}
                      agentId={1}
                  />
              </div>
          </div>
      </div>
    );
}

export default memo(SettingsCompanyAgentsAgentCard)
