import { useReducer, useCallback, memo } from 'react';
import UserCompanyBranchesContext from "./user-company-branches-context";

const defaultUserCompanyBranchesState = {
  list: null,
  set: () => {},
  add: () => {},
  update: () => {},
  delete: () => {}
};

const userCompanyBranchesReducer = (state, action) => {
  if (action.type === 'SET_BRANCHES') {
    return {
      ...state,
      list: action.payload.branches
    };
  }
  if (action.type === 'ADD_BRANCH') {
    return {
      ...state,
      list: [
        ...state.list,
        action.payload.branch
      ]
    };
  }
  if (action.type === 'UPDATE_BRANCH') {
    return {
      ...state,
      list: state.list.map(_branch => {
        if (_branch.id === action.payload.branch.id) {
          return action.payload.branch;
        }

        return _branch;
      })
    };
  }
  if (action.type === 'DELETE_BRANCH') {
    return {
      ...state,
      list: state.list.map(_branch => {
        if (_branch.id === action.payload.id) {
          return {
            ..._branch,
            deletedAt: new Date().toISOString()
          }
        }

        return _branch;
      })
    };
  }

  return defaultUserCompanyBranchesState;
};

const UserCompanyBranchesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userCompanyBranchesReducer, defaultUserCompanyBranchesState);

  const setBranches = useCallback(branches => {
    dispatch({
      type: 'SET_BRANCHES',
      payload: { branches },
    });
  }, [dispatch]);
  const addBranches = useCallback(branch => {
    dispatch({
      type: 'ADD_BRANCH',
      payload: { branch },
    });
  }, [dispatch]);
  const updateBranches = useCallback(branch => {
    dispatch({
      type: 'UPDATE_BRANCH',
      payload: { branch },
    });
  }, [dispatch]);
  const deleteBranches = useCallback(id => {
    dispatch({
      type: 'DELETE_BRANCH',
      payload: { id },
    });
  }, [dispatch]);

  const userCompanyBranchesContext = {
    list: state.list,
    set: setBranches,
    add: addBranches,
    update: updateBranches,
    delete: deleteBranches
  };

  return (
    <UserCompanyBranchesContext.Provider value={userCompanyBranchesContext}>{children}</UserCompanyBranchesContext.Provider>
  );
};

export default memo(UserCompanyBranchesProvider);
