import React, {memo, useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import Loading from '../../ui/loading';
import Marker from './marker';
import {resizeImage} from "../open-street-map/marker";

function GoogleMap({ zoom, center, mapOptions, map, setMap, markers = [] }) {
  const ref = useRef();
  const [loading, setLoading] = useState(true);
  const [traffic, setTraffic] = useState(null);
  const [mapMarkers, setMapMarkers] = useState([]);

  useEffect(() => {
    const _map = new window.google.maps.Map(ref.current, {
      zoom,
      center,
      disableDefaultUI: true
    });

    window.google.maps.event.addListenerOnce(_map, 'idle', () => {
      setMap(_map);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!map || !mapOptions) {
      return;
    }

    if (mapOptions?.traffic && !traffic) {
      const trafficLayer = new window.google.maps.TrafficLayer();
      trafficLayer.setMap(map);
      setTraffic(trafficLayer);
    }

    if (!mapOptions?.traffic && traffic) {
      traffic.setMap(null);
      setTraffic(null);
    }
  }, [map, mapOptions, traffic]);
  useEffect(() => {
    const promises = [];

    for (const marker of markers) {
      if (marker.type === 'agent') {
        promises.push(new Promise(resolve => resizeImage(marker.img, marker.imgSize[0], marker.imgSize[1], marker.status, img => resolve({
          ...marker,
          img
        }))));
      } else {
        promises.push(Promise.resolve(marker));
      }
    }

    Promise
        .all(promises)
        .then(mapMarkers => setMapMarkers(mapMarkers));
  }, [markers]);

  return (
    <>
      <div
        className="map"
        ref={ref}
      >
        {!loading && map && mapMarkers.map(marker => (
          <Marker
            key={`${marker.type}-${marker.id}`}
            map={map}
            options={marker}
          />
        ))}
      </div>
      {loading && (
        <div className="map__loading">
          <Loading />
        </div>
      )}
    </>
  );
}

Map.propTypes = {
  zoom: PropTypes.number.isRequired,
  center: PropTypes.object.isRequired,
  mapOptions: PropTypes.object,
  setMap: PropTypes.func.isRequired,
  map: PropTypes.object,
  markers: PropTypes.array
};

export default memo(GoogleMap);
