import "./index.scss";
import {memo, useEffect, useState, useMemo, useCallback, useContext} from "react";
import {useLocation} from "react-router-dom";
import {createPortal} from "react-dom";
import api from "../../../utils/api";
import AnalyticsInfo from "../../../components/ui/analytics-info";
import Container from "../../../components/layout/container";
import Table from "../../../components/table";
import PageHeader from "../../../components/layout/page-header";
import moment from "moment";
import Status from "../../../components/ui/status";
import {LIMITS} from "../../../constants/drop-down-options";
import Pagination from "../../../components/ui/pagination";
import Search from "../../../components/search";
import DateTimePicker from "../../../components/ui/calendar/date-time-picker-double-calendar";
import UserProfile from "../../../components/layout/user-profile";
import {getCurrentDateWithoutHours} from '../../../utils/date';
import {AiFillStar} from "react-icons/ai";
import PreferencesContext from "../../../context/preferences/preferences-context";
import {useTranslation} from 'react-i18next';
import {errToString} from "../../../utils";

const SEARCH_FIELDS_ORDERS = [
  {label: 'orderId', value: 'externalId'},
  {label: 'agent', value: 'agent'},
  {label: 'amount', value: 'amount'},
  {label: 'address', value: 'address'}
];

function AgentDetails() {
  const {pathname} = useLocation();
  const agentId = pathname.split("/")[3];
  const {list} = useContext(PreferencesContext);
  const {t} = useTranslation();

  const detailsOrdersTitles = useMemo(() => [
    {
      name: t("pages.agents.agent_details.orderId"),
    },
    {
      name: t("pages.agents.agent_details.agent"),
    },
    {
      name: t("pages.agents.agent_details.paymentMethod"),
    },
    {
      name: t("pages.agents.agent_details.amount"),
    },
    {
      name: t("pages.agents.agent_details.customer")
    },
    {
      name: t("pages.agents.agent_details.deliveryAddress"),
      size: 200
    },
    {
      name: t("pages.agents.agent_details.rating"),
    },
    {
      name: t("pages.agents.agent_details.review"),
    },
    {
      name: t("pages.agents.agent_details.orderCreated"),
    },
    {
      name: t("pages.agents.agent_details.orderStatus"),
    }
  ], [t]);

  const [showProfile, setShowProfile] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);

  const [agentData, setAgentData] = useState({})
  const [analyticsData, setAnalyticsData] = useState({})
  const [profileData, setProfileData] = useState(null)
  const [profileLoading, setProfileLoading] = useState(true)
  const [agentDataLoading, setAgentDataLoading] = useState(true)
  const [agentAnalyticsLoading, setAgentAnalyticsLoading] = useState(true)
  const searchFieldsOrders = useMemo(() => SEARCH_FIELDS_ORDERS.map(option => ({
    ...option,
    label: t(`pages.dashboard.orders.${option.label}`)
  })), [t]);

  const onChangeLimit = useCallback(option => {
    if (option.value === limit.value) {
      return;
    }

    setLimit(option);
    setOffset(0);
    setCurrentPage(0);

  }, [limit]);
  const [dateFields, setDateFields] = useState({
    startDate: getCurrentDateWithoutHours(),
    endDate: getCurrentDateWithoutHours(23, 59, 59),
  });
  const onSearch = useCallback(({ search, searchField }) => {
    const startDate = moment(dateFields.startDate).utc().format();
    const endDate = moment(dateFields.endDate).utc().format();

    setAgentDataLoading(true);

    api
      .get(`/employees/agents/${agentId}/orders?offset=${offset}&limit=${limit.value}&from=${startDate}&to=${endDate}${search && searchField ? `&search=${search}&searchField=${searchField}` : ''}`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setAgentData(res.data);
        setAgentDataLoading(false);
      })
      .catch((err) => console.log(errToString(err)))
  }, [offset, limit, agentId, dateFields])

  useEffect(() => {
    const startDate = moment(dateFields.startDate).utc().format();
    const endDate = moment(dateFields.endDate).utc().format();

    setAgentDataLoading(true);
    setAgentAnalyticsLoading(true);
    api
      .get(`/employees/agents/${agentId}/orders?offset=${offset}&limit=${limit.value}&from=${startDate}&to=${endDate}`)
      .then((res) => {
        setAgentData(res.data);
        setPagesCount(res.data.pagesCount);
        setAgentDataLoading(false);
        setAgentAnalyticsLoading(false);
      })
      .catch((err) => console.log(errToString(err)))
  }, [agentId, dateFields, offset, limit]);
  useEffect(() => {
    const startDate = moment(dateFields.startDate).utc().format();
    let endDate = moment(dateFields.endDate).utc().format();

    setAgentDataLoading(true);
    setAgentAnalyticsLoading(true);
    api
      .get(`/employees/agents/${agentId}/analytics?from=${startDate}&to=${endDate}`)
      .then((res) => {
        setAnalyticsData(res.data);
        setAgentDataLoading(false);
        setAgentAnalyticsLoading(false);
      })
      .catch((err) => console.log(errToString(err)))
  }, [agentId, dateFields]);
  useEffect(() => {

    setProfileLoading(true);
    api
      .get(`/employees/agents/${agentId}?freezed`)
      .then((res) => {
        setProfileData(res.data);
        setProfileLoading(false)
      })
      .catch((err) => console.log(errToString(err)))
  }, [agentId]);

  return (
    <Container>
      <div
        className="agent_details"
      >
        <PageHeader
          type="navigation"
          link="/agents"
          linkTitle={t("pages.agents.agent_details.agents")}
          pageTitle={t("pages.agents.agent_details.agentDetails")}
          isCustomer={false}
          data={profileData}
          loading={profileLoading}
          onClick={() => setShowProfile(true)}
        />
        <div className="agent_details_in_actions">
          <Search
            placeholder={t("pages.agents.agent_details.search")}
            options={searchFieldsOrders}
            onSearch={onSearch}
          />
          <div className='orders_in_actions_calendar'>
            <DateTimePicker
              className="orders_date_time_picker"
              placeholder="DD/MM/YYYY HH:MM - HH:MM"
              enableTimePicker={true}
              position="bottom"
              theme='orders'
              enableRangePicker={true}
              value={[dateFields.startDate, dateFields.endDate]}
              onChange={e => {
                setDateFields(e);
                setOffset(0);
              }}
            />
          </div>
        </div>
        <div className="agent_details_dashboard">
          <AnalyticsInfo
            className="agent_details_dashboard__info"
            title={t("pages.agents.agent_details.pendingOrders")}
            count={analyticsData?.pendingOrdersCount}
            status={"pending"}
            loading={agentAnalyticsLoading}
          />
          <AnalyticsInfo
            className="agent_details_dashboard__info"
            title={t("pages.agents.agent_details.ongoingOrders")}
            count={analyticsData?.ongoingOrdersCount}
            status={"ongoing"}
            loading={agentAnalyticsLoading}
          />
          <AnalyticsInfo
            className="agent_details_dashboard__info"
            title={t("pages.agents.agent_details.finishedOrders")}
            count={analyticsData?.finishedOrdersCount}
            status={"finished"}
            loading={agentAnalyticsLoading}
          />
          <AnalyticsInfo
            className="agent_details_dashboard__info"
            title={t("pages.agents.agent_details.canceledOrders")}
            count={analyticsData?.canceledOrdersCount}
            status={"canceled"}
            loading={agentAnalyticsLoading}
          />
        </div>
        <div className="agent_details_in">
          <Table
            titles={detailsOrdersTitles}
            hiddenFields={['id']}
            rows={agentData?.results?.map(order => ({
              id: order.id,
              orderId: (
                <p>{`${t("pages.agents.agent_details.orderId")} ${order.externalId}`}</p>
              ),
              agent: order.agent ? (
                <p>{`${order.agent.firstName} ${order.agent.lastName} ${order.agent.phoneNumber}`}</p>
              ) : <p>n/a</p>,
              paymentMethod: (
                <p>{order.paymentMethod.name.toLowerCase() === "cash" ? t("payment.cash") : order.paymentMethod.name}</p>
              ),
              amount: (
                <p>{`${order.amount}`}</p>
              ),
              customer: (order.customer.firstName && order.customer.lastName ? (
                  <p>{`${order.customer.firstName} ${order.customer.lastName} ${order.customer.phoneNumber}`}</p>
                ) : <p>{`${order.customer.phoneNumber}`}</p>
              ),
              deliveryAddress: (
                <p>{order.address.deliveryAddress}</p>
              ),
              rating: (
                <div className="table__rating">
                  <AiFillStar/>
                  <p>{order.rating ? Number(order.rating).toFixed(2) : "-"}</p>
                </div >
              ),
              review: (
                <p>{order.review ? order.review : '-'}</p>
              ),
              orderCreated: (
                <p>{moment(order.createdAt).format(`${list.date_format.value.format}, ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}</p>
              ),
              status: order.status && (
                <Status status={order.status} type="table"/>
              )
            })) || []}
            loading={agentDataLoading}
          />
        </div>
      </div>
      {!!pagesCount && (
        <Pagination
            pagesCount={pagesCount}
            setOffset={setOffset}
            limit={limit}
            limitPlaceholder={limit.label}
            limits={LIMITS}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onChange={onChangeLimit}
        />
      )}
      {profileData && createPortal(
        <UserProfile
          id={agentId}
          type="agents"
          user={profileData}
          isProfileModalOpen={showProfile}
          setIsProfileModalOpen={setShowProfile}
        />,document.getElementById('modal'))}
    </Container>
  );
}

export default memo(AgentDetails);
