import { memo } from "react";
import "./index.scss";
import cn from "classnames";
import {useTranslation} from "react-i18next";

function Status({status, type = 'default'}) {
  const {t} = useTranslation();

  return (
    <div className={cn('status', {'status--sm' : type === 'table'}, {'status--flag' : type === 'card'})}>
      <p className={cn(`status status--${status}`, {"status--sm" : type === "table"})}>{t(`statuses.${status}`)}</p>
    </div>
  );
}

export default memo(Status);
