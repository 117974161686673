export const NOT_ALLOWED_NAVIGATION_ROUTES = [
  "/auth",
  "/auth/sign-up",
  "/auth/sign-up/am",
  "/forgot-password/verify",
  "/employees/verify",
  "/not-found",
  "/t",
  "/admin",
  "export"
];
