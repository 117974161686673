import "./index.scss";
import {memo, useCallback, useMemo, useState} from "react";
import {createPortal} from 'react-dom';
import {RiCloseCircleFill} from "react-icons/ri";
import PropTypes from "prop-types";
import Table from "../../../../components/table";
import {FiEdit} from "react-icons/fi";
import {FaUserMinus, FaUsersCog} from "react-icons/fa";
import api from "../../../../utils/api";
import TransferUserForm from "./transfer-user-form";
import {useTranslation} from "react-i18next";
import sound from "../../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import moment from "moment/moment";
import {errToString} from "../../../../utils";

function BranchSettingsPopup({
selectedBranch,
setSelectedBranch,
setIsEditBranchNameFormOpen,
setIsAssignUsersFormOpen,
setBranchesData,
dateFields,
branchesData
}) {
  const {t} = useTranslation();
  const branchUsersTitles = useMemo(() => [
    {
      name: t("pages.branches.branch_card.branch_settings_popup.user"),
      size: 300
    },
    {
      name: t("pages.branches.branch_card.branch_settings_popup.type")
    },
    {
      name: t("pages.branches.branch_card.branch_settings_popup.username")
    },
    {
      name: t("pages.branches.branch_card.branch_settings_popup.role")
    },
    {
      size: 50
    },
    {
      size: 50
    }
  ], [t]);
  const [isTransferUsersFormOpen, setIsTransferUsersFormOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const onRemoveUser = useCallback((user) => {

        const startDate = moment(dateFields.startDate).utc().format();
        const endDate = moment(dateFields.endDate).utc().format();

         api
          .delete(`/company/address/${selectedBranch.id}/employees?from=${startDate}&to=${endDate}`, {
            data: {
              ids: [user.id]
            }
          })
          .then(() => {
            api
              .get('/company/addresses?includeUsers')
              .then(({data}) => {
                setSelectedBranch(data.find(({id}) => id === selectedBranch.id));
                setBranchesData(data);
                const audio = new Audio(sound);
                const info = `${t("pages.branches.branch_card.branch_settings_popup.user")} #${user.id}id ${t("pages.branches.branch_card.branch_settings_popup.hasBeenRemoved")}`;
                toast.info(info, {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                audio.play();
              })
              .catch(err => console.log(errToString(err)));
          })
          .catch(err => console.log(errToString(err)))

  }, [dateFields, t, setSelectedBranch, setBranchesData, selectedBranch]);

  return createPortal(
    <>
      <div className="branch_settings_popup">
        <h2 className="branch_settings_popup__title">
          <p>
            {`${selectedBranch.name}`}
          </p>
          <FiEdit
            onClick={() => setIsEditBranchNameFormOpen(true)}
          />
           <button
            onClick={() => setIsAssignUsersFormOpen(true)}
           >{t("pages.branches.branch_card.branch_settings_popup.assignUsers")}</button>
        </h2>
        <div
          className="branch_settings_popup__close"
          onClick={() => setSelectedBranch(null)}
        >
          <RiCloseCircleFill/>
        </div>
        <div className="branch_settings_popup_in">
          <Table
            titles={branchUsersTitles}
            loading={false}
            hiddenFields={['id']}
            rows={selectedBranch.users ? (
              selectedBranch.users.filter(user => user.type !== "owner").map((user, index) => ({
                id: index,
                name: (
                  <div className="table__avatar">
                    <img
                      src={user.src}
                      alt="agent"
                    />
                    <p>{user.firstName} {user.lastName}</p>
                  </div>
                ),
                type: (
                  <p>{user.type}</p>
                ),
                username: (
                  <p>{user.username}</p>
                ),
                role: (
                  <p>{user.role.name}</p>
                ),
                remove: (
                  <p
                    className="branch_settings_popup_in__icon"
                    onClick={() => {
                      onRemoveUser(user)
                    }}
                  >
                    <FaUserMinus />
                  </p>
                ),
                transfer: (
                  <p
                    className="branch_settings_popup_in__icon"
                    onClick={() => {
                      setIsTransferUsersFormOpen(true);
                      setSelectedUser(user);
                    }}
                  >
                    <FaUsersCog />
                  </p>
                )
              })) || []
            ) : []}
          />
        </div>
      </div>
      {selectedBranch && isTransferUsersFormOpen && (
        <TransferUserForm
          dateFields={dateFields}
          selectedBranch={selectedBranch || []}
          onCancel={() => setIsTransferUsersFormOpen(false)}
          branchesData={branchesData}
          selectedUser={selectedUser}
          setIsTransferUsersFormOpen={setIsTransferUsersFormOpen}
          setBranchesData={setBranchesData}
          setSelectedBranch={setSelectedBranch}
        />
      )}
    </>,
      document.getElementById('modal')
  );
}

BranchSettingsPopup.propTypes = {
  selectedBranch: PropTypes.object,
  setSelectedBranch: PropTypes.func,
  setIsEditBranchNameFormOpen: PropTypes.func,
  setIsAssignUsersFormOpen: PropTypes.func,
  setBranchesData: PropTypes.func,
  setIsTransferUsersMultipleFormOpen: PropTypes.func,
  branchesData: PropTypes.array
}

export default memo(BranchSettingsPopup);
