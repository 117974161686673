import "./index.scss";
import {memo, useCallback, useEffect, useMemo, useState} from "react";
import {createPortal} from 'react-dom';
import Form from "../../../../../components/form";
import FormRow from "../../../../../components/form/form-row";
import FormCol from "../../../../../components/form/form-col";
import PropTypes from "prop-types";
import api from "../../../../../utils/api";
import Loading from "../../../../../components/ui/loading";
import Table from "../../../../../components/table";
import DropDown from "../../../../../components/ui/drop-down";
import {useTranslation} from "react-i18next";
import {errToString} from "../../../../../utils";

function AssignRoleForm({onCancel}) {

  const [users, setUsers] = useState(null);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [roles, setRoles] = useState(null);
  const assignRoleTitles = useMemo(() => [
    {
    },
    {
    },
    {
    },
    {
      size: 100
    }
  ], []);
  const [loading, setLoading] = useState(true);
  const [rolesLoading, setRolesLoading] = useState(true);
  const {t} = useTranslation();

  const onChange = useCallback(user => {
    setUsers(users.map(_user => {
      if (_user.id === user.id) {
        return {
          ...user,
          isChosen: !user.isChosen
        };
      }

      return _user;
    }));
  }, [users]);
  const onChangeRole = useCallback((e) => {
    setSelectedRole(e);
  }, []);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setError(null);

    const assignedUsers = users.filter(user => user.isChosen);
    const mangersIds = assignedUsers.filter(user => user.type === "manager").map(manager => manager.id);
    const agentsIds = assignedUsers.filter(user => user.type === "agent").map(agent => agent.id);

    const promises = [];
    mangersIds.forEach(managerId => {
      promises.push(api.put(`/employees/managers/${managerId}`, {
        roleId: selectedRole.id
      }));
    })
    agentsIds.forEach(agentId => {
      promises.push(api.put(`/employees/agents/${agentId}`, {
        roleId: selectedRole.id
      }));
    })

    setPending(true);
    Promise.all(promises)
      .then(() => {
        setUsers(users.map(user => {
          if (assignedUsers.find(assignedUser => assignedUser.id === user.id)) {
            return {
              ...user,
              role: selectedRole
            }
          }
          return user;
        }));
        setPending(false);
        onCancel();
      })
      .catch(err => {
        console.log(errToString(err));
        setError(errToString(err));
        setPending(false);
      })

  }, [onCancel, users, selectedRole]);

  useEffect(() => {
    setLoading(true);

    Promise.all([
      api.get(`/employees/managers?companyAddressIds=all`)
    ])
      .then((res) => {
        let employees = [];
        res.forEach((obj) => {
          employees.push(...obj.data.results)
        })
        setUsers(employees.map(user => {
          return {
            ...user,
            isChosen: false
          }
        }))
      })
      .catch(err => {
        console.log(errToString(err));
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);
  useEffect(() => {
    api
      .get("/roles")
      .then(({data}) => {
        const _data = data.map(role => {
          return {...role, 'label': role.name}
        })
        setRoles(_data);
        setSelectedRole(_data[0]);
        setRolesLoading(false)
      })
  }, [])

  return createPortal(
    <div className="assign_role_form">
      {!loading && !rolesLoading && (
        <div className="assign_role_form_in">
          <Form
            title ={t("pages.settings.assignRole")}
            error={error && t(`errors.${error?.toLowerCase()}`)}
            onSubmit={onSubmit}
          >
            <FormRow>
              <FormCol>
                <div className="assign_role_form_drop_down">
                  <DropDown
                    hasRoleIcon={true}
                    placeholder={t("pages.settings.chooseRole")}
                    options={roles}
                    value={selectedRole}
                    onChange={onChangeRole}
                    position="bottom"
                    showClose={false}
                    className="assign_role_form_drop_down"
                  />
                </div>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <div className="assign_role_form_table">
                  <Table
                    titles={assignRoleTitles}
                    loading={false}
                    hiddenFields={['id']}
                    rows={users.map(user => ({
                      id: user.id,
                      name: <p>{user.firstName} {user.lastName}</p>,
                      type: <p>{user.type}</p>,
                      role: <p>{user.role?.name}</p>,
                      check: (
                        <input
                          type="checkbox"
                          onChange={() => onChange(user)}
                          checked={user.isChosen}
                        />
                      )
                    })) || []}
                  />
                </div>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <button
                  className="assign_role_form__cancel_btn"
                  onClick={onCancel}
                >{t("pages.branches.create_branch_form.cancel")}</button>
              </FormCol>
              <FormCol>
                {!pending && (
                  <button className="assign_role_form__submit_btn" type="submit">{t("pages.branches.create_branch_form.save")}</button>
                )}
                {pending && (
                  <button className="assign_role_form__submit_btn assign_role_form__submit_btn--pending">
                    <Loading />
                  </button>
                )}
              </FormCol>
            </FormRow>
          </Form>
        </div>
      )}
      {loading && (
        <Loading/>
      )}
    </div>,
    document.getElementById('modal')
  );
}

AssignRoleForm.propTypes = {
  onCancel: PropTypes.func.isRequired
}

export default memo(AssignRoleForm);
