import './index.scss';
import React, {memo, useMemo, useState} from 'react';
import cn from 'classnames';
import AssignAgentFormItem from "./assign-agent-form-item";
import {useTranslation} from "react-i18next";

function AssignAgentForm({
show,
assignTypeAgents,
setAssignTypeAgents,
agentsOptions,
partnersOptions,
pickupAddress,
setShow,
setAssignedPartner,
setAssignedAgent,
pickupAddressLocation,
assignedAgent,
assignedPartner,
showTitleBtn = true
}) {

  const {t} = useTranslation();
  const [searchValue, setSearchValue] = useState("")

  const onlineDriversFree = useMemo(() => {
    if (!!agentsOptions.length) {
      if (!searchValue) {
        return (
          agentsOptions
            .filter(({isOnline}) => isOnline)
            .filter(agent => (
              !agent.orders.filter(order => order.status === 'started' || order.status === 'picked_up' || order.status === 'on_way').length ||
              !agent.orders.length
            ))
        )
      } else {
        return (
          agentsOptions
            .filter(({isOnline}) => isOnline)
            .filter(agent => (
              !agent.orders.filter(order => order.status === 'started' || order.status === 'picked_up' || order.status === 'on_way').length ||
              !agent.orders.length
            ))
            .filter(agent => (
              agent.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
              agent.lastName.toLowerCase().includes(searchValue.toLowerCase())
            ))
        )
      }
    }
    if (!agentsOptions.length) {
      return []
    }
  }, [agentsOptions, searchValue]);
  const onlineDriversBusy = useMemo(() => {
    if (!!agentsOptions.length) {
      if (!searchValue) {
        return (
          agentsOptions
            .filter(({isOnline}) => isOnline)
            .filter(agent => (
              agent.orders.filter(order => order.status === 'started' || order.status === 'picked_up' || order.status === 'on_way').length
            ))
        )
      } else {
        return (
          agentsOptions
            .filter(({isOnline}) => isOnline)
            .filter(agent => (
              agent.orders.filter(order => order.status === 'started' || order.status === 'picked_up' || order.status === 'on_way').length
            ))
            .filter(agent => (
              agent.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
              agent.lastName.toLowerCase().includes(searchValue.toLowerCase())
            ))
        )
      }
    }
    if (!agentsOptions.length) {
      return []
    }
  }, [agentsOptions, searchValue]);
  const offlineDrivers = useMemo(() => {
    if (!!agentsOptions.length) {
      if (!searchValue) {
        return (
          agentsOptions.filter(({isOnline}) => !isOnline)
        )
      } else {
        return (
          agentsOptions
            .filter(({isOnline}) => !isOnline)
            .filter(agent => (
              agent.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
              agent.lastName.toLowerCase().includes(searchValue.toLowerCase())
            ))
        )
      }
    }
    if (!agentsOptions.length) {
      return []
    }
  }, [agentsOptions, searchValue]);

  return (
    <div className={cn("assign_agent_form", {"assign_agent_form--show" : show})}>
      <ul className="assign_agent_form_in">
        <h2 className="assign_agent_form_in_title">
          {assignTypeAgents ? `${pickupAddress} ${t("general.drivers")}` : t("general.partners")}
          {showTitleBtn && (
            <div
              className="assign_agent_form_in_title_btn"
              onClick={() => setAssignTypeAgents(!assignTypeAgents)}
            >
              {assignTypeAgents ? t("general.changeToPartners") : t("general.changeToDrivers")}
            </div>
          )}
        </h2>
        {assignTypeAgents && agentsOptions && !agentsOptions.length && (
          <li
            className='assign_agent_form_item assign_agent_form_item--noHover'
          >
              <span className='assign_agent_form_item_noAgents'>
                {t("general.noDrivers")}
              </span>
          </li>
        )}
        {!assignTypeAgents && partnersOptions && !partnersOptions.length && (
          <li
            className='assign_agent_form_item assign_agent_form_item--noHover'
          >
              <span className='assign_agent_form_item_noAgents'>
                {t("general.noPartners")}
              </span>
          </li>
        )}
        {assignTypeAgents && agentsOptions && !!agentsOptions.length && (
          <div className="assign_agent_form_in_search">
            <input
              type="text"
              placeholder={t("general.searchDriver")}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        )}
        {assignTypeAgents && (
          onlineDriversFree.map((agentsOption, index) => (
            <AssignAgentFormItem
              key={`${agentsOption.id}:${index}`}
              type={"agents"}
              free={true}
              busy={false}
              index={`${index}:${agentsOption.id}`}
              setAssignedPartner={setAssignedPartner}
              setAssignedAgent={setAssignedAgent}
              setShow={setShow}
              agentsOption={agentsOption}
              partnerOption={null}
              pickupAddressLocation={pickupAddressLocation}
              assignedAgent={assignedAgent}
            />
          )))}
        {assignTypeAgents && (
          onlineDriversBusy.map((agentsOption, index) => (
            <AssignAgentFormItem
              key={`${agentsOption.id}:${index}`}
              type={"agents"}
              free={false}
              busy={true}
              index={`${index}:${agentsOption.id}`}
              setAssignedPartner={setAssignedPartner}
              setAssignedAgent={setAssignedAgent}
              setShow={setShow}
              agentsOption={agentsOption}
              partnerOption={null}
              pickupAddressLocation={pickupAddressLocation}
              assignedAgent={assignedAgent}
            />
          )))}
        {assignTypeAgents && (
          offlineDrivers.map((agentsOption, index) => (
            <AssignAgentFormItem
              key={`${agentsOption.id}:${index}`}
              type={"agents"}
              index={`${index}:${agentsOption.id}`}
              setAssignedPartner={setAssignedPartner}
              setAssignedAgent={setAssignedAgent}
              setShow={setShow}
              agentsOption={agentsOption}
              partnerOption={null}
              pickupAddressLocation={pickupAddressLocation}
              assignedAgent={assignedAgent}
            />
          )))}
        {!assignTypeAgents && !!partnersOptions.length && (
          partnersOptions.map((partnerOption, index) => (
            <AssignAgentFormItem
              key={`${partnerOption.id}:${index}`}
              type={"partners"}
              index={`${index}:${partnerOption.id}`}
              setAssignedPartner={setAssignedPartner}
              setAssignedAgent={setAssignedAgent}
              setShow={setShow}
              agentsOption={null}
              partnerOption={partnerOption}
              pickupAddressLocation={pickupAddressLocation}
              assignedPartner={assignedPartner}
            />
          )))}
      </ul>
    </div>
  );
}

export default memo(AssignAgentForm);
