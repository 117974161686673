export const removeDuplicates = (arr, key) => [...new Map(arr.map(item => [item[key], item])).values()];

export const createArrFromLength = length => {
  const loadingArray = []

  for (let i = 0; i < length; i++) {
    loadingArray.push(i);
  }

  return loadingArray;
};

// export const toPriceWithCurrency = (price, currency) => price.toLocaleString('en-US', {
//   style: 'currency',
//   currency,
//   minimumFractionDigits: 0,
//   maximumFractionDigits: 0
// });
export const toPriceWithCurrency = (price, currency) => {
  const formatToCurrency =  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    maximumSignificantDigits: 20
  });

  return formatToCurrency.format(price);
};

export const errToString = err => {
  console.log(err);
  if (Array.isArray(err?.response?.data)) {
    const error = err.response.data[0];
    return `${error.path.join('.')}.${error.type}`;
  }
  if (err?.response?.data?.error) {
    return err.response.data.error;
  }
  if (err?.response?.data?.code) {
    return err.response.data.code;
  }

  return 'internal.server.error';
}
