import "./index.scss";
import {memo, useState} from "react";
import Overlay from "../../../../components/ui/overlay";
import {useTranslation} from "react-i18next";
import StripeLogo from "../../../../assets/stripe.png"
import Logo from "../../../../assets/logo.png";
import {RiLockPasswordLine} from "react-icons/ri";
import {MdOutlineError} from "react-icons/md";
import {toPriceWithCurrency} from "../../../../utils";
import Loading from "../../../../components/ui/loading";
import {createPortal} from "react-dom";

function StripeFormSms({
setIsFormOpen,
products,
onSelect,
pending,
setPending
}) {
  const {t} = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  return (
    <>
      <form className="stripe_form_sms">
        <div className='stripe_form_sms_logo'>
          <img src={Logo} alt="taskroad driver"/>
        </div>
        <div className="stripe_form_sms_info stripe_form_sms_info_margin_bottom">
          <span className="stripe_form_sms_info_icon stripe_form_sms_info_icon--warning">
            <MdOutlineError />
          </span>
          <p>{t("pages.billing.warning")}</p>
        </div>
        <div className="stripe_form_sms_wrapper">
          {products.map((product) => (
            <div
              key={product.id}
              className="stripe_form_sms_wrapper_item"
              onClick={() => {
                setOpenModal(true);
                setSelectedProduct(product);
              }}
            >
              {!pending && (
                <>
                  <p>x{product.quantity}</p>
                  <span>{toPriceWithCurrency(product.price, "usd")}</span>
                </>
              )}
              {pending && (
                <Loading />
              )}
            </div>
          ))}
        </div>
        <div className="stripe_form_sms_info stripe_form_sms_info_margin_top">
          <span className="stripe_form_sms_info_icon stripe_form_sms_info_icon--lock">
            <RiLockPasswordLine />
          </span>
          <p>{t("pages.billing.secured")}</p>
          <div className="stripe_form_sms_info_wrapper">
            <span>Powered by</span>
            <div className="stripe_form_sms_info_img">
              <img src={StripeLogo} alt="stripe"/>
            </div>
          </div>
        </div>
      </form>
      <Overlay isTransparent={false} onClick={() => {
        setIsFormOpen(false);
        setPending(false);
        setSelectedProduct(null);
      }}/>
      {openModal && createPortal(
        <>
          <div className="stripe_form_sms_alert">
            <div className="stripe_form_sms_alert_title">{t("pages.billing.smsCreditsPurchaseWarningTitle")} {selectedProduct.name}?</div>
            <div className="stripe_form_sms_alert_actions">
              <button
                className="stripe_form_sms_alert_actions_btn stripe_form_sms_alert_actions_btn--cancel"
                onClick={() => {
                  setOpenModal(false);
                  setPending(false);
                }}
              >{t("pages.billing.cancel")}</button>
              <button
                className="stripe_form_sms_alert_actions_btn stripe_form_sms_alert_actions_btn--submit"
                onClick={() => {
                  setOpenModal(false);
                  setPending(true);
                  onSelect(selectedProduct.id);
                }}
              >{t("pages.billing.buyCredits")}</button>
            </div>
          </div>
          <div
            className="stripe_form_sms_alert_overlay"
            onClick={() => {
              setOpenModal(false);
              setSelectedProduct(null);
            }}
          />
        </>,
        document.getElementById('modal')
      )}
    </>
  );
}

export default memo(StripeFormSms);
