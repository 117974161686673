import "./index.scss";
import {memo, useCallback, useContext, useState} from "react";
import {createPortal} from 'react-dom';
import Form from "../../../../../components/form";
import FormRow from "../../../../../components/form/form-row";
import FormCol from "../../../../../components/form/form-col";
import api from "../../../../../utils/api";
import PropTypes from "prop-types";
import Loading from "../../../../../components/ui/loading";
import {useTranslation} from "react-i18next";
import PaymentMethodsContext from "../../../../../context/payment-methods/payment-methods-context";
import {errToString} from "../../../../../utils";

function CreatePaymentMethodForm({
  onCancel,
  setIsCreatePaymentMethodFormOpen
}) {
  const paymentMethods = useContext(PaymentMethodsContext);
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const {t} = useTranslation();
  const [pending, setPending] = useState(false)

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setError(null);

    setPending(true);
    api
      .post('/company/payment_method', {
        name
      })
      .then(({ data }) => {
        paymentMethods.add(data);
        setIsCreatePaymentMethodFormOpen(false);
        setPending(false);
      })
      .catch((err) => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err))
      })
  }, [setIsCreatePaymentMethodFormOpen, name, paymentMethods]);

  return createPortal(
    <div className="create_payment_method_form">
      <Form
        title ={t("pages.settings.createPayment")}
        error={error && t(`errors.${error?.toLowerCase()}`)}
        onSubmit={onSubmit}
      >
        <FormRow>
          <FormCol>
            <label htmlFor="payment_method">
              <span>*</span>
              {t("pages.settings.paymentName")}
            </label>
            <input
              name="payment_method"
              id="payment_method"
              maxLength={20}
              type="text"
              placeholder={t("pages.settings.paymentName")}
              autoComplete="off"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <button
                className="create_payment_method_form__cancel_btn"
                onClick={onCancel}
            >{t("pages.branches.create_branch_form.cancel")}</button>
          </FormCol>
          <FormCol>
            {!pending && (
                <button className="create_payment_method_form__submit_btn" type="submit">{t("pages.branches.create_branch_form.save")}</button>
            )}
            {pending && (
                <button className="create_payment_method_form__submit_btn create_payment_method_form__submit_btn--pending">
                  <Loading />
                </button>
            )}
          </FormCol>
        </FormRow>
      </Form>
    </div>,
      document.getElementById('modal')
  );
}

CreatePaymentMethodForm.propTypes = {
  onCancel: PropTypes.func,
  setIsCreatePaymentMethodFormOpen: PropTypes.func
}

export default memo(CreatePaymentMethodForm);
