import "../index.scss";
import 'react-calendar/dist/Calendar.css';
import {memo, useState, useRef, useEffect, useCallback, useContext} from "react";
import {BsCalendarCheck} from "react-icons/bs";
import Calendar from "react-calendar";
import moment from "moment";
import PropTypes from 'prop-types';
import cn from 'classnames';
import {createPortal} from 'react-dom';
import Overlay from '../../overlay';
import PreferencesContext from "../../../../context/preferences/preferences-context";
import {useTranslation} from "react-i18next";

function DateTimePicker({
  className = '',
  placeholder = '',
  minDate,
  maxDate,
  position = 'bottom',
  disabled,
  enableRangePicker = false,
  value,
  onChange,
  type="default"
}) {

  const {list} = useContext(PreferencesContext);
  const ref = useRef();
  const popupRef = useRef();
  const {t} = useTranslation();
  const [popupTop, setPopupTop] = useState('-9999px');
  const [popupLeft, setPopupLeft] = useState('-9999px');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [date, setDate] = useState(value);

  const onSave = useCallback(() => {
    const newStartDate = new Date(date[0]);
    const newEndDate = new Date(date[1]);

    onChange({
      startDate: newStartDate,
      endDate: newEndDate
    });
    setIsCalendarOpen(false);
  }, [onChange, date]);

  useEffect(() => {
    if (ref.current && popupRef.current) {
      setPopupLeft(ref.current?.getBoundingClientRect().left);

      if (position === 'top') {
        setPopupTop(ref.current?.getBoundingClientRect().top - popupRef.current?.getBoundingClientRect().height - 5);
      }
      if (position === 'bottom') {
        setPopupTop(ref.current?.getBoundingClientRect().top + ref.current?.getBoundingClientRect().height + 5);
      }
    }
  }, [ref, popupRef, isCalendarOpen, position]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={cn('date_time_picker', className, { 'date_time_picker--disabled': disabled })}
    >
      <div className="date_time_picker_in">
          <label htmlFor="dateTimePicker">
            <BsCalendarCheck/>
          </label>
          {type === "default" && (
            <input
              autoComplete="off"
              id="dateTimePicker"
              type="text"
              value={`${moment(date[0]).format(`${list.date_format.value.format}`)} - ${moment(date[1]).format(`${list.date_format.value.format.toUpperCase()}`)}`}
              readOnly={true}
              placeholder={placeholder}
              onClick={e => {
                e.stopPropagation();
                setIsCalendarOpen(!isCalendarOpen);
              }}
            />
          )}
        {type === "admin" && (
          <input
            autoComplete="off"
            id="dateTimePicker"
            type="text"
            value={`${moment(date[0]).format(`DD/MM/YY`)} - ${moment(date[1]).format(`DD/MM/YY`)}`}
            readOnly={true}
            placeholder={placeholder}
            onClick={e => {
              e.stopPropagation();
              setIsCalendarOpen(!isCalendarOpen);
            }}
          />
        )}
      </div>
      {isCalendarOpen && createPortal(
        <div
          ref={popupRef}
          className="date_time_picker_wrapper"
          style={{
            top: popupTop,
            left: windowWidth > 600 ? popupLeft - 300 : 5,
            maxWidth: windowWidth > 600 ? ref.current?.offsetWidth + 300 : windowWidth - 10
        }}
        >
          <>
            <div className="date_time_picker_wrapper_calendar date_time_picker_wrapper_calendar__double">
              <Calendar
                showNavigation={true}
                selectRange={enableRangePicker}
                view="month"
                minDate={minDate}
                maxDate={maxDate}
                showDoubleView={true}
                value={date}
                onChange={date => setDate(date)}
              />
              <button
                className="date_time_picker_wrapper_btn"
                onClick={onSave}
              >{t("components.ui.calendar.save")}</button>
            </div>
          </>
        </div>,
        document.getElementById('modal')
      )}
      {isCalendarOpen && (
        <Overlay
          isTransparent={true}
          onClick={() => {
            setIsCalendarOpen(false);
            return setDate(value)
          }}
        />
      )}
    </div>
  );
}

DateTimePicker.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  endDate: PropTypes.object,
  position: PropTypes.string,
  disabled: PropTypes.bool,
  enableTimePicker: PropTypes.bool,
  enableRangePicker: PropTypes.bool,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default memo(DateTimePicker);
