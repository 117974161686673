import {memo} from "react";
import PropTypes from "prop-types";

function TableRow({row, className, onClick, hiddenFields}) {
  return (
    <>
      <tr
        className={className}
        onClick={onClick}
      >
        {Object.keys(row).filter(field => !hiddenFields.includes(field)).map((col, index) => (
          <td key={index}>{row[col]}</td>
        ))}
      </tr>
    </>
  );
}

TableRow.propTypes = {
  row: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  hiddenFields: PropTypes.array
};

export default memo(TableRow);
