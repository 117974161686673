import './index.scss';
import {memo, useCallback, useContext, useState} from 'react';
import {FiSettings} from "react-icons/fi";
import {AiFillCheckCircle, AiFillStar} from "react-icons/ai";
import {FiEdit} from "react-icons/fi";
import {MdCancel} from "react-icons/md";
import {TbTrashX} from "react-icons/tb";
import PropTypes from "prop-types";
import DeleteBranchForm from "./delete-branch-form";
import Overlay from "../../../components/ui/overlay";
import Loading from "../../../components/ui/loading";
import {errToString, toPriceWithCurrency} from "../../../utils";
import EditBranchHeadForm from "./edit-branch-head-form";
import Status from "../../../components/ui/status";
import api from "../../../utils/api";
import sound from "../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import PreferencesContext from "../../../context/preferences/preferences-context";
import {useTranslation} from "react-i18next";
import AuthContext from "../../../context/auth/auth-context";
import {Tooltip} from "react-tooltip";
import UserCompanyBranchesContext from "../../../context/user-company-branches/user-company-branches-context";

function BranchCard({
  branch,
  setSelectedBranch,
  branchesData,
  setBranchesData,
  loading,
  setIsSeeAllAgentsPopupOpen,
  setIsSeeAllManagersPopupOpen,
  dateFields
}) {
  const {list} = useContext(PreferencesContext);
  const {user} = useContext(AuthContext);
  const userCompanyBranches = useContext(UserCompanyBranchesContext);

  const {t} = useTranslation();
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);
  const [isEditHeadFormOpen, setIsEditHeadFormOpen] = useState(false);
  const [isFreezedHovered, setIsFreezedHovered] = useState(false);

  const onUnfreezeBranch = useCallback(() => {
    api
      .put(`/company/address/${branch.id}`, {
        restore: true
      })
      .then((res) => {
        setBranchesData(branchesData.map((data) => {
          if (branch.id === data.id) {
            return {
              ...data,
              deletedAt: null
            }
          }
          return data;
        }));

        const audio = new Audio(sound);
        const info = `${t("pages.branches.branch_card.branch")} ${branch.name} ${t("pages.branches.branch_card.hasBeenUnfrozen")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();

        api
            .get('/user-company-addresses')
            .then((res) => userCompanyBranches.set(res.data))
            .catch(err => console.log(errToString(err)));
      })
      .catch(err => console.log(errToString(err)));
  // eslint-disable-next-line
  }, [t, branch, setBranchesData, branchesData, userCompanyBranches.set])

  return (
    <>
      <div className="branch_card">
        <div className="branch_card_wrapper">
          {branch.deletedAt && (
            <div
              className="branch_card_state"
              onMouseOver={() => setIsFreezedHovered(true)}
              onMouseLeave={() => setIsFreezedHovered(false)}
            >
              {!isFreezedHovered && (
                <div className="branch_card_state_in">
                  <Status type='table' status={"freezed"}/>
                </div>
              )}
              {isFreezedHovered && (
                <div
                  style={{cursor: "pointer"}}
                  className="branch_card_state_in"
                  onClick={onUnfreezeBranch}
                >
                  <Status type='table' status={"unfreeze"}/>
                </div>
              )}
            </div>
          )}
          {!branch.deletedAt && (
            <div className="branch_card_state"
            >
              <div className="branch_card_state_in">
                <Status type='table' status={"active"}/>
              </div>
            </div>
          )}
          <div className="branch_card_title">
            {user.role.permissions.includes("update_branch") && (
              <div className="branch_card_title__settings">
                {!branch.deletedAt && (
                  <FiSettings
                    onClick={() => setSelectedBranch(branch)}
                  />
                )}
              </div>
            )}
            <p>{branch.name}</p>
            {!branch.deletedAt && user.role.permissions.includes("delete_branch") && (
              <div className="branch_card_title__delete">
                <TbTrashX
                  onClick={() => setIsDeleteFormOpen(true)}
                />
              </div>
            )}
          </div>
          <div className="branch_card_loaction">
            <p>{branch.deliveryAddress}</p>
          </div>
          <div className="branch_card_header">
            <div className="branch_card_header__img">
              <img src={branch.head.src} alt="user"/>
            </div>
            <div className="branch_card_header_info">
              <div className="branch_card_header_info__name">
                <p>{branch.head.firstName} {branch.head.lastName}</p>
                {!branch.deletedAt && (
                  <div
                    className="branch_card_header_info__icon"
                    onClick={() => setIsEditHeadFormOpen(true)}
                  >
                    <FiEdit />
                  </div>
                )}
              </div>
              <div className="branch_card_header_info__data">
                <p>{branch.head.phoneNumber}</p>
              </div>
            </div>
            <div className="branch_card_header_money">
              <p className="branch_card_header_money__title">{t("pages.branches.branch_card.balance")}</p>
              {!loading && (
                branch.ordersAmount ? (
                  <p className="branch_card_header_money__amount">{branch.ordersAmount > 999999999 ? `${list.currency.value.currency.toUpperCase()} 1 BLN` : toPriceWithCurrency(Number(branch.ordersAmount), list.currency.value.currency.toUpperCase())}</p>
                ) : (
                  <p className="branch_card_header_money__amount">{`${list.currency.value.currency.toUpperCase()} 0`}</p>
                )
              )}
              {loading && (
                <Loading />
              )}
            </div>
          </div>
        </div>
        <div className="branch_card_in">
          <p className="branch_card_in__title">
            {t("pages.branches.branch_card.managers")}
          </p>
          <div className="branch_card_in_users" onClick={(e) => {
            e.stopPropagation();
            setSelectedBranch(branch);
            setIsSeeAllManagersPopupOpen(true);
          }}>
            {branch.users?.filter(user => user.type === "manager").length ? branch.users.filter(user => user.type === "manager").map(manager => (
              <div
                key={manager.id}
                className="branch_card_in_users__img"
              >
                <img src={manager.src} alt="user"/>
              </div>
            )) : (
              <p className="branch_card_in_users__no_data">{t("pages.branches.branch_card.noManagers")}</p>
            )}
            <div
              className="branch_card_in_users__count"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedBranch(branch);
                setIsSeeAllManagersPopupOpen(true);
              }}
            >
              {branch.users?.filter(user => user.type === "manager") ? branch.users?.filter(user => user.type === "manager").length : 0}
            </div>
          </div>
          <p className="branch_card_in__title">
            {t("pages.branches.branch_card.agents")}
          </p>
          <div className="branch_card_in_users" onClick={(e) => {
            e.stopPropagation();
            setSelectedBranch(branch);
            setIsSeeAllAgentsPopupOpen(true);
          }}>
            {branch.users?.filter(user => user.type === "agent").length ? branch.users.filter(user => user.type === "agent").map(agent => (
              <div
                key={agent.id}
                className="branch_card_in_users__img"
              >
                <img src={agent.src} alt="user"/>
              </div>
            )) : (
              <p className="branch_card_in_users__no_data">{t("pages.branches.branch_card.noAgents")}</p>
            )}
            <div
              className="branch_card_in_users__count"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedBranch(branch);
                setIsSeeAllAgentsPopupOpen(true);
              }}
            >
              {branch.users?.filter(user => user.type === "agent") ? branch.users?.filter(user => user.type === "agent").length : 0}
            </div>
          </div>
        </div>
        <div className="branch_card_footer">
          <div className="branch_card_footer_item">
            <div
              className="branch_card_footer_item_in"
              data-tooltip-id={"tooltip-delivered-orders"}
            >
              <div className="branch_card_footer_item__count">
                {!loading && (
                  branch.deliveredOrdersCount > 999999 ? "999K" : branch.deliveredOrdersCount
                )}
                {loading && (
                  <Loading />
                )}
              </div>
              <div className="branch_card_footer_item__icon branch_card_footer_item__icon--delivered">
                <AiFillCheckCircle />
              </div>
            </div>
            <div
              className="branch_card_footer_item_in"
              data-tooltip-id={"tooltip-canceled-orders"}
            >
              <div className="branch_card_footer_item__count">
                {!loading && (
                  branch.failedOrdersCount > 999999 ? "999K" : branch.failedOrdersCount
                )}
                {loading && (
                  <Loading />
                )}
              </div>
              <div className="branch_card_footer_item__icon branch_card_footer_item__icon--failed">
                <MdCancel />
              </div>
            </div>
            <div
              className="branch_card_footer_item_in"
              data-tooltip-id={"tooltip-average-rating"}
            >
              <div className="branch_card_footer_item__count">
                {!loading && (
                  Number(branch.averageRating).toFixed(2)
                )}
                {loading && (
                  <Loading />
                )}
              </div>
              <div className="branch_card_footer_item__icon branch_card_footer_item__icon--rating">
                <AiFillStar />
              </div>
            </div>
            <Tooltip
              id={`tooltip-delivered-orders`}
              place="bottom"
              className="tooltipClass"
            >{t("pages.branches.branch_card.delivered_orders")}</Tooltip>
            <Tooltip
              id={`tooltip-canceled-orders`}
              place="bottom"
              className="tooltipClass"
            >{t("pages.branches.branch_card.canceled_orders")}</Tooltip>
            <Tooltip
              id={`tooltip-average-rating`}
              place="bottom"
              className="tooltipClass"
            >{t("pages.branches.branch_card.average_rating")}</Tooltip>
          </div>
        </div>
      </div>
      {isDeleteFormOpen && (
        <>
          <DeleteBranchForm
            branch={branch}
            setIsDeleteFormOpen={setIsDeleteFormOpen}
            setBranchesData={setBranchesData}
            branchesData={branchesData}
          />
          <Overlay onClick={() => setIsDeleteFormOpen(false)}/>
        </>
      )}
      {isEditHeadFormOpen && (
        <>
          <EditBranchHeadForm
            selectedBranch={branch}
            dateFields={dateFields}
            setSelectedBranch={setSelectedBranch}
            setBranchesData={setBranchesData}
            branchesData={branchesData}
            onCancel={() => setIsEditHeadFormOpen(false)}
          />
          <Overlay
            onClick={() => setIsEditHeadFormOpen(false)}
          />
        </>
      )}
    </>
  );
}

BranchCard.propTypes = {
  branch: PropTypes.object.isRequired,
  setSelectedBranch: PropTypes.func.isRequired,
  branchesData: PropTypes.array,
  setBranchesData: PropTypes.func,
  loading: PropTypes.bool,
  setIsSeeAllManagersPopupOpen: PropTypes.func,
  setIsSeeAllAgentsPopupOpen: PropTypes.func
};

export default memo(BranchCard);
