import {memo, useEffect} from "react";
import 'react-toastify/dist/ReactToastify.css';
import "./index.scss";
import {toast, ToastContainer} from 'react-toastify';

function NotificationsPopup() {

  useEffect(() => {
    return toast.clearWaitingQueue({ containerId: "toast" });
  }, []);

  return (
    <ToastContainer
      containerId={"toast"}
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      limit={3}
      pauseOnHover
      theme="colored"
      style={{fontSize: 30}}
    />
  );
}

export default memo(NotificationsPopup);
