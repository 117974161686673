import {memo, useContext, useMemo} from "react";
import "./index.scss";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import AgentInfoLabel from "../../../../components/ui/agnet-info-labels";
import AgentMoney from "../agent-money";
import cn from "classnames";
import PreferencesContext from "../../../../context/preferences/preferences-context";
import PaymentMethodsContext from "../../../../context/payment-methods/payment-methods-context";

function PartnerInfo({ partner, isOpen }) {
    const paymentMethods = useContext(PaymentMethodsContext);
    const {t} = useTranslation();
    const {list} = useContext(PreferencesContext);

    const cashOptions = useMemo(() => {
      return paymentMethods.list
        .filter(option => option.name.toLowerCase() === "cash")
        .map((option) => ({
          paymentMethod: option.name,
          amount: partner.orders
            .filter(order => order.status === 'delivered')
            .filter(order => (order.paymentMethod.name)?.toLowerCase() === (option.name).toLowerCase())
            .reduce((acc, order) => acc + order.amount, 0),
          count: partner.orders
            .filter(order => order.status === 'delivered')
            .filter(order => order.paymentMethod.name?.toLowerCase() === option.name.toLowerCase())
            .length
        }));
    }, [partner.orders, paymentMethods.list]);

    const activePaymentMethodOption = useMemo(() => {
      const activePaymentMethodName = list.agent_pane_money.value.paymentMethods?.filter(item => !item.includes("Cash"));

      if (activePaymentMethodName.length === 0) {
        return
      }
      return {
        paymentMethod: activePaymentMethodName[0],
        amount: partner.orders
          .filter(order => order.status === 'delivered')
          .filter(order => (order.paymentMethod.name)?.toLowerCase() === activePaymentMethodName[0].toLowerCase())
          .reduce((acc, order) => acc + order.amount, 0),
        count: partner.orders
          .filter(order => order.status === 'delivered')
          .filter(order => order.paymentMethod.name?.toLowerCase() === activePaymentMethodName[0].toLowerCase())
          .length
      }
    }, [partner.orders, list.agent_pane_money.value.paymentMethods]);

    const otherPaymentMethodOption = useMemo(() => {

      return {
        paymentMethod: "Other",
        amount: partner.orders
          .filter(order => order.status === 'delivered')
          .filter(order => (order.paymentMethod.name)?.toLowerCase() !== "cash" && order.paymentMethod.name.toLowerCase() !== activePaymentMethodOption?.paymentMethod.toLowerCase())
          .reduce((acc, order) => acc + order.amount, 0),
        count: partner.orders
          .filter(order => order.status === 'delivered')
          .filter(order => (order.paymentMethod.name)?.toLowerCase() !== "cash" && order.paymentMethod.name.toLowerCase() !== activePaymentMethodOption?.paymentMethod.toLowerCase())
          .length
      }
    }, [activePaymentMethodOption, partner.orders]);
    const otherPaymentMethodOptions = useMemo(() => {
      return partner.orders
        .filter(order => order.status === 'delivered')
        .filter(order => (order.paymentMethod.name)?.toLowerCase() !== "cash" && order.paymentMethod.name.toLowerCase() !== activePaymentMethodOption?.paymentMethod.toLowerCase())
    }, [activePaymentMethodOption, partner.orders]);

    return (
      <li
          className={cn(
              "agents_container__list",
              {"agents_container__list--animate" : !isOpen}
          )}
      >
          <div className="partner_info">
              <div className="partner_info_details">
                  <div className="partner_info_details_data">
                      <img src={partner.src} alt="driver"/>
                      <div className="partner_info_details_data_rows">
                          <div className="partner_info_details_data_row">
                              <span className="partner_info_details_data_row_title">{t("pages.dashboard.agents_modal.agent_info.info")}:</span>
                              <p>{partner.name}</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="partner_info_tasks">
                  <AgentInfoLabel data={partner} status="on_way"/>
                  <AgentInfoLabel data={partner} status="picked_up"/>
                  <AgentInfoLabel data={partner} status="started"/>
                  <AgentInfoLabel data={partner} status="assigned"/>
                  <AgentInfoLabel data={partner} status="accepted"/>
                  <AgentInfoLabel data={partner} status="delivered"/>
                  <AgentInfoLabel data={partner} status="canceled"/>
                  <AgentInfoLabel data={partner} status="failed"/>
              </div>
              <div className="partner_info_money">
                  <div className="partner_info_money__col">
                      <AgentMoney
                          paymentMethod={t("components.create_order.cash")}
                          amount={cashOptions[0].amount}
                          count={cashOptions[0].count}
                          agentId={partner.id}
                      />
                      {activePaymentMethodOption && (
                          <AgentMoney
                              paymentMethod={activePaymentMethodOption.paymentMethod}
                              amount={activePaymentMethodOption.amount}
                              count={activePaymentMethodOption.count}
                              agentId={partner.id}
                          />
                      )}
                      <AgentMoney
                          paymentMethod={t("pages.dashboard.agents_modal.agent_info.other")}
                          amount={otherPaymentMethodOption.amount}
                          count={otherPaymentMethodOption.count}
                          data={otherPaymentMethodOptions}
                          agentId={partner.id}
                      />
                  </div>
              </div>
          </div>
      </li>
    );
}

PartnerInfo.propTypes = {
  partner: PropTypes.object.isRequired
};

export default memo(PartnerInfo)
