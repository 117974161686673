import "./index.scss";
import {memo} from "react";
import {GrUnorderedList} from "react-icons/gr"
import {MdOutlineDashboard} from "react-icons/md"
import {Tooltip} from "react-tooltip";
import {BiHide, BiShow} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import {RiEBike2Fill} from "react-icons/ri";
import {AiFillCar} from "react-icons/ai";

function SwitcherBtn({checked, setChecked, type}) {
    const {t} = useTranslation();

    return (
        <label
          className="tgl"
          data-tooltip-id={`tooltip-${type === "table-card" ? "switch" : "freeze"}`}
        >
            <input
                type="checkbox"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
            />
            {type === "table-card" && (
              <>
                <span>{checked ? <GrUnorderedList /> : <MdOutlineDashboard />}</span>
                <Tooltip
                  id={`tooltip-switch`}
                  place="top"
                  className="tooltipClass"
                >{checked ? t("components.ui.switcher_btn.card") : t("components.ui.switcher_btn.table")}</Tooltip>
              </>
            )}
            {type === "freeze-unfreeze-agents" && (
              <>
                <span>{checked ? <BiShow /> : <BiHide />}</span>
                <Tooltip
                  id={`tooltip-freeze`}
                  place="top"
                  className="tooltipClass"
                >{checked ? t("components.ui.switcher_btn.hideAgents") : t("components.ui.switcher_btn.showAgents")}</Tooltip>
              </>
            )}
            {type === "freeze-unfreeze-managers" && (
              <>
                <span>{checked ? <BiShow /> : <BiHide />}</span>
                <Tooltip
                  id={`tooltip-freeze`}
                  place="top"
                  className="tooltipClass"
                >{checked ? t("components.ui.switcher_btn.hideManagers") : t("components.ui.switcher_btn.showManagers")}</Tooltip>
              </>
            )}
          {type === "freeze-unfreeze-branches" && (
            <>
              <span>{checked ? <BiShow /> : <BiHide />}</span>
              <Tooltip
                id={`tooltip-freeze`}
                place="top"
                className="tooltipClass"
              >{checked ? t("components.ui.switcher_btn.hideBranches") : t("components.ui.switcher_btn.showBranches")}</Tooltip>
            </>
          )}
          {type === "freeze-unfreeze-partners" && (
            <>
              <span>{checked ? <BiShow /> : <BiHide />}</span>
              <Tooltip
                id={`tooltip-freeze`}
                place="top"
                className="tooltipClass"
              >{checked ? t("components.ui.switcher_btn.hidePartners") : t("components.ui.switcher_btn.showPartners")}</Tooltip>
            </>
          )}
          {type === "switch-vehicle" && (
            <>
                <span>{checked ? <AiFillCar /> : <RiEBike2Fill />}</span>
                <Tooltip
                    id={`tooltip-freeze`}
                    place="top"
                    className="tooltipClass"
                >{checked ? t("components.ui.switcher_btn.bike") : t("components.ui.switcher_btn.car")}</Tooltip>
            </>
         )}
        {type === "settings-payment-method" && (
            <>
                <span>{checked ? <BiShow /> : <BiHide />}</span>
                <Tooltip
                    id={`tooltip-freeze`}
                    place="top"
                    className="tooltipClass"
                >{checked ? t("components.ui.switcher_btn.hidePayments") : t("components.ui.switcher_btn.showPayments")}</Tooltip>
            </>
        )}
        </label>
    );
}

export default memo(SwitcherBtn)
