import './index.scss';
import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import api from "../../../utils/api";
import Form from '../../../components/form'
import FormRow from "../../../components/form/form-row";
import FormCol from "../../../components/form/form-col";
import PropTypes from "prop-types";
import sound from "../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Loading from "../../../components/ui/loading";
import {BsPlus} from "react-icons/bs";
import {MdPersonOutline} from "react-icons/md";
import DropDown from "../../../components/ui/drop-down";
import UserCompanyBranchesContext from "../../../context/user-company-branches/user-company-branches-context";
import {errToString} from "../../../utils";

function UpdateManager({
onClose,
manager,
managersData,
setManagersData
}) {
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const [branches, setBranches] = useState(null);
  const {t} = useTranslation();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [fields, setFields] = useState({
    firstName: manager.firstName || " ",
    lastName: manager.lastName || " "
  });
  const [roles, setRoles] = useState(null);
  const [selectedRole, setSelectedRole] = useState({label: manager.role.name, value: manager.role.id});
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onChangeField = useCallback((e, name) => {
    setError(null);

    if (
      e.target.type === 'text' ||
      e.target.type === 'number'
    ) {
      setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [fields]);
  const onSubmit = useCallback(e => {
    e.preventDefault();

    const data = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      roleId: selectedRole.id,
      address: {
        countryCode: branches[0].countryCode,
        country: branches[0].countryCode,
        city: branches[0].countryCode,
        street: branches[0].deliveryAddress,
        apartment: null,
        entrance: null,
        floor: null,
      },
    };

    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    for (const key in data.address) {
      if (data.address[key] === null) {
        delete data.address[key];
      }
    }

    setPending(true);
    api
      .put(`/employees/managers/${manager.id}`, data)
      .then((res) => {
        setManagersData(managersData.map(data => {
          if (data.id === manager.id) {
            return res.data
          }
          return data
        }))
        onClose();
        setPending(false);
        const audio = new Audio(sound);
        const info = `${t("pages.managers.update_manager.updateManagerMsg")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();

        api
          .get('/user-company-addresses')
          .then((res) => userCompanyBranches.set(res.data))
          .catch(err => console.log(errToString(err)));
      })
      .catch((err) => {
        setPending(false);
        setError(errToString(err))
        console.log(errToString(err));
      });
  }, [branches, userCompanyBranches, selectedRole, t, onClose, fields, manager, managersData, setManagersData]);

  useEffect(() => {
    const promises = [];
    promises.push(api.get("/company/addresses"));
    promises.push(api.get("/roles"));

    Promise
      .all(promises)
      .then((res) => {
        setBranches(res[0].data)
        const _data = res[1].data.map(role => {
          return {...role, 'label': role.name}
        })
        setRoles(_data);
        setSelectedRole(_data[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(errToString(err))
      })
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      {manager && !loading && (
          <div className="update_manager">
            <div className="update_manager_in">
              <Form
                  title={`${t("pages.managers.update_manager.updateManager")} ${manager.username}`}
                  error={error && t(`errors.${error?.toLowerCase()}`)}
                  onSubmit={onSubmit}
              >
                {windowWidth > 600 && (
                  <>
                    <FormRow>
                      <FormCol>
                        <label htmlFor="firstName">
                          <span>*</span>
                          {t("pages.agents.create_agent.firstName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <MdPersonOutline />
                          </div>
                          <input
                            name="firstName"
                            type="text"
                            placeholder={t("pages.managers.update_manager.firstName")}
                            autoComplete="off"
                            className="create_order_wrapper_input"
                            value={fields.firstName}
                            onChange={e => onChangeField(e, 'firstName')}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label htmlFor="lastName">
                          <span>*</span>
                          {t("pages.agents.create_agent.lastName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <MdPersonOutline />
                          </div>
                          <input
                            name="lastName"
                            type="text"
                            placeholder={t("pages.managers.update_manager.lastName")}
                            autoComplete="off"
                            className="create_order_wrapper_input"
                            value={fields.lastName}
                            onChange={e => onChangeField(e, 'lastName')}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label>
                          {t("pages.managers.create_manager.roles")}
                        </label>
                        <DropDown
                          hasRoleIcon={true}
                          placeholder={t("pages.managers.create_manager.chooseRole")}
                          options={roles}
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e)}
                          position="bottom"
                          showClose={false}
                        />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <a
                          href="!#"
                          className="update_manager__cancel_btn"
                          onClick={onClose}
                        >{t("pages.managers.update_manager.cancel")}</a>
                      </FormCol>
                      <FormCol>
                        {!pending && (
                          <button type="submit" className="update_manager__submit_btn">
                            {t("pages.managers.update_manager.updateManager")}
                          </button>
                        )}
                        {pending && (
                          <button className="create_agent__submit_btn create_agent__submit_btn--pending">
                            <Loading />
                          </button>
                        )}
                      </FormCol>
                    </FormRow>
                  </>
                )}
                {windowWidth <= 600 && (
                  <>
                    <FormRow>
                      <FormCol>
                        <label htmlFor="firstName">
                          <span>*</span>
                          {t("pages.agents.create_agent.firstName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <MdPersonOutline />
                          </div>
                          <input
                            name="firstName"
                            type="text"
                            placeholder={t("pages.managers.update_manager.firstName")}
                            autoComplete="off"
                            className="create_order_wrapper_input"
                            value={fields.firstName}
                            onChange={e => onChangeField(e, 'firstName')}
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <label htmlFor="lastName">
                          <span>*</span>
                          {t("pages.agents.create_agent.lastName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <MdPersonOutline />
                          </div>
                          <input
                            name="lastName"
                            type="text"
                            placeholder={t("pages.managers.update_manager.lastName")}
                            autoComplete="off"
                            className="create_order_wrapper_input"
                            value={fields.lastName}
                            onChange={e => onChangeField(e, 'lastName')}
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <label>
                          {t("pages.managers.create_manager.roles")}
                        </label>
                        <DropDown
                          hasRoleIcon={true}
                          placeholder={t("pages.managers.create_manager.chooseRole")}
                          options={roles}
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e)}
                          position="bottom"
                          showClose={false}
                        />
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol>
                        <a
                          href="!#"
                          className="update_manager__cancel_btn"
                          onClick={onClose}
                        >{t("pages.managers.update_manager.cancel")}</a>
                      </FormCol>
                      <div
                        className="update_order_submit_btn--round"
                        style={{marginTop: "20px"}}
                        onClick={onSubmit}
                      >
                        <BsPlus />
                      </div>
                    </FormRow>
                  </>
                )}
              </Form>
            </div>
          </div>
      )}
      {loading && (
          <div className="assign_order_loading">
            <Loading />
          </div>
      )}
    </>
  );
}

UpdateManager.propTypes = {
  onClose: PropTypes.func,
  manager: PropTypes.object,
  setManagersData: PropTypes.func,
  managersData: PropTypes.array
};

export default memo(UpdateManager);
