export const CARS = [
  {
    "brand": "Seat",
    "models": ["Alhambra", "Altea", "Altea XL", "Arosa", "Cordoba", "Cordoba Vario", "Exeo", "Ibiza", "Ibiza ST", "Exeo ST", "Leon", "Leon ST", "Inca", "Mii", "Toledo"]
  },
  {
    "brand": "Renault", "models": ["Captur", "Clio", "Clio Grandtour", "Espace", "Express", "Fluence", "Grand Espace", "Grand Modus", "Grand Scenic", "Kadjar", "Kangoo", "Kangoo Express", "Koleos", "Laguna", "Laguna Grandtour", "Latitude", "Mascott", "Mégane", "Mégane CC", "Mégane Combi", "Mégane Grandtour", "Mégane Coupé", "Mégane Scénic", "Scénic", "Talisman", "Talisman Grandtour", "Thalia", "Twingo", "Wind", "Zoé"]},
  {
    "brand": "Peugeot", "models": ["1007", "107", "106", "108", "2008", "205", "205 Cabrio", "206", "206 CC", "206 SW", "207", "207 CC", "207 SW", "306", "307", "307 CC", "307 SW", "308", "308 CC", "308 SW", "309", "4007", "4008", "405", "406", "407", "407 SW", "5008", "508", "508 SW", "605", "806", "607", "807", "Bipper", "RCZ"]},
  {
    "brand": "Dacia", "models": ["Dokker", "Duster", "Lodgy", "Logan", "Logan MCV", "Logan Van", "Sandero", "Solenza"]},
  {
    "brand": "Citroën", "models": ["Berlingo", "C-Crosser", "C-Elissée", "C-Zero", "C1", "C2", "C3", "C3 Picasso", "C4", "C4 Aircross", "C4 Cactus", "C4 Coupé", "C4 Grand Picasso", "C4 Sedan", "C5", "C5 Break", "C5 Tourer", "C6", "C8", "DS3", "DS4", "DS5", "Evasion", "Jumper", "Jumpy", "Saxo", "Nemo", "Xantia", "Xsara"]},
  {
    "brand": "Opel", "models": ["Agila", "Ampera", "Antara", "Astra", "Astra cabrio", "Astra caravan", "Astra coupé", "Calibra", "Campo", "Cascada", "Corsa", "Frontera", "Insignia", "Insignia kombi", "Kadett", "Meriva", "Mokka", "Movano", "Omega", "Signum", "Vectra", "Vectra Caravan", "Vivaro", "Vivaro Kombi", "Zafira"]},
  {
    "brand": "Alfa Romeo", "models": ["145", "146", "147", "155", "156", "156 Sportwagon", "159", "159 Sportwagon", "164", "166", "4C", "Brera", "GTV", "MiTo", "Crosswagon", "Spider", "GT", "Giulietta", "Giulia"]},
  {
    "brand": "Škoda", "models": ["Favorit", "Felicia", "Citigo", "Fabia", "Fabia Combi", "Fabia Sedan", "Felicia Combi", "Octavia", "Octavia Combi", "Roomster", "Yeti", "Rapid", "Rapid Spaceback", "Superb", "Superb Combi"]},
  {
    "brand": "Chevrolet", "models": ["Alero", "Aveo", "Camaro", "Captiva", "Corvette", "Cruze", "Cruze SW", "Epica", "Equinox", "Evanda", "HHR", "Kalos", "Lacetti", "Lacetti SW", "Lumina", "Malibu", "Matiz", "Monte Carlo", "Nubira", "Orlando", "Spark", "Suburban", "Tacuma", "Tahoe", "Trax"]},
  {
    "brand": "Porsche", "models": ["911 Carrera", "911 Carrera Cabrio", "911 Targa", "911 Turbo", "924", "944", "997", "Boxster", "Cayenne", "Cayman", "Macan", "Panamera"]},
  {
    "brand": "Honda", "models": ["Accord", "Accord Coupé", "Accord Tourer", "City", "Civic", "Civic Aerodeck", "Civic Coupé", "Civic Tourer", "Civic Type R", "CR-V", "CR-X", "CR-Z", "FR-V", "HR-V", "Insight", "Integra", "Jazz", "Legend", "Prelude"]},
  {
    "brand": "Subaru", "models": ["BRZ", "Forester", "Impreza", "Impreza Wagon", "Justy", "Legacy", "Legacy Wagon", "Legacy Outback", "Levorg", "Outback", "SVX", "Tribeca", "Tribeca B9", "XV"]},
  {
    "brand": "Mazda", "models": ["121", "2", "3", "323", "323 Combi", "323 Coupé", "323 F", "5", "6", "6 Combi", "626", "626 Combi", "B-Fighter", "B2500", "BT", "CX-3", "CX-5", "CX-7", "CX-9", "Demio", "MPV", "MX-3", "MX-5", "MX-6", "Premacy", "RX-7", "RX-8", "Xedox 6"]},
  {
    "brand": "Mitsubishi", "models": ["3000 GT", "ASX", "Carisma", "Colt", "Colt CC", "Eclipse", "Fuso canter", "Galant", "Galant Combi", "Grandis", "L200", "L200 Pick up", "L200 Pick up Allrad", "L300", "Lancer", "Lancer Combi", "Lancer Evo", "Lancer Sportback", "Outlander", "Pajero", "Pajeto Pinin", "Pajero Pinin Wagon", "Pajero Sport", "Pajero Wagon", "Space Star"]},
  {
    "brand": "Lexus", "models": ["CT", "GS", "GS 300", "GX", "IS", "IS 200", "IS 250 C", "IS-F", "LS", "LX", "NX", "RC F", "RX", "RX 300", "RX 400h", "RX 450h", "SC 430"]},
  {
    "brand": "Toyota", "models": ["4-Runner", "Auris", "Avensis", "Avensis Combi", "Avensis Van Verso", "Aygo", "Camry", "Carina", "Celica", "Corolla", "Corolla Combi", "Corolla sedan", "Corolla Verso", "FJ Cruiser", "GT86", "Hiace", "Hiace Van", "Highlander", "Hilux", "Land Cruiser", "MR2", "Paseo", "Picnic", "Prius", "RAV4", "Sequoia", "Starlet", "Supra", "Tundra", "Urban Cruiser", "Verso", "Yaris", "Yaris Verso"]},
  {
    "brand": "BMW", "models": ["i3", "i8", "M3", "M4", "M5", "M6", "Rad 1", "Rad 1 Cabrio", "Rad 1 Coupé", "Rad 2", "Rad 2 Active Tourer", "Rad 2 Coupé", "Rad 2 Gran Tourer", "Rad 3", "Rad 3 Cabrio", "Rad 3 Compact", "Rad 3 Coupé", "Rad 3 GT", "Rad 3 Touring", "Rad 4", "Rad 4 Cabrio", "Rad 4 Gran Coupé", "Rad 5", "Rad 5 GT", "Rad 5 Touring", "Rad 6", "Rad 6 Cabrio", "Rad 6 Coupé", "Rad 6 Gran Coupé", "Rad 7", "Rad 8 Coupé", "X1", "X3", "X4", "X5", "X6", "Z3", "Z3 Coupé", "Z3 Roadster", "Z4", "Z4 Roadster"]},
  {
    "brand": "Volkswagen", "models": ["Amarok", "Beetle", "Bora", "Bora Variant", "Caddy", "Caddy Van", "Life", "California", "Caravelle", "CC", "Crafter", "Crafter Van", "Crafter Kombi", "CrossTouran", "Eos", "Fox", "Golf", "Golf Cabrio", "Golf Plus", "Golf Sportvan", "Golf Variant", "Jetta", "LT", "Lupo", "Multivan", "New Beetle", "New Beetle Cabrio", "Passat", "Passat Alltrack", "Passat CC", "Passat Variant", "Passat Variant Van", "Phaeton", "Polo", "Polo Van", "Polo Variant", "Scirocco", "Sharan", "T4", "T4 Caravelle", "T4 Multivan", "T5", "T5 Caravelle", "T5 Multivan", "T5 Transporter Shuttle", "Tiguan", "Touareg", "Touran"]},
  {
    "brand": "Suzuki", "models": ["Alto", "Baleno", "Baleno kombi", "Grand Vitara", "Grand Vitara XL-7", "Ignis", "Jimny", "Kizashi", "Liana", "Samurai", "Splash", "Swift", "SX4", "SX4 Sedan", "Vitara", "Wagon R+"]},
  {
    "brand": "Mercedes-Benz", "models": ["100 D", "115", "124", "126", "190", "190 D", "190 E", "200 - 300", "200 D", "200 E", "210 Van", "210 kombi", "310 Van", "310 kombi", "230 - 300 CE Coupé", "260 - 560 SE", "260 - 560 SEL", "500 - 600 SEC Coupé", "Trieda A", "A", "A L", "AMG GT", "Trieda B", "Trieda C", "C", "C Sportcoupé", "C T", "Citan", "CL", "CL", "CLA", "CLC", "CLK Cabrio", "CLK Coupé", "CLS", "Trieda E", "E", "E Cabrio", "E Coupé", "E T", "Trieda G", "G Cabrio", "GL", "GLA", "GLC", "GLE", "GLK", "Trieda M", "MB 100", "Trieda R", "Trieda S", "S", "S Coupé", "SL", "SLC", "SLK", "SLR", "Sprinter"]},
  {
    "brand": "Saab", "models": ["9-3", "9-3 Cabriolet", "9-3 Coupé", "9-3 SportCombi", "9-5", "9-5 SportCombi", "900", "900 C", "900 C Turbo", "9000"]},
  {
    "brand": "Audi", "models": ["100", "100 Avant", "80", "80 Avant", "80 Cabrio", "90", "A1", "A2", "A3", "A3 Cabriolet", "A3 Limuzina", "A3 Sportback", "A4", "A4 Allroad", "A4 Avant", "A4 Cabriolet", "A5", "A5 Cabriolet", "A5 Sportback", "A6", "A6 Allroad", "A6 Avant", "A7", "A8", "A8 Long", "Q3", "Q5", "Q7", "R8", "RS4 Cabriolet", "RS4/RS4 Avant", "RS5", "RS6 Avant", "RS7", "S3/S3 Sportback", "S4 Cabriolet", "S4/S4 Avant", "S5/S5 Cabriolet", "S6/RS6", "S7", "S8", "SQ5", "TT Coupé", "TT Roadster", "TTS"]},
  {
    "brand": "Kia", "models": ["Avella", "Besta", "Carens", "Carnival", "Cee`d", "Cee`d SW", "Cerato", "K 2500", "Magentis", "Opirus", "Optima", "Picanto", "Pregio", "Pride", "Pro Cee`d", "Rio", "Rio Combi", "Rio sedan", "Sephia", "Shuma", "Sorento", "Soul", "Sportage", "Venga"]},
  {
    "brand": "Land Rover", "models": ["109", "Defender", "Discovery", "Discovery Sport", "Freelander", "Range Rover", "Range Rover Evoque", "Range Rover Sport"]},
  {
    "brand": "Dodge", "models": ["Avenger", "Caliber", "Challenger", "Charger", "Grand Caravan", "Journey", "Magnum", "Nitro", "RAM", "Stealth", "Viper"]},
  {
    "brand": "Chrysler", "models": ["300 C", "300 C Touring", "300 M", "Crossfire", "Grand Voyager", "LHS", "Neon", "Pacifica", "Plymouth", "PT Cruiser", "Sebring", "Sebring Convertible", "Stratus", "Stratus Cabrio", "Town & Country", "Voyager"]},
  {
    "brand": "Ford", "models": ["Aerostar", "B-Max", "C-Max", "Cortina", "Cougar", "Edge", "Escort", "Escort Cabrio", "Escort kombi", "Explorer", "F-150", "F-250", "Fiesta", "Focus", "Focus C-Max", "Focus CC", "Focus kombi", "Fusion", "Galaxy", "Grand C-Max", "Ka", "Kuga", "Maverick", "Mondeo", "Mondeo Combi", "Mustang", "Orion", "Puma", "Ranger", "S-Max", "Sierra", "Street Ka", "Tourneo Connect", "Tourneo Custom", "Transit", "Transit", "Transit Bus", "Transit Connect LWB", "Transit Courier", "Transit Custom", "Transit kombi", "Transit Tourneo", "Transit Valnik", "Transit Van", "Transit Van 350", "Windstar"]},
  {
    "brand": "Hummer", "models": ["H2", "H3"]},
  {
    "brand": "Hyundai", "models": ["Accent", "Atos", "Atos Prime", "Coupé", "Elantra", "Galloper", "Genesis", "Getz", "Grandeur", "H 350", "H1", "H1 Bus", "H1 Van", "H200", "i10", "i20", "i30", "i30 CW", "i40", "i40 CW", "ix20", "ix35", "ix55", "Lantra", "Matrix", "Santa Fe", "Sonata", "Terracan", "Trajet", "Tucson", "Veloster"]},
  {
    "brand": "Infiniti", "models": ["EX", "FX", "G", "G Coupé", "M", "Q", "QX"]},
  {
    "brand": "Jaguar", "models": ["Daimler", "F-Pace", "F-Type", "S-Type", "Sovereign", "X-Type", "X-type Estate", "XE", "XF", "XJ", "XJ12", "XJ6", "XJ8", "XJ8", "XJR", "XK", "XK8 Convertible", "XKR", "XKR Convertible"]},
  {
    "brand": "Jeep", "models": ["Cherokee", "Commander", "Compass", "Grand Cherokee", "Patriot", "Renegade", "Wrangler"]},
  {
    "brand": "Nissan", "models": ["100 NX", "200 SX", "350 Z", "350 Z Roadster", "370 Z", "Almera", "Almera Tino", "Cabstar E - T", "Cabstar TL2 Valnik", "e-NV200", "GT-R", "Insterstar", "Juke", "King Cab", "Leaf", "Maxima", "Maxima QX", "Micra", "Murano", "Navara", "Note", "NP300 Pickup", "NV200", "NV400", "Pathfinder", "Patrol", "Patrol GR", "Pickup", "Pixo", "Primastar", "Primastar Combi", "Primera", "Primera Combi", "Pulsar", "Qashqai", "Serena", "Sunny", "Terrano", "Tiida", "Trade", "Vanette Cargo", "X-Trail"]},
  {
    "brand": "Volvo", "models": ["240", "340", "360", "460", "850", "850 kombi", "C30", "C70", "C70 Cabrio", "C70 Coupé", "S40", "S60", "S70", "S80", "S90", "V40", "V50", "V60", "V70", "V90", "XC60", "XC70", "XC90"]},
  {
    "brand": "Daewoo",
    "models": ["Espero", "Kalos", "Lacetti", "Lanos", "Leganza", "Lublin", "Matiz", "Nexia", "Nubira", "Nubira kombi", "Racer", "Tacuma", "Tico"]},
  {
    "brand": "Fiat",
    "models": ["1100", "126", "500", "500L", "500X", "850", "Barchetta", "Brava", "Cinquecento", "Coupé", "Croma", "Doblo", "Doblo Cargo", "Doblo Cargo Combi", "Ducato", "Ducato Van", "Ducato Kombi", "Ducato Podvozok", "Florino", "Florino Combi", "Freemont", "Grande Punto", "Idea", "Linea", "Marea", "Marea Weekend", "Multipla", "Palio Weekend", "Panda", "Panda Van", "Punto", "Punto Cabriolet", "Punto Evo", "Punto Van", "Qubo", "Scudo", "Scudo Van", "Scudo Kombi", "Sedici", "Seicento", "Stilo", "Stilo Multiwagon", "Strada", "Talento", "Tipo", "Ulysse", "Uno", "X1/9"]},
  {
    "brand": "MINI",
    "models": ["Cooper", "Cooper Cabrio", "Cooper Clubman", "Cooper D", "Cooper D Clubman", "Cooper S", "Cooper S Cabrio", "Cooper S Clubman", "Countryman", "Mini One", "One D"]},
  {
    "brand": "Rover",
    "models": ["200", "214", "218", "25", "400", "414", "416", "620", "75"]},
  {
    "brand": "Smart",
    "models": ["Cabrio", "City-Coupé", "Compact Pulse", "Forfour", "Fortwo cabrio", "Fortwo coupé", "Roadster"]}
];

export const DEVICES = [
  {label: "iOS", value: "ios"},
  {label: "Android", value: "android"}
];

export const TIME_RANGE = [
  {label: `00:00`, value: { h: 0, m: 0 }},
  {label: `00:15`, value: { h: 0, m: 15 }},
  {label: `00:30`, value: { h: 0, m: 30 }},
  {label: `00:45`, value: { h: 0, m: 45 }},

  {label: `01:00`, value: { h: 1, m: 0 }},
  {label: `01:15`, value: { h: 1, m: 15 }},
  {label: `01:30`, value: { h: 1, m: 30 }},
  {label: `01:45`, value: { h: 1, m: 45 }},

  {label: `02:00`, value: { h: 2, m: 0 }},
  {label: `02:15`, value: { h: 2, m: 15 }},
  {label: `02:30`, value: { h: 2, m: 30 }},
  {label: `02:45`, value: { h: 2, m: 45 }},

  {label: `03:00`, value: { h: 3, m: 0 }},
  {label: `03:15`, value: { h: 3, m: 15 }},
  {label: `03:30`, value: { h: 3, m: 30 }},
  {label: `03:45`, value: { h: 3, m: 45 }},

  {label: `04:00`, value: { h: 4, m: 0 }},
  {label: `04:15`, value: { h: 4, m: 15 }},
  {label: `04:30`, value: { h: 4, m: 30 }},
  {label: `04:45`, value: { h: 4, m: 45 }},

  {label: `05:00`, value: { h: 5, m: 0 }},
  {label: `05:15`, value: { h: 5, m: 15 }},
  {label: `05:30`, value: { h: 5, m: 30 }},
  {label: `05:45`, value: { h: 5, m: 45 }},

  {label: `06:00`, value: { h: 6, m: 0 }},
  {label: `06:15`, value: { h: 6, m: 15 }},
  {label: `06:30`, value: { h: 6, m: 30 }},
  {label: `06:45`, value: { h: 6, m: 45 }},

  {label: `07:00`, value: { h: 7, m: 0 }},
  {label: `07:15`, value: { h: 7, m: 15 }},
  {label: `07:30`, value: { h: 7, m: 30 }},
  {label: `07:45`, value: { h: 7, m: 45 }},

  {label: `08:00`, value: { h: 8, m: 0 }},
  {label: `08:15`, value: { h: 8, m: 15 }},
  {label: `08:30`, value: { h: 8, m: 30 }},
  {label: `08:45`, value: { h: 8, m: 45 }},

  {label: `09:00`, value: { h: 9, m: 0 }},
  {label: `09:15`, value: { h: 9, m: 15 }},
  {label: `09:30`, value: { h: 9, m: 30 }},
  {label: `09:45`, value: { h: 9, m: 45 }},

  {label: `10:00`, value: { h:10, m: 0 }},
  {label: `10:15`, value: { h:10, m: 15 }},
  {label: `10:30`, value: { h:10, m: 30 }},
  {label: `10:45`, value: { h:10, m: 45 }},

  {label: `11:00`, value: { h:11, m: 0 }},
  {label: `11:15`, value: { h:11, m: 15 }},
  {label: `11:30`, value: { h:11, m: 30 }},
  {label: `11:45`, value: { h:11, m: 45 }},

  {label: `12:00`, value: { h:12, m: 0 }},
  {label: `12:15`, value: { h:12, m: 15 }},
  {label: `12:30`, value: { h:12, m: 30 }},
  {label: `12:45`, value: { h:12, m: 45 }},

  {label: `13:00`, value: { h:13, m: 0 }},
  {label: `13:15`, value: { h:13, m: 15 }},
  {label: `13:30`, value: { h:13, m: 30 }},
  {label: `13:45`, value: { h:13, m: 45 }},

  {label: `14:00`, value: { h:14, m: 0 }},
  {label: `14:15`, value: { h:14, m: 15 }},
  {label: `14:30`, value: { h:14, m: 30 }},
  {label: `14:45`, value: { h:14, m: 45 }},

  {label: `15:00`, value: { h:15, m: 0 }},
  {label: `15:15`, value: { h:15, m: 15 }},
  {label: `15:30`, value: { h:15, m: 30 }},
  {label: `15:45`, value: { h:15, m: 45 }},

  {label: `16:00`, value: { h:16, m: 0 }},
  {label: `16:15`, value: { h:16, m: 15 }},
  {label: `16:30`, value: { h:16, m: 30 }},
  {label: `16:45`, value: { h:16, m: 45 }},

  {label: `17:00`, value: { h:17, m: 0 }},
  {label: `17:15`, value: { h:17, m: 15 }},
  {label: `17:30`, value: { h:17, m: 30 }},
  {label: `17:45`, value: { h:17, m: 45 }},

  {label: `18:00`, value: { h:18, m: 0 }},
  {label: `18:15`, value: { h:18, m: 15 }},
  {label: `18:30`, value: { h:18, m: 30 }},
  {label: `18:45`, value: { h:18, m: 45 }},

  {label: `19:00`, value: { h:19, m: 0 }},
  {label: `19:15`, value: { h:19, m: 15 }},
  {label: `19:30`, value: { h:19, m: 30 }},
  {label: `19:45`, value: { h:19, m: 45 }},

  {label: `20:00`, value: { h:20, m: 0 }},
  {label: `20:15`, value: { h:20, m: 15 }},
  {label: `20:30`, value: { h:20, m: 30 }},
  {label: `20:45`, value: { h:20, m: 45 }},

  {label: `21:00`, value: { h:21, m: 0 }},
  {label: `21:15`, value: { h:21, m: 15 }},
  {label: `21:30`, value: { h:21, m: 30 }},
  {label: `21:45`, value: { h:21, m: 45 }},

  {label: `22:00`, value: { h:22, m: 0 }},
  {label: `22:15`, value: { h:22, m: 15 }},
  {label: `22:30`, value: { h:22, m: 30 }},
  {label: `22:45`, value: { h:22, m: 45 }},

  {label: `23:00`, value: { h:23, m: 0 }},
  {label: `23:15`, value: { h:23, m: 15 }},
  {label: `23:30`, value: { h:23, m: 30 }},
  {label: `23:59`, value: { h:23, m: 59 }},
];

export const TIME_RANGE_US = [
  {label: `12:00 am`, value: { h: 0, m: 0 }},
  {label: `12:15 am`, value: { h: 0, m: 15 }},
  {label: `12:30 am`, value: { h: 0, m: 30 }},
  {label: `12:45 am`, value: { h: 0, m: 45 }},

  {label: `1:00 am`, value: { h: 1, m: 0 }},
  {label: `1:15 am`, value: { h: 1, m: 15 }},
  {label: `1:30 am`, value: { h: 1, m: 30 }},
  {label: `1:45 am`, value: { h: 1, m: 45 }},

  {label: `2:00 am`, value: { h: 2, m: 0 }},
  {label: `2:15 am`, value: { h: 2, m: 15 }},
  {label: `2:30 am`, value: { h: 2, m: 30 }},
  {label: `2:45 am`, value: { h: 2, m: 45 }},

  {label: `3:00 am`, value: { h: 3, m: 0 }},
  {label: `3:15 am`, value: { h: 3, m: 15 }},
  {label: `3:30 am`, value: { h: 3, m: 30 }},
  {label: `3:45 am`, value: { h: 3, m: 45 }},

  {label: `4:00 am`, value: { h: 4, m: 0 }},
  {label: `4:15 am`, value: { h: 4, m: 15 }},
  {label: `4:30 am`, value: { h: 4, m: 30 }},
  {label: `4:45 am`, value: { h: 4, m: 45 }},

  {label: `5:00 am`, value: { h: 5, m: 0 }},
  {label: `5:15 am`, value: { h: 5, m: 15 }},
  {label: `5:30 am`, value: { h: 5, m: 30 }},
  {label: `5:45 am`, value: { h: 5, m: 45 }},

  {label: `6:00 am`, value: { h: 6, m: 0 }},
  {label: `6:15 am`, value: { h: 6, m: 15 }},
  {label: `6:30 am`, value: { h: 6, m: 30 }},
  {label: `6:45 am`, value: { h: 6, m: 45 }},

  {label: `7:00 am`, value: { h: 7, m: 0 }},
  {label: `7:15 am`, value: { h: 7, m: 15 }},
  {label: `7:30 am`, value: { h: 7, m: 30 }},
  {label: `7:45 am`, value: { h: 7, m: 45 }},

  {label: `8:00 am`, value: { h: 8, m: 0 }},
  {label: `8:15 am`, value: { h: 8, m: 15 }},
  {label: `8:30 am`, value: { h: 8, m: 30 }},
  {label: `8:45 am`, value: { h: 8, m: 45 }},

  {label: `9:00 am`, value: { h: 9, m: 0 }},
  {label: `9:15 am`, value: { h: 9, m: 15 }},
  {label: `9:30 am`, value: { h: 9, m: 30 }},
  {label: `9:45 am`, value: { h: 9, m: 45 }},

  {label: `10:00 am`, value: { h:10, m: 0 }},
  {label: `10:15 am`, value: { h:10, m: 15 }},
  {label: `10:30 am`, value: { h:10, m: 30 }},
  {label: `10:45 am`, value: { h:10, m: 45 }},

  {label: `11:00 am`, value: { h:11, m: 0 }},
  {label: `11:15 am`, value: { h:11, m: 15 }},
  {label: `11:30 am`, value: { h:11, m: 30 }},
  {label: `11:45 am`, value: { h:11, m: 45 }},

  {label: `12:00 pm`, value: { h:12, m: 0 }},
  {label: `12:15 pm`, value: { h:12, m: 15 }},
  {label: `12:30 pm`, value: { h:12, m: 30 }},
  {label: `12:45 pm`, value: { h:12, m: 45 }},

  {label: `1:00 pm`, value: { h:13, m: 0 }},
  {label: `1:15 pm`, value: { h:13, m: 15 }},
  {label: `1:30 pm`, value: { h:13, m: 30 }},
  {label: `1:45 pm`, value: { h:13, m: 45 }},

  {label: `2:00 pm`, value: { h:14, m: 0 }},
  {label: `2:15 pm`, value: { h:14, m: 15 }},
  {label: `2:30 pm`, value: { h:14, m: 30 }},
  {label: `2:45 pm`, value: { h:14, m: 45 }},

  {label: `3:00 pm`, value: { h:15, m: 0 }},
  {label: `3:15 pm`, value: { h:15, m: 15 }},
  {label: `3:30 pm`, value: { h:15, m: 30 }},
  {label: `3:45 pm`, value: { h:15, m: 45 }},

  {label: `4:00 pm`, value: { h:16, m: 0 }},
  {label: `4:15 pm`, value: { h:16, m: 15 }},
  {label: `4:30 pm`, value: { h:16, m: 30 }},
  {label: `4:45 pm`, value: { h:16, m: 45 }},

  {label: `5:00 pm`, value: { h:17, m: 0 }},
  {label: `5:15 pm`, value: { h:17, m: 15 }},
  {label: `5:30 pm`, value: { h:17, m: 30 }},
  {label: `5:45 pm`, value: { h:17, m: 45 }},

  {label: `6:00 pm`, value: { h:18, m: 0 }},
  {label: `6:15 pm`, value: { h:18, m: 15 }},
  {label: `6:30 pm`, value: { h:18, m: 30 }},
  {label: `6:45 pm`, value: { h:18, m: 45 }},

  {label: `7:00 pm`, value: { h:19, m: 0 }},
  {label: `7:15 pm`, value: { h:19, m: 15 }},
  {label: `7:30 pm`, value: { h:19, m: 30 }},
  {label: `7:45 pm`, value: { h:19, m: 45 }},

  {label: `8:00 pm`, value: { h:20, m: 0 }},
  {label: `8:15 pm`, value: { h:20, m: 15 }},
  {label: `8:30 pm`, value: { h:20, m: 30 }},
  {label: `8:45 pm`, value: { h:20, m: 45 }},

  {label: `9:00 pm`, value: { h:21, m: 0 }},
  {label: `9:15 pm`, value: { h:21, m: 15 }},
  {label: `9:30 pm`, value: { h:21, m: 30 }},
  {label: `9:45 pm`, value: { h:21, m: 45 }},

  {label: `10:00 pm`, value: { h:22, m: 0 }},
  {label: `10:15 pm`, value: { h:22, m: 15 }},
  {label: `10:30 pm`, value: { h:22, m: 30 }},
  {label: `10:45 pm`, value: { h:22, m: 45 }},

  {label: `11:00 pm`, value: { h:23, m: 0 }},
  {label: `11:15 pm`, value: { h:23, m: 15 }},
  {label: `11:30 pm`, value: { h:23, m: 30 }},
  {label: `11:59 pm`, value: { h:23, m: 59 }},
];

export const TIME_RANGE_HOURS = [
  {label: `00:00`, value: { h: 0, m: 0 }},
  {label: `01:00`, value: { h: 1, m: 0 }},
  {label: `02:00`, value: { h: 2, m: 0 }},
  {label: `03:00`, value: { h: 3, m: 0 }},
  {label: `04:00`, value: { h: 4, m: 0 }},
  {label: `05:00`, value: { h: 5, m: 0 }},
  {label: `06:00`, value: { h: 6, m: 0 }},
  {label: `07:00`, value: { h: 7, m: 0 }},
  {label: `08:00`, value: { h: 8, m: 0 }},
  {label: `09:00`, value: { h: 9, m: 0 }},
  {label: `10:00`, value: { h:10, m: 0 }},
  {label: `11:00`, value: { h:11, m: 0 }},
  {label: `12:00`, value: { h:12, m: 0 }},
  {label: `13:00`, value: { h:13, m: 0 }},
  {label: `14:00`, value: { h:14, m: 0 }},
  {label: `15:00`, value: { h:15, m: 0 }},
  {label: `16:00`, value: { h:16, m: 0 }},
  {label: `17:00`, value: { h:17, m: 0 }},
  {label: `18:00`, value: { h:18, m: 0 }},
  {label: `19:00`, value: { h:19, m: 0 }},
  {label: `20:00`, value: { h:20, m: 0 }},
  {label: `21:00`, value: { h:21, m: 0 }},
  {label: `22:00`, value: { h:22, m: 0 }},
  {label: `23:00`, value: { h:23, m: 0 }},
  {label: `23:59`, value: { h:23, m: 59 }},
];

export const TIME_RANGE_HOURS_US = [
  {label: `12:00 am`, value: { h: 0, m: 0 }},
  {label: `1:00 am`, value: { h: 1, m: 0 }},
  {label: `2:00 am`, value: { h: 2, m: 0 }},
  {label: `3:00 am`, value: { h: 3, m: 0 }},
  {label: `4:00 am`, value: { h: 4, m: 0 }},
  {label: `5:00 am`, value: { h: 5, m: 0 }},
  {label: `6:00 am`, value: { h: 6, m: 0 }},
  {label: `7:00 am`, value: { h: 7, m: 0 }},
  {label: `8:00 am`, value: { h: 8, m: 0 }},
  {label: `9:00 am`, value: { h: 9, m: 0 }},
  {label: `10:00 am`, value: { h:10, m: 0 }},
  {label: `11:00 am`, value: { h:11, m: 0 }},
  {label: `12:00 pm`, value: { h:12, m: 0 }},
  {label: `1:00 pm`, value: { h:13, m: 0 }},
  {label: `2:00 pm`, value: { h:14, m: 0 }},
  {label: `3:00 pm`, value: { h:15, m: 0 }},
  {label: `4:00 pm`, value: { h:16, m: 0 }},
  {label: `5:00 pm`, value: { h:17, m: 0 }},
  {label: `6:00 pm`, value: { h:18, m: 0 }},
  {label: `7:00 pm`, value: { h:19, m: 0 }},
  {label: `8:00 pm`, value: { h:20, m: 0 }},
  {label: `9:00 pm`, value: { h:21, m: 0 }},
  {label: `10:00 pm`, value: { h:22, m: 0 }},
  {label: `11:00 pm`, value: { h:23, m: 0 }},
  {label: `11:59 pm`, value: { h:23, m: 59 }},
];

export const LIMITS = [
  {label: "25", value: 25},
  {label: "50", value: 50},
  {label: "100", value: 100},
  {label: "200", value: 200},
];

export const PHONE_NUMBER_CODES = [
  {
  "name": "Afghanistan",
  "flag": "🇦🇫",
  "code": "AF",
  "dial_code": "+93"
}, {"name": "Åland Islands", "flag": "🇦🇽", "code": "AX", "dial_code": "+358"}, {
  "name": "Albania",
  "flag": "🇦🇱",
  "code": "AL",
  "dial_code": "+355"
}, {"name": "Algeria", "flag": "🇩🇿", "code": "DZ", "dial_code": "+213"}, {
  "name": "American Samoa",
  "flag": "🇦🇸",
  "code": "AS",
  "dial_code": "+1684"
}, {"name": "Andorra", "flag": "🇦🇩", "code": "AD", "dial_code": "+376"}, {
  "name": "Angola",
  "flag": "🇦🇴",
  "code": "AO",
  "dial_code": "+244"
}, {"name": "Anguilla", "flag": "🇦🇮", "code": "AI", "dial_code": "+1264"}, {
  "name": "Antarctica",
  "flag": "🇦🇶",
  "code": "AQ",
  "dial_code": "+672"
}, {"name": "Antigua and Barbuda", "flag": "🇦🇬", "code": "AG", "dial_code": "+1268"}, {
  "name": "Argentina",
  "flag": "🇦🇷",
  "code": "AR",
  "dial_code": "+54"
}, {"name": "Armenia", "flag": "🇦🇲", "code": "AM", "dial_code": "+374"}, {
  "name": "Aruba",
  "flag": "🇦🇼",
  "code": "AW",
  "dial_code": "+297"
}, {"name": "Australia", "flag": "🇦🇺", "code": "AU", "dial_code": "+61"}, {
  "name": "Austria",
  "flag": "🇦🇹",
  "code": "AT",
  "dial_code": "+43"
}, {"name": "Azerbaijan", "flag": "🇦🇿", "code": "AZ", "dial_code": "+994"}, {
  "name": "Bahamas",
  "flag": "🇧🇸",
  "code": "BS",
  "dial_code": "+1242"
}, {"name": "Bahrain", "flag": "🇧🇭", "code": "BH", "dial_code": "+973"}, {
  "name": "Bangladesh",
  "flag": "🇧🇩",
  "code": "BD",
  "dial_code": "+880"
}, {"name": "Barbados", "flag": "🇧🇧", "code": "BB", "dial_code": "+1246"}, {
  "name": "Belarus",
  "flag": "🇧🇾",
  "code": "BY",
  "dial_code": "+375"
}, {"name": "Belgium", "flag": "🇧🇪", "code": "BE", "dial_code": "+32"}, {
  "name": "Belize",
  "flag": "🇧🇿",
  "code": "BZ",
  "dial_code": "+501"
}, {"name": "Benin", "flag": "🇧🇯", "code": "BJ", "dial_code": "+229"}, {
  "name": "Bermuda",
  "flag": "🇧🇲",
  "code": "BM",
  "dial_code": "+1441"
}, {
  "name": "Bhutan",
  "flag": "🇧🇹",
  "code": "BT",
  "dial_code": "+975"
}, {
  "name": "Bolivia, Plurinational State of bolivia",
  "flag": "🇧🇴",
  "code": "BO",
  "dial_code": "+591"
}, {"name": "Bosnia and Herzegovina", "flag": "🇧🇦", "code": "BA", "dial_code": "+387"}, {
  "name": "Botswana",
  "flag": "🇧🇼",
  "code": "BW",
  "dial_code": "+267"
}, {"name": "Bouvet Island", "flag": "🇧🇻", "code": "BV", "dial_code": "+47"}, {
  "name": "Brazil",
  "flag": "🇧🇷",
  "code": "BR",
  "dial_code": "+55"
}, {
  "name": "British Indian Ocean Territory",
  "flag": "🇮🇴",
  "code": "IO",
  "dial_code": "+246"
}, {"name": "Brunei Darussalam", "flag": "🇧🇳", "code": "BN", "dial_code": "+673"}, {
  "name": "Bulgaria",
  "flag": "🇧🇬",
  "code": "BG",
  "dial_code": "+359"
}, {"name": "Burkina Faso", "flag": "🇧🇫", "code": "BF", "dial_code": "+226"}, {
  "name": "Burundi",
  "flag": "🇧🇮",
  "code": "BI",
  "dial_code": "+257"
}, {"name": "Cambodia", "flag": "🇰🇭", "code": "KH", "dial_code": "+855"}, {
  "name": "Cameroon",
  "flag": "🇨🇲",
  "code": "CM",
  "dial_code": "+237"
}, {"name": "Canada", "flag": "🇨🇦", "code": "CA", "dial_code": "+1"}, {
  "name": "Cape Verde",
  "flag": "🇨🇻",
  "code": "CV",
  "dial_code": "+238"
}, {"name": "Cayman Islands", "flag": "🇰🇾", "code": "KY", "dial_code": "+345"}, {
  "name": "Central African Republic",
  "flag": "🇨🇫",
  "code": "CF",
  "dial_code": "+236"
}, {"name": "Chad", "flag": "🇹🇩", "code": "TD", "dial_code": "+235"}, {
  "name": "Chile",
  "flag": "🇨🇱",
  "code": "CL",
  "dial_code": "+56"
}, {"name": "China", "flag": "🇨🇳", "code": "CN", "dial_code": "+86"}, {
  "name": "Christmas Island",
  "flag": "🇨🇽",
  "code": "CX",
  "dial_code": "+61"
}, {"name": "Cocos (Keeling) Islands", "flag": "🇨🇨", "code": "CC", "dial_code": "+61"}, {
  "name": "Colombia",
  "flag": "🇨🇴",
  "code": "CO",
  "dial_code": "+57"
}, {"name": "Comoros", "flag": "🇰🇲", "code": "KM", "dial_code": "+269"}, {
  "name": "Congo",
  "flag": "🇨🇬",
  "code": "CG",
  "dial_code": "+242"
}, {
  "name": "Congo, The Democratic Republic of the Congo",
  "flag": "🇨🇩",
  "code": "CD",
  "dial_code": "+243"
}, {"name": "Cook Islands", "flag": "🇨🇰", "code": "CK", "dial_code": "+682"}, {
  "name": "Costa Rica",
  "flag": "🇨🇷",
  "code": "CR",
  "dial_code": "+506"
}, {"name": "Cote d'Ivoire", "flag": "🇨🇮", "code": "CI", "dial_code": "+225"}, {
  "name": "Croatia",
  "flag": "🇭🇷",
  "code": "HR",
  "dial_code": "+385"
}, {"name": "Cuba", "flag": "🇨🇺", "code": "CU", "dial_code": "+53"}, {
  "name": "Cyprus",
  "flag": "🇨🇾",
  "code": "CY",
  "dial_code": "+357"
}, {"name": "Czech Republic", "flag": "🇨🇿", "code": "CZ", "dial_code": "+420"}, {
  "name": "Denmark",
  "flag": "🇩🇰",
  "code": "DK",
  "dial_code": "+45"
}, {"name": "Djibouti", "flag": "🇩🇯", "code": "DJ", "dial_code": "+253"}, {
  "name": "Dominica",
  "flag": "🇩🇲",
  "code": "DM",
  "dial_code": "+1767"
}, {"name": "Dominican Republic", "flag": "🇩🇴", "code": "DO", "dial_code": "+1849"}, {
  "name": "Ecuador",
  "flag": "🇪🇨",
  "code": "EC",
  "dial_code": "+593"
}, {"name": "Egypt", "flag": "🇪🇬", "code": "EG", "dial_code": "+20"}, {
  "name": "El Salvador",
  "flag": "🇸🇻",
  "code": "SV",
  "dial_code": "+503"
}, {"name": "Equatorial Guinea", "flag": "🇬🇶", "code": "GQ", "dial_code": "+240"}, {
  "name": "Eritrea",
  "flag": "🇪🇷",
  "code": "ER",
  "dial_code": "+291"
}, {"name": "Estonia", "flag": "🇪🇪", "code": "EE", "dial_code": "+372"}, {
  "name": "Ethiopia",
  "flag": "🇪🇹",
  "code": "ET",
  "dial_code": "+251"
}, {"name": "Falkland Islands (Malvinas)", "flag": "🇫🇰", "code": "FK", "dial_code": "+500"}, {
  "name": "Faroe Islands",
  "flag": "🇫🇴",
  "code": "FO",
  "dial_code": "+298"
}, {"name": "Fiji", "flag": "🇫🇯", "code": "FJ", "dial_code": "+679"}, {
  "name": "Finland",
  "flag": "🇫🇮",
  "code": "FI",
  "dial_code": "+358"
}, {"name": "France", "flag": "🇫🇷", "code": "FR", "dial_code": "+33"}, {
  "name": "French Guiana",
  "flag": "🇬🇫",
  "code": "GF",
  "dial_code": "+594"
}, {
  "name": "French Polynesia",
  "flag": "🇵🇫",
  "code": "PF",
  "dial_code": "+689"
}, {"name": "French Southern Territories", "flag": "🇹🇫", "code": "TF", "dial_code": "+262"}, {
  "name": "Gabon",
  "flag": "🇬🇦",
  "code": "GA",
  "dial_code": "+241"
}, {"name": "Gambia", "flag": "🇬🇲", "code": "GM", "dial_code": "+220"}, {
  "name": "Georgia",
  "flag": "🇬🇪",
  "code": "GE",
  "dial_code": "+995"
}, {"name": "Germany", "flag": "🇩🇪", "code": "DE", "dial_code": "+49"}, {
  "name": "Ghana",
  "flag": "🇬🇭",
  "code": "GH",
  "dial_code": "+233"
}, {"name": "Gibraltar", "flag": "🇬🇮", "code": "GI", "dial_code": "+350"}, {
  "name": "Greece",
  "flag": "🇬🇷",
  "code": "GR",
  "dial_code": "+30"
}, {"name": "Greenland", "flag": "🇬🇱", "code": "GL", "dial_code": "+299"}, {
  "name": "Grenada",
  "flag": "🇬🇩",
  "code": "GD",
  "dial_code": "+1473"
}, {"name": "Guadeloupe", "flag": "🇬🇵", "code": "GP", "dial_code": "+590"}, {
  "name": "Guam",
  "flag": "🇬🇺",
  "code": "GU",
  "dial_code": "+1671"
}, {"name": "Guatemala", "flag": "🇬🇹", "code": "GT", "dial_code": "+502"}, {
  "name": "Guernsey",
  "flag": "🇬🇬",
  "code": "GG",
  "dial_code": "+44"
}, {"name": "Guinea", "flag": "🇬🇳", "code": "GN", "dial_code": "+224"}, {
  "name": "Guinea-Bissau",
  "flag": "🇬🇼",
  "code": "GW",
  "dial_code": "+245"
}, {"name": "Guyana", "flag": "🇬🇾", "code": "GY", "dial_code": "+592"}, {
  "name": "Haiti",
  "flag": "🇭🇹",
  "code": "HT",
  "dial_code": "+509"
}, {
  "name": "Heard Island and Mcdonald Islands",
  "flag": "🇭🇲",
  "code": "HM",
  "dial_code": "+672"
}, {"name": "Holy See (Vatican City State)", "flag": "🇻🇦", "code": "VA", "dial_code": "+379"}, {
  "name": "Honduras",
  "flag": "🇭🇳",
  "code": "HN",
  "dial_code": "+504"
}, {"name": "Hong Kong", "flag": "🇭🇰", "code": "HK", "dial_code": "+852"}, {
  "name": "Hungary",
  "flag": "🇭🇺",
  "code": "HU",
  "dial_code": "+36"
}, {"name": "Iceland", "flag": "🇮🇸", "code": "IS", "dial_code": "+354"}, {
  "name": "India",
  "flag": "🇮🇳",
  "code": "IN",
  "dial_code": "+91"
}, {
  "name": "Indonesia",
  "flag": "🇮🇩",
  "code": "ID",
  "dial_code": "+62"
}, {"name": "Iran, Islamic Republic of Persian Gulf", "flag": "🇮🇷", "code": "IR", "dial_code": "+98"}, {
  "name": "Iraq",
  "flag": "🇮🇶",
  "code": "IQ",
  "dial_code": "+964"
}, {"name": "Ireland", "flag": "🇮🇪", "code": "IE", "dial_code": "+353"}, {
  "name": "Isle of Man",
  "flag": "🇮🇲",
  "code": "IM",
  "dial_code": "+44"
}, {"name": "Israel", "flag": "🇮🇱", "code": "IL", "dial_code": "+972"}, {
  "name": "Italy",
  "flag": "🇮🇹",
  "code": "IT",
  "dial_code": "+39"
}, {"name": "Jamaica", "flag": "🇯🇲", "code": "JM", "dial_code": "+1876"}, {
  "name": "Japan",
  "flag": "🇯🇵",
  "code": "JP",
  "dial_code": "+81"
}, {"name": "Jersey", "flag": "🇯🇪", "code": "JE", "dial_code": "+44"}, {
  "name": "Jordan",
  "flag": "🇯🇴",
  "code": "JO",
  "dial_code": "+962"
}, {"name": "Kazakhstan", "flag": "🇰🇿", "code": "KZ", "dial_code": "+7"}, {
  "name": "Kenya",
  "flag": "🇰🇪",
  "code": "KE",
  "dial_code": "+254"
}, {
  "name": "Kiribati",
  "flag": "🇰🇮",
  "code": "KI",
  "dial_code": "+686"
}, {
  "name": "Korea, Democratic People's Republic of Korea",
  "flag": "🇰🇵",
  "code": "KP",
  "dial_code": "+850"
}, {"name": "Korea, Republic of South Korea", "flag": "🇰🇷", "code": "KR", "dial_code": "+82"}, {
  "name": "Kosovo",
  "flag": "🇽🇰",
  "code": "XK",
  "dial_code": "+383"
}, {"name": "Kuwait", "flag": "🇰🇼", "code": "KW", "dial_code": "+965"}, {
  "name": "Kyrgyzstan",
  "flag": "🇰🇬",
  "code": "KG",
  "dial_code": "+996"
}, {"name": "Laos", "flag": "🇱🇦", "code": "LA", "dial_code": "+856"}, {
  "name": "Latvia",
  "flag": "🇱🇻",
  "code": "LV",
  "dial_code": "+371"
}, {"name": "Lebanon", "flag": "🇱🇧", "code": "LB", "dial_code": "+961"}, {
  "name": "Lesotho",
  "flag": "🇱🇸",
  "code": "LS",
  "dial_code": "+266"
}, {"name": "Liberia", "flag": "🇱🇷", "code": "LR", "dial_code": "+231"}, {
  "name": "Libyan Arab Jamahiriya",
  "flag": "🇱🇾",
  "code": "LY",
  "dial_code": "+218"
}, {"name": "Liechtenstein", "flag": "🇱🇮", "code": "LI", "dial_code": "+423"}, {
  "name": "Lithuania",
  "flag": "🇱🇹",
  "code": "LT",
  "dial_code": "+370"
}, {"name": "Luxembourg", "flag": "🇱🇺", "code": "LU", "dial_code": "+352"}, {
  "name": "Macao",
  "flag": "🇲🇴",
  "code": "MO",
  "dial_code": "+853"
}, {"name": "Macedonia", "flag": "🇲🇰", "code": "MK", "dial_code": "+389"}, {
  "name": "Madagascar",
  "flag": "🇲🇬",
  "code": "MG",
  "dial_code": "+261"
}, {"name": "Malawi", "flag": "🇲🇼", "code": "MW", "dial_code": "+265"}, {
  "name": "Malaysia",
  "flag": "🇲🇾",
  "code": "MY",
  "dial_code": "+60"
}, {"name": "Maldives", "flag": "🇲🇻", "code": "MV", "dial_code": "+960"}, {
  "name": "Mali",
  "flag": "🇲🇱",
  "code": "ML",
  "dial_code": "+223"
}, {"name": "Malta", "flag": "🇲🇹", "code": "MT", "dial_code": "+356"}, {
  "name": "Marshall Islands",
  "flag": "🇲🇭",
  "code": "MH",
  "dial_code": "+692"
}, {"name": "Martinique", "flag": "🇲🇶", "code": "MQ", "dial_code": "+596"}, {
  "name": "Mauritania",
  "flag": "🇲🇷",
  "code": "MR",
  "dial_code": "+222"
}, {"name": "Mauritius", "flag": "🇲🇺", "code": "MU", "dial_code": "+230"}, {
  "name": "Mayotte",
  "flag": "🇾🇹",
  "code": "YT",
  "dial_code": "+262"
}, {
  "name": "Mexico",
  "flag": "🇲🇽",
  "code": "MX",
  "dial_code": "+52"
}, {
  "name": "Micronesia, Federated States of Micronesia",
  "flag": "🇫🇲",
  "code": "FM",
  "dial_code": "+691"
}, {"name": "Moldova", "flag": "🇲🇩", "code": "MD", "dial_code": "+373"}, {
  "name": "Monaco",
  "flag": "🇲🇨",
  "code": "MC",
  "dial_code": "+377"
}, {"name": "Mongolia", "flag": "🇲🇳", "code": "MN", "dial_code": "+976"}, {
  "name": "Montenegro",
  "flag": "🇲🇪",
  "code": "ME",
  "dial_code": "+382"
}, {"name": "Montserrat", "flag": "🇲🇸", "code": "MS", "dial_code": "+1664"}, {
  "name": "Morocco",
  "flag": "🇲🇦",
  "code": "MA",
  "dial_code": "+212"
}, {"name": "Mozambique", "flag": "🇲🇿", "code": "MZ", "dial_code": "+258"}, {
  "name": "Myanmar",
  "flag": "🇲🇲",
  "code": "MM",
  "dial_code": "+95"
}, {"name": "Namibia", "flag": "🇳🇦", "code": "NA", "dial_code": "+264"}, {
  "name": "Nauru",
  "flag": "🇳🇷",
  "code": "NR",
  "dial_code": "+674"
}, {"name": "Nepal", "flag": "🇳🇵", "code": "NP", "dial_code": "+977"}, {
  "name": "Netherlands",
  "flag": "🇳🇱",
  "code": "NL",
  "dial_code": "+31"
}, {"name": "Netherlands Antilles", "flag": "", "code": "AN", "dial_code": "+599"}, {
  "name": "New Caledonia",
  "flag": "🇳🇨",
  "code": "NC",
  "dial_code": "+687"
}, {"name": "New Zealand", "flag": "🇳🇿", "code": "NZ", "dial_code": "+64"}, {
  "name": "Nicaragua",
  "flag": "🇳🇮",
  "code": "NI",
  "dial_code": "+505"
}, {"name": "Niger", "flag": "🇳🇪", "code": "NE", "dial_code": "+227"}, {
  "name": "Nigeria",
  "flag": "🇳🇬",
  "code": "NG",
  "dial_code": "+234"
}, {"name": "Niue", "flag": "🇳🇺", "code": "NU", "dial_code": "+683"}, {
  "name": "Norfolk Island",
  "flag": "🇳🇫",
  "code": "NF",
  "dial_code": "+672"
}, {"name": "Northern Mariana Islands", "flag": "🇲🇵", "code": "MP", "dial_code": "+1670"}, {
  "name": "Norway",
  "flag": "🇳🇴",
  "code": "NO",
  "dial_code": "+47"
}, {"name": "Oman", "flag": "🇴🇲", "code": "OM", "dial_code": "+968"}, {
  "name": "Pakistan",
  "flag": "🇵🇰",
  "code": "PK",
  "dial_code": "+92"
}, {"name": "Palau", "flag": "🇵🇼", "code": "PW", "dial_code": "+680"}, {
  "name": "Palestinian Territory, Occupied",
  "flag": "🇵🇸",
  "code": "PS",
  "dial_code": "+970"
}, {"name": "Panama", "flag": "🇵🇦", "code": "PA", "dial_code": "+507"}, {
  "name": "Papua New Guinea",
  "flag": "🇵🇬",
  "code": "PG",
  "dial_code": "+675"
}, {"name": "Paraguay", "flag": "🇵🇾", "code": "PY", "dial_code": "+595"}, {
  "name": "Peru",
  "flag": "🇵🇪",
  "code": "PE",
  "dial_code": "+51"
}, {"name": "Philippines", "flag": "🇵🇭", "code": "PH", "dial_code": "+63"}, {
  "name": "Pitcairn",
  "flag": "🇵🇳",
  "code": "PN",
  "dial_code": "+64"
}, {"name": "Poland", "flag": "🇵🇱", "code": "PL", "dial_code": "+48"}, {
  "name": "Portugal",
  "flag": "🇵🇹",
  "code": "PT",
  "dial_code": "+351"
}, {"name": "Puerto Rico", "flag": "🇵🇷", "code": "PR", "dial_code": "+1939"}, {
  "name": "Qatar",
  "flag": "🇶🇦",
  "code": "QA",
  "dial_code": "+974"
}, {"name": "Romania", "flag": "🇷🇴", "code": "RO", "dial_code": "+40"}, {
  "name": "Russia",
  "flag": "🇷🇺",
  "code": "RU",
  "dial_code": "+7"
}, {"name": "Rwanda", "flag": "🇷🇼", "code": "RW", "dial_code": "+250"}, {
  "name": "Reunion",
  "flag": "🇷🇪",
  "code": "RE",
  "dial_code": "+262"
}, {
  "name": "Saint Barthelemy",
  "flag": "🇧🇱",
  "code": "BL",
  "dial_code": "+590"
}, {
  "name": "Saint Helena, Ascension and Tristan Da Cunha",
  "flag": "🇸🇭",
  "code": "SH",
  "dial_code": "+290"
}, {"name": "Saint Kitts and Nevis", "flag": "🇰🇳", "code": "KN", "dial_code": "+1869"}, {
  "name": "Saint Lucia",
  "flag": "🇱🇨",
  "code": "LC",
  "dial_code": "+1758"
}, {"name": "Saint Martin", "flag": "🇲🇫", "code": "MF", "dial_code": "+590"}, {
  "name": "Saint Pierre and Miquelon",
  "flag": "🇵🇲",
  "code": "PM",
  "dial_code": "+508"
}, {"name": "Saint Vincent and the Grenadines", "flag": "🇻🇨", "code": "VC", "dial_code": "+1784"}, {
  "name": "Samoa",
  "flag": "🇼🇸",
  "code": "WS",
  "dial_code": "+685"
}, {"name": "San Marino", "flag": "🇸🇲", "code": "SM", "dial_code": "+378"}, {
  "name": "Sao Tome and Principe",
  "flag": "🇸🇹",
  "code": "ST",
  "dial_code": "+239"
}, {"name": "Saudi Arabia", "flag": "🇸🇦", "code": "SA", "dial_code": "+966"}, {
  "name": "Senegal",
  "flag": "🇸🇳",
  "code": "SN",
  "dial_code": "+221"
}, {"name": "Serbia", "flag": "🇷🇸", "code": "RS", "dial_code": "+381"}, {
  "name": "Seychelles",
  "flag": "🇸🇨",
  "code": "SC",
  "dial_code": "+248"
}, {"name": "Sierra Leone", "flag": "🇸🇱", "code": "SL", "dial_code": "+232"}, {
  "name": "Singapore",
  "flag": "🇸🇬",
  "code": "SG",
  "dial_code": "+65"
}, {"name": "Slovakia", "flag": "🇸🇰", "code": "SK", "dial_code": "+421"}, {
  "name": "Slovenia",
  "flag": "🇸🇮",
  "code": "SI",
  "dial_code": "+386"
}, {"name": "Solomon Islands", "flag": "🇸🇧", "code": "SB", "dial_code": "+677"}, {
  "name": "Somalia",
  "flag": "🇸🇴",
  "code": "SO",
  "dial_code": "+252"
}, {"name": "South Africa", "flag": "🇿🇦", "code": "ZA", "dial_code": "+27"}, {
  "name": "South Sudan",
  "flag": "🇸🇸",
  "code": "SS",
  "dial_code": "+211"
}, {
  "name": "South Georgia and the South Sandwich Islands",
  "flag": "🇬🇸",
  "code": "GS",
  "dial_code": "+500"
}, {"name": "Spain", "flag": "🇪🇸", "code": "ES", "dial_code": "+34"}, {
  "name": "Sri Lanka",
  "flag": "🇱🇰",
  "code": "LK",
  "dial_code": "+94"
}, {"name": "Sudan", "flag": "🇸🇩", "code": "SD", "dial_code": "+249"}, {
  "name": "Suriname",
  "flag": "🇸🇷",
  "code": "SR",
  "dial_code": "+597"
}, {"name": "Svalbard and Jan Mayen", "flag": "🇸🇯", "code": "SJ", "dial_code": "+47"}, {
  "name": "Eswatini",
  "flag": "🇸🇿",
  "code": "SZ",
  "dial_code": "+268"
}, {"name": "Sweden", "flag": "🇸🇪", "code": "SE", "dial_code": "+46"}, {
  "name": "Switzerland",
  "flag": "🇨🇭",
  "code": "CH",
  "dial_code": "+41"
}, {"name": "Syrian Arab Republic", "flag": "🇸🇾", "code": "SY", "dial_code": "+963"}, {
  "name": "Taiwan",
  "flag": "🇹🇼",
  "code": "TW",
  "dial_code": "+886"
}, {
  "name": "Tajikistan",
  "flag": "🇹🇯",
  "code": "TJ",
  "dial_code": "+992"
}, {
  "name": "Tanzania, United Republic of Tanzania",
  "flag": "🇹🇿",
  "code": "TZ",
  "dial_code": "+255"
}, {"name": "Thailand", "flag": "🇹🇭", "code": "TH", "dial_code": "+66"}, {
  "name": "Timor-Leste",
  "flag": "🇹🇱",
  "code": "TL",
  "dial_code": "+670"
}, {"name": "Togo", "flag": "🇹🇬", "code": "TG", "dial_code": "+228"}, {
  "name": "Tokelau",
  "flag": "🇹🇰",
  "code": "TK",
  "dial_code": "+690"
}, {"name": "Tonga", "flag": "🇹🇴", "code": "TO", "dial_code": "+676"}, {
  "name": "Trinidad and Tobago",
  "flag": "🇹🇹",
  "code": "TT",
  "dial_code": "+1868"
}, {"name": "Tunisia", "flag": "🇹🇳", "code": "TN", "dial_code": "+216"}, {
  "name": "Turkey",
  "flag": "🇹🇷",
  "code": "TR",
  "dial_code": "+90"
}, {"name": "Turkmenistan", "flag": "🇹🇲", "code": "TM", "dial_code": "+993"}, {
  "name": "Turks and Caicos Islands",
  "flag": "🇹🇨",
  "code": "TC",
  "dial_code": "+1649"
}, {"name": "Tuvalu", "flag": "🇹🇻", "code": "TV", "dial_code": "+688"}, {
  "name": "Uganda",
  "flag": "🇺🇬",
  "code": "UG",
  "dial_code": "+256"
}, {"name": "Ukraine", "flag": "🇺🇦", "code": "UA", "dial_code": "+380"}, {
  "name": "United Arab Emirates",
  "flag": "🇦🇪",
  "code": "AE",
  "dial_code": "+971"
}, {"name": "United Kingdom", "flag": "🇬🇧", "code": "GB", "dial_code": "+44"}, {
  "name": "United States",
  "flag": "🇺🇸",
  "code": "US",
  "dial_code": "+1"
}, {"name": "Uruguay", "flag": "🇺🇾", "code": "UY", "dial_code": "+598"}, {
  "name": "Uzbekistan",
  "flag": "🇺🇿",
  "code": "UZ",
  "dial_code": "+998"
}, {
  "name": "Vanuatu",
  "flag": "🇻🇺",
  "code": "VU",
  "dial_code": "+678"
}, {
  "name": "Venezuela, Bolivarian Republic of Venezuela",
  "flag": "🇻🇪",
  "code": "VE",
  "dial_code": "+58"
}, {"name": "Vietnam", "flag": "🇻🇳", "code": "VN", "dial_code": "+84"}, {
  "name": "Virgin Islands, British",
  "flag": "🇻🇬",
  "code": "VG",
  "dial_code": "+1284"
}, {"name": "Virgin Islands, U.S.", "flag": "🇻🇮", "code": "VI", "dial_code": "+1340"}, {
  "name": "Wallis and Futuna",
  "flag": "🇼🇫",
  "code": "WF",
  "dial_code": "+681"
}, {"name": "Yemen", "flag": "🇾🇪", "code": "YE", "dial_code": "+967"}, {
  "name": "Zambia",
  "flag": "🇿🇲",
  "code": "ZM",
  "dial_code": "+260"
}, {"name": "Zimbabwe", "flag": "🇿🇼", "code": "ZW", "dial_code": "+263"}];
