import Geocode from 'react-geocode';
import api from './api';
import {errToString} from "./index";
import {OpenStreetMapProvider} from 'leaflet-geosearch';

const provider = new OpenStreetMapProvider();

export const searchAddress = (region, address, callback, options = { type: 'default', apiKey: null }) => {
  if (options.type === 'open_street') {
    provider
        .search({
          query: address
        })
        .then(result => {
          const addresses = result.map(({ label, raw: { place_id } }) => ({
            value: place_id,
            label
          }));
          return callback(null, addresses);
        })
        .catch(err => {
          console.error(err);
        });
  }
  if (options.type === 'google') {
    Geocode.setApiKey(options.apiKey);
    Geocode.setLanguage('en');

    api
        .post('/places', {
          region,
          address,
          apiKey: options.apiKey
        })
        .then(({ data: { predictions } }) => {
          const promises = [];
          for (const prediction of predictions) {
            promises.push(new Promise(resolve => {
              return resolve({
                value: prediction.place_id,
                label: prediction.description
              });
            }));
          }

          Promise.all(promises).then(addresses => callback(null, addresses));
        })
        .catch(err => {
          console.log(errToString(err))
          return callback(err);
        });
  }
  if (options.type === 'default') {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
    Geocode.setLanguage('en');

    api
    .post('/places', {
        region,
        address,
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY
    })
    .then(({ data: { predictions } }) => {
        const promises = [];
        for (const prediction of predictions) {
            promises.push(new Promise(resolve => {
                return resolve({
                    value: prediction.place_id,
                    label: prediction.description
                });
            }));
        }

        Promise.all(promises).then(addresses => callback(null, addresses));
    })
    .catch(err => {
        console.log(errToString(err))
        return callback(err);
    });
  }
};

export const getPlaceDetails = (placeId, callback) => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
    Geocode.setLanguage('en');

    api
        .get(`/places?placeId=${placeId}&apiKey=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`)
        .then(({ data: { countryCode, country, city, street } }) => callback({ countryCode, country, city, street }))
        .catch(err => {
            console.log(errToString(err))
            return callback(err);
        });
};

export const getLatLngFromAddress = (address, callback, options = { type: 'default', apiKey: null }) => {
  if (options.type === 'open_street') {
    provider
        .search({
          query: address
        })
        .then(result => {
          return callback({
            lat: result[0].y,
            lng: result[0].x
          });
        })
        .catch(err => {
          console.error(err);
        });
  }
  if (options.type === 'google') {
    Geocode.setApiKey(options.apiKey);
    Geocode.setLanguage('en');

    Geocode.fromAddress(address).then(response => {
      const { lat, lng } = response.results[0].geometry.location;
      const countryCode = response.results[0].address_components.find(({ types }) => types.includes('country'))?.short_name?.toLowerCase();
      return callback({ lat, lng }, countryCode);
    }, err => {
      console.error(err);
    });
  }
  if (options.type === 'default') {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
    Geocode.setLanguage('en');

    Geocode.fromAddress(address).then(response => {
      const { lat, lng } = response.results[0].geometry.location;
      const countryCode = response.results[0].address_components.find(({ types }) => types.includes('country'))?.short_name?.toLowerCase();
      return callback({ lat, lng }, countryCode);
    }, err => {
      console.error(err);
    });
  }
};

export const getAddressFromLatLng = (lat, lng, callback, options = { type: 'default', apiKey: null }) => {
  if (options.type === 'open_street') {
    provider
      .search({
        query: `${lat} ${lng}`
      })
      .then(result => {
        return callback({
          value: result[0].raw.place_id,
          label: result[0].label,
          location: { lat: result[0].y, lng: result[0].x }
        });
      })
      .catch(err => {
        console.error(err);
      });
  }
  if (options.type === 'google') {
    Geocode.setApiKey(options.apiKey);
    Geocode.setLanguage('en');

    Geocode.fromLatLng(lat, lng).then(response => {
      return callback({
        value: response.results[0]?.place_id,
        label: response.results[0]?.formatted_address,
        location: { lat: Number(lat), lng: Number(lng) }
      });
    }, err => {
      console.error(err);
    });
  }
  if (options.type === 'default') {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
    Geocode.setLanguage('en');

    Geocode.fromLatLng(lat, lng).then(response => {
      return callback({
        value: response.results[0]?.place_id,
        label: response.results[0]?.formatted_address,
        location: { lat: Number(lat), lng: Number(lng) }
      });
    }, err => {
      console.error(err);
    });
  }
};
