import './index.scss';
import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Map from '../map';
import api from '../../utils/api';
import Loading from '../ui/loading';
import {ORDER_MARKERS} from '../../constants/markers';
import {Stroke, Style} from 'ol/style';
import LineString from 'ol/geom/LineString';
import {Feature} from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {useTranslation} from "react-i18next";
import {getCenterFromLatLngOpenStreet, getZoomFromLatLngOpenStreet} from '../../utils/map';
import {FaRegStopCircle} from "react-icons/fa";
import {FaPlayCircle} from "react-icons/fa"
import cn from "classnames";
import {errToString} from "../../utils";
import {MdDirectionsBike} from "react-icons/md";
import {AiOutlineCar} from "react-icons/ai";
import {HiOutlineLocationMarker} from "react-icons/hi";

function TimelineMarker({
mapIntegration,
agentId,
agentSrc,
order
}) {
  const {t} = useTranslation();
  const [map, setMap] = useState(null);
  const [locations, setLocations] = useState(null);
  const [agentMarker, setAgentMarker] = useState(null);
  const [timeoutIds, setTimeoutIds] = useState(null);
  const [btnPressed, setBtnPressed] = useState(null)

  const markers = useMemo(() => {
    if (!locations) {
      return [];
    }

    const startLocation = locations[0];
    const endLocation = locations[locations.length - 1];
    const markers = [
      {
        id: 'start',
        type: 'start',
        position: startLocation,
        icon: ORDER_MARKERS.started,
        content: `
          <div class="marker">${t("components.timeline_marker.startLocation")}</div>
        `,
        open: true,
        onClick: () => null
      },
      {
        id: 'end',
        type: 'end',
        position: endLocation,
        icon: ORDER_MARKERS.delivered,
        content: `
          <div class="marker">${t("components.timeline_marker.endLocation")}</div>
        `,
        open: true,
        onClick: () => null
      }
    ];

    if (agentMarker) {
      markers.push(agentMarker);
    }

    return markers;
  }, [t, locations, agentMarker]);
  const center = useMemo(() => {
      if (!markers.length) {
        return;
      }
      const start = markers.find(marker => marker.id === 'start');
      const end = markers.find(marker => marker.id === 'end');

      if (start.position === undefined || end.position === undefined) {
        return;
      }
      return getCenterFromLatLngOpenStreet(start.position.lat, start.position.lng, end.position.lat, end.position.lng);
  }, [markers]);
  const zoom = useMemo(() => {
    if (!markers.length) {
      return;
    }

    const start = markers.find(marker => marker.id === 'start');
    const end = markers.find(marker => marker.id === 'end');

    if (start.position === undefined || end.position === undefined) {
      return;
    }

    return getZoomFromLatLngOpenStreet(start.position.lat, start.position.lng, end.position.lat, end.position.lng);
  }, [markers]);

  const onStop = useCallback(() => {
    setBtnPressed("stop");

    timeoutIds?.forEach(timeoutId => clearTimeout(timeoutId));
    setAgentMarker(null);
  }, [timeoutIds]);
  const onPlay = useCallback(async () => {
    if (!locations.length) {
      return;
    }
    setBtnPressed("play");

    const timeoutIds = [];

    for (let i = 0; i <= locations.length; i++) {
      const location = locations[i];
      const timeoutId = setTimeout(() => {
        setAgentMarker({
          id: 'agent',
          type: 'agent',
          position: location,
          // icon: AGENT_MARKERS.online,
          img: agentSrc,
          imgSize: [40, 40],
          status: 'online',
          onClick: () => null,
          zIndex: 1,
          disableAnimation: true
        });

        if (i === locations.length) {
          setAgentMarker(null);
          setBtnPressed(null);
        }
      }, 150 * i);

      timeoutIds.push(timeoutId);
    }

    setTimeoutIds(timeoutIds);
  }, [locations, agentSrc]);

  useEffect(() => {
    if (!order) {
      return;
    }

    api
      .get(`/employees/agents/${agentId}/locations?orderId=${order.id}`)
      .then(({ data }) => setLocations(data))
      .catch(err => console.log(errToString(err)));
  }, [agentId, order]);
  useEffect(() => {
    if (!map || !locations) {
      return;
    }

    if (mapIntegration.value === 'open_street') {
      const paths = locations.map(({ lat, lng }) => [lng, lat]);
      const styles = {
        'route': new Style({
          stroke: new Stroke({
            width: 7,
            color: 'rgba(131,63,165,0.7)'
          }),
        })
      }
      const route = new LineString(paths).transform('EPSG:4326', 'EPSG:3857');
      const routeFeature = new Feature({
        type: 'route',
        geometry: route,
      });
      const path = new VectorLayer({
        source: new VectorSource({
          features: [routeFeature]
        }),
        style: function (feature) {
          return styles[feature.get('type')];
        }
      });
      map.addLayer(path);
    }
    if (mapIntegration.value === 'google') {
      const steps = new window.google.maps.Polyline({
        path: locations,
        geodesic: true,
        strokeColor: 'rgba(131,63,165,0.7)',
        strokeWeight: 3,
      });
      steps.setMap(map);
    }
  }, [mapIntegration.value, map, locations]);

  return (
    <div className="timeline_marker">
      {!locations && (
        <Loading />
      )}
      {locations && locations.length !== 0 && (
          <div className="timeline_marker_in">
            <div className="tracking_link_map_footer tracking_link_map_footer--animate-up">
              <div className="tracking_link_map_footer_main">
                <div className="timeline_marker_in_actions">
                  <button
                    className={cn("timeline_marker_btn timeline_marker_btn--play", {"timeline_marker_btn--active" : btnPressed === "play"})}
                    onClick={onPlay}
                  ><FaPlayCircle/></button>
                  <button
                    className={cn("timeline_marker_btn timeline_marker_btn--stop", {"timeline_marker_btn--active" : btnPressed === "stop"})}
                    onClick={onStop}
                  ><FaRegStopCircle/></button>
                </div>
                {order.agent.vehicleType === "bike" && (
                  <p className="tracking_link_map_footer_car">
                    <MdDirectionsBike />
                    {t(`pages.trackingLink.bike`)}
                  </p>
                )}
                {order.agent.vehicleType !== "bike" && (
                  <p className="tracking_link_map_footer_car">
                    <AiOutlineCar />
                    {order.agent.carBrand} {order.agent.carModel}
                    <span>{order.agent.carPlate}</span>
                  </p>
                )}
                <img
                  src={agentSrc}
                  alt="Agent"
                />
                <div className="tracking_link_map_footer_driver">
                  <p className="tracking_link_map_footer_driver_name">
                    {order.agent.firstName} {order.agent.lastName}
                  </p>
                </div>
              </div>
              <div className="tracking_link_map_footer_order">
                <div className="tracking_link_map_footer_order_in">
                  <div className="tracking_link_map_footer_order_in_icon tracking_link_map_footer_order_in_icon--pin">
                    <HiOutlineLocationMarker />
                  </div>
                  <div className="tracking_link_map_footer_order_in_details">
                    <span>{t(`pages.trackingLink.deliveryAddress`)}</span>
                    <p> {order.address.deliveryAddress}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )}
      {center && zoom && (
        <Map
          type={mapIntegration.value}
          zoom={zoom}
          center={center}
          setMap={setMap}
          map={map}
          markers={markers}
        />
      )}
      {locations?.length === 0 && (
        <p>{t("components.timeline_marker.noLocation")} #{order.externalId}</p>
      )}
    </div>
  );
}

TimelineMarker.propTypes = {
  mapIntegration: PropTypes.object.isRequired,
  agentId: PropTypes.number.isRequired,
  agentSrc: PropTypes.string.isRequired,
  order: PropTypes.object,
  onClose: PropTypes.func
};

export default memo(TimelineMarker);
