import { memo} from "react";
import "./index.scss";
import cn from "classnames";
import Loading from "../loading";

function AnalyticsInfo({loading, count, title, status, className }) {
    return (
        <div className={cn('analytics_info', className)}>
            <div className={cn("analytics_info_in", `analytics_info_in--${status}`)}>
              <div className="analytics_info__count">{!loading ? <p>{count}</p> : <Loading/>}</div>
              <p className="analytics_info__title">{title}</p>
            </div>
        </div>
    );
}

export default memo(AnalyticsInfo);
