import { memo } from "react";
import "./index.scss";
import cn from "classnames";
import { BsFillCalendar2CheckFill } from "react-icons/bs";


function ActionBtn({title, btnIcon = false, icon, onClick, large = false, small = true, theme = 'default'}) {
    return (
        <div
          className={cn("action_btn", {'action_btn_refresh' : theme === 'refresh'})}
          onClick={onClick}
        >
            {icon}
            {title && (
              <div className="action_btn_wrapper">
                  <button className={cn("", {"action_btn--lg" : large, "action_btn--sm" : small})}>{title}</button>
                  {btnIcon && (
                    <BsFillCalendar2CheckFill/>
                  )}
              </div>
            )}
        </div>
    );
}

export default memo(ActionBtn);
