import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../localizations/en.json'
import am from '../localizations/am.json'

i18n
  .use(initReactI18next)
  .init({
    resources: { am, en },
    lng: 'am',
    interpolation: { escapeValue: false }
  });

export default i18n;