import {memo, useCallback, useContext, useEffect, useState} from "react";
import "./index.scss";
import {createPortal} from "react-dom";
import cn from "classnames";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import AuthContext from "../../../context/auth/auth-context";
import {RiCloseCircleFill} from "react-icons/ri";
import {setMomentJsLocale} from "../../../utils/moment";

function UserProfile({
 type,
 user,
 isProfileModalOpen,
 setIsProfileModalOpen
}) {
    const {i18n, t} = useTranslation();
    const authCtx = useContext(AuthContext);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const logOutHandler = useCallback(() => {
        localStorage.removeItem("token");
        window.location.reload();
        authCtx.logOut();
    }, [authCtx]);
    const onChangeLng = useCallback((lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("lng", lng);
        setMomentJsLocale(localStorage.getItem("lng"));
    }, [i18n]);

    useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {createPortal(
        <div
          className={cn("user_profile", {"user_profile--animate" : isProfileModalOpen})}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="user_profile_in">
            <div className="user_profile_header">
              <div className="user_profile_header__info">
                {windowWidth <= 600 && (
                  <div className="user_profile_header__info_close">
                    <RiCloseCircleFill onClick={() => setIsProfileModalOpen(false)}/>
                  </div>
                )}
                {type === 'agents' && (
                  <div className={cn("user_profile_header__info_status",
                    {"user_profile_header__info_status--online" : user.isOnline},
                    {"user_profile_header__info_status--offline": !user.isOnline}
                  )}
                  >{user.isOnline ? "Online" : "Offline"}</div>
                )}
                  <div className="user_profile_in_actions">
                      <div className="user_profile_in_lng">
                          <button
                              className={cn("user_profile_in_lng_btn", {"user_profile_in_lng_btn--active" : i18n.language === "am"})}
                              onClick={() => onChangeLng("am")}
                          >AM</button>
                          <button
                              className={cn("user_profile_in_lng_btn", {"user_profile_in_lng_btn--active" : i18n.language === "en"})}
                              onClick={() => onChangeLng("en")}
                          >EN</button>
                      </div>
                      <button
                          className="user_profile_in_logout"
                          onClick={logOutHandler}
                      >{t("pages.settings.logout")}</button>
                  </div>
              </div>
              <div className="user_profile_header__img">
                <img src={user.src} alt="user"/>
              </div>
              <div className="user_profile_header__data">
                <div className="user_profile_header__data_title">
                    {t("components.layout.user_profile.info")}
                </div>
                <span>{t("components.layout.user_profile.username")}</span>
                <p>{user.username}</p>
                <span>{t("components.layout.user_profile.firstName")}</span>
                <p>{user.firstName}</p>
                <span>{t("components.layout.user_profile.lastName")}</span>
                <p>{user.lastName}</p>
                <span>{t("components.layout.user_profile.phoneNumber")}</span>
                <p>{user.phoneNumber}</p>
                {user.email && (
                  <>
                    <span>{t("components.layout.user_profile.email")}</span>
                    <p>{user.email}</p>
                    <span>{t("components.layout.user_profile.emailVerified")}</span>
                    <p>{user.isEmailVerified ? t("components.layout.user_profile.yes") : t("components.layout.user_profile.no")}</p>
                  </>
                )}
                <span>{t("components.layout.user_profile.type")}</span>
                <p>{user.type}</p>
                {type === 'agents' && (
                  <>
                    <div className="user_profile_header__data_title">
                      {t("components.layout.user_profile.carDetails")}
                    </div>
                    <span>{t("components.layout.user_profile.car")}</span>
                    <p>{user.carBrand} {user.carModel}, {user.carPlate}</p>
                    <div className="user_profile_header__data_title">
                      {t("components.layout.user_profile.deviceDetails")}
                    </div>
                    {user.device && (
                      <>
                        <span>{t("components.layout.user_profile.device")}</span>
                        <p>{user.device}</p>
                      </>
                    )}
                    {user.deviceModel && (
                      <>
                        <span>{t("components.layout.user_profile.deviceModel")}</span>
                        <p>{user.deviceModel}</p>
                      </>
                    )}
                    {user.appVersion && (
                      <>
                        <span>{t("components.layout.user_profile.appVersion")}</span>
                        <p>{user.appVersion}</p>
                      </>
                    )}
                    {user.batteryLevel && (
                      <>
                        <span>{t("components.layout.user_profile.batteryLevel")}</span>
                        <p>{user.batteryLevel * 100}</p>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>, document.getElementById('modal')
      )}
      {isProfileModalOpen && createPortal(
        <div
          className="user_profile__overlay"
          onClick={() => {
            setIsProfileModalOpen(false);
          }}
        />, document.getElementById('modal')
      )}
    </>
  );
}

UserProfile.propTypes = {
  type: PropTypes.string,
  isProfileModalOpen: PropTypes.bool,
  setIsProfileModalOpen: PropTypes.func,
  user: PropTypes.object
};

export default memo(UserProfile);
