import "./index.scss";
import {memo, useCallback, useContext} from "react";
import {createPortal} from 'react-dom';
import PropTypes from "prop-types";
import api from "../../../../utils/api";
import sound from "../../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import UserCompanyBranchesContext from "../../../../context/user-company-branches/user-company-branches-context";
import {errToString} from "../../../../utils";

function DeleteBranchForm({
branch,
setIsDeleteFormOpen,
branchesData,
setBranchesData
}) {
  const {t} = useTranslation();
  const userCompanyBranches = useContext(UserCompanyBranchesContext);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    api
      .delete(`/company/address/${branch.id}`)
      .then(() => {
        setBranchesData(branchesData.map((data) => {
          if (branch.id === data.id) {
            return {
              ...data,
              deletedAt: new Date()
            }
          }
          return data;
        }));
        setIsDeleteFormOpen(false);
        const audio = new Audio(sound);
        const info = `${t("pages.branches.branch_card.delete_branch.branch")} ${branch.name} ${t("pages.branches.branch_card.delete_branch.hasBeenFrozen")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();

          api
              .get('/user-company-addresses')
              .then((res) => userCompanyBranches.set(res.data))
              .catch(err => console.log(errToString(err)));
      })
      .catch((err) => console.log(errToString(err)))
  // eslint-disable-next-line
  }, [t, branch, setIsDeleteFormOpen, setBranchesData, branchesData, userCompanyBranches.set]);

  return createPortal(
    <div className="delete_branch_form">
      <div
        className="agents_in_freeze_alert"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="agents_in_freeze_alert_title">{`${t("pages.branches.branch_card.delete_branch.branchDeleteWarning")} - ${branch.name}`}</div>
        <div className="agents_in_freeze_alert_actions">
          <button
            className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_cancel"
            onClick={() => setIsDeleteFormOpen(false)}
          >{t("pages.agents.cancel")}</button>
          <button
            className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_submit agents_in_freeze_alert_btn_submit--freeze"
            onClick={onSubmit}
          >{t("pages.agents.freeze")}</button>
        </div>
      </div>
      <div
        className="agents_in_freeze_alert_overlay"
        onClick={() => setIsDeleteFormOpen(false)}
      ></div>
    </div>, document.getElementById('modal')
  );
}

DeleteBranchForm.propTypes = {
  branch: PropTypes.object.isRequired,
  setIsDeleteFormOpen: PropTypes.func,
  setBranchesData: PropTypes.func,
  branchesData: PropTypes.array
}

export default memo(DeleteBranchForm);
