import "../index.scss";
import {memo, useCallback, useEffect, useState} from "react";
import {CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe} from "@stripe/react-stripe-js";
import VisaCardSVG from "../../../../assets/visa.svg";
import MasterCardSVG from "../../../../assets/mastercard.svg";
import AmexSVG from "../../../../assets/amex.svg";
import api from "../../../../utils/api";
import Overlay from "../../../../components/ui/overlay";
import {useTranslation} from "react-i18next";
import Loading from "../../../../components/ui/loading";
import StripeLogo from "../../../../assets/stripe.png"
import {errToString} from "../../../../utils";
import Logo from "../../../../assets/logo.png";
import {RiLockPasswordLine} from "react-icons/ri";
import {MdOutlineError} from "react-icons/md";

function AddCardForm({ onClose, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const {t} = useTranslation();
  const [errorMsg, setErrorMsg] = useState(null);

  const onAddCard = useCallback(async e => {
    e.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)
    });
    if (error) {
      setLoading(false);
      setErrorMsg(error.code)
      return console.log(error);
    }

    api
        .post('/subscriptions/method', {
          paymentMethodId: paymentMethod.id
        })
        .then(({ data }) => {
          if (typeof onSuccess === 'function') {
            return onSuccess();
          }

          setLoading(false);
          return window.location.reload();
        })
        .catch(err => {
          setLoading(false);
          console.log(errToString(err));
          setErrorMsg(errToString(err));
        });
  }, [stripe, elements, loading, onSuccess]);

  useEffect(() => {
    // Find the input element with the specified criteria
    const cvcInput = document.querySelector('.InputElement.is-empty.Input.Input--empty[data-elements-stable-field-name="cardCvc"]');

    // Set the type to "password" if the input element is found
    if (cvcInput) {
      cvcInput.type = 'password';
    }
  }, []);


  return (
    <>
      <form
        className="stripe_form"
        onSubmit={onAddCard}
      >
        <div className='stripe_form_logo'>
          <img src={Logo} alt="taskroad driver"/>
        </div>
        <div className="stripe_form_info stripe_form_info_margin_bottom">
          <span className="stripe_form_info_icon stripe_form_info_icon--warning">
            <MdOutlineError />
          </span>
          <p>{t("pages.billing.warning")}</p>
        </div>
        <div className="stripe_form_label">{t("pages.billing.cardDetails")}</div>
        <div className="stripe_form_input">
          <CardNumberElement
            options={{
              showIcon: true,
              style: {
                base: {
                  iconColor: '#000',
                  color: '#000',
                  fontWeight: 400,
                  fontFamily: 'inherit',
                  fontSize: '13px',
                  fontSmoothing: 'antialiased',
                  ':-webkit-autofill': {
                    color: '#000',
                  },
                  '::placeholder': {
                    color: '#666',
                  },
                },
                invalid: {
                  iconColor: 'red',
                  color: 'red',
                },
              }
            }}
          />
          <div className="stripe_form_input__icon">
            <img src={VisaCardSVG} alt="visa"/>
          </div>
          <div className="stripe_form_input__icon">
            <img src={MasterCardSVG} alt="visa"/>
          </div>
          <div className="stripe_form_input__icon">
            <img src={AmexSVG} alt="visa"/>
          </div>
        </div>
        <div className="stripe_form_wrapper">
          <div className="stripe_form_wrapper_in">
            <div className="stripe_form_label">{t("pages.billing.cardExpiration")}</div>
            <div className="stripe_form_input">
              <CardExpiryElement
                options={{
                  style: {
                    base: {
                      iconColor: '#000',
                      color: '#000',
                      fontWeight: 400,
                      fontFamily: 'inherit',
                      fontSize: '13px',
                      fontSmoothing: 'antialiased',
                      ':-webkit-autofill': {
                        color: '#000',
                      },
                      '::placeholder': {
                        color: '#666',
                      },
                    },
                    invalid: {
                      iconColor: 'red',
                      color: 'red',
                    },
                  }
                }}
              />
            </div>
          </div>
          <div className="stripe_form_wrapper_in">
            <div className="stripe_form_label">CVC</div>
            <div className="stripe_form_input">
              <CardCvcElement
                options={{
                  style: {
                    base: {
                      iconColor: '#000',
                      color: '#000',
                      fontWeight: 400,
                      fontFamily: 'inherit',
                      fontSize: '13px',
                      fontSmoothing: 'antialiased',
                      ':-webkit-autofill': {
                        color: '#000',
                      },
                      '::placeholder': {
                        color: '#666',
                      },
                    },
                    invalid: {
                      iconColor: 'red',
                      color: 'red',
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="stripe_form_info stripe_form_info_margin_top">
          <span className="stripe_form_info_icon stripe_form_info_icon--lock">
            <RiLockPasswordLine />
          </span>
          <p>{t("pages.billing.secured")}</p>
          <div className="stripe_form_info_wrapper">
            <span>Powered by</span>
            <div className="stripe_form_info_img">
              <img src={StripeLogo} alt="stripe"/>
            </div>
          </div>
        </div>
        <div className="stripe_form_error">
          {errorMsg && (
            <p><MdOutlineError /> {errorMsg}</p>
          )}
        </div>
        <div className="stripe_form_actions">
          <>
            <a
              href="!#"
              className="stripe_form_actions_cancel"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >{t("pages.agents.create_agent.cancel")}</a>
            {!loading && (
              <button className="stripe_form_actions_submit">
                {t("pages.billing.addCard")}
              </button>
            )}
            {loading && (
              <button className="stripe_form_actions_submit stripe_form_actions_submit--pending">
                <Loading />
              </button>
            )}
          </>
        </div>
      </form>
      <Overlay isTransparent={false} onClick={onClose}/>
    </>
  );
}

export default memo(AddCardForm);
