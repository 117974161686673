import "./index.scss";
import {memo, useEffect, useState, useMemo, useCallback, useContext} from "react";
import {useLocation} from "react-router-dom";
import api from "../../../utils/api";
import AnalyticsInfo from "../../../components/ui/analytics-info";
import Container from "../../../components/layout/container";
import Table from "../../../components/table";
import PageHeader from "../../../components/layout/page-header";
import moment from "moment";
import Status from "../../../components/ui/status";
import {LIMITS} from "../../../constants/drop-down-options";
import Pagination from "../../../components/ui/pagination";
import Search from "../../../components/search";
import DateTimePicker from "../../../components/ui/calendar/date-time-picker-double-calendar";
import {getCurrentDateWithoutHours} from '../../../utils/date';
import PreferencesContext from "../../../context/preferences/preferences-context";
import {useTranslation} from 'react-i18next';

const SEARCH_FIELDS_ORDERS = [
  {label: 'orderId', value: 'externalId'},
  {label: 'agent', value: 'agent'},
  {label: 'amount', value: 'amount'},
  {label: 'address', value: 'address'}
];

function CustomerDetails() {
    const {pathname} = useLocation();
    const customerId = pathname.split("/")[3];
    const {list} = useContext(PreferencesContext);
    const {t} = useTranslation();

    const searchFieldsOrders = useMemo(() => SEARCH_FIELDS_ORDERS.map(option => ({
      ...option,
      label: t(`pages.dashboard.orders.${option.label}`)
    })), [t]);
    const detailsOrdersTitles = useMemo(() => [
      {
        name: t("pages.customers.customer_details.id"),
      },
      {
        name: t("pages.customers.customer_details.agent"),
      },
      {
        name: t("pages.customers.customer_details.paymentMethod"),
      },
      {
        name: t("pages.customers.customer_details.amount"),
      },
      {
        name: t("pages.customers.customer_details.address"),
        size: 200
      },
      {
        name: t("pages.customers.customer_details.branch"),
        size: 200
      },
      {
        name: t("pages.customers.customer_details.created"),
      },
      {
        name: t("pages.customers.customer_details.status"),
      }
    ], [t]);

    const [currentPage, setCurrentPage] = useState(0);
    const [pagesCount, setPagesCount] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(LIMITS[0]);

    const [customerData, setCustomerData] = useState({})
    const [analyticsData, setAnalyticsData] = useState({})
    const [profileData, setProfileData] = useState({})
    const [customerDataLoading, setCustomerDataLoading] = useState(true)
    const [customerAnalyticsLoading, setCustomerAnalyticsLoading] = useState(true)

    const onChangeLimit = useCallback(option => {
      if (option.value === limit.value) {
        return;
      }

      setLimit(option);
      setOffset(0);
      setCurrentPage(0);
    }, [limit]);
    const [dateFields, setDateFields] = useState({
    startDate: getCurrentDateWithoutHours(),
      endDate: getCurrentDateWithoutHours(23, 59, 59),
  });
    const onSearch = useCallback(({ search, searchField }) => {
      const startDate = moment(dateFields.startDate).utc().format();
      const endDate = moment(dateFields.endDate).utc().format();

      setCustomerDataLoading(true);

      api
        .get(`/customers/${customerId}/orders?offset=${offset}&limit=${limit.value}&from=${startDate}&to=${endDate}${search && searchField ? `&search=${search}&searchField=${searchField}` : ''}`)
        .then((res) => {
          setPagesCount(res.data.pagesCount);
          setCustomerData(res.data);
          setCustomerDataLoading(false);
          setCustomerAnalyticsLoading(false);
        })
        .catch((err) => console.log(err));
  }, [offset, limit, customerId, dateFields])

    useEffect(() => {
      const startDate = moment(dateFields.startDate).utc().format();
      let endDate = moment(dateFields.endDate).utc().format();

      setCustomerDataLoading(true);
      setCustomerAnalyticsLoading(true);
      api
        .get(`/customers/${customerId}/orders?offset=${offset}&limit=${limit.value}&from=${startDate}&to=${endDate}`)
        .then((res) => {
          setCustomerData(res.data);
          setPagesCount(res.data.pagesCount);
          setCustomerDataLoading(false);
          setCustomerAnalyticsLoading(false);
        })
        .catch((err) => console.log(err));
    }, [customerId, dateFields, offset, limit]);
    useEffect(() => {
      const startDate = moment(dateFields.startDate).utc().format();
      const endDate = moment(dateFields.endDate).utc().format();

      setCustomerDataLoading(true);
      setCustomerAnalyticsLoading(true);
      api
        .get(`/customers/${customerId}/analytics?from=${startDate}&to=${endDate}`)
        .then((res) => {
          setAnalyticsData(res.data);
          setCustomerDataLoading(false);
          setCustomerAnalyticsLoading(false);
        })
        .catch((err) => console.log(err));
    }, [customerId, dateFields]);
    useEffect(() => {

      api
        .get(`/customers/${customerId}`)
        .then((res) => {
          setProfileData(res.data);
        })
        .catch((err) => console.log(err));
    }, [customerId]);

    return (
        <Container>
          <div
            className="customer_details"
          >
            <PageHeader
              type="navigation"
              link="/customers"
              linkTitle={t("pages.customers.customer_details.customers")}
              pageTitle={t("pages.customers.customer_details.customerDetails")}
              isCustomer={true}
              data={profileData}
              loading={false}
            />
            <div className="customer_details_in_actions">
              <Search
                placeholder={t("pages.customers.customer_details.search")}
                options={searchFieldsOrders}
                onSearch={onSearch}
              />
              <div className='orders_in_actions_calendar'>
                <DateTimePicker
                  className="orders_date_time_picker"
                  placeholder="DD/MM/YYYY HH:MM - HH:MM"
                  enableTimePicker={true}
                  position="bottom"
                  theme='orders'
                  enableRangePicker={true}
                  value={[dateFields.startDate, dateFields.endDate]}
                  onChange={e => {
                    setDateFields(e);
                    setOffset(0);
                  }}
                />
              </div>
            </div>
            <div className="customer_details_dashboard">
                <AnalyticsInfo
                  className="customer_details_dashboard__info"
                  title={t("pages.customers.customer_details.pending")}
                  count={analyticsData?.pendingOrdersCount}
                  status={"pending"}
                  loading={customerAnalyticsLoading}
                />
                <AnalyticsInfo
                  className="customer_details_dashboard__info"
                  title={t("pages.customers.customer_details.ongoing")}
                  count={analyticsData?.ongoingOrdersCount}
                  status={"ongoing"}
                  loading={customerAnalyticsLoading}
                />
                <AnalyticsInfo
                  className="customer_details_dashboard__info"
                  title={t("pages.customers.customer_details.finished")}
                  count={analyticsData?.finishedOrdersCount}
                  status={"finished"}
                  loading={customerAnalyticsLoading}
                />
                <AnalyticsInfo
                  className="customer_details_dashboard__info"
                  title={t("pages.customers.customer_details.canceled")}
                  count={analyticsData?.canceledOrdersCount}
                  status={"canceled"}
                  loading={customerAnalyticsLoading}
                />
              </div>
            <div className="customer_details_in">
              <Table
                titles={detailsOrdersTitles}
                hiddenFields={['id']}
                rows={customerData?.results?.map(order => ({
                  id: order.id,
                  orderId: (
                    <p>{`Order #${order.externalId}`}</p>
                  ),
                  agent: order.agent ? (
                    <p>{`${order.agent.firstName} ${order.agent.lastName} ${order.agent.phoneNumber}`}</p>
                  ) : <p>n/a</p>,
                  paymentMethod: (
                    <p>{order.paymentMethod.name.toLowerCase() === "cash" ? t("payment.cash") : order.paymentMethod.name}</p>
                  ),
                  amount: (
                    <p>{`${order.amount}`}</p>
                  ),
                  deliveryAddress: (
                    <p>{order.address.deliveryAddress}</p>
                  ),
                  branch: (
                    <p>{order.pickupAddress.name}</p>
                  ),
                  orderCreated: (
                    <p>{moment(order.createdAt).format(`${list.date_format.value.format}, ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}</p>
                  ),
                  status: order.status && (
                    <Status status={order.status} type="table"/>
                  )
                })) || []}
                loading={customerDataLoading}
              />
            </div>
          </div>
          {!!pagesCount && (
            <Pagination
              pagesCount={pagesCount}
              setOffset={setOffset}
              limit={limit}
              limitPlaceholder={limit.label}
              limits={LIMITS}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onChange={onChangeLimit}
            />
          )}
       </Container>
     );
}

export default memo(CustomerDetails);
