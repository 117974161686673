import "./index.scss";
import { memo } from "react";
import cn from "classnames";
import {createPortal} from "react-dom";

function Overlay({onClick = () => {}, isTransparent}) {

  return createPortal(
    <div
      className={cn("overlay", {'overlay--transparent' : isTransparent})}
      onClick={onClick}
    >
    </div>,
    document.getElementById('modal')
  );
}

export default memo(Overlay);
