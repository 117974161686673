import "./index.scss";
import {memo, useCallback, useState, useRef, useMemo, useContext, useEffect} from "react";
import api from "../../../../utils/api";
import PageHeader from "../../../../components/layout/page-header";
import Table from "../../../../components/table";
import Overlay from "../../../../components/ui/overlay";
import EditPaymentMethodForm from "./edit-payment-method-form";
import CreatePaymentMethodForm from "./create-payment-method-form";
import DeletePaymentMethodForm from "./delete-payment-method-form";
import {BsPlus, BsThreeDotsVertical} from "react-icons/bs";
import {TbTrashX} from "react-icons/tb";
import {useTranslation} from "react-i18next";
import Status from "../../../../components/ui/status";
import sound from "../../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {MdSettingsBackupRestore} from "react-icons/md";
import cn from "classnames";
import PaymentMethodsContext from "../../../../context/payment-methods/payment-methods-context";
import SwitcherBtn from "../../../../components/ui/switcher-btn";
import {errToString} from "../../../../utils";

function SettingsCompanyPaymentMethods() {
  const paymentMethods = useContext(PaymentMethodsContext);
  const editPaymentMethodRef = useRef({});
  const {t} = useTranslation();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isEditPaymentMethodFormOpen, setIsEditPaymentMethodFormOpen] = useState(false);
  const [isDeletePaymentMethodFormOpen, setIsDeletePaymentMethodFormOpen] = useState(false);
  const [isCreatePaymentMethodFormOpen, setIsCreatePaymentMethodFormOpen] = useState(false);
  const [showFrozenPayments, setShowFrozenPayments] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const paymentMethodTitles = useMemo(() => [
    {
    },
    {size: 100},
    {size: 50},
    {size: 50}
  ], []);

  const onEditPaymentMethod = useCallback((e, paymentMethod) => {
    e.stopPropagation();

    setIsEditPaymentMethodFormOpen(true);
    setSelectedPaymentMethod(paymentMethod);
  }, []);
  const onDeletePaymentMethod = useCallback((e, paymentMethod) => {
    e.stopPropagation();

    setIsDeletePaymentMethodFormOpen(true);
    setSelectedPaymentMethod(paymentMethod);
  }, []);
  const onRestorePaymentMethod = useCallback((payment_method) => {
    if (
      payment_method?.name?.toLowerCase() === "cash"
    ) {
      return alert("Payment method could not be deleted!")
    }

    api
      .put(`/company/payment_method/${payment_method.id}`, {
        restore: true
      })
      .then(() => {
          paymentMethods.update({
              ...payment_method,
              deletedAt: null
          });
        const audio = new Audio(sound);
        const info = `${t("pages.settings.paymentMethod")} ${payment_method.name} ${t("pages.branches.branch_card.hasBeenUnfrozen")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch((err) => console.log(errToString(err)))
  }, [t, paymentMethods]);
  const onCancelPaymentMethodForm = useCallback(() => {
    setIsEditPaymentMethodFormOpen(false);
    setIsDeletePaymentMethodFormOpen(false);
    setIsCreatePaymentMethodFormOpen(false);
  }, []);

  const paymentMethodsOptions = useMemo(() => {
      if (!showFrozenPayments) {
          return paymentMethods?.list.filter(({deletedAt}) => !deletedAt)
      }
      return paymentMethods?.list;
  }, [showFrozenPayments, paymentMethods?.list]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="settings_company_payment_methods">
      <PageHeader>
        <p>{t("payment.paymentMethods")}</p>
        <div className="settings_company_payment_methods_switcher">
          <SwitcherBtn
              checked={showFrozenPayments}
              setChecked={setShowFrozenPayments}
              type={"settings-payment-method"}
          />
        </div>
        <div
            className="settings_company_payment_methods_btn"
            onClick={(e) => {
              e.stopPropagation();
              setIsCreatePaymentMethodFormOpen(true);
            }}
        >
          {windowWidth > 600 ? t("payment.create") : <BsPlus />}
        </div>
      </PageHeader>
      <div className="settings_company_payment_methods_in">
        <p className="settings_company_payment_methods_in__descrip">
          {t("payment.description")}
        </p>
        <div className="settings_company_payment_methods_in_options">
          <Table
              titles={paymentMethodTitles}
              loading={false}
              hiddenFields={['id']}
              rows={paymentMethodsOptions.map(payment_method => ({
                id: payment_method.id,
                payment_method: (
                    <p className="settings_company_payment_methods_in_options_name">{payment_method.name.toLowerCase() === "cash" ? t("payment.cash") : payment_method.name}</p>
                ),
                isActive: (
                    <div className="settings_company_payment_methods_in_options_status">
                      <Status
                          type="table"
                          status={payment_method.deletedAt ? "freezed" : "active"}
                      />
                    </div>
                ),
                more: payment_method.name.toLowerCase() !== "cash" && !payment_method.deletedAt && (
                    <div
                        ref={el => editPaymentMethodRef.current[payment_method.id] = el}
                        onClick={e => onEditPaymentMethod(e, payment_method)}
                    >
                      <BsThreeDotsVertical/>
                    </div>
                ),
                delete: payment_method.name.toLowerCase() !== "cash" && (
                    <div
                        className={cn("partners_in_icon", {"partners_in_icon--delete" : !payment_method.deletedAt}, {"partners_in_icon--restore" : payment_method.deletedAt})}
                        ref={el => editPaymentMethodRef.current[payment_method.id] = el}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();

                          if (!payment_method.deletedAt) {
                            return onDeletePaymentMethod(e, payment_method)
                          }
                          if (payment_method.deletedAt) {
                            return onRestorePaymentMethod(payment_method)
                          }
                        }}
                    >
                      {!payment_method.deletedAt ? <TbTrashX/> : <MdSettingsBackupRestore/>}
                    </div>
                )
              })) || []}
          />
        </div>
        {isEditPaymentMethodFormOpen && (
          <>
            <EditPaymentMethodForm
              id={selectedPaymentMethod.id}
              name={selectedPaymentMethod.name}
              onCancel={onCancelPaymentMethodForm}
              setIsEditPaymentMethodFormOpen={setIsEditPaymentMethodFormOpen}
            />
            <Overlay onClick={onCancelPaymentMethodForm}/>
          </>
        )}
        {isDeletePaymentMethodFormOpen && (
          <>
            <DeletePaymentMethodForm
              id={selectedPaymentMethod.id}
              name={selectedPaymentMethod.name}
              onCancel={onCancelPaymentMethodForm}
              setIsDeletePaymentMethodFormOpen={setIsDeletePaymentMethodFormOpen}
            />
            <Overlay onClick={onCancelPaymentMethodForm}/>
          </>
        )}
        {isCreatePaymentMethodFormOpen && (
          <>
            <CreatePaymentMethodForm
              onCancel={onCancelPaymentMethodForm}
              setIsCreatePaymentMethodFormOpen={setIsCreatePaymentMethodFormOpen}
            />
            <Overlay onClick={onCancelPaymentMethodForm}/>
          </>
        )}
      </div>
    </div>
  );
}

export default memo(SettingsCompanyPaymentMethods);
