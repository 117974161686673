import "./index.scss";
import {memo, useState, useCallback} from "react";
import Form from "../../../../components/form";
import FormRow from "../../../../components/form/form-row";
import FormCol from "../../../../components/form/form-col";
import api from "../../../../utils/api";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import Loading from "../../../../components/ui/loading";
import {BiGitBranch} from "react-icons/bi";
import moment from "moment/moment";
import {errToString} from "../../../../utils";

function EditBranchNameForm({
dateFields,
selectedBranch,
setSelectedBranch,
onCancel,
branchesData,
setBranchesData
}) {
  const [error, setError] = useState(null);
  const {t} = useTranslation();
  const [branchName, setBranchName] = useState(selectedBranch.name);
  const [pending, setPending] = useState(false);

  const onEditBranchName = useCallback((e) => {
    e.preventDefault();
    const startDate = moment(dateFields.startDate).utc().format();
    const endDate = moment(dateFields.endDate).utc().format();

    setPending(true);
    api
      .put(`/company/address/${selectedBranch.id}?from=${startDate}&to=${endDate}`, {
        name: branchName
      })
      .then((res) => {
        setSelectedBranch({
          ...selectedBranch,
          name: res.data.name
        });
        setBranchesData(branchesData.map(obj => {
          if (obj.id === res.data.id) {
            return {
              ...selectedBranch,
              ...res.data
            }
          }
          return obj
        }));
        setPending(false);
        onCancel();
      })
      .catch(err => {
        console.log(errToString(err));
        setError(errToString(err));
        setPending(false);
      })

  }, [dateFields, setBranchesData, branchesData, onCancel, setSelectedBranch, selectedBranch, branchName]);

  return (
    <>
      <div className="edit_branch_name_form">
        <Form
          title={`${t("pages.branches.branch_card.edit_branch_name_form.editBranchName")} - ${selectedBranch.name}`}
          error={error && t(`errors.${error?.toLowerCase()}`)}
          onSubmit={onEditBranchName}
        >
          <FormRow>
            <FormCol>
              <label>
                <span>*</span>
                {t("pages.branches.branch_card.edit_branch_name_form.branchName")}
              </label>
              <div className="create_order_wrapper">
                <div className="create_order_wrapper_icon">
                  <BiGitBranch />
                </div>
                <input
                  type="text"
                  className="create_order_wrapper_input"
                  placeholder={t("pages.branches.branch_card.edit_branch_name_form.branchName")}
                  autoComplete="off"
                  value={branchName}
                  onChange={e => {
                    setError(null);
                    setBranchName(e.target.value);
                  }}
                />
              </div>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <a
                href="!#"
                className="update_agent__cancel_btn"
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
              >{t("pages.agents.update_agent.update_password_agent.cancel")}</a>
            </FormCol>
            <FormCol>
              {!pending && (
                <button
                  className="edit_branch_name_form__submit_btn"
                  type="submit"
                >{t("pages.branches.branch_card.edit_branch_name_form.save")}</button>
              )}
              {pending && (
                <button className="create_order_submit_btn create_order_submit_btn--pending">
                  <Loading />
                </button>
              )}
            </FormCol>
          </FormRow>
        </Form>
      </div>
      <div
        className="edit_branch_name_form__overlay"
        onClick={() => onCancel()}
      />
    </>
  );
}

EditBranchNameForm.propTypes = {
  selectedBranch: PropTypes.object,
  setSelectedBranch: PropTypes.func,
  setBranchesData: PropTypes.func,
  branchesData: PropTypes.array,
  onCancel: PropTypes.func
}

export default memo(EditBranchNameForm);
