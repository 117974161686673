import "./index.scss";
import {memo, useCallback, useContext, useState} from "react";
import {createPortal} from 'react-dom';
import Form from "../../../../../components/form";
import FormRow from "../../../../../components/form/form-row";
import FormCol from "../../../../../components/form/form-col";
import api from "../../../../../utils/api";
import PropTypes from "prop-types";
import PreferencesContext from "../../../../../context/preferences/preferences-context";
import Loading from "../../../../../components/ui/loading";
import {useTranslation} from "react-i18next";
import PaymentMethodsContext from "../../../../../context/payment-methods/payment-methods-context";
import {errToString} from "../../../../../utils";

function EditPaymentMethodForm({
    id,
    onCancel,
    name,
    setIsEditPaymentMethodFormOpen,
}) {
  const [newPaymentMethod, setNewPaymentMethod] = useState(name);
  const {list, update} = useContext(PreferencesContext);
  const paymentMethods = useContext(PaymentMethodsContext);
  const [error, setError] = useState(null);
  const {t} = useTranslation();
  const [pending, setPending] = useState(false)

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setError(null);

    setPending(true);
    api
      .put(`/company/payment_method/${id}`,
        {
          name: newPaymentMethod
        })
      .then(({data}) => {
        setPending(false);
        setIsEditPaymentMethodFormOpen(false);

        const activePaymentMethods = [
          ...list.agent_pane_money.value.paymentMethods.filter(option => option !== name)
        ]
        const body = {
          value: {
            paymentMethods: activePaymentMethods
          }
        };
        api
          .put(`/company/preferences/${list.agent_pane_money.id}`, body)
          .then((res) => {
            update(res.data);
          })
          .catch(err => {
            console.log(errToString(err));
            setError(errToString(err));
          });

          paymentMethods.update(data);
      })
      .catch((err) => {
          setPending(false);
          setError(errToString(err));
          console.log(errToString(err));
      })
  }, [list, update, name, id, newPaymentMethod, setIsEditPaymentMethodFormOpen, paymentMethods]);

  return createPortal(
    <div className="edit_payment_method_form">
      <Form
        title ={`${t("pages.settings.editPaymentName")} ${name}`}
        error={error && t(`errors.${error?.toLowerCase()}`)}
        onSubmit={onSubmit}
      >
          <FormRow>
          <FormCol>
            <label htmlFor="payment_method">
              <span>*</span>
              {t("pages.settings.paymentName")}
            </label>
            <input
              name="payment_method"
              id="payment_method"
              maxLength={20}
              type="text"
              placeholder=""
              autoComplete="off"
              value={newPaymentMethod}
              onChange={e => setNewPaymentMethod(e.target.value)}
            />
          </FormCol>
        </FormRow>
          <FormRow>
              <FormCol>
                  <button
                      className="edit_payment_method_form__cancel_btn"
                      onClick={onCancel}
                  >{t("pages.branches.create_branch_form.cancel")}</button>
              </FormCol>
              <FormCol>
                  {!pending && (
                      <button className="edit_payment_method_form__submit_btn" type="submit">{t("pages.branches.create_branch_form.save")}</button>
                  )}
                  {pending && (
                      <button className="edit_payment_method_form__submit_btn edit_payment_method_form__submit_btn--pending">
                          <Loading />
                      </button>
                  )}
              </FormCol>
          </FormRow>
      </Form>
    </div>,
      document.getElementById('modal')
  );
}

EditPaymentMethodForm.propTypes = {
  onCancel: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  setIsEditPaymentMethodFormOpen: PropTypes.func
}

export default memo(EditPaymentMethodForm);
