import "./index.scss";
import {memo, useCallback, useState} from "react";
import {createPortal} from 'react-dom';
import Form from "../../../components/form";
import FormRow from "../../../components/form/form-row";
import FormCol from "../../../components/form/form-col";
import PropTypes from "prop-types";
import api from "../../../utils/api";
import Loading from "../../../components/ui/loading";
import {useTranslation} from "react-i18next";
import {errToString} from "../../../utils";

function CreatePartnerForm({onCancel, partners, setPartners}) {
  const [name, setName] = useState("");
  const [src, setSrc] = useState("");
  const {t} = useTranslation();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);

    if (src) {
      formData.append('avatar', src);
    }

    setPending(true);
    api
      .post('/partners', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
      .then((res) => {
        setPartners([
          ...partners,
          res.data
        ]);
        setPending(false);
        onCancel();
      })
      .catch((err) => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      })
  }, [onCancel, partners, setPartners, name, src]);

  return createPortal(
    <div className="create_partner_form">
      <Form
        title={t("pages.partners.create")}
        error={error && t(`errors.${error?.toLowerCase()}`)}
        onSubmit={onSubmit}
      >
        <FormRow>
          <FormCol>
            <label htmlFor="partnerName">
              <span>*</span>
              {t("pages.partners.name")}
            </label>
            <input
              name="partnerName"
              id="partnerName"
              type="text"
              placeholder={t("pages.partners.name")}
              autoComplete="off"
              value={name}
              onChange={e => {
                setName(e.target.value);
                setError(null);
              }}
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <label htmlFor="partnerSrc">
              <span>*</span>
              {t("pages.partners.image")}
            </label>
            <div>
              {src && (
                <div className="form_col_file">
                  <img
                    alt="not found"
                    width={"250px"}
                    src={URL.createObjectURL(src)}
                  />
                  <br/>
                  <button className="form_col_file--remove" onClick={() => setSrc(null)}>{t("pages.partners.remove")}</button>
                </div>
              )}
              {!src && (
                <>
                  <label className="form_col_file--add" htmlFor="partnerSrc">{t("pages.partners.choose")}</label>
                  <input
                    id={"partnerSrc"}
                    type="file"
                    name="partnerSrc"
                    onChange={(event) => {
                      setSrc(event.target.files[0]);
                    }}
                  />
                </>
              )}
            </div>
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <button
              className="create_partner_form_cancel_btn"
              onClick={onCancel}
            >{t("pages.branches.create_branch_form.cancel")}</button>
          </FormCol>
          <FormCol>
            {!pending && (
              <button className="create_partner_form_submit_btn" type="submit">{t("pages.customers.create_customer.createCustomerBtn")}</button>
            )}
            {pending && (
              <button className="create_partner_form_submit_btn create_partner_form_submit_btn--pending">
                <Loading />
              </button>
            )}
          </FormCol>
        </FormRow>
      </Form>
    </div>,
      document.getElementById('modal')
  );
}

CreatePartnerForm.propTypes = {
  onCancel: PropTypes.func,
  setPartners: PropTypes.func,
  partners: PropTypes.array
}

export default memo(CreatePartnerForm);
