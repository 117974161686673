import "./index.scss";
import React, {memo, useCallback, useEffect, useState} from "react";
import PageHeader from "../../../components/layout/page-header";
import {useTranslation} from "react-i18next";
import {BsPlus} from "react-icons/bs";
import BillingCreditCard from "../billing-credit-card";
import api from "../../../utils/api";
import {Elements} from "@stripe/react-stripe-js";
import AddCardForm from "../stripe-form/add-card-form";
import {loadStripe} from "@stripe/stripe-js";
import {errToString} from "../../../utils";
import Loading from "../../../components/ui/loading";
import {createPortal} from "react-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function AccountPaymentMethods() {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState({
      default: null,
      others: []
  });
  const [isChangeCardOpen, setIsChangeCardOpen] = useState(false);

  const onChangeDefaultCard = useCallback((e, paymentMethodId) => {
    e.preventDefault();

    api
      .put(`/subscriptions/method/${paymentMethodId}`)
      .then(() => {
        window.location.reload();
      });
  }, []);
  const onDeleteCard = useCallback((e, paymentMethodId) => {
    e.preventDefault();

    api
      .delete(`/subscriptions/method/${paymentMethodId}`)
      .then(() => {
        window.location.reload();
      });
  }, []);

  useEffect(() => {
    setLoading(true);

    api.get('/subscriptions/methods')
      .then(result => {
        setLoading(false);
        setPaymentMethods(result.data);
      })
      .catch(err => {
        setLoading(false);
        console.log(errToString(err));
      });
  }, []);

  return (
    <div className="account_payment_methods">
      <PageHeader>
        <p>{t("pages.billing.paymentMethods")}</p>
      </PageHeader>
      <p className="account_payment_methods_in_descrip">
        {t("pages.billing.paymentMethodsDescrip")}
      </p>
      <div className="account_payment_methods_in">
        {!loading && (
          <>
            <div className="account_payment_methods_in_wrapper">
              {paymentMethods.default && (
                  <BillingCreditCard
                      name={paymentMethods.default.card.brand}
                      last4={paymentMethods.default.card.last4}
                      expFrom={paymentMethods.default.card.exp_month < 10 ?
                          `0${paymentMethods.default.card.exp_month}` :
                          paymentMethods.default.card.exp_month}
                      expTo={paymentMethods.default.card.exp_year}
                      isDefault={true}
                  />
              )}
              <div
                className="account_payment_methods_in_card_btn"
                onClick={() => setIsChangeCardOpen(true)}
              >
                <BsPlus />
                <p>{t("pages.billing.addCard")}</p>
              </div>
            </div>
              {paymentMethods.others && paymentMethods.others.length > 0 && (
                  <>
                      <PageHeader>
                          <p>{t("pages.billing.otherPaymentMethods")}</p>
                      </PageHeader>
                      <div className="account_payment_methods_in_wrapper">
                          {paymentMethods.others.map(paymentMethod => (
                              <BillingCreditCard
                                  key={paymentMethod.id}
                                  name={paymentMethod.card.brand}
                                  last4={paymentMethod.card.last4}
                                  expFrom={paymentMethod.card.exp_month < 10 ? `0${paymentMethod.card.exp_month}` : paymentMethod.card.exp_month}
                                  expTo={paymentMethod.card.exp_year}
                                  onChange={e => onChangeDefaultCard(e, paymentMethod.id)}
                                  onDelete={e => onDeleteCard(e, paymentMethod.id)}
                                  isDefault={false}
                              />
                          ))}
                      </div>
                  </>
              )}
          </>
        )}
        {loading && (
          <div className="account_payment_methods_in_center">
            <Loading />
          </div>
        )}
      </div>
      {isChangeCardOpen && createPortal(
        <Elements stripe={stripePromise}>
          <AddCardForm onClose={() => setIsChangeCardOpen(false)} />
        </Elements>,
        document.getElementById('modal')
      )}
    </div>
  );
}

export default memo(AccountPaymentMethods);
