import { memo } from "react";
import "./index.scss";

const Loading = () => {
  return (
    <div className="loading-circle">
      <svg
        className="loading-circle__spinner"
        width="25px"
        height="25px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="loading-circle__path"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </div>
  );
}

export default memo(Loading);
