import moment from 'moment/moment';
import en from '../localizations/en.json'
import am from '../localizations/am.json'

export const setMomentJsLocale = lng => {
    const translations = { am, en };
    moment.updateLocale(lng, {
        relativeTime: translations[lng].translation.moment.relativeTime
    });
    moment.locale(lng);
};
