import './index.scss';
import React, {memo, useCallback, useMemo, useState, useEffect, useContext} from 'react';
import api from "../../../utils/api";
import DropDown from '../../../components/ui/drop-down';
import Form from '../../../components/form'
import FormRow from "../../../components/form/form-row";
import FormCol from "../../../components/form/form-col";
import PropTypes from "prop-types";
import sound from "../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Loading from "../../../components/ui/loading";
import {MdOutlineError, MdPersonOutline} from "react-icons/md";
import {BsFillCheckCircleFill, BsPersonCheck, BsPlus} from "react-icons/bs";
import {AiOutlineMail} from "react-icons/ai";
import UserCompanyBranchesContext from "../../../context/user-company-branches/user-company-branches-context";
import {errToString} from "../../../utils";
import {useDebouncedCallback} from "use-debounce";
import PhoneNumberInput from "../../../components/ui/phone-number-input";

const GENDERS = [
  {
    value: "male",
    label: "male"
  },
  {
    value: "female",
    label: "female"
  }
]

function CreateManager({
onClose,
managers,
setManagers
}) {

  const [error, setError] = useState(null);
  const [branches, setBranches] = useState(null);
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const {t} = useTranslation();
  const [pending, setPending] = useState(false);
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    gender: null,
    email: ''
  });
  const [assignedBranch, setAssignedBranch] = useState({label: "", value: ""});
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedGender, setSelectedGender] = useState({label: "", value: ""});
  const genders = useMemo(() => GENDERS.map(option => ({
    ...option,
    label: t(`pages.agents.create_agent.${option.label}`)
  })), [t]);

  const [phoneNumberLoading, setPhoneNumberLoading] = useState(false);
  const [phoneNumberPassed, setPhoneNumberPassed] = useState(false);
  const [usernameLoading, setUsernameLoading] = useState(false);
  const [usernamePassed, setUsernamePassed] = useState(false);
  const [parsedPhoneNumber, setParsedPhoneNumber] = useState(null);
  const [showPhoneNumberError, setShowPhoneNumberError] = useState(false);
  const [showUsernameError, setShowUsernameError] = useState(false);
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber]= useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const companyBranchesOptions = useMemo(() => {
    if (!branches) {
      return
    }

    return branches
      .filter(({deletedAt}) => !deletedAt)
      .map((address) => {
      return {
        ...address,
        value: address.id,
        label: address.name
      }
    })
  }, [branches]);

  const onChangeField = useCallback((e, name) => {
    setError(null);

    if (
        name === 'gender'
    ) {
      return setFields({
        ...fields,
        [name]: e
      })
    }

    if (
      e.target.type === 'text' ||
      e.target.type === 'number'
    ) {
      setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [fields]);
  const onCheckValidation = useDebouncedCallback((field, link) => {
    setError(null);

    if (!phoneNumber) {
      setShowPhoneNumberError(false)
    }
    if (!username) {
      setShowUsernameError(false);
    }
    if (!field) {
      setUsernameLoading(false);
      setPhoneNumberLoading(false);
      return;
    }


    if (link === "username") {
      setUsernameLoading(true);

      return (
        api
          .get(`/validations/username?username=${field}`)
          .then(() => {
            setUsernameLoading(false);
            setShowUsernameError(false);
            return setUsernamePassed(true);
          })
          .catch((err) => {
            setUsernamePassed(false);
            setUsernameLoading(false);
            setShowUsernameError(true);
            console.log(errToString(err));
            setError(errToString(err));
          })
      )
    }
    if (link === "phone-number") {
      if (!parsedPhoneNumber) {
        return
      }

      setPhoneNumberLoading(true);

      if (parsedPhoneNumber) {
        if (!parsedPhoneNumber.isPossible || !parsedPhoneNumber.isValid) {
          setPhoneNumberPassed(false);
          setPhoneNumberLoading(false);
          setShowPhoneNumberError(true);
          return setError(errToString("phoneNumber.invalid"));
        }
      }

      if (parsedPhoneNumber) {
        if (!parsedPhoneNumber.data) {
          setPhoneNumberPassed(false);
          setPhoneNumberLoading(false);
          setShowPhoneNumberError(false);
          return setError(null);
        }
      }

      if (parsedPhoneNumber) {
        if (parsedPhoneNumber.isPossible && parsedPhoneNumber.isValid && parsedPhoneNumber.data) {
          return (
            api
              .get(`/validations/phone-number?phoneNumber=${field}&countryCode=${parsedPhoneNumber.data.country?.toLowerCase()}`)
              .then(() => {
                setPhoneNumberLoading(false);
                setPhoneNumberPassed(true);
                setShowPhoneNumberError(false);
                setError(null);
              })
              .catch((err) => {
                console.log(errToString(err));
                setPhoneNumberPassed(false);
                setPhoneNumberLoading(false);
                setShowPhoneNumberError(true);
                setError(errToString(err));
              })
          )
        }
      }
    }

  }, 500);

  const onSubmit = useCallback(e => {
    e.preventDefault();

    if (!assignedBranch.id) {
      return setError("Branch is required")
    }

    const data = {
      username: username,
      phoneNumber: phoneNumber,
      firstName: fields.firstName,
      lastName: fields.lastName,
      email: fields.email || null,
      note: null,
      gender: selectedGender.value || null,
      address: {
        countryCode: branches[0].countryCode,
        country: branches[0].countryCode,
        city: branches[0].countryCode,
        street: branches[0].deliveryAddress,
        apartment: null,
        entrance: null,
        floor: null,
      },
      roleId: selectedRole.id
    };

    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    for (const key in data.address) {
      if (data.address[key] === null) {
        delete data.address[key];
      }
    }

    setPending(true);
    api
      .post("/employees/managers", data)
      .then((res) => {
        setManagers([
          res.data,
          ...managers
        ])
        setPending(false);
        api
          .post(`/company/address/${assignedBranch.id}/employees`, {
            ids: [res.data.id]
          })
          .then(() => {
            onClose();
            const audio = new Audio(sound);
            const info = t("pages.managers.create_manager.createdNotification");
            toast.info(info, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            audio.play();

            api
              .get('/user-company-addresses')
              .then((res) => userCompanyBranches.set(res.data))
              .catch(err => console.log(errToString(err)));
          })
          .catch(err => console.log(errToString(err)))
      })
      .catch((err) => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      });
  // eslint-disable-next-line
  }, [branches, username, phoneNumber, selectedGender, selectedRole, t, assignedBranch, onClose, fields, setManagers, managers, userCompanyBranches.set]);

  useEffect(() => {
    const promises = [];
    promises.push(api.get("/company/addresses"));
    promises.push(api.get("/roles"));

    Promise
      .all(promises)
      .then((res) => {
        setBranches(res[0].data)
        const _data = res[1].data.map(role => {
          return {...role, 'label': role.name}
        })
        setRoles(_data);
        setSelectedRole(_data[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(errToString(err))
      })
  }, []);
  useEffect(() => {
    if (!phoneNumber) {
      return
    }

    setPhoneNumberPassed(false);
    setShowPhoneNumberError(false);
    setError(null);
    onCheckValidation(phoneNumber, "phone-number");
  }, [phoneNumber, onCheckValidation]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="create_manager">
      <div className="create_manager_in">
        {!loading && (
          <Form
            title={t("pages.managers.create_manager.createManager")}
            error={error && t(`errors.${error?.toLowerCase()}`)}
            onSubmit={onSubmit}
          >
            {windowWidth > 600 && (
              <>
                <FormRow>
                  <FormCol>
                    <label htmlFor="phoneNumber">
                      <span>*</span>
                      {t("pages.managers.create_manager.phoneNumber")}
                    </label>
                    <PhoneNumberInput
                      country={branches[0].countryCode?.toUpperCase()}
                      placeholder={t("pages.signUp.phoneNumber")}
                      value={phoneNumber}
                      setValue={setPhoneNumber}
                      loading={phoneNumberLoading}
                      passed={phoneNumberPassed}
                      showError={showPhoneNumberError}
                      setParsedPhoneNumber={setParsedPhoneNumber}
                    />
                  </FormCol>
                  <FormCol>
                    <label htmlFor="username">
                      <span>*</span>
                      {t("pages.agents.create_agent.username")}
                    </label>
                    <div className="create_order_wrapper">
                      <div className="create_order_wrapper_icon">
                        <BsPersonCheck />
                      </div>
                      <input
                        name="username"
                        type="text"
                        className="create_order_wrapper_input"
                        placeholder={t("pages.signUp.username")}
                        autoComplete="off"
                        value={username}
                        onChange={(e) => {
                          setError(null);
                          setUsernamePassed(false);
                          setUsername(e.target.value);
                          onCheckValidation(e.target.value, "username");
                        }}
                      />
                      <div className="create_order_wrapper_icon create_order_wrapper_icon--loading">
                        {usernameLoading && (
                          <Loading />
                        )}
                        {usernamePassed && (
                          <span className=" create_order_wrapper_icon create_order_wrapper_icon--green">
                            <BsFillCheckCircleFill/>
                          </span>
                        )}
                        {showUsernameError && !usernameLoading && (
                          <span className="create_order_wrapper_icon create_order_wrapper_icon--red">
                            <MdOutlineError />
                          </span>
                        )}
                      </div>
                    </div>
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label htmlFor="firstName">
                      <span>*</span>
                      {t("pages.managers.create_manager.firstName")}
                    </label>
                    <div className="create_order_wrapper">
                      <div className="create_order_wrapper_icon">
                        <MdPersonOutline />
                      </div>
                      <input
                        name="firstName"
                        className="create_order_wrapper_input"
                        type="text"
                        placeholder={t("pages.managers.create_manager.firstName")}
                        autoComplete="off"
                        value={fields.firstName}
                        onChange={e => onChangeField(e, 'firstName')}
                      />
                    </div>
                  </FormCol>
                  <FormCol>
                    <label htmlFor="lastName">
                      <span>*</span>
                      {t("pages.managers.create_manager.lastName")}
                    </label>
                    <div className="create_order_wrapper">
                      <div className="create_order_wrapper_icon">
                        <MdPersonOutline />
                      </div>
                      <input
                        name="lastName"
                        className="create_order_wrapper_input"
                        type="text"
                        placeholder={t("pages.managers.create_manager.lastName")}
                        autoComplete="off"
                        value={fields.lastName}
                        onChange={e => onChangeField(e, 'lastName')}
                      />
                    </div>
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label htmlFor="gender">
                      <span>*</span>
                      {t("pages.agents.create_agent.gender")}
                    </label>
                    <DropDown
                      hasPersonIcon={true}
                      name="gender"
                      placeholder={t("pages.agents.create_agent.gender")}
                      options={genders}
                      position={"top"}
                      value={selectedGender}
                      onChange={e => setSelectedGender(e)}
                    />
                  </FormCol>
                  <FormCol>
                    <label htmlFor="email">
                      {t("pages.managers.create_manager.email")}
                    </label>
                    <div className="create_order_wrapper">
                      <div className="create_order_wrapper_icon">
                        <AiOutlineMail />
                      </div>
                      <input
                        name="email"
                        type="text"
                        placeholder={t("pages.managers.create_manager.email")}
                        className="create_order_wrapper_input"
                        autoComplete="off"
                        value={fields.email}
                        onChange={e => onChangeField(e, 'email')}
                      />
                    </div>
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label>
                      <span>*</span>
                      {t("pages.managers.create_manager.branches")}
                    </label>
                    <DropDown
                      hasBranchIcon={true}
                      placeholder={t("pages.managers.create_manager.chooseBranch")}
                      options={companyBranchesOptions}
                      value={assignedBranch}
                      onChange={(e) => setAssignedBranch(e)}
                      position="bottom"
                      showClose={false}
                    />
                  </FormCol>
                  <FormCol>
                    <label>
                      {t("pages.managers.create_manager.roles")}
                    </label>
                    <DropDown
                      hasRoleIcon={true}
                      placeholder={t("pages.managers.create_manager.chooseRole")}
                      options={roles}
                      value={selectedRole}
                      onChange={(e) => setSelectedRole(e)}
                      position="bottom"
                      showClose={false}
                    />
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <a
                      href="!#"
                      className="create_manager__cancel_btn"
                      onClick={onClose}
                    >{t("pages.managers.create_manager.cancel")}</a>
                  </FormCol>
                  <FormCol>
                    {!pending && (
                      <button className="create_manager__submit_btn">{t("pages.managers.create_manager.createManager")}</button>
                    )}
                    {pending && (
                      <button className="create_agent__submit_btn create_agent__submit_btn--pending">
                        <Loading />
                      </button>
                    )}
                  </FormCol>
                </FormRow>
              </>
            )}
            {windowWidth <= 600 && (
              <>
                <FormRow>
                  <FormCol>
                    <label htmlFor="phoneNumber">
                      <span>*</span>
                      {t("pages.managers.create_manager.phoneNumber")}
                    </label>
                    <PhoneNumberInput
                      country={branches[0].countryCode?.toUpperCase()}
                      placeholder={t("pages.signUp.phoneNumber")}
                      value={phoneNumber}
                      setValue={setPhoneNumber}
                      loading={phoneNumberLoading}
                      passed={phoneNumberPassed}
                      showError={showPhoneNumberError}
                      setParsedPhoneNumber={setParsedPhoneNumber}
                    />
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label htmlFor="username">
                      <span>*</span>
                      {t("pages.agents.create_agent.username")}
                    </label>
                    <div className="create_order_wrapper">
                      <div className="create_order_wrapper_icon">
                        <BsPersonCheck />
                      </div>
                      <input
                        name="username"
                        type="text"
                        className="create_order_wrapper_input"
                        placeholder={t("pages.signUp.username")}
                        autoComplete="off"
                        value={username}
                        onChange={(e) => {
                          setError(null);
                          setUsernamePassed(false);
                          setUsername(e.target.value);
                          onCheckValidation(e.target.value, "username");
                        }}
                      />
                      <div className="create_order_wrapper_icon create_order_wrapper_icon--loading">
                        {usernameLoading && (
                          <Loading />
                        )}
                        {usernamePassed && (
                          <span className=" create_order_wrapper_icon create_order_wrapper_icon--green">
                            <BsFillCheckCircleFill/>
                          </span>
                        )}
                        {showUsernameError && !usernameLoading && (
                          <span className="create_order_wrapper_icon create_order_wrapper_icon--red">
                            <MdOutlineError />
                          </span>
                        )}
                      </div>
                    </div>
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label htmlFor="firstName">
                      <span>*</span>
                      {t("pages.managers.create_manager.firstName")}
                    </label>
                    <div className="create_order_wrapper">
                      <div className="create_order_wrapper_icon">
                        <MdPersonOutline />
                      </div>
                      <input
                        name="firstName"
                        className="create_order_wrapper_input"
                        type="text"
                        placeholder={t("pages.managers.create_manager.firstName")}
                        autoComplete="off"
                        value={fields.firstName}
                        onChange={e => onChangeField(e, 'firstName')}
                      />
                    </div>
                  </FormCol>
                  <FormCol>
                    <label htmlFor="lastName">
                      <span>*</span>
                      {t("pages.managers.create_manager.lastName")}
                    </label>
                    <div className="create_order_wrapper">
                      <div className="create_order_wrapper_icon">
                        <MdPersonOutline />
                      </div>
                      <input
                        name="lastName"
                        className="create_order_wrapper_input"
                        type="text"
                        placeholder={t("pages.managers.create_manager.lastName")}
                        autoComplete="off"
                        value={fields.lastName}
                        onChange={e => onChangeField(e, 'lastName')}
                      />
                    </div>
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label htmlFor="gender">
                      <span>*</span>
                      {t("pages.agents.create_agent.gender")}
                    </label>
                    <DropDown
                      hasPersonIcon={true}
                      name="gender"
                      placeholder={t("pages.agents.create_agent.gender")}
                      options={genders}
                      position={"top"}
                      value={selectedGender}
                      onChange={e => setSelectedGender(e)}
                    />
                  </FormCol>
                  <FormCol>
                    <label htmlFor="email">
                      {t("pages.managers.create_manager.email")}
                    </label>
                    <div className="create_order_wrapper">
                      <div className="create_order_wrapper_icon">
                        <AiOutlineMail />
                      </div>
                      <input
                        name="email"
                        type="text"
                        placeholder={t("pages.managers.create_manager.email")}
                        className="create_order_wrapper_input"
                        autoComplete="off"
                        value={fields.email}
                        onChange={e => onChangeField(e, 'email')}
                      />
                    </div>
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label>
                      <span>*</span>
                      {t("pages.managers.create_manager.branches")}
                    </label>
                    <DropDown
                      hasBranchIcon={true}
                      placeholder={t("pages.managers.create_manager.chooseBranch")}
                      options={companyBranchesOptions}
                      value={assignedBranch}
                      onChange={(e) => setAssignedBranch(e)}
                      position="bottom"
                      showClose={false}
                    />
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <label>
                      {t("pages.managers.create_manager.roles")}
                    </label>
                    <DropDown
                      hasRoleIcon={true}
                      placeholder={t("pages.managers.create_manager.chooseRole")}
                      options={roles}
                      value={selectedRole}
                      onChange={(e) => setSelectedRole(e)}
                      position="bottom"
                      showClose={false}
                    />
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <a
                      href="!#"
                      className="create_manager__cancel_btn"
                      onClick={onClose}
                    >{t("pages.managers.create_manager.cancel")}</a>
                  </FormCol>
                  <div
                    className="update_order_submit_btn--round"
                    style={{marginTop: "20px"}}
                    onClick={onSubmit}
                  >
                    <BsPlus />
                  </div>
                </FormRow>
              </>
            )}
          </Form>
        )}
        {loading && (
          <Loading/>
        )}
      </div>
    </div>
  );
}

CreateManager.propTypes = {
  onClose: PropTypes.func,
  managers: PropTypes.array.isRequired,
  setManagers: PropTypes.func.isRequired

};

export default memo(CreateManager);
