import "./index.scss";
import React, {memo, useCallback, useEffect, useState} from "react";
import PageHeader from "../../../../components/layout/page-header";
import Form from "../../../../components/form";
import FormRow from "../../../../components/form/form-row";
import FormCol from "../../../../components/form/form-col";
import api from "../../../../utils/api";
import Loading from "../../../../components/ui/loading";
import OrsMap from "../../../../assets/ors_map.jpg"
import GoogleMap from "../../../../assets/google_map.jpg"
import {useTranslation} from "react-i18next";
import {errToString} from "../../../../utils";
import cn from "classnames";
import ORS from "../../../../assets/ors.png";
import GMAP from "../../../../assets/gmap.png";

function SettingsIntegrationsMaps() {

  const [mapIntegrations, setMapIntegrations] = useState(null);
  const [selectedMap, setSelectedMap] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    setPending(true);

    const data = {
      isActive: true,
      name: 'map'
    }
    if (selectedMap.value === 'google') {
      data.credentials = {
        // apiKey: selectedMap.credentials.apiKey
        apiKey: "AIzaSyDYhSJx1twv8MmdBbUIGcaYnsgCgdLeRYg"
      }
    }

    api
      .put(`/company/integrations/${selectedMap.id}`, data)
      .then(() => {
        setSuccess(t("pages.settings.mapSuccess"));
        window.location.reload();
        setError(null);
        setPending(false);
      })
      .catch((err) => {
        setSuccess(null);
        setPending(false);
        console.log(errToString(err));
        setError(errToString(err))
      })
  }, [t, selectedMap]);

  useEffect(() => {
    api
      .get("/company/integrations")
      .then(({data}) => {
        const mapIntegrations = data
          .map(item => {
            return {...item, 'label': item.value}
          })
          .filter(item => item.name === 'map');
        setMapIntegrations(mapIntegrations);
        setSelectedMap(mapIntegrations.find(({ isActive }) => isActive));
        setLoading(false);
      })
      .catch((err) => console.log(errToString(err)))
  }, []);

  return (
    <>
      {!loading && (
        <div className="settings_integrations_maps">
          <PageHeader>
            <p>{t("pages.settings.maps")}</p>
          </PageHeader>
          <div className="settings_integrations_maps_in">
            <div className="settings_integrations_maps_in_left">
              {!loading && (
                <ul className="settings_integrations_maps_in_list">
                  {mapIntegrations.map((map, index) => (
                    <li
                      key={`${map.id} ${index}`}
                      className={cn("settings_integrations_maps_in_list_item", {"settings_integrations_maps_in_list_item--active" : map.id === selectedMap.id})}
                      onClick={() => {
                        setSelectedMap(map);
                        setSuccess(null);
                        setError(null);
                      }}
                    >
                      {map.value === "open_street" && (
                        <>
                          <img src={ORS} alt={map.label}/>
                          <h3>ORS</h3>
                        </>
                      )}
                      {map.value === "google" && (
                        <>
                          <img src={GMAP} alt={map.label}/>
                          <h3>Google maps</h3>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              {!loading && selectedMap.value === "open_street" && (
                <div className="settings_integrations_maps_in_left_descrip">
                  <p>{t("pages.settings.openStreetMapDescription")}</p>
                </div>
              )}
              {!loading && selectedMap.value === "google" && (
                <div className="settings_integrations_maps_in_left_descrip">
                  <p>
                    {t("pages.settings.openStreetMapDescription")}
                    {/*{t("pages.settings.googleMapDescription")}*/}
                    {/*<span*/}
                    {/*  onClick={() => window.open("https://developers.google.com/maps/documentation/embed/get-api-key", '_blank')}*/}
                    {/*>{t("pages.settings.link")}</span>*/}
                  </p>
                </div>
              )}
              <div className="settings_integrations_maps_in_options">
                {!loading && selectedMap.value === "google" && (
                  <div className="settings_integrations_maps_in_form">
                    <Form onSubmit={onSubmit}>
                      {/*<FormRow>*/}
                      {/*  <FormCol>*/}
                      {/*    <label htmlFor="api_key">*/}
                      {/*      <span>*</span>*/}
                      {/*      API KEY*/}
                      {/*    </label>*/}
                      {/*    <input*/}
                      {/*      name="api_key"*/}
                      {/*      type="text"*/}
                      {/*      placeholder="API KEY"*/}
                      {/*      autoComplete="off"*/}
                      {/*      value={selectedMap.credentials.apiKey}*/}
                      {/*      onChange={e => setSelectedMap({*/}
                      {/*        ...selectedMap,*/}
                      {/*        credentials: {*/}
                      {/*          ...selectedMap.credentials,*/}
                      {/*          apiKey: e.target.value*/}
                      {/*        }*/}
                      {/*      })}*/}
                      {/*    />*/}
                      {/*  </FormCol>*/}
                      {/*</FormRow>*/}
                      <FormRow>
                        <FormCol>
                          {!pending && (
                              <button className="create_order_submit_btn">
                                {t("pages.branches.create_branch_form.save")}
                              </button>
                          )}
                          {pending && (
                              <button className="create_order_submit_btn create_order_submit_btn--pending">
                                <Loading />
                              </button>
                          )}
                          {success && (
                              <p className="settings_integrations_sms_in_form_success">{success}</p>
                          )}
                          {error && (
                              <p className="settings_integrations_sms_in_form_error">{error}</p>
                          )}
                        </FormCol>
                      </FormRow>
                    </Form>
                  </div>
                )}
                {!loading && selectedMap.value === "open_street" && (
                  <div className="settings_integrations_maps_in_form">
                    <Form onSubmit={onSubmit}>
                      <FormRow>
                        <FormCol>
                          {!pending && (
                              <button className="create_order_submit_btn">
                                {t("pages.branches.create_branch_form.save")}
                              </button>
                          )}
                          {pending && (
                              <button className="create_order_submit_btn create_order_submit_btn--pending">
                                <Loading />
                              </button>
                          )}
                          {success && (
                              <p className="settings_integrations_sms_in_form_success">{success}</p>
                          )}
                          {error && (
                              <p className="settings_integrations_sms_in_form_error">{error}</p>
                          )}
                        </FormCol>
                      </FormRow>
                    </Form>
                  </div>
                )}
                {loading && (
                  <div className="settings_integrations_maps_in_options_loading">
                    <Loading />
                  </div>
                )}
              </div>
            </div>
            <div className="settings_integrations_maps_in_right">
              <div className="settings_integrations_maps_in_right_img">
                {!loading && selectedMap.value === "google" && (
                  <img src={GoogleMap} alt="google map example"/>
                )}
                {!loading && selectedMap.value === "open_street" && (
                  <img src={OrsMap} alt="open street map example"/>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="settings_integrations_sms_in_options_loading">
          <Loading />
        </div>
      )}
    </>
  );
}

export default memo(SettingsIntegrationsMaps);
