import './index.scss';
import React, {memo, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import api from "../../../../../../utils/api";
import Loading from '../../../../../../components/ui/loading';
import FormRow from "../../../../../../components/form/form-row";
import FormCol from "../../../../../../components/form/form-col";
import Form from "../../../../../../components/form";
import DropDown from "../../../../../../components/ui/drop-down";
import sound from "../../../../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {errToString} from "../../../../../../utils";

function UpdateStatus({ onClose, order }) {

  const [error, setError] = useState(null);
  const {t} = useTranslation();

  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);

  const [orderStatuses, setOrderStatuses] = useState(null);
  const [orderStatus, setOrderStatus] = useState({label: t(`statuses.${order.status}`), value: order.status});

  const onSubmit = useCallback(e => {
    e.preventDefault();

    if (pending) {
      return;
    }

    const data = {
      status: orderStatus.value,
      amount: order.amount,
      paymentMethodId: order.paymentMethod.id,
      deliveryType: order.deliveryType,
      startDate: order.startDate,
      endDate: order.endDate,
      note: order.note
    };

    setPending(true);

    api
      .put(`/orders/${order.id}`, data)
      .then(() => {
        onClose();
        setPending(false);

        const audio = new Audio(sound);
        const info = `${t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.order")} #${order.externalId} ${t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.hasBeenUpdated")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch((err) => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      })
  }, [t, pending, order, onClose, orderStatus]);

  useEffect(() => {
    api
      .get("/company/order_statuses")
      .then(({data}) => {
        setOrderStatuses(data
          .filter(({ status, isActive }) => status !== 'created' && status !== 'unassigned' && isActive)
          .map(orderStatus => ({
            ...orderStatus,
            value: orderStatus.status,
            label: t(`statuses.${orderStatus.status}`)
          })));
        setLoading(false);
      })
      .catch(err => console.log(errToString(err)));
  }, [t]);

  return (
    <>
        {orderStatuses && (
            <div className="update_status_form">
                <div className="update_status_form_in">
                    <Form
                        title={t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.updateStatus")}
                        error={error && t(`errors.${error?.toLowerCase()}`)}
                        onSubmit={onSubmit}
                    >
                        <FormRow>
                            <FormCol>
                              <DropDown
                                hasStatusIcon={true}
                                options={orderStatuses.map((status, index) => ({
                                  option: status,
                                  el: (
                                    <li
                                      className='create_order_custom_dropdown_menu'
                                      key={index}
                                    >
                                      <div className={`update_status_form_status update_status_form_status--${status.value}`}>
                                        {status.label}
                                      </div>
                                    </li>
                                  )
                                }))}
                                placeholder={t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.updateStatus")}
                                value={orderStatus}
                                onChange={(e) => setOrderStatus(e)}
                                loading={loading}
                              />
                            </FormCol>
                        </FormRow>
                        <FormRow>
                          <div className="update_status_form_in_actions">
                            <FormCol>
                              <a
                                href="!#"
                                className="update_status_form_in_actions_cancel"
                                onClick={onClose}
                              >{t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.cancel")}</a>
                            </FormCol>
                            <FormCol>
                              {!pending && (
                                <button className="update_status_form_in_actions_submit">{t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.update")}</button>
                              )}
                              {pending && (
                                <button className="update_status_form_in_actions_submit update_status_form_in_actions_submit--pending">
                                  <Loading />
                                </button>
                              )}
                            </FormCol>
                          </div>
                        </FormRow>
                    </Form>
                </div>
            </div>
        )}
        {!orderStatuses && (
            <div className="update_status_form_loading">
                <Loading />
            </div>
        )}
    </>
  );
}

UpdateStatus.propTypes = {
  onClose: PropTypes.func,
  order: PropTypes.object.isRequired
};

export default memo(UpdateStatus);
