import {memo, useCallback, useRef} from "react";
import "./index.scss";
import QR from "../../assets/qr.jpeg"
import Logo from "../../assets/logo.png"
import {useTranslation} from "react-i18next";
import {BiCopy} from "react-icons/bi";
import {BsFillCheckCircleFill} from "react-icons/bs";
import {RiCloseCircleFill} from "react-icons/ri";
import GooglePlayIcon from "../../assets/google play market taskroad driver.jpg"
import AppStoreIcon from "../../assets/app store taskroad driver.jpg"

function DriverAppQR({
showTitle = false,
onClose
}) {
    const {t} = useTranslation();
    const textRef = useRef(null);

    const onQrDownload = useCallback(async () => {

        try {
            // Fetch the image as a Blob
            const response = await fetch("data:image/jpeg;base64,R0lGODdhfQB9AIAAAAAAAP///ywAAAAAfQB9AAAC/4SPqbvhDxOcgdlD4928e5s9Ulh9CFma6qqiY8gCbkzXyoyRMW73u34Crni+DeqoyYmCsKLS8UISo9LmM2WY9ibUKuXiJXKZGee1G14y0tZs0t0ujq9sKKg+h9vpb3kfn3fTB/Y3yBLoRfURqOiBaDhUKNXYwUiGZSS5V/M4ebkIqYdJqCbq04lEyWF55qg5evgqFipYWruZaau1VkWKy9pAC3yLuRvsyatLq0oMqhznexTNPH1nW813bZ26Xbz82e38K7TaGw5eKfzdyi6KJ+6tnYxLfd4uAyh/PK7fbF/+jF8+ePXmwbqHzdSld64CHtz3EF8/ZqgAEUQHkN7COv8FFWYrs9EhQ4MjPUJEE2dWvogrBbKURU4iyI9hopWU+VJkTJUDu90cVpHnl5BpzGSM55LiK6NMcyXFiFPpxKZmKkp9ipAqVatQuWbVytSryahd13FqmcjnUqJo27rtJxTl27loba6lSTcvR7UO5er9W5MvVneAjZGlaVfnVIMXGf/DCXRnzMZHE/pDLBjp0EiLx3Y8TBiuZGgNNTo+fZJtYpfDLLtWvWW0ac+wSe792kJ2ztmQ1a38DE/sZYiRf0P9kdI3b8N1j8dKLjr6TOK3aeN2utti5rSoh5so3tM2m9fXyYcuut1cd+CP/XKnPr6983TStYtHfz+i+d7I4E//568XcstVR4xQhQmY3XsF6nbgcwPGl19QgCG4mjTpKVbYg7y5p5x+mX2mIHt4dYigcBzWF9xk8x1lIlvgeTgiig5uZt15NHpX4Ysl3pWaDXGVFhh9Gmp2im4p9oedZa0B2WKEKq5XVmfYNemfhUJmJyKLPE6pXmXy3RPihUNS6Z2OLpoF4I1+ZQlam1dZqSVpmJUX5ZAnbnhmfWS+qWSXXMppI5vCSQhhbnrCBN1/BhJI2YIY3rjoo2FeWeGdmhHKGqNAVppnpo9aath+kSpq5IczLkmnVsw9GSeeqW7Fapo7SokqrIDKeiqatTa16q2cUgpWjhnSWqqTh0o615GD/zn635qdtqUskWImiGiATG5p6a820hUtiH46GyyY2M7orZRhbblrnzKG2+NY6T5mJrtljsvZsmye29eK39FbI7NBblstpAHvOuq/aWIqMLLEJmofwAovO2mVoDJIMcPZXvswnAcPjO63pr7aZsT7mrtpnTC663G9J48Msa4ckwyljx0v7Gmz5Bo1qMsZFwmzl+LSLC2wV4qsrZvw6uyqwzYPze+8QEcb6sf98lky00h2qy/VQreqMdYgI4ywukujvK7PSl9KYm3tGv31yzVjqbbTdlZ9Nrhr92pv1iYXLLaaJguL5IvxGkpq4C2VOzbbXF8cIzfX4b2y3Rtn/GN++ywVrWBzj8f67px1gxU23Tg+K+/dsSJOra+gB9wo6lGryrrorp+++qey6ytyAQA7")
            const blob = await response.blob();

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = url;
            link.download = 'taskroad driver qr.jpg'; // Set the desired file name

            // Click the anchor element to trigger download
            link.click();

            // Clean up by revoking the URL
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    }, []);

    const handleCopy = () => {
        const textToCopy = textRef.current.innerText;
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert('Text copied to clipboard');
            })
            .catch((error) => {
                console.error('Error copying text:', error);
            });
    };


    return (
        <div
            className="driver_app_qr"
        >
            <div className="driver_app_qr_wrapper">
                <div className='driver_app_qr_wrapper_logo'>
                    <img src={Logo} alt="taskroad driver"/>
                </div>
                <div
                  className="driver_app_qr_wrapper_close"
                  onClick={onClose}
                >
                    <RiCloseCircleFill />
                </div>
            </div>
            {showTitle && (
                <p className="driver_app_qr_title">
                    <p><span><BsFillCheckCircleFill /></span>{t('components.qr.congrats')}</p>
                    <p>{t('components.qr.congratsDescrip')}</p>
                </p>
            )}
            <div className='driver_app_qr_img'>
                <img src={QR} alt="taskroad driver"/>
            </div>
            <p className="driver_app_qr_description">{t("components.qr.description")}</p>
            <div className="driver_app_qr_app_icons">
                <a rel="noreferrer" target="_blank" href='https://play.google.com/store/apps/details?id=pro.taskroad.driver&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img
                        style={{height: "40px"}}
                        src={GooglePlayIcon}
                        alt='TaskRoad Driver'
                    />
                </a>
                <a rel="noreferrer" target="_blank" href="https://apps.apple.com/us/app/taskroad-driver/id6449285341?itsct=apps_box_badge&amp;itscg=30200">
                    <img
                      style={{height: "40px"}}
                        src={AppStoreIcon}
                        alt='TaskRoad Driver'
                    />
                </a>
            </div>
            <button className="driver_app_qr_download_btn" onClick={onQrDownload}>{t("components.qr.download")}</button>
            <p style={{display: "none"}} ref={textRef}>https://onelink.to/vb3qmb</p>
            <button
              className="driver_app_qr_copy_btn"
              onClick={handleCopy}
            >
                <BiCopy />
                {t("components.qr.link")}
            </button>
        </div>
    );
}

export default memo(DriverAppQR);
