import {memo, useState, useEffect, useMemo, useCallback, useRef, useContext} from "react";
import api from "../../utils/api";
import "./index.scss";
import PageHeader from "../../components/layout/page-header";
import Table from '../../components/table';
import Container from "../../components/layout/container";
import PopUp from "../../components/layout/popup";
import CreateManager from "./create-manager";
import Pagination from "../../components/ui/pagination";
import {LIMITS} from "../../constants/drop-down-options";
import Search from "../../components/search";
import {BsPlus, BsThreeDotsVertical} from "react-icons/bs";
import sound from "../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {createPortal} from "react-dom";
import MorePopup from "../../components/ui/more-popup";
import Loading from "../../components/ui/loading";
import UpdateManager from "./update-manager";
import UpdateManagerPassword from "./update-manager/update-password-manager";
import AuthContext from "../../context/auth/auth-context";
import {useTranslation} from 'react-i18next';
import SwitcherBtn from "../../components/ui/switcher-btn";
import UserCompanyBranchesContext from "../../context/user-company-branches/user-company-branches-context";
import {errToString} from "../../utils";
import Status from "../../components/ui/status";

const SEARCH_FIELDS_MANAGERS = [
  {label: `name`, value: 'name'},
  {label: `username`, value: 'username'},
  {label: `phoneNumber`, value: 'phoneNumber'},
  {label: `email`, value: 'email'},
];
const MORE_BTN_SIZE = 50;

function Managers() {
  const {user} = useContext(AuthContext);
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const morePopupRef = useRef({});
  const {t} = useTranslation();

  const [selectedManagerId, setSelectedManagerId] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
  const [managersData, setManagersData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isMorePopupOpen, setIsMorePopupOpen] = useState(false);
  const [isUnFreezeAlertOn, setIsUnFreezeAlertOn] = useState(false);
  const [isFreezeAlertOn, setIsFreezeAlertOn] = useState(false);
  const [isEditProfileOn, setIsEditProfileOn] = useState(false);
  const [isEditPasswordOn, setIsEditPasswordOn] = useState(false);
  const [createManager, setCreateManager] = useState(false);
  const [morePopupUser, setMorePopupUser] = useState(null);
  const [showFrozenManagers, setShowFrozenManagers] = useState(false);

  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [currentPage, setCurrentPage] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const searchFieldsManagers = useMemo(() => SEARCH_FIELDS_MANAGERS.map(option => ({
    ...option,
    label: t(`pages.managers.${option.label}`)
  })), [t]);
  const managerTableTitles = useMemo(() => [
    {
      name: t("pages.managers.manager")
    },
    {
      name:  t("pages.managers.username")
    },
    {
      name:  t("pages.managers.phoneNumber")
    },
    {
      name: t("pages.managers.email")
    },
    {
      name: t("pages.managers.role"),
      size: 100
    },
    {
      name: t("pages.managers.isActive"),
      size: 100
    },
    {
      size: MORE_BTN_SIZE
    }
  ], [t]);

  const onCloseCreateManager = useCallback(e => {
    e?.preventDefault();
    setCreateManager(false);
    setSelectedManagerId(null);
    setSelectedManager(null);
  }, []);
  const onCancelFreezeUser = useCallback((e) => {
    e.stopPropagation();
    setIsMorePopupOpen(false);
    setIsFreezeAlertOn(false);
    setSelectedManagerId(null);
    setSelectedManager(null)
  }, []);
  const onCancelUnFreezeUser = useCallback((e) => {
    e.stopPropagation();
    setIsMorePopupOpen(false);
    setIsUnFreezeAlertOn(false);
    setSelectedManagerId(null);
    setSelectedManager(null)
  }, []);
  const onCloseEditManager = useCallback(e => {
    e?.preventDefault();
    setIsMorePopupOpen(false);
    setIsEditProfileOn(false);
    setIsEditPasswordOn(false);
    setSelectedManagerId(null);
    setSelectedManager(null)
  }, []);

  const onChangeLimit = useCallback(option => {
    if (option.value === limit.value) {
      return;
    }

    setLimit(option);
    setOffset(0);
    setLoading(true);
    setCurrentPage(0);
  }, [limit]);
  const onSearch = useCallback(({ search, searchField }) => {
    const branchIds = userCompanyBranches?.list.map(({ id }) => id);
    const companyAddressIds = branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : '';

    setLoading(true);

    api
      .get(`/employees/managers?offset=${offset}&limit=${limit.value}${search && searchField ? `&search=${search}&searchField=${searchField}` : ''}&freezed${companyAddressIds}`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setManagersData(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(errToString(err));
        setLoading(false);
      });
  }, [userCompanyBranches?.list, offset, limit])
  const onFreezeUser = useCallback((e) => {
    e.stopPropagation();

    api
      .delete(`/employees/managers/${selectedManagerId}`)
      .then(() => {
        setManagersData(managersData.map((data) => {
          if (selectedManagerId === data.id) {
            return {
              ...data,
              deletedAt: new Date()
            }
          }
          return data;
        }));
        setIsFreezeAlertOn(false);
        setIsMorePopupOpen(false);

        const audio = new Audio(sound);
        const info = `${t("pages.managers.manager")} ${t("pages.managers.managerHasBeenFrozen")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch(err => console.log(errToString(err)));
  }, [t, selectedManagerId, managersData, setManagersData]);
  const onUnFreezeUser = useCallback((e) => {
    e.stopPropagation();

    api
      .put(`/employees/managers/${selectedManagerId}`, {
        restore: true
      })
      .then(() => {
        setIsUnFreezeAlertOn(false);
        setIsMorePopupOpen(false);
        setManagersData(managersData.map((data) => {
          if (selectedManagerId === data.id) {
            return {
              ...data,
              deletedAt: null
            }
          }
          return data;
        }));

        const audio = new Audio(sound);
        const info = `${t("pages.managers.manager")} ${t("pages.managers.managerHasBeenUnFrozen")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch(err => console.log(errToString(err)));
  }, [t, selectedManagerId, managersData, setManagersData]);
  const onShowMore = useCallback((e, id, user) => {
    e.stopPropagation();
    setIsMorePopupOpen(true);
    setSelectedManagerId(id);
    setMorePopupUser(user);
  }, []);

  useEffect(() => {
    api
      .get(`/employees/managers?offset=${offset}&limit=${limit.value}${showFrozenManagers ? "&freezed" : ""}&companyAddressIds=all`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setManagersData(res.data.results);
        setLoading(false);
      })
      .catch((err) => console.log(errToString(err)));
  }, [showFrozenManagers, offset, limit]);
  useEffect(() => {
    if (!selectedManagerId) {
      return;
    }

    if (
      selectedManagerId &&
      (isEditProfileOn || isEditPasswordOn)
    ) {
      api
        .get(`/employees/managers/${selectedManagerId}?freezed`)
        .then(({ data }) => {
          setSelectedManager(data);
        })
        .catch(err => console.log(errToString(err)));
    }
  }, [selectedManagerId, isEditProfileOn, isEditPasswordOn]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container>
      <div className="managers">
        <PageHeader>
        <p>{t("pages.managers.managers")}</p>
      </PageHeader>
        <div className="managers_in_actions">
          <Search
            placeholder={t("pages.managers.searchManager")}
            options={searchFieldsManagers}
            onSearch={onSearch}
          />
          <div className="managers_in_actions__switcher">
            <SwitcherBtn
              checked={showFrozenManagers}
              setChecked={setShowFrozenManagers}
              type={"freeze-unfreeze-managers"}
            />
          </div>
          {user.role.permissions.includes("create_manager") && (
            <button
              onClick={() => setCreateManager(true)}
            >
              {windowWidth > 600 ? t("components.form.createManager") : <BsPlus />}
            </button>
          )}
          {createManager && (
            <PopUp>
              <CreateManager
                onClose={onCloseCreateManager}
                managers={managersData}
                setManagers={setManagersData}
              />
            </PopUp>
          )}
        </div>
        <div className="managers_in">
        <Table
          route={user.role.permissions.includes("see_manager_details") ? "managers" : ""}
          titles={managerTableTitles}
          loading={loading}
          hiddenFields={['id']}
          rows={managersData?.map(manager => ({
            id: manager.id,
            manager: (
              <span className="table__avatar">
                <img
                  src={manager.src}
                  alt=""
                />
                <p>{manager.firstName} {manager.lastName}</p>
              </span>
            ),
            username: (
              <p>{manager.username}</p>
            ),
            phoneNumber: (
              <p>{manager.phoneNumber}</p>
            ),
            email: manager.email ? (
              <p>{manager.email}</p>
            ) : <p>n/a</p>,
            role: (
              <p>{manager.role.name}</p>
            ),
            state: (
              <Status type='table' status={manager.deletedAt ? "freezed" : "active"}/>
            ),
            more: (user.role.permissions.includes("update_manager") || user.role.permissions.includes("delete_manager")) && (
              <div
                ref={el => morePopupRef.current[manager.id] = el}
                className="table_body_menu"
                onClick={e => onShowMore(e, manager.id, manager)}
              >
                <BsThreeDotsVertical/>
              </div>
            )
          })) || []}
        />
      </div>
        {isEditProfileOn && selectedManager && (
          <PopUp>
            <UpdateManager
              manager={selectedManager}
              onClose={onCloseEditManager}
              setManagersData={setManagersData}
              managersData={managersData}
            />
          </PopUp>
        )}
        {isEditPasswordOn && selectedManager && (
          <PopUp>
            <UpdateManagerPassword
              manager={selectedManager}
              onClose={onCloseEditManager}
            />
          </PopUp>
        )}
        {isFreezeAlertOn && createPortal(
          <>
            <div
              className="managers_in_freeze_alert"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="managers_in_freeze_alert_title">{t("pages.managers.freezeManagerWarning")} {morePopupUser.firstName} {morePopupUser.lastName} ?</div>
              <div className="managers_in_freeze_alert_actions">
                <button
                  className="managers_in_freeze_alert_btn managers_in_freeze_alert_btn_cancel"
                  onClick={onCancelFreezeUser}
                >{t("pages.managers.cancel")}</button>
                <button
                  className="managers_in_freeze_alert_btn managers_in_freeze_alert_btn_submit managers_in_freeze_alert_btn_submit--freeze"
                  onClick={onFreezeUser}
                >{t("pages.managers.freeze")}</button>
              </div>
            </div>
            <div
              className="managers_in_freeze_alert_overlay"
              onClick={onCancelFreezeUser}
            ></div>
          </>, document.getElementById('modal')
        )}
        {isUnFreezeAlertOn && createPortal(
          <>
            <div
              className="managers_in_freeze_alert"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="managers_in_freeze_alert_title">{t("pages.managers.unFreezeManagerWarning")} {morePopupUser.firstName} {morePopupUser.lastName} ?</div>
              <div className="managers_in_freeze_alert_actions">
                <button
                  className="managers_in_freeze_alert_btn managers_in_freeze_alert_btn_cancel"
                  onClick={onCancelUnFreezeUser}
                >{t("pages.managers.cancel")}</button>
                <button
                  className="managers_in_freeze_alert_btn managers_in_freeze_alert_btn_submit managers_in_freeze_alert_btn_submit--unfreeze"
                  onClick={onUnFreezeUser}
                >{t("pages.managers.unFreeze")}</button>
              </div>
            </div>
            <div
              className="managers_in_freeze_alert_overlay"
              onClick={onCancelUnFreezeUser}
            ></div>
          </>, document.getElementById('modal')
        )}
        {isMorePopupOpen && selectedManagerId && (
          <MorePopup
            selectedUser={morePopupUser}
            id={selectedManagerId}
            top={morePopupRef.current[selectedManagerId].getBoundingClientRect().top}
            left={morePopupRef.current[selectedManagerId].getBoundingClientRect().left - 200}
            setIsMorePopupOpen={setIsMorePopupOpen}
            setIsFreezeAlertOn={setIsFreezeAlertOn}
            setIsEditProfileOn={setIsEditProfileOn}
            setIsEditPasswordOn={setIsEditPasswordOn}
            setIsUnFreezeAlertOn={setIsUnFreezeAlertOn}
          />
        )}
        {(isEditProfileOn || isEditPasswordOn) && !selectedManager && createPortal(
          <>
            <PopUp>
              <div className="assign_order_loading">
                <Loading />
              </div>
            </PopUp>
          </>, document.getElementById('modal')
        )}
        </div>
      {!loading && !!pagesCount && !!managersData?.length && (
          <Pagination
              pagesCount={pagesCount}
              setOffset={setOffset}
              limit={limit}
              limitPlaceholder={limit.label}
              limits={LIMITS}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onChange={onChangeLimit}
          />
      )}
    </Container>
  );
}

export default memo(Managers);
