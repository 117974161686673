import { useReducer, useCallback, memo } from "react";
import AnalyticsContext from "./analytics-context";

const defaultAnalyticsState = {
  analytics: null,
  set: () => {},
  update: () => {}
};

const analyticsReducer = (state, action) => {
  if (action.type === "SET_ANALYTICS") {
    return {
      ...state,
      analytics: action.payload
    };
  }

  return defaultAnalyticsState;
};

const AnalyticsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(analyticsReducer, defaultAnalyticsState);

  const setAnalytics = useCallback(analytics => {
      dispatch({
        type: "SET_ANALYTICS",
        payload: { analytics },
      });
    }, [dispatch]);

  const analyticsContext = {
    analytics: state.analytics,
    set: setAnalytics
  };

  return (
    <AnalyticsContext.Provider value={analyticsContext}>{children}</AnalyticsContext.Provider>
  );
};

export default memo(AnalyticsProvider);
