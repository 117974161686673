import {memo} from "react";
import "./index.scss";
import SignIn from "./sign-in";
import ForgotPassword from "./forgot-password";
import SignUp from "./sign-up";

function Auth({
authType,
setAuthType,
setShowSignUpWarning
}) {

  return (
    <div className="auth">
      {authType === "sign-in" && (
        <SignIn setAuthType={setAuthType} />
      )}
      {authType === "forgotPass" && (
        <ForgotPassword setAuthType={setAuthType}/>
      )}
      {authType === "sign-up" && (
        <SignUp setShowSignUpWarning={setShowSignUpWarning}/>
      )}
    </div>
  );
}

export default memo(Auth);