import { memo } from "react";
import "./index.scss";
import cn from "classnames";
import PropTypes from "prop-types";

function Container({
 children,
 disableInnerPadding = false,
 flexDirClmn = false,
 short = false
}) {

  return (
    <div className={cn(
      "container",
      {"container--disable_inner_padding" : disableInnerPadding},
      {"container--flex_dir_clmn" : flexDirClmn},
      {"container--short" : short},
      )}
    >
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.any,
  disableInnerPadding: PropTypes.bool,
  flexDirClmn: PropTypes.bool
};

export default memo(Container);
