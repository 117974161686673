import './index.scss';
import {memo, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

function Marker({ options, map }) {
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (marker) {
      return;
    }

    let _marker = null;

    if (options.icon) {
      _marker = new window.google.maps.Marker({
        map,
        ...options
      });

      if (options.content && !options.open) {
        const infoWindow = new window.google.maps.InfoWindow({ content: options.content});
        window.google.maps.event.addListener(_marker, 'mouseover', () => infoWindow.open(map, _marker));
        window.google.maps.event.addListener(_marker, 'mouseout', () => infoWindow.close(map, _marker));
        _marker.infoWindow = infoWindow;
      }
      if (options.content && options.open) {
        _marker.infoWindow = new window.google.maps.InfoWindow({ content: options.content});
        _marker.infoWindow.open(map, _marker);
      }
      if (options.onClick) {
        window.google.maps.event.addListener(_marker, 'click', options.onClick);
      }
      if (options.onDragEnd) {
        window.google.maps.event.addListener(_marker, 'dragend', options.onDragEnd);
      }

      setMarker(_marker);
    }
    if (options.img) {
      _marker = new window.google.maps.Marker({
        map,
        ...options,
        icon: {
          url: options.img.toDataURL(),
          scaledSize: new window.google.maps.Size(...options.imgSize)
        }
      });

      if (options.content && !options.open) {
        const infoWindow = new window.google.maps.InfoWindow({ content: options.content});
        window.google.maps.event.addListener(_marker, 'mouseover', () => infoWindow.open(map, _marker));
        window.google.maps.event.addListener(_marker, 'mouseout', () => infoWindow.close(map, _marker));
        _marker.infoWindow = infoWindow;
      }
      if (options.content && options.open) {
        _marker.infoWindow = new window.google.maps.InfoWindow({ content: options.content});
        _marker.infoWindow.open(map, _marker);
      }
      if (options.onClick) {
        window.google.maps.event.addListener(_marker, 'click', options.onClick);
      }
      if (options.onDragEnd) {
        window.google.maps.event.addListener(_marker, 'dragend', options.onDragEnd);
      }

      setMarker(_marker);
    }
  }, [marker, options, map]);
  useEffect(() => {
    if (!marker) {
      return;
    }

    if (options.position && !options.disableAnimation) {
      marker.animateTo(new window.google.maps.LatLng(options.position));
    }
    if (options.position && options.disableAnimation) {
      marker.setPosition(new window.google.maps.LatLng(options.position));
    }

    if (options.icon) {
      marker.setIcon(options.icon);
    }
    if (options.img) {
      marker.setIcon({
        url: options.img.toDataURL(),
        scaledSize: new window.google.maps.Size(...options.imgSize)
      });
    }

    marker.infoWindow?.setContent(options.content);
    marker.setVisible(options.visible);
  }, [marker, options.position, options.icon, options.img, options.imgSize, options.content, options.disableAnimation, options.visible, options.status]);
  useEffect(() => {
    if (!marker) {
      return;
    }

    window.google.maps.event.clearListeners(marker, 'click');
    window.google.maps.event.addListener(marker, 'click', options.onClick);
  }, [marker, options.onClick]);
  useEffect(() => {
    return () => {
      if (!marker) {
        return;
      }

      marker.setMap(null);
      setMarker(null);
    };
  }, [marker]);

  return null;
}

Marker.propTypes = {
  map: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

export default memo(Marker);
