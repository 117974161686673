import {memo, useEffect, useState} from "react";
import "./index.scss";
import {Link, useNavigate} from "react-router-dom";
import Loading from "../../ui/loading";
import PropTypes from "prop-types";
import {MdArrowBackIos, MdArrowForwardIos} from "react-icons/md";

function PageHeader({
  children,
  type,
  isCustomer = false ,
  link,
  linkTitle,
  pageTitle,
  onClick = () => null,
  data,
  loading,
  isPartner = false
}) {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {type !== "navigation" && (
        <div className="page_header">
          {children}
        </div>
      )}
      {type === "navigation" && (
        <div className="page_header_navigation">
          {windowWidth > 600 && (
            <>
              <Link to={link} className="page_header_navigation--title">{linkTitle}</Link>
              <MdArrowForwardIos />
              <p className="page_header_navigation--active">{pageTitle}</p>
              <div className="page_header_profile">
                <div className="page_header_profile__data" onClick={onClick}>
                  {!isCustomer && !isPartner && (
                    <>
                      <span>username:</span>
                      <p>{data?.username}</p>
                      <div className="page_header_profile__data_img">
                        {loading && (
                          <Loading/>
                        )}
                        {!loading && (
                          <img src={data.src} alt=""/>
                        )}
                      </div>
                    </>
                  )}
                  {isPartner && !isCustomer && (
                    <>
                      <span>name:</span>
                      <p>{data?.name}</p>
                      <div className="page_header_profile__data_img">
                        {loading && (
                          <Loading/>
                        )}
                        {!loading && (
                          <img src={data.src} alt=""/>
                        )}
                      </div>
                    </>
                  )}
                  {isCustomer && !isPartner && (
                    <>
                      {data?.firstName && (
                        <p>{data?.firstName}</p>
                      )}
                      {data?.lastName && (
                        <p>{data?.lastName}</p>
                      )}
                      <p>{data?.phoneNumber}</p>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {windowWidth <= 600 && (
            <>
              <MdArrowBackIos onClick={() => navigate(link)}/>
              <Link to={link} className="page_header_navigation--active">{linkTitle}</Link>
              <div className="page_header_profile">
                <div className="page_header_profile__data" onClick={onClick}>
                  {!isCustomer && !isPartner && (
                    <>
                      <span>username:</span>
                      <p>{data?.username}</p>
                      <div className="page_header_profile__data_img">
                        {loading && (
                          <Loading/>
                        )}
                        {!loading && (
                          <img src={data.src} alt=""/>
                        )}
                      </div>
                    </>
                  )}
                  {isPartner && !isCustomer && (
                    <>
                      <span>name:</span>
                      <p>{data?.name}</p>
                      <div className="page_header_profile__data_img">
                        {loading && (
                          <Loading/>
                        )}
                        {!loading && (
                          <img src={data.src} alt=""/>
                        )}
                      </div>
                    </>
                  )}
                  {isCustomer && !isPartner && (
                    <>
                      {data?.firstName && (
                        <p>{data?.firstName}</p>
                      )}
                      {data?.lastName && (
                        <p>{data?.lastName}</p>
                      )}
                      <p>{data?.phoneNumber}</p>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

PageHeader.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  isCustomer: PropTypes.bool,
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  pageTitle: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.object
};

export default memo(PageHeader);
