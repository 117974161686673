import './index.scss';
import {memo, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Icon, Style} from 'ol/style';
import VectorSource from 'ol/source/Vector';
import {Collection, Feature, Overlay} from 'ol';
import {Point} from 'ol/geom';
import {fromLonLat, toLonLat} from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import {Translate} from 'ol/interaction';

//This was original
export function resizeImage1(src, width, height, status, callback) {
  const img = new Image();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const fillColor = status === 'online' ? 'rgba(0, 128, 0, 0.8)' : 'rgba(255, 0, 0, 0.8)';
  ctx.imageSmoothingQuality = 'high';

  img.onload = function() {
    canvas.width = width;
    canvas.height = height;

    ctx.beginPath();
    ctx.arc(width / 2, height / 2, width / 2, 0, 2 * Math.PI, false);
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.arc(width / 2, height / 2, (width / 2) - 2, 0, 2 * Math.PI, false);
    ctx.fillStyle = 'white';
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.arc(width / 2, height / 2, (width / 2) - 5, 0, 2 * Math.PI, false);
    ctx.closePath();
    ctx.clip();
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    return callback(canvas);
  };
  img.src = src;
  img.crossOrigin = 'anonymous';
}

//This is new function, it makes quality a little bit better
export function resizeImage(src, displayWidth, displayHeight, status, callback) {
  const img = new Image();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const fillColor = status === 'online' ? 'rgba(0, 128, 0, 0.8)' : 'rgba(255, 0, 0, 0.8)';
  canvas.width = displayWidth * 2; // Double the resolution for better quality
  canvas.height = displayHeight * 2; // Double the resolution for better quality

  img.onload = function() {
    // Draw the clipping path
    ctx.beginPath();
    ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2, 0, 2 * Math.PI, false);
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.closePath();

    // Draw a white border
    ctx.beginPath();
    ctx.arc(canvas.width / 2, canvas.height / 2, (canvas.width / 2) - 2, 0, 2 * Math.PI, false);
    ctx.fillStyle = 'white';
    ctx.fill();
    ctx.closePath();

    // Adjust the clip path for the image
    ctx.beginPath();
    ctx.arc(canvas.width / 2, canvas.height / 2, (canvas.width / 2) - 5, 0, 2 * Math.PI, false);
    ctx.clip();

    // Draw the image with high resolution
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    // Scale down the canvas for display
    const scaledCanvas = document.createElement('canvas');
    const scaledCtx = scaledCanvas.getContext('2d');
    scaledCanvas.width = displayWidth;
    scaledCanvas.height = displayHeight;
    scaledCtx.drawImage(canvas, 0, 0, displayWidth, displayHeight);

    return callback(scaledCanvas);
  };
  img.src = src;
  img.crossOrigin = 'anonymous';
}

function translate(fromX, fromY, toX, toY, callback) {
  let start = null;
  const duration = 500;
  const step = timestamp => {
    if (!start) {
      start = timestamp;
    }
    const progress = timestamp - start;
    const percent = Math.min(progress / duration, 1);

    const newX = fromX + (toX - fromX) * percent;
    const newY = fromY + (toY - fromY) * percent;

    callback(newX, newY);

    if (progress < duration) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

function Marker({ options, map, isMapDragging, isDraggable }) {
  const [marker, setMarker] = useState(null);
  const [overlay, setOverlay] = useState(null);

  useEffect(() => {
    if (marker || !options.position || options.visible === false) {
      return;
    }

    const _feature = new Feature({
      geometry: new Point(fromLonLat([options.position.lng, options.position.lat])),
      id: options.id,
      type: options.type,
      onClick: options.onClick,
      open: options.open
    });
    _feature.setId(options.id);
    let _marker = null;

    if (options.content) {
      const el = document.createElement('div');
      el.classList.add('marker_overlay');
      if (options.className) {
        el.classList.add(options.className);
      }
      el.innerHTML = options.content;
      el.innerHTML = el.innerText;
      document.body.appendChild(el);

      const _overlay = new Overlay({
        element: el
      });
      _overlay.setProperties({
        id: options.id,
        type: options.type,
        open: options.open
      })
      map.addOverlay(_overlay);

      if (options.open) {
        _overlay.setPosition(_feature.getProperties().geometry.flatCoordinates);
      }

      setOverlay(_overlay);
    }
    if (options.onDragEnd) {
      const _translate = new Translate({
        features: new Collection([_feature])
      });
      map.addInteraction(_translate);

      _translate.on('translatestart', () => {
        document.body.style.cursor = 'grab';
      });
      _translate.on('translating', () => {
        document.body.style.cursor = 'grab';
      });
      _translate.on('translateend', e => {
        document.body.style.cursor = 'default';

        const latLng = toLonLat(e.coordinate);

        options.onDragEnd({
          latLng: {
            lng: () => latLng[0],
            lat: () => latLng[1]
          }
        });
      });
    }

    if (options.icon) {
      _marker = new VectorLayer({
        style: new Style({
          image: new Icon({
            src: options.icon
          })
        }),
        source: new VectorSource({
          features: [_feature]
        })
      });
      map.addLayer(_marker);
      setMarker(_marker);
    }
    if (options.img) {
      _marker = new VectorLayer({
        style: new Style({
          image: new Icon({
            img: options.img,
            imgSize: options.imgSize
          })
        }),
        source: new VectorSource({
          features: [_feature]
        })
      });
      map.addLayer(_marker);
      setMarker(_marker);
    }
  }, [marker, options, map]);
  useEffect(() => {
    if (!marker) {
      return;
    }

    if (options.visible === false) {
      map.removeLayer(marker);
      setMarker(null);
    }

    if (options.icon) {
      marker.setStyle(new Style({
        image: new Icon({
          src: options.icon
        })
      }));
    }
    if (options.img) {
      marker.setStyle(new Style({
        image: new Icon({
          img: options.img,
          imgSize: options.imgSize
        })
      }));
    }

    (async () => {
      const source = await marker.getSource();
      const features = await source.getFeatures();
      const feature = features[0];

      const prevPoint = feature.getGeometry();
      const point = new Point(fromLonLat([options.position.lng, options.position.lat]));
      const fromX = prevPoint.getCoordinates()[0];
      const fromY = prevPoint.getCoordinates()[1];
      const toX = point.getCoordinates()[0];
      const toY = point.getCoordinates()[1];

      if (!isDraggable) {
        translate(fromX, fromY, toX, toY, (newX, newY) => {
          feature.setGeometry(new Point([newX, newY]));

          if (options.open && !isMapDragging) {
            overlay.setPosition(feature.getProperties().geometry.flatCoordinates);
          }
        });
      }

      if (options.open && !isMapDragging) {
        overlay.setPosition(feature.getProperties().geometry.flatCoordinates);
      }
      if (options.content) {
        const el = document.createElement('div');
        el.classList.add('marker_overlay');
        if (options.className) {
          el.classList.add(options.className);
        }
        el.innerHTML = options.content;
        el.innerHTML = el.innerText;
        document.body.appendChild(el);

        overlay.setElement(el);
      }
    })();
  }, [map, marker, overlay, isDraggable, options.visible, options.position, options.open, options.content, options.status, options.className, options.icon, options.img, options.imgSize, options.animateView, isMapDragging]);
  useEffect(() => {
    return () => {
      if (!marker) {
        return;
      }

      map.removeLayer(marker);
      setMarker(null);

      map.removeOverlay(overlay);
      setOverlay(null);
    };
  }, [map, marker, overlay, isDraggable]);

  return null;
}

Marker.propTypes = {
  map: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  isMapDragging: PropTypes.bool.isRequired,
  isDraggable: PropTypes.bool
};

export default memo(Marker);
