import "./index.scss";
import {memo, useCallback, useState, useRef, useMemo, useEffect} from "react";
import PageHeader from "../../../../components/layout/page-header";
import Table from "../../../../components/table";
import Overlay from "../../../../components/ui/overlay";
import {BsPlus, BsThreeDotsVertical} from "react-icons/bs";
import {TbTrashX} from "react-icons/tb";
import api from "../../../../utils/api";
import Loading from "../../../../components/ui/loading";
import EditRoleForm from "./edit-role-form";
import DeleteRoleForm from "./delete-role-form";
import CreateRoleForm from "./create-role-form";
import AssignRoleForm from "./assign-role-form";
import {useTranslation} from "react-i18next";
import {FiEdit} from "react-icons/fi";
import {errToString} from "../../../../utils";

function SettingsCompanyRoles() {
  const editRoleRef = useRef({});
  const {t} = useTranslation();

  const [selectedRole, setSelectedRole] = useState(null);
  const [roles, setRoles] = useState(null);
  const [isEditRoleFormOpen, setIsEditRoleFormOpen] = useState(false);
  const [isDeleteRoleFormOpen, setIsDeleteRoleOpen] = useState(false);
  const [isCreateRoleFormOpen, setIsCreateRoleFormOpen] = useState(false);
  const [isAssignRoleFormOpen, setIsAssignRoleFormOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const roleTitles = useMemo(() => [
    {
    },
    {size: 50},
    {size: 50}
  ], []);

  const onEditRole = useCallback((e, role) => {
    e.stopPropagation();

    setIsEditRoleFormOpen(true);
    setSelectedRole(role);
  }, []);
  const onDeleteRole = useCallback((e, role) => {
    e.stopPropagation();

    setIsDeleteRoleOpen(true);
    setSelectedRole(role);
  }, []);
  const onCancelRoleForm = useCallback(() => {
    setIsEditRoleFormOpen(false);
    setIsAssignRoleFormOpen(false);
    setIsDeleteRoleOpen(false);
    setIsCreateRoleFormOpen(false);
  }, []);

  useEffect(() => {
    api
      .get("/roles")
      .then((res) => {
        setRoles(res.data);
        setLoading(false);
      })
      .catch(err => console.log(errToString(err)))
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="settings_company_roles">
      <PageHeader>
        <p>{t("pages.settings.roles")}</p>
        <div
          className="settings_company_roles_btn"
          onClick={() => setIsAssignRoleFormOpen(true)}
        >
          {windowWidth > 600 ? t("pages.settings.assignRoleBtn") : <FiEdit fontSize={15}/>}
        </div>
        <div
          className="settings_company_roles_btn"
          onClick={(e) => {
            e.stopPropagation();
            setIsCreateRoleFormOpen(true);
          }}
        >
          {windowWidth > 600 ? t("pages.settings.createRoleBtn") : <BsPlus fontSize={22}/>}
        </div>
      </PageHeader>
      <div className="settings_company_roles_in">
        <div className="settings_company_roles_in__descrip">
          <p>{t("pages.settings.rolesDescription")}</p>
        </div>
        <div className="settings_company_roles_in_options">
          {!loading && (
            <Table
              titles={roleTitles}
              loading={false}
              hiddenFields={['id']}
              rows={roles.map(role => ({
                id: role.id,
                role: (
                  <p className="settings_company_roles_in_options_name">{role.name} {role.deletedAt ? '(freezed)' : ''}</p>
                ),
                more: role.name.toLowerCase() !== "default" && (
                  <div
                    ref={el => editRoleRef.current[role.id] = el}
                    onClick={e => onEditRole(e, role)}
                  >
                    <BsThreeDotsVertical/>
                  </div>
                ),
                delete: role.name.toLowerCase() !== "default" && (
                  <div
                    className="partners_in_icon partners_in_icon--delete"
                    ref={el => editRoleRef.current[role.id] = el}
                    onClick={e => onDeleteRole(e, role)}
                  >
                    <TbTrashX/>
                  </div>
                )
              })) || []}
            />
          )}
          {loading && (
            <div className="settings_company_payment_methods_in_options settings_company_payment_methods_in_options_loading">
              <Loading/>
            </div>
          )}
        </div>
        {isEditRoleFormOpen && (
          <>
            <EditRoleForm
              onCancel={onCancelRoleForm}
              selectedRole={selectedRole}
              setRoles={setRoles}
              roles={roles}
            />
            <Overlay onClick={onCancelRoleForm}/>
          </>
        )}
        {isAssignRoleFormOpen && (
          <>
            <AssignRoleForm
              onCancel={onCancelRoleForm}
            />
            <Overlay onClick={onCancelRoleForm}/>
          </>
        )}
        {isDeleteRoleFormOpen && (
          <>
            <DeleteRoleForm
              onCancel={onCancelRoleForm}
              selectedRole={selectedRole}
              setRoles={setRoles}
              roles={roles}
            />
            <Overlay onClick={onCancelRoleForm}/>
          </>
        )}
        {isCreateRoleFormOpen && (
          <>
            <CreateRoleForm
              onCancel={onCancelRoleForm}
              setRoles={setRoles}
              roles={roles}
            />
            <Overlay onClick={onCancelRoleForm}/>
          </>
        )}
      </div>
    </div>
  );
}

export default memo(SettingsCompanyRoles);
