import './index.scss';
import React, {memo, useCallback, useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import api from "../../../../../../utils/api";
import Loading from '../../../../../../components/ui/loading';
import moment from "moment/moment";
import sound from "../../../../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {errToString} from "../../../../../../utils";
import AssignAgentForm from "../../../../../../components/assign-agent-form";
import {BsPlus} from "react-icons/bs";

function UpdatePartner({ onClose, order, startDate, endDate }) {

  const {t} = useTranslation();

  const [pending, setPending] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [partnersData, setPartnersData] = useState(null);
  const [assignedPartner, setAssignedPartner] = useState(order.partner);
  const partnersOptions = useMemo(() => {
    if (!partnersData) {
      return [];
    }

    return partnersData.map(partner => ({
      value: partner?.id,
      label: `${partner?.name}`,
      ...partner
    }))
  }, [partnersData]);

  const onSubmit = useCallback(e => {
    e.preventDefault();

    if (pending) {
      return;
    }

    const data = {
      partnerId: assignedPartner?.id || order.partner.id,
      amount: order.amount,
      paymentMethodId: order.paymentMethod.id,
      deliveryType: order.deliveryType,
      startDate: order.startDate,
      endDate: order.endDate,
      note: order.note,
      status: order.status
    };

    setPending(true);

    api
      .put(`/orders/${order.id}`, data)
      .then(() => {
        setPending(false);
        onClose();

        const audio = new Audio(sound);
        const info = `${t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.order")} #${order.externalId} ${t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.hasBeenUpdated")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch((err) => {
        setPending(false);
        alert(t(`errors.${errToString(err)}`));
        console.log(errToString(err));
      })
  }, [t, pending, order, onClose, assignedPartner]);

  useEffect(() => {
    const from = moment(startDate).utc().format();
    const to = moment(endDate).utc().format();
    const companyAddressIds = order.pickupAddress.id ? `&companyAddressIds=${order.pickupAddress.id}` : '';

    api
      .get(`/partners?from=${from}&to=${to}${companyAddressIds}`)
      .then(({ data }) => {
        setPartnersData(data.results);
      })
      .catch((err) => console.log(errToString(err)));
  }, [startDate, endDate, order.pickupAddress.id]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
      <>
        {!!partnersOptions.length && (
          <div className="update_partner">
            <div className="update_partner_in">
              <AssignAgentForm
                show={true}
                setShow={() => true}
                assignTypeAgents={false}
                setAssignTypeAgents={() => false}
                agentsOptions={[]}
                partnersOptions={partnersOptions}
                pickupAddress={order.pickupAddress.name}
                setAssignedPartner={setAssignedPartner}
                setAssignedAgent={() => {}}
                pickupAddressLocation={order.pickupAddress.location}
                assignedAgent={null}
                assignedPartner={assignedPartner}
                showTitleBtn={false}
              />
            </div>
            <div className="update_partner_in_fixed">
              {assignedPartner && (
                <div className="assign_order_in_fixed_assignBtn assign_order_in_fixed_assignBtn--assigned">
                  {assignedPartner.name}
                </div>
              )}
              <div className="update_partner_in_fixed_actions">
                {windowWidth > 800 && (
                  <>
                    {!pending && (
                      <button
                        className="update_partner_in_fixed_actions_submit"
                        onClick={onSubmit}
                      >
                        {t("pages.dashboard.orders_modal.orders_list.order_item.assign_order.assign")}
                      </button>
                    )}
                    {pending && (
                      <button className="update_partner_in_fixed_actions_submit update_partner_in_fixed_actions_submit--pending">
                        <Loading />
                      </button>
                    )}
                  </>
                )}
                {windowWidth <= 800 && (
                  <div
                    className="update_order_submit_btn--round"
                    onClick={onSubmit}
                  >
                    <BsPlus />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {!partnersOptions.length && (
          <div className="assign_order_loading">
            <Loading />
          </div>
        )}
      </>
  );
}

UpdatePartner.propTypes = {
  onClose: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  order: PropTypes.object.isRequired
};

export default memo(UpdatePartner);
