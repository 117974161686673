import './index.scss';
import {memo, useContext} from "react";
import PropTypes from "prop-types";
import {createPortal} from 'react-dom';
import {useTranslation} from "react-i18next";
import AuthContext from "../../../context/auth/auth-context";

function MorePopup({
 setIsFreezeAlertOn,
 setIsUnFreezeAlertOn,
 setIsEditProfileOn,
 setIsEditPasswordOn,
 setIsMorePopupOpen,
 top,
 left,
 selectedUser,
 setIsDeleteAlertOn
}) {
  const {t} = useTranslation();
  const {user} = useContext(AuthContext);

  return (
      <>
        <div
          style={{top: top, left: left}}
          className="more_popup"
        >
          {(user.role.permissions.includes("update_agent") || user.role.permissions.includes("update_manager")) && !selectedUser?.deletedAt && (
            <>
              <div
                className="more_popup__item"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditProfileOn(true);
                  setIsMorePopupOpen(false);
                }}
              >{t('components.ui.more_popup.editProfile')}</div>
              <div
                className="more_popup__item"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditPasswordOn(true);
                  setIsMorePopupOpen(false);
                }}
              >{t('components.ui.more_popup.editPassword')}</div>
            </>
          )}
          {(user.role.permissions.includes("delete_agent") || user.role.permissions.includes("delete_manager")) && (
            <>
              {!selectedUser?.deletedAt && (
                <div
                  className="more_popup__item"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsFreezeAlertOn(true);
                    setIsMorePopupOpen(false);
                  }}
                >{t('components.ui.more_popup.freeze')}</div>
              )}
              {selectedUser?.deletedAt && (
                <>
                  <div
                    className="more_popup__item"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsUnFreezeAlertOn(true);
                      setIsMorePopupOpen(false);
                    }}
                  >{t('components.ui.more_popup.unFreeze')}</div>
                  {selectedUser.type === "agent" && (
                    <div
                      className="more_popup__item"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsDeleteAlertOn(true);
                        setIsMorePopupOpen(false);
                      }}
                    >{t('components.ui.more_popup.delete')}</div>
                  )}
                </>
              )}
            </>
          )}
      </div>
        {createPortal(
          <div
            className="more_popup__overlay more_popup--transparent"
            onClick={() => {
              setIsMorePopupOpen(false);
              setIsFreezeAlertOn(false);
            }}
          />,
          document.getElementById('modal')
        )}
      </>
  );
}

MorePopup.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  setIsFreezeAlertOn: PropTypes.func.isRequired,
  setIsUnFreezeAlertOn: PropTypes.func.isRequired,
  setIsEditProfileOn: PropTypes.func.isRequired,
  setIsEditPasswordOn: PropTypes.func.isRequired,
  setIsMorePopupOpen: PropTypes.func.isRequired,
  position: PropTypes.string
};

export default memo(MorePopup);
