import {memo, useCallback, useState, useEffect, useContext} from "react";
import "./index.scss";
import api from "../../../utils/api";
import DropDown from "../../../components/ui/drop-down";
import Cover1 from "../../../assets/cover_sign_up.jpeg"
import Logo from "../../../assets/logo.png";
import cn from "classnames";
import {useNavigate} from "react-router-dom";
import {BsFillCheckCircleFill} from "react-icons/bs";
import {BiBuilding, BiHide, BiShow} from "react-icons/bi";
import {MdOutlineError, MdPersonOutline} from "react-icons/md";
import {AiOutlineMail} from "react-icons/ai";
import AuthContext from "../../../context/auth/auth-context";
import {FaMapMarkerAlt} from "react-icons/fa";
import {useDebouncedCallback} from "use-debounce";
import {getLatLngFromAddress, getPlaceDetails, searchAddress} from "../../../utils/geocoding";
import Loading from "../../../components/ui/loading";
import {errToString} from "../../../utils";
import {useTranslation} from "react-i18next";
import {isValidEmail} from "../../../utils/validation";
import i18n from "i18next";
import {RiLockPasswordLine} from "react-icons/ri";
import PhoneNumberInput from "../../../components/ui/phone-number-input";

function SignUp({
lng,
setShowSignUpWarning
}) {
    const {auth} = useContext(AuthContext);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [ip, setIp] = useState("");
    const [error, setError] = useState(null);
    const [page, setPage] = useState("1");
    const [checked, setChecked] = useState(false);
    const [address, setAddress] = useState(null);
    const [code, setCode] = useState('');
    const [addressLoading, setAddressLoading] = useState(false);
    const [isVerificationOpen, setIsVerificationOpen] = useState(false);
    const [geocodingAddresses, setGeocodingAddresses] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showUsernameError, setShowUsernameError] = useState(false);
    const [showPhoneNumberError, setShowPhoneNumberError] = useState(false);

    const [fields, setFields] = useState({
      companyName: '',
      password: '',
      passwordConfirmation: '',
      firstName: '',
      lastName: '',
      email: '',
      country: null,
      city: null,
      street: ''
  });
    const [phoneNumberLoading, setPhoneNumberLoading] = useState(false);
    const [phoneNumberPassed, setPhoneNumberPassed] = useState(false);
    const [usernameLoading, setUsernameLoading] = useState(false);
    const [usernamePassed, setUsernamePassed] = useState(false);
    const [phoneNumber, setPhoneNumber]= useState('');
    const [parsedPhoneNumber, setParsedPhoneNumber] = useState(null);
    const [loading, setLoading] = useState(false);
    const [verificationLoading, setVerificationLoading] = useState(false);
    const [countriesData, setCountriesData] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const onChangeField = useCallback((e, name) => {
    setError(null);

    if (
      e.target.type === 'text' ||
      e.target.type === 'number' ||
      e.target.type === 'password' ||
      e.target.type === 'email'
    ) {
      setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [fields]);
    const onChangePage = useCallback((e) => {
      e.preventDefault();

      if (page === "1" &&
        (
          !phoneNumber ||
          !parsedPhoneNumber.data ||
          !fields.email ||
          !fields.password ||
          !fields.passwordConfirmation
        )
      ) {
        return setError("pleasefillallthefields");
      }

      if (
        page === "1" &&
        phoneNumber &&
        phoneNumberPassed &&
        parsedPhoneNumber.data &&
        fields.email &&
        usernamePassed &&
        !isValidEmail(fields.email)
      ) {
        return setError("email.invalid");
      }

      if (
        page === "1" &&
        phoneNumber &&
        parsedPhoneNumber.data &&
        phoneNumberPassed &&
        fields.email &&
        usernamePassed &&
        isValidEmail(fields.email) &&
        fields.password &&
        fields.passwordConfirmation &&
        fields.password !== fields.passwordConfirmation
      ) {
        return setError("password.does.not.match.password.confirmation");
      }

      if (
        page === "1" &&
        phoneNumber &&
        parsedPhoneNumber.data &&
        phoneNumberPassed &&
        fields.email &&
        usernamePassed &&
        isValidEmail(fields.email) &&
        fields.password &&
        fields.passwordConfirmation &&
        fields.password === fields.passwordConfirmation &&
        (
          fields.password?.length < 6 ||
          fields.passwordConfirmation?.length < 6
        )
      ) {
        return setError("password.string.min");
      }

      if (
        page === "1" &&
        phoneNumber &&
        parsedPhoneNumber.data &&
        phoneNumberPassed &&
        fields.email &&
        usernamePassed &&
        isValidEmail(fields.email) &&
        fields.password &&
        fields.password?.length >= 6 &&
        fields.passwordConfirmation &&
        fields.passwordConfirmation?.length >= 6 &&
        fields.password === fields.passwordConfirmation
      ) {
        return setPage("2");
      }

    }, [phoneNumber, page, fields, phoneNumberPassed, usernamePassed, parsedPhoneNumber]);
    const onResetAddress = useCallback(() => {
    setFields({
      ...fields,
      companyAddress: {
        deliveryAddress: null,
        countryCode: "",
        location: ""
      },
    });
    setGeocodingAddresses([]);
  }, [fields]);
    const onChangeAddress = useDebouncedCallback(value => {
      if (!value) {
        return
      }
      setError(null);

      setAddressLoading(true)
      searchAddress(null, value, (err, addresses) => {
        setGeocodingAddresses(addresses);
        setAddressLoading(false);
      });
    }, 500);
    const onCheckValidation = useDebouncedCallback((field, link) => {
    setError(null);

    if (!phoneNumber) {
      setShowPhoneNumberError(false)
    }
    if (!fields.email) {
      setShowUsernameError(false);
    }
    if (!field) {
      setUsernameLoading(false);
      setPhoneNumberLoading(false);
      return;
    }


    if (link === "username") {
        setUsernameLoading(true);

        return (
          api
            .get(`/validations/username?username=${field?.toLowerCase()}`)
            .then(() => {
              if (isValidEmail(fields.email)) {
                setUsernameLoading(false);
                setShowUsernameError(false);
                return setUsernamePassed(true);
              }
              if (!isValidEmail(fields.email)) {
                setUsernameLoading(false);
                setUsernamePassed(false);
                setShowUsernameError(true);
                return setError('email.invalid');
              }
            })
            .catch((err) => {
              console.log(errToString(err));
              setUsernamePassed(false);
              setUsernameLoading(false);
              setShowUsernameError(true);
              setError(errToString(err));
            })
        )
      }
    if (link === "phone-number") {
      if (!parsedPhoneNumber) {
        return
      }

      setPhoneNumberLoading(true);

      if (parsedPhoneNumber) {
        if (!parsedPhoneNumber.isPossible || !parsedPhoneNumber.isValid) {
          setPhoneNumberPassed(false);
          setPhoneNumberLoading(false);
          setShowPhoneNumberError(true);
          return setError(errToString("phoneNumber.invalid"));
        }
      }

      if (parsedPhoneNumber) {
        if (!parsedPhoneNumber.data) {
          setPhoneNumberPassed(false);
          setPhoneNumberLoading(false);
          setShowPhoneNumberError(false);
          return setError(null);
        }
      }

      if (parsedPhoneNumber) {
        if (parsedPhoneNumber.isPossible && parsedPhoneNumber.isValid && parsedPhoneNumber.data) {
          return (
            api
              .get(`/validations/phone-number?phoneNumber=${field}&countryCode=${parsedPhoneNumber.data.country?.toLowerCase()}`)
              .then(() => {
                setPhoneNumberLoading(false);
                setPhoneNumberPassed(true);
                setShowPhoneNumberError(false);
                setError(null);
              })
              .catch((err) => {
                console.log(errToString(err));
                setPhoneNumberPassed(false);
                setPhoneNumberLoading(false);
                setShowPhoneNumberError(true);
                setError(errToString(err));
              })
          )
        }
      }
    }

  }, 500);

    const onSubmit = useCallback(e => {
      e.preventDefault();

      setError(null);

      if (loading) {
        return
      }

      if (!address) {
        return setError("branch.string.empty");
      }

      setLoading(true);

      getLatLngFromAddress(address.label, (location, countryCode) => {
        getPlaceDetails(address.value, ({ countryCode, country, city, street }) => {
          const data = {
            username: fields.email?.toLowerCase(),
            companyName: fields.companyName,
            phoneNumber: phoneNumber,
            firstName: fields.firstName,
            address: {
              countryCode,
              country,
              city: city || "",
              street: street || "",
              apartment: null,
              entrance: null,
              floor: null,
            },
            lastName: fields.lastName,
            email: fields.email?.toLowerCase(),
            companyAddress: {
              deliveryAddress: address.label,
              countryCode,
              location
            },
            password: fields.password,
            passwordConfirmation: fields.passwordConfirmation,
            isTermsAndConditionsAccepted: checked,
            companyCountryCode: selectedCountry?.value?.toLowerCase() || parsedPhoneNumber.data.country?.toLowerCase()
          };

          for (const key in data) {
            if (data[key] === null) {
              delete data[key];
            }
          }
          for (const key in data.address) {
            if (data.address[key] === null) {
              delete data.address[key];
            }
          }

          api
            .post("/auth/sign-up", data)
            .then(() => {
              setIsVerificationOpen(true);
              setLoading(false);
            })
            .catch((err) => {
              setError(errToString(err));
              setLoading(false);
              console.log(errToString(err))
            });
        })
      })
    }, [loading, phoneNumber, fields, checked, address, selectedCountry, parsedPhoneNumber]);
    const onVerify = useCallback(e => {
      e.preventDefault();

      if (verificationLoading) {
        return
      }

      setVerificationLoading(true);

      api
          .post('/auth/sign-up/verify', { code })
          .then(res => {
            setVerificationLoading(false);
            if (windowWidth <= 600) {
              setShowSignUpWarning(true);
            }
            if (windowWidth > 600) {
              setShowSignUpWarning(false);
            }
            localStorage.setItem('token', res.data.token);
            auth(res.data.user);
            return navigate("/", { replace: true });
          })
          .catch((err) => {
              setVerificationLoading(false);
              setShowSignUpWarning(false);
              setError(errToString(err));
              console.log(errToString(err))
            });
    }, [setShowSignUpWarning, verificationLoading, code, auth, navigate, windowWidth]);

    useEffect(() => {
      i18n.changeLanguage(lng);
    }, [lng]);
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    useEffect(() => {
      api
        .get('https://ipinfo.io?token=21bfeec771f420')
        .then((response) => {
          const data = response.data;
          setIp(data);
        })
        .catch((error) => {
          console.error('Error fetching IP information:', error);
        });

      api
        .get('https://restcountries.com/v3.1/all')
        .then(({ data }) => {
          setCountriesData(data.map((el) => {
            return {
              label: el.name.common,
              value: el.cca2
            }
          }));
        })
        .catch((error) => {
          console.error(error);
        });
    }, []);
    useEffect(() => {
      if (!phoneNumber) {
        return
      }

      setPhoneNumberPassed(false);
      setShowPhoneNumberError(false);
      setError(null);
      onCheckValidation(phoneNumber, "phone-number");
    }, [phoneNumber, onCheckValidation]);
    useEffect(() => {
      if (!parsedPhoneNumber && !countriesData) {
        return
      }

      if (parsedPhoneNumber && countriesData) {
        if (parsedPhoneNumber.isValid && parsedPhoneNumber.isPossible && parsedPhoneNumber.data) {
          let country = countriesData.find(({value}) => value === parsedPhoneNumber?.data?.country);

          return setSelectedCountry({label: country?.label, value: country?.value})
        }
      }
    }, [parsedPhoneNumber, countriesData]);

    return (
      <div className="sign_up">
        <div className="sign_up_in">
          <div className="sign_up_in_cover" style={page === "1" ? {backgroundImage: `url(${Cover1})`} : {backgroundImage: `url(${Cover1})`}}/>
          <div className="sign_up_in_form">
            {!isVerificationOpen && (
              <form className="new_form">
                <img className="new_form_logo" src={Logo} alt="logo"/>
                <h2 className="new_form_title">
                  {page === "1" && t("pages.signUp.accountDetails")}
                  {page === "2" && t("pages.signUp.companyDetails")}
                </h2>
                {page === "1" && (
                  <>
                    <div className="new_form_columns">
                      <div className="new_form_column">
                        <label htmlFor="phoneNumber">
                          {t("pages.signUp.phoneNumber")}
                        </label>
                        <PhoneNumberInput
                          country={ip.country}
                          placeholder={t("pages.signUp.phoneNumber")}
                          value={phoneNumber}
                          setValue={setPhoneNumber}
                          loading={phoneNumberLoading}
                          passed={phoneNumberPassed}
                          showError={showPhoneNumberError}
                          setParsedPhoneNumber={setParsedPhoneNumber}
                        />
                      </div>
                    </div>
                    <div className="new_form_columns">
                      <div className="new_form_column">
                        <label htmlFor="user">
                          {t("pages.signUp.email")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <AiOutlineMail />
                          </div>
                          <input
                            name="user"
                            type="text"
                            className="create_order_wrapper_input"
                            placeholder={t("pages.signUp.email")}
                            autoComplete="off"
                            value={fields.email}
                            onChange={(e) => {
                              setError(null);
                              setUsernamePassed(false);
                              onChangeField(e, 'email');
                              onCheckValidation(e.target.value, "username");
                            }}
                          />
                          <div className="create_order_wrapper_icon create_order_wrapper_icon--loading">
                            {usernameLoading && (
                              <Loading />
                            )}
                            {usernamePassed && (
                              <span className=" create_order_wrapper_icon create_order_wrapper_icon--green">
                                <BsFillCheckCircleFill/>
                              </span>
                            )}
                            {showUsernameError && !usernameLoading && (
                              <span className="create_order_wrapper_icon create_order_wrapper_icon--red">
                                <MdOutlineError />
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="new_form_columns">
                      <div className="new_form_column">
                        <label htmlFor="password">
                          {t("pages.signUp.password")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <RiLockPasswordLine />
                          </div>
                          <input
                            name="password"
                            className="create_order_wrapper_input"
                            type={showPassword ? "text" : "password"}
                            autoComplete="off"
                            placeholder={t("pages.signUp.password")}
                            value={fields.password}
                            onChange={e => onChangeField(e, 'password')}
                          />
                          <div className="create_order_wrapper_icon create_order_wrapper_icon--loading">
                            <span
                              className="create_order_wrapper_icon create_order_wrapper_icon--show"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                             {showPassword ? <BiHide /> : <BiShow />}
                            </span>
                          </div>
                        </div>
                        <div className="new_form_column_wrapper" />
                        <div className={cn("new_form_column_animated", {"new_form_column_animated--show": fields.password})}>
                          <label htmlFor="passwordConfirmation">
                            {t("pages.signUp.confirmPassword")}
                          </label>
                          <div className="create_order_wrapper">
                            <div className="create_order_wrapper_icon">
                              <RiLockPasswordLine />
                            </div>
                            <input
                              name="passwordConfirmation"
                              className="create_order_wrapper_input"
                              type={showConfirmPassword ? "text" : "password"}
                              autoComplete="off"
                              placeholder={t("pages.signUp.confirmPassword")}
                              value={fields.passwordConfirmation}
                              onChange={e => onChangeField(e, 'passwordConfirmation')}
                            />
                            <div className="create_order_wrapper_icon create_order_wrapper_icon--loading">
                              <span
                                className="create_order_wrapper_icon create_order_wrapper_icon--show"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              >
                                {showConfirmPassword ? <BiHide /> : <BiShow />}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="new_form_actions">
                      <button
                        onClick={onChangePage}
                      >
                        {t("pages.signUp.continue")}
                      </button>
                      <h2
                        className="new_form_actions_login"
                        onClick={() => navigate("/auth")}
                      >
                        <span>{t("pages.signUp.alreadyHave")}</span>
                        {t("pages.signUp.signIn")}
                      </h2>
                    </div>
                    {!error && (
                      <span className="new_form_actions_error"/>
                    )}
                    {error && (
                      <p className="new_form_actions_error">
                        <MdOutlineError />
                        {t(`errors.${error?.toLowerCase()}`)}
                      </p>
                    )}
                  </>
                )}
                {page === "2" && (
                  <>
                    <div className="new_form_columns">
                      <div className="new_form_column new_form_column--right">
                        <label htmlFor="firstName">
                          {t("pages.signUp.firstName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <MdPersonOutline />
                          </div>
                          <input
                            name="firstName"
                            type="text"
                            placeholder={t("pages.signUp.firstName")}
                            className="create_order_wrapper_input"
                            autoComplete="off"
                            value={fields.firstName}
                            onChange={e => onChangeField(e, 'firstName')}
                          />
                        </div>
                      </div>
                      <div className="new_form_column">
                        <label htmlFor="lastName">
                          {t("pages.signUp.lastName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <MdPersonOutline />
                          </div>
                          <input
                            name="lastName"
                            className="create_order_wrapper_input"
                            type="text"
                            placeholder={t("pages.signUp.lastName")}
                            autoComplete="off"
                            value={fields.lastName}
                            onChange={e => onChangeField(e, 'lastName')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new_form_columns">
                      <div className="new_form_column">
                        <label htmlFor="companyName">
                          {t("pages.signUp.companyName")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <BiBuilding />
                          </div>
                          <input
                            name="companyName"
                            className="create_order_wrapper_input"
                            type="text"
                            autoComplete="off"
                            placeholder={t("pages.signUp.companyName")}
                            value={fields.companyName}
                            onChange={e => onChangeField(e, 'companyName')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new_form_column" style={!countriesData ? {marginBottom: "25px"} : {marginBottom: "0px"}}>
                      <label htmlFor="companyAddress">
                        {t("pages.signUp.companyBranchAddress")}
                      </label>
                      <DropDown
                        hasMarkerIcon={true}
                        placeholder={t("pages.signUp.companyBranchAddress")}
                        loading={addressLoading}
                        options={geocodingAddresses.map((address, index) => ({
                          option: address,
                          el: (
                            <li
                              className='create_order_custom_dropdown_menu'
                              key={index}
                            >
                              <FaMapMarkerAlt /> {address.label}
                            </li>
                          )
                        }))}
                        value={address}
                        onSearch={onChangeAddress}
                        onChange={option => setAddress(option)}
                        onReset={onResetAddress}
                      />
                    </div>
                    {countriesData && (
                      <div className="new_form_column" style={{marginBottom: "25px"}}>
                        <label htmlFor="country">
                          {t("pages.signUp.country")}
                        </label>
                        <DropDown
                          hasMarkerIcon={true}
                          placeholder={t("pages.signUp.country")}
                          loading={false}
                          options={countriesData}
                          value={selectedCountry}
                          onChange={option => setSelectedCountry(option)}
                        />
                      </div>
                    )}
                    <div className="new_form_actions">
                      <div className="new_form_actions_in">
                        <button
                          className="new_form_actions_back"
                          onClick={(e) => {
                            e.preventDefault();

                            setPage("1");
                            setChecked(false);
                            setError(null);
                          }}
                        >
                          {t("pages.signUp.back")}
                        </button>
                        {loading && (
                          <button className="new_form_actions_pending">
                            <Loading />
                          </button>
                        )}
                        {!loading && (
                          <button
                            onClick={onSubmit}
                          >
                            {t("pages.signUp.create")}
                          </button>
                        )}
                      </div>
                      <div className="new_form_actions_login">
                        <input
                          className="new_form_actions_login--checkbox"
                          type="checkbox"
                          value={checked}
                          onChange={(e) => setChecked(e.target.checked)}
                        />
                        <span>{t("pages.signUp.agree")}</span>
                        <h2
                          onClick={() => window.open("https://taskroad.pro/terms-of-service/", '_blank')}
                        >
                          {t("pages.signUp.terms")}
                        </h2>
                      </div>
                    </div>
                    {!error && (
                      <span className="new_form_actions_error"/>
                    )}
                    {error && (
                      <p className="new_form_actions_error">
                        <MdOutlineError />
                        {t(`errors.${error?.toLowerCase()}`)}
                      </p>
                    )}
                  </>
                )}
              </form>
            )}
            {isVerificationOpen && (
              <form className="new_form">
                <img className="new_form_logo" src={Logo} alt="logo"/>
                <p className="new_form_description">
                  {`${t("pages.signUp.descripStart")} ${fields.email}. ${t("pages.signUp.descripEnd")}`}
                </p>
                <div className="new_form_columns">
                  <div className="new_form_column">
                    <label htmlFor="code">
                      {t("pages.signUp.code")}
                    </label>
                    <div className="create_order_wrapper">
                      <div className="create_order_wrapper_icon">
                        <RiLockPasswordLine />
                      </div>
                      <input
                        name="code"
                        type="text"
                        className="create_order_wrapper_input"
                        autoComplete="off"
                        placeholder={t("pages.signUp.code")}
                        value={code}
                        onChange={e => setCode(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="new_form_actions"
                  style={{marginTop: "25px"}}
                >
                  {!verificationLoading && (
                    <button onClick={onVerify}>
                      {t("pages.signUp.verify")}
                    </button>
                  )}
                  {verificationLoading && (
                    <button>
                      <Loading/>
                    </button>
                  )}
                  {!error && (
                    <span className="new_form_actions_error"/>
                  )}
                  {error && (
                    <p className="new_form_actions_error">
                      <MdOutlineError />
                      {t(`errors.${error?.toLowerCase()}`)}
                    </p>
                  )}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
}

export default memo(SignUp);
