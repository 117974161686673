import { memo, useCallback, useState } from "react";
import api from "../../../utils/api";
import "./index.scss";
import {MdOutlineError, MdPersonOutline} from "react-icons/md";
import {errToString} from "../../../utils";
import {useTranslation} from "react-i18next";
import {RiLockPasswordLine} from "react-icons/ri";
import Loading from "../../../components/ui/loading";

function ForgotPassword({setAuthType}) {
    const {t} = useTranslation();

    const [error, setError] = useState(null);
    const [isSuccessful, setIsSuccessful] = useState(null)
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [username, setUsername] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [verificationChannel, setVerificationChannel] = useState(null);
    const [loading, setLoading] = useState(false);
    const [verifyLoading, setVerifyLoading] = useState(false);

    const onSubmit = useCallback(e => {
      e.preventDefault();
      setError(null);
      setLoading(true);

      api
        .post("/auth/forgot-password", {
          username,
        })
        .then((res) => {
          setIsSuccessful(true);
          setLoading(false);
          setVerificationChannel(res.data.verificationChannel);
        })
        .catch((err) => {
          setLoading(false);
          setError(errToString(err));
          console.log(errToString(err))
        });
    }, [username]);
    const onVerify = useCallback(e => {
      e.preventDefault();
      setError(null);
      setVerifyLoading(true);

      api
        .post("/auth/forgot-password/verify", {
          password,
          passwordConfirmation,
          code
        })
        .then(() => {
          setAuthType('sign-in');
          setVerifyLoading(false);
        })
        .catch((err) => {
          setError(errToString(err));
          console.log(errToString(err));
          setVerifyLoading(false);
        });
    }, [password, passwordConfirmation, code, setAuthType]);

    return (
      <>
        {!isSuccessful && (
          <div className="forgot_pass">
            <form
              className="new_form"
              onSubmit={onSubmit}
            >
              <h2 className="new_form_title">Reset Password</h2>
              <p className="new_form_description">
                Enter your username to receive an email with a code to reset your password.
              </p>
              <div className="new_form_columns">
                <div className="new_form_column">
                  <label htmlFor="username">Email</label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <MdPersonOutline />
                    </div>
                    <input
                      name="username"
                      className="create_order_wrapper_input"
                      type="text"
                      placeholder="Email"
                      autoComplete="off"
                      value={username}
                      onChange={e => {
                        setUsername(e.target.value);
                        setError(null);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="new_form_column_wrapper" />
              <div className="new_form_actions">
                {!loading && (
                  <button type={"submit"}>Submit</button>
                )}
                {loading && (
                  <button className="new_form_actions_pending">
                    <Loading />
                  </button>
                )}
                <h2
                  className="new_form_actions_login"
                  onClick={() => setAuthType("sign-in")}
                >
                  back
                </h2>
                {!error && (
                  <span className="new_form_actions_error"/>
                )}
                {error && (
                  <p className="new_form_actions_error">
                    <MdOutlineError />
                    {t(`errors.${error?.toLowerCase()}`)}
                  </p>
                )}
              </div>
            </form>
          </div>
        )}
        {isSuccessful && verificationChannel && (
          <div className="forgot_pass_verify">
            <form
              className="new_form"
              onSubmit={onVerify}
            >
              <h2 className="new_form_title">Reset Password</h2>
              <p className="new_form_description">
                Create a new password
              </p>
              <div className="new_form_columns">
                <div className="new_form_column">
                  <label htmlFor="">Code</label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <RiLockPasswordLine />
                    </div>
                    <input
                      name="code"
                      type="text"
                      className="create_order_wrapper_input"
                      placeholder="Verification Code"
                      autoComplete="off"
                      value={code}
                      onChange={e => {
                        setCode(e.target.value);
                        setError(null);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="new_form_columns">
                <div className="new_form_column">
                  <label htmlFor="">Password</label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <RiLockPasswordLine />
                    </div>
                    <input
                      name="password"
                      type="password"
                      className="create_order_wrapper_input"
                      placeholder="Password"
                      autoComplete="off"
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value);
                        setError(null);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="new_form_columns">
                <div className="new_form_column">
                  <label htmlFor="">Confirm password</label>
                  <div className="create_order_wrapper">
                    <div className="create_order_wrapper_icon">
                      <RiLockPasswordLine />
                    </div>
                    <input
                      name="paswwordConfirmation"
                      type="password"
                      className="create_order_wrapper_input"
                      placeholder="Password Confirmation"
                      autoComplete="off"
                      value={passwordConfirmation}
                      onChange={e => {
                        setPasswordConfirmation(e.target.value);
                        setError(null);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="new_form_column_wrapper" />
              <div className="new_form_actions">
                {!verifyLoading && (
                  <button type={"submit"}>Verify</button>
                )}
                {verifyLoading && (
                  <button className="new_form_actions_pending">
                    <Loading />
                  </button>
                )}
                {!error && (
                  <span className="new_form_actions_error"/>
                )}
                {error && (
                  <p className="new_form_actions_error">
                    <MdOutlineError />
                    {t(`errors.${error?.toLowerCase()}`)}
                  </p>
                )}
              </div>
            </form>
          </div>
        )}
      </>
    );
}

export default memo(ForgotPassword);
