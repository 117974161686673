import React, {useReducer, useCallback, memo} from 'react';
import SocketContext from './socket-context';

const defaultSocketState = {
  socket: null,
  setSocket: null,
};

const socketReducer = (state, action) => {
  if (action.type === 'SET_SOCKET') {
    return {
      ...state,
      socket: action.payload.socket,
    };
  }
  return defaultSocketState;
};

const SocketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(socketReducer, defaultSocketState);
  const setSocket = useCallback(socket => {
    dispatch({
      type: 'SET_SOCKET',
      payload: { socket },
    });
  }, [dispatch]);

  const socketContext = {
    ...state,
    setSocket
  };
  return (
    <SocketContext.Provider value={socketContext}>
      {children}
    </SocketContext.Provider>
  );
};

export default memo(SocketProvider);
