import './index.scss';
import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import api from "../../../utils/api";
import {DEVICES} from "../../../constants/drop-down-options";
import DropDown from '../../../components/ui/drop-down';
import Form from '../../../components/form'
import FormRow from "../../../components/form/form-row";
import FormCol from "../../../components/form/form-col";
import PropTypes from "prop-types";
import sound from "../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {MdPersonOutline} from "react-icons/md";
import {BsPlus} from "react-icons/bs";
import {AiFillCar, AiOutlineCar, AiOutlineFieldNumber} from "react-icons/ai";
import Loading from "../../../components/ui/loading";
import {RiEBike2Fill} from "react-icons/ri";
import UserCompanyBranchesContext from "../../../context/user-company-branches/user-company-branches-context";
import {errToString} from "../../../utils";
import cn from "classnames";
import {IoMdBicycle} from "react-icons/io";
import {FaTruck} from "react-icons/fa";

function UpdateAgent({
onClose,
agent,
agentsData,
setAgentsData,
setAgent
}) {
  const userCompanyBranches = useContext(UserCompanyBranchesContext);
  const {t} = useTranslation();
  const [branches, setBranches] = useState(null);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [carType, setCarType] = useState(agent.vehicleType);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [fields, setFields] = useState({
    firstName: agent.firstName,
    lastName: agent.lastName,
    email: agent.email || " ",
    device: {
      value: agent.device,
      label: agent.device
    },
    carBrand:  agent.carBrand,
    carModel: agent.carModel,
    carPlate: agent.carPlate,
  });

  const onChangeField = useCallback((e, name) => {
    setError(null);

    if (
      name === 'device'
    ) {
      return setFields({
        ...fields,
        [name]: e
      })
    }

    if (
      e.target.type === 'text' ||
      e.target.type === 'number'
    ) {
      setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [fields]);
  const onSubmit = useCallback(e => {
    e.preventDefault();

    const data = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      email: fields.email,
      device: fields.device?.value,
      vehicleType: carType,
      carBrand: carType === "car" ? fields.carBrand : null,
      carModel: carType === "car" ? fields.carModel : null,
      carPlate: carType === "car" ? fields.carPlate : null,
      address: {
        countryCode: branches[0].countryCode,
        country: branches[0].countryCode,
        city: branches[0].countryCode,
        street: branches[0].deliveryAddress,
        apartment: null,
        entrance: null,
        floor: null,
      },
    };

    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    for (const key in data.address) {
      if (data.address[key] === null) {
        delete data.address[key];
      }
    }

    setPending(true);
    api
      .put(`/employees/agents/${agent.id}`, data)
      .then((res) => {
        setAgent(res.data);
        setAgentsData(agentsData.map(_agent => {
          if (_agent.id === agent.id) {
            return res.data
          }
          return _agent
        }));
        setPending(false);
        onClose();
        const audio = new Audio(sound);
        const info = `${t("pages.agents.update_agent.agent")} ${agent.username} ${t("pages.agents.update_agent.updateMsg")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();

        api
          .get('/user-company-addresses')
          .then((res) => userCompanyBranches.set(res.data))
          .catch(err => console.log(errToString(err)));
      })
      .catch((err) => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      });
  }, [branches, userCompanyBranches, t, carType, onClose, fields, agent, agentsData, setAgentsData, setAgent]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const promises = [];
    promises.push(api.get("/company/addresses"));

    Promise
      .all(promises)
      .then((res) => {
        setBranches(res[0].data)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(errToString(err))
      })
  }, []);

  return (
    <>
      {!loading && agent && (
        <div className="update_agent">
          <div className="update_agent_in">
            <Form
              title={`${t("pages.agents.update_agent.updateAgent")} ${agent.username}`}
              error={error && t(`errors.${error?.toLowerCase()}`)}
              onSubmit={onSubmit}
            >
              {windowWidth > 600 && (
                <>
                  <FormRow>
                    <FormCol>
                      <label htmlFor="firstName">
                        <span>*</span>
                        {t("pages.agents.create_agent.firstName")}
                      </label>
                      <div className="create_order_wrapper">
                        <div className="create_order_wrapper_icon">
                          <MdPersonOutline />
                        </div>
                        <input
                          className="create_order_wrapper_input"
                          name="firstName"
                          type="text"
                          placeholder={t("pages.agents.create_agent.firstName")}
                          autoComplete="off"
                          value={fields.firstName}
                          onChange={e => onChangeField(e, 'firstName')}
                        />
                      </div>
                    </FormCol>
                    <FormCol>
                      <label htmlFor="lastName">
                        <span>*</span>
                        {t("pages.agents.create_agent.lastName")}
                      </label>
                      <div className="create_order_wrapper">
                        <div className="create_order_wrapper_icon">
                          <MdPersonOutline />
                        </div>
                        <input
                          name="lastName"
                          className="create_order_wrapper_input"
                          type="text"
                          placeholder= {t("pages.agents.create_agent.lastName")}
                          autoComplete="off"
                          value={fields.lastName}
                          onChange={e => onChangeField(e, 'lastName')}
                        />
                      </div>
                    </FormCol>
                    <FormCol title={t("pages.agents.create_agent.additionalInfo")}>
                      <label htmlFor="device">
                        <span>*</span>
                        {t("pages.agents.create_agent.device")}
                      </label>
                      <DropDown
                        hasDeviceIcon={true}
                        name="device"
                        placeholder={t("pages.agents.create_agent.device")}
                        options={DEVICES}
                        position={"top"}
                        value={fields.device}
                        onChange={e => onChangeField(e, 'device')}
                      />
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <div className="create_agent_in_switcher">
                      <div
                        className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "car"})}
                        onClick={() => {
                          setCarType("car");
                          setError(null)
                        }}
                      ><AiFillCar /></div>
                      <div
                        className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "bike"})}
                        onClick={() => {
                          setCarType("bike");
                          setError(null)
                        }}
                      ><RiEBike2Fill /></div>
                      <div
                        className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "bicycle"})}
                        onClick={() => {
                          setCarType("bicycle");
                          setError(null)
                        }}
                      ><IoMdBicycle /></div>
                      <div
                        className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "truck"})}
                        onClick={() => {
                          setCarType("truck");
                          setError(null)
                        }}
                      ><FaTruck /></div>
                    </div>
                  </FormRow>
                  {carType === 'car' && (
                    <FormRow>
                      <FormCol>
                        <label htmlFor="carBrand">
                          <span>*</span>
                          {t("pages.agents.create_agent.carBrand")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <AiOutlineCar />
                          </div>
                          <input
                            type="text"
                            name="carBrand"
                            className="create_order_wrapper_input"
                            placeholder={t("pages.agents.create_agent.carBrand")}
                            autoComplete="off"
                            value={fields.carBrand}
                            onChange={e => onChangeField(e, 'carBrand')}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label htmlFor="carModel">
                          <span>*</span>
                          {t("pages.agents.create_agent.carModel")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <AiOutlineCar />
                          </div>
                          <input
                            type="text"
                            name="carModel"
                            className="create_order_wrapper_input"
                            placeholder={t("pages.agents.create_agent.carModel")}
                            autoComplete="off"
                            value={fields.carModel}
                            onChange={e => onChangeField(e, 'carModel')}
                          />
                        </div>
                      </FormCol>
                      <FormCol>
                        <label htmlFor="carPlate">
                          <span>*</span>
                          {t("pages.agents.create_agent.carPlate")}
                        </label>
                        <div className="create_order_wrapper">
                          <div className="create_order_wrapper_icon">
                            <AiOutlineFieldNumber />
                          </div>
                          <input
                            name="carPlate"
                            className="create_order_wrapper_input"
                            type="text"
                            placeholder={t("pages.agents.create_agent.carPlate")}
                            autoComplete="off"
                            value={fields.carPlate}
                            onChange={e => onChangeField(e, 'carPlate')}
                          />
                        </div>
                      </FormCol>
                    </FormRow>
                  )}
                  <FormRow>
                    <FormCol>
                      <a
                        href="!#"
                        className="update_agent__cancel_btn"
                        onClick={onClose}
                      >{t("pages.agents.create_agent.cancel")}</a>
                    </FormCol>
                    <FormCol>
                      {!pending && (
                        <button className="update_agent__submit_btn">
                          {t("pages.managers.update_manager.updateManager")}
                        </button>
                      )}
                      {pending && (
                        <button className="update_agent__submit_btn update_agent__submit_btn--pending">
                          <Loading />
                        </button>
                      )}
                    </FormCol>
                  </FormRow>
                </>
              )}
              {windowWidth <= 600 && (
                <>
                  <FormRow>
                    <FormCol>
                      <label htmlFor="firstName">
                        <span>*</span>
                        {t("pages.agents.create_agent.firstName")}
                      </label>
                      <div className="create_order_wrapper">
                        <div className="create_order_wrapper_icon">
                          <MdPersonOutline />
                        </div>
                        <input
                          className="create_order_wrapper_input"
                          name="firstName"
                          type="text"
                          placeholder={t("pages.agents.create_agent.firstName")}
                          autoComplete="off"
                          value={fields.firstName}
                          onChange={e => onChangeField(e, 'firstName')}
                        />
                      </div>
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <FormCol>
                      <label htmlFor="lastName">
                        <span>*</span>
                        {t("pages.agents.create_agent.lastName")}
                      </label>
                      <div className="create_order_wrapper">
                        <div className="create_order_wrapper_icon">
                          <MdPersonOutline />
                        </div>
                        <input
                          name="lastName"
                          className="create_order_wrapper_input"
                          type="text"
                          placeholder= {t("pages.agents.create_agent.lastName")}
                          autoComplete="off"
                          value={fields.lastName}
                          onChange={e => onChangeField(e, 'lastName')}
                        />
                      </div>
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <FormCol title={t("pages.agents.create_agent.additionalInfo")}>
                      <label htmlFor="device">
                        <span>*</span>
                        {t("pages.agents.create_agent.device")}
                      </label>
                      <DropDown
                        hasDeviceIcon={true}
                        name="device"
                        placeholder={t("pages.agents.create_agent.device")}
                        options={DEVICES}
                        position={"top"}
                        value={fields.device}
                        onChange={e => onChangeField(e, 'device')}
                      />
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <div className="create_agent_in_switcher">
                      <div
                        className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "car"})}
                        onClick={() => {
                          setCarType("car");
                          setError(null)
                        }}
                      ><AiFillCar /></div>
                      <div
                        className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "bike"})}
                        onClick={() => {
                          setCarType("bike");
                          setError(null)
                        }}
                      ><RiEBike2Fill /></div>
                      <div
                        className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "bicycle"})}
                        onClick={() => {
                          setCarType("bicycle");
                          setError(null)
                        }}
                      ><IoMdBicycle /></div>
                      <div
                        className={cn("create_agent_in_switcher_icon", {"create_agent_in_switcher_icon--selected": carType === "truck"})}
                        onClick={() => {
                          setCarType("truck");
                          setError(null)
                        }}
                      ><FaTruck /></div>
                    </div>
                  </FormRow>
                  {carType === 'car' && (
                    <>
                      <FormRow>
                        <FormCol>
                          <label htmlFor="carBrand">
                            <span>*</span>
                            {t("pages.agents.create_agent.carBrand")}
                          </label>
                          <div className="create_order_wrapper">
                            <div className="create_order_wrapper_icon">
                              <AiOutlineCar />
                            </div>
                            <input
                              type="text"
                              name="carBrand"
                              className="create_order_wrapper_input"
                              placeholder={t("pages.agents.create_agent.carBrand")}
                              autoComplete="off"
                              value={fields.carBrand}
                              onChange={e => onChangeField(e, 'carBrand')}
                            />
                          </div>
                        </FormCol>
                        <FormCol>
                          <label htmlFor="carModel">
                            <span>*</span>
                            {t("pages.agents.create_agent.carModel")}
                          </label>
                          <div className="create_order_wrapper">
                            <div className="create_order_wrapper_icon">
                              <AiOutlineCar />
                            </div>
                            <input
                              type="text"
                              name="carModel"
                              className="create_order_wrapper_input"
                              placeholder={t("pages.agents.create_agent.carModel")}
                              autoComplete="off"
                              value={fields.carModel}
                              onChange={e => onChangeField(e, 'carModel')}
                            />
                          </div>
                        </FormCol>
                      </FormRow>
                      <FormRow>
                        <FormCol>
                          <label htmlFor="carPlate">
                            <span>*</span>
                            {t("pages.agents.create_agent.carPlate")}
                          </label>
                          <div className="create_order_wrapper">
                            <div className="create_order_wrapper_icon">
                              <AiOutlineFieldNumber />
                            </div>
                            <input
                              name="carPlate"
                              className="create_order_wrapper_input"
                              type="text"
                              placeholder={t("pages.agents.create_agent.carPlate")}
                              autoComplete="off"
                              value={fields.carPlate}
                              onChange={e => onChangeField(e, 'carPlate')}
                            />
                          </div>
                        </FormCol>
                        <FormCol />
                      </FormRow>
                    </>
                  )}
                  <FormRow>
                    <FormCol>
                      <a
                        href="!#"
                        className="update_agent__cancel_btn"
                        onClick={onClose}
                      >{t("pages.agents.create_agent.cancel")}</a>
                    </FormCol>
                    <div
                      className="update_order_submit_btn--round"
                      onClick={onSubmit}
                    >
                      <BsPlus />
                    </div>
                  </FormRow>
                </>
              )}
            </Form>
          </div>
        </div>
      )}
      {loading && (
        <div className="agents_loading_wrapper">
          <Loading/>
        </div>
      )}
    </>
  );
}

UpdateAgent.propTypes = {
  onClose: PropTypes.func,
  agent: PropTypes.object,
  setAgentsData: PropTypes.func,
  setAgent: PropTypes.func,
  agentsData: PropTypes.array
};

export default memo(UpdateAgent);
