export const getStartDate = () => {
  const startDate = new Date();
  startDate.setHours(startDate.getHours());
  startDate.setMinutes(startDate.getMinutes() + 15);

  return startDate;
};

export const getEndDate = () => {
  const endDate = new Date();
  endDate.setHours(endDate.getHours());
  endDate.setMinutes(endDate.getMinutes() + 60);

  return endDate;
};

export const getCurrentDateWithoutHours = (h = 0, m = 0, s = 0) => {
  const date = new Date();
  date.setHours(h, m, s);
  return date;
};

export const getDatesInRange = (startDate, endDate) => {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}