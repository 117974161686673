import React, {memo, useState, useEffect, useContext, useMemo} from "react";
import {Link, useLocation} from "react-router-dom";
import cn from "classnames";
import {createPortal} from "react-dom";
import "./index.scss";
import AuthContext from "../../context/auth/auth-context";
import {NAVIGATION_ROUTES} from '../../constants/navigation-routes';
import {useTranslation} from "react-i18next";
import { IoMdHelpCircle } from "react-icons/io";
import { RiExternalLinkLine } from "react-icons/ri";

function Navigation({
isBurgerOpen,
onToggleMenu}) {
  const {t} = useTranslation();
  const authCtx = useContext(AuthContext);
  const { pathname } = useLocation();
  const [isMount, setIsMount] = useState(false);
  const navigationRoutes = useMemo(() => {
    return NAVIGATION_ROUTES.filter(({id}) => {
      if (id === 'managers') {
        return authCtx.user.role.permissions.includes('read_manager');
      }
      if (id === 'agents') {
        return authCtx.user.role.permissions.includes('read_agent');
      }
      if (id === 'customers') {
        return authCtx.user.role.permissions.includes('read_customer');
      }
      if (id === 'branches') {
        return authCtx.user.role.permissions.includes('read_branch');
      }
      if (id === 'partners') {
        return authCtx.user.role.permissions.includes('read_partner');
      }
      if (id === 'billing') {
        return authCtx.user.role.permissions.includes('update_company_subscription');
      }

      if (id === 'settings') {
        return authCtx.user.role.permissions.includes('update_payment_methods', 'update_roles', 'update_agent_preferences', 'update_notifications', 'update_company_preferences', 'update_map', 'update_sms');
      }
      if (id === 'settings/payment-methods') {
        return authCtx.user.role.permissions.includes('update_payment_methods');
      }
      if (id === 'settings/roles') {
        return authCtx.user.role.permissions.includes('update_roles');
      }
      if (id === 'settings/agents') {
        return authCtx.user.role.permissions.includes('update_agent_preferences');
      }
      if (id === 'settings/notifications') {
        return authCtx.user.role.permissions.includes('update_notifications');
      }
      if (id === 'settings/preferences') {
        return authCtx.user.role.permissions.includes('update_company_preferences');
      }
      if (id === 'settings/reviews') {
        return authCtx.user.role.permissions.includes('update_company_preferences');
      }
      if (id === 'settings/maps') {
        return authCtx.user.role.permissions.includes('update_map');
      }
      if (id === 'settings/sms') {
        return authCtx.user.role.permissions.includes('update_sms');
      }

      return true;
    });
  }, [authCtx.user.role.permissions]);

  const route = useMemo(() => pathname.split('/').slice(0, 3).join('/'), [pathname]);

  useEffect(() => {
    setIsMount(true);
  }, []);

  return authCtx.isAuth && createPortal(
      <>
        <nav
            className={cn(
              "navigation",
              {'navigation--animate': isMount && isBurgerOpen}
            )}
        >
          <div className="navigation__in">
            <ul
              className={cn({ 'show': isBurgerOpen })}>
              {navigationRoutes.map(({ id, links, icon }) => (
                  <li
                      key={id}
                      className={cn("navigation__list", {
                        "navigation__list--active": links.includes(route)
                      })}
                      onClick={isBurgerOpen ? onToggleMenu : null}
                  >
                    <Link to={links[0]}>
                      <div className="navigation__list-icon">{icon}</div>
                      {t(`components.navigation.${id}`)}
                    </Link>
                  </li>
              ))}
              <li className="navigation__list">
                <a href="https://taskroad.pro/support/" target="_blank" rel="noreferrer">
                  <div className="navigation__list-icon">
                    <IoMdHelpCircle fill={"blue"}/>
                  </div>
                  {t(`components.navigation.support`)}
                  <RiExternalLinkLine fill={"#000"} style={{marginLeft: "5px"}}/>
                </a>
              </li>
            </ul>
          </div>
        </nav>
        {isBurgerOpen && (
          <div className="navigation__overlay" onClick={onToggleMenu} />
        )}
      </>,
      document.getElementById('modal')
  );
}

export default memo(Navigation);
