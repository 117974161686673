import {memo, useCallback, useContext, useMemo, useState} from "react";
import "./index.scss";
import {useLocation, useNavigate} from "react-router-dom";
import AuthContext from "../../../context/auth/auth-context";
import api from "../../../utils/api"
import {errToString} from "../../../utils";
import {MdOutlineError} from "react-icons/md";
import {useTranslation} from "react-i18next";

function CreateEmployee() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const authCtx = useContext(AuthContext);
  const [error, setError] = useState(null);
  const location = useLocation();
  const code = useMemo(() => location.search.split("?code=")[1], [location.search]);
  const [fields, setFields] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const onChangeField = useCallback((e, name) => {
    setError(null);

    if (
      e.target.type === 'password'
    ) {
      setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [fields]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setError(null);

    const data = {
      password: fields.password,
      passwordConfirmation: fields.passwordConfirmation,
      code,
    };

    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }
    api
      .post("/employees/verify", data)
      .then((res) => {
        localStorage.setItem('token', res.data.token);
        authCtx.auth(res.data.user);
        navigate("/", { replace: true });
      })
      .catch(err => {
        setError(errToString(err));
        console.log(errToString(err));
      });
  }, [fields, authCtx, code, navigate]);

    return (
      <div className="create_employee">
        <form
          className="new_form"
          onSubmit={onSubmit}
        >
          <h2 className="new_form_title">Reset Password</h2>
          <p className="new_form_description">
            Enter your email address to receive an email with a link to reset your password.
          </p>
          <div className="new_form_columns">
            <div className="new_form_column">
              <label htmlFor="">Password</label>
              <input
                name="password"
                type="password"
                placeholder="Password"
                autoComplete="off"
                value={fields.password}
                onChange={e => onChangeField(e, 'password')}
              />
            </div>
          </div>
          <div className="new_form_columns">
            <div className="new_form_column">
              <label htmlFor="">Confirm password</label>
              <input
                name="passwordConfirmation"
                type="password"
                placeholder="Password confirmation"
                autoComplete="off"
                value={fields.passwordConfirmation}
                onChange={e => onChangeField(e, 'passwordConfirmation')}
              />
            </div>
          </div>
          <div className="new_form_actions">
            <button type={"submit"}>Submit</button>
            {error && (
              <p className="new_form_actions_error">
                <MdOutlineError />
                {t(`errors.${error?.toLowerCase()}`)}
              </p>
            )}
          </div>
        </form>
      </div>
    );
}

export default memo(CreateEmployee);
