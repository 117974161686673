import "./index.scss";
import { memo } from "react";
import PropTypes from 'prop-types';

function FormRow({
  title,
  children
}) {
  return (
    <div className="form_row">
      {title && (
        <p className="form_row_title">{title}</p>
      )}
      <div className="form_row_in">
        {children}
      </div>
    </div>
  );
}

FormRow.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
};

export default memo(FormRow);
