import {memo, useCallback, useState} from "react";
import DropDown from "../../../../../components/ui/drop-down";
import Form from "../../../../../components/form";
import FormRow from "../../../../../components/form/form-row";
import FormCol from "../../../../../components/form/form-col";
import Loading from "../../../../../components/ui/loading";
import {useTranslation} from "react-i18next";
import api from "../../../../../utils/api";
import {errToString} from "../../../../../utils";

function SettingsIntegrationsSmsActivation({
activeSmsSelection,
setIsSmsActivated,
isSmsActivated,
smsChannels
}) {

  const {t} = useTranslation();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [pending, setPending] = useState(false);

  const onDoNotUseSms = useCallback((e) => {
    e.preventDefault();

    const activeSmsChannel = smsChannels.find(item => item.isActive === true)
    const data = {
      name: 'sms',
      isActive: false
    };

    if (!activeSmsChannel?.id) {
      return;
    }

    setPending(true);

    api
      .put(`/company/integrations/${activeSmsChannel.id}`, data)
      .then(() => {
        setSuccess(t("pages.settings.smsRemoved"));
        setError(null);
        setPending(false);
      })
      .catch((err) => {
        console.log(errToString(err))
        setSuccess(null);
        setPending(false);
      })
  }, [t, smsChannels]);

  return (
    <>
      <div className="settings_integrations_sms_in_left_wrapper">
        {isSmsActivated && (
          <p className="settings_integrations_sms_in_left_descrip">
            {t("pages.settings.smsActive")}
          </p>
        )}
        {!isSmsActivated && (
          <p className="settings_integrations_sms_in_left_descrip">
            {t("pages.settings.smsNotActive")}
          </p>
        )}
        <div className="settings_integrations_sms_in_drop_down">
          <DropDown
            hasSmsIcon={true}
            placeholder="Choose options"
            options={activeSmsSelection}
            value={isSmsActivated ? activeSmsSelection[1] : activeSmsSelection[0]}
            onChange={(e) => {
              setIsSmsActivated(e.value === "sms_is_active");
              setSuccess(null);
              setError(null);
            }}
            position="bottom"
            showClose={false}
          />
        </div>
        {!isSmsActivated && (
          <div className="settings_integrations_sms_in_options">
            <div className="settings_integrations_sms_in_form">
              <Form onSubmit={onDoNotUseSms}>
                <FormRow>
                  <FormCol>
                    {!pending && (
                      <button className="create_order_submit_btn">
                        {t("pages.branches.create_branch_form.save")}
                      </button>
                    )}
                    {pending && (
                      <button className="create_order_submit_btn create_order_submit_btn--pending">
                        <Loading />
                      </button>
                    )}
                    {success && (
                      <p className="settings_integrations_sms_in_form_success">{success}</p>
                    )}
                    {error && (
                      <p className="settings_integrations_sms_in_form_error">{error}</p>
                    )}
                  </FormCol>
                </FormRow>
              </Form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default memo(SettingsIntegrationsSmsActivation);
