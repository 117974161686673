import "./index.scss";
import {memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import OrdersModal from "./orders-modal";
import AgentsModal from "./agents-modal";
import ActionBtn from "../../components/ui/action-btn";
import api from "../../utils/api";
import SocketContext from '../../context/socket/socket-context';
import CreateOrder from '../../components/create-order';
import BranchSvg from '../../assets/markers/branch.svg';
import AuthContext from '../../context/auth/auth-context';
import {ORDER_MARKERS} from '../../constants/markers';
import moment from 'moment/moment';
import {MdFilterListAlt} from "react-icons/md";
import PropTypes from 'prop-types';
import {LIMIT} from '../../App';
import Map from '../../components/map';
import Cookies from 'js-cookie';
import PreferencesContext from '../../context/preferences/preferences-context';
import DateTimePicker from '../../components/ui/calendar/date-time-picker-double-time';
import DropDownFilter from '../../components/ui/drop-down-filter';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import cn from "classnames";
import UserCompanyBranchesContext from "../../context/user-company-branches/user-company-branches-context";
import PopUp from "../../components/layout/popup";
import CreateAgent from "../agents/create-agent";
import {errToString} from "../../utils";
import AnalyticsContext from "../../context/analytics/analytics-context";
import {FaMap} from "react-icons/fa";
import {BsFillPersonFill} from "react-icons/bs";
import {BiTask} from "react-icons/bi";
import Logo from "../../assets/logo.png";
import DriverAppQR from "../../components/driver-app-qr";

const FILTER_OPTIONS = [
    {
        label: `showTraffic`,
        value: 'traffic'
    },
    {
        label: `showCompletedOrders`,
        value: 'completedTasks'
    },
    {
        label: `showOnlineUsers`,
        value: 'showOnlineUsers'
    }
];
export const DEFAULT_CENTER = {
    lat: 40.193289256994234,
    lng: 44.515466997652204
};
const getStartDate = (h, m) => {
    let storedDate = null;

    if (Cookies.get('dashboard:calendar')) {
        const cookie = Cookies.get('dashboard:calendar');
        storedDate = JSON.parse(cookie)
    }

    if (!storedDate?.startDate) {
        const date = new Date();
        date.setHours(h, m, 0);
        return date;
    }

    return new Date(storedDate?.startDate);
};
const getEndDate = (h, m) => {
    let storedDate = null;

    if (Cookies.get('dashboard:calendar')) {
        const cookie = Cookies.get('dashboard:calendar');
        storedDate = JSON.parse(cookie)
    }

    if (!storedDate?.endDate) {
        const date = new Date();
        date.setHours(h, m, 59);
        return date;
    }

    return new Date(storedDate?.endDate);
};

function Dashboard({
mapIntegration,
setIsNewOrderOpen,
isNewOrderOpen,
setAgentsData,
agentsData,
setAnalyticsLoading,
showSignUpWarning,
setShowSignUpWarning
}) {
    const preferences = useContext(PreferencesContext);
    const {socket} = useContext(SocketContext);
    const {isAuth, user} = useContext(AuthContext);
    const userCompanyBranches = useContext(UserCompanyBranchesContext);
    const analyticsCtx = useContext(AnalyticsContext);
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const ref = useRef();
    const [showCreateAgentBtn, setShowCreateAgentBtn] = useState(false);
    const [showCreateAgentForm, setShowCreateAgentForm] = useState(false);
    const [showQr, setShowQr] = useState(false);

    const [map, setMap] = useState(null);
    const [center, setCenter] = useState(null);
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filterOptions, setFilterOptions] = useState({
        traffic: false,
        completedTasks: true,
        showOnlineUsers: false
    });
    const [branchMarkers, setBranchMarkers] = useState([]);
    const [agentMarkers, setAgentMarkers] = useState([]);
    const [orderMarkers, setOrderMarkers] = useState([]);

    const [activeTabAgents, setActiveTabAgents] = useState("free")
    const [freeAgents, setFreeAgents] = useState([]);
    const [busyAgents, setBusyAgents] = useState([]);
    const [inactiveAgents, setInactiveAgents] = useState([]);
    const [agentsLoading, setAgentsLoading] = useState(true);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [selectedAgentActiveTab, setSelectedAgentActiveTab] = useState('details');
    const [partners, setPartners] = useState([]);

    const [unassignedOrders, setUnassignedOrders] = useState({
        loading: true,
        pagesCount: null,
        count: null,
        limit: LIMIT,
        offset: 0,
        list: null
    });
    const [assignedOrders, setAssignedOrders] = useState({
        loading: true,
        pagesCount: null,
        count: null,
        limit: LIMIT,
        offset: 0,
        list: null
    });
    const [completedOrders, setCompletedOrders] = useState({
        loading: true,
        pagesCount: null,
        count: null,
        limit: LIMIT,
        offset: 0,
        list: null
    });
    const [dateFields, setDateFields] = useState({
        startDate: getStartDate(Number(preferences.list.working_hours.value.from.split(":")[0]), Number(preferences.list.working_hours.value.from.split(":")[1])),
        endDate: getEndDate(23, 59)
    });
    const [selectedBranchId, setSelectedBranchId] = useState(-1);
    const [activeTabOrders, setActiveTabOrders] = useState("assigned");
    const [selectedOrderActiveTab, setSelectedOrderActiveTab] = useState("information");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [searchValue, setSearchValue] = useState('');

    const branchFilterOptions = useMemo(() => {
        if (!userCompanyBranches.list) {
            return
        }

        const array = [];
        const editedBranches = userCompanyBranches.list.map(branch => {
            return {
                ...branch,
                label: branch.name,
                value: branch.id
            }
        });

        array.push(
          {id: -1, label: t("components.layout.header.all"), value: -1},
          ...editedBranches
        );

        return array;
    }, [t, userCompanyBranches]);
    const filterOptionsFields = useMemo(() => {
        const options = FILTER_OPTIONS.map(option => ({
            ...option,
            label: t(`pages.dashboard.${option.label}`)
        }));

        if (mapIntegration.value === 'open_street') {
            return options.filter(option => option.value !== 'traffic');
        }

        return options;
    }, [t, mapIntegration]);

    const [responsivePage, setResponsivePage] = useState('agents');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const onCloseCreateOrder = useCallback(e => {
        e?.preventDefault();
        setIsNewOrderOpen(false);
    }, [setIsNewOrderOpen]);
    const refreshAgents = useCallback(() => {
        const agentMarkers = [];
        const startDate = moment(dateFields.startDate).utc().format();
        const endDate = moment(dateFields.endDate).utc().format();
        const branchIds = userCompanyBranches?.list.map(({ id }) => id);
        const companyAddressIds = selectedBranchId === -1 && branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : selectedBranchId !== -1 ? `&companyAddressIds=${selectedBranchId}` : '';

        api
          .get(`/employees/agents?from=${startDate}&to=${endDate}&sortField=lastActivity${companyAddressIds}&ordersSort=position`)
          .then(({ data }) => {
              data.results.forEach(agent => {
                  const status = agent.isOnline ? 'online' : 'offline';

                  agentMarkers.push({
                      id: agent.id,
                      type: 'agent',
                      position: agent.lastLocation,
                      // icon: AGENT_MARKERS[status],
                      img: agent.src,
                      imgSize: [40, 40],
                      status,
                      content: `
                        <div class="marker">
                            ${agent.firstName} ${agent.lastName} is ${status}
                        </div>
                      `,
                      onClick: () => setSelectedAgent(agent)
                  });
              });
              setAgentMarkers(agentMarkers);

              setFreeAgents(data.results.filter(agent => {
                  return (
                    agent.isOnline &&
                    (
                      !agent.orders.filter(order => order.status === 'started' || order.status === 'picked_up' || order.status === 'on_way').length ||
                      !agent.orders.length
                    )
                  );
              }))
              setBusyAgents(data.results.filter(agent => {
                  return (
                    agent.isOnline &&
                    agent.orders.filter(order => order.status === 'started' || order.status === 'picked_up' || order.status === 'on_way').length
                  );
              }))
              setInactiveAgents(data.results.filter(agent => !agent.isOnline));
              setAgentsLoading(false);
          })
          .catch((err) => {
              console.log(err.message);
              return setAgentsLoading(false);
          });
    }, [dateFields, selectedBranchId, userCompanyBranches?.list]);
    const refreshPartners = useCallback(() => {
        const startDate = moment(dateFields.startDate).utc().format();
        const endDate = moment(dateFields.endDate).utc().format();
        const branchIds = userCompanyBranches?.list.map(({ id }) => id);
        const companyAddressIds = selectedBranchId === -1 && branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : selectedBranchId !== -1 ? `&companyAddressIds=${selectedBranchId}` : '';

        api
          .get(`/partners?from=${startDate}&to=${endDate}${companyAddressIds}`)
          .then(res => {
              setPartners(res.data.results);
          })
          .catch(err => console.log(err))
    }, [dateFields, selectedBranchId, userCompanyBranches?.list]);
    const onDateTimeChange = useCallback(({ startDate, endDate }) => {
        setUnassignedOrders({
            ...unassignedOrders,
            loading: true,
            list: null
        });
        setAssignedOrders({
            ...assignedOrders,
            loading: true,
            list: null
        });
        setCompletedOrders({
            ...completedOrders,
            loading: true,
            list: null
        });
        setDateFields({ startDate, endDate });

        const date = Date.parse(new Date());
        const lastHour = (new Date()).setHours(23, 59, 59);
        const lastHourOffset = (lastHour - date) / 1000 / 60 / 60 / 24;
        Cookies.set('dashboard:calendar', JSON.stringify({ startDate, endDate }), { expires: lastHourOffset});
    },
      [
        unassignedOrders,
        assignedOrders,
        completedOrders,
        setAssignedOrders,
        setCompletedOrders,
        setUnassignedOrders,
        setDateFields
    ]);

    const markers = useMemo(() => {
        let markers = [
            ...orderMarkers,
            ...branchMarkers,
            ...agentMarkers
        ];

        if (selectedOrder && selectedAgent) {
            return markers.map(marker => {
                if (
                  (
                    (marker.type === 'order' && marker.id === selectedOrder.id) ||
                    (marker.type === 'agent' && marker.id === selectedAgent.id)
                  )
                ) {
                    return marker;
                }

                return {
                    ...marker,
                    visible: false
                };
            });
        }
        if (selectedOrder) {
            return markers.map(marker => {
                if (
                  (
                    (marker.type === 'order' && marker.id === selectedOrder.id) ||
                    (marker.type === 'agent' && marker.id === selectedOrder.agent?.id)
                  )
                ) {
                    return marker;
                }

                return {
                    ...marker,
                    visible: false
                }
            });
        }
        if (selectedAgent) {
            return markers.map(marker => {
                if (marker.type === 'agent' && marker.id === selectedAgent.id) {
                    return marker;
                }

                return {
                    ...marker,
                    visible: false
                }
            });
        }

        if (!filterOptions.completedTasks) {
            markers = markers.map(orderMarker => {
                if (
                  (
                    orderMarker.order?.status !== 'delivered' &&
                    orderMarker.order?.status !== 'failed' &&
                    orderMarker.order?.status !== 'canceled'
                  )
                ) {
                    return orderMarker;
                }

                return {
                    ...orderMarker,
                    visible: false
                }
            });
        }
        if (filterOptions.showOnlineUsers) {
            markers = markers.map(marker => {
                if (marker.type === 'agent') {
                    return {
                        ...marker,
                        visible: marker.status === 'online'
                    };
                }

                return marker;
            });
        }

        return markers;
    }, [orderMarkers, agentMarkers, branchMarkers, filterOptions, selectedOrder, selectedAgent]);
    window.markers = markers.filter(({ visible }) => visible); // TODO

    useEffect(() => {
        if (unassignedOrders.list !== null) {
            return;
        }
        if (assignedOrders.list !== null) {
            return;
        }
        if (completedOrders.list !== null) {
            return;
        }

        const startDate = moment(dateFields.startDate).utc().format();
        const endDate = moment(dateFields.endDate).utc().format();
        const search = searchValue ? `&search=${searchValue}` : '';
        const branchIds = userCompanyBranches?.list.map(({ id }) => id);
        const companyAddressIds = selectedBranchId === -1 && branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : selectedBranchId !== -1 ? `&companyAddressIds=${selectedBranchId}` : '';

        api
          .get(`/orders?offset=0&limit=${LIMIT}&state=unassigned&from=${startDate}&to=${endDate}${search}${companyAddressIds}`)
          .then(({ data }) => {
              setUnassignedOrders({
                  loading: false,
                  pagesCount: data.pagesCount,
                  count: data.count,
                  limit: LIMIT,
                  offset: LIMIT,
                  list: data.results,
              });
          })
          .catch(err => console.log(err.message));

        api
          .get(`/orders?offset=0&limit=${LIMIT}&state=assigned&from=${startDate}&to=${endDate}${search}${companyAddressIds}`)
          .then(({ data }) => {
              setAssignedOrders({
                  loading: false,
                  pagesCount: data.pagesCount,
                  count: data.count,
                  limit: LIMIT,
                  offset: LIMIT,
                  list: data.results,
              });
          })
          .catch(err => console.log(err.message));

        api
          .get(`/orders?offset=0&limit=${LIMIT}&state=completed&from=${startDate}&to=${endDate}${search}${companyAddressIds}`)
          .then(({ data }) => {
              setCompletedOrders({
                  loading: false,
                  pagesCount: data.pagesCount,
                  count: data.count,
                  limit: LIMIT,
                  offset: LIMIT,
                  list: data.results,
                  deliveredOrdersCount: data.deliveredOrdersCount,
                  failedOrdersCount: data.failedOrdersCount,
                  canceledOrdersCount: data.canceledOrdersCount
              });
          })
          .catch(err => console.log(err.message));
    }, [
      unassignedOrders,
        assignedOrders,
        completedOrders,
        setUnassignedOrders,
        setAssignedOrders,
        setCompletedOrders,
        dateFields,
        searchValue,
        selectedBranchId,
        userCompanyBranches?.list
    ]);
    useEffect(() => {
        const startDate = moment(dateFields.startDate).utc().format();
        const endDate = moment(dateFields.endDate).utc().format();
        const branchIds = userCompanyBranches?.list.map(({ id }) => id);
        const companyAddressIds = selectedBranchId === -1 && branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : selectedBranchId !== -1 ? `&companyAddressIds=${selectedBranchId}` : '';

        api
          .get(`/orders?from=${startDate}&to=${endDate}${companyAddressIds}`)
          .then(({ data }) => {
              const orderMarkers = [];

              data.results.forEach(order => {
                  orderMarkers.push({
                      id: order.id,
                      type: 'order',
                      order,
                      position: order.address.location,
                      icon: ORDER_MARKERS[order.status],
                      content: `
                        <div class="marker">
                            Order #${order.externalId} - ${order.status} - ${order.address.deliveryAddress}
                        </div>
                      `,
                      onClick: () => setSelectedOrder(order)
                  });
              });

              setOrderMarkers(orderMarkers);
          })
          .catch((err) => console.log(err));
    }, [dateFields, selectedBranchId, userCompanyBranches?.list]);
    useEffect(() => {
        const agentMarkers = [];
        const startDate = moment(dateFields.startDate).utc().format();
        const endDate = moment(dateFields.endDate).utc().format();
        const branchIds = userCompanyBranches?.list.map(({ id }) => id);
        const companyAddressIds = selectedBranchId === -1 && branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : selectedBranchId !== -1 ? `&companyAddressIds=${selectedBranchId}` : '';

        api
          .get(`/employees/agents?from=${startDate}&to=${endDate}&sortField=lastActivity${companyAddressIds}&ordersSort=position`)
          .then(({ data }) => {
            data.results.forEach(agent => {
                const status = agent.isOnline ? 'online' : 'offline';

                agentMarkers.push({
                    id: agent.id,
                    type: 'agent',
                    position: agent.lastLocation,
                    // icon: AGENT_MARKERS[status],
                    img: agent.src,
                    imgSize: [40, 40],
                    status,
                    content: `
                        <div class="marker">
                            ${agent.firstName} ${agent.lastName} is ${status}
                        </div>
                    `,
                    onClick: () => setSelectedAgent(agent)
                });
            });
            setAgentMarkers(agentMarkers);

            setShowCreateAgentBtn(!data.results.length);
            setFreeAgents(data.results.filter(agent => {
                return (
                  agent.isOnline &&
                  (
                    !agent.orders.filter(order => order.status === 'started' || order.status === 'picked_up' || order.status === 'on_way').length ||
                    !agent.orders.length
                  )
                );
            }))
            setBusyAgents(data.results.filter(agent => {
                return (
                  agent.isOnline &&
                  agent.orders.filter(order => order.status === 'started' || order.status === 'picked_up' || order.status === 'on_way').length
                );
            }))
            setInactiveAgents(data.results.filter(agent => !agent.isOnline));
            setAgentsLoading(false);
          })
          .catch((err) => {
              console.log(err.message);
              return setAgentsLoading(false);
          });
    }, [dateFields, selectedBranchId, userCompanyBranches?.list]);
    useEffect(() => {
        const startDate = moment(dateFields.startDate).utc().format();
        const endDate = moment(dateFields.endDate).utc().format();
        const branchIds = userCompanyBranches?.list.map(({ id }) => id);
        const companyAddressIds = selectedBranchId === -1 && branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : selectedBranchId !== -1 ? `&companyAddressIds=${selectedBranchId}` : '';

        api
          .get(`/partners?from=${startDate}&to=${endDate}${companyAddressIds}`)
          .then(res => {
              setPartners(res.data.results);
          })
          .catch(err => console.log(err))
    }, [dateFields, selectedBranchId, userCompanyBranches?.list])
    useEffect(() => {
        const branchMarkers = [];

        userCompanyBranches.list.forEach(address => {
            branchMarkers.push({
                id: address.id,
                type: 'branch',
                position: address.location,
                icon: BranchSvg,
                content: `
                    <div class="marker">${address.deliveryAddress}</div>
                `
            });
        });

        setBranchMarkers(branchMarkers);

        if (branchMarkers[0]?.position) {
            setCenter(branchMarkers[0]?.position);
        }
        if (!branchMarkers[0]?.position) {
            setCenter(DEFAULT_CENTER);
        }
    }, [userCompanyBranches]);
    useEffect(() => {
        if (!socket) {
            return;
        }

        //UNASSIGNED ORDERS
        socket.on('order:unassigned:add', order => {
            const startDate = moment(dateFields.startDate).utc().format();
            const endDate = moment(dateFields.endDate).utc().format();
            const orderDate = moment(order.startDate).utc().format();
            const orderMarkerExists = orderMarkers.find(({ id }) => id === order.id);
            const branchIds = userCompanyBranches?.list.map(({ id }) => id);

            if (
              orderDate >= startDate &&
              orderDate <= endDate &&
              (
                (selectedBranchId === -1 && branchIds.includes(order.pickupAddress.id)) ||
                (selectedBranchId !== -1 && selectedBranchId === order.pickupAddress.id)
              )
            ) {
                setUnassignedOrders({
                    ...unassignedOrders,
                    count: unassignedOrders.count + 1,
                    offset: unassignedOrders.offset + 1,
                    list: [
                        order,
                        ...unassignedOrders.list
                    ]
                });

                if (!orderMarkerExists) {
                    setOrderMarkers([
                        {
                            id: order.id,
                            type: 'order',
                            order,
                            position: order.address.location,
                            icon: ORDER_MARKERS[order.status],
                            content: `
                        <div class="marker">
                            Order #${order.externalId} - ${order.status} - ${order.address.deliveryAddress}
                        </div>
                      `,
                            onClick: () => setSelectedOrder(order)
                        },
                        ...orderMarkers
                    ]);
                }
                if (orderMarkerExists) {
                    setOrderMarkers(orderMarkers.map(orderMarker => {
                        if (orderMarker.id === order.id) {
                            return {
                                id: order.id,
                                type: 'order',
                                order,
                                position: order.address.location,
                                icon: ORDER_MARKERS[order.status],
                                content: `
                        <div class="marker">
                            Order #${order.externalId} - ${order.status} - ${order.address.deliveryAddress}
                        </div>
                      `,
                                onClick: () => setSelectedOrder(order)
                            };
                        }

                        return orderMarker;
                    }));
                }
            }
        });
        socket.on('order:unassigned:remove', order => {
            setUnassignedOrders({
                ...unassignedOrders,
                count: unassignedOrders.count - 1 === -1 ? 0 : unassignedOrders.count - 1,
                offset: unassignedOrders.offset - 1 === -1 ? 0 : unassignedOrders.offset - 1,
                list: unassignedOrders.list.filter(_order => _order.id !== order.id)
            });
            setOrderMarkers(orderMarkers.filter(orderMarker => orderMarker.id !== order.id));
        });
        socket.on('order:unassigned:change', order => {
            setUnassignedOrders({
                ...unassignedOrders,
                list: unassignedOrders.list.map(_order => {
                    if (_order.id === order.id) {
                        return order;
                    }

                    return _order;
                })
            });
            setOrderMarkers(orderMarkers.map(orderMarker => {
                if (orderMarker.id === order.id) {
                    return {
                        id: order.id,
                        type: 'order',
                        order,
                        position: order.address.location,
                        icon: ORDER_MARKERS[order.status],
                        content: `
                        <div class="marker">
                            Order #${order.externalId} - ${order.status} - ${order.address.deliveryAddress}
                        </div>
                      `,
                        onClick: () => setSelectedOrder(order)
                    };
                }

                return orderMarker;
            }));
        });
        //ASSIGNED ORDERS
        socket.on('order:assigned:add', order => {
            const startDate = moment(dateFields.startDate).utc().format();
            const endDate = moment(dateFields.endDate).utc().format();
            const orderDate = moment(order.startDate).utc().format();
            const orderMarkerExists = orderMarkers.find(({ id }) => id === order.id);
            const branchIds = userCompanyBranches?.list.map(({ id }) => id);

            if (
              orderDate >= startDate &&
              orderDate <= endDate &&
              (
                (selectedBranchId === -1 && branchIds.includes(order.pickupAddress.id)) ||
                (selectedBranchId !== -1 && selectedBranchId === order.pickupAddress.id)
              )
            ) {
                setAssignedOrders({
                    ...assignedOrders,
                    count: assignedOrders.count + 1,
                    offset: assignedOrders.offset + 1,
                    list: [
                        order,
                        ...assignedOrders.list
                    ]
                });

                if (!orderMarkerExists) {
                    setOrderMarkers([
                        {
                            id: order.id,
                            type: 'order',
                            order,
                            position: order.address.location,
                            icon: ORDER_MARKERS[order.status],
                            content: `
                        <div class="marker">
                            Order #${order.externalId} - ${order.status} - ${order.address.deliveryAddress}
                        </div>
                      `,
                            onClick: () => setSelectedOrder(order)
                        },
                        ...orderMarkers
                    ]);
                }
                if (orderMarkerExists) {
                    setOrderMarkers(orderMarkers.map(orderMarker => {
                        if (orderMarker.id === order.id) {
                            return {
                                id: order.id,
                                type: 'order',
                                order,
                                position: order.address.location,
                                icon: ORDER_MARKERS[order.status],
                                content: `
                        <div class="marker">
                            Order #${order.externalId} - ${order.status} - ${order.address.deliveryAddress}
                        </div>
                      `,
                                onClick: () => setSelectedOrder(order)
                            };
                        }

                        return orderMarker;
                    }));
                }
            }
        });
        socket.on('order:assigned:remove', order => {
            setAssignedOrders({
                ...assignedOrders,
                count: assignedOrders.count - 1 === -1 ? 0 : assignedOrders.count - 1,
                offset: assignedOrders.offset - 1 === -1 ? 0 : assignedOrders.offset - 1,
                list: assignedOrders.list.filter(_order => _order.id !== order.id)
            });
            setOrderMarkers(orderMarkers.filter(orderMarker => orderMarker.id !== order.id));
        });
        socket.on('order:assigned:change', order => {
            setAssignedOrders({
                ...assignedOrders,
                list: assignedOrders.list.map(_order => {
                    if (_order.id === order.id) {
                        return order;
                    }

                    return _order;
                })
            });
            setOrderMarkers(orderMarkers.map(orderMarker => {
                if (orderMarker.id === order.id) {
                    return {
                        id: order.id,
                        type: 'order',
                        order,
                        position: order.address.location,
                        icon: ORDER_MARKERS[order.status],
                        content: `
                        <div class="marker">
                            ${t("pages.dashboard.order")} #${order.externalId} - ${order.status} - ${order.address.deliveryAddress}
                        </div>
                      `,
                        onClick: () => setSelectedOrder(order)
                    };
                }

                return orderMarker;
            }));
        });
        //COMPLETED ORDERS
        socket.on('order:completed:add', order => {
            const startDate = moment(dateFields.startDate).utc().format();
            const endDate = moment(dateFields.endDate).utc().format();
            const orderDate = moment(order.startDate).utc().format();
            const orderMarkerExists = orderMarkers.find(({ id }) => id === order.id);
            const branchIds = userCompanyBranches?.list.map(({ id }) => id);

            console.log('order:completed:add', order);

            if (
              orderDate >= startDate &&
              orderDate <= endDate &&
              (
                (selectedBranchId === -1 && branchIds.includes(order.pickupAddress.id)) ||
                (selectedBranchId !== -1 && selectedBranchId === order.pickupAddress.id)
              )
            ) {
                setCompletedOrders({
                    ...completedOrders,
                    deliveredOrdersCount: order.status === "delivered" ? completedOrders.deliveredOrdersCount + 1 : completedOrders.deliveredOrdersCount,
                    failedOrdersCount: order.status === "failed" ? completedOrders.failedOrdersCount + 1 : completedOrders.failedOrdersCount,
                    canceledOrdersCount: order.status === "canceled" ? completedOrders.canceledOrdersCount + 1 : completedOrders.canceledOrdersCount,
                    count: completedOrders.count + 1,
                    offset: completedOrders.offset + 1,
                    list: [
                        order,
                        ...completedOrders.list
                    ]
                });

                if (!orderMarkerExists) {
                    setOrderMarkers([
                        {
                            id: order.id,
                            type: 'order',
                            order,
                            position: order.address.location,
                            icon: ORDER_MARKERS[order.status],
                            content: `
                                    <div class="marker">
                                        Order #${order.externalId} - ${order.status} - ${order.address.deliveryAddress}
                                    </div>
                                  `,
                            onClick: () => setSelectedOrder(order)
                        },
                        ...orderMarkers
                    ]);
                }
                if (orderMarkerExists) {
                    setOrderMarkers(orderMarkers.map(orderMarker => {
                        if (orderMarker.id === order.id) {
                            return {
                                id: order.id,
                                type: 'order',
                                order,
                                position: order.address.location,
                                icon: ORDER_MARKERS[order.status],
                                content: `
                                        <div class="marker">
                                            Order #${order.externalId} - ${order.status} - ${order.address.deliveryAddress}
                                        </div>
                                      `,
                                onClick: () => setSelectedOrder(order)
                            };
                        }

                        return orderMarker;
                    }));
                }
            }
        });
        socket.on('order:completed:remove', order => {
            setCompletedOrders({
                ...completedOrders,
                count: completedOrders.count - 1 === -1 ? 0 : completedOrders.count - 1,
                deliveredOrdersCount: order.status === "delivered" ? completedOrders.deliveredOrdersCount - 1 : completedOrders.deliveredOrdersCount,
                failedOrdersCount: order.status === "failed" ? completedOrders.failedOrdersCount - 1 : completedOrders.failedOrdersCount,
                canceledOrdersCount: order.status === "canceled" ? completedOrders.canceledOrdersCount - 1 : completedOrders.canceledOrdersCount,
                offset: completedOrders.offset - 1 === -1 ? 0 : completedOrders.offset - 1,
                list: completedOrders.list.filter(_order => _order.id !== order.id)
            });
            setOrderMarkers(orderMarkers.filter(orderMarker => orderMarker.id !== order.id));
        });
        socket.on('order:completed:change', order => {
            setCompletedOrders({
                ...completedOrders,
                list: completedOrders.list.map(_order => {
                    if (_order.id === order.id) {
                        return order;
                    }

                    return _order;
                })
            });
            setOrderMarkers(orderMarkers.map(orderMarker => {
                if (orderMarker.id === order.id) {
                    return {
                        id: order.id,
                        type: 'order',
                        order,
                        position: order.address.location,
                        icon: ORDER_MARKERS[order.status],
                        content: `
                        <div class="marker">
                            ${t("pages.dashboard.order")} #${order.externalId} - ${order.status} - ${order.address.deliveryAddress}
                        </div>
                      `,
                        onClick: () => setSelectedOrder(order)
                    };
                }

                return orderMarker;
            }));
        });
        //AGENTS CHANGE ALL
        socket.on('agent:change:all', () => refreshAgents());
        //AGENTS LAST LOCATION
        socket.on('agent:change:lastLocation', ({ id, lastLocation, lastActivity }) => {
            setAgentMarkers(agentMarkers.map(agentMarker => {
                if (agentMarker.id !== id) {
                    return agentMarker;
                }

                return {
                    ...agentMarker,
                    position: lastLocation
                };
            }));

            if (selectedAgent && selectedAgent.id === id) {
                setSelectedAgent({
                    ...selectedAgent,
                    lastLocation,
                    lastActivity
                });
            }
            if (freeAgents.find(agent => agent.id === id)) {
                return setFreeAgents(freeAgents.map(agent => {
                    if (agent.id === id) {
                        return {
                            ...agent,
                            lastLocation,
                            lastActivity
                        };
                    }

                    return agent;
                }));
            }
            if (busyAgents.find(agent => agent.id === id)) {
                return setBusyAgents(busyAgents.map(agent => {
                    if (agent.id === id) {
                        return {
                            ...agent,
                            lastLocation,
                            lastActivity
                        };
                    }

                    return agent;
                }));
            }
            if (inactiveAgents.find(agent => agent.id === id)) {
                return setInactiveAgents(inactiveAgents.map(agent => {
                    if (agent.id === id) {
                        return {
                            ...agent,
                            lastLocation,
                            lastActivity
                        };
                    }

                    return agent;
                }));
            }
        });
        //PARTNERS CHANGE ALL
        socket.on('partner:change:all', () => refreshPartners());

        //SOCKET OFF
        return () => {
            socket.off('order:unassigned:add');
            socket.off('order:unassigned:remove');
            socket.off('order:unassigned:change');
            socket.off('order:assigned:add');
            socket.off('order:assigned:remove');
            socket.off('order:assigned:change');
            socket.off('order:completed:add');
            socket.off('order:completed:remove');
            socket.off('order:completed:change');
            socket.off('agent:change:all');
            socket.off('partner:change:all');
            socket.off('agent:change:lastLocation');
        };
    }, [
      t,
        orderMarkers,
        setUnassignedOrders,
        setAssignedOrders,
        setCompletedOrders,
        dateFields,
        socket,
        agentMarkers,
        freeAgents,
        busyAgents,
        inactiveAgents,
        selectedOrder,
        selectedAgent,
        activeTabOrders,
        unassignedOrders,
        assignedOrders,
        completedOrders,
        refreshAgents,
        refreshPartners,
        userCompanyBranches?.list,
        selectedBranchId
    ]);
    useEffect(() => {
        const startDate = moment(dateFields.startDate).utc().format();
        const endDate = moment(dateFields.endDate).utc().format();
        const branchIds = userCompanyBranches?.list.map(({ id }) => id);
        const companyAddressIds = selectedBranchId === -1 && branchIds.length ? `&companyAddressIds=${branchIds.join(',')}` : selectedBranchId !== -1 ? `&companyAddressIds=${selectedBranchId}` : '';

        setAnalyticsLoading(true);

        api
          .get(`/orders/averages?from=${startDate}&to=${endDate}${companyAddressIds}`)
          .then(({data}) => {
              analyticsCtx.set(data);
              setAnalyticsLoading(false);
          })
          .catch(err => {
              setAnalyticsLoading(false);
              console.log(errToString(err));
          });

        // eslint-disable-next-line
    }, [analyticsCtx.set, dateFields, selectedBranchId, setSelectedBranchId, setDateFields, userCompanyBranches]);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!isAuth || !dateFields) {
        return;
    }

    return (
      <>
          <div className="dashboard">
              {showSignUpWarning && (
                <PopUp onOverlayClick={() => setShowSignUpWarning(false)}>
                    <div className="dashboard_form">
                        <img className="new_form_logo" src={Logo} alt="logo"/>
                        <p className="new_form_description">
                            {t("pages.dashboard.responsiveWarning")}
                        </p>
                        <div
                          className="new_form_actions"
                        >
                            <button onClick={() => setShowSignUpWarning(false)}>
                                {t("components.navigation.dashboard")}
                            </button>
                        </div>
                    </div>
                </PopUp>
              )}
              <div className="dashboard_container">
                  {windowWidth <= 1024 && (
                    <>
                        {responsivePage === 'orders' && (
                          <OrdersModal
                            mapIntegration={mapIntegration}
                            onClose={onCloseCreateOrder}
                            activeTab={activeTabOrders}
                            setActiveTab={setActiveTabOrders}
                            selectedOrderActiveTab={selectedOrderActiveTab}
                            setSelectedOrderActiveTab={setSelectedOrderActiveTab}
                            selectedOrder={selectedOrder}
                            setSelectedOrder={setSelectedOrder}
                            setIsNewOrderOpen={setIsNewOrderOpen}
                            unassignedOrders={unassignedOrders}
                            assignedOrders={assignedOrders}
                            completedOrders={completedOrders}
                            setUnassignedOrders={setUnassignedOrders}
                            setAssignedOrders={setAssignedOrders}
                            setCompletedOrders={setCompletedOrders}
                            setActiveTabOrders={setActiveTabOrders}
                            map={map}
                            startDate={dateFields.startDate}
                            endDate={dateFields.endDate}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            branchMarkers={branchMarkers}
                            selectedBranchId={selectedBranchId}
                            hasCreateOrderBtn={true}
                          />
                        )}
                        {responsivePage === 'map' && (
                          <div className="dashboard_container_main">
                              <>
                                  {(pathname === '/dashboard/orders' || pathname === '/') &&(
                                    <div className="dashboard_container_main_wrapper">
                                        <div
                                          ref={ref}
                                          className="dashboard_container_main_wrapper_in"
                                        >
                                            {(preferences.list.dashboard_view.value.type === 'list' ? pathname === '/dashboard/orders' : pathname === '/') && (
                                              <>
                                                  <DateTimePicker
                                                    className="dashboard_container_main_wrapper_calendar"
                                                    placeholder="DD/MM/YYYY HH:MM - HH:MM"
                                                    enableTimePicker={true}
                                                    position="bottom"
                                                    value={dateFields}
                                                    onChange={onDateTimeChange}
                                                  />
                                                  <div className="dashboard_container_main_wrapper_branch">
                                                      {branchFilterOptions && (
                                                        <DropDownFilter
                                                          placeholder=""
                                                          options={branchFilterOptions}
                                                          value={branchFilterOptions?.find(branch => branch.value === -1) || branchFilterOptions[0]}
                                                          onChange={branch => {
                                                              setUnassignedOrders({
                                                                  ...unassignedOrders,
                                                                  loading: true,
                                                                  list: null
                                                              });
                                                              setAssignedOrders({
                                                                  ...assignedOrders,
                                                                  loading: true,
                                                                  list: null
                                                              });
                                                              setCompletedOrders({
                                                                  ...completedOrders,
                                                                  loading: true,
                                                                  list: null
                                                              });
                                                              setSelectedBranchId(branch.id);
                                                          }}
                                                          position="bottom"
                                                          showClose={false}
                                                          width={ref.current?.offsetWidth}
                                                          popupLeft={ref.current?.getBoundingClientRect().left}
                                                        />
                                                      )}
                                                  </div>
                                              </>
                                            )}
                                        </div>
                                    </div>
                                  )}
                                  {center && mapIntegration.value && (
                                    <Map
                                      type={mapIntegration.value}
                                      zoom={13}
                                      center={center}
                                      mapOptions={filterOptions}
                                      setMap={setMap}
                                      map={map}
                                      markers={markers}
                                    />
                                  )}
                                  <div className="dashboard_actions">
                                      {user.role.permissions.includes("create_order") && (
                                        <button
                                          className="dashboard_actions_btn"
                                          onClick={() => setIsNewOrderOpen(true)}
                                        >
                                            <AiOutlinePlusCircle />
                                            {t("pages.dashboard.createOrder")}
                                        </button>
                                      )}
                                      <div className="dashboard_filter">
                                          <ActionBtn
                                            icon={<MdFilterListAlt />}
                                            onClick={() => setIsFilterOpen(!isFilterOpen)}
                                          />
                                      </div>
                                      {isFilterOpen && (
                                        <div className="dashboard_filter_container">
                                            <ul>
                                                {filterOptionsFields.map((option, index) => (
                                                  <li key={index}>
                                                      <label htmlFor={option.value}>{option.label}</label>
                                                      <input
                                                        name={option.value}
                                                        type="checkbox"
                                                        checked={filterOptions[option.value]}
                                                        onChange={e => {
                                                            setFilterOptions({
                                                                ...filterOptions,
                                                                [option.value]: e.target.checked
                                                            })
                                                        }}
                                                      />
                                                  </li>
                                                ))}
                                            </ul>
                                        </div>
                                      )}
                                      {isFilterOpen && (
                                        <div className="dashboard_filter_container_overlay" onClick={() => setIsFilterOpen(!isFilterOpen)} />
                                      )}
                                  </div>
                              </>
                          </div>
                        )}
                        {responsivePage === 'agents' && (
                          <AgentsModal
                            setShowCreateAgentForm={setShowCreateAgentForm}
                            showCreateAgentBtn={showCreateAgentBtn}
                            partners={partners}
                            mapIntegration={mapIntegration}
                            map={map}
                            freeAgents={freeAgents}
                            busyAgents={busyAgents}
                            inactiveAgents={inactiveAgents}
                            setFreeAgents={setFreeAgents}
                            setBusyAgents={setBusyAgents}
                            setInactiveAgents={setInactiveAgents}
                            activeTabAgents={activeTabAgents}
                            setActiveTabAgents={setActiveTabAgents}
                            selectedAgentActiveTab={selectedAgentActiveTab}
                            setSelectedAgentActiveTab={setSelectedAgentActiveTab}
                            setSelectedOrder={setSelectedOrder}
                            selectedAgent={selectedAgent}
                            setSelectedAgent={setSelectedAgent}
                            agentsLoading={agentsLoading}
                            setAgentsLoading={setAgentsLoading}
                            startDate={dateFields.startDate}
                            endDate={dateFields.endDate}
                            selectedBranchId={selectedBranchId}
                            setPartners={setPartners}
                          />
                        )}
                    </>
                  )}
                  {windowWidth > 1024 && (
                    <>
                        <OrdersModal
                          mapIntegration={mapIntegration}
                          onClose={onCloseCreateOrder}
                          activeTab={activeTabOrders}
                          setActiveTab={setActiveTabOrders}
                          selectedOrderActiveTab={selectedOrderActiveTab}
                          setSelectedOrderActiveTab={setSelectedOrderActiveTab}
                          selectedOrder={selectedOrder}
                          setSelectedOrder={setSelectedOrder}
                          setIsNewOrderOpen={setIsNewOrderOpen}
                          unassignedOrders={unassignedOrders}
                          assignedOrders={assignedOrders}
                          completedOrders={completedOrders}
                          setUnassignedOrders={setUnassignedOrders}
                          setAssignedOrders={setAssignedOrders}
                          setCompletedOrders={setCompletedOrders}
                          setActiveTabOrders={setActiveTabOrders}
                          map={map}
                          startDate={dateFields.startDate}
                          endDate={dateFields.endDate}
                          searchValue={searchValue}
                          setSearchValue={setSearchValue}
                          branchMarkers={branchMarkers}
                          selectedBranchId={selectedBranchId}
                          hasCreateOrderBtn={false}
                        />
                        <div className="dashboard_container_main">
                            <>
                                {(pathname === '/dashboard/orders' || pathname === '/') &&(
                                  <div className="dashboard_container_main_wrapper">
                                      <div
                                        ref={ref}
                                        className="dashboard_container_main_wrapper_in"
                                      >
                                          {(preferences.list.dashboard_view.value.type === 'list' ? pathname === '/dashboard/orders' : pathname === '/') && (
                                            <>
                                                <DateTimePicker
                                                  className="dashboard_container_main_wrapper_calendar"
                                                  placeholder="DD/MM/YYYY HH:MM - HH:MM"
                                                  enableTimePicker={true}
                                                  position="bottom"
                                                  value={dateFields}
                                                  onChange={onDateTimeChange}
                                                />
                                                <div className="dashboard_container_main_wrapper_branch">
                                                    {branchFilterOptions && (
                                                      <DropDownFilter
                                                        placeholder=""
                                                        options={branchFilterOptions}
                                                        value={branchFilterOptions?.find(branch => branch.value === -1) || branchFilterOptions[0]}
                                                        onChange={branch => {
                                                            setUnassignedOrders({
                                                                ...unassignedOrders,
                                                                loading: true,
                                                                list: null
                                                            });
                                                            setAssignedOrders({
                                                                ...assignedOrders,
                                                                loading: true,
                                                                list: null
                                                            });
                                                            setCompletedOrders({
                                                                ...completedOrders,
                                                                loading: true,
                                                                list: null
                                                            });
                                                            setSelectedBranchId(branch.id);
                                                        }}
                                                        position="bottom"
                                                        showClose={false}
                                                        width={ref.current?.offsetWidth}
                                                        popupLeft={ref.current?.getBoundingClientRect().left}
                                                      />
                                                    )}
                                                </div>
                                            </>
                                          )}
                                      </div>
                                  </div>
                                )}
                                {center && mapIntegration.value && (
                                  <Map
                                    type={mapIntegration.value}
                                    zoom={13}
                                    center={center}
                                    mapOptions={filterOptions}
                                    setMap={setMap}
                                    map={map}
                                    markers={markers}
                                  />
                                )}
                                <div className="dashboard_actions">
                                    {user.role.permissions.includes("create_order") && (
                                      <button
                                        className="dashboard_actions_btn"
                                        onClick={() => setIsNewOrderOpen(true)}
                                      >
                                          <AiOutlinePlusCircle />
                                          {t("pages.dashboard.createOrder")}
                                      </button>
                                    )}
                                    <div className="dashboard_filter">
                                        <ActionBtn
                                          icon={<MdFilterListAlt />}
                                          onClick={() => setIsFilterOpen(!isFilterOpen)}
                                        />
                                    </div>
                                    {isFilterOpen && (
                                      <div className="dashboard_filter_container">
                                          <ul>
                                              {filterOptionsFields.map((option, index) => (
                                                <li key={index}>
                                                    <label htmlFor={option.value}>{option.label}</label>
                                                    <input
                                                      name={option.value}
                                                      type="checkbox"
                                                      checked={filterOptions[option.value]}
                                                      onChange={e => {
                                                          setFilterOptions({
                                                              ...filterOptions,
                                                              [option.value]: e.target.checked
                                                          })
                                                      }}
                                                    />
                                                </li>
                                              ))}
                                          </ul>
                                      </div>
                                    )}
                                    {isFilterOpen && (
                                      <div className="dashboard_filter_container_overlay" onClick={() => setIsFilterOpen(!isFilterOpen)} />
                                    )}
                                </div>
                            </>
                        </div>
                        <AgentsModal
                          setShowCreateAgentForm={setShowCreateAgentForm}
                          showCreateAgentBtn={showCreateAgentBtn}
                          partners={partners}
                          mapIntegration={mapIntegration}
                          map={map}
                          freeAgents={freeAgents}
                          busyAgents={busyAgents}
                          inactiveAgents={inactiveAgents}
                          setFreeAgents={setFreeAgents}
                          setBusyAgents={setBusyAgents}
                          setInactiveAgents={setInactiveAgents}
                          activeTabAgents={activeTabAgents}
                          setActiveTabAgents={setActiveTabAgents}
                          selectedAgentActiveTab={selectedAgentActiveTab}
                          setSelectedAgentActiveTab={setSelectedAgentActiveTab}
                          setSelectedOrder={setSelectedOrder}
                          selectedAgent={selectedAgent}
                          setSelectedAgent={setSelectedAgent}
                          agentsLoading={agentsLoading}
                          setAgentsLoading={setAgentsLoading}
                          startDate={dateFields.startDate}
                          endDate={dateFields.endDate}
                          selectedBranchId={selectedBranchId}
                          setPartners={setPartners}
                        />
                    </>
                  )}
                  <div className="dashboard_container_pages">
                      <button
                        className={cn("dashboard_container_pages_btn", {"dashboard_container_pages_btn--active" : responsivePage === 'orders'})}
                        onClick={() => setResponsivePage('orders')}
                      >
                          <BiTask fill={"green"}/>
                          <p>{t("pages.dashboard.navOrders")}</p>
                      </button>
                      <button
                        className={cn("dashboard_container_pages_btn", {"dashboard_container_pages_btn--active" : responsivePage === 'map'})}
                        onClick={() => setResponsivePage('map')}
                      >
                          <FaMap fill={"#5C0089"}/>
                          <p>{t("pages.dashboard.navMap")}</p>
                      </button>
                      <button
                        className={cn("dashboard_container_pages_btn", {"dashboard_container_pages_btn--active" : responsivePage === 'agents'})}
                        onClick={() => setResponsivePage('agents')}
                      >
                          <BsFillPersonFill fill={"#f0b101"} />
                          <p>{t("pages.dashboard.navAgents")}</p>
                      </button>
                  </div>
              </div>
          </div>
          {showCreateAgentForm && (
            <PopUp>
                <CreateAgent
                  setShowQr={setShowQr}
                  type={"congrats"}
                  refreshAgents={refreshAgents}
                  onClose={(e) => {
                      e?.preventDefault();
                      setShowCreateAgentForm(false);
                  }}
                  setAgents={setAgentsData}
                  agents={agentsData}
                  setShowCreateAgentBtn={setShowCreateAgentBtn}
                />
            </PopUp>
          )}
          {showQr && (
            <PopUp>
                <DriverAppQR
                  showTitle={true}
                  onClose={() => setShowQr(false)}
                />
            </PopUp>
          )}
          {isNewOrderOpen && (
            <CreateOrder
              mapIntegration={mapIntegration}
              center={branchMarkers[0]?.position || DEFAULT_CENTER}
              onClose={onCloseCreateOrder}
              setActiveTabOrders={setActiveTabOrders}
              startDate={dateFields.startDate}
              endDate={dateFields.endDate}
              selectedBranchId={selectedBranchId}
            />
          )}
      </>
    );
}

Dashboard.propTypes = {
    mapIntegration: PropTypes.object.isRequired,
    isNewOrderOpen: PropTypes.bool.isRequired,
    setIsNewOrderOpen: PropTypes.func.isRequired
};

export default memo(Dashboard);
