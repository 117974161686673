import React from "react";

const AuthContext = React.createContext({
  isAuth: null,
  user: null,
  auth: () => {},
  logOut: () => {},
});

export default AuthContext;
