import "./index.scss";
import {memo, useState, useEffect, useCallback, useContext, useMemo} from "react";
import api from "../../utils/api"
import PageHeader from "../../components/layout/page-header";
import Container from "../../components/layout/container";
import Search from "../../components/search";
import DateTimePicker from "../../components/ui/calendar/date-time-picker-double-calendar";
import {getCurrentDateWithoutHours} from '../../utils/date';
import CreateBranchForm from "./create-branch-form";
import BranchCard from "./branch-card";
import Overlay from "../../components/ui/overlay";
import BranchSettingsPopup from "./branch-card/branch-settings-popup";
import BranchAllUsersPopup from "./branch-card/branch-all-users-popup";
import EditBranchNameForm from "./branch-card/edit-branch-name-form";
import AssignUsersForm from "./branch-card/assign-users-form";
import moment from "moment";
import AuthContext from "../../context/auth/auth-context";
import {useTranslation} from 'react-i18next';
import NoData from "../../components/ui/no-data";
import {createArrFromLength, errToString} from "../../utils";
import AgentCard from "../agents/agent-card";
import SwitcherBtn from "../../components/ui/switcher-btn";
import {BsPlus} from "react-icons/bs";

const SEARCH_FIELDS_BRANCHES = [
  {label: `name`, value: 'name'}
];

function Branches() {
  const {user} = useContext(AuthContext);
  const {t} = useTranslation();
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branchesData, setBranchesData] = useState(null);
  const [showFrozenBranches, setShowFrozenBranches] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isCreateBranchFormOpen, setIsCreateBranchFormOpen] = useState(false);
  const [isEditBranchNameFormOpen, setIsEditBranchNameFormOpen] = useState(false);
  const [isSeeAllManagersPopupOpen, setIsSeeAllManagersPopupOpen] = useState(false);
  const [isSeeAllAgentsPopupOpen, setIsSeeAllAgentsPopupOpen] = useState(false);
  const [isAssignUsersFormOpen, setIsAssignUsersFormOpen] = useState(false);
  const searchFieldsBranches = useMemo(() => SEARCH_FIELDS_BRANCHES.map(option => ({
    ...option,
    label: t(`pages.branches.${option.label}`)
  })), [t]);
  const [dateFields, setDateFields] = useState({
    startDate: getCurrentDateWithoutHours(),
    endDate: getCurrentDateWithoutHours(23, 59, 59),
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onSearch = useCallback(({ search, searchField }) => {
    const startDate = moment(dateFields.startDate).utc().format();
    const endDate = moment(dateFields.endDate).utc().format();
    setLoading(true);

    api
      .get(`/company/addresses?from=${startDate}&to=${endDate}${showFrozenBranches ? "&freezed" : ""}&includeUsers${search && searchField ? `&search=${search}&searchField=${searchField}` : ''}`)
      .then((res) => {
        setBranchesData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(errToString(err));
        setLoading(false);
      });
  }, [showFrozenBranches, dateFields]);

  useEffect(() => {
    const startDate = moment(dateFields.startDate).utc().format();
    const endDate = moment(dateFields.endDate).utc().format();

    api
      .get(`/company/addresses?from=${startDate}&to=${endDate}${showFrozenBranches ? "&freezed" : ""}&includeUsers`)
      .then((res) => {
        setBranchesData(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(errToString(err)))
  }, [showFrozenBranches, dateFields]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Container>
      <div className="branches">
        <PageHeader>
          <p>{t("pages.branches.branches")}</p>
        </PageHeader>
        <div className="branches_in_actions">
          <Search
            placeholder={t("pages.branches.searchBranch")}
            options={searchFieldsBranches}
            onSearch={onSearch}
          />
          <div className="branches_in_actions__switcher">
            <SwitcherBtn
              checked={showFrozenBranches}
              setChecked={setShowFrozenBranches}
              type={"freeze-unfreeze-branches"}
            />
          </div>
          <div className='orders_in_actions_calendar'>
            <DateTimePicker
              className="orders_date_time_picker"
              placeholder="DD/MM/YYYY HH:MM - HH:MM"
              enableTimePicker={true}
              position="bottom"
              theme='orders'
              enableRangePicker={true}
              value={[dateFields.startDate, dateFields.endDate]}
              onChange={e => {
                setDateFields(e);
                // setOffset(0);
              }}
            />
          </div>
          {user.role.permissions.includes("create_branch") && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsCreateBranchFormOpen(true);
              }}
            >
              {windowWidth > 1024 ? t("components.form.createBranch") : <BsPlus />}
            </button>
          )}
        </div>
        <div className="branches_in">
          {!loading && branchesData.map(branch => (
            <BranchCard
              key={branch.id}
              setSelectedBranch={setSelectedBranch}
              branch={branch}
              branchesData={branchesData}
              setBranchesData={setBranchesData}
              loading={loading}
              dateFields={dateFields}
              setIsSeeAllManagersPopupOpen={setIsSeeAllManagersPopupOpen}
              setIsSeeAllAgentsPopupOpen={setIsSeeAllAgentsPopupOpen}
            />
          ))}
          {loading && (
            <div className="agents_in_cards">
              {createArrFromLength(15).map((val, index) => (
                <AgentCard
                  key={index}
                  agent={{}}
                  loading={true}
                />
              ))}
            </div>
          )}
          {(!loading && !branchesData?.length) && (
            <div className="table_body_no_data">
              <NoData />
            </div>
          )}
        </div>
      </div>
      {isCreateBranchFormOpen && (
        <>
          <CreateBranchForm
            onCancel={() => setIsCreateBranchFormOpen(false)}
            setBranchesData={setBranchesData}
            branchesData={branchesData}
          />
          <Overlay />
        </>
      )}
      {selectedBranch && (!isSeeAllAgentsPopupOpen && !isSeeAllManagersPopupOpen) && (
        <BranchSettingsPopup
          selectedBranch={selectedBranch || []}
          setSelectedBranch={setSelectedBranch}
          setIsEditBranchNameFormOpen={setIsEditBranchNameFormOpen}
          setIsAssignUsersFormOpen={setIsAssignUsersFormOpen}
          setBranchesData={setBranchesData}
          branchesData={branchesData}
          dateFields={dateFields}
        />
      )}
      {selectedBranch && isEditBranchNameFormOpen  && (!isSeeAllAgentsPopupOpen && !isSeeAllManagersPopupOpen) && (
        <EditBranchNameForm
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          branchesData={branchesData}
          dateFields={dateFields}
          setBranchesData={setBranchesData}
          onCancel={() => setIsEditBranchNameFormOpen(false)}
        />
      )}
      {selectedBranch && isAssignUsersFormOpen  && (!isSeeAllAgentsPopupOpen && !isSeeAllManagersPopupOpen) && (
        <AssignUsersForm
          dateFields={dateFields}
          onCancel={() => setIsAssignUsersFormOpen(false)}
          selectedBranch={selectedBranch}
          setIsAssignUsersFormOpen={setIsAssignUsersFormOpen}
          setBranchesData={setBranchesData}
          setSelectedBranch={setSelectedBranch}
        />
      )}
      {selectedBranch && isSeeAllManagersPopupOpen && (
        <BranchAllUsersPopup
          selectedBranch={selectedBranch}
          onCancel={() => {
            setIsSeeAllManagersPopupOpen(false);
            setSelectedBranch(null);
          }}
          type="manager"
        />
      )}
      {selectedBranch && isSeeAllAgentsPopupOpen && (
        <BranchAllUsersPopup
          selectedBranch={selectedBranch}
          onCancel={() => {
            setIsSeeAllAgentsPopupOpen(false);
            setSelectedBranch(null);
          }}
          type="agent"
        />
      )}
    </Container>
  );
}

export default memo(Branches);
