import "./index.scss";
import React, {memo, useCallback, useEffect, useState, useMemo} from "react";
import {AiFillStar, AiOutlineCar, AiOutlinePhone} from "react-icons/ai";
import cn from "classnames";
import logo from '../../assets/logo.png'
import Map from "../../components/map";
import {ORDER_MARKERS} from "../../constants/markers";
import {MdOutlineSms} from "react-icons/md";
import api from "../../utils/api";
import {useLocation} from "react-router-dom";
import Loading from "../../components/ui/loading";
import io from 'socket.io-client';
import {MdDirectionsBike} from "react-icons/md";
import {useDebouncedCallback} from 'use-debounce';
import {DEFAULT_CENTER} from '../dashboard';
import {Feature} from 'ol';
import LineString from 'ol/geom/LineString';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import {Stroke, Style} from 'ol/style';
import {Loader} from '@googlemaps/js-api-loader';
import {animateTo} from '../../utils/marker';
import {getCenterFromLatLngOpenStreet, getZoomFromLatLngOpenStreet} from '../../utils/map';
import {HiOutlineLocationMarker} from "react-icons/hi";
import {IoIosArrowDown, IoIosArrowUp, IoMdBicycle, IoMdRefreshCircle} from "react-icons/io";
import {GrMoney} from "react-icons/gr";
import {toPriceWithCurrency} from "../../utils";
import {createPortal} from "react-dom";
import {FaCheck, FaTruck} from "react-icons/fa";

const calcRoute = (
from,
to,
mapType = 'google',
distanceUnit,
callback
) => {
  if (mapType === 'open_street') {
    api
      .get(`/ors?start=${from.lng},${from.lat}&end=${to.lng},${to.lat}`)
      .then(({ data: { features } }) => {
        const distanceValue = distanceUnit.value.unit === 'km' ? (features[0].properties.summary.distance / 1000).toFixed(1) : (features[0].properties.summary.distance / 1000 / 1.6).toFixed(1);
        const distance = `${distanceValue} ${distanceUnit.value.unit}`;
        const duration = `${(features[0].properties.summary.duration / 60).toFixed()} min`;
        const steps = features[0].geometry.coordinates;

        return callback({
          steps,
          distance,
          duration
        }, 'OK');
      })
      .catch(err => {
        console.log(err.message)

        return callback({
          steps: [],
          distance: 'N/A',
          duration: 'N/A'
        }, 'OK');
      });
  }
  if (mapType === 'google') {
    const directionsService = new window.google.maps.DirectionsService();
    const request = {
      origin: from,
      destination: to,
      travelMode: 'DRIVING',
      optimizeWaypoints: true
    };

    directionsService.route(request, (response, status) => {
      const routes = response.routes;
      let distanceValue = Number(routes[0]?.legs[0]?.distance?.text.replace(' km', '').replace(' mi', ''));
          distanceValue = distanceUnit.value.unit === 'km' ? distanceValue : distanceValue / 1.6;
          distanceValue = distanceValue.toFixed(1);
      const distance = `${distanceValue} ${distanceUnit.value.unit}`;
      const duration = routes[0]?.legs[0]?.duration?.text;
      const steps = routes[0]?.overview_path.map(location => ({ lat: location.lat(), lng: location.lng() })) || [];

      return callback({
        distance,
        duration,
        steps
      }, status);
    });
  }
}

function TrackingLink() {
  const location = useLocation();

  const [mapIntegration, setMapIntegration] = useState(null);
  const [preferences, setPreferences] = useState(null);
  const [duration, setDuration] = useState("...");
  const [openFooter, setOpenFooter] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [map, setMap] = useState(null);
  const [path, setPath] = useState(null);

  const [star1, setStar1] = useState(true)
  const [star2, setStar2] = useState(true)
  const [star3, setStar3] = useState(true)
  const [star4, setStar4] = useState(true)
  const [star5, setStar5] = useState(true)
  const [rating, setRating] = useState(5);
  const [review, setReview] = useState('')
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const trackingLinkId = useMemo(() => location.pathname.split("t/")[1], [location.pathname]);
  const [orderMarker, setOrderMarker] = useState(null);
  const [agentMarker, setAgentMarker] = useState(null);
  const [agentLocation, setAgentLocation] = useState(null);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const markers = useMemo(() => {
    const markers = [];

    if (orderMarker) {
      markers.push(orderMarker);
    }
    if (agentMarker) {
      markers.push({
        ...agentMarker,
        position: agentLocation || agentMarker.position
      });
    }

    return markers;
  }, [orderMarker, agentMarker, agentLocation]);
  const center = useMemo(() => {
    if (!order || !order.agent) {
      return;
    }

    const start = order.address.location;
    const end = order.agent.lastLocation;

    if (!start || !end) {
      return
    }

    return getCenterFromLatLngOpenStreet(start.lat, start.lng, end.lat, end.lng);
  }, [order]);
  const zoom = useMemo(() => {
    if (!order || !order.agent) {
      return;
    }

    const start = order.address.location;
    const end = order.agent.lastLocation;

    if (!start || !end) {
      return
    }

    return getZoomFromLatLngOpenStreet(start.lat, start.lng, end.lat, end.lng);
  }, [order]);

  const onSubmit = useCallback(() => {
    if (!order) {
      return;
    }

    const data = { rating: Number(rating) };

    if (review) {
      data.review = review;
    }

    api
      .put(`/orders/${order.id}/review`, data)
      .then(res => {
        setOrder(res.data);
        setIsSubmitted(true);
      })
      .catch((err) => console.log(err));
  }, [rating, review, order]);
  const onRefreshTrackingLink = useDebouncedCallback(() => {
    if (!mapIntegration) {
      return;
    }

    api
      .get(`/tracking-link/${trackingLinkId}`)
      .then(({ data }) => {
        const mapIntegration = data.company.integrations.find(({ name, isActive }) => name === 'map' && isActive);
        setMapIntegration(mapIntegration);

        const preferences = data.company.preferences.filter(({ name }) => name === 'tracking_link_redirect' || name === 'distance_unit');
        const distanceUnitPreference = preferences.find(({ name }) => name === 'distance_unit');

        calcRoute((data.agent.lastLocation || DEFAULT_CENTER), data.address.location, mapIntegration.value, distanceUnitPreference, ({ steps, distance, duration }, status) => {
          if (status !== 'OK') {
            return console.log(status);
          }

          if (mapIntegration.value === 'open_street') {
            map.removeLayer(path);

            const paths = [
              ...steps
            ];
            const styles = {
              'route': new Style({
                stroke: new Stroke({
                  width: 4,
                  color: 'rgba(131,63,165,0.7)'
                }),
              })
            }
            const route = new LineString(paths).transform('EPSG:4326', 'EPSG:3857');
            const routeFeature = new Feature({
              type: 'route',
              geometry: route,
            });
            const newPath = new VectorLayer({
              source: new VectorSource({
                features: [routeFeature]
              }),
              style: function (feature) {
                return styles[feature.get('type')];
              }
            });
            map.addLayer(newPath);
            setPath(newPath);
          }
          if (mapIntegration.value === 'google') {
            const paths = [
              ...steps
            ];
            path.setPath(paths);
          }

          setAgentMarker({
            id: data.agent.id,
            type: 'agent',
            position: data.agent.lastLocation,
            // icon: AGENT_MARKERS['online'],
            img: data.agent.src,
            imgSize: [40, 40],
            status: data.agent.isOnline ? 'online' : 'offline',
            content: `
              <div class="marker tracking_link__marker_time">${distance || 'N/A'} - ${duration || 'N/A'}</div>
            `,
            className: 'tracking_link__marker_time',
            open: true,
            animateView: true
          });
        });
      })
      .catch(err => console.log(err.message));
  }, 300);

  useEffect(() => {
    api
      .get(`/tracking-link/${trackingLinkId}`)
      .then((res) => {
        if (res.data.rating) {
          setIsSubmitted(true);
        }

        const promises = [];

        const mapIntegration = res.data.company.integrations.find(({ name, isActive }) => name === 'map' && isActive);
        setMapIntegration(mapIntegration);
        const preferences = res.data.company.preferences.filter(({ name }) => name === 'tracking_link_redirect' || name === 'distance_unit' || name === 'currency');
        setPreferences(preferences);

        if (mapIntegration.value === 'google') {
          promises.push(new Promise((resolve, reject) => {
            const loader = new Loader({
              apiKey: mapIntegration.credentials.apiKey,
              version: "weekly"
            });

            loader
              .load()
              .then(() => {
                window.google.maps.Marker.prototype.animateTo = animateTo;
                resolve();
              })
              .catch(err => {
                console.log(err.message)
                reject(err);
              });
          }));
        }

        Promise.all(promises).then(() => {
          const redirectPreference = preferences.find(({ name }) => name === 'tracking_link_redirect');

          if (
            redirectPreference.isActive &&
            redirectPreference.value?.url &&
            (res.data.rating || res.data.status === 'failed' || res.data.status === 'canceled')
          ) {
            return window.location.replace(redirectPreference.value.url);
          }

          setOrder(res.data);
          setLoading(false);
        });
      })
      .catch(err => console.log(err.message));
  }, [trackingLinkId, rating]);
  useEffect(() => {
    if (!order || !map || !mapIntegration?.value || !preferences) {
      return;
    }

    const distanceUnitPreference = preferences.find(({ name }) => name === 'distance_unit');
    calcRoute((order.agent.lastLocation || DEFAULT_CENTER), order.address.location, mapIntegration.value, distanceUnitPreference, ({ steps, distance, duration }, status) => {
      if (status !== 'OK') {
        setAgentMarker({
          id: order.agent.id,
          type: 'agent',
          position: order.agent.lastLocation,
          // icon: AGENT_MARKERS['online'],
          img: order.agent.src,
          imgSize: [40, 40],
          status: order.agent.isOnline ? 'online' : 'offline',
          content: `
            <div class="marker tracking_link__marker_time">${distance || 'N/A'} - ${duration || 'N/A'}</div>
          `,
          className: 'tracking_link__marker_time',
          open: true,
          animateView: true
        });
        setOrderMarker({
          id: order.id,
          type: 'order',
          position: order.address.location,
          icon: ORDER_MARKERS[order.status],
          content: `
            <div class="marker">
                ${order.address.deliveryAddress}
            </div>
          `,
          open: true
        });

        return console.log(status);
      }

      if (mapIntegration.value === 'open_street') {
        const paths = [
          ...steps
        ];
        const styles = {
          'route': new Style({
            stroke: new Stroke({
              width: 4,
              color: 'rgba(131,63,165,0.7)'
            }),
          })
        }
        const route = new LineString(paths).transform('EPSG:4326', 'EPSG:3857');
        const routeFeature = new Feature({
          type: 'route',
          geometry: route,
        });
        const path = new VectorLayer({
          source: new VectorSource({
            features: [routeFeature]
          }),
          style: function (feature) {
            return styles[feature.get('type')];
          }
        });
        map.addLayer(path);

        setPath(path);
      }
      if (mapIntegration.value === 'google') {
        const paths = [
          ...steps
        ];
        const path = new window.google.maps.Polyline({
          path: paths,
          geodesic: true,
          strokeColor: 'rgba(131,63,165,0.7)',
          strokeWeight: 4
        });
        path.setMap(map);

        setPath(path);
      }

      setDuration(duration);
      setOrderMarker({
        id: order.id,
        type: 'order',
        position: order.address.location,
        icon: ORDER_MARKERS[order.status],
        content: `
          <div class="marker">
              ${order.address.deliveryAddress}
          </div>
        `,
        open: true
      });
      setAgentMarker({
        id: order.agent.id,
        type: 'agent',
        position: order.agent.lastLocation,
        // icon: AGENT_MARKERS['online'],
        img: order.agent.src,
        imgSize: [40, 40],
        status: order.agent.isOnline ? 'online' : 'offline',
        content: `
            <div class="marker tracking_link__marker_time">${distance || 'N/A'} - ${duration || 'N/A'}</div>
        `,
        className: 'tracking_link__marker_time',
        open: true,
        animateView: true
      });
    });
  }, [order, map, mapIntegration?.value, preferences]);
  useEffect(() => {
    if (!isSubmitted) {
      return;
    }

    const timer = setTimeout(() => {
      const redirectPreference = preferences.find(({ name }) => name === 'tracking_link_redirect');

      if (redirectPreference.isActive && redirectPreference.value?.url && rating < 5) {
        return window.location.replace(redirectPreference.value.url);
      }
      if (redirectPreference.isActive && redirectPreference.value?.reviewUrl) {
        return window.location.replace(redirectPreference.value.reviewUrl);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [isSubmitted, rating, preferences]);
  useEffect(() => {
    if (!order || !map) {
      return;
    }

    const socket = io(process.env.REACT_APP_BASE_URL, {
      transports: ['websocket'],
      auth: {
        customerId: order.customer.id
      }
    });

    socket.on('connect', () => {
      console.log('connected');
      setIsConnected(true);
    });

    socket.on('order:tracking', location => {
      setAgentLocation(location);

      if (mapIntegration.value === 'google') {
        // map.panTo(location);
      }
    });

    socket.on('order:tracking:completed', order => {
      if (order.status === 'failed' || order.status === 'canceled') {
        const redirectPreference = preferences.find(({ name }) => name === 'tracking_link_redirect');

        if (redirectPreference.isActive &&redirectPreference.value?.url && order.rating < 5) {
          return window.location.replace(redirectPreference.value.url);
        }
        if (redirectPreference.isActive && redirectPreference.value?.reviewUrl) {
          return window.location.replace(redirectPreference.value.reviewUrl);
        }
      }

      setOrder(order);
    });

    socket.on('disconnect', reason => {
      console.log('disconnect', reason);
      socket.disconnect();
    });

    return () => {
      socket.off('connect');
      socket.off('order:tracking');
      socket.off('disconnect');
    };
  }, [order, map, mapIntegration?.value, preferences]);
  useEffect(() => {
    if (!order || !path || !isConnected || !trackingLinkId) {
      return;
    }

    const interval = mapIntegration?.value === 'open_street' ? 1000 : 30000;
    const intervalId = setInterval(() => onRefreshTrackingLink(), interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [order, path, isConnected, trackingLinkId, onRefreshTrackingLink, mapIntegration?.value]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (windowWidth < 600) {
      return setOpenFooter(false)
    } else {
      return setOpenFooter(true)
    }
  }, [windowWidth])

  return (
    <div className="tracking_link">
      {
        !loading &&
        order &&
        mapIntegration && (
          <div className="tracking_link_map">
            <div className="tracking_link_map_header">
              <div className="tracking_link_map_header_img">
                <span>powered by</span>
                <img
                  src={logo}
                  alt="Task Road logo"
                  onClick={() => window.open("https://rebrand.ly/taskroad", '_blank')}
                />
              </div>
              {!loading && order && order.status !== 'delivered' && (
                <>
                  <p>Order #{order.externalId}</p>
                  <div
                    className="tracking_link_map_header_refresh"
                    onClick={onRefreshTrackingLink}
                  >
                    <IoMdRefreshCircle/>
                  </div>
                </>
              )}
            </div>
              {center && zoom && (
                <Map
                  type={mapIntegration.value}
                  center={center}
                  zoom={zoom}
                  setMap={setMap}
                  map={map}
                  markers={markers}
                />
              )}
              {(!center || !zoom) && (
                <p className="tracking_link_map_location">Driver's location not found. Please refresh the page.</p>
              )}
            {
              order.status !== 'delivered' &&
              order.status !== 'failed' &&
              order.status !== 'canceled' && (
                <div className={cn(
                  "tracking_link_map_footer",
                  {"tracking_link_map_footer--animate-up" : openFooter}, {"tracking_link_map_footer--animate-down" : !openFooter}
                )}
                >
                  {windowWidth <= 600 && (
                    <i
                      className="tracking_link_map_footer_arrow"
                      onClick={() => setOpenFooter(!openFooter)}
                    >
                      {openFooter && (
                        <IoIosArrowDown />
                      )}
                      {!openFooter && (
                        <IoIosArrowUp />
                      )}
                    </i>
                  )}
                  <div className="tracking_link_map_footer_main">
                    <p className="tracking_link_map_footer_title">Arriving in {duration}</p>
                    {order.agent.vehicleType === "bike" && (
                      <p className="tracking_link_map_footer_car">
                        <MdDirectionsBike />
                        Bike
                      </p>
                    )}
                    {order.agent.vehicleType === "car" && (
                      <p className="tracking_link_map_footer_car">
                        <AiOutlineCar />
                        {order.agent.carBrand} {order.agent.carModel}
                        <span>{order.agent.carPlate}</span>
                      </p>
                    )}
                    {order.agent.vehicleType === "bicycle" && (
                      <p className="tracking_link_map_footer_car">
                        <IoMdBicycle />
                        Bicycle
                      </p>
                    )}
                    {order.agent.vehicleType === "truck" && (
                      <p className="tracking_link_map_footer_car">
                        <FaTruck />
                        Truck
                      </p>
                    )}
                    <img
                      src={order.agent.src}
                      alt="Agent"
                    />
                    <div className="tracking_link_map_footer_driver">
                      <p className="tracking_link_map_footer_driver_name">
                        {order.agent.firstName} {order.agent.lastName}
                      </p>
                      {windowWidth <= 600 && (
                        <div className="tracking_link_map_footer_driver_actions">
                          <a href={`tel:${order.agent.phoneNumber}`}>
                            <AiOutlinePhone/>
                          </a>
                          <a href={`sms:${order.agent.phoneNumber}`}>
                            <MdOutlineSms/>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="tracking_link_map_footer_order">
                    <div className="tracking_link_map_footer_order_in">
                      <div className="tracking_link_map_footer_order_in_icon tracking_link_map_footer_order_in_icon--pin">
                        <HiOutlineLocationMarker />
                      </div>
                      <div className="tracking_link_map_footer_order_in_details">
                        <span>Delivery address</span>
                        <p> {order.address.deliveryAddress}</p>
                      </div>
                    </div>
                    <div className="tracking_link_map_footer_order_in">
                      <div className="tracking_link_map_footer_order_in_icon tracking_link_map_footer_order_in_icon--money">
                        <GrMoney />
                      </div>
                      <div className="tracking_link_map_footer_order_in_details">
                        <span>{order.paymentMethod.name}</span>
                        <p>{toPriceWithCurrency(order.amount, preferences.find(({ name }) => name === 'currency').value.currency)}</p>
                      </div>
                    </div>
                    {windowWidth <= 600 && (
                      <div className="tracking_link_map_footer_order_actions">
                        <a href={`tel:${order.agent.phoneNumber}`}>
                          <AiOutlinePhone/>
                        </a>
                        <a href={`sms:${order.agent.phoneNumber}`}>
                          <MdOutlineSms/>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
        </div>
        )
      }
      {!loading && order && order.status === 'delivered' && (
        <div className="tracking_link_rating">
          {isSubmitted && (
            <div className="tracking_link_rating_success tracking_link_rating_success--end">
              <FaCheck />
              Thank you for your review!
            </div>
          )}
          <>
            {!isSubmitted && (
              <>
                <div className="tracking_link_rating_success">
                  <FaCheck />
                  Your order #{order.externalId} is delivered!
                </div>
                <div className="tracking_link_map_footer_main">
                  {order.agent.vehicleType === "bike" && (
                    <p className="tracking_link_map_footer_car">
                      <MdDirectionsBike />
                      Bike
                    </p>
                  )}
                  {order.agent.vehicleType === "car" && (
                    <p className="tracking_link_map_footer_car">
                      <AiOutlineCar />
                      {order.agent.carBrand} {order.agent.carModel}
                      <span>{order.agent.carPlate}</span>
                    </p>
                  )}
                  {order.agent.vehicleType === "bicycle" && (
                    <p className="tracking_link_map_footer_car">
                      <IoMdBicycle />
                      Bicycle
                    </p>
                  )}
                  {order.agent.vehicleType === "truck" && (
                    <p className="tracking_link_map_footer_car">
                      <FaTruck />
                      Truck
                    </p>
                  )}
                  <div className="tracking_link_rating_img">
                    <img
                      src={order.agent.src}
                      alt="Agent"
                    />
                  </div>
                  <div className="tracking_link_map_footer_driver">
                    <p className="tracking_link_map_footer_driver_name">
                      {order.agent.firstName} {order.agent.lastName}
                    </p>
                  </div>
                </div>
                <h3 className="tracking_link_rating_label">
                  Leave your review here
                </h3>
                <div className="tracking_link_rating_in">
                  <div
                    className={cn('tracking_link_rating_stars', {"tracking_link_rating_stars--active" : star1})}
                    onClick={() => {
                      setStar1(false)
                      setStar2(false)
                      setStar3(false)
                      setStar4(false)
                      setStar5(false)
                      setStar1(true)
                      setRating(1)
                    }}
                  >
                    <AiFillStar />
                  </div>
                  <div
                    className={cn('tracking_link_rating_stars', {"tracking_link_rating_stars--active" : star1 && star2})}
                    onClick={() => {
                      setStar1(false)
                      setStar2(false)
                      setStar3(false)
                      setStar4(false)
                      setStar5(false)
                      setStar1(true)
                      setStar2(true)
                      setRating(2)
                    }}
                  >
                    <AiFillStar />
                  </div>
                  <div
                    className={cn('tracking_link_rating_stars', {"tracking_link_rating_stars--active" : star1 && star2 && star3})}
                    onClick={() => {
                      setStar1(false)
                      setStar2(false)
                      setStar3(false)
                      setStar4(false)
                      setStar5(false)
                      setStar1(true)
                      setStar2(true)
                      setStar3(true)
                      setRating(3)
                    }}
                  >
                    <AiFillStar />
                  </div>
                  <div
                    className={cn('tracking_link_rating_stars', {"tracking_link_rating_stars--active" : star1 && star2 && star3 && star4})}
                    onClick={() => {
                      setStar1(false)
                      setStar2(false)
                      setStar3(false)
                      setStar4(false)
                      setStar5(false)
                      setStar1(true)
                      setStar2(true)
                      setStar3(true)
                      setStar4(true)
                      setRating(4)
                    }}
                  >
                    <AiFillStar />
                  </div>
                  <div
                    className={cn('tracking_link_rating_stars', {"tracking_link_rating_stars--active" : star1 && star2 && star3 && star4 && star5})}
                    onClick={() => {
                      setStar1(false)
                      setStar2(false)
                      setStar3(false)
                      setStar4(false)
                      setStar5(false)
                      setStar1(true)
                      setStar2(true)
                      setStar3(true)
                      setStar4(true)
                      setStar5(true)
                      setRating(5)
                    }}
                  >
                    <AiFillStar />
                  </div>
                </div>
                <div className="tracking_link_rating_review">
              <textarea
                name="review"
                autoComplete="off"
                maxLength={200}
                placeholder="Leave your review here"
                value={review}
                onChange={(e) => setReview(e.target.value)}
              />
                </div>
                <button
                  className="tracking_link_rating_btn"
                  onClick={onSubmit}
                >Submit</button>
              </>
            )}
            {createPortal(
              <div className="tracking_link_rating_overlay" />,
              document.getElementById('modal')
            )}
          </>
        </div>
      )}
      {loading && !order && (
        <div className="tracking_link_loading">
          <Loading/>
        </div>
      )}
    </div>
  );
}

export default memo(TrackingLink);
