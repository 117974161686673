import { memo } from "react";
import "./index.scss";
import cn from "classnames";
import PropTypes from "prop-types";

function Tab({
title,
isActive,
onClick,
count,
deliveredCount,
failedCount,
canceledCount
}) {

  return (
    <div
      className={cn("menu_tab_container", {" menu_tab_container--active" : isActive})}
      onClick={onClick}
    >
      <button className={cn("menu_tab_container_btn", {" menu_tab_container_btn--active" : isActive})}>
          {title}
      </button>
      {(count || count === 0) && (
        <div className={cn("menu_tab_container_count", {" menu_tab_container_count--active" : isActive})}>
          {count > 9999 ? '+9999' : count} {failedCount + canceledCount > 0 ? `(${failedCount + canceledCount})` : ""}
        </div>
      )}
    </div>
  );
}
Tab.propTypes = {
  title: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  count: PropTypes.number,
};
export default memo(Tab);
