import './index.scss';
import React, {memo} from "react";
import PropTypes from 'prop-types';
import GoogleMap from './google-map';
import OpenStreetMap from './open-street-map';

function Map({
  type = 'google',
  target,
  zoom,
  center,
  mapOptions,
  map,
  setMap,
  markers = [],
  isMarkerDraggable
}) {
  if (type === 'google') {
    return (
      <GoogleMap
        zoom={zoom}
        center={center}
        mapOptions={mapOptions}
        setMap={setMap}
        map={map}
        markers={markers}
      />
    );
  }
  if (type === 'open_street') {
    return (
      <OpenStreetMap
        target={target}
        zoom={zoom}
        center={center}
        setMap={setMap}
        map={map}
        markers={markers}
        isMarkerDraggable={isMarkerDraggable}
      />
    );
  }

  return null;
}

Map.propTypes = {
  type: PropTypes.string,
  target: PropTypes.string,
  zoom: PropTypes.number.isRequired,
  center: PropTypes.object.isRequired,
  mapOptions: PropTypes.object,
  setMap: PropTypes.func.isRequired,
  map: PropTypes.object,
  markers: PropTypes.array,
  isMarkerDraggable: PropTypes.bool
};

export default memo(Map);
