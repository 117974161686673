import "./index.scss";
import React, {memo} from "react";
import {createPortal} from "react-dom";
import PropTypes from "prop-types";
import BillingProductsModalCard from "./billing-produts-modal-card";
import {IoMdClose} from "react-icons/io";
import {useTranslation} from "react-i18next";

function BillingProductsModal({
subscriptionsMonthly,
subscriptionsYearly,
setChangePlanIsOpen,
onSelectSubscription,
pending,
showButton,
setSubscriptionType,
subscriptionType
}) {
  const {t} = useTranslation();

  return createPortal(
    <div className="billing_products_modal">
      <div className="billing_products_modal_header">
        <div className="billing_products_modal_header_in">
          <div className="billing_products_modal_header_wrapper">
            <h2 className="billing_products_modal__title">
              {t("pages.billing.subscriptionPlan")}
            </h2>
            <div
              className="billing_products_modal__close"
              onClick={() => setChangePlanIsOpen(false)}
            >
              <IoMdClose />
            </div>
          </div>
          <p className="billing_products_modal__descrip">
            {t("pages.billing.subscriptionPlanDescription")}
          </p>
          <div
            className="billing_products_modal__switcher"
            onClick={() => {
              if (subscriptionType === "yearly") {
                return setSubscriptionType("monthly")
              }
              if (subscriptionType === "monthly") {
                return setSubscriptionType("yearly")
              }
            }}
          >
            <p>
              {subscriptionType === "monthly" ? t("pages.billing.yearly") : t("pages.billing.monthly")}
            </p>
          </div>
        </div>
      </div>
      <div className="billing_products_modal_in">
        {subscriptionType === "monthly" && (
          subscriptionsMonthly.map(product => (
            <BillingProductsModalCard
              key={product.id}
              name={product.name}
              price={product.price}
              currency={product.currency}
              descriptions={product.description}
              onSelect={() => onSelectSubscription(product.id)}
              recommended={product.isRecommended}
              pending={pending}
              showButton={showButton}
            />
          ))
        )}
        {subscriptionType === "yearly" && (
          subscriptionsYearly.map(product => (
            <BillingProductsModalCard
              key={product.id}
              name={product.name}
              price={product.price}
              currency={product.currency}
              descriptions={product.description}
              onSelect={() => onSelectSubscription(product.id)}
              recommended={product.isRecommended}
              pending={pending}
              showButton={showButton}
            />
          ))
        )}
      </div>
    </div>,
    document.getElementById('modal')
  );
}

BillingProductsModal.propTypes = {
    subscriptionsMonthly: PropTypes.array.isRequired,
    subscriptionsYearly: PropTypes.array.isRequired,
    setChangePlanIsOpen: PropTypes.func.isRequired,
    onSelectSubscription: PropTypes.func.isRequired
}

export default memo(BillingProductsModal);
