import {memo, useMemo} from "react";

function TableColumn({ name, size, colMinWidth }) {
  const style = useMemo(() => {
    if (size) {
      return { minWidth: size }
    }

    return { width: colMinWidth };
  }, [size, colMinWidth]);

  return (
    <th style={style}>
      {name}
    </th>
  );
}

export default memo(TableColumn);
