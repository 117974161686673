import './index.scss';
import {memo, useState} from 'react';
import PropTypes from 'prop-types';
import TimelineMaker from '../../../../components/timeline-maker';
import PopUp from '../../../../components/layout/popup';
import {useTranslation} from "react-i18next";
import NoData from "../../../../components/ui/no-data";

function AgentTimeline({ mapIntegration, agentId, agentSrc, orders = [] }) {
    const [selectedOrder, setSelectedOrder] = useState(null);
    const {t} = useTranslation();

    return (
        <>
            <div className="agent_timeline">
                {!!orders.length && (
                  <div className="agent_timeline_list">
                      {orders.map(order => (
                        <div
                          key={order.id}
                          className="agent_timeline_btn"
                          onClick={() => setSelectedOrder(order)}
                        >
                          <h2>{t("pages.dashboard.agents_modal.agent_timeline.showMovement")} #{order.externalId}</h2>
                          <p>{order.address.deliveryAddress}</p>
                        </div>
                      ))}
                  </div>
                )}
              {!orders.length && (
                <div className="agent_timeline_no_data">
                  <NoData/>
                </div>
              )}
            </div>
            {selectedOrder && (
              <PopUp onOverlayClick={() => setSelectedOrder(null)}>
                <TimelineMaker
                  mapIntegration={mapIntegration}
                  agentId={agentId}
                  agentSrc={agentSrc}
                  order={selectedOrder}
                />
              </PopUp>
            )}
        </>
    );
}

AgentTimeline.propTypes = {
    mapIntegration: PropTypes.object.isRequired,
    agentId: PropTypes.number.isRequired,
    agentSrc: PropTypes.string.isRequired,
    orders: PropTypes.array
};

export default memo(AgentTimeline)
