import "./index.scss";
import {memo, useContext, useEffect, useMemo, useState} from "react";
import { useStopwatch } from 'react-timer-hook';
import cn from "classnames";
import PropTypes from 'prop-types';
import PreferencesContext from "../../../context/preferences/preferences-context";

function StopWatch({ order }) {
  const {list} = useContext(PreferencesContext)
  const [normative, setNormative] = useState(false);
  const [midNormative, setMidNormative] = useState(false);
  const endDate = useMemo(() => {

    if (order.status === 'failed' || order.status === 'canceled') {
      return new Date(order.updatedAt);
    }
    if (order.status === 'delivered') {
      return new Date(order.history.find(history => history.status === 'delivered').updatedAt);
    }

    return Date.now();
  }, [order]);
  const offset = useMemo(() => (endDate - new Date(order.createdAt)) / 1000, [order.createdAt, endDate]);
  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + offset);
  const {
    seconds,
    minutes,
    hours,
    days,
    pause
  } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });

  useEffect(() => {
    const orderTotalTimeMin = (days * 24 * 60) + (hours * 60) + minutes + (seconds / 60);

    if (orderTotalTimeMin >= list.order_delay_time.value.low / 60) {
      setMidNormative(true);
    }
    if (orderTotalTimeMin >= list.order_delay_time.value.high / 60) {
      setMidNormative(false);
      setNormative(true);
    }
    if((order.status === 'delivered' || order.status === 'failed' || order.status === 'canceled')) {
      pause();
    }

  }, [list.order_delay_time.value.low, list.order_delay_time.value.high, days, minutes, hours, seconds, order, pause])

  return (
    <div className="stop_watch">
      <div className={cn(
        "stop_watch_wrapper",
        {"stop_watch_wrapper--red" : (normative && order.deliveryType !== 'pre')},
        {"stop_watch_wrapper--gray" : order.deliveryType === 'pre'},
        {"stop_watch_wrapper--yellow" : (midNormative && order.deliveryType !== 'pre')})
      }>
        {days > 0 && (
          <>
            <p>{days}d</p>
          </>
        )}
        {days === 0 && (
          <>
            {hours > 0 && (
              <>
                <p>{hours}h</p><span>:</span>
              </>
            )}
            <>
              <p>{minutes}m</p><span>:</span>
              <p>{seconds}</p>
            </>
          </>
        )}
      </div>
    </div>
  );
}

StopWatch.propTypes = {
  order: PropTypes.object.isRequired
};

export default memo(StopWatch)
