import "./index.scss";
import {memo, useCallback, useEffect, useRef, useState} from "react";
import {createPortal} from 'react-dom';
import Form from "../../../../../components/form";
import FormRow from "../../../../../components/form/form-row";
import FormCol from "../../../../../components/form/form-col";
import PropTypes from "prop-types";
import DropDown from "../../../../../components/ui/drop-down";
import api from "../../../../../utils/api";
import {useTranslation} from "react-i18next";
import Loading from "../../../../../components/ui/loading";
import {errToString} from "../../../../../utils";

const AGENT_TAGS = [
  {id: 1, label: "Tracking link", value: "{trackingLink}"},
  {id: 2, label: "Agent first name", value: "{agentFirstName}"},
  {id: 3, label: "Agent last name", value: "{agentLastName}"},
  {id: 4, label: "Agent phone number", value: "{agentPhoneNumber}"},
  {id: 5, label: "Agent Car brand", value: "{agentCarBrand}"},
  {id: 6, label: "Agent Car model", value: "{agentCarModel}"},
  {id: 7, label: "Agent Car plate", value: "{agentCarPlate}"},
  {id: 8, label: "Order Id", value: "{orderId}"},
  {id: 9, label: "Order start date", value: "{orderStartDate}"},
  {id: 10, label: "Order end date", value: "{orderEndDate}"}
]
const PARTNER_TAGS = [
  {id: 1, label: "Tracking link", value: "{trackingLink}"},
  {id: 2, label: "Partner name", value: "{partnerName}"},
  {id: 8, label: "Order Id", value: "{orderId}"},
  {id: 9, label: "Order start date", value: "{orderStartDate}"},
  {id: 10, label: "Order end date", value: "{orderEndDate}"}
]
function containsUnicode(target) {
  for (let i = 0; i < target.length; i++) {
    const codePoint = target.charCodeAt(i);

    // Check if the code point is outside the ASCII range
    if (codePoint > 127) {
      return true; // Unicode character found
    }
  }
  return false; // No Unicode characters found
}

function EditNotificationsForm({
type,
id,
name,
onCancel,
format,
templates,
setTemplates
}) {

  const ref = useRef(null);
  const {t} = useTranslation();
  const [pending, setPending] = useState(false)
  const [isUnicode, setIsUnicode] = useState(false);
  const [newFormat, setNewFormat] = useState(format);
  const [formatIndex, setFormatIndex] = useState(-1);
  const [tag, setTag] = useState(null);
  const [error, setError] = useState(null)

  const onChangeFormat = useCallback((e) => {
    setIsUnicode(containsUnicode(e.target.value));
    setError(null);
    setNewFormat(e.target.value);
  }, []);
  const onAddTag = useCallback((tag) => {
    setError(null);
    const newFormatArr = newFormat.split('');
    newFormatArr.splice(formatIndex, 0, tag);

    setNewFormat(newFormatArr.join(''));
  }, [newFormat, formatIndex])
  const onSelectionChange = useCallback(() => {
    if (document.activeElement !== ref.current) {
      return;
    }

    setFormatIndex(document.activeElement.selectionStart);
  }, []);
  const onSubmit = useCallback((e) => {
    e.preventDefault();

    setError(null);
    setPending(true);

    api
      .put(`/company/templates/${id}`, {
        format: newFormat
      })
      .then((res) => {
        setTemplates(templates.map(obj => {
          if (obj.id === res.data.id) {
            return res.data
          }
          return obj
        }))
        setPending(false);
        onCancel();
      })
      .catch(err => {
        setError(errToString(err));
        console.log(errToString(err));
        setPending(false);
      })
  }, [id, onCancel, newFormat, setTemplates, templates]);

  useEffect(() => {
    document.addEventListener('selectionchange', onSelectionChange);

    return () => document.removeEventListener('selectionchange', onSelectionChange);
  }, [onSelectionChange]);

  return createPortal(
    <div className="edit_notifications_form">
      <Form
        title={`${t(`pages.settings.notificationContent`)} - ${t(`statuses.${name}`)} `}
        error={error && t(`errors.${error?.toLowerCase()}`)}
        onSubmit={onSubmit}
      >
        <FormRow>
          <FormCol>
            <label htmlFor="notification_content">
              {t(`pages.settings.notificationTag`)}
            </label>
            <DropDown
                hasTagIcon={true}
                placeholder="Choose options"
                options={type === "partner" ? PARTNER_TAGS : AGENT_TAGS}
                value={tag}
                onChange={(e) => {
                  setTag(e);
                  onAddTag(e.value);
                }}
                position="bottom"
                showClose={false}
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <label htmlFor="notification_content">
              <span>*</span>
              {t(`pages.settings.notificationContent`)}
            </label>
            <textarea
              ref={ref}
              name="notification_content"
              placeholder={t(`pages.settings.notificationContent`)}
              maxLength={isUnicode ? 40 : 110}
              minLength={0}
              value={newFormat}
              onChange={onChangeFormat}
            />
            <div className="edit_notifications_form_count">
              <span>{t(`pages.settings.count`)}:</span>
              <p>{newFormat ? newFormat.length : 0}</p>
            </div>
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <button
                className="create_partner_form_cancel_btn"
                onClick={onCancel}
            >{t("pages.branches.create_branch_form.cancel")}</button>
          </FormCol>
          <FormCol>
            {!pending && (
                <button className="create_partner_form_submit_btn" type="submit">{t("pages.branches.create_branch_form.save")}</button>
            )}
            {pending && (
                <button className="create_order_submit_btn create_order_submit_btn--pending">
                  <Loading />
                </button>
            )}
          </FormCol>
        </FormRow>
      </Form>
    </div>,
    document.getElementById('modal')
  );
}

EditNotificationsForm.propTypes = {
  onCancel: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.number,
  format: PropTypes.string,
  setTemplates: PropTypes.func,
  templates:PropTypes.array
}

export default memo(EditNotificationsForm);
