import React, {memo, useContext} from "react";
import "./index.scss";
import { CgProfile } from "react-icons/cg";
import {AiFillPhone, AiOutlineNumber} from "react-icons/ai";
import {RiMoneyDollarCircleFill} from "react-icons/ri";
import { BsFillPinMapFill } from "react-icons/bs";
import { BsChatDotsFill } from "react-icons/bs";
import { MdDateRange } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import { MdLabel } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { HiOutlineExternalLink } from "react-icons/hi";
import moment from "moment";
import PropTypes from 'prop-types';
import {toPriceWithCurrency} from "../../../../utils";
import PreferencesContext from "../../../../context/preferences/preferences-context";
import {useTranslation} from "react-i18next";
import cn from "classnames";

function OrderInfo({ order }) {
  const {list} = useContext(PreferencesContext);
  const {t} = useTranslation();

  console.log("order",order)
  return (
      <div className="order_info">
        <div className="order_info_title">{t("pages.dashboard.order_details.order_info.orderDetails")}</div>
        <div className="order_info_in">
            <div className="order_info_in_row">
              <div className="order_info_in_row_wrapper">
                <MdLabel />
                <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.status")}</p>
                <div
                    className={cn(`order_info_in_row__status order_info_in_row__status--${order.status}`)}
                >
                  <p>{t(`statuses.${order.status}`)}</p>
                </div>
              </div>
            </div>
            {order.extraId && (
              <div className="order_info_in_row">
                <div className="order_info_in_row_wrapper">
                  <AiOutlineNumber />
                  <p className="order_info_in_row__label">Extra Id</p>
                </div>
                <p className="order_info_in_row__location">
                  {order.extraId}
                </p>
              </div>
            )}
            <div className="order_info_in_row">
            <div className="order_info_in_row_wrapper">
              <BsFillPinMapFill />
              <p className="order_info_in_row__label">{t("components.create_order.pickUpAddress")}</p>
            </div>
            <p className="order_info_in_row__location">
              {order.pickupAddress.name}
            </p>
          </div>
            <div className="order_info_in_row">
              <div className="order_info_in_row_wrapper">
                <BsFillPinMapFill />
                <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.deliveryAddress")}</p>
              </div>
              <p className="order_info_in_row__location">
                {order.address.deliveryAddress}{order.address.apartment && `, ${t("pages.dashboard.order_details.order_info.apt")}. ${order.address.apartment}`}{order.address.entrance && `, ${t("pages.dashboard.order_details.order_info.ent")}. ${order.address.entrance}`}{order.address.floor && `, ${t("pages.dashboard.order_details.order_info.flr")} ${order.address.floor}`}
              </p>
            </div>
            <div className="order_info_in_row">
              <div className="order_info_in_row_wrapper">
                <MdDateRange />
                <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.startDate")}</p>
              </div>
              <p className="order_info_in_row__descrip">
                {moment(order.startDate).format(`${list.date_format.value.format}, ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}
              </p>
            </div>
            <div className="order_info_in_row">
              <div className="order_info_in_row_wrapper">
                <MdDateRange />
                <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.endDate")}</p>
              </div>
              <p className="order_info_in_row__descrip">
                {moment(order.endDate).format(`${list.date_format.value.format}, ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}
              </p>
            </div>
            {order.agent && (
              order.status !== 'canceled' &&
              order.status !== 'failed' &&
              order.status !== 'delivered'
            ) && (
              <div className="order_info_in_row">
                <div className="order_info_in_row_wrapper">
                  <HiOutlineExternalLink />
                  <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.trackingLink")}</p>
                </div>
                <a
                  href={`/t/${order.trackingLink}`}
                  target="_blank"
                  rel="noreferrer"
                  className="order_info_in_row__descrip order_info_in_row__link"
                >{t("pages.dashboard.order_details.order_info.goToLink")}</a>
              </div>
            )}
            {order?.note && (
              <div className="order_info_in_row">
                <div className="order_info_in_row_wrapper">
                  <BsChatDotsFill />
                  <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.description")}</p>
                </div>
                <p className="order_info_in_row__descrip">
                  {order?.note}
                </p>
              </div>
            )}
            <div className="order_info_in_row">
              <div className="order_info_in_row_wrapper">
                <MdPayments />
                <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.paymentMethod")}</p>
              </div>
              <p className="order_info_in_row__descrip">
                {order.paymentMethod.name.toLowerCase() === "cash" ? t("payment.cash") : order.paymentMethod.name}
              </p>
            </div>
            <div className="order_info_in_row">
              <div className="order_info_in_row_wrapper">
                <RiMoneyDollarCircleFill />
                <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.amount")}</p>
              </div>
              <p className="order_info_in_row__descrip">
                {toPriceWithCurrency(order.amount, list.currency.value.currency.toUpperCase())}
              </p>
            </div>
          </div>
        <div className="order_info_title">{t("pages.dashboard.order_details.order_info.customer")}</div>
        <div className="order_info_in">
          <div className="order_info_in_row">
            <div className="order_info_in_row_wrapper">
              <CgProfile />
              <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.name")}</p>
            </div>
            {order.customer.firstName && order.customer.lastName ? (
              <p className="order_info_in_row__descrip">
                {order.customer.firstName} {order.customer.lastName}
              </p>
              ) :
              <p className="order_info_in_row__descrip">
                n/a
              </p>
            }
          </div>
          <div className="order_info_in_row">
            <div className="order_info_in_row_wrapper">
              <AiFillPhone />
              <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.phoneNumber")}</p>
            </div>
            <p className="order_info_in_row__descrip">
              {order.customer.phoneNumber}
            </p>
          </div>
          {order.customer.email && (
            <div className="order_info_in_row">
              <div className="order_info_in_row_wrapper">
                <MdEmail />
                <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.email")}</p>
              </div>
              <p className="order_info_in_row__descrip">
                {order.customer.email}
              </p>
            </div>
          )}
        </div>
        {order.agent && (
          <>
            <div className="order_info_title">{t("pages.dashboard.order_details.order_info.agent")}</div>
            <div className="order_info_in">
              <div className="order_info_in_row">
                <div className="order_info_in_row_wrapper">
                  <CgProfile />
                  <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.name")}</p>
                </div>
                <p className="order_info_in_row__descrip">
                  {order.agent?.firstName} {order.agent?.lastName} ({order.agent.username})
                </p>
              </div>
              <div className="order_info_in_row">
                <div className="order_info_in_row_wrapper">
                  <AiFillPhone />
                  <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.phoneNumber")}</p>
                </div>
                <p className="order_info_in_row__descrip">
                  {order.agent?.phoneNumber}
                </p>
              </div>
              {order.agent.email && (
                <div className="order_info_in_row">
                  <div className="order_info_in_row_wrapper">
                    <MdEmail />
                    <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.email")}</p>
                  </div>
                  <p className="order_info_in_row__descrip">
                    {order.agent.email}
                  </p>
                </div>
              )}
            </div>
          </>
        )}
        {order.partner && (
          <>
            <div className="order_info_title">{t("pages.dashboard.order_details.order_info.partner")}</div>
            <div className="order_info_in">
              <div className="order_info_in_row">
                <div className="order_info_in_row_wrapper">
                  <CgProfile />
                  <p className="order_info_in_row__label">{t("pages.dashboard.order_details.order_info.name")}</p>
                </div>
                <p className="order_info_in_row__descrip">
                  {order.partner?.name}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    );
}

OrderInfo.propTypes = {
  order: PropTypes.object.isRequired
};

export default memo(OrderInfo)
