import React from "react";

const PaymentMethodsContext = React.createContext({
  list: null,
  set: () => {},
  add: () => {},
  update: () => {},
  delete: () => {}
});

export default PaymentMethodsContext;
