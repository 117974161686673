import "./index.scss";
import {memo, useState, useCallback, useEffect} from "react";
import Form from "../../../../components/form";
import FormRow from "../../../../components/form/form-row";
import FormCol from "../../../../components/form/form-col";
import api from "../../../../utils/api";
import PropTypes from "prop-types";
import DropDown from "../../../../components/ui/drop-down";
import {useTranslation} from "react-i18next";
import Loading from "../../../../components/ui/loading";
import moment from "moment";
import {errToString} from "../../../../utils";

function EditBranchHeadForm({
dateFields,
selectedBranch,
setSelectedBranch,
onCancel,
branchesData,
setBranchesData
}) {
  const [error, setError] = useState(null);
  const {t} = useTranslation();
  const [managers, setManagers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);
  const [branchHead, setBranchHead] = useState({
    label: `${selectedBranch.head.firstName} ${selectedBranch.head.lastName}` ,
    value: selectedBranch.head.id
  });

  const onEditBranchHead = useCallback((e) => {
    e.preventDefault();
    setPending(true);

    const startDate = moment(dateFields.startDate).utc().format();
    const endDate = moment(dateFields.endDate).utc().format();

    api
      .put(`/company/address/${selectedBranch.id}?from=${startDate}&to=${endDate}`, {
        headId: branchHead.id
      })
      .then((res) => {
        setSelectedBranch({
          ...selectedBranch,
          head: res.data.head
        });
        setBranchesData(branchesData.map(obj => {
          if (obj.id === res.data.id) {
            return {
              ...selectedBranch,
              ...res.data
            }
          }
          return obj
        }));
        onCancel();
        setPending(false);
        setSelectedBranch(null);
      })
      .catch(err => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      })

  }, [dateFields, branchHead.id, setBranchesData, branchesData, onCancel, setSelectedBranch, selectedBranch]);

  useEffect(() => {
    api
      .get(`/employees/managers?companyAddressIds=all`)
      .then((res) => {
        setLoading(false);
        return setManagers(res.data.results.map(obj => ({
          ...obj,
          label: obj.firstName + " " + obj.lastName,
          value: obj.id
        })));
      })
      .catch(err => console.log(errToString(err)))
  }, []);

  return (
    <>
      <div className="edit_branch_head_form">
        <Form
          title={`${t("pages.branches.branch_card.edit_branch_head_form.editBranchHead")} - ${selectedBranch.name}`}
          error={error && t(`errors.${error?.toLowerCase()}`)}
          onSubmit={onEditBranchHead}
        >
          <FormRow>
            <FormCol>
              <label>
                <span>*</span>
                {t("pages.branches.branch_card.edit_branch_head_form.managers")}
              </label>
              <DropDown
                hasPersonIcon={true}
                placeholder="Choose head of branch"
                options={managers || []}
                value={branchHead}
                onChange={(e) => setBranchHead(e)}
                position="bottom"
                showClose={false}
                loading={loading}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <a
                href="!#"
                className="update_agent__cancel_btn"
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
              >{t("pages.agents.update_agent.update_password_agent.cancel")}</a>
            </FormCol>
            <FormCol>
              {!pending && (
                <button
                  className="edit_branch_head_form_submit_btn"
                  type="submit"
                >{t("pages.branches.branch_card.edit_branch_head_form.save")}</button>
              )}
              {pending && (
                <button className="create_order_submit_btn create_order_submit_btn--pending">
                  <Loading />
                </button>
              )}
            </FormCol>
          </FormRow>
        </Form>
      </div>
      <div
        className="edit_branch_head_form__overlay"
        onClick={() => onCancel()}
      />
    </>
  );
}

EditBranchHeadForm.propTypes = {
  selectedBranch: PropTypes.object,
  setSelectedBranch: PropTypes.func,
  setBranchesData: PropTypes.func,
  branchesData: PropTypes.array,
  onCancel: PropTypes.func
}

export default memo(EditBranchHeadForm);
