import axios from "axios";

const api = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");

  if (token && window.location.pathname !== '/admin') {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
