import { memo } from "react";

function Year() {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <div className="copy-year">
      <p>{year}</p>
    </div>
  );
}

export default memo(Year);
