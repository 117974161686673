import {memo, useCallback, useEffect, useState} from 'react';
import api from "../../../../utils/api";
import Form from '../../../../components/form'
import FormRow from "../../../../components/form/form-row";
import FormCol from "../../../../components/form/form-col";
import PropTypes from "prop-types";
import sound from "../../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Loading from "../../../../components/ui/loading";
import {RiLockPasswordLine} from "react-icons/ri";
import {errToString} from "../../../../utils";

function UpdateManagerPassword({onClose, manager}) {
  const {t} = useTranslation();
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [fields, setFields] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onChangeField = useCallback((e, name) => {
    setError(null);

    if (e.target.type === 'password') {
      setFields({
        ...fields,
        [name]: e.target.value
      });
    }
  }, [fields]);
  const onSubmit = useCallback(e => {
    e.preventDefault();

    const data = {
      newPassword: fields.newPassword,
      confirmPassword: fields.confirmPassword,
    };

    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    if (data.newPassword !== data.confirmPassword) {
      return setError(t("passwords_not_match"));
    }

    setPending(true);
    api
      .put(`/employees/managers/${manager.id}`, {
        password: fields.confirmPassword
      })
      .then(() => {
        onClose();
        setPending(false);
        const audio = new Audio(sound);
        const info = `${t("pages.managers.update_manager.update_password_manager.manager")} ${manager.firstName} ${manager.lastName} ${t("pages.managers.update_manager.update_password_manager.passwordHasBeenUpdated")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch((err) => {
        setPending(false);
        setError(errToString(err));
        console.log(errToString(err));
      });
  }, [t, onClose, fields, manager]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
      <>
        {manager && (
            <div className="update_manager">
              <div className="update_manager_in">
                <Form
                    title={`${t("pages.managers.update_manager.update_password_manager.updatePassword")} ${manager.firstName} ${manager.lastName}`}
                    error={error && t(`errors.${error?.toLowerCase()}`)}
                    onSubmit={onSubmit}
                >
                  {windowWidth > 600 && (
                      <FormRow>
                        <FormCol>
                          <label htmlFor="newPassword">
                            <span>*</span>
                            {t("pages.managers.update_manager.update_password_manager.newPassword")}
                          </label>
                          <div className="create_order_wrapper">
                            <div className="create_order_wrapper_icon">
                              <RiLockPasswordLine />
                            </div>
                            <input
                                id="newPassword"
                                name="newPassword"
                                type="password"
                                placeholder= {t("pages.managers.update_manager.update_password_manager.newPassword")}
                                autoComplete="off"
                                className="create_order_wrapper_input"
                                value={fields.newPassword}
                                onChange={e => onChangeField(e, 'newPassword')}
                            />
                          </div>
                        </FormCol>
                        <FormCol>
                          <label htmlFor="confirmPassword">
                            <span>*</span>
                            {t("pages.managers.update_manager.update_password_manager.confirmPassword")}
                          </label>
                          <div className="create_order_wrapper">
                            <div className="create_order_wrapper_icon">
                              <RiLockPasswordLine />
                            </div>
                            <input
                                className="create_order_wrapper_input"
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                placeholder={t("pages.managers.update_manager.update_password_manager.confirmPassword")}
                                autoComplete="off"
                                value={fields.confirmPassword}
                                onChange={e => onChangeField(e, 'confirmPassword')}
                            />
                          </div>
                        </FormCol>
                      </FormRow>
                  )}
                  {windowWidth <= 600 && (
                      <>
                        <FormRow>
                          <FormCol>
                            <label htmlFor="newPassword">
                              <span>*</span>
                              {t("pages.managers.update_manager.update_password_manager.newPassword")}
                            </label>
                            <div className="create_order_wrapper">
                              <div className="create_order_wrapper_icon">
                                <RiLockPasswordLine />
                              </div>
                              <input
                                  id="newPassword"
                                  name="newPassword"
                                  type="password"
                                  placeholder= {t("pages.managers.update_manager.update_password_manager.newPassword")}
                                  autoComplete="off"
                                  className="create_order_wrapper_input"
                                  value={fields.newPassword}
                                  onChange={e => onChangeField(e, 'newPassword')}
                              />
                            </div>
                          </FormCol>
                        </FormRow>
                        <FormRow>
                          <FormCol>
                            <label htmlFor="confirmPassword">
                              <span>*</span>
                              {t("pages.managers.update_manager.update_password_manager.confirmPassword")}
                            </label>
                            <div className="create_order_wrapper">
                              <div className="create_order_wrapper_icon">
                                <RiLockPasswordLine />
                              </div>
                              <input
                                  className="create_order_wrapper_input"
                                  id="confirmPassword"
                                  name="confirmPassword"
                                  type="password"
                                  placeholder={t("pages.managers.update_manager.update_password_manager.confirmPassword")}
                                  autoComplete="off"
                                  value={fields.confirmPassword}
                                  onChange={e => onChangeField(e, 'confirmPassword')}
                              />
                            </div>
                          </FormCol>
                        </FormRow>
                      </>
                  )}
                  <FormRow>
                    <FormCol>
                      <a
                          href="!#"
                          className="update_manager__cancel_btn"
                          onClick={onClose}
                      >{t("pages.managers.update_manager.update_password_manager.cancel")}</a>
                    </FormCol>
                    <FormCol>
                      {!pending && (
                          <button type="submit" className="update_manager__submit_btn">
                            {t("pages.agents.update_agent.update_password_agent.updatePasswordBtn")}
                          </button>
                      )}
                      {pending && (
                          <button className="update_manager__submit_btn update_manager__submit_btn--pending">
                            <Loading />
                          </button>
                      )}
                    </FormCol>
                  </FormRow>
                </Form>
              </div>
            </div>
        )}
        {!manager && (
            <div className="assign_order_loading">
              <Loading />
            </div>
        )}
      </>
  );
}

UpdateManagerPassword.propTypes = {
  onClose: PropTypes.func,
  manager: PropTypes.object
};

export default memo(UpdateManagerPassword);
