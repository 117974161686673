import "./index.scss";
import {memo, useCallback, useContext} from "react";
import {createPortal} from 'react-dom';
import api from "../../../../../utils/api";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import sound from "../../../../../assets/audio/notification.mp3";
import {toast} from "react-toastify";
import PaymentMethodsContext from "../../../../../context/payment-methods/payment-methods-context";
import {errToString} from "../../../../../utils";

function DeletePaymentMethodForm({id, onCancel, name, setIsDeletePaymentMethodFormOpen}) {
  const {t} = useTranslation();
  const paymentMethods = useContext(PaymentMethodsContext);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    if (name.toLowerCase() === 'cash') {
      return alert(`${t("err.cashCannotBeDeleted")}`)
    }

    api
      .delete(`/company/payment_method/${id}`)
      .then(() => {
        paymentMethods.delete(id);
        setIsDeletePaymentMethodFormOpen(false);
        const audio = new Audio(sound);
        const info = `${t("pages.settings.paymentMethod")} ${name} ${t("pages.branches.branch_card.delete_branch.hasBeenFrozen")}`;
        toast.info(info, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        audio.play();
      })
      .catch((err) => {
        alert(t(`errors.${errToString(err)}`))
        console.log(errToString(err))
      })
  }, [t, name, id, setIsDeletePaymentMethodFormOpen, paymentMethods]);

  return createPortal(
    <div className="delete_payment_method_form">
        <div
            className="agents_in_freeze_alert"
            onClick={(e) => e.stopPropagation()}
        >
            <div className="agents_in_freeze_alert_title">
                {`${t("payment.warningFreeze")} ${name}`}
            </div>
            <div className="agents_in_freeze_alert_actions">
                <button
                    className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_cancel"
                    onClick={onCancel}
                >{t("pages.agents.cancel")}</button>
                <button
                    className="agents_in_freeze_alert_btn agents_in_freeze_alert_btn_submit agents_in_freeze_alert_btn_submit--freeze"
                    onClick={onSubmit}
                >{t("pages.agents.freeze")}</button>
            </div>
        </div>
        <div
            className="agents_in_freeze_alert_overlay"
            onClick={onCancel}
        />
    </div>,
      document.getElementById('modal')
  );
}

DeletePaymentMethodForm.propTypes = {
  onCancel: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  setIsCreatePaymentMethodFormOpen: PropTypes.bool
}

export default memo(DeletePaymentMethodForm);
