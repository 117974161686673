import "./index.scss";
import {memo, useContext, useState} from "react";
import SideDecoration from "../../../../components/ui/side-decoration";
import moment from "moment";
import { BsFillPinMapFill, BsPeopleFill } from "react-icons/bs";
import { MdLabel } from "react-icons/md";
import { MdOutlineAssignmentInd } from "react-icons/md";
import { BiTimeFive } from "react-icons/bi";
import PopUp from "../../../../components/layout/popup";
import PropTypes from 'prop-types';
import SingleMarker from '../../../../components/single-marker';
import cn from "classnames";
import {useTranslation} from "react-i18next";
import PreferencesContext from "../../../../context/preferences/preferences-context";

function OrderHistory({
mapIntegration,
order
}) {
    const [selectedHistory, setSelectedHistory] = useState(null);
    const {t} = useTranslation();
    const {list} = useContext(PreferencesContext);

    return (
      <div className="order_history">
        <div className="order_history_title">{t("pages.dashboard.order_details.order_history.orderHistory")}</div>
        <div className="order_history_in">
          <div className="order_history_in_row">
            <div className="order_history_in_row_wrapper">
              <MdLabel />
              <p className="order_history_in_row_label">{t("pages.dashboard.order_details.order_history.status")}</p>
              <div
                  className={cn(`order_history_in_row_status order_history_in_row_status--${order.status}`)}
              >
                  <p>{t(`statuses.${order.status}`)}</p>
              </div>
            </div>
          </div>
          <div className="order_history_in_row">
            <div className="order_history_in_row_wrapper">
              <MdOutlineAssignmentInd />
              <p className="order_history_in_row_label">{t("pages.dashboard.order_details.order_history.createdBy")}</p>
            </div>
            <p className="order_history_in_row_descrip">
              {`${order.creator.type} ${order.creator.firstName} ${order.creator.lastName}`}
            </p>
          </div>
          <div className="order_history_in_row">
            <div className="order_history_in_row_wrapper">
              <BiTimeFive />
              <p className="order_history_in_row_label">{t("pages.dashboard.order_details.order_history.createdAt")}</p>
            </div>
            <p className="order_history_in_row_descrip">
              {`${moment(order.createdAt).format(`${list.date_format.value.format}, ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}`}
            </p>
          </div>
          <div className="order_history_in_row">
            <div className="order_history_in_row_wrapper">
              <BsFillPinMapFill />
              <p className="order_history_in_row_label">{t("pages.dashboard.order_details.order_history.deliveryAddress")}</p>
            </div>
            <p className="order_history_in_row_location">
              {order.address.deliveryAddress}{order.address.apartment && `, ${t("pages.dashboard.order_details.order_history.apt")}. ${order.address.apartment}`}{order.address.entrance && `, ${t("pages.dashboard.order_details.order_history.ent")}. ${order.address.entrance}`}{order.address.floor && `, ${t("pages.dashboard.order_details.order_history.flr")} ${order.address.floor}`}
            </p>
          </div>
          <div className="order_history_in_row">
            <div className="order_history_in_row_wrapper">
              <BsPeopleFill />
              <p className="order_history_in_row_label">{t("pages.dashboard.order_details.order_history.customer")}</p>
            </div>
            {(order.customer.firstName && order.customer.lastName) ? (
              <p className="order_history_in_row_descrip">
                {`${order.customer.firstName} ${order.customer.lastName} ${order.customer.phoneNumber}`}
              </p>
            ) : (
              <p className="order_history_in_row_descrip">
                {`${order.customer.phoneNumber}`}
              </p>
            )}
          </div>
        </div>
        <ul>
          {order.history?.length && order.history.map((history, index) => (
            <li
              key={index}
              className="order_history_list"
            >
              <SideDecoration/>
              <div className='order_history_list_in'>
                <div className="order_history_list_in_wrapper">
                  <h2 className="order_history_list_in_wrapper_title">{t("pages.dashboard.order_details.order_history.status")}</h2>
                  <div
                      className={cn(`order_history_list_in_wrapper_status order_history_list_in_wrapper_status--${history.status}`)}
                  >
                      <p>{t(`statuses.${history.status}`)}</p>
                  </div>
                </div>
                <div className='order_history_list_in_wrapper'>
                  {history.status === 'created' ? (
                    <span className="order_history_list_in_wrapper_label">{t("pages.dashboard.order_details.order_history.createdAt")}</span>
                  ) : (
                    <span className="order_history_list_in_wrapper_label">{t("pages.dashboard.order_details.order_history.updatedAt")}</span>
                  )}
                  <p className="order_history_list_in_wrapper_description">{`${moment(history.updatedAt).format(`${list.date_format.value.format}, ${list.time_format.value.format} ${list.time_format.value.format === "hh:mm" ? "A" : ""}`)}`}</p>
                </div>
                <div className='order_history_list_in_wrapper'>
                  {<span className="order_history_list_in_wrapper_label">{t("pages.dashboard.order_details.order_history.by")}</span>}
                  <p className="order_history_list_in_wrapper_description">{history.initiator.username} <span>({history.initiator.type})</span></p>
                </div>
                <button
                  className={cn("order_history_list_in_wrapper_btn", {"order_history_list_in_wrapper_btn--hovered" : history.location})}
                  onClick={() => history.location && setSelectedHistory(history)}
                >{history.location ? `${t("pages.dashboard.order_details.order_history.showOnMap")}` : `${t("pages.dashboard.order_details.order_history.noLocation")}`}</button>
              </div>
            </li>
          ))}
        </ul>
        {selectedHistory?.location && (
          <PopUp onOverlayClick={() => setSelectedHistory(null)}>
            <SingleMarker
              mapIntegration={mapIntegration}
              agentLocation={selectedHistory.location}
              agentSrc={order.agent.src}
              deliveryAddressLocation={order.address.location}
              pickUpLocation={(selectedHistory.status === 'picked_up' || selectedHistory.status === 'on_way' || selectedHistory.status === 'delivered') ? order.history.find(({ status }) => status === 'picked_up')?.location : null}
            />
          </PopUp>
        )}
      </div>
    );
}

OrderHistory.propTypes = {
    mapIntegration: PropTypes.object.isRequired,
    order: PropTypes.object
};

export default memo(OrderHistory)
