import {memo, useCallback, useContext, useEffect, useMemo, useState} from "react";
import "./index.scss";
import PageHeader from "../../../../components/layout/page-header";
import {useTranslation} from "react-i18next";
import DropDown from "../../../../components/ui/drop-down";
import PreferencesContext from "../../../../context/preferences/preferences-context";
import Review from "../../../../assets/review.jpeg"
import api from "../../../../utils/api";
import {errToString} from "../../../../utils";

const DEFAULT_OPTIONS = [
    { label: "yes", value: true },
    { label: "no", value: false }
];

function SettingsCompanyReviews() {
    const {list, update} = useContext(PreferencesContext);
    const {t} = useTranslation();
    const [trackingLinkRedirectUrl, setTrackingLinkRedirectUrl] = useState(list.tracking_link_redirect.value.url);
    const [trackingLinkRedirectReviewUrl, setTrackingLinkRedirectReviewUrl] = useState(list.tracking_link_redirect.value.reviewUrl)
    const [trackingLinkRedirectOption, setTrackingLinkRedirectOption] = useState({label: "", value: ""});
    const default_options = useMemo(() => DEFAULT_OPTIONS.map(option => ({
        ...option,
        label: t(`pages.settings.${option.label}`)
    })), [t]);

    const onSubmit = useCallback(() => {
        const promises = [];

        if (
            trackingLinkRedirectOption.value !== list.tracking_link_redirect.isActive ||
            trackingLinkRedirectUrl !== list.tracking_link_redirect.value.url ||
            trackingLinkRedirectReviewUrl !== list.tracking_link_redirect.value.reviewUrl
        ) {
            const trackingLinkRedirectBody = {
                isActive: trackingLinkRedirectOption.value,
                value: {
                    url: trackingLinkRedirectUrl,
                    reviewUrl: trackingLinkRedirectReviewUrl
                }
            };
            promises.push(api.put(`/company/preferences/${list.tracking_link_redirect.id}`, trackingLinkRedirectBody));
        }

        Promise
            .all(promises)
            .then((res) => {
                res.forEach(({ data }) => update(data));
                return alert(t("errors.success"));
            })
            .catch(err => {
                alert(errToString(err));
                console.log(errToString(err))
            });
    }, [
        t,
        list,
        update,
        trackingLinkRedirectOption,
        trackingLinkRedirectReviewUrl,
        trackingLinkRedirectUrl
    ]);

    useEffect(() => {
        if (default_options) {
            setTrackingLinkRedirectOption(default_options.find(({ value }) => value === list.tracking_link_redirect.isActive));
        }
    }, [default_options, list]);

    return (
        <div className="settings_company_reviews">
            <PageHeader>
                <p>{t("pages.settings.reviews")}</p>
            </PageHeader>
            <div className="settings_company_reviews_in">
                <h2 className="settings_company_reviews_in__title">
                    {t("pages.settings.trackingLinkRedirect")}
                </h2>
                <p className="settings_company_reviews_in__descrip">
                    {t("pages.settings.trackingLinkRedirectDescription")}
                    <span
                        onClick={() => window.open("https://support.google.com/business/answer/3474122?hl=en#create_and_share_a_link", '_blank')}
                    >{t("pages.settings.visitHere")}</span>
                </p>
                <div className="settings_company_reviews_in_options">
                    <div className="settings_company_reviews_in_options_in">
                        <div className="settings_company_reviews_in_options_drop_down">
                            <DropDown
                                hasTrackingLinkIcon={true}
                                placeholder={t("components.search.chooseOptions")}
                                position="top"
                                showClose={false}
                                options={default_options}
                                value={trackingLinkRedirectOption}
                                onChange={option => {
                                    if (option.value === trackingLinkRedirectOption.value) {
                                        return;
                                    }
                                    setTrackingLinkRedirectOption(option);
                                    setTrackingLinkRedirectUrl(list.tracking_link_redirect.value.url);
                                    setTrackingLinkRedirectReviewUrl(list.tracking_link_redirect.value.reviewUrl);
                                }}
                            />
                            {trackingLinkRedirectOption.value && (
                                <>
                                    <div className="settings_company_preferences_in_options_wrapper settings_company_preferences_in_options_wrapper--lng">
                                        <p className="settings_company_reviews_in_options_wrapper_descrip">
                                            {t("pages.settings.trackingLinkRedirectDescriptionMainWebsite")}
                                        </p>
                                        <label htmlFor="">{t("pages.settings.brandedWebsite")}</label>
                                        <input
                                            type="text"
                                            value={trackingLinkRedirectUrl}
                                            onChange={e => setTrackingLinkRedirectUrl(e.target.value)}
                                        />
                                    </div>
                                    <div className="settings_company_preferences_in_options_wrapper settings_company_preferences_in_options_wrapper--lng">
                                        <p className="settings_company_reviews_in_options_wrapper_descrip">
                                            {t("pages.settings.trackingLinkRedirectDescriptionReviewWebsite")}
                                        </p>
                                        <label htmlFor="">{t("pages.settings.reviewUrl")}</label>
                                        <input
                                            type="text"
                                            value={trackingLinkRedirectReviewUrl}
                                            onChange={e => setTrackingLinkRedirectReviewUrl(e.target.value)}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        {trackingLinkRedirectOption.value && (
                            <div className="settings_company_reviews_in_options_image">
                                <img src={Review} alt="google review"/>
                            </div>
                        )}
                    </div>
                </div>
                <div className="settings_company_reviews_in_actions">
                    <button
                      className="create_order_submit_btn"
                      onClick={onSubmit}
                    >
                        {t("pages.branches.create_branch_form.save")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default memo(SettingsCompanyReviews);
