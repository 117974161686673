import { useReducer, useCallback, memo } from 'react';
import PaymentMethodsContext from './payment-methods-context';

const defaultPaymentMethodsState = {
  list: null,
  set: () => {},
  add: () => {},
  update: () => {},
  delete: () => {}
};

const paymentMethodsReducer = (state, action) => {
  if (action.type === 'SET_PAYMENT_METHODS') {
    return {
      ...state,
      list: action.payload.paymentMethods
    };
  }
  if (action.type === 'ADD_PAYMENT_METHOD') {
    return {
      ...state,
      list: [
          ...state.list,
          action.payload.paymentMethod
      ]
    };
  }
  if (action.type === 'UPDATE_PAYMENT_METHOD') {
    return {
      ...state,
      list: state.list.map(_paymentMethod => {
        if (_paymentMethod.id === action.payload.paymentMethod.id) {
          return action.payload.paymentMethod;
        }

        return _paymentMethod;
      })
    };
  }
  if (action.type === 'DELETE_PAYMENT_METHOD') {
    return {
      ...state,
      list: state.list.map(_paymentMethod => {
        if (_paymentMethod.id === action.payload.id) {
          return {
            ..._paymentMethod,
            deletedAt: new Date().toISOString()
          }
        }

        return _paymentMethod;
      })
    };
  }

  return defaultPaymentMethodsState;
};

const PaymentMethodsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(paymentMethodsReducer, defaultPaymentMethodsState);

  const setPaymentMethods = useCallback(paymentMethods => {
    dispatch({
      type: 'SET_PAYMENT_METHODS',
      payload: { paymentMethods },
    });
  }, [dispatch]);
  const addPaymentMethods = useCallback(paymentMethod => {
    dispatch({
      type: 'ADD_PAYMENT_METHOD',
      payload: { paymentMethod },
    });
  }, [dispatch]);
  const updatePaymentMethods = useCallback(paymentMethod => {
    dispatch({
      type: 'UPDATE_PAYMENT_METHOD',
      payload: { paymentMethod },
    });
  }, [dispatch]);
  const deletePaymentMethods = useCallback(id => {
    dispatch({
      type: 'DELETE_PAYMENT_METHOD',
      payload: { id },
    });
  }, [dispatch]);

  const paymentMethodsContext = {
    list: state.list,
    set: setPaymentMethods,
    add: addPaymentMethods,
    update: updatePaymentMethods,
    delete: deletePaymentMethods
  };

  return (
      <PaymentMethodsContext.Provider value={paymentMethodsContext}>{children}</PaymentMethodsContext.Provider>
  );
};

export default memo(PaymentMethodsProvider);
