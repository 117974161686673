import "./index.scss";
import {memo, useState, useCallback, useRef, useMemo, useEffect, cloneElement} from "react";
import {HiArrowSmDown, HiOutlineDeviceTablet, HiOutlineLocationMarker} from "react-icons/hi";
import {IoMdClose} from "react-icons/io";
import {BiFilterAlt, BiGitBranch, BiMoney, BiTimeFive} from "react-icons/bi";
import PropTypes from 'prop-types';
import cn from 'classnames';
import {createPortal} from 'react-dom';
import Loading from '../loading';
import {
  MdOutlineAccessTime,
  MdOutlinePayments,
  MdOutlineSystemUpdateAlt,
  MdOutlineTextsms,
  MdOutlineTimer
} from "react-icons/md";
import {
  BsBroadcastPin,
  BsBuilding,
  BsCalendar2,
  BsGlobe,
  BsPersonCheck
} from "react-icons/bs";
import {TbLockAccess, TbRoad} from "react-icons/tb";
import {MdTag} from "react-icons/md";
import {ImMap2} from "react-icons/im";
import {AiFillBell} from "react-icons/ai";
import {RiPinDistanceFill} from "react-icons/ri";
import {useTranslation} from "react-i18next";

function DropDown({
  value,
  position = 'bottom',
  className = '',
  disabled,
  options,
  onSearch,
  onChange,
  onReset = () => null,
  onFocus = () => null,
  onInit = ({ methods }) => null,
  placeholder,
  maxHeight,
  label,
  loading,
  hasHover = true,
  theme = 'default',
  showClose = true,
  hasPaymentMethodIcon = false,
  hasMarkerIcon = false,
  hasPersonIcon = false,
  hasFilterIcon = false,
  hasStatusIcon = false,
  hasBellIcon = false,
  hasBranchIcon = false,
  hasDeviceIcon = false,
  hasRoleIcon = false,
  hasCityIcon = false,
  hasDistanceIcon = false,
  hasCountryIcon = false,
  hasTagIcon = false,
  hasSmsIcon = false,
  hasMapIcon = false,
  fullWidthMenu = null,
  hasCounterIcon=false,
  hasCurrencyIcon=false,
  hasTimeIcon=false,
  hasDateIcon=false,
  hasUpdateIcon=false,
  hasTrackingLinkIcon=false,
  hasKmIcon=false
}) {
  const ref = useRef();
  const {t} = useTranslation();
  const popupRef = useRef();
  const inputRef = useRef();
  const [popupTop, setPopupTop] = useState('-9999px');
  const [popupLeft, setPopupLeft] = useState('-9999px');
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState(value?.label || '');
  const [selected, setSelected] = useState(value);
  const [activeOptionIndex, setActiveOptionIndex] = useState(-1);
  const filteredOptions = useMemo(() => {
    if (search && !onSearch && !selected) {
      return options.filter(option => {
        if (option?.option) {
          return option?.option?.label.toLowerCase().includes(search.toLowerCase());
        }

        return option?.label.toLowerCase().includes(search.toLowerCase());
      });
    }

    return options;
  }, [options, search, onSearch, selected]);
  const maxWidth = (() => {
    if (ref?.current?.offsetHeight) {
      return ref.current?.offsetWidth;
    }

    return null;
  })();
  const onChangeSearch = useCallback(e => {
    if (theme === 'pagination') {
      return;
    }

    setActiveOptionIndex(-1);
    setSearch(e.target.value);
    setSelected(null);
    onSearch && onSearch(e.target.value);
  }, [onSearch, theme]);
  const onSelect = useCallback(option => {
    setSelected(option);
    setSearch(option.label);
    setIsOpen(false);
    onChange(option);
    setActiveOptionIndex(filteredOptions.findIndex(_option => _option.value === option.value));
    inputRef.current.blur();
  }, [onChange, filteredOptions, inputRef]);
  const onDeselect = useCallback(() => {
    setSelected(null);
    setSearch('');
    setIsOpen(false);
    onReset();
    setActiveOptionIndex(-1);
  }, [onReset]);
  const onKeyDown = useCallback(e => {
    if (selected && e.keyCode === 8) {
      onReset();
      setActiveOptionIndex(-1);
      return setSelected(null);
    }

    if (e.keyCode === 9) {
      setIsOpen(false);
    }
    if (e.keyCode === 38) {
      const index = activeOptionIndex > 0 ? activeOptionIndex - 1 : activeOptionIndex;
      setActiveOptionIndex(index);
      setIsOpen(true);
    }
    if (e.keyCode === 40) {
      const index = activeOptionIndex < filteredOptions.length - 1 ? activeOptionIndex + 1 : activeOptionIndex;
      setActiveOptionIndex(index);
      setIsOpen(true);
    }
    if (e.keyCode === 13) {
      if (filteredOptions[activeOptionIndex]?.option) {
        e.preventDefault();
        return onSelect(filteredOptions[activeOptionIndex].option);
      }
      if (filteredOptions[activeOptionIndex]) {
        e.preventDefault();
        return onSelect(filteredOptions[activeOptionIndex]);
      }

      onSelect(filteredOptions[activeOptionIndex]);
    }
    if (e.keyCode === 27) {
      setSearch('');
      setSelected(null);
      setIsOpen(false);
      onReset();
      setActiveOptionIndex(-1);
      document.activeElement.blur();
    }
  }, [activeOptionIndex, filteredOptions, onSelect, onReset, selected]);
  const onCloseMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (ref.current && popupRef.current) {
      if (position === 'top') {
        setPopupTop(ref.current?.getBoundingClientRect().top - popupRef.current?.getBoundingClientRect().height - 5);
      }
      if (position === 'bottom') {
        setPopupTop(ref.current?.getBoundingClientRect().top + ref.current?.getBoundingClientRect().height + 5);
      }

      setPopupLeft(ref.current?.getBoundingClientRect().left);
    }
  }, [ref, popupRef, isOpen, position, filteredOptions]);
  useEffect(() => {
    setActiveOptionIndex(filteredOptions.findIndex(option => {
      if (option?.option) {
        return option?.option?.label === selected?.label;
      }

      return option?.label === selected?.label;
    }));
  }, [filteredOptions, selected]);
  useEffect(() => {
    setSelected(value);
    setSearch('');
  }, [value]);
  useEffect(() => {
    const resetSearch = () => setSearch('');
    onInit({ resetSearch, close: onCloseMenu });
  // eslint-disable-next-line
  }, []);

  return (
    <div
      ref={ref}
      className={cn('drop_down', { 'drop_down--disabled': disabled })}
    >
      <div className={cn('drop_down_in', { 'drop_down_in--disable_hover': !hasHover })}>
        <div className="drop_down_search">
          {hasMarkerIcon && (
              <div className="drop_down_search_icon"><HiOutlineLocationMarker/></div>
          )}
          {hasFilterIcon && (
            <div className="drop_down_search_icon"><BiFilterAlt/></div>
          )}
          {hasPaymentMethodIcon && (
              <div className="drop_down_search_icon"><MdOutlinePayments/></div>
          )}
          {hasPersonIcon && (
              <div className="drop_down_search_icon"><BsPersonCheck /></div>
          )}
          {hasStatusIcon && (
            <div className="drop_down_search_icon"><BsBroadcastPin /></div>
          )}
          {hasBranchIcon && (
            <div className="drop_down_search_icon"><BiGitBranch /></div>
          )}
          {hasDeviceIcon && (
            <div className="drop_down_search_icon"><HiOutlineDeviceTablet /></div>
          )}
          {hasRoleIcon && (
            <div className="drop_down_search_icon"><TbLockAccess /></div>
          )}
          {hasCityIcon && (
            <div className="drop_down_search_icon"><BsBuilding /></div>
          )}
          {hasCountryIcon && (
            <div className="drop_down_search_icon"><BsGlobe /></div>
          )}
          {hasTagIcon && (
              <div className="drop_down_search_icon"><MdTag /></div>
          )}
          {hasSmsIcon && (
              <div className="drop_down_search_icon"><MdOutlineTextsms /></div>
          )}
          {hasMapIcon && (
              <div className="drop_down_search_icon"><ImMap2 /></div>
          )}
          {hasBellIcon && (
            <div className="drop_down_search_icon"><AiFillBell /></div>
          )}
          {hasDistanceIcon && (
            <div className="drop_down_search_icon"><RiPinDistanceFill /></div>
          )}
          {hasKmIcon && (
            <div className="drop_down_search_icon"><TbRoad /></div>
          )}
          {hasTrackingLinkIcon && (
            <div className="drop_down_search_icon"><BsBroadcastPin /></div>
          )}
          {hasUpdateIcon && (
            <div className="drop_down_search_icon"><MdOutlineSystemUpdateAlt /></div>
          )}
          {hasTimeIcon && (
            <div className="drop_down_search_icon"><MdOutlineAccessTime /></div>
          )}
          {hasCurrencyIcon && (
            <div className="drop_down_search_icon"><BiMoney /></div>
          )}
          {hasCounterIcon && (
            <div className="drop_down_search_icon"><MdOutlineTimer /></div>
          )}
          {hasDateIcon && (
            <div className="drop_down_search_icon"><BsCalendar2 /></div>
          )}
          <input
            ref={inputRef}
            className={cn('drop_down__input', { 'drop_down__input--pagination': theme === 'pagination' }, { 'drop_down__input--search': theme === 'search', 'drop_down__input--active': isOpen })}
            autoComplete={'do-not-autoComplete'}
            aria-autocomplete={'none'}
            autoCorrect={'do-not-autoCorrect'}
            id={placeholder}
            type={'text'}
            placeholder={placeholder}
            onChange={onChangeSearch}
            value={selected?.label || search}
            onKeyDown={onKeyDown}
            onFocus={() => {
              setIsOpen(true);
              onFocus();
            }}
            onClick={() => setIsOpen(true)}
            disabled={disabled}
          />
        </div>
        {((!selected && theme !== 'pagination') || !showClose) && (
          <label
            className="drop_down__label"
            htmlFor={placeholder}
            onClick={() => setIsOpen(true)}
          >
            {!label && (
              <HiArrowSmDown/>
            )}
            {label === 'time' && (
              <BiTimeFive/>
            )}
          </label>
        )}
        {selected && theme !== 'pagination' && showClose && (
          <label
            className="drop_down__label"
            htmlFor="dropDown"
            onClick={onDeselect}
          >
            <IoMdClose />
          </label>
        )}
      </div>
      {(isOpen && (!selected || theme === 'pagination' || theme === 'search' || theme === 'default')) && createPortal(
        <div
          ref={popupRef}
          className={cn('drop_down_menu', className, { 'drop_down_menu--pagination': theme === 'pagination' })}
          style={{top: popupTop, left: popupLeft, maxWidth: fullWidthMenu ? fullWidthMenu : maxWidth, maxHeight}}
        >
          <ul>
            <>
              {loading && (
                <Loading/>
              )}
              {!loading && !filteredOptions?.length && (
                <p>{t("components.ui.drop_down.empty")}</p>
              )}
              {!loading && filteredOptions.map((option, index) => {
                if (option.el) {
                  return cloneElement(option.el, {
                    onClick: () => {
                      onSelect(option.option);
                    },
                    onMouseEnter: () => setActiveOptionIndex(index),
                    className: cn('drop_down_menu__item', option.el.props?.className, { 'drop_down_menu__item--active': index === activeOptionIndex })
                  });
                }

                return (
                  <li
                    key={index}
                    className={cn('drop_down_menu__item', { 'drop_down_menu__item--active': index === activeOptionIndex })}
                    onClick={() => onSelect(option)}
                    onMouseEnter={() => setActiveOptionIndex(index)}
                  >
                    {option.label}
                  </li>
                );
              })}
            </>
          </ul>
        </div>,
        document.getElementById('modal')
      )}
      {isOpen && (
          <div
              className="drop_down__overlay"
              onClick={onCloseMenu}
          />
      )}
    </div>
  );
}

DropDown.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array.isRequired,
  onSearch: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onFocus: PropTypes.func,
  onInit: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  position: PropTypes.string,
  maxHeight: PropTypes.number,
  label: PropTypes.string,
  hasHover: PropTypes.bool,
  loading: PropTypes.bool,
  theme: PropTypes.string,
  showClose: PropTypes.bool
};

export default memo(DropDown);
