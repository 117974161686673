import "./index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import './utils/i18n';
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./context/auth/auth-provider";
import PreferencesProvider from "./context/preferences/preferences-provider";
import SocketProvider from './context/socket/socket-provider';
import PaymentMethodsProvider from "./context/payment-methods/payment-methods-provider";
import BranchesProvider from "./context/branches/branches-provider";
import UserCompanyBranchesProvider from "./context/user-company-branches/user-company-branches-provider";
import AnalyticsProvider from "./context/analytics/analytics-provider";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import IntegrationsProvider from "./context/integrations/integrations-provider";

if (process.env.NODE_ENV === "production") {
  Bugsnag.start({
    apiKey: '7465203b01d523c60abf93f40d8f93f0',
    plugins: [new BugsnagPluginReact()],
  });
  BugsnagPerformance.start({ apiKey: '7465203b01d523c60abf93f40d8f93f0' });
}

const ErrorBoundary = process.env.NODE_ENV === "production" ?
  Bugsnag.getPlugin('react').createErrorBoundary(React) :
  ({ children }) => (
    <>
      {children}
    </>
  );

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ErrorBoundary>
    <AuthProvider>
      <PreferencesProvider>
        <UserCompanyBranchesProvider>
          <BranchesProvider>
            <PaymentMethodsProvider>
              <SocketProvider>
                <AnalyticsProvider>
                  <IntegrationsProvider>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </IntegrationsProvider>
                </AnalyticsProvider>
              </SocketProvider>
            </PaymentMethodsProvider>
          </BranchesProvider>
        </UserCompanyBranchesProvider>
      </PreferencesProvider>
    </AuthProvider>
  </ErrorBoundary>
);
