import "./index.scss";
import React, {memo, useCallback, useEffect, useMemo, useState} from "react";
import PageHeader from "../../../components/layout/page-header";
import {useTranslation} from "react-i18next";
import Table from "../../../components/table";
import {BiExport} from "react-icons/bi";
import moment from "moment/moment";
import Loading from "../../../components/ui/loading";
import Pagination from "../../../components/ui/pagination";
import {LIMITS} from "../../../constants/drop-down-options";
import api from "../../../utils/api";
import {errToString, toPriceWithCurrency} from "../../../utils";
import Search from "../../../components/search";
import DateTimePicker from "../../../components/ui/calendar/date-time-picker-double-calendar";
import {getCurrentDateWithoutHours} from "../../../utils/date";
import {Tooltip} from "react-tooltip";
import VisaSvg from "../../../assets/visa.svg";
import MasterSvg from "../../../assets/mastercard.svg";
import AmexSvg from "../../../assets/amex.svg";
import DiscoverSvg from "../../../assets/discover.svg";
import JcbSvg from "../../../assets/jcb.svg";
import cn from "classnames";

function AccountBillingHistory() {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [currentPage, setCurrentPage] = useState(0);
  const [dateFields, setDateFields] = useState({
    startDate: getCurrentDateWithoutHours(),
    endDate: getCurrentDateWithoutHours(23, 59, 59),
  });
  const [invoices, setInvoices] = useState(null);

  const subscriptionInvoiceTitles = useMemo(() => [
    {
      name: t("pages.billing.invoice"),
      size: 150
    },
    {
      name: t("pages.billing.planName"),
      size: 200
    },
    {
      name: t("pages.billing.paymentMethod"),
      size: 200
    },
    {
      name: t("pages.billing.status"),
      size: 100
    },
    {
      name: t("pages.billing.amount"),
      size: 100
    },
    {
      size: 100
    },
    {

    }
  ], [t]);

  const onChangeLimit = useCallback(option => {
    if (option.value === limit.value) {
      return;
    }

    setLimit(option);
    setOffset(0);
    setLoading(true);
    setCurrentPage(0);
  }, [limit]);
  const onExport = useCallback(() => {
    alert("coming soon!")
  }, []);
  const onSearch = useCallback(({ search, searchField }) => {
    setLoading(true);

    api
      .get(`/subscriptions/invoices?offset=${offset}&limit=${limit.value}${search && searchField ? `&search=${search}&searchField=${searchField}` : ''}`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setInvoices(res.data.results);
        setLoading(false);
      })
      .catch(err => {
        console.log(errToString(err));
        setLoading(false);
      })
  }, [offset, limit]);
  const onPay = useCallback(async (e, id) => {
    e.preventDefault();
    setOnPayPending(true);

    try {
      const {data} = await api.post(`/subscriptions/invoices/${id}/pay`);
      setInvoices(invoices.map(invoice => invoice.id === id ? data : invoice));
      setOnPayPending(false);
      window.location.reload();
    } catch (err) {
      console.log(errToString(err));
      alert(errToString(err));
      setOnPayPending(false);
    }
  }, [invoices]);
  const [onPayPending, setOnPayPending] = useState(false);

  useEffect(() => {
    setLoading(true);
    const promises = [];
    promises.push(api.get(`/subscriptions/invoices?offset=${offset}&limit=${limit.value}`));

    Promise.all(promises)
      .then((results) => {
        setLoading(false);
        setPagesCount(results[0].data.pagesCount);

        return setInvoices(results[0].data.results);
      })
      .catch((err) => {
        setLoading(false);
        console.log(errToString(err));
      });

  }, [offset, limit]);

  return (
    <div className="billing_history">
      <PageHeader>
        <p>{t("pages.billing.billingHistory")}</p>
      </PageHeader>
      <div className="billing_history_in_actions">
        <Search
          placeholder={t("pages.dashboard.orders.search")}
          options={[]}
          onSearch={onSearch}
        />
        <div className='billing_history_in_actions_calendar'>
          <DateTimePicker
            className="orders_date_time_picker"
            placeholder="DD/MM/YYYY HH:MM - HH:MM"
            enableTimePicker={true}
            position="bottom"
            theme='orders'
            enableRangePicker={true}
            value={[dateFields.startDate, dateFields.endDate]}
            onChange={e => {
              setDateFields(e);
              setOffset(0);
            }}
          />
        </div>
        <div
          className="billing_history_in_actions_export"
          data-tooltip-id={`tooltip-export-invoices`}
          onClick={onExport}
        >
          <BiExport />
          <Tooltip
            id={`tooltip-export-invoices`}
            place="top"
            className="tooltipClass"
          >{t("pages.billing.exportInvoices")}</Tooltip>
        </div>
      </div>
      <div className="billing_history_in">
        {!loading && !invoices && (
          <p className="billing_history_in_center">{t("pages.billing.noInvoices")}</p>
        )}
        {!loading && invoices && !invoices?.length && (
          <p className="billing_history_in_center">{t("pages.billing.noInvoices")}</p>
        )}
        {!loading && invoices && !!invoices?.length && (
          <Table
            titles={subscriptionInvoiceTitles}
            loading={false}
            hiddenFields={['id']}
            rows={
            invoices
              .filter(invoice => invoice.amount_due > 0)
              .filter(invoice => invoice.status === "paid" || invoice.status === "failed" || invoice.status === "open" ||  invoice.status === "past_due")
              .map((invoice) => ({
                id: invoice.id,
                invoiceNumber: (
                  <div className="billing_history_in_invoice">
                    <p>{moment(invoice.created * 1000).format('DD-MM-YYYY')}</p>
                    <a
                      href={invoice.invoice_pdf}
                      download
                    >
                      {invoice.number}
                    </a>
                  </div>
                ),
                invoiceSubscription: (
                  <button className="subscriptions_in_status subscriptions_in_status--plan">{invoice.name}</button>
                ),
                card: (
                  (invoice.status === 'paid' && invoice.payment_intent) ?
                    <div className="subscriptions_in_payment">
                    {(invoice.payment_intent.payment_method.card?.brand !== "visa" && invoice.payment_intent.payment_method.card?.brand !== "mastercard" && invoice.payment_intent.payment_method.card?.brand !== "amex" && invoice.payment_intent.payment_method.card?.brand !== "discover" && invoice.payment_intent.payment_method.card?.brand !== "jcb" ) && (
                      <div className="subscriptions_in_payment_name">{invoice.payment_intent.payment_method.card?.brand}</div>
                    )}
                    {invoice.payment_intent.payment_method.card?.brand === "visa" && (
                      <div className="subscriptions_in_payment_img">
                        <img src={VisaSvg} alt="visa"/>
                      </div>
                    )}
                    {invoice.payment_intent.payment_method.card?.brand === "mastercard" && (
                      <div className="subscriptions_in_payment_img">
                        <img src={MasterSvg} alt="mastercard"/>
                      </div>
                    )}
                    {invoice.payment_intent.payment_method.card?.brand === "amex" && (
                      <div className="subscriptions_in_payment_img">
                        <img src={AmexSvg} alt="amex"/>
                      </div>
                    )}
                    {invoice.payment_intent.payment_method.card?.brand === "discover" && (
                      <div className="subscriptions_in_payment_img">
                        <img src={DiscoverSvg} alt="discover"/>
                      </div>
                    )}
                    {invoice.payment_intent.payment_method.card?.brand === "jcb" && (
                      <div className="subscriptions_in_payment_img">
                        <img src={JcbSvg} alt="jcb"/>
                      </div>
                    )}
                    <div className="subscriptions_in_payment_name">{
                      `****${invoice.payment_intent.payment_method.card.last4} | ${
                        invoice.payment_intent.payment_method.card.exp_month < 10 ?
                          `0${invoice.payment_intent.payment_method.card.exp_month}` :
                          invoice.payment_intent.payment_method.card.exp_month
                      }/${invoice.payment_intent.payment_method.card.exp_year}`
                    }</div>
                  </div> :
                    (invoice.status === 'open' && invoice.payment_intent) ?
                      <div className="subscriptions_in_payment">
                        {(invoice.payment_intent.last_payment_error.payment_method.card?.brand !== "visa" && invoice.payment_intent.last_payment_error.payment_method.card?.brand !== "mastercard" && invoice.payment_intent.last_payment_error.payment_method.card?.brand !== "amex" && invoice.payment_intent.last_payment_error.payment_method.card?.brand !== "discover" && invoice.payment_intent.last_payment_error.payment_method.card?.brand !== "jcb" ) && (
                          <div className="subscriptions_in_payment_name">{invoice.payment_intent.last_payment_error.payment_method.card?.brand}</div>
                        )}
                        {invoice.payment_intent.last_payment_error.payment_method.card?.brand === "visa" && (
                          <div className="subscriptions_in_payment_img">
                            <img src={VisaSvg} alt="visa"/>
                          </div>
                        )}
                        {invoice.payment_intent.last_payment_error.payment_method.card?.brand === "mastercard" && (
                          <div className="subscriptions_in_payment_img">
                            <img src={MasterSvg} alt="mastercard"/>
                          </div>
                        )}
                        {invoice.payment_intent.last_payment_error.payment_method.card?.brand === "amex" && (
                          <div className="subscriptions_in_payment_img">
                            <img src={AmexSvg} alt="amex"/>
                          </div>
                        )}
                        {invoice.payment_intent.last_payment_error.payment_method.card?.brand === "discover" && (
                          <div className="subscriptions_in_payment_img">
                            <img src={DiscoverSvg} alt="discover"/>
                          </div>
                        )}
                        {invoice.payment_intent.last_payment_error.payment_method.card?.brand === "jcb" && (
                          <div className="subscriptions_in_payment_img">
                            <img src={JcbSvg} alt="jcb"/>
                          </div>
                        )}
                        <div className="subscriptions_in_payment_name">{
                          `****${invoice.payment_intent.last_payment_error.payment_method.card.last4} | ${
                            invoice.payment_intent.last_payment_error.payment_method.card.exp_month < 10 ?
                              `0${invoice.payment_intent.last_payment_error.payment_method.card.exp_month}` :
                              invoice.payment_intent.last_payment_error.payment_method.card.exp_month
                          }/${invoice.payment_intent.last_payment_error.payment_method.card.exp_year}`
                        }</div>
                      </div> :
                      <div className="subscriptions_in_payment">-</div>
                ),
                invoiceStatus: (
                  <div
                    className={cn('subscriptions_in_status',
                      {'subscriptions_in_status--past_due': invoice.status === 'open'},
                      {'subscriptions_in_status--past_due': invoice.status === 'failed'},
                      {'subscriptions_in_status--pending': invoice.status === 'draft'},
                      {'subscriptions_in_status--paid': invoice.status === 'paid'}
                    )}
                  >{t(`pages.billing.${invoice.status}`)}</div>
                ),
                amount: (
                  <p style={{fontSize: '13px', fontWeight: 300, marginBottom: 0}}>{toPriceWithCurrency(invoice.amount_due / 100, "usd")}</p>
                ),
                invoicePaidAt: (
                  <div>
                    {invoice.status === 'open' && (
                      <button
                        style={{justifyContent: "flex-start"}}
                        className="subscriptions_in_status subscriptions_in_status--pay"
                        onClick={e => onPay(e, invoice.id)}
                      >
                        {onPayPending && (
                          <Loading />
                        )}
                        {!onPayPending && (
                          t("pages.billing.payYourBill")
                        )}
                      </button>
                    )}
                    {invoice.status === 'draft' && (
                      <div
                        className={cn('subscriptions_in_status subscriptions_in_status--pending')}
                      >{t("pages.billing.pending")}</div>
                    )}
                    {invoice.status !== 'open' && invoice.status !== 'draft' && (
                      <div className="billing_history_in_invoice">
                        <p>{t("pages.billing.paidAt")}</p>
                        <p>{moment(invoice.status_transitions.paid_at * 1000).format('DD-MM-YYYY')}</p>
                      </div>
                    )}
                  </div>
                )
            })) || []}
          />
        )}
        {loading && (
          <div className="billing_history_in_center">
            <Loading />
          </div>
        )}
        {!!pagesCount && !!invoices.length && (
          <Pagination
            pagesCount={pagesCount}
            setOffset={setOffset}
            limit={limit}
            limitPlaceholder={limit.label}
            limits={LIMITS}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onChange={onChangeLimit}
          />
        )}
      </div>
    </div>
  );
}

export default memo(AccountBillingHistory);
